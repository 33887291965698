import { Fragment } from "react";
import { ObjectFitContain } from "../ObjectFitContain";
import { RCContainer } from "../RCContainer/RCContainer";

export type RCRulerProps = {
    imageSize: { width: number, height: number };
    pixelSpacing: { x: number, y: number };
}


// TODO: refactoring + fix codestyle
const longLine = 3
const middleLine = 2
const shortLine = 1
const color = "gray"
const scale = 8

export const RCRuler = (props: RCRulerProps) => {

    const { imageSize, pixelSpacing } = props;

    return (
        <ObjectFitContain
            objectWidth={imageSize.width}
            objectHeight={imageSize.height}
        >
            <RCContainer style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                <svg
                    width={"100%"}
                    height={"100%"}
                    viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
                >
                    {Array.from(Array(imageSize.width / pixelSpacing.x + 1).keys()).map((i) => {
                        const length = i % 10 === 0 ? longLine : i % 5 === 0 ? middleLine : shortLine
                        const renderText = i % 10 === 0 && i !== 0
                        return (
                            <Fragment key={i}>
                                <line
                                    x1={i * pixelSpacing.x}
                                    y1={imageSize.height}
                                    x2={i * pixelSpacing.x}
                                    y2={imageSize.height - length * scale}
                                    stroke={color}
                                    strokeWidth={3}
                                />
                                {renderText &&
                                    <text
                                        x={i * pixelSpacing.x}
                                        y={imageSize.height - 5 * scale}
                                        fill={color}
                                        fontSize={3 * scale}
                                        textAnchor="middle"
                                        pointerEvents={"none"}
                                        style={{ userSelect: "none" }}
                                    >
                                        {i / 10}
                                    </text>
                                }
                            </Fragment>
                        )
                    })}
                </svg>

            </RCContainer>
        </ObjectFitContain>
    );
};

import { MaskStyleTypes } from './reports.type';

export const REPORT_SIGNED_DATE_FORMAT = 'EEE dd, yyyy HH:mm';

export interface ColorProps {
  color: [number, number, number];
  opacity: number;
}
export interface ColorPreset {
  red: ColorProps;
  yellow: ColorProps;
  purple: ColorProps;
  white: ColorProps;
}

const whiteConditionStyles: ColorProps = {
  color: [1, 1, 1],
  opacity: 0.1,
};

export const presetTypes: Record<keyof typeof MaskStyleTypes, ColorPreset> = {
  Default: {
    red: {
      color: [1, 0.1, 0.3],
      opacity: 0.7,
    },
    yellow: {
      color: [1, 0.7, 0],
      opacity: 0.6,
    },
    purple: {
      color: [0.5, 0.1, 1],
      opacity: 0.7,
    },
    white: whiteConditionStyles,
  },
  Style2: {
    red: {
      color: [1, 0.1, 0.3],
      opacity: 0.6,
    },
    yellow: {
      color: [1, 0.7, 0],
      opacity: 0.5,
    },
    purple: {
      color: [1, 1, 1],
      opacity: 0.7,
    },
    white: whiteConditionStyles,
  },
  Style3: {
    red: {
      color: [1, 0.1, 0.3],
      opacity: 0.5,
    },
    yellow: {
      color: [1, 0.7, 0],
      opacity: 0.3,
    },
    purple: {
      color: [0.6, 0.6, 0.6],
      opacity: 0.4,
    },
    white: whiteConditionStyles,
  },
  Style4: {
    red: {
      color: [1, 0.1, 0.3],
      opacity: 0.7,
    },
    yellow: {
      color: [1, 0.7, 0],
      opacity: 1,
    },
    purple: {
      color: [1, 1, 1],
      opacity: 0.4,
    },
    white: whiteConditionStyles,
  },
  // TODO: I've just added copy of Style4 to fix TS error. Values should be asked and tuned.
  StyleBW: {
    red: {
      color: [1, 0.1, 0.3],
      opacity: 0.7,
    },
    yellow: {
      color: [1, 0.7, 0],
      opacity: 1,
    },
    purple: {
      color: [1, 1, 1],
      opacity: 0.4,
    },
    white: whiteConditionStyles,
  },
};

export const TWO_D_REPORT_RIGHT_COLUMN_ID = '2D-report-right-column';

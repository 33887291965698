import { useIntl } from 'react-intl';

import { machineTypes } from '../config/i18n';

export const useGetMachineTypesOptions = () => {
  const { formatMessage } = useIntl();

  const machineTypeOptions = [
    {
      label: formatMessage(machineTypes.carestreamCS96003D),
      value: 'Carestream CS 9600 3D',
    },
    {
      label: formatMessage(machineTypes.carestreamCS90003D),
      value: 'Carestream CS 9000 3D',
    },
    {
      label: formatMessage(machineTypes.carestreamCS8000),
      value: 'Carestream CS 8000',
    },
    {
      label: formatMessage(machineTypes.carestreamCS8100),
      value: 'Carestream CS 8100',
    },
    {
      label: formatMessage(machineTypes.carestreamCS81003D),
      value: 'Carestream CS 8100 3D',
    },
    {
      label: formatMessage(machineTypes.carestreamCS82003D),
      value: 'Carestream CS 8200 3D',
    },
    {
      label: formatMessage(machineTypes.durrVistaPanoS),
      value: 'Dürr VistaPano S',
    },
    {
      label: formatMessage(machineTypes.durrVistaVoxS),
      value: 'Dürr VistaVox S',
    },
    {
      label: formatMessage(machineTypes.gendexGXDP),
      value: 'Gendex GXDP',
    },
    {
      label: formatMessage(machineTypes.morita3DAccuitomo170),
      value: 'Morita 3D Accuitomo 170',
    },
    {
      label: formatMessage(machineTypes.moritaVeraviewIC5HD),
      value: 'Morita Veraview IC5 HD',
    },
    {
      label: formatMessage(machineTypes.moritaVeraviewX800),
      value: 'Morita Veraview X800',
    },
    {
      label: formatMessage(machineTypes.moritaVeraviewepocs2D),
      value: 'Morita Veraviewepocs 2D',
    },
    {
      label: formatMessage(machineTypes.moritaVeraviewepocs3DR100),
      value: 'Morita Veraviewepocs 3D R100',
    },
    {
      label: formatMessage(machineTypes.orangedentalGreenX),
      value: 'Orangedental Green X',
    },
    {
      label: formatMessage(machineTypes.orangedentalPaXi),
      value: 'Orangedental PaX-i',
    },
    {
      label: formatMessage(machineTypes.orangedentalPaXi3D),
      value: 'Orangedental PaX-i3D',
    },
    {
      label: formatMessage(machineTypes.planmecaProMax2DS2),
      value: 'Planmeca ProMax 2D S2',
    },
    {
      label: formatMessage(machineTypes.planmecaProMax2DS3),
      value: 'Planmeca ProMax 2D S3',
    },
    {
      label: formatMessage(machineTypes.planmecaProOne),
      value: 'Planmeca ProOne',
    },
    {
      label: formatMessage(machineTypes.planmecaVisoG5),
      value: 'Planmeca Viso® G5',
    },
    {
      label: formatMessage(machineTypes.planmecaVisoG7),
      value: 'Planmeca Viso® G7',
    },
    {
      label: formatMessage(machineTypes.preXion3DExplorerPro),
      value: 'PreXion 3D Explorer Pro',
    },
    {
      label: formatMessage(machineTypes.sironaAxeos),
      value: 'Sirona Axeos',
    },
    {
      label: formatMessage(machineTypes.sironaOrthophosE),
      value: 'Sirona Orthophos E',
    },
    {
      label: formatMessage(machineTypes.sironaOrthophosS2D),
      value: 'Sirona Orthophos S 2D',
    },
    {
      label: formatMessage(machineTypes.sironaOrthophosS3D),
      value: 'Sirona Orthophos S 3D',
    },
    {
      label: formatMessage(machineTypes.sironaOrthophosSL2D),
      value: 'Sirona Orthophos SL 2D',
    },
    {
      label: formatMessage(machineTypes.sironaOrthophosSL3D),
      value: 'Sirona Orthophos SL 3D',
    },
    {
      label: formatMessage(machineTypes.sironaOrthophosXG),
      value: 'Sirona Orthophos XG',
    },
    {
      label: formatMessage(machineTypes.instrumentarium),
      value: 'Instrumentarium',
    },
    {
      label: formatMessage(machineTypes.sironaGalileos),
      value: 'Sirona Galileos',
    },
    {
      label: formatMessage(machineTypes.newTomGianoHR),
      value: 'New Tom Giano HR',
    },
    {
      label: formatMessage(machineTypes.newTomGo),
      value: 'New Tom Go',
    },
    {
      label: formatMessage(machineTypes.newTom4G),
      value: 'New Tom 4G',
    },
    {
      label: formatMessage(machineTypes.newTom5G),
      value: 'New Tom 5G',
    },
    {
      label: formatMessage(machineTypes.newTom6G),
      value: 'New Tom 6G',
    },
    {
      label: formatMessage(machineTypes.newTom7G),
      value: 'New Tom 7G',
    },
    {
      label: formatMessage(machineTypes.newTomVgi),
      value: 'New Tom Vgi',
    },
    {
      label: formatMessage(machineTypes.myrayHyperionX9pro),
      value: 'Myray Hyperion X9 pro',
    },
    {
      label: formatMessage(machineTypes.myrayHyperionX5pro),
      value: 'Myray Hyperion X5 pro',
    },
    {
      label: formatMessage(machineTypes.vaTechGreenCT2),
      value: 'VaTech Green CT2',
    },
    {
      label: formatMessage(machineTypes.vaTechGreenX),
      value: 'VaTech Green X',
    },
    {
      label: formatMessage(machineTypes.vaTechGreenX12),
      value: 'VaTech Green X12',
    },
    {
      label: formatMessage(machineTypes.vaTechSmartPlus),
      value: 'VaTech Smart Plus',
    },
  ];

  machineTypeOptions.sort((a, b) => a.label.localeCompare(b.label));

  machineTypeOptions.push({
    label: formatMessage({ id: 'global.other', defaultMessage: 'Other' }),
    value: 'Other',
  });

  return machineTypeOptions;
};

import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppSelector, useMouseCoords } from '@/shared/hooks';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { Mask2DConfigCodes, MaskGroup } from '@/shared/config';

import {
  getMaskLabelID,
  MASK_TOOLTIP_X_SHIFT,
  MASK_TOOLTIP_Y_SHIFT,
  maskFiltersModel,
} from '@/features/renderMasks';

import styles from './MaskTooltip.module.scss';

type MaskTooltipProps = {
  className?: string;
};

export const MaskTooltip: FC<MaskTooltipProps> = (props) => {
  const { className } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  const [wrapperCoordinates, setWrapperCoordinates] = useState({
    top: 0,
    left: 0,
  });
  const [hoveredMaskID, setHoveredMaskID] = useState<string | undefined>(
    undefined,
  );

  const { x: mouseX, y: mouseY } = useMouseCoords();

  const masks = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );

  const isRenderRunning = MedicalImageRender.isRunning();

  useEffect(() => {
    const updateHoveredMaskID = ({
      localizationID,
    }: {
      localizationID?: string;
    }) => {
      setHoveredMaskID(localizationID);
    };

    if (MedicalImageRender.isRunning()) {
      MedicalImageRender.addEventListener('hoverMask', updateHoveredMaskID);
    }

    return () => {
      MedicalImageRender.removeEventListener('hoverMask', updateHoveredMaskID);
    };
  }, [isRenderRunning, MedicalImageRender]);

  const updateWrapperCoordinates = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();

      setWrapperCoordinates({ top: rect.top, left: rect.left });
    }
  };

  useEffect(() => {
    updateWrapperCoordinates();

    window.addEventListener('resize', updateWrapperCoordinates);

    return () => {
      window.removeEventListener('resize', updateWrapperCoordinates);
    };
  }, [wrapperRef.current]);

  //
  //
  // Don't use hooks below, because they are not working
  if (!hoveredMaskID || !masks) {
    return null;
  }

  const hoveredMask = masks.find(
    (mask) => mask.localizationID === hoveredMaskID,
  );

  const codes = hoveredMask?.meta?.codes as Mask2DConfigCodes;
  const groupID = hoveredMask?.groupID || 'chart';

  // TODO: remove when add inactive mask type
  // if (groupID === 'chart') {
  //   return null;
  // }

  const buttonColors = {
    perio: styles.maskFilterPerio,
    restorative: styles.maskFilterRestorative,
    endo: styles.maskFilterEndo,
    chart: styles.maskFilterChart,
  };

  const labelMessage = getMaskLabelID(codes);

  const text = labelMessage && formatMessage(labelMessage);

  const stylePosition: React.CSSProperties = {
    top: mouseY - wrapperCoordinates.top + MASK_TOOLTIP_Y_SHIFT,
    left: mouseX - wrapperCoordinates.left + MASK_TOOLTIP_X_SHIFT,
  };

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
      onMouseMove={(e) => {
        setWrapperCoordinates({ top: e.clientY, left: e.clientX });
      }}
    >
      <div
        style={stylePosition}
        className={cn(
          styles.container,
          className,
          buttonColors[groupID as MaskGroup],
        )}
      >
        {text}
      </div>
    </div>
  );
};

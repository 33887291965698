import React, { FC } from 'react';
import cn from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';
import { useSearchParams } from 'react-router-dom';

import styles from './TabGroup.module.scss';

type TabGroupSize = 'normal' | 'small';

type TabBarExtraMap = {
  left?: React.ReactNode;
  right?: React.ReactNode;
};

export type TabItem = {
  label: React.ReactNode;
  value: string;
  content: React.ReactNode;
};

export type TabGroupProps = {
  className?: string;
  extraContentRightClassName?: string;
  testID?: string;
  tabName: string;
  defaultTabID?: string;
  orientation?: 'vertical' | 'horizontal';
  size?: TabGroupSize;
  tabItems: TabItem[];
  extraContent?: React.ReactNode | TabBarExtraMap;
  onTabChange?: (id: string) => void;
};

const tabGroupSize: Record<TabGroupSize, string> = {
  normal: cn(styles.sizeNormal, 'p1'),
  small: cn(styles.sizeSmall, 'p3'),
};

export const TabGroup: FC<TabGroupProps> = (props) => {
  const {
    className,
    extraContentRightClassName,
    testID,
    defaultTabID,
    tabName,
    orientation = 'vertical',
    size = 'normal',
    tabItems,
    extraContent,
    onTabChange,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = searchParams.get(tabName);

  const defaultTab = defaultTabID || tabItems[0].value;

  let extraContentMap: TabBarExtraMap = {};

  // Parse extra
  if (typeof extraContent === 'object' && !React.isValidElement(extraContent)) {
    extraContentMap = extraContent as TabBarExtraMap;
  } else {
    extraContentMap.right = extraContent;
  }

  const updateQueryString = (value: string): void => {
    searchParams.set(tabName, value);
    setSearchParams(searchParams);
  };

  const handleTabChange = (value: string) => {
    if (typeof onTabChange === 'function') {
      onTabChange(value);
    }
    updateQueryString(value);
  };

  return (
    <div
      className={cn(styles.container, className)}
      data-testid={testID}
      data-tabname={tabName}
    >
      <Tabs.Root
        defaultValue={selectedTab || defaultTab}
        orientation={orientation}
        className={styles.groupTab}
        onValueChange={(newValue) => {
          handleTabChange(newValue);
        }}
      >
        <div className={styles.tabButtonsWrapper}>
          {extraContentMap?.left}
          <Tabs.List
            aria-label={`${tabName} tabs`}
            className={cn(
              styles.tabsList,
              size === 'normal' && styles.sizeNormal,
              size === 'small' && styles.sizeSmall,
            )}
          >
            {tabItems.map((tab) => (
              <Tabs.Trigger
                value={tab.value}
                className={cn(styles.tab, tabGroupSize[size])}
                key={`label_${tab.value}`}
              >
                {tab.label}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {extraContentMap?.right && (
            <div
              className={cn(
                styles.extraContentRight,
                extraContentRightClassName,
              )}
            >
              {extraContentMap?.right}
            </div>
          )}
        </div>
        {tabItems.map((tab) => (
          <Tabs.Content
            value={tab.value}
            className={styles.tabContent}
            key={`content_${tab.value}`}
          >
            {tab.content}
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </div>
  );
};

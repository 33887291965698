export const modules = {
  toolbar: [
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { header: [false, 1, 2, 3, 4, 5, 6] },
      { list: 'ordered' },
      { list: 'bullet' },
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
      'link',
      'image',
      { indent: '-1' },
      { indent: '+1' },
    ],
  ],
};

export const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'header',
  'list',
  'bullet',
  'align',
  'link',
  'image',
  'indent',
];

export const tagsRegex = /<[a-z][a-z0-9]*>/i;

export const isJsonRegex =
  /\\{\\*?\\\\[^{}]+}|[{}]|\\\\\\n?[A-Za-z]+\\n?(?:-?\\d+)?[ ]?/;

import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { sortConditionsByGroupOrder } from '../lib/sortConditionsByGroupOrder';

export const conditionAdapter = createEntityAdapter<Condition>({
  selectId: (condition: Condition) => condition.ID,
  sortComparer: sortConditionsByGroupOrder,
});

type ConditionInitialState = DefaultInitialState;

const initialState: ConditionInitialState = {
  loading: 'idle',
};
// TODO: refactor conditionSlice for new slice
const conditionSlice = createSlice({
  name: SliceName.condition,
  initialState: conditionAdapter.getInitialState(initialState),
  reducers: {
    addOne: conditionAdapter.addOne,
    addMany: conditionAdapter.addMany,
    setNewestOne: (state, action: PayloadAction<Condition>) => {
      if (action.payload.Deleted?.Deleted) {
        conditionAdapter.removeOne(state, action.payload.ID);
      }

      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        conditionAdapter.setOne(state, action.payload);
      }
    },
    setNewestMany: (state, action: PayloadAction<Condition[]>) => {
      const items = action.payload.filter((item) => {
        const currentRevisionNumber =
          state.entities[item.ID]?.Revision?.Number ?? 0;
        const payloadRevisionNumber = item.Revision?.Number ?? 0;

        return payloadRevisionNumber > currentRevisionNumber;
      });

      conditionAdapter.setMany(state, items);
    },
    setMany: conditionAdapter.setMany,
    removeOne: conditionAdapter.removeOne,
    removeAll: conditionAdapter.removeAll,
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    reset: () => conditionAdapter.getInitialState(initialState),
  },
});

export const { actions } = conditionSlice;

export default conditionSlice.reducer;

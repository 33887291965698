import { MEDICAL_IMAGE_MAX_SIZE } from '../config/constants';
import { MedicalImageSizeType } from '../config/types';

export const getMedicalImageSize = (
  width: number,
  height: number,
): MedicalImageSizeType => {
  // Determine if the image is horizontal (wider than tall)
  const hasHorizontalImage: boolean = width > height;

  // Calculate the aspect ratio of the image
  const aspectRatio: number = hasHorizontalImage
    ? height / width
    : width / height;

  const imageWidth = hasHorizontalImage
    ? MEDICAL_IMAGE_MAX_SIZE
    : MEDICAL_IMAGE_MAX_SIZE * aspectRatio;

  const imageHeight = hasHorizontalImage
    ? MEDICAL_IMAGE_MAX_SIZE * aspectRatio
    : MEDICAL_IMAGE_MAX_SIZE;

  // Return an object with the width and height of the image, depending on the screen orientation
  return {
    width: imageWidth,
    height: imageHeight,
  };
};

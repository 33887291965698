import { MIRenderImageInterface } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import {
  FMXMatrixPartition,
  GroupedIOXRayImages,
  IOXrayImageInterface,
} from '@/entities/IOXRayImagesMatrix';

import { convertToMIRenderInterface } from './convertToMIRenderInterface';

export const convertGroupedIOXRayImagesToMIRenderImageInterface = (
  groupedImages: GroupedIOXRayImages,
): Record<FMXMatrixPartition, MIRenderImageInterface[]> => {
  const result: Record<FMXMatrixPartition, MIRenderImageInterface[]> =
    {} as Record<FMXMatrixPartition, MIRenderImageInterface[]>;

  for (const [partition, images] of Object.entries(groupedImages) as [
    FMXMatrixPartition,
    IOXrayImageInterface[],
  ][]) {
    result[partition] = images.map(convertToMIRenderInterface);
  }

  return result;
};

import type { AnyAction, Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import type { ApiError } from '@/shared/api/api';
import {
  IGNORE_INTERNAL_SERVER_ERROR_SERVICES,
  INLINE_ERROR_CODES,
} from '@/shared/config';
import { type ThunkGeneratorRejectedValuePayload } from '@/shared/lib';

import { errorToastCaller } from '@/features/errorToastCaller';

export const errorMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
      const {
        type: errorType,
        message: errorMessage,
        apiService,
      } = action.payload as ThunkGeneratorRejectedValuePayload;

      // NOTE: Do not show toast for internal server errors of specific services.
      // For example for all hubspot unexpected errors.
      const ignoreInternalServerError =
        errorType === 'InternalServerError' &&
        IGNORE_INTERNAL_SERVER_ERROR_SERVICES.includes(apiService);

      // eslint-disable-next-line no-console
      console.warn(
        `Receive api error: ${errorMessage}, error type: ${errorType}`,
      );

      if (
        !INLINE_ERROR_CODES.includes(JSON.parse(errorMessage).code) &&
        !ignoreInternalServerError
      ) {
        errorToastCaller(action.payload as ApiError);
      }
    }

    return next(action);
  };

import { ReactNode } from 'react';
import cn from 'classnames';

import styles from './Avatar.module.scss';

type AvatarGroupProps = {
  children?: ReactNode;
  className?: string;
  testID?: string;
};

export const AvatarGroup = ({
  children,
  className,
  testID,
}: AvatarGroupProps) => (
  <div className={cn(styles.avatarGroup, className)} data-testid={testID}>
    {children}
  </div>
);

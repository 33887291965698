import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';

import { ToothWithLocalization } from '@/entities/tooth';

export interface JawQuartilesRanges {
  [key: string]: number[];
}

export enum TabValue {
  All = 'All',
  OneByOne = 'OneByOne',
}

export type TeethNumberingModalProps = {
  title: string;
  patientID: string;
  reportID: string;
  onClose?: () => void;
  onConfirm?: () => void;
  modalWidth?: number;
  // TODO: Refactor this modal
  // This is a fast solution to fix opening this modal in the GpReport page
  reportImage?: {
    src: string;
    width?: number;
    height?: number;
    path?: string;
    viewOptions?: {
      sharpness: number;
      invert: boolean;
      wwwc: { ww: number; wc: number };
    };
    zoom?: number;
  };
};

export type ChangeToothNumberPopupProps = {
  isOpen: boolean;
  title: string;
  isToothRemoving: boolean;
  onChangeSelectedTooth: (tooth?: ToothWithLocalization) => void;
  onChangeToothNumber: (
    tooth: ToothWithLocalization,
    toothNumber: number,
  ) => void;
  selectedTooth?: ToothWithLocalization;
  dentalNotationFormat: DentalNotationFormat;
  onRemoveTooth?: (tooth: ToothWithLocalization) => void;
  animatedToothNumber?: number;
};

export type NumberingTeethProps = {
  roiTeeth: ToothWithLocalization[];
  image: {
    src: string;
    width?: number;
    height?: number;
    path?: string;
    viewOptions?: {
      sharpness: number;
      invert: boolean;
      wwwc: { ww: number; wc: number };
    };
    zoom?: number;
  };
  containerSize: { width: number; height: number };
  onToothClick: (tooth: ToothWithLocalization) => void;
  dentalNotationFormat: DentalNotationFormat;
};

export type FMXTeethProps = {
  title?: string;
  onClose?: () => void;
};

export enum JawQuartiles {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  topLeftPrimary = 'topLeftPrimary',
  topRightPrimary = 'topRightPrimary',
  bottomLeftPrimary = 'bottomLeftPrimary',
  bottomRightPrimary = 'bottomRightPrimary',
}

export type JawQuartileName = keyof typeof JawQuartiles;

export type JawQuartileProps = {
  onToothClick?: (id: string) => void;
  className?: string;
};

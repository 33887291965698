import { useLocation, useNavigate } from 'react-router';
import { useMemo } from 'react';

import { useAppDispatch } from '@/shared/hooks';
import { LocalStorageKey, PATHS } from '@/shared/config';
import { AuthState_AuthStatus } from '@/shared/api/protocol_gen/api/auth/dto_auth';

import { authModel } from '@/entities/auth';

import { FinishRegistrationMethod } from '../config/constants';

export const useFinishRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  // type casting is necessary for typification location.state
  const invitationToken = (location?.state as { invitationToken: string })
    ?.invitationToken;

  const isInviteFromSalesman = (
    location?.state as { isInviteFromSalesman: string }
  )?.isInviteFromSalesman;

  const authenticationMethod = useMemo(() => {
    if (isInviteFromSalesman) {
      return FinishRegistrationMethod.signUpViaSalesman;
    }

    return FinishRegistrationMethod.confirmPassword;
  }, [isInviteFromSalesman]);

  const confirmPasswordHandle = async (password: string) => {
    try {
      const confirmPasswordResponse = await dispatch(
        authModel.thunks.confirmPassword({ Password: password }),
      ).unwrap();

      if (confirmPasswordResponse.Status === AuthState_AuthStatus.SUCCESSFUL) {
        localStorage.removeItem(LocalStorageKey.signUpFormData);
        localStorage.removeItem(LocalStorageKey.UTMParameters);

        navigate(PATHS.additionalInfo);
      }
    } catch (error) {
      // TODO: [1/l]  Investigate reason of this behaviour
      // We need a catch for useForm's isSubmitting to work correctly
    }
  };

  const signUpViaSalesmanHandle = async (password: string) => {
    try {
      const signUpViaSalesmanResponse = await dispatch(
        authModel.thunks.SignUpViaSalesmanInvite({
          InviteToken: invitationToken,
          Password: password,
        }),
      ).unwrap();

      if (
        signUpViaSalesmanResponse.Status === AuthState_AuthStatus.SUCCESSFUL
      ) {
        navigate(PATHS.companySettings);
      }
    } catch (error) {
      // TODO: [1/l]  Investigate reason of this behaviour
      // We need a catch for useForm's isSubmitting to work correctly
    }
  };

  return {
    authenticationMethod,
    isInviteFromSalesman,
    confirmPasswordHandle,
    signUpViaSalesmanHandle,
  };
};

import { FC } from 'react';
import cn from 'classnames';

import { Avatar, Icon } from '@/shared/ui';
import { OrganizationUserRole } from '@/shared/api/protocol_gen/model/dto_organization';

import { useDoctorDetails } from '@/entities/organization/hooks/useDoctorDetails';

import styles from './DoctorRow.module.scss';

type DoctorRowProps = {
  doctor: OrganizationUserRole;
  className?: string;
  description?: JSX.Element | string;
  disabled?: boolean;
  onShare?: () => void;
  onDelete?: () => void;
};

export const DoctorRow: FC<DoctorRowProps> = (props) => {
  const { doctor, className, description, disabled, onShare, onDelete } = props;

  const { doctorFullName, doctorInitials, doctorAvatarSrc } =
    useDoctorDetails(doctor);

  return (
    <div
      className={cn(styles.container, disabled && styles.disabled, className)}
    >
      <Avatar
        src={doctorAvatarSrc}
        alt={doctorFullName}
        initials={doctorInitials}
      />
      <div className={styles.content}>
        <h5 className={cn(styles.title, 'p2')}>{doctorFullName}</h5>
        <p className={cn(styles.description, 'p3')}>{description}</p>
      </div>
      <div className={styles.controls}>
        {onShare ? (
          <button type="button" className={styles.button} onClick={onShare}>
            <Icon name="share" size={32} />
          </button>
        ) : null}
        {onDelete ? (
          <button type="button" className={styles.button} onClick={onDelete}>
            <Icon name="delete" size={32} />
          </button>
        ) : null}
      </div>
    </div>
  );
};

import { defineMessages } from 'react-intl';

import { OrganizationUserRoleType } from '@/shared/api/protocol_gen/model/dto_organization';
import { OrganizationJobPosition } from '@/shared/api/protocol_gen/model/dto_organization_job_position';

export const specialityType = defineMessages<OrganizationJobPosition>({
  [OrganizationJobPosition.OrganizationJobPositionInvalidValue]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionInvalidValue',
    defaultMessage: 'Invalid Value',
  },
  [OrganizationJobPosition.OrganizationJobPositionChiefDentalOfficer]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionChiefDentalOfficer',
    defaultMessage: 'Chief Dental Officer',
  },
  [OrganizationJobPosition.OrganizationJobPositionTreatmentCoordinator]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionTreatmentCoordinator',
    defaultMessage: 'Treatment Coordinator',
  },
  [OrganizationJobPosition.OrganizationJobPositionDentalAssistant]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionDentalAssistant',
    defaultMessage: 'Dental Assistant',
  },
  [OrganizationJobPosition.OrganizationJobPositionDentalTechnician]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionDentalTechnician',
    defaultMessage: 'Dental Technician',
  },
  [OrganizationJobPosition.OrganizationJobPositionGeneralDentist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionGeneralDentist',
    defaultMessage: 'General Dentist',
  },
  [OrganizationJobPosition.OrganizationJobPositionPeriodontist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionPeriodontist',
    defaultMessage: 'Periodontist',
  },
  [OrganizationJobPosition.OrganizationJobPositionProsthodontist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionProsthodontist',
    defaultMessage: 'Prosthodontist',
  },
  [OrganizationJobPosition.OrganizationJobPositionOrthodontist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionOrthodontist',
    defaultMessage: 'Orthodontist',
  },
  [OrganizationJobPosition.OrganizationJobPositionPediatricDentist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionPediatricDentist',
    defaultMessage: 'Pediatric Dentist',
  },
  [OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialSurgeon]:
    {
      id: 'OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialSurgeon',
      defaultMessage: 'Oral And Maxillofacial Surgeon',
    },
  [OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialRadiologist]:
    {
      id: 'OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialRadiologist',
      defaultMessage: 'Oral And Maxillofacial Radiologist',
    },
  [OrganizationJobPosition.OrganizationJobPositionAestheticDentist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionAestheticDentist',
    defaultMessage: 'Aesthetic Dentist',
  },
  [OrganizationJobPosition.OrganizationJobPositionDentalHygienist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionDentalHygienist',
    defaultMessage: 'Dental Hygienist',
  },
  [OrganizationJobPosition.OrganizationJobPositionOther]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionOther',
    defaultMessage: 'Other',
  },
  [OrganizationJobPosition.OrganizationJobPositionOfficeManager]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionOfficeManager',
    defaultMessage: 'Office Manager',
  },
  [OrganizationJobPosition.OrganizationJobPositionEndodontist]: {
    id: 'OrganizationJobPosition.OrganizationJobPositionEndodontist',
    defaultMessage: 'Endodontist',
  },
  [OrganizationJobPosition.UNRECOGNIZED]: {
    id: 'OrganizationJobPosition.UNRECOGNIZED',
    defaultMessage: 'UNRECOGNIZED',
  },
});

export enum PermissionStatuses {
  'Manage company settings' = 'Manage company settings',
  'Manage payments and subscriptions' = 'Manage payments and subscriptions',
  'View and manage staff' = 'View and manage staff',
  'View and manage patients' = 'View and manage patients',
  'Share patients' = 'Share patients',
  'Manage patient’s reports' = 'Manage patient’s reports',
}

export const permissionOwners = defineMessages<OrganizationUserRoleType>({
  [OrganizationUserRoleType.OrganizationUserRoleTypeInvalidValue]: {
    id: 'permissionOwners.InvalidValue',
    defaultMessage: ' ',
  },
  [OrganizationUserRoleType.OrganizationUserRoleTypeOwner]: {
    id: 'permissionOwners.Owner',
    defaultMessage: 'Owner',
  },
  [OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader]: {
    id: 'permissionOwners.Leader',
    defaultMessage: 'Clinical Leader',
  },
  [OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor]: {
    id: 'permissionOwners.Doctor',
    defaultMessage: 'Treating Doctor',
  },
  [OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember]: {
    id: 'permissionOwners.member',
    defaultMessage: 'Non-clinical Member',
  },
  [OrganizationUserRoleType.UNRECOGNIZED]: {
    id: 'permissionOwners.Unrecognized',
    defaultMessage: ' ',
  },
});

export const permissionOwnersComments =
  defineMessages<OrganizationUserRoleType>({
    [OrganizationUserRoleType.OrganizationUserRoleTypeInvalidValue]: {
      id: 'permissionComments.InvalidValue',
      defaultMessage: ' ',
    },
    [OrganizationUserRoleType.OrganizationUserRoleTypeOwner]: {
      id: 'permissionComments.Owner',
      defaultMessage: ' ',
    },
    [OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader]: {
      id: 'permissionComments.Leader',
      defaultMessage: 'Manage team members and patients',
    },
    [OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor]: {
      id: 'permissionComments.Doctor',
      defaultMessage: 'Manage patients and patients’ reports',
    },
    [OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember]: {
      id: 'permissionComments.member',
      defaultMessage: 'View and manage patients',
    },
    [OrganizationUserRoleType.UNRECOGNIZED]: {
      id: 'permissionComments.Unrecognized',
      defaultMessage: ' ',
    },
  });

export const permissionDescriptions = defineMessages<PermissionStatuses>({
  [PermissionStatuses['Manage company settings']]: {
    id: 'permissionDescriptions.manageCompanySettings',
    defaultMessage: 'Manage company settings',
  },
  [PermissionStatuses['Manage payments and subscriptions']]: {
    id: 'permissionDescriptions.managePaymentsAndSubscriptions',
    defaultMessage: 'Manage payments and subscriptions',
  },
  [PermissionStatuses['View and manage staff']]: {
    id: 'permissionDescriptions.viewAndManageStaff',
    defaultMessage: 'View and manage staff',
  },
  [PermissionStatuses['View and manage patients']]: {
    id: 'permissionDescriptions.viewAndManagePatients',
    defaultMessage: 'View and manage patients',
  },
  [PermissionStatuses['Share patients']]: {
    id: 'permissionDescriptions.sharePatients',
    defaultMessage: 'Share patients',
  },
  [PermissionStatuses['Manage patient’s reports']]: {
    id: 'permissionDescriptions.managePatientsReports',
    defaultMessage: 'Manage patient’s reports',
  },
});

import { FC, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Icon, Tooltip, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector, useModal } from '@/shared/hooks';
import { Invitation } from '@/shared/api/protocol_gen/model/dto_access';

import { accessModel } from '@/entities/access';
import { modalModel, ModalID } from '@/entities/modal';
import { getPatientYourPermissions, patientModel } from '@/entities/patient';

import {
  RevokeSharedPatientModal,
  SharePatientModalForm,
} from '@/features/sharePatient';
import { SharedDoctorRow } from '@/features/sharedDoctorRow';
import { ShowAllSharedWithModal } from '@/features/showAllSharedWithList';

import { getShortedList } from '../../utils/getShortedList';
import { MAX_ROWS_COUNT } from '../../config/constants';

import styles from './SharedWith.module.scss';

type SharedWithProps = {
  className?: string;
  patientID: string;
};

export const SharedWith: FC<SharedWithProps> = (props) => {
  const { className, patientID } = props;
  const dispatch = useAppDispatch();

  const acceptedOrPendingSharedPatientInvitations = useAppSelector(
    accessModel.selectors.selectAcceptedOrPendingSharedPatientInvitations,
  );

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const {
    isOpen: isShowAllModalOpen,
    openModal: openShowAllModal,
    closeModal: closeShowAllModal,
  } = useModal();

  const shortedSharedWithList = getShortedList(
    acceptedOrPendingSharedPatientInvitations,
  ) as Invitation[];

  const { CanCancelOthersSharings, CanViewSharings, CanShare } =
    getPatientYourPermissions(patient);

  const openSharePatientFormModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.SharePatient,
        data: { patientID },
      }),
    );
  }, [dispatch, patientID]);

  const sharedPatientInvitationsQuantity =
    !!acceptedOrPendingSharedPatientInvitations.length;

  const shouldShowAllAppear =
    acceptedOrPendingSharedPatientInvitations.length > MAX_ROWS_COUNT;

  if (!CanViewSharings) {
    return null;
  }

  return (
    <>
      <WidgetCard className={cn(styles.container, className)}>
        <div className={styles.header}>
          <h4 className={cn(styles.title, 'h4')}>
            <FormattedMessage
              id="patientInfo.sharedWith"
              defaultMessage="Shared with"
            />
          </h4>

          {CanShare && (
            <Tooltip.Primary
              content={
                <FormattedMessage
                  id="patientInfo.sharePatient"
                  defaultMessage="Share"
                />
              }
            >
              <Icon
                name="boldShare"
                className={styles.icon}
                onClick={openSharePatientFormModal}
                size={32}
              />
            </Tooltip.Primary>
          )}
        </div>

        {sharedPatientInvitationsQuantity ? (
          <div className={styles.sharedList}>
            {shortedSharedWithList.map((sharedPatientInvitation) => (
              <SharedDoctorRow
                key={sharedPatientInvitation?.ID}
                sharedPatientInvitation={sharedPatientInvitation}
                canCancelSharing={CanCancelOthersSharings}
                openShowAllModal={openShowAllModal}
                lessInfo
              />
            ))}
          </div>
        ) : (
          <div className={styles.emptyList}>
            <Icon className={styles.emptyListIcon} name="users" size={64} />
          </div>
        )}

        {shouldShowAllAppear && (
          <Button variant="tertiary" size="medium" onClick={openShowAllModal}>
            <FormattedMessage
              id="patientInfo.showAll"
              defaultMessage="Show all"
            />
          </Button>
        )}
      </WidgetCard>

      <SharePatientModalForm />

      <RevokeSharedPatientModal />

      <ShowAllSharedWithModal
        isOpen={isShowAllModalOpen}
        onClose={closeShowAllModal}
        invitations={acceptedOrPendingSharedPatientInvitations}
        canCancelSharing={CanCancelOthersSharings}
      />
    </>
  );
};

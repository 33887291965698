import { InvoiceStatuses } from '../config/type';

export const getInvoiceStatus = (
  paid: boolean,
  overdue: boolean,
): InvoiceStatuses => {
  if (paid) {
    return 'paid';
  }

  if (overdue) {
    return 'overdue';
  }

  return 'new';
};

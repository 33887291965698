import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';

import { MPRBase } from '@/shared/graphics/MPRBase/MPRBase';
import { Button, Switch } from '@/shared/ui';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';

import { useCheckReportSignature } from '@/entities/reports';

import { useUploadAssetContext } from '@/features/uploadAsset';

import { MPR, MPRInputMode } from 'graphics';

import { MPRControlTools } from '../MPRControlTools/MPRControlTools';

import styles from './MPRWidget.module.scss';

type MPRWidgetProps = {
  mprUrl: string;
  toothID: string;
  className?: string;
  initialWw?: number;
  initialWc?: number;
};

const cursorStyle = {
  normal: styles.move,
  brightness: styles.brightness,
  pan: styles.pan,
  zoom: styles.zoom,
  depth: styles.brightness,
  default: styles.noScroll,
};

export const MPRWidget: FC<MPRWidgetProps> = (props) => {
  const { className, mprUrl, initialWw, initialWc, toothID } = props;

  const { formatMessage } = useIntl();

  const [mpr, setMpr] = useState<MPR>();
  const [inputMode, setInputMode] = useState<MPRInputMode>('normal');
  const [zoomSynced, setZoomSynced] = useState<boolean>(true);
  const [axesVisible, setAxesVisible] = useState<boolean>(true);

  const { startUploadAsset } = useUploadAssetContext();

  const ref = useRef<HTMLCanvasElement>(null);
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);

  const { checkReportSignature } = useCheckReportSignature();

  const uploadAsset = useCallback(
    async (slice: FileWithPath[]) => {
      await startUploadAsset(
        {
          files: slice,
          assetType: AssetType.AssetType_Report_Uploaded_MPRPlane,
          toothID,
        },
        true,
      );
    },
    [toothID, startUploadAsset],
  );

  const handleControlClick = useCallback(
    (control: MPRInputMode | 'reset') => {
      if (!mpr) return;

      if (control === 'reset') {
        mpr.reset();
        mpr.ww = mpr.defaultWW;
        mpr.wc = mpr.defaultWC;
      } else {
        setInputMode(control);
      }
    },
    [mpr],
  );
  const handleAddToReport = (context: 0 | 1 | 2) => {
    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        if (mpr) {
          mpr.makeScreenshot((blob) => {
            if (blob) {
              const slice = new File([blob], `${Date.now()}.png`, {
                type: 'image/png',
              });

              uploadAsset([slice]);
            }
          }, context);
        }
      },
    });
  };

  const onMouseEvent = useCallback(
    (e: SyntheticEvent<HTMLElement>) => {
      ref.current?.dispatchEvent(
        new MouseEvent(e.nativeEvent.type, e.nativeEvent),
      );
    },
    [ref],
  );

  useEffect(() => {
    if (mpr) {
      mpr.inputMode = inputMode as MPRInputMode;
      mpr.axesVisible = axesVisible;
      mpr.zoomSynced = zoomSynced;
    }
  }, [mpr, inputMode, zoomSynced, axesVisible]);

  useEffect((): (() => void) => {
    if (ref1.current == null || ref2.current == null || ref3.current == null)
      return () => {};

    const onWheel = (e: WheelEvent): void => {
      e.stopPropagation();
      e.preventDefault();
      ref.current?.dispatchEvent(new WheelEvent(e.type, e));
    };

    ref1.current.addEventListener('wheel', onWheel, { passive: false });
    ref2.current.addEventListener('wheel', onWheel, { passive: false });
    ref3.current.addEventListener('wheel', onWheel, { passive: false });

    return (): void => {
      if (ref1.current == null || ref2.current == null || ref3.current == null)
        return;

      ref1.current.removeEventListener('wheel', onWheel);
      ref2.current.removeEventListener('wheel', onWheel);
      ref3.current.removeEventListener('wheel', onWheel);
    };
  }, []);

  return (
    <div className={className}>
      <div className={styles.controls}>
        <MPRControlTools
          activeTool={inputMode}
          setActiveTool={handleControlClick}
        />
        <div className={styles.extendedControls}>
          {inputMode === 'zoom' && (
            <Switch
              onChange={() => setZoomSynced(!zoomSynced)}
              checked={zoomSynced}
            >
              <FormattedMessage
                id="mpr.tools.zoomSynced"
                defaultMessage="Zoom synced"
              />
            </Switch>
          )}
          <Switch
            onChange={() => setAxesVisible(!axesVisible)}
            checked={axesVisible}
          >
            <FormattedMessage
              id="mpr.tools.axesVisible"
              defaultMessage="Axes"
            />
          </Switch>
        </div>
      </div>

      <div className={cn(styles.mprViewer, cursorStyle[inputMode])}>
        <MPRBase
          ref={ref}
          nrrdUrl={mprUrl}
          initialWw={initialWw}
          initialWc={initialWc}
          onMPRReady={(mprInstance) => setMpr(mprInstance)}
        />
        <div
          ref={ref1}
          className={styles.mprSlice}
          onMouseDown={onMouseEvent}
          onMouseMove={onMouseEvent}
          onMouseUp={onMouseEvent}
        >
          <Button
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleAddToReport(0);
            }}
          >
            {formatMessage({
              id: 'mpr.button.addToReport',
              defaultMessage: 'Add to report',
            })}
          </Button>
        </div>

        <div
          ref={ref2}
          className={styles.mprSlice}
          onMouseDown={onMouseEvent}
          onMouseMove={onMouseEvent}
          onMouseUp={onMouseEvent}
        >
          <Button
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleAddToReport(1);
            }}
          >
            {formatMessage({
              id: 'mpr.button.addToReport',
              defaultMessage: 'Add to report',
            })}
          </Button>
        </div>

        <div
          ref={ref3}
          className={styles.mprSlice}
          onMouseDown={onMouseEvent}
          onMouseMove={onMouseEvent}
          onMouseUp={onMouseEvent}
        >
          <Button
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleAddToReport(2);
            }}
          >
            {formatMessage({
              id: 'mpr.button.addToReport',
              defaultMessage: 'Add to report',
            })}
          </Button>
        </div>
      </div>
    </div>
  );
};

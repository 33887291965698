import { ToothIconPathByToothStatus, ToothStatus } from '../config';

export const getToothIconPathByToothStatus = (toothStatus: ToothStatus) => {
  if (toothStatus === ToothStatus.lowProbability) {
    return ToothIconPathByToothStatus.lowProbability;
  }
  if (toothStatus === ToothStatus.unhealthy) {
    return ToothIconPathByToothStatus.unhealthy;
  }
  if (toothStatus === ToothStatus.treated) {
    return ToothIconPathByToothStatus.treated;
  }

  return ToothIconPathByToothStatus.healthy;
};

import { AnnotationEditContext } from './Annotation';
import { CircleHandleHelper } from './CircleHandleHelper';
import { LineProps } from './Line';

type LineHandlesProps = LineProps & {
    onIntentToEdit: (context: AnnotationEditContext) => void;
};

export const LineHandle = ({
    x1,
    y1,
    x2,
    y2,
    onIntentToEdit,
}: LineHandlesProps) => {
    return (
        <>
            <CircleHandleHelper
                x={x1}
                y={y1}
                onDown={() => {
                    onIntentToEdit({ kind: 'line', value: 'dragStartingPoint' });
                }}
            />
            <CircleHandleHelper
                x={x2}
                y={y2}
                onDown={() => {
                    onIntentToEdit({ kind: 'line', value: 'dragEndingPoint' });
                }}
            />
        </>
    );
};

type IdentityProps = {
  email: string;
  options?: {};
};

type HubspotEventsFunctions = {
  setTrackPageView: () => void;
  setIdentity: (identityProps: IdentityProps) => void;
  trackPage: (path: string) => void;
};

export const useHubspotEvents = (): HubspotEventsFunctions => {
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
  const _hsq = typeof window !== 'undefined' && window._hsq ? window._hsq : [];

  const setIdentity = ({ email, options }: IdentityProps) => {
    _hsq.push([
      'identify',
      {
        email,
        ...options,
      },
    ]);
  };

  const setTrackPageView = () => {
    _hsq.push(['trackPageView']);
  };

  const trackPage = (path: string): void => {
    _hsq.push(['setPath', path]);

    setTrackPageView();
  };

  return {
    setIdentity,
    setTrackPageView,
    trackPage,
  };
};

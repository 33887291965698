import { createSelector } from '@reduxjs/toolkit';

import { patientModel } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';

// NOTE: Obtaining IDs of doctors for a specific patient, and using these IDs to retrieve complete data of the doctors within the organization
export const selectAllPatientDoctors = (patientID: string) =>
  createSelector(
    patientModel.selectors.selectPatientDoctorsIDs(patientID),
    organizationModel.selectors.selectAllDoctors,
    (patientDoctorsIDs, allDoctors) => {
      const allPatientDoctors = allDoctors?.filter((doctor) =>
        patientDoctorsIDs?.includes(doctor.UserID),
      );

      return allPatientDoctors;
    },
  );

// NOTE: Obtaining IDs of treating doctors for a specific patient, and using these IDs to retrieve complete data of the doctors within the organization
export const selectPatientThreatingDoctors = (patientID: string) =>
  createSelector(
    patientModel.selectors.selectTreatingDoctorsIDs(patientID),
    organizationModel.selectors.selectTreatingDoctors,
    (patientDoctorsIDs, treatingDoctors) => {
      const patientTreatingDoctors = treatingDoctors?.filter((doctor) =>
        patientDoctorsIDs?.includes(doctor.UserID),
      );

      return patientTreatingDoctors;
    },
  );

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { SliceName } from '@/shared/config';

import { StudyCount } from '../config/types';

export const studyCountAdapter = createEntityAdapter<StudyCount>({
  selectId: (count) => count.patientID,
});

const studyCountSlice = createSlice({
  name: SliceName.studyCount,
  initialState: studyCountAdapter.getInitialState(),
  reducers: {
    setOne: studyCountAdapter.setOne,
    addMany: studyCountAdapter.addMany,
    setMany: studyCountAdapter.setMany,
  },
});

export const { actions } = studyCountSlice;

export default studyCountSlice.reducer;

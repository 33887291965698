import { StylesConfig } from 'react-select';

export const selectStyles: StylesConfig = {
  container: (base, { isDisabled }) => ({
    ...base,
    pointerEvents: 'all',
    fontSize: '18px',
    lineHeight: '24px',
    cursor: isDisabled
      ? "url('@/shared/assets/images/cursors/notAllowed.svg'), not-allowed"
      : 'default',
  }),
  control: (base, { isFocused, isDisabled, selectProps }) => {
    const borderColor = selectProps['aria-invalid']
      ? 'var(--redrose)'
      : 'rgba(var(--bg3-rgb), .2)';

    return {
      ...base,
      pointerEvents: isDisabled ? 'none' : 'all',
      borderRadius: 26,
      borderColor: isFocused ? 'var(--purpleflat)' : borderColor,
      borderWidth: 2,
      boxShadow: 'none',
      cursor: 'pointer',
      color: selectProps['aria-invalid'] ? 'red' : 'green',
      ':hover': {
        color: 'var(--black)',
        borderColor: selectProps['aria-invalid']
          ? 'var(--redrose)'
          : 'var(--purpleflat)',
      },

      // for mobile
      maxHeight: 'calc(100vh - 300px)',
      overflow: 'auto',
    };
  },
  singleValue: (base, { isDisabled, selectProps }) => {
    const valueColor = isDisabled ? 'var(--greytext)' : 'var(--bw)';

    return {
      ...base,
      color: selectProps['aria-invalid'] ? 'var(--redrose)' : valueColor,
    };
  },
  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
    marginRight: '8px',
    color: 'var(--black)',
  }),
  dropdownIndicator: (base, { isDisabled, selectProps }) => {
    const indicatorColor = selectProps['aria-invalid']
      ? 'var(--redrose)'
      : 'var(--bw)';

    return {
      ...base,
      padding: '8px',
      margin: '3px 8px 3px 0',
      color: isDisabled ? 'var(--greytext)' : indicatorColor,
      ':hover': {
        color: 'var(--black)',
      },
    };
  },
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    margin: '11px 8px 11px 16px',
  }),
  placeholder: (base, { selectProps }) => ({
    ...base,
    color: selectProps['aria-invalid'] ? 'var(--redrose)' : 'var(--greytext)',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '24px',
    overflow: 'hidden',
    boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.05);',
  }),
  menuList: (base) => ({
    ...base,
    padding: '0 0 8px 0',
  }),
  option: (base) => ({
    ...base,
    padding: '12px 16px',
    backgroundColor: 'transparent',
    borderRadius: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 18px center',
    wordWrap: 'break-word',
    color: 'var(--bw)',
    ':active': {
      backgroundColor: 'transparent',
    },
    ':hover': {
      boxShadow: 'inset 0 0 0 2px var(--purpleflat)',
    },
    ':not(:last-child)': {
      marginBottom: '4px',
    },
  }),
};

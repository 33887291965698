import { AngleProps } from './Angle';
import { AnnotationEditContext } from './Annotation';
import { CircleHandleHelper } from './CircleHandleHelper';

type AngleHandlesProps = AngleProps & { onIntentToEdit: (context: AnnotationEditContext) => void };
export const AngleHandle = ({ x1, x2, x3, y1, y2, y3, onIntentToEdit }: AngleHandlesProps) => {

  return (
    <>
      <CircleHandleHelper
        x={x1}
        y={y1}
        onDown={() => {
          onIntentToEdit({ kind: 'angle', value: 'dragStartingPoint' });
        }}
      />
      <CircleHandleHelper
        x={x2}
        y={y2}
        onDown={() => {
          onIntentToEdit({ kind: 'angle', value: 'dragMidPoint' });
        }}
      />
      <CircleHandleHelper
        x={x3}
        y={y3}
        onDown={() => {
          onIntentToEdit({ kind: 'angle', value: 'dragEndingPoint' });
        }}
      />
    </>
  );
};

import { useIntl } from 'react-intl';

export const useConfirmRefresh = () => {
  const { formatMessage } = useIntl();
  const confirmRefreshText = formatMessage({
    id: 'confirmRefresh',
    defaultMessage:
      'You have uploading files in progress. Are you sure you want to reload page?',
  });

  const onConfirmRefresh = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = confirmRefreshText;
  };

  const addConfirmRefreshListener = () => {
    window.addEventListener('beforeunload', onConfirmRefresh, {
      capture: true,
    });
  };

  const removeConfirmRefreshListener = () => {
    window.removeEventListener('beforeunload', onConfirmRefresh, {
      capture: true,
    });
  };

  return {
    addConfirmRefreshListener,
    removeConfirmRefreshListener,
  };
};

import { FC } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';

import styles from '../SharedPatientList/SharedPatientList.module.scss';

type RevokeSharedPatientColumnProps = {
  patientID: string;
  sharedPatientDoctorID: string;
  invitationID: string;
  isInviteAccepted: boolean;
  className?: string;
  testID?: string;
};

export const RevokeSharedPatientColumn: FC<RevokeSharedPatientColumnProps> = (
  props,
) => {
  const {
    patientID,
    sharedPatientDoctorID,
    invitationID,
    isInviteAccepted,
    className,
    testID,
  } = props;
  const dispatch = useAppDispatch();

  const openRevokeSharedPatientModal = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.RevokeSharedPatientModal,
        data: {
          patientID,
          sharedPatientDoctorID,
          invitationID,
          isInviteAccepted,
        },
      }),
    );
  };

  return (
    <button
      type="button"
      onClick={openRevokeSharedPatientModal}
      className={cn(styles.deleteButton, className)}
      data-testid={testID}
    >
      <Icon name="delete" size={32} />
    </button>
  );
};

import { useRef, useState } from 'react';
import { Subscription } from 'rxjs';

import { useAppDispatch } from '@/shared/hooks';
import api from '@/shared/api/api';

import { accessModel } from '@/entities/access';

import { SharedWithMeFilterSettingsType } from '../config/types';

export type UseSharedWithMeStreamArgs = {
  organizationID?: string;
  searchValue?: string;
};

export const useSharedWithMeStream = ({
  organizationID,
  searchValue = '',
}: UseSharedWithMeStreamArgs) => {
  // use to restore patients state for infinity scroll
  // must be reseted after any filters update
  const [resumeToken, setResumeToken] = useState('');

  const dispatch = useAppDispatch();

  const sharedWithMeStream = useRef<Subscription>();

  const openSharedWithMeStream = (
    filterSettings: SharedWithMeFilterSettingsType = {
      searchString: searchValue,
      id: '',
    },
  ) => {
    const { searchString, id, sortBy } = filterSettings;

    dispatch(accessModel.actions.setLoading('pending'));

    sharedWithMeStream.current = api.access
      .SharedWithMeStream({
        OrganizationID: organizationID,
        ResumeToken: resumeToken,
        SearchString: searchString,
        Sort: sortBy,
        StartFromInvitationID: id,
        Limit: 20,
      })
      .subscribe({
        next: (data) => {
          const {
            InitialInvitationsList,
            InvitationUpdated,
            ResumeToken,
            TotalOrganizationPatientCount,
            SharedByMeCount,
            SharedWithMeCount,
          } = data;

          if (InitialInvitationsList?.Invitations) {
            dispatch(
              accessModel.actions.addMany(InitialInvitationsList.Invitations),
            );

            dispatch(accessModel.actions.setLoading('succeeded'));
          } else if (TotalOrganizationPatientCount) {
            dispatch(
              accessModel.actions.setTotalOrganizationPatientCount(
                TotalOrganizationPatientCount,
              ),
            );
          } else if (SharedByMeCount) {
            dispatch(accessModel.actions.setSharedByMeCount(SharedByMeCount));
          } else if (SharedWithMeCount) {
            dispatch(
              accessModel.actions.setSharedWithMeCount(SharedWithMeCount),
            );
          } else if (InvitationUpdated) {
            dispatch(accessModel.actions.setNewestOne(InvitationUpdated));
          } else if (ResumeToken) {
            setResumeToken(ResumeToken);
          }
        },
        complete: () => {},

        error: () => {
          dispatch(accessModel.actions.setLoading('failed'));
        },
      });
  };

  const closeSharedWithMeStream = (shouldClearStore = true) => {
    if (sharedWithMeStream.current) {
      sharedWithMeStream.current.unsubscribe();
    }

    if (shouldClearStore) {
      dispatch(accessModel.actions.removeAll());
    }
  };

  return { openSharedWithMeStream, closeSharedWithMeStream };
};

import { OrganizationUserRoleType } from '@/shared/api/protocol_gen/model/dto_organization';
import { FormatMessageFunction } from '@/shared/config';

import { i18n } from '../config';

export const getOrganizationDoctorsRoles = (
  roles: OrganizationUserRoleType[],
  formatMessage: FormatMessageFunction,
) =>
  roles.map((role: OrganizationUserRoleType) =>
    formatMessage(i18n.permissionOwners[role]),
  );

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { File } from '../model/dto_asset_common';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentReportUserUploadedToothSlice {
  Slice: File | undefined;
}

export interface AssetContentReportUserUploadedMPRPlane {
  MPRPlane: File | undefined;
}

function createBaseAssetContentReportUserUploadedToothSlice(): AssetContentReportUserUploadedToothSlice {
  return { Slice: undefined };
}

export const AssetContentReportUserUploadedToothSlice = {
  encode(
    message: AssetContentReportUserUploadedToothSlice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Slice !== undefined) {
      File.encode(message.Slice, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentReportUserUploadedToothSlice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentReportUserUploadedToothSlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Slice = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentReportUserUploadedToothSlice {
    return {
      Slice: isSet(object.Slice) ? File.fromJSON(object.Slice) : undefined,
    };
  },

  toJSON(message: AssetContentReportUserUploadedToothSlice): unknown {
    const obj: any = {};
    message.Slice !== undefined &&
      (obj.Slice = message.Slice ? File.toJSON(message.Slice) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentReportUserUploadedToothSlice>, I>,
  >(object: I): AssetContentReportUserUploadedToothSlice {
    const message = createBaseAssetContentReportUserUploadedToothSlice();
    message.Slice =
      object.Slice !== undefined && object.Slice !== null
        ? File.fromPartial(object.Slice)
        : undefined;
    return message;
  },
};

function createBaseAssetContentReportUserUploadedMPRPlane(): AssetContentReportUserUploadedMPRPlane {
  return { MPRPlane: undefined };
}

export const AssetContentReportUserUploadedMPRPlane = {
  encode(
    message: AssetContentReportUserUploadedMPRPlane,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.MPRPlane !== undefined) {
      File.encode(message.MPRPlane, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentReportUserUploadedMPRPlane {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentReportUserUploadedMPRPlane();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.MPRPlane = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentReportUserUploadedMPRPlane {
    return {
      MPRPlane: isSet(object.MPRPlane)
        ? File.fromJSON(object.MPRPlane)
        : undefined,
    };
  },

  toJSON(message: AssetContentReportUserUploadedMPRPlane): unknown {
    const obj: any = {};
    message.MPRPlane !== undefined &&
      (obj.MPRPlane = message.MPRPlane
        ? File.toJSON(message.MPRPlane)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentReportUserUploadedMPRPlane>, I>,
  >(object: I): AssetContentReportUserUploadedMPRPlane {
    const message = createBaseAssetContentReportUserUploadedMPRPlane();
    message.MPRPlane =
      object.MPRPlane !== undefined && object.MPRPlane !== null
        ? File.fromPartial(object.MPRPlane)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

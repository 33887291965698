// TODO [8/l] Fix types
// @ts-nocheck
import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Select, SelectProps } from '@/shared/ui';

import { DoctorOptionType } from '../../config/types';

import {
  DoctorMultiValueLabel,
  DoctorMultiValueRemove,
  DoctorOption,
} from './DoctorsSelect.components';
import { doctorsSelectStyles } from './DoctorsSelect.styles';
import styles from './DoctorsSelect.module.scss';

export type DoctorsSelectProps = Pick<
  SelectProps<DoctorOptionType>,
  'value' | 'options' | 'error' | 'onChange'
> & {
  className?: string;
};

export const DoctorsSelect: FC<DoctorsSelectProps> = (props) => {
  const { className, value, options, error, onChange } = props;
  const { formatMessage } = useIntl();

  // TODO [1/l] Why not just use ramda.uniqBy?
  const uniqueOptions = Array.from(
    new Map(options.map((item) => [item.value, item])).values(),
  );

  return (
    <Select
      className={cn(styles.container, className)}
      label={formatMessage({
        id: 'doctorsSelect.label',
        defaultMessage: 'Treating doctor',
      })}
      placeholder={formatMessage({
        id: 'doctorsSelect.placeholder',
        defaultMessage: 'Select doctor',
      })}
      onChange={onChange}
      value={value}
      options={uniqueOptions}
      components={{
        Option: DoctorOption,
        MultiValueLabel: DoctorMultiValueLabel,
        MultiValueRemove: DoctorMultiValueRemove,
      }}
      styles={doctorsSelectStyles}
      error={error}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      isRequired
    />
  );
};

import { FC, ReactNode } from 'react';

import style from './menu.module.scss';

type MenuProps = {
  children: ReactNode;
};

export const Menu: FC<MenuProps> = (props) => {
  const { children } = props;
  return <div className={style.menu}>{children}</div>;
};

import { useIntl } from 'react-intl';
import { useContext } from 'react';

import { useAppSelector } from '@/shared/hooks';

import { toothModel } from '@/entities/tooth';

import { ToothChartContext } from '../ToothChartRoot/ToothChartRoot';

import styles from './ToothChartHeader.module.scss';

type ToothChartHeaderProps = {
  children?: React.ReactNode;
};

export const ToothChartHeader = (props: ToothChartHeaderProps) => {
  const { children } = props;

  const { formatMessage } = useIntl();

  const size = useContext(ToothChartContext);

  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );

  const title = customMode
    ? formatMessage({
        id: 'toothChart.titleCustomMode',
        defaultMessage: 'Select teeth',
      })
    : formatMessage({
        id: 'toothChart.title',
        defaultMessage: 'Teeth in the report',
      });

  return (
    <header className={styles.container}>
      <h2 className={size === 'default' ? 'h4' : 'h5'}>{title}</h2>
      {children}
    </header>
  );
};

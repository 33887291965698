export const calculateImageSize = (
  containerWidth: number,
  containerHeight: number,
  imageOriginalWidth: number,
  imageOriginalHeight: number,
) => {
  const coefficientX = containerWidth / imageOriginalWidth;
  const imageHeight = coefficientX * imageOriginalHeight;

  if (imageHeight < containerHeight) {
    return {
      height: imageHeight,
      width: containerWidth,
      zoom: coefficientX,
    };
  }

  const coefficientY = containerHeight / imageOriginalHeight;
  const imageWidth = coefficientY * imageOriginalWidth;

  return {
    height: containerHeight,
    width: imageWidth,
    zoom: coefficientY,
  };
};

import { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  MedicalImageRender,
  PBLProps,
} from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import {
  IOXRayImagesInterfaceModel,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { organizationModel } from '@/entities/organization';

import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { maskFiltersModel } from '@/features/renderMasks';
import { convertGroupedIOXRayImagesToMIRenderImageInterface } from '@/features/MIRenderInterface';

import styles from './IOXRayReportRender.module.scss';

type IOXRayReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const IOXRayReportRender: FC<IOXRayReportRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  const dispatch = useAppDispatch();

  const reportID = useAppSelector(reportsModel.selectors.selectCurrentReportID);
  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );
  const showPBL = useAppSelector(
    maskFiltersModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );
  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );
  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );
  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID as string),
  );

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  );

  const masksRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  useEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef, 'main');
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      MedicalImageRender.updateLayout(
        convertGroupedIOXRayImagesToMIRenderImageInterface(groupedIOXRayImages),
      );
    }
  }, [groupedIOXRayImages]);

  const isPreviewMode = previewSettings?.isPreview;

  // render initialization
  useEffect(() => {
    if (reportReadyForRender && !!IOXRayImagesInterface.length) {
      performance.mark('InitializeIOXRayRender:start');

      MedicalImageRender.setCredentials(CORS_POLICY);

      const convertedImages =
        convertGroupedIOXRayImagesToMIRenderImageInterface(groupedIOXRayImages);

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID!,
        [
          [
            convertedImages.UpperLeft,
            convertedImages.UpperMiddle,
            convertedImages.UpperRight,
          ],
          [
            convertedImages.MiddleLeft,
            convertedImages.MiddleMiddle,
            convertedImages.MiddleRight,
          ],
          [
            convertedImages.LowerLeft,
            convertedImages.LowerMiddle,
            convertedImages.LowerRight,
          ],
        ],
        reportViewOptions,
      );

      if (!isRenderStartCorrect) {
        return;
      }

      if (isPreviewMode) {
        MedicalImageRender.layoutModes.fullScreenMatrix();
        MedicalImageRender.activateMode('printMode');
      } else {
        MedicalImageRender.setTeeth(teeth);

        MedicalImageRender.addEventListener('layout', (event) => {
          if (event.mode === 'focus') {
            dispatch(reportsModel.actions.setToolbarActiveControl('view'));
            dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));
          }
        });
      }

      performance.mark('InitializeIOXRayRender:end');
      performance.measure(
        'InitializeIOXRayRender',
        'InitializeIOXRayRender:start',
        'InitializeIOXRayRender:end',
      );
    }
  }, [
    reportReadyForRender,
    IOXRayImagesInterface,
    hidePBLFeatureFlag,
    groupedIOXRayImages,
  ]);

  // NOTE: PBL should be set up just one time after render is done.
  useEffect(() => {
    if (reportPBLReadyForRender && MedicalImageRender.isRunning()) {
      performance.mark('setPBLs:start');
      if (!isPreviewMode) {
        const PBLsData = IOXRayImagesInterface.map((data) => {
          const initialPBLList = getPBLsForImage(data.asset.ID);

          if (initialPBLList.length === 0) {
            return undefined;
          }

          const getPBL = (landmark: Landmark) => ({
            start: {
              x: landmark.lowPoint?.ModelPosition?.X || 0,
              y: landmark.lowPoint?.ModelPosition?.Y || 0,
            },
            end: {
              x: landmark.upPoint?.ModelPosition?.X || 0,
              y: landmark.upPoint?.ModelPosition?.Y || 0,
            },
            color: landmark.color,
            textProps: {
              color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
            },
          });

          return {
            imageID:
              data.imageMeta?.GeneratedAssetID || data.imageMeta?.StudyAssetID,
            PBLs: initialPBLList.map((pbl) => {
              return getPBL(pbl);
            }),
            scale: data.imageMeta.Scale?.X || 1,
          };
        }).filter((data) => data) as PBLProps[];

        if (PBLsData) {
          MedicalImageRender.setPBLs(PBLsData);
          if (showPBL) {
            MedicalImageRender.showPBLs();
          }
          performance.mark('setPBLs:end');
          performance.measure('setPBLs', 'setPBLs:start', 'setPBLs:end');
        }
      }
    }
  }, [reportPBLReadyForRender]);

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportReadyForRender && MedicalImageRender.isRunning()) {
      if (!hideMasks && !hidePBLFeatureFlag && showPBL) {
        MedicalImageRender.showPBLs();
      } else {
        MedicalImageRender.hidePBLs();
      }
    }
  }, [reportReadyForRender, showPBL, hideMasks, hidePBLFeatureFlag]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  // Render masks
  useEffect(() => {
    if (
      MedicalImageRender.isRunning() &&
      !isPreviewMode &&
      reportReadyForRender
    ) {
      MedicalImageRender.deleteMasks();

      if (!hideMasks && masksRenderData.length > 0) {
        MedicalImageRender.addMasks(masksRenderData);
        MedicalImageRender.showMasks(activeMaskFilters);
      }
    }
  }, [
    masksRenderData,
    isPreviewMode,
    reportReadyForRender,
    hideMasks,
    activeMaskFilters,
  ]);

  // BBoxes render
  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      if (hoveredBBoxes) {
        MedicalImageRender.deleteConditionBoxes();
        MedicalImageRender.addConditionBoxes(hoveredBBoxes);
      } else {
        MedicalImageRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  return (
    <div
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
      ref={mainViewRef}
      id="report_render"
    >
      <canvas ref={canvasRef} className={styles.canvas} />
      {children}
    </div>
  );
};

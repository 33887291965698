import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useLocation, useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import api, { ApiError } from '@/shared/api/api';
import { PATHS } from '@/shared/config';

import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';
import { accessModel } from '@/entities/access';

export const useOrganizationSettingsStream = () => {
  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const organizationSettingsStream = useRef<Subscription | null>(null);

  const openOrganizationSettingsStream = () => {
    dispatch(billingModel.actions.setLoading('pending'));

    organizationSettingsStream.current = api.organization
      .OrganizationSettingsStream({ OrganizationID: organizationID })
      .subscribe({
        next: ({
          HistoricalOrganization,
          UpdatedOrganization,
          HistoricalInvitation,
          UpdatedInvitation,
        }) => {
          if (HistoricalOrganization) {
            dispatch(organizationModel.actions.setOne(HistoricalOrganization));
          }

          if (UpdatedOrganization) {
            dispatch(
              organizationModel.actions.setNewestOne(UpdatedOrganization),
            );
          }

          if (HistoricalInvitation) {
            dispatch(accessModel.actions.setOne(HistoricalInvitation));
          }

          if (UpdatedInvitation) {
            dispatch(accessModel.actions.setOne(UpdatedInvitation));
          }
        },
        error: (error: ApiError) => {
          if (error.type === 'UnauthenticatedError') {
            navigate(PATHS.signIn, { state: { from: location?.pathname } });
          }

          if (error.type === 'NotFoundError') {
            navigate(PATHS.patients);
          }

          // eslint-disable-next-line no-console
          console.error(
            'OrganizationSettingsStream: error: ',
            error.message,
            error.code,
            error.type,
            error,
          );
        },
        complete: () => {
          // Do nothing
        },
      });
  };

  const closeOrganizationSettingsStream = () => {
    if (organizationSettingsStream.current) {
      organizationSettingsStream.current.unsubscribe();
    }
  };

  useEffect(() => {
    if (organizationID) {
      openOrganizationSettingsStream();
    }

    return () => {
      closeOrganizationSettingsStream();
    };
  }, [organizationID]);
};

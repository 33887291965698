import { useAppDispatch } from '@/shared/hooks';

import { studyModel } from '@/entities/study';
import { patientModel } from '@/entities/patient';

export const useRemoveStudy = () => {
  const dispatch = useAppDispatch();

  return async (studyID: string) => {
    const { DeletedStudyID, ChangedPatient } = await dispatch(
      studyModel.thunks.deleteStudy({ StudyID: studyID }),
    ).unwrap();

    dispatch(studyModel.actions.removeOne(DeletedStudyID));

    if (ChangedPatient) {
      dispatch(patientModel.actions.setNewestOne(ChangedPatient));
    }
  };
};

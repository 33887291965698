import { StructuredReportsAndStudiesType } from '../config';

export const distributeReportsAndStudies = (
  structuredReportsAndStudies: StructuredReportsAndStudiesType[],
) => {
  const leftColumn: StructuredReportsAndStudiesType[] = [];
  const rightColumn: StructuredReportsAndStudiesType[] = [];

  structuredReportsAndStudies.forEach((item, index) =>
    index % 2 === 0 ? leftColumn.push(item) : rightColumn.push(item),
  );

  return [leftColumn, rightColumn];
};

import { InferType, object, string } from '@/shared/config/yup';

export const editCompanyInfoFormSchema = object({
  companyName: string().required(),
  workEmail: string().email(),
  phoneNumber: string(),
  stateRegion: string(),
  city: string(),
  zipCode: string(),
  address: string(),
  webSite: string(),
  country: string(),
});

export type EditCompanyInfoFormPayload = InferType<
  typeof editCompanyInfoFormSchema
>;

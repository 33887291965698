import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';

import { Guide } from '../config/medicalImages.types';

import { makeGuideLines } from './makeGuideLines';
import { getFileSrc } from './getFileSrc';

export const getGroupGuides = (groupItems: Asset[]) =>
  groupItems.reduce((medicalImages, asset) => {
    const medicalImage = asset.GeneratedReport?.CBCTGPToothSlice;

    const guideFrontalImage = medicalImage?.GuideFrontal;
    const guideImage = medicalImage?.Guide;

    const guideImageFields = guideFrontalImage || guideImage;

    if (!guideImageFields) {
      return medicalImages;
    }

    const Lines = makeGuideLines(
      asset?.GeneratedReport?.MedicalImageFeatures?.Annotations?.Layers,
    );

    const guide = {
      ...guideImageFields,
      Lines,
    };

    if (medicalImage?.Slice) {
      medicalImages.push({
        id: medicalImage?.Slice.ID,
        src: getFileSrc(medicalImage?.Slice.ID),
        image: medicalImage?.Slice.Image ?? { Width: 0, Height: 0 },
        guide,
      });
    }

    return medicalImages;
  }, [] as Guide[]);

import { OrganizationOnboardingQuestionnaireV1 } from '@/shared/api/protocol_gen/api/marketing/dto_questionnaire';
import { DefaultInitialState } from '@/shared/config';

export enum QuestionnaireSteps {
  CompanyName = 1,
  OwnerSpecialization = 2,
  CompanyProfile = 3,
  CBCTMachineType = 4,
  CBCTImagingSoftware = 5,
  XRayImagingSoftware = 6,
  FocusOfInterest = 7,
}

export type MarketingInitialState = DefaultInitialState & {
  questionnaire: OrganizationOnboardingQuestionnaireV1;
};

import { Outlet, useParams } from 'react-router';

import { usePatientProfileStream } from '../../hooks/usePatientProfileStream';

export const PatientLayout = () => {
  const { patientID = '' } = useParams();

  usePatientProfileStream(patientID);

  return <Outlet />;
};

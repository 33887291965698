import { useState } from 'react';

import { Button } from '@/shared/ui';

import styles from './index.module.scss';
import { MPR2 } from './components/MPRv2/MPRv2';
import { Buttons } from './components/Buttons/Buttons';

const COMPONENTS_CONFIG = [
  { label: 'MPR v2', element: MPR2 },
  { label: 'Buttons', element: Buttons },
];

export const Playground = () => {
  const [component, setComponent] = useState(COMPONENTS_CONFIG[0]);

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <h2>Components</h2>
        {COMPONENTS_CONFIG.map(({ label, element }, index) => (
          <Button
            key={label + index}
            variant={component.label === label ? 'primary' : 'gray'}
            size="small"
            onClick={() => setComponent({ label, element })}
          >
            {label}
          </Button>
        ))}
      </div>
      <div className={styles.content}>{component.element()}</div>
    </div>
  );
};

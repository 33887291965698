import { AnnotationsLayer } from '@/shared/api/protocol_gen/model/dto_common_image_annotations';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';

// TODO [4|h]: need to refactor this function to aviod reduce inside reduce inside reduce
export const makeAnnotations = (
  annotations: AnnotationsLayer[] = [],
): Annotation[] =>
  annotations.reduce((annotationsAcc, item) => {
    const polyLines = item.Annotations.reduce((polyLinesAcc, annotation) => {
      if (annotation?.PolyLine?.Points) {
        const points = annotation?.PolyLine?.Points;
        const lines = points?.reduce((linesAcc, line, lineIndex) => {
          if (lineIndex > 0) {
            const currentLine = {
              kind: 'line',
              x1: line.X,
              x2: points[lineIndex - 1].X,
              y1: line.Y,
              y2: points[lineIndex - 1].Y,
            } as Annotation;
            linesAcc.push(currentLine);
          }

          return linesAcc;
        }, [] as Annotation[]);
        polyLinesAcc.push(...lines);
      }
      if (annotation?.Angle) {
        const angle = {
          kind: 'angle',
          x1: annotation?.Angle?.Start?.X,
          y1: annotation?.Angle?.Start?.Y,
          x2: annotation?.Angle?.End?.X,
          y2: annotation?.Angle?.End?.Y,
          x3: annotation?.Angle?.Corner?.X,
          y3: annotation?.Angle?.Corner?.Y,
        } as Annotation;
        polyLinesAcc.push(angle);
      }
      if (annotation?.Arrow) {
        const arrow = {
          kind: 'arrow',
          x1: annotation.Arrow?.Start?.X,
          x2: annotation.Arrow?.End?.X,
          y1: annotation.Arrow?.Start?.Y,
          y2: annotation.Arrow?.End?.Y,
        } as Annotation;
        polyLinesAcc.push(arrow);
      }
      if (annotation?.Ruler) {
        const ruler = {
          kind: 'ruler',
          x1: annotation.Ruler?.Start?.X,
          x2: annotation.Ruler?.End?.X,
          y1: annotation.Ruler?.Start?.Y,
          y2: annotation.Ruler?.End?.Y,
        } as Annotation;
        polyLinesAcc.push(ruler);
      }
      return polyLinesAcc;
    }, [] as Annotation[]);

    if (item.Annotations?.length) {
      annotationsAcc.push(...polyLines);
    }

    return annotationsAcc;
  }, [] as Annotation[]);

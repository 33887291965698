import { AssetContentGeneratedCBCTToothPathologySlice_PathologyType } from '@/shared/api/protocol_gen/model/dto_asset';
import { AssetContentGeneratedCBCTEndoReportSection } from '@/shared/api/protocol_gen/model/dto_asset_cbct_endo';
import { CBCTSectionOrientation } from '@/shared/api/protocol_gen/model/dto_asset_common';

import { GroupConfigsType, MedicalImagesGroups } from './medicalImages.types';

export const TOOTH_PAGE_GROUPS: GroupConfigsType[] = [
  {
    groupName: MedicalImagesGroups.OrientationAxial,
    groupCode: CBCTSectionOrientation.SectionOrientationAxial,
  },
  {
    groupName: MedicalImagesGroups.OrientationVestibuloOral,
    groupCode: CBCTSectionOrientation.SectionOrientationVestibuloOral,
  },
  {
    groupName: MedicalImagesGroups.OrientationMesioDistal,
    groupCode: CBCTSectionOrientation.SectionOrientationMesioDistal,
  },
];

export const TOOTH_PAGE_PATHOLOGY_GROUPS: GroupConfigsType[] = [
  {
    groupName: MedicalImagesGroups.Caries,
    groupCode:
      AssetContentGeneratedCBCTToothPathologySlice_PathologyType.Caries,
  },
  {
    groupName: MedicalImagesGroups.PeriapicalLesion,
    groupCode:
      AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion,
  },
];

export const ENDO_REPORT_GROUPS: GroupConfigsType[] = [
  {
    groupName: MedicalImagesGroups.EndoReportSectionRootCanalSpace,
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSpace,
  },
  {
    groupName: MedicalImagesGroups.EndoReportSectionRootCanalSystem,
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSystem,
  },
  {
    groupName: MedicalImagesGroups.EndoReportSectionRootCanalShape,
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalShape,
  },
  {
    groupName: MedicalImagesGroups.EndoReportSectionFurcation,
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_Furcation,
  },
];

export const ENDODONTIC_PATHOLOGY_GROUP: GroupConfigsType = {
  groupName: MedicalImagesGroups.PeriapicalLesion,
  groupCode:
    AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion,
};

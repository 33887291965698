import { useIntl } from 'react-intl';

export const useFormatBytes = () => {
  const { formatMessage } = useIntl();

  const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) {
      return formatMessage({
        id: 'formatBytes.zeroBytes',
        defaultMessage: '0 Bytes',
      });
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    const sizes = [
      formatMessage({ id: 'formatBytes.Bytes', defaultMessage: 'Bytes' }),
      formatMessage({ id: 'formatBytes.KB', defaultMessage: 'KB' }),
      formatMessage({ id: 'formatBytes.MB', defaultMessage: 'MB' }),
      formatMessage({ id: 'formatBytes.GB', defaultMessage: 'GB' }),
      formatMessage({ id: 'formatBytes.TB', defaultMessage: 'TB' }),
      formatMessage({ id: 'formatBytes.PB', defaultMessage: 'PB' }),
      formatMessage({ id: 'formatBytes.EB', defaultMessage: 'EB' }),
      formatMessage({ id: 'formatBytes.ZB', defaultMessage: 'ZB' }),
      formatMessage({ id: 'formatBytes.YB', defaultMessage: 'YB' }),
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  return formatBytes;
};

import { FC } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { userModel } from '@/entities/user';

import styles from './ViewSwitcher.module.scss';

type ViewSwitcherProps = {
  className?: string;
};

export const ViewSwitcher: FC<ViewSwitcherProps> = ({ className }) => {
  const dispatch = useAppDispatch();

  const { view } = useAppSelector(userModel.selectors.selectSettings);

  return (
    <div
      className={cn(styles.container, className)}
      onClick={() =>
        dispatch(
          userModel.thunks.setSettings({
            view: view === 'grid' ? 'row' : 'grid',
          }),
        )
      }
    >
      <div className={cn(styles.switcher, view === 'row' && styles.rowView)} />
      <button
        aria-label="view-grid"
        type="button"
        className={cn(styles.viewButton, view === 'grid' && styles.isSelected)}
      >
        <Icon size={40} name="tiles" />
      </button>
      <button
        aria-label="view-row"
        type="button"
        className={cn(styles.viewButton, view === 'row' && styles.isSelected)}
      >
        <Icon size={40} name="list" />
      </button>
    </div>
  );
};

import { defineMessages } from 'react-intl';

import { SignStatus } from '@/entities/reports/config/reports.type';

export const previewFooterDisclaimer = defineMessages<SignStatus>({
  withoutSign: {
    id: 'withoutSign',
    defaultMessage:
      'This report was generated with Diagnocat using Artificial Intelligence. The conditions and pathologies in this report can not be considered a medical diagnosis and must be interpreted by the attending dentist.',
  },
  notSigned: {
    id: 'notSigned',
    defaultMessage:
      'This report contains confidential health information. It was generated with Diagnocat SaaS using Artificial Intelligence. The conditions and pathologies in this report can not be considered a medical diagnosis and must be interpreted by the attending dentist.',
  },
  signed: {
    id: 'signed',
    defaultMessage:
      'This report contains confidential health information. It was generated with Diagnocat SaaS using Artificial Intelligence. The conditions and pathologies in this report were verified by the attending dentist.',
  },
});

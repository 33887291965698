import { MedicalImageInterface } from '@/shared/config';
import { AnnotationTypeGuide } from '@/shared/api/protocol_gen/model/dto_common_image_annotations';

type GuideLine = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  src: string;
  assetID: string;
};

export const getGuideLines = (
  lines: AnnotationTypeGuide[],
  medicalImages: MedicalImageInterface[],
) => {
  if (!lines) return [];
  return lines.reduce((guideLines, line, lineIndex) => {
    const src = medicalImages[lineIndex]?.src;

    guideLines.push({
      x1: line?.Start?.X ?? 0,
      x2: line?.End?.X ?? 0,
      y1: line?.Start?.Y ?? 0,
      y2: line?.End?.Y ?? 0,
      src,
      assetID: line.ReferencedAssetID,
    });

    return guideLines;
  }, [] as GuideLine[]);
};

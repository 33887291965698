import { FC } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';

import { getLabelingCountryGroup } from '../../lib';
import { labelingData } from '../../config';

import styles from './LabelingContent.module.scss';

type AccordionContentProps = {
  country: string;
};
export const LabelingContent: FC<AccordionContentProps> = (props) => {
  const { country } = props;

  const labelingCountryGroup = getLabelingCountryGroup(country);

  if (!labelingCountryGroup) {
    return null;
  }

  const isCanada = labelingCountryGroup === 'Canada';

  const {
    manufacturer,
    contactInfo,
    UDI,
    productVersion,
    marking,
    userManual,
    lastUpdate,
    nameOfProduct,
  } = labelingData[labelingCountryGroup];

  const title = contactInfo?.title;
  const address = contactInfo?.address;
  const email = contactInfo?.email;
  const phoneNumber = contactInfo?.phoneNumber;

  return (
    <div className={styles.container}>
      <div className={styles.wrapperWithGap}>
        {nameOfProduct && (
          <div className={styles.marginBottom}>
            <h6 className={cn(styles.inline, 'h6')}>
              Name of product{isCanada && ` / Nom du produit`}:
            </h6>{' '}
            <span className="p2">{nameOfProduct}</span>
          </div>
        )}

        <h6 className={cn(styles.marginBottom, 'h6')}>
          Manufacturer {isCanada && ` / Fabricant`}
        </h6>
        <p className="p2">{manufacturer}</p>
      </div>

      {contactInfo && (
        <div className={styles.wrapperWithGap}>
          {title && <h6 className={cn(styles.marginBottom, 'h6')}>{title}</h6>}

          {address && <p className="p2">{address}</p>}

          {(phoneNumber || email) && (
            <div className={styles.wrapper}>
              {phoneNumber && (
                <a
                  href={`tel:${phoneNumber}`}
                  className={cn(styles.link, 'p2')}
                >
                  {phoneNumber}
                </a>
              )}
              {email && (
                <a href={`mailto:${email}`} className={cn(styles.link, 'p2')}>
                  {email}
                </a>
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.wrapperWithGap}>
        <h6 className={cn(styles.marginBottom, 'h6')}>Version</h6>

        <p className="p2">{productVersion}</p>
      </div>

      {lastUpdate && (
        <div className={styles.wrapperWithGap}>
          <h6 className={cn(styles.marginBottom, 'h6')}>Update date</h6>

          <p className="p2">{lastUpdate}</p>
        </div>
      )}

      {UDI !== null && <h6 className="h6">UDI: {UDI}</h6>}

      {userManual && (
        <div className={styles.wrapperWithGap}>
          <h6 className={cn(styles.marginBottom, 'h6')}>
            User manual {isCanada && ` / Manuel d’utilisation`}
          </h6>

          <div className={styles.linkWrapper}>
            <a
              href={userManual}
              target="_blank"
              className={cn(styles.download, styles.link, 'p2')}
              rel="noreferrer"
            >
              Download
            </a>

            {isCanada && (
              <>
                {' / '}

                <a
                  href={userManual}
                  target="_blank"
                  className={cn(styles.download, styles.link, 'p2')}
                  rel="noreferrer"
                >
                  “Télécharger
                </a>
              </>
            )}
          </div>
        </div>
      )}

      {marking && (
        <div className={styles.marking}>
          {marking.CE && <Icon name="CEMarking" width={32} height={24} />}

          {marking.MD && <Icon name="MDMarking" width={44} height={28} />}
        </div>
      )}
    </div>
  );
};

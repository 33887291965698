import api from '@/shared/api/api';
import {
  OrganizationOnboardingQuestionnaireReqV1,
  OrganizationOnboardingQuestionnaireRespV1,
  PutOrganizationOnboardingQuestionnaireReqV1,
  PutOrganizationOnboardingQuestionnaireRespV1,
  QuestionnaireServiceClientImpl,
} from '@/shared/api/protocol_gen/api/marketing/svc_questionnaire';
import { SliceName } from '@/shared/config';
import { createThunkGenerator } from '@/shared/lib';

type MarketingRequests = keyof QuestionnaireServiceClientImpl;

const generateMarketingThunk = createThunkGenerator<MarketingRequests>(
  SliceName.marketing,
  'marketing',
);

export const getOrganizationOnboardingQuestionnaire = generateMarketingThunk<
  OrganizationOnboardingQuestionnaireReqV1,
  OrganizationOnboardingQuestionnaireRespV1
>(
  'GetOrganizationOnboardingQuestionnaire_V1',
  api.marketing.GetOrganizationOnboardingQuestionnaire_V1,
);

export const putOrganizationOnboardingQuestionnaire = generateMarketingThunk<
  PutOrganizationOnboardingQuestionnaireReqV1,
  PutOrganizationOnboardingQuestionnaireRespV1
>(
  'PutOrganizationOnboardingQuestionnaire_V1',
  api.marketing.PutOrganizationOnboardingQuestionnaire_V1,
);

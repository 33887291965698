/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { ToothNumeration } from '../model/dto_report_common';
import { FloatPoint3D, AnatomicalBasis3D } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

/** @deprecation_pending("no substitution exist yet") */
export interface ReportStudioToothPosition {
  Tooth: ToothNumeration | undefined;
  IsMissing: boolean;
  Position: ReportStudioOriginatedPosition | undefined;
}

/**
 * @deprecation_pending("no substitution exist yet")
 * Coordinate system describing tooth, used in segmentron reports only:
 *  * ReportType_Studio_Implant
 *  * ReportType_Studio_Orhto
 *  * ReportType_CBCT_IOS_Superimposition
 *  * ReportType_CBCT_Segmentation
 */
export interface ReportStudioOriginatedPosition {
  /** Origin [required]: CS origin, common for both bases. */
  Origin: FloatPoint3D | undefined;
  /** AbsoluteAxes [required]: orthogonal right-handed basis of tooth axes in absolute CS. */
  Directions:
    | ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections
    | undefined;
  /**
   * AnatomicalAxes [required]: anatomical basis (direction depend on jaw type and quarter
   * tooth belongs to). Axial component is co-aligned with rooth-crown direction.
   * This axis is properly inverted `AbsoluteAxis` if only CBCT was available for
   * calculation.
   */
  AnatomicalBasis: AnatomicalBasis3D | undefined;
}

export interface ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
  X: FloatPoint3D | undefined;
  Y: FloatPoint3D | undefined;
  Z: FloatPoint3D | undefined;
}

function createBaseReportStudioToothPosition(): ReportStudioToothPosition {
  return { Tooth: undefined, IsMissing: false, Position: undefined };
}

export const ReportStudioToothPosition = {
  encode(
    message: ReportStudioToothPosition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      ToothNumeration.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.IsMissing === true) {
      writer.uint32(16).bool(message.IsMissing);
    }
    if (message.Position !== undefined) {
      ReportStudioOriginatedPosition.encode(
        message.Position,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioToothPosition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioToothPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.IsMissing = reader.bool();
          break;
        case 3:
          message.Position = ReportStudioOriginatedPosition.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioToothPosition {
    return {
      Tooth: isSet(object.Tooth)
        ? ToothNumeration.fromJSON(object.Tooth)
        : undefined,
      IsMissing: isSet(object.IsMissing) ? Boolean(object.IsMissing) : false,
      Position: isSet(object.Position)
        ? ReportStudioOriginatedPosition.fromJSON(object.Position)
        : undefined,
    };
  },

  toJSON(message: ReportStudioToothPosition): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth
        ? ToothNumeration.toJSON(message.Tooth)
        : undefined);
    message.IsMissing !== undefined && (obj.IsMissing = message.IsMissing);
    message.Position !== undefined &&
      (obj.Position = message.Position
        ? ReportStudioOriginatedPosition.toJSON(message.Position)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioToothPosition>, I>>(
    object: I,
  ): ReportStudioToothPosition {
    const message = createBaseReportStudioToothPosition();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? ToothNumeration.fromPartial(object.Tooth)
        : undefined;
    message.IsMissing = object.IsMissing ?? false;
    message.Position =
      object.Position !== undefined && object.Position !== null
        ? ReportStudioOriginatedPosition.fromPartial(object.Position)
        : undefined;
    return message;
  },
};

function createBaseReportStudioOriginatedPosition(): ReportStudioOriginatedPosition {
  return {
    Origin: undefined,
    Directions: undefined,
    AnatomicalBasis: undefined,
  };
}

export const ReportStudioOriginatedPosition = {
  encode(
    message: ReportStudioOriginatedPosition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Origin !== undefined) {
      FloatPoint3D.encode(message.Origin, writer.uint32(10).fork()).ldelim();
    }
    if (message.Directions !== undefined) {
      ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.encode(
        message.Directions,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.AnatomicalBasis !== undefined) {
      AnatomicalBasis3D.encode(
        message.AnatomicalBasis,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOriginatedPosition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOriginatedPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Origin = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 2:
          message.Directions =
            ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 3:
          message.AnatomicalBasis = AnatomicalBasis3D.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOriginatedPosition {
    return {
      Origin: isSet(object.Origin)
        ? FloatPoint3D.fromJSON(object.Origin)
        : undefined,
      Directions: isSet(object.Directions)
        ? ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.fromJSON(
            object.Directions,
          )
        : undefined,
      AnatomicalBasis: isSet(object.AnatomicalBasis)
        ? AnatomicalBasis3D.fromJSON(object.AnatomicalBasis)
        : undefined,
    };
  },

  toJSON(message: ReportStudioOriginatedPosition): unknown {
    const obj: any = {};
    message.Origin !== undefined &&
      (obj.Origin = message.Origin
        ? FloatPoint3D.toJSON(message.Origin)
        : undefined);
    message.Directions !== undefined &&
      (obj.Directions = message.Directions
        ? ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.toJSON(
            message.Directions,
          )
        : undefined);
    message.AnatomicalBasis !== undefined &&
      (obj.AnatomicalBasis = message.AnatomicalBasis
        ? AnatomicalBasis3D.toJSON(message.AnatomicalBasis)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioOriginatedPosition>, I>>(
    object: I,
  ): ReportStudioOriginatedPosition {
    const message = createBaseReportStudioOriginatedPosition();
    message.Origin =
      object.Origin !== undefined && object.Origin !== null
        ? FloatPoint3D.fromPartial(object.Origin)
        : undefined;
    message.Directions =
      object.Directions !== undefined && object.Directions !== null
        ? ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.fromPartial(
            object.Directions,
          )
        : undefined;
    message.AnatomicalBasis =
      object.AnatomicalBasis !== undefined && object.AnatomicalBasis !== null
        ? AnatomicalBasis3D.fromPartial(object.AnatomicalBasis)
        : undefined;
    return message;
  },
};

function createBaseReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections(): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
  return { X: undefined, Y: undefined, Z: undefined };
}

export const ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections =
  {
    encode(
      message: ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections,
      writer: _m0.Writer = _m0.Writer.create(),
    ): _m0.Writer {
      if (message.X !== undefined) {
        FloatPoint3D.encode(message.X, writer.uint32(10).fork()).ldelim();
      }
      if (message.Y !== undefined) {
        FloatPoint3D.encode(message.Y, writer.uint32(18).fork()).ldelim();
      }
      if (message.Z !== undefined) {
        FloatPoint3D.encode(message.Z, writer.uint32(26).fork()).ldelim();
      }
      return writer;
    },

    decode(
      input: _m0.Reader | Uint8Array,
      length?: number,
    ): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
      const reader =
        input instanceof _m0.Reader ? input : new _m0.Reader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message =
        createBaseReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.X = FloatPoint3D.decode(reader, reader.uint32());
            break;
          case 2:
            message.Y = FloatPoint3D.decode(reader, reader.uint32());
            break;
          case 3:
            message.Z = FloatPoint3D.decode(reader, reader.uint32());
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    },

    fromJSON(
      object: any,
    ): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
      return {
        X: isSet(object.X) ? FloatPoint3D.fromJSON(object.X) : undefined,
        Y: isSet(object.Y) ? FloatPoint3D.fromJSON(object.Y) : undefined,
        Z: isSet(object.Z) ? FloatPoint3D.fromJSON(object.Z) : undefined,
      };
    },

    toJSON(
      message: ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections,
    ): unknown {
      const obj: any = {};
      message.X !== undefined &&
        (obj.X = message.X ? FloatPoint3D.toJSON(message.X) : undefined);
      message.Y !== undefined &&
        (obj.Y = message.Y ? FloatPoint3D.toJSON(message.Y) : undefined);
      message.Z !== undefined &&
        (obj.Z = message.Z ? FloatPoint3D.toJSON(message.Z) : undefined);
      return obj;
    },

    fromPartial<
      I extends Exact<
        DeepPartial<ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections>,
        I
      >,
    >(
      object: I,
    ): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
      const message =
        createBaseReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections();
      message.X =
        object.X !== undefined && object.X !== null
          ? FloatPoint3D.fromPartial(object.X)
          : undefined;
      message.Y =
        object.Y !== undefined && object.Y !== null
          ? FloatPoint3D.fromPartial(object.Y)
          : undefined;
      message.Z =
        object.Z !== undefined && object.Z !== null
          ? FloatPoint3D.fromPartial(object.Z)
          : undefined;
      return message;
    },
  };

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { MedicalImageInterface } from '@/shared/config';

import {
  getFileSrc,
  makeAnnotations,
  makeImageViewOptions,
} from '@/entities/assets';

export const transformUserAssetsToMedicalImages = (
  assets: Asset[],
): MedicalImageInterface[] =>
  assets.map((asset, index) => {
    const medicalImageID =
      asset?.Report?.UserUploadedToothSlice?.Slice?.ID ??
      asset?.Report?.UserUploadedMPRPlane?.MPRPlane?.ID;
    const medicalImagePath = asset?.Report?.UserUploadedToothSlice?.Slice?.Path;
    const annotations = makeAnnotations(
      asset?.Report?.MedicalImageFeatures?.Annotations?.Layers,
    );
    const viewOptions = makeImageViewOptions(
      asset?.Report?.MedicalImageFeatures?.ViewOptions,
    );

    return {
      src: getFileSrc(medicalImageID as string),
      kind: 'raster',
      id: medicalImageID as string,
      path: medicalImagePath,
      assetID: asset?.ID,
      order: index,
      annotations,
      viewOptions,
    };
  });

import { FC } from 'react';
import cn from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

import { StudyStatus } from '@/shared/api/protocol_gen/model/dto_study';
import { Modal, Button } from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';

import { assetsModel, getStudyArchiveURL } from '@/entities/assets';
import { studyModel } from '@/entities/study';

import { RemoveStudyModal, useRemoveStudyModal } from '@/features/removeStudy';
import { ScanUidCopy } from '@/features/scanUidCopy';

import { ScanInfo } from '../ScanInfo/ScanInfo';

import styles from './StudyInformation.module.scss';

type StudyInformationProps = {
  className?: string;
  testID?: string;
  studyID: string;
  isOpen?: boolean;
  onClose?: () => void;
};

export const StudyInformation: FC<StudyInformationProps> = (props) => {
  const {
    className,
    testID,
    studyID,
    isOpen = false,
    onClose = () => {},
  } = props;

  const studyStatus = useAppSelector(
    studyModel.selectors.selectStudyStatus(studyID),
  );

  const studyIsNotFinished = studyStatus !== StudyStatus.StudyStatus_Finished;

  const {
    isRemoveStudyModalOpen,
    openRemoveStudyModal,
    closeRemoveStudyModal,
  } = useRemoveStudyModal();

  const { isPhone } = useMedia();

  const buttonSize = isPhone ? 'small' : 'medium';

  const { formatMessage } = useIntl();

  const studyMeta = useAppSelector(
    assetsModel.selectors.selectCBCTMetaDataByStudyID(studyID),
  );

  return (
    <>
      <div className={className} data-testid={testID}>
        <Modal
          isOpen={isOpen}
          onCancel={() => onClose()}
          title={formatMessage({
            id: 'viewDetails.title',
            defaultMessage: 'Study information',
          })}
          footer={
            <footer className={styles.footer}>
              <Button
                size={buttonSize}
                variant="tertiary"
                danger
                onClick={() => openRemoveStudyModal(studyID)}
              >
                <FormattedMessage id="global.remove" defaultMessage="Remove" />
              </Button>
              <Button
                href={getStudyArchiveURL(studyID)}
                target="_blank"
                size={buttonSize}
                variant="secondary"
                className={styles.downloadButton}
                disabled={studyIsNotFinished}
              >
                <FormattedMessage
                  id="global.downloadStudyFile"
                  defaultMessage="Download study file"
                />
              </Button>
            </footer>
          }
        >
          <main className={cn(styles.content, className)}>
            <ScanUidCopy scanUid={studyMeta?.ScanUID ?? ''} />
            {!!studyMeta && <ScanInfo studyMeta={studyMeta} />}
          </main>
        </Modal>
      </div>

      <RemoveStudyModal
        studyID={studyID}
        isOpen={isRemoveStudyModalOpen}
        onCancel={closeRemoveStudyModal}
        onRemove={onClose}
      />
    </>
  );
};

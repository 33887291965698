import { defineMessages } from 'react-intl';

import { ResetPasswordErrors } from '@/widgets/ResetPasswordErrorModal/config/types';

export const resetPasswordErrorModalTittleMessages =
  defineMessages<ResetPasswordErrors>({
    [ResetPasswordErrors.WrongEmail]: {
      id: 'resetPassword.errors.wrongEmail.title',
      defaultMessage: 'Error: No user found with this email.',
    },
    [ResetPasswordErrors.ExpiredTime]: {
      id: 'resetPassword.errors.expiredTime.title',
      defaultMessage: 'Error: Reset link expired.',
    },
  });

export const resetPasswordErrorModalMessages =
  defineMessages<ResetPasswordErrors>({
    [ResetPasswordErrors.WrongEmail]: {
      id: 'resetPassword.errors.wrongEmail.message',
      defaultMessage: 'Check email or register a new one.',
    },
    [ResetPasswordErrors.ExpiredTime]: {
      id: 'resetPassword.errors.expiredTime.message',
      defaultMessage: 'Request a new one.',
    },
  });

import { FormattedMessage } from 'react-intl';

import {
  array,
  date,
  InferType,
  number,
  object,
  string,
} from '@/shared/config/yup';
import { Gender } from '@/shared/api/protocol_gen/model/dto_base';

import { addDoctorsSchema } from '@/features/addDoctors';

const defaultPatientSchema = object({
  firstName: string().required().max(50),
  lastName: string().required().max(50),
  email: string().email().max(50),
  patientExternalID: string().max(50),
  dateOfBirth: date().required(),
  gender: number()
    .required()
    .oneOf([Gender.GenderMale, Gender.GenderFemale, Gender.GenderOther]),
});

export const newPatientSchema = defaultPatientSchema.concat(addDoctorsSchema);

export const editPatientSchema = defaultPatientSchema.concat(
  object({
    treatingDoctors: array().notRequired(),
  }),
);

export const patientSchema = object({
  firstName: string().required().max(50),
  lastName: string().required().max(50),
  email: string().email().max(50),
  patientExternalID: string().max(50),
  dateOfBirth: date().required(),
  gender: number()
    .required()
    .oneOf([Gender.GenderMale, Gender.GenderFemale, Gender.GenderOther]),
}).concat(addDoctorsSchema);

export const getPatientValidationSchema = (validateDoctors: boolean) =>
  object({
    firstName: string().required().max(50),
    lastName: string().required().max(50),
    email: string().email().max(50),
    patientExternalID: string().max(50),
    dateOfBirth: date().nullable().required(),
    gender: number()
      .required()
      .oneOf([Gender.GenderMale, Gender.GenderFemale, Gender.GenderOther]),
    treatingDoctors: validateDoctors
      ? array()
          .of(string())
          .min(1, () => (
            <FormattedMessage
              id="addDoctors.validationError"
              defaultMessage="You should select at least one doctor"
            />
          ))
          .required()
      : array().notRequired(),
  });

export type PatientPayload = InferType<typeof patientSchema>;

export type NewPatientPayload = InferType<typeof newPatientSchema>;
export type EditPatientPayload = InferType<typeof editPatientSchema>;

export const DATE_OF_BIRTH_FORMAT = 'yyyy-MM-dd';

export const PATIENT_FORM_ID = 'patient-form';

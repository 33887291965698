/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  StudyType,
  Study,
  studyTypeFromJSON,
  studyTypeToJSON,
} from '../../model/dto_study';
import {
  Gender,
  PersonalData,
  Attribute,
  genderFromJSON,
  genderToJSON,
} from '../../model/dto_base';
import {
  PatientStatus,
  Patient,
  patientStatusFromJSON,
  patientStatusToJSON,
} from '../../model/dto_patient';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { Timestamp } from '../../google/protobuf/timestamp';
import { PatientTask } from '../../model/dto_patient_task';
import { Empty } from '../../google/protobuf/empty';
import { Asset } from '../../model/dto_asset';
import { Report } from '../../model/dto_report';
import { Tooth } from '../../model/dto_report_tooth';
import { Invitation } from '../../model/dto_access';
import { User } from '../../model/dto_user';

export const protobufPackage = 'com.diagnocat.core';

export enum PatientListStreamSortBy {
  PatientListStreamSortByFirstName = 0,
  PatientListStreamSortByLastName = 1,
  PatientListStreamSortByExternalID = 2,
  PatientListStreamSortByDateOfBirth = 3,
  UNRECOGNIZED = -1,
}

export function patientListStreamSortByFromJSON(
  object: any,
): PatientListStreamSortBy {
  switch (object) {
    case 0:
    case 'PatientListStreamSortByFirstName':
      return PatientListStreamSortBy.PatientListStreamSortByFirstName;
    case 1:
    case 'PatientListStreamSortByLastName':
      return PatientListStreamSortBy.PatientListStreamSortByLastName;
    case 2:
    case 'PatientListStreamSortByExternalID':
      return PatientListStreamSortBy.PatientListStreamSortByExternalID;
    case 3:
    case 'PatientListStreamSortByDateOfBirth':
      return PatientListStreamSortBy.PatientListStreamSortByDateOfBirth;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PatientListStreamSortBy.UNRECOGNIZED;
  }
}

export function patientListStreamSortByToJSON(
  object: PatientListStreamSortBy,
): string {
  switch (object) {
    case PatientListStreamSortBy.PatientListStreamSortByFirstName:
      return 'PatientListStreamSortByFirstName';
    case PatientListStreamSortBy.PatientListStreamSortByLastName:
      return 'PatientListStreamSortByLastName';
    case PatientListStreamSortBy.PatientListStreamSortByExternalID:
      return 'PatientListStreamSortByExternalID';
    case PatientListStreamSortBy.PatientListStreamSortByDateOfBirth:
      return 'PatientListStreamSortByDateOfBirth';
    case PatientListStreamSortBy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface PatientProfileStreamReq {
  /** `PatientID`: [required] */
  PatientID: string;
}

export interface PatientProfileStreamResp {
  HistoricalPatient: Patient | undefined;
  /** if `Patient.YourPermissions.CanViewClinicalCaseDescription` == true */
  HistoricalPatientTask: PatientTask | undefined;
  /** `EndOfHistoricalPatientTasks` comes right after all historical patient projects are received */
  EndOfHistoricalPatientTasks: Empty | undefined;
  /** if `Patient.YourPermissions.CanViewStudies` == true */
  HistoricalStudy: Study | undefined;
  /** `EndOfHistoricalStudies` comes right after all historical studies are received */
  EndOfHistoricalStudies: Empty | undefined;
  /** study assets, documents assets */
  HistoricalAsset: Asset | undefined;
  /** `EndOfHistoricalAssets` comes right after all historical assets are received */
  EndOfHistoricalAssets: Empty | undefined;
  /** patient reports - if `Patient.YourPermissions.CanViewReports` == true */
  HistoricalReport: Report | undefined;
  /** `EndOfHistoricalReports` comes right after all historical reports are received */
  EndOfHistoricalReports: Empty | undefined;
  HistoricalTooth: Tooth | undefined;
  /** `EndOfHistoricalTeeth` comes right after all historical teeth are received */
  EndOfHistoricalTeeth: Empty | undefined;
  /**
   * if `Patient.YourPermissions.CanViewSharings` == true.
   * Invitations with status `StatusPending` && `StatusAccepted`
   */
  HistoricalInvitation: Invitation | undefined;
  /** `EndOfHistoricalInvitations` comes right after all historical invitations are received */
  EndOfHistoricalInvitations: Empty | undefined;
  /**
   * if `Patient.YourPermissions.CanViewSharings` == true.
   * Users to whom this patient/patient_studies/patient_reports was shared
   */
  HistoricalSharingMember: User | undefined;
  /** `EndOfHistoricalSharingMembers` comes right after all historical users are received */
  EndOfHistoricalSharingMembers: Empty | undefined;
  UpdatedPatient: Patient | undefined;
  UpdatedPatientTask: PatientTask | undefined;
  UpdatedStudy: Study | undefined;
  UpdatedAsset: Asset | undefined;
  UpdatedReport: Report | undefined;
  UpdatedTooth: Tooth | undefined;
  /** UpdatedInvitation: there will be no invitations that were canceled/accepted/deleted before the stream opening */
  UpdatedInvitation: Invitation | undefined;
  UpdatedSharingMember: User | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

/** sorted by Last modified date in descending order */
export interface PatientListStreamReq {
  /**
   * `ResumeToken`: [optional]: the last resumeToken obtained from `PatientListStreamResp`.
   * If filters are changed or a page is just opened, pass empty string until new token is received
   */
  ResumeToken: string;
  /** `OrganizationID`: [required]: organization you gonna work with */
  OrganizationID: string;
  /** `SearchString`: [optional]: externalID/first name/last name or a part of it */
  SearchString: string;
  AllDoctors: Empty | undefined;
  AllExceptDoctors: PatientListStreamReq_DoctorIDs | undefined;
  OnlyTheseDoctors: PatientListStreamReq_DoctorIDs | undefined;
  /** `Sort`: [optional]: default == by last modification */
  Sort: PatientListStreamSort[];
  /** `StartFromPatientID`: [optional]: Patient's identifier to start listing from */
  StartFromPatientID: string;
  /** `Limit`: [required]: min == 10, max == 1000 */
  Limit: number;
}

/** Returns only patients who include at least one of the `DoctorIDs` */
export interface PatientListStreamReq_DoctorIDs {
  DoctorIDs: string[];
}

export interface PatientListStreamSort {
  By: PatientListStreamSortBy;
  Ascending: boolean;
}

/** We do not send doctors participating in this patient 'cause you already have it from MyDataStream */
export interface PatientListStreamResp {
  /** If the stream was closed, we need `ResumeToken` to know which event you received the last. */
  ResumeToken: string;
  /** Patients list according to request filters and limit */
  InitialPatientsListItems: PatientListStreamResp_PatientsListItems | undefined;
  PatientUpdated: PatientListStreamResp_PatientItem | undefined;
  /** Total count of patients available to `Me` in the selected organization */
  TotalOrganizationPatientCount: number | undefined;
  SharedWithMeCount: number | undefined;
  /** if user is an admin in organization -> return SharedByStaffCount */
  SharedByMeCount: number | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface PatientListStreamResp_PatientItem {
  Patient: Patient | undefined;
  Studies: PatientListStreamResp_PatientItem_StudyCount[];
  Tasks: PatientListStreamResp_PatientItem_TasksCounts | undefined;
}

export interface PatientListStreamResp_PatientItem_StudyCount {
  Modality: StudyType;
  Count: number;
}

export interface PatientListStreamResp_PatientItem_TasksCounts {
  All: number;
  Done: number;
  Overdue: number;
}

export interface PatientListStreamResp_PatientsListItems {
  Patients: PatientListStreamResp_PatientItem[];
}

export interface CreatePatientReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /**
   * `PersonalData`: [required]:
   * - `FirstName`: [required]
   * - `LastName`: [required]
   * - `DateOfBirth`: [optional]
   * - `Emails`: [optional]
   * - `Phones`: [optional]
   */
  PersonalData: PersonalData | undefined;
  /** `Gender`: [required] */
  Gender: Gender;
  /** `ExternalID`: [optional] */
  ExternalID: string;
  /** `TreatingDoctorIDs`: [required]: repeated User.ID */
  TreatingDoctorIDs: string[];
  /** `Attributes`: [optional] */
  Attributes: Attribute[];
}

export interface CreatePatientResp {
  Patient: Patient | undefined;
}

export interface SetPatientPersonalDataReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /**
   * `PersonalData`: [required]:
   * - `FirstName`: [required]
   * - `LastName`: [required]
   * - `DateOfBirth`: [optional]
   * - `Emails`: [optional]
   * - `Phones`: [optional]
   */
  PersonalData: PersonalData | undefined;
}

export interface SetPatientPersonalDataResp {
  Patient: Patient | undefined;
  /** with new patient name */
  Invitations: Invitation[];
}

export interface SetPatientExternalIDReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `ExternalID`: [required] */
  ExternalID: string;
}

export interface SetPatientExternalIDResp {
  Patient: Patient | undefined;
}

export interface SetPatientGenderReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `Gender`: [required] */
  Gender: Gender;
}

export interface SetPatientGenderResp {
  Patient: Patient | undefined;
}

export interface SetPatientStatusReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `Status`: [required] */
  Status: PatientStatus;
}

export interface SetPatientStatusResp {
  Patient: Patient | undefined;
}

export interface SetPatientClinicalCaseDescriptionReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `ClinicalCaseDescription`: [optional] */
  ClinicalCaseDescription: string;
}

export interface SetPatientClinicalCaseDescriptionResp {
  Patient: Patient | undefined;
}

export interface SetPatientTreatmentDueDateReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `TreatmentDueDate`: [optional] */
  TreatmentDueDate: Date | undefined;
}

export interface SetPatientTreatmentDueDateResp {
  Patient: Patient | undefined;
}

export interface DeletePatientReq {
  /** `PatientID`: [required] */
  PatientID: string;
}

export interface DeletePatientResp {
  DeletedPatientID: string;
  DeletedStudyIDs: string[];
  DeletedReportIDs: string[];
  DeletedToothIDs: string[];
  DeletedConditionIDs: string[];
  DeletedTeethLandmarkIDs: string[];
  DeletedAssetIDs: string[];
  DeletedInvitationIDs: string[];
}

function createBasePatientProfileStreamReq(): PatientProfileStreamReq {
  return { PatientID: '' };
}

export const PatientProfileStreamReq = {
  encode(
    message: PatientProfileStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientProfileStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientProfileStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientProfileStreamReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
    };
  },

  toJSON(message: PatientProfileStreamReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientProfileStreamReq>, I>>(
    object: I,
  ): PatientProfileStreamReq {
    const message = createBasePatientProfileStreamReq();
    message.PatientID = object.PatientID ?? '';
    return message;
  },
};

function createBasePatientProfileStreamResp(): PatientProfileStreamResp {
  return {
    HistoricalPatient: undefined,
    HistoricalPatientTask: undefined,
    EndOfHistoricalPatientTasks: undefined,
    HistoricalStudy: undefined,
    EndOfHistoricalStudies: undefined,
    HistoricalAsset: undefined,
    EndOfHistoricalAssets: undefined,
    HistoricalReport: undefined,
    EndOfHistoricalReports: undefined,
    HistoricalTooth: undefined,
    EndOfHistoricalTeeth: undefined,
    HistoricalInvitation: undefined,
    EndOfHistoricalInvitations: undefined,
    HistoricalSharingMember: undefined,
    EndOfHistoricalSharingMembers: undefined,
    UpdatedPatient: undefined,
    UpdatedPatientTask: undefined,
    UpdatedStudy: undefined,
    UpdatedAsset: undefined,
    UpdatedReport: undefined,
    UpdatedTooth: undefined,
    UpdatedInvitation: undefined,
    UpdatedSharingMember: undefined,
    Ping: undefined,
  };
}

export const PatientProfileStreamResp = {
  encode(
    message: PatientProfileStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HistoricalPatient !== undefined) {
      Patient.encode(
        message.HistoricalPatient,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.HistoricalPatientTask !== undefined) {
      PatientTask.encode(
        message.HistoricalPatientTask,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalPatientTasks !== undefined) {
      Empty.encode(
        message.EndOfHistoricalPatientTasks,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.HistoricalStudy !== undefined) {
      Study.encode(message.HistoricalStudy, writer.uint32(34).fork()).ldelim();
    }
    if (message.EndOfHistoricalStudies !== undefined) {
      Empty.encode(
        message.EndOfHistoricalStudies,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.HistoricalAsset !== undefined) {
      Asset.encode(message.HistoricalAsset, writer.uint32(50).fork()).ldelim();
    }
    if (message.EndOfHistoricalAssets !== undefined) {
      Empty.encode(
        message.EndOfHistoricalAssets,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.HistoricalReport !== undefined) {
      Report.encode(
        message.HistoricalReport,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalReports !== undefined) {
      Empty.encode(
        message.EndOfHistoricalReports,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.HistoricalTooth !== undefined) {
      Tooth.encode(message.HistoricalTooth, writer.uint32(82).fork()).ldelim();
    }
    if (message.EndOfHistoricalTeeth !== undefined) {
      Empty.encode(
        message.EndOfHistoricalTeeth,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.HistoricalInvitation !== undefined) {
      Invitation.encode(
        message.HistoricalInvitation,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalInvitations !== undefined) {
      Empty.encode(
        message.EndOfHistoricalInvitations,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.HistoricalSharingMember !== undefined) {
      User.encode(
        message.HistoricalSharingMember,
        writer.uint32(114).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalSharingMembers !== undefined) {
      Empty.encode(
        message.EndOfHistoricalSharingMembers,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    if (message.UpdatedPatient !== undefined) {
      Patient.encode(
        message.UpdatedPatient,
        writer.uint32(250).fork(),
      ).ldelim();
    }
    if (message.UpdatedPatientTask !== undefined) {
      PatientTask.encode(
        message.UpdatedPatientTask,
        writer.uint32(258).fork(),
      ).ldelim();
    }
    if (message.UpdatedStudy !== undefined) {
      Study.encode(message.UpdatedStudy, writer.uint32(266).fork()).ldelim();
    }
    if (message.UpdatedAsset !== undefined) {
      Asset.encode(message.UpdatedAsset, writer.uint32(274).fork()).ldelim();
    }
    if (message.UpdatedReport !== undefined) {
      Report.encode(message.UpdatedReport, writer.uint32(282).fork()).ldelim();
    }
    if (message.UpdatedTooth !== undefined) {
      Tooth.encode(message.UpdatedTooth, writer.uint32(290).fork()).ldelim();
    }
    if (message.UpdatedInvitation !== undefined) {
      Invitation.encode(
        message.UpdatedInvitation,
        writer.uint32(306).fork(),
      ).ldelim();
    }
    if (message.UpdatedSharingMember !== undefined) {
      User.encode(
        message.UpdatedSharingMember,
        writer.uint32(314).fork(),
      ).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientProfileStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientProfileStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HistoricalPatient = Patient.decode(reader, reader.uint32());
          break;
        case 2:
          message.HistoricalPatientTask = PatientTask.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.EndOfHistoricalPatientTasks = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.HistoricalStudy = Study.decode(reader, reader.uint32());
          break;
        case 5:
          message.EndOfHistoricalStudies = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.HistoricalAsset = Asset.decode(reader, reader.uint32());
          break;
        case 7:
          message.EndOfHistoricalAssets = Empty.decode(reader, reader.uint32());
          break;
        case 8:
          message.HistoricalReport = Report.decode(reader, reader.uint32());
          break;
        case 9:
          message.EndOfHistoricalReports = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10:
          message.HistoricalTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 11:
          message.EndOfHistoricalTeeth = Empty.decode(reader, reader.uint32());
          break;
        case 12:
          message.HistoricalInvitation = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 13:
          message.EndOfHistoricalInvitations = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 14:
          message.HistoricalSharingMember = User.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 15:
          message.EndOfHistoricalSharingMembers = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 31:
          message.UpdatedPatient = Patient.decode(reader, reader.uint32());
          break;
        case 32:
          message.UpdatedPatientTask = PatientTask.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 33:
          message.UpdatedStudy = Study.decode(reader, reader.uint32());
          break;
        case 34:
          message.UpdatedAsset = Asset.decode(reader, reader.uint32());
          break;
        case 35:
          message.UpdatedReport = Report.decode(reader, reader.uint32());
          break;
        case 36:
          message.UpdatedTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 38:
          message.UpdatedInvitation = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 39:
          message.UpdatedSharingMember = User.decode(reader, reader.uint32());
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientProfileStreamResp {
    return {
      HistoricalPatient: isSet(object.HistoricalPatient)
        ? Patient.fromJSON(object.HistoricalPatient)
        : undefined,
      HistoricalPatientTask: isSet(object.HistoricalPatientTask)
        ? PatientTask.fromJSON(object.HistoricalPatientTask)
        : undefined,
      EndOfHistoricalPatientTasks: isSet(object.EndOfHistoricalPatientTasks)
        ? Empty.fromJSON(object.EndOfHistoricalPatientTasks)
        : undefined,
      HistoricalStudy: isSet(object.HistoricalStudy)
        ? Study.fromJSON(object.HistoricalStudy)
        : undefined,
      EndOfHistoricalStudies: isSet(object.EndOfHistoricalStudies)
        ? Empty.fromJSON(object.EndOfHistoricalStudies)
        : undefined,
      HistoricalAsset: isSet(object.HistoricalAsset)
        ? Asset.fromJSON(object.HistoricalAsset)
        : undefined,
      EndOfHistoricalAssets: isSet(object.EndOfHistoricalAssets)
        ? Empty.fromJSON(object.EndOfHistoricalAssets)
        : undefined,
      HistoricalReport: isSet(object.HistoricalReport)
        ? Report.fromJSON(object.HistoricalReport)
        : undefined,
      EndOfHistoricalReports: isSet(object.EndOfHistoricalReports)
        ? Empty.fromJSON(object.EndOfHistoricalReports)
        : undefined,
      HistoricalTooth: isSet(object.HistoricalTooth)
        ? Tooth.fromJSON(object.HistoricalTooth)
        : undefined,
      EndOfHistoricalTeeth: isSet(object.EndOfHistoricalTeeth)
        ? Empty.fromJSON(object.EndOfHistoricalTeeth)
        : undefined,
      HistoricalInvitation: isSet(object.HistoricalInvitation)
        ? Invitation.fromJSON(object.HistoricalInvitation)
        : undefined,
      EndOfHistoricalInvitations: isSet(object.EndOfHistoricalInvitations)
        ? Empty.fromJSON(object.EndOfHistoricalInvitations)
        : undefined,
      HistoricalSharingMember: isSet(object.HistoricalSharingMember)
        ? User.fromJSON(object.HistoricalSharingMember)
        : undefined,
      EndOfHistoricalSharingMembers: isSet(object.EndOfHistoricalSharingMembers)
        ? Empty.fromJSON(object.EndOfHistoricalSharingMembers)
        : undefined,
      UpdatedPatient: isSet(object.UpdatedPatient)
        ? Patient.fromJSON(object.UpdatedPatient)
        : undefined,
      UpdatedPatientTask: isSet(object.UpdatedPatientTask)
        ? PatientTask.fromJSON(object.UpdatedPatientTask)
        : undefined,
      UpdatedStudy: isSet(object.UpdatedStudy)
        ? Study.fromJSON(object.UpdatedStudy)
        : undefined,
      UpdatedAsset: isSet(object.UpdatedAsset)
        ? Asset.fromJSON(object.UpdatedAsset)
        : undefined,
      UpdatedReport: isSet(object.UpdatedReport)
        ? Report.fromJSON(object.UpdatedReport)
        : undefined,
      UpdatedTooth: isSet(object.UpdatedTooth)
        ? Tooth.fromJSON(object.UpdatedTooth)
        : undefined,
      UpdatedInvitation: isSet(object.UpdatedInvitation)
        ? Invitation.fromJSON(object.UpdatedInvitation)
        : undefined,
      UpdatedSharingMember: isSet(object.UpdatedSharingMember)
        ? User.fromJSON(object.UpdatedSharingMember)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: PatientProfileStreamResp): unknown {
    const obj: any = {};
    message.HistoricalPatient !== undefined &&
      (obj.HistoricalPatient = message.HistoricalPatient
        ? Patient.toJSON(message.HistoricalPatient)
        : undefined);
    message.HistoricalPatientTask !== undefined &&
      (obj.HistoricalPatientTask = message.HistoricalPatientTask
        ? PatientTask.toJSON(message.HistoricalPatientTask)
        : undefined);
    message.EndOfHistoricalPatientTasks !== undefined &&
      (obj.EndOfHistoricalPatientTasks = message.EndOfHistoricalPatientTasks
        ? Empty.toJSON(message.EndOfHistoricalPatientTasks)
        : undefined);
    message.HistoricalStudy !== undefined &&
      (obj.HistoricalStudy = message.HistoricalStudy
        ? Study.toJSON(message.HistoricalStudy)
        : undefined);
    message.EndOfHistoricalStudies !== undefined &&
      (obj.EndOfHistoricalStudies = message.EndOfHistoricalStudies
        ? Empty.toJSON(message.EndOfHistoricalStudies)
        : undefined);
    message.HistoricalAsset !== undefined &&
      (obj.HistoricalAsset = message.HistoricalAsset
        ? Asset.toJSON(message.HistoricalAsset)
        : undefined);
    message.EndOfHistoricalAssets !== undefined &&
      (obj.EndOfHistoricalAssets = message.EndOfHistoricalAssets
        ? Empty.toJSON(message.EndOfHistoricalAssets)
        : undefined);
    message.HistoricalReport !== undefined &&
      (obj.HistoricalReport = message.HistoricalReport
        ? Report.toJSON(message.HistoricalReport)
        : undefined);
    message.EndOfHistoricalReports !== undefined &&
      (obj.EndOfHistoricalReports = message.EndOfHistoricalReports
        ? Empty.toJSON(message.EndOfHistoricalReports)
        : undefined);
    message.HistoricalTooth !== undefined &&
      (obj.HistoricalTooth = message.HistoricalTooth
        ? Tooth.toJSON(message.HistoricalTooth)
        : undefined);
    message.EndOfHistoricalTeeth !== undefined &&
      (obj.EndOfHistoricalTeeth = message.EndOfHistoricalTeeth
        ? Empty.toJSON(message.EndOfHistoricalTeeth)
        : undefined);
    message.HistoricalInvitation !== undefined &&
      (obj.HistoricalInvitation = message.HistoricalInvitation
        ? Invitation.toJSON(message.HistoricalInvitation)
        : undefined);
    message.EndOfHistoricalInvitations !== undefined &&
      (obj.EndOfHistoricalInvitations = message.EndOfHistoricalInvitations
        ? Empty.toJSON(message.EndOfHistoricalInvitations)
        : undefined);
    message.HistoricalSharingMember !== undefined &&
      (obj.HistoricalSharingMember = message.HistoricalSharingMember
        ? User.toJSON(message.HistoricalSharingMember)
        : undefined);
    message.EndOfHistoricalSharingMembers !== undefined &&
      (obj.EndOfHistoricalSharingMembers = message.EndOfHistoricalSharingMembers
        ? Empty.toJSON(message.EndOfHistoricalSharingMembers)
        : undefined);
    message.UpdatedPatient !== undefined &&
      (obj.UpdatedPatient = message.UpdatedPatient
        ? Patient.toJSON(message.UpdatedPatient)
        : undefined);
    message.UpdatedPatientTask !== undefined &&
      (obj.UpdatedPatientTask = message.UpdatedPatientTask
        ? PatientTask.toJSON(message.UpdatedPatientTask)
        : undefined);
    message.UpdatedStudy !== undefined &&
      (obj.UpdatedStudy = message.UpdatedStudy
        ? Study.toJSON(message.UpdatedStudy)
        : undefined);
    message.UpdatedAsset !== undefined &&
      (obj.UpdatedAsset = message.UpdatedAsset
        ? Asset.toJSON(message.UpdatedAsset)
        : undefined);
    message.UpdatedReport !== undefined &&
      (obj.UpdatedReport = message.UpdatedReport
        ? Report.toJSON(message.UpdatedReport)
        : undefined);
    message.UpdatedTooth !== undefined &&
      (obj.UpdatedTooth = message.UpdatedTooth
        ? Tooth.toJSON(message.UpdatedTooth)
        : undefined);
    message.UpdatedInvitation !== undefined &&
      (obj.UpdatedInvitation = message.UpdatedInvitation
        ? Invitation.toJSON(message.UpdatedInvitation)
        : undefined);
    message.UpdatedSharingMember !== undefined &&
      (obj.UpdatedSharingMember = message.UpdatedSharingMember
        ? User.toJSON(message.UpdatedSharingMember)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientProfileStreamResp>, I>>(
    object: I,
  ): PatientProfileStreamResp {
    const message = createBasePatientProfileStreamResp();
    message.HistoricalPatient =
      object.HistoricalPatient !== undefined &&
      object.HistoricalPatient !== null
        ? Patient.fromPartial(object.HistoricalPatient)
        : undefined;
    message.HistoricalPatientTask =
      object.HistoricalPatientTask !== undefined &&
      object.HistoricalPatientTask !== null
        ? PatientTask.fromPartial(object.HistoricalPatientTask)
        : undefined;
    message.EndOfHistoricalPatientTasks =
      object.EndOfHistoricalPatientTasks !== undefined &&
      object.EndOfHistoricalPatientTasks !== null
        ? Empty.fromPartial(object.EndOfHistoricalPatientTasks)
        : undefined;
    message.HistoricalStudy =
      object.HistoricalStudy !== undefined && object.HistoricalStudy !== null
        ? Study.fromPartial(object.HistoricalStudy)
        : undefined;
    message.EndOfHistoricalStudies =
      object.EndOfHistoricalStudies !== undefined &&
      object.EndOfHistoricalStudies !== null
        ? Empty.fromPartial(object.EndOfHistoricalStudies)
        : undefined;
    message.HistoricalAsset =
      object.HistoricalAsset !== undefined && object.HistoricalAsset !== null
        ? Asset.fromPartial(object.HistoricalAsset)
        : undefined;
    message.EndOfHistoricalAssets =
      object.EndOfHistoricalAssets !== undefined &&
      object.EndOfHistoricalAssets !== null
        ? Empty.fromPartial(object.EndOfHistoricalAssets)
        : undefined;
    message.HistoricalReport =
      object.HistoricalReport !== undefined && object.HistoricalReport !== null
        ? Report.fromPartial(object.HistoricalReport)
        : undefined;
    message.EndOfHistoricalReports =
      object.EndOfHistoricalReports !== undefined &&
      object.EndOfHistoricalReports !== null
        ? Empty.fromPartial(object.EndOfHistoricalReports)
        : undefined;
    message.HistoricalTooth =
      object.HistoricalTooth !== undefined && object.HistoricalTooth !== null
        ? Tooth.fromPartial(object.HistoricalTooth)
        : undefined;
    message.EndOfHistoricalTeeth =
      object.EndOfHistoricalTeeth !== undefined &&
      object.EndOfHistoricalTeeth !== null
        ? Empty.fromPartial(object.EndOfHistoricalTeeth)
        : undefined;
    message.HistoricalInvitation =
      object.HistoricalInvitation !== undefined &&
      object.HistoricalInvitation !== null
        ? Invitation.fromPartial(object.HistoricalInvitation)
        : undefined;
    message.EndOfHistoricalInvitations =
      object.EndOfHistoricalInvitations !== undefined &&
      object.EndOfHistoricalInvitations !== null
        ? Empty.fromPartial(object.EndOfHistoricalInvitations)
        : undefined;
    message.HistoricalSharingMember =
      object.HistoricalSharingMember !== undefined &&
      object.HistoricalSharingMember !== null
        ? User.fromPartial(object.HistoricalSharingMember)
        : undefined;
    message.EndOfHistoricalSharingMembers =
      object.EndOfHistoricalSharingMembers !== undefined &&
      object.EndOfHistoricalSharingMembers !== null
        ? Empty.fromPartial(object.EndOfHistoricalSharingMembers)
        : undefined;
    message.UpdatedPatient =
      object.UpdatedPatient !== undefined && object.UpdatedPatient !== null
        ? Patient.fromPartial(object.UpdatedPatient)
        : undefined;
    message.UpdatedPatientTask =
      object.UpdatedPatientTask !== undefined &&
      object.UpdatedPatientTask !== null
        ? PatientTask.fromPartial(object.UpdatedPatientTask)
        : undefined;
    message.UpdatedStudy =
      object.UpdatedStudy !== undefined && object.UpdatedStudy !== null
        ? Study.fromPartial(object.UpdatedStudy)
        : undefined;
    message.UpdatedAsset =
      object.UpdatedAsset !== undefined && object.UpdatedAsset !== null
        ? Asset.fromPartial(object.UpdatedAsset)
        : undefined;
    message.UpdatedReport =
      object.UpdatedReport !== undefined && object.UpdatedReport !== null
        ? Report.fromPartial(object.UpdatedReport)
        : undefined;
    message.UpdatedTooth =
      object.UpdatedTooth !== undefined && object.UpdatedTooth !== null
        ? Tooth.fromPartial(object.UpdatedTooth)
        : undefined;
    message.UpdatedInvitation =
      object.UpdatedInvitation !== undefined &&
      object.UpdatedInvitation !== null
        ? Invitation.fromPartial(object.UpdatedInvitation)
        : undefined;
    message.UpdatedSharingMember =
      object.UpdatedSharingMember !== undefined &&
      object.UpdatedSharingMember !== null
        ? User.fromPartial(object.UpdatedSharingMember)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBasePatientListStreamReq(): PatientListStreamReq {
  return {
    ResumeToken: '',
    OrganizationID: '',
    SearchString: '',
    AllDoctors: undefined,
    AllExceptDoctors: undefined,
    OnlyTheseDoctors: undefined,
    Sort: [],
    StartFromPatientID: '',
    Limit: 0,
  };
}

export const PatientListStreamReq = {
  encode(
    message: PatientListStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.SearchString !== '') {
      writer.uint32(26).string(message.SearchString);
    }
    if (message.AllDoctors !== undefined) {
      Empty.encode(message.AllDoctors, writer.uint32(170).fork()).ldelim();
    }
    if (message.AllExceptDoctors !== undefined) {
      PatientListStreamReq_DoctorIDs.encode(
        message.AllExceptDoctors,
        writer.uint32(178).fork(),
      ).ldelim();
    }
    if (message.OnlyTheseDoctors !== undefined) {
      PatientListStreamReq_DoctorIDs.encode(
        message.OnlyTheseDoctors,
        writer.uint32(186).fork(),
      ).ldelim();
    }
    for (const v of message.Sort) {
      PatientListStreamSort.encode(v!, writer.uint32(250).fork()).ldelim();
    }
    if (message.StartFromPatientID !== '') {
      writer.uint32(802).string(message.StartFromPatientID);
    }
    if (message.Limit !== 0) {
      writer.uint32(808).uint32(message.Limit);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.SearchString = reader.string();
          break;
        case 21:
          message.AllDoctors = Empty.decode(reader, reader.uint32());
          break;
        case 22:
          message.AllExceptDoctors = PatientListStreamReq_DoctorIDs.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 23:
          message.OnlyTheseDoctors = PatientListStreamReq_DoctorIDs.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 31:
          message.Sort.push(
            PatientListStreamSort.decode(reader, reader.uint32()),
          );
          break;
        case 100:
          message.StartFromPatientID = reader.string();
          break;
        case 101:
          message.Limit = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamReq {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      SearchString: isSet(object.SearchString)
        ? String(object.SearchString)
        : '',
      AllDoctors: isSet(object.AllDoctors)
        ? Empty.fromJSON(object.AllDoctors)
        : undefined,
      AllExceptDoctors: isSet(object.AllExceptDoctors)
        ? PatientListStreamReq_DoctorIDs.fromJSON(object.AllExceptDoctors)
        : undefined,
      OnlyTheseDoctors: isSet(object.OnlyTheseDoctors)
        ? PatientListStreamReq_DoctorIDs.fromJSON(object.OnlyTheseDoctors)
        : undefined,
      Sort: Array.isArray(object?.Sort)
        ? object.Sort.map((e: any) => PatientListStreamSort.fromJSON(e))
        : [],
      StartFromPatientID: isSet(object.StartFromPatientID)
        ? String(object.StartFromPatientID)
        : '',
      Limit: isSet(object.Limit) ? Number(object.Limit) : 0,
    };
  },

  toJSON(message: PatientListStreamReq): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.SearchString !== undefined &&
      (obj.SearchString = message.SearchString);
    message.AllDoctors !== undefined &&
      (obj.AllDoctors = message.AllDoctors
        ? Empty.toJSON(message.AllDoctors)
        : undefined);
    message.AllExceptDoctors !== undefined &&
      (obj.AllExceptDoctors = message.AllExceptDoctors
        ? PatientListStreamReq_DoctorIDs.toJSON(message.AllExceptDoctors)
        : undefined);
    message.OnlyTheseDoctors !== undefined &&
      (obj.OnlyTheseDoctors = message.OnlyTheseDoctors
        ? PatientListStreamReq_DoctorIDs.toJSON(message.OnlyTheseDoctors)
        : undefined);
    if (message.Sort) {
      obj.Sort = message.Sort.map((e) =>
        e ? PatientListStreamSort.toJSON(e) : undefined,
      );
    } else {
      obj.Sort = [];
    }
    message.StartFromPatientID !== undefined &&
      (obj.StartFromPatientID = message.StartFromPatientID);
    message.Limit !== undefined && (obj.Limit = Math.round(message.Limit));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientListStreamReq>, I>>(
    object: I,
  ): PatientListStreamReq {
    const message = createBasePatientListStreamReq();
    message.ResumeToken = object.ResumeToken ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.SearchString = object.SearchString ?? '';
    message.AllDoctors =
      object.AllDoctors !== undefined && object.AllDoctors !== null
        ? Empty.fromPartial(object.AllDoctors)
        : undefined;
    message.AllExceptDoctors =
      object.AllExceptDoctors !== undefined && object.AllExceptDoctors !== null
        ? PatientListStreamReq_DoctorIDs.fromPartial(object.AllExceptDoctors)
        : undefined;
    message.OnlyTheseDoctors =
      object.OnlyTheseDoctors !== undefined && object.OnlyTheseDoctors !== null
        ? PatientListStreamReq_DoctorIDs.fromPartial(object.OnlyTheseDoctors)
        : undefined;
    message.Sort =
      object.Sort?.map((e) => PatientListStreamSort.fromPartial(e)) || [];
    message.StartFromPatientID = object.StartFromPatientID ?? '';
    message.Limit = object.Limit ?? 0;
    return message;
  },
};

function createBasePatientListStreamReq_DoctorIDs(): PatientListStreamReq_DoctorIDs {
  return { DoctorIDs: [] };
}

export const PatientListStreamReq_DoctorIDs = {
  encode(
    message: PatientListStreamReq_DoctorIDs,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.DoctorIDs) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamReq_DoctorIDs {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamReq_DoctorIDs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DoctorIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamReq_DoctorIDs {
    return {
      DoctorIDs: Array.isArray(object?.DoctorIDs)
        ? object.DoctorIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: PatientListStreamReq_DoctorIDs): unknown {
    const obj: any = {};
    if (message.DoctorIDs) {
      obj.DoctorIDs = message.DoctorIDs.map((e) => e);
    } else {
      obj.DoctorIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientListStreamReq_DoctorIDs>, I>>(
    object: I,
  ): PatientListStreamReq_DoctorIDs {
    const message = createBasePatientListStreamReq_DoctorIDs();
    message.DoctorIDs = object.DoctorIDs?.map((e) => e) || [];
    return message;
  },
};

function createBasePatientListStreamSort(): PatientListStreamSort {
  return { By: 0, Ascending: false };
}

export const PatientListStreamSort = {
  encode(
    message: PatientListStreamSort,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.By !== 0) {
      writer.uint32(8).int32(message.By);
    }
    if (message.Ascending === true) {
      writer.uint32(16).bool(message.Ascending);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamSort {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamSort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.By = reader.int32() as any;
          break;
        case 2:
          message.Ascending = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamSort {
    return {
      By: isSet(object.By) ? patientListStreamSortByFromJSON(object.By) : 0,
      Ascending: isSet(object.Ascending) ? Boolean(object.Ascending) : false,
    };
  },

  toJSON(message: PatientListStreamSort): unknown {
    const obj: any = {};
    message.By !== undefined &&
      (obj.By = patientListStreamSortByToJSON(message.By));
    message.Ascending !== undefined && (obj.Ascending = message.Ascending);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientListStreamSort>, I>>(
    object: I,
  ): PatientListStreamSort {
    const message = createBasePatientListStreamSort();
    message.By = object.By ?? 0;
    message.Ascending = object.Ascending ?? false;
    return message;
  },
};

function createBasePatientListStreamResp(): PatientListStreamResp {
  return {
    ResumeToken: '',
    InitialPatientsListItems: undefined,
    PatientUpdated: undefined,
    TotalOrganizationPatientCount: undefined,
    SharedWithMeCount: undefined,
    SharedByMeCount: undefined,
    Ping: undefined,
  };
}

export const PatientListStreamResp = {
  encode(
    message: PatientListStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.InitialPatientsListItems !== undefined) {
      PatientListStreamResp_PatientsListItems.encode(
        message.InitialPatientsListItems,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.PatientUpdated !== undefined) {
      PatientListStreamResp_PatientItem.encode(
        message.PatientUpdated,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.TotalOrganizationPatientCount !== undefined) {
      writer.uint32(168).uint32(message.TotalOrganizationPatientCount);
    }
    if (message.SharedWithMeCount !== undefined) {
      writer.uint32(176).uint32(message.SharedWithMeCount);
    }
    if (message.SharedByMeCount !== undefined) {
      writer.uint32(184).uint32(message.SharedByMeCount);
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 10:
          message.InitialPatientsListItems =
            PatientListStreamResp_PatientsListItems.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 11:
          message.PatientUpdated = PatientListStreamResp_PatientItem.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 21:
          message.TotalOrganizationPatientCount = reader.uint32();
          break;
        case 22:
          message.SharedWithMeCount = reader.uint32();
          break;
        case 23:
          message.SharedByMeCount = reader.uint32();
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      InitialPatientsListItems: isSet(object.InitialPatientsListItems)
        ? PatientListStreamResp_PatientsListItems.fromJSON(
            object.InitialPatientsListItems,
          )
        : undefined,
      PatientUpdated: isSet(object.PatientUpdated)
        ? PatientListStreamResp_PatientItem.fromJSON(object.PatientUpdated)
        : undefined,
      TotalOrganizationPatientCount: isSet(object.TotalOrganizationPatientCount)
        ? Number(object.TotalOrganizationPatientCount)
        : undefined,
      SharedWithMeCount: isSet(object.SharedWithMeCount)
        ? Number(object.SharedWithMeCount)
        : undefined,
      SharedByMeCount: isSet(object.SharedByMeCount)
        ? Number(object.SharedByMeCount)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: PatientListStreamResp): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.InitialPatientsListItems !== undefined &&
      (obj.InitialPatientsListItems = message.InitialPatientsListItems
        ? PatientListStreamResp_PatientsListItems.toJSON(
            message.InitialPatientsListItems,
          )
        : undefined);
    message.PatientUpdated !== undefined &&
      (obj.PatientUpdated = message.PatientUpdated
        ? PatientListStreamResp_PatientItem.toJSON(message.PatientUpdated)
        : undefined);
    message.TotalOrganizationPatientCount !== undefined &&
      (obj.TotalOrganizationPatientCount = Math.round(
        message.TotalOrganizationPatientCount,
      ));
    message.SharedWithMeCount !== undefined &&
      (obj.SharedWithMeCount = Math.round(message.SharedWithMeCount));
    message.SharedByMeCount !== undefined &&
      (obj.SharedByMeCount = Math.round(message.SharedByMeCount));
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientListStreamResp>, I>>(
    object: I,
  ): PatientListStreamResp {
    const message = createBasePatientListStreamResp();
    message.ResumeToken = object.ResumeToken ?? '';
    message.InitialPatientsListItems =
      object.InitialPatientsListItems !== undefined &&
      object.InitialPatientsListItems !== null
        ? PatientListStreamResp_PatientsListItems.fromPartial(
            object.InitialPatientsListItems,
          )
        : undefined;
    message.PatientUpdated =
      object.PatientUpdated !== undefined && object.PatientUpdated !== null
        ? PatientListStreamResp_PatientItem.fromPartial(object.PatientUpdated)
        : undefined;
    message.TotalOrganizationPatientCount =
      object.TotalOrganizationPatientCount ?? undefined;
    message.SharedWithMeCount = object.SharedWithMeCount ?? undefined;
    message.SharedByMeCount = object.SharedByMeCount ?? undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBasePatientListStreamResp_PatientItem(): PatientListStreamResp_PatientItem {
  return { Patient: undefined, Studies: [], Tasks: undefined };
}

export const PatientListStreamResp_PatientItem = {
  encode(
    message: PatientListStreamResp_PatientItem,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Studies) {
      PatientListStreamResp_PatientItem_StudyCount.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Tasks !== undefined) {
      PatientListStreamResp_PatientItem_TasksCounts.encode(
        message.Tasks,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp_PatientItem {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp_PatientItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        case 2:
          message.Studies.push(
            PatientListStreamResp_PatientItem_StudyCount.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 3:
          message.Tasks = PatientListStreamResp_PatientItem_TasksCounts.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp_PatientItem {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
      Studies: Array.isArray(object?.Studies)
        ? object.Studies.map((e: any) =>
            PatientListStreamResp_PatientItem_StudyCount.fromJSON(e),
          )
        : [],
      Tasks: isSet(object.Tasks)
        ? PatientListStreamResp_PatientItem_TasksCounts.fromJSON(object.Tasks)
        : undefined,
    };
  },

  toJSON(message: PatientListStreamResp_PatientItem): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    if (message.Studies) {
      obj.Studies = message.Studies.map((e) =>
        e ? PatientListStreamResp_PatientItem_StudyCount.toJSON(e) : undefined,
      );
    } else {
      obj.Studies = [];
    }
    message.Tasks !== undefined &&
      (obj.Tasks = message.Tasks
        ? PatientListStreamResp_PatientItem_TasksCounts.toJSON(message.Tasks)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<PatientListStreamResp_PatientItem>, I>,
  >(object: I): PatientListStreamResp_PatientItem {
    const message = createBasePatientListStreamResp_PatientItem();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    message.Studies =
      object.Studies?.map((e) =>
        PatientListStreamResp_PatientItem_StudyCount.fromPartial(e),
      ) || [];
    message.Tasks =
      object.Tasks !== undefined && object.Tasks !== null
        ? PatientListStreamResp_PatientItem_TasksCounts.fromPartial(
            object.Tasks,
          )
        : undefined;
    return message;
  },
};

function createBasePatientListStreamResp_PatientItem_StudyCount(): PatientListStreamResp_PatientItem_StudyCount {
  return { Modality: 0, Count: 0 };
}

export const PatientListStreamResp_PatientItem_StudyCount = {
  encode(
    message: PatientListStreamResp_PatientItem_StudyCount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Modality !== 0) {
      writer.uint32(8).int32(message.Modality);
    }
    if (message.Count !== 0) {
      writer.uint32(16).uint32(message.Count);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp_PatientItem_StudyCount {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp_PatientItem_StudyCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Modality = reader.int32() as any;
          break;
        case 2:
          message.Count = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp_PatientItem_StudyCount {
    return {
      Modality: isSet(object.Modality) ? studyTypeFromJSON(object.Modality) : 0,
      Count: isSet(object.Count) ? Number(object.Count) : 0,
    };
  },

  toJSON(message: PatientListStreamResp_PatientItem_StudyCount): unknown {
    const obj: any = {};
    message.Modality !== undefined &&
      (obj.Modality = studyTypeToJSON(message.Modality));
    message.Count !== undefined && (obj.Count = Math.round(message.Count));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PatientListStreamResp_PatientItem_StudyCount>,
      I
    >,
  >(object: I): PatientListStreamResp_PatientItem_StudyCount {
    const message = createBasePatientListStreamResp_PatientItem_StudyCount();
    message.Modality = object.Modality ?? 0;
    message.Count = object.Count ?? 0;
    return message;
  },
};

function createBasePatientListStreamResp_PatientItem_TasksCounts(): PatientListStreamResp_PatientItem_TasksCounts {
  return { All: 0, Done: 0, Overdue: 0 };
}

export const PatientListStreamResp_PatientItem_TasksCounts = {
  encode(
    message: PatientListStreamResp_PatientItem_TasksCounts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.All !== 0) {
      writer.uint32(8).uint32(message.All);
    }
    if (message.Done !== 0) {
      writer.uint32(16).uint32(message.Done);
    }
    if (message.Overdue !== 0) {
      writer.uint32(24).uint32(message.Overdue);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp_PatientItem_TasksCounts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp_PatientItem_TasksCounts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.All = reader.uint32();
          break;
        case 2:
          message.Done = reader.uint32();
          break;
        case 3:
          message.Overdue = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp_PatientItem_TasksCounts {
    return {
      All: isSet(object.All) ? Number(object.All) : 0,
      Done: isSet(object.Done) ? Number(object.Done) : 0,
      Overdue: isSet(object.Overdue) ? Number(object.Overdue) : 0,
    };
  },

  toJSON(message: PatientListStreamResp_PatientItem_TasksCounts): unknown {
    const obj: any = {};
    message.All !== undefined && (obj.All = Math.round(message.All));
    message.Done !== undefined && (obj.Done = Math.round(message.Done));
    message.Overdue !== undefined &&
      (obj.Overdue = Math.round(message.Overdue));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PatientListStreamResp_PatientItem_TasksCounts>,
      I
    >,
  >(object: I): PatientListStreamResp_PatientItem_TasksCounts {
    const message = createBasePatientListStreamResp_PatientItem_TasksCounts();
    message.All = object.All ?? 0;
    message.Done = object.Done ?? 0;
    message.Overdue = object.Overdue ?? 0;
    return message;
  },
};

function createBasePatientListStreamResp_PatientsListItems(): PatientListStreamResp_PatientsListItems {
  return { Patients: [] };
}

export const PatientListStreamResp_PatientsListItems = {
  encode(
    message: PatientListStreamResp_PatientsListItems,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Patients) {
      PatientListStreamResp_PatientItem.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp_PatientsListItems {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp_PatientsListItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patients.push(
            PatientListStreamResp_PatientItem.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp_PatientsListItems {
    return {
      Patients: Array.isArray(object?.Patients)
        ? object.Patients.map((e: any) =>
            PatientListStreamResp_PatientItem.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: PatientListStreamResp_PatientsListItems): unknown {
    const obj: any = {};
    if (message.Patients) {
      obj.Patients = message.Patients.map((e) =>
        e ? PatientListStreamResp_PatientItem.toJSON(e) : undefined,
      );
    } else {
      obj.Patients = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<PatientListStreamResp_PatientsListItems>, I>,
  >(object: I): PatientListStreamResp_PatientsListItems {
    const message = createBasePatientListStreamResp_PatientsListItems();
    message.Patients =
      object.Patients?.map((e) =>
        PatientListStreamResp_PatientItem.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseCreatePatientReq(): CreatePatientReq {
  return {
    OrganizationID: '',
    PersonalData: undefined,
    Gender: 0,
    ExternalID: '',
    TreatingDoctorIDs: [],
    Attributes: [],
  };
}

export const CreatePatientReq = {
  encode(
    message: CreatePatientReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.PersonalData !== undefined) {
      PersonalData.encode(
        message.PersonalData,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Gender !== 0) {
      writer.uint32(24).int32(message.Gender);
    }
    if (message.ExternalID !== '') {
      writer.uint32(34).string(message.ExternalID);
    }
    for (const v of message.TreatingDoctorIDs) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.Attributes) {
      Attribute.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePatientReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.PersonalData = PersonalData.decode(reader, reader.uint32());
          break;
        case 3:
          message.Gender = reader.int32() as any;
          break;
        case 4:
          message.ExternalID = reader.string();
          break;
        case 5:
          message.TreatingDoctorIDs.push(reader.string());
          break;
        case 10:
          message.Attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PersonalData: isSet(object.PersonalData)
        ? PersonalData.fromJSON(object.PersonalData)
        : undefined,
      Gender: isSet(object.Gender) ? genderFromJSON(object.Gender) : 0,
      ExternalID: isSet(object.ExternalID) ? String(object.ExternalID) : '',
      TreatingDoctorIDs: Array.isArray(object?.TreatingDoctorIDs)
        ? object.TreatingDoctorIDs.map((e: any) => String(e))
        : [],
      Attributes: Array.isArray(object?.Attributes)
        ? object.Attributes.map((e: any) => Attribute.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CreatePatientReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PersonalData !== undefined &&
      (obj.PersonalData = message.PersonalData
        ? PersonalData.toJSON(message.PersonalData)
        : undefined);
    message.Gender !== undefined && (obj.Gender = genderToJSON(message.Gender));
    message.ExternalID !== undefined && (obj.ExternalID = message.ExternalID);
    if (message.TreatingDoctorIDs) {
      obj.TreatingDoctorIDs = message.TreatingDoctorIDs.map((e) => e);
    } else {
      obj.TreatingDoctorIDs = [];
    }
    if (message.Attributes) {
      obj.Attributes = message.Attributes.map((e) =>
        e ? Attribute.toJSON(e) : undefined,
      );
    } else {
      obj.Attributes = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientReq>, I>>(
    object: I,
  ): CreatePatientReq {
    const message = createBaseCreatePatientReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.PersonalData =
      object.PersonalData !== undefined && object.PersonalData !== null
        ? PersonalData.fromPartial(object.PersonalData)
        : undefined;
    message.Gender = object.Gender ?? 0;
    message.ExternalID = object.ExternalID ?? '';
    message.TreatingDoctorIDs = object.TreatingDoctorIDs?.map((e) => e) || [];
    message.Attributes =
      object.Attributes?.map((e) => Attribute.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreatePatientResp(): CreatePatientResp {
  return { Patient: undefined };
}

export const CreatePatientResp = {
  encode(
    message: CreatePatientResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePatientResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: CreatePatientResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientResp>, I>>(
    object: I,
  ): CreatePatientResp {
    const message = createBaseCreatePatientResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientPersonalDataReq(): SetPatientPersonalDataReq {
  return { PatientID: '', PersonalData: undefined };
}

export const SetPatientPersonalDataReq = {
  encode(
    message: SetPatientPersonalDataReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.PersonalData !== undefined) {
      PersonalData.encode(
        message.PersonalData,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientPersonalDataReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientPersonalDataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.PersonalData = PersonalData.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientPersonalDataReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      PersonalData: isSet(object.PersonalData)
        ? PersonalData.fromJSON(object.PersonalData)
        : undefined,
    };
  },

  toJSON(message: SetPatientPersonalDataReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.PersonalData !== undefined &&
      (obj.PersonalData = message.PersonalData
        ? PersonalData.toJSON(message.PersonalData)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientPersonalDataReq>, I>>(
    object: I,
  ): SetPatientPersonalDataReq {
    const message = createBaseSetPatientPersonalDataReq();
    message.PatientID = object.PatientID ?? '';
    message.PersonalData =
      object.PersonalData !== undefined && object.PersonalData !== null
        ? PersonalData.fromPartial(object.PersonalData)
        : undefined;
    return message;
  },
};

function createBaseSetPatientPersonalDataResp(): SetPatientPersonalDataResp {
  return { Patient: undefined, Invitations: [] };
}

export const SetPatientPersonalDataResp = {
  encode(
    message: SetPatientPersonalDataResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Invitations) {
      Invitation.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientPersonalDataResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientPersonalDataResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        case 2:
          message.Invitations.push(Invitation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientPersonalDataResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
      Invitations: Array.isArray(object?.Invitations)
        ? object.Invitations.map((e: any) => Invitation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetPatientPersonalDataResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    if (message.Invitations) {
      obj.Invitations = message.Invitations.map((e) =>
        e ? Invitation.toJSON(e) : undefined,
      );
    } else {
      obj.Invitations = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientPersonalDataResp>, I>>(
    object: I,
  ): SetPatientPersonalDataResp {
    const message = createBaseSetPatientPersonalDataResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    message.Invitations =
      object.Invitations?.map((e) => Invitation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetPatientExternalIDReq(): SetPatientExternalIDReq {
  return { PatientID: '', ExternalID: '' };
}

export const SetPatientExternalIDReq = {
  encode(
    message: SetPatientExternalIDReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.ExternalID !== '') {
      writer.uint32(18).string(message.ExternalID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientExternalIDReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientExternalIDReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.ExternalID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientExternalIDReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      ExternalID: isSet(object.ExternalID) ? String(object.ExternalID) : '',
    };
  },

  toJSON(message: SetPatientExternalIDReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.ExternalID !== undefined && (obj.ExternalID = message.ExternalID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientExternalIDReq>, I>>(
    object: I,
  ): SetPatientExternalIDReq {
    const message = createBaseSetPatientExternalIDReq();
    message.PatientID = object.PatientID ?? '';
    message.ExternalID = object.ExternalID ?? '';
    return message;
  },
};

function createBaseSetPatientExternalIDResp(): SetPatientExternalIDResp {
  return { Patient: undefined };
}

export const SetPatientExternalIDResp = {
  encode(
    message: SetPatientExternalIDResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientExternalIDResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientExternalIDResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientExternalIDResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientExternalIDResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientExternalIDResp>, I>>(
    object: I,
  ): SetPatientExternalIDResp {
    const message = createBaseSetPatientExternalIDResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientGenderReq(): SetPatientGenderReq {
  return { PatientID: '', Gender: 0 };
}

export const SetPatientGenderReq = {
  encode(
    message: SetPatientGenderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.Gender !== 0) {
      writer.uint32(16).int32(message.Gender);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPatientGenderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientGenderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.Gender = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientGenderReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Gender: isSet(object.Gender) ? genderFromJSON(object.Gender) : 0,
    };
  },

  toJSON(message: SetPatientGenderReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Gender !== undefined && (obj.Gender = genderToJSON(message.Gender));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientGenderReq>, I>>(
    object: I,
  ): SetPatientGenderReq {
    const message = createBaseSetPatientGenderReq();
    message.PatientID = object.PatientID ?? '';
    message.Gender = object.Gender ?? 0;
    return message;
  },
};

function createBaseSetPatientGenderResp(): SetPatientGenderResp {
  return { Patient: undefined };
}

export const SetPatientGenderResp = {
  encode(
    message: SetPatientGenderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientGenderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientGenderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientGenderResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientGenderResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientGenderResp>, I>>(
    object: I,
  ): SetPatientGenderResp {
    const message = createBaseSetPatientGenderResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientStatusReq(): SetPatientStatusReq {
  return { PatientID: '', Status: 0 };
}

export const SetPatientStatusReq = {
  encode(
    message: SetPatientStatusReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.Status !== 0) {
      writer.uint32(16).int32(message.Status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPatientStatusReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientStatusReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.Status = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientStatusReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Status: isSet(object.Status) ? patientStatusFromJSON(object.Status) : 0,
    };
  },

  toJSON(message: SetPatientStatusReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Status !== undefined &&
      (obj.Status = patientStatusToJSON(message.Status));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientStatusReq>, I>>(
    object: I,
  ): SetPatientStatusReq {
    const message = createBaseSetPatientStatusReq();
    message.PatientID = object.PatientID ?? '';
    message.Status = object.Status ?? 0;
    return message;
  },
};

function createBaseSetPatientStatusResp(): SetPatientStatusResp {
  return { Patient: undefined };
}

export const SetPatientStatusResp = {
  encode(
    message: SetPatientStatusResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientStatusResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientStatusResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientStatusResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientStatusResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientStatusResp>, I>>(
    object: I,
  ): SetPatientStatusResp {
    const message = createBaseSetPatientStatusResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientClinicalCaseDescriptionReq(): SetPatientClinicalCaseDescriptionReq {
  return { PatientID: '', ClinicalCaseDescription: '' };
}

export const SetPatientClinicalCaseDescriptionReq = {
  encode(
    message: SetPatientClinicalCaseDescriptionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.ClinicalCaseDescription !== '') {
      writer.uint32(18).string(message.ClinicalCaseDescription);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientClinicalCaseDescriptionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientClinicalCaseDescriptionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.ClinicalCaseDescription = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientClinicalCaseDescriptionReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      ClinicalCaseDescription: isSet(object.ClinicalCaseDescription)
        ? String(object.ClinicalCaseDescription)
        : '',
    };
  },

  toJSON(message: SetPatientClinicalCaseDescriptionReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.ClinicalCaseDescription !== undefined &&
      (obj.ClinicalCaseDescription = message.ClinicalCaseDescription);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetPatientClinicalCaseDescriptionReq>, I>,
  >(object: I): SetPatientClinicalCaseDescriptionReq {
    const message = createBaseSetPatientClinicalCaseDescriptionReq();
    message.PatientID = object.PatientID ?? '';
    message.ClinicalCaseDescription = object.ClinicalCaseDescription ?? '';
    return message;
  },
};

function createBaseSetPatientClinicalCaseDescriptionResp(): SetPatientClinicalCaseDescriptionResp {
  return { Patient: undefined };
}

export const SetPatientClinicalCaseDescriptionResp = {
  encode(
    message: SetPatientClinicalCaseDescriptionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientClinicalCaseDescriptionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientClinicalCaseDescriptionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientClinicalCaseDescriptionResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientClinicalCaseDescriptionResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetPatientClinicalCaseDescriptionResp>, I>,
  >(object: I): SetPatientClinicalCaseDescriptionResp {
    const message = createBaseSetPatientClinicalCaseDescriptionResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientTreatmentDueDateReq(): SetPatientTreatmentDueDateReq {
  return { PatientID: '', TreatmentDueDate: undefined };
}

export const SetPatientTreatmentDueDateReq = {
  encode(
    message: SetPatientTreatmentDueDateReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.TreatmentDueDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.TreatmentDueDate),
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTreatmentDueDateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTreatmentDueDateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.TreatmentDueDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTreatmentDueDateReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      TreatmentDueDate: isSet(object.TreatmentDueDate)
        ? fromJsonTimestamp(object.TreatmentDueDate)
        : undefined,
    };
  },

  toJSON(message: SetPatientTreatmentDueDateReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.TreatmentDueDate !== undefined &&
      (obj.TreatmentDueDate = message.TreatmentDueDate.toISOString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTreatmentDueDateReq>, I>>(
    object: I,
  ): SetPatientTreatmentDueDateReq {
    const message = createBaseSetPatientTreatmentDueDateReq();
    message.PatientID = object.PatientID ?? '';
    message.TreatmentDueDate = object.TreatmentDueDate ?? undefined;
    return message;
  },
};

function createBaseSetPatientTreatmentDueDateResp(): SetPatientTreatmentDueDateResp {
  return { Patient: undefined };
}

export const SetPatientTreatmentDueDateResp = {
  encode(
    message: SetPatientTreatmentDueDateResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTreatmentDueDateResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTreatmentDueDateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTreatmentDueDateResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientTreatmentDueDateResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTreatmentDueDateResp>, I>>(
    object: I,
  ): SetPatientTreatmentDueDateResp {
    const message = createBaseSetPatientTreatmentDueDateResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseDeletePatientReq(): DeletePatientReq {
  return { PatientID: '' };
}

export const DeletePatientReq = {
  encode(
    message: DeletePatientReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletePatientReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePatientReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletePatientReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
    };
  },

  toJSON(message: DeletePatientReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeletePatientReq>, I>>(
    object: I,
  ): DeletePatientReq {
    const message = createBaseDeletePatientReq();
    message.PatientID = object.PatientID ?? '';
    return message;
  },
};

function createBaseDeletePatientResp(): DeletePatientResp {
  return {
    DeletedPatientID: '',
    DeletedStudyIDs: [],
    DeletedReportIDs: [],
    DeletedToothIDs: [],
    DeletedConditionIDs: [],
    DeletedTeethLandmarkIDs: [],
    DeletedAssetIDs: [],
    DeletedInvitationIDs: [],
  };
}

export const DeletePatientResp = {
  encode(
    message: DeletePatientResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DeletedPatientID !== '') {
      writer.uint32(10).string(message.DeletedPatientID);
    }
    for (const v of message.DeletedStudyIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.DeletedReportIDs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.DeletedToothIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.DeletedConditionIDs) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.DeletedTeethLandmarkIDs) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.DeletedAssetIDs) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.DeletedInvitationIDs) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletePatientResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePatientResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DeletedPatientID = reader.string();
          break;
        case 2:
          message.DeletedStudyIDs.push(reader.string());
          break;
        case 3:
          message.DeletedReportIDs.push(reader.string());
          break;
        case 4:
          message.DeletedToothIDs.push(reader.string());
          break;
        case 5:
          message.DeletedConditionIDs.push(reader.string());
          break;
        case 8:
          message.DeletedTeethLandmarkIDs.push(reader.string());
          break;
        case 6:
          message.DeletedAssetIDs.push(reader.string());
          break;
        case 7:
          message.DeletedInvitationIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletePatientResp {
    return {
      DeletedPatientID: isSet(object.DeletedPatientID)
        ? String(object.DeletedPatientID)
        : '',
      DeletedStudyIDs: Array.isArray(object?.DeletedStudyIDs)
        ? object.DeletedStudyIDs.map((e: any) => String(e))
        : [],
      DeletedReportIDs: Array.isArray(object?.DeletedReportIDs)
        ? object.DeletedReportIDs.map((e: any) => String(e))
        : [],
      DeletedToothIDs: Array.isArray(object?.DeletedToothIDs)
        ? object.DeletedToothIDs.map((e: any) => String(e))
        : [],
      DeletedConditionIDs: Array.isArray(object?.DeletedConditionIDs)
        ? object.DeletedConditionIDs.map((e: any) => String(e))
        : [],
      DeletedTeethLandmarkIDs: Array.isArray(object?.DeletedTeethLandmarkIDs)
        ? object.DeletedTeethLandmarkIDs.map((e: any) => String(e))
        : [],
      DeletedAssetIDs: Array.isArray(object?.DeletedAssetIDs)
        ? object.DeletedAssetIDs.map((e: any) => String(e))
        : [],
      DeletedInvitationIDs: Array.isArray(object?.DeletedInvitationIDs)
        ? object.DeletedInvitationIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: DeletePatientResp): unknown {
    const obj: any = {};
    message.DeletedPatientID !== undefined &&
      (obj.DeletedPatientID = message.DeletedPatientID);
    if (message.DeletedStudyIDs) {
      obj.DeletedStudyIDs = message.DeletedStudyIDs.map((e) => e);
    } else {
      obj.DeletedStudyIDs = [];
    }
    if (message.DeletedReportIDs) {
      obj.DeletedReportIDs = message.DeletedReportIDs.map((e) => e);
    } else {
      obj.DeletedReportIDs = [];
    }
    if (message.DeletedToothIDs) {
      obj.DeletedToothIDs = message.DeletedToothIDs.map((e) => e);
    } else {
      obj.DeletedToothIDs = [];
    }
    if (message.DeletedConditionIDs) {
      obj.DeletedConditionIDs = message.DeletedConditionIDs.map((e) => e);
    } else {
      obj.DeletedConditionIDs = [];
    }
    if (message.DeletedTeethLandmarkIDs) {
      obj.DeletedTeethLandmarkIDs = message.DeletedTeethLandmarkIDs.map(
        (e) => e,
      );
    } else {
      obj.DeletedTeethLandmarkIDs = [];
    }
    if (message.DeletedAssetIDs) {
      obj.DeletedAssetIDs = message.DeletedAssetIDs.map((e) => e);
    } else {
      obj.DeletedAssetIDs = [];
    }
    if (message.DeletedInvitationIDs) {
      obj.DeletedInvitationIDs = message.DeletedInvitationIDs.map((e) => e);
    } else {
      obj.DeletedInvitationIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeletePatientResp>, I>>(
    object: I,
  ): DeletePatientResp {
    const message = createBaseDeletePatientResp();
    message.DeletedPatientID = object.DeletedPatientID ?? '';
    message.DeletedStudyIDs = object.DeletedStudyIDs?.map((e) => e) || [];
    message.DeletedReportIDs = object.DeletedReportIDs?.map((e) => e) || [];
    message.DeletedToothIDs = object.DeletedToothIDs?.map((e) => e) || [];
    message.DeletedConditionIDs =
      object.DeletedConditionIDs?.map((e) => e) || [];
    message.DeletedTeethLandmarkIDs =
      object.DeletedTeethLandmarkIDs?.map((e) => e) || [];
    message.DeletedAssetIDs = object.DeletedAssetIDs?.map((e) => e) || [];
    message.DeletedInvitationIDs =
      object.DeletedInvitationIDs?.map((e) => e) || [];
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: PatientID
 * - type: NotFoundError, Entity: patient (if patient not found by PatientID)
 * - type: BadRequestError, Reason: patient deleted (if remote patient found by PatientID)
 */
export interface PatientService {
  /**
   * Get stream with information about patient, his projects, sharing members, invitations, studies and reports
   *
   * Permission: `PatientPermissions.CanView`
   */
  PatientProfileStream(
    request: DeepPartial<PatientProfileStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientProfileStreamResp>;
  /**
   * Get list of patients from available organizations
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: PatientStatus - (if `ListPatientsReq.Filters.PatientStatuses` contains `PatientStatusInvalidValue`)
   * - type: InvalidArgumentValueError, Argument: StudyType - (if `ListPatientsReq.Filters.StudyTypes` contains `StudyTypeInvalidValue`)
   */
  PatientListStream(
    request: DeepPartial<PatientListStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientListStreamResp>;
  /**
   * Create new patient
   *
   * Permission:
   * - `OrganizationPermissions.CanCreateTheirOwnPatients`
   * - `OrganizationPermissions.CanCreateOtherDoctorsPatients`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: OrganizationID/PersonalData/FirstName/LastName/ExternalID/TreatingDoctorID - (if the corresponding argument is empty)
   * - type: BadRequestError, Reason: organization deleted (if remote organization found by `CreatePatientReq.OrganizationID`)
   * - type: InvalidArgumentValueError, Argument: DateOfBirth - (if `CreatePatientReq.PersonalData.DateOfBirth` is not in the correct format ("2006-01-25"))
   * - type: BadRequestError, Reason: treating doctor does not have organization roles
   */
  CreatePatient(
    request: DeepPartial<CreatePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientResp>;
  /**
   * Set personal data
   *
   * Permission: `PatientPermissions.CanEditPersonalData`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: PersonalData/FirstName/LastName - (if the corresponding argument is empty)
   */
  SetPatientPersonalData(
    request: DeepPartial<SetPatientPersonalDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientPersonalDataResp>;
  /**
   * Set externalID
   *
   * Permission: `PatientPermissions.CanEditPatient`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: ExternalID
   */
  SetPatientExternalID(
    request: DeepPartial<SetPatientExternalIDReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientExternalIDResp>;
  /**
   * Set gender
   *
   * Permission: `PatientPermissions.CanEditPatient`
   */
  SetPatientGender(
    request: DeepPartial<SetPatientGenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientGenderResp>;
  /**
   * Set patient status
   *
   * Permission: `PatientPermissions.CanEditPatient`
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: Status - (if `SetPatientStatusReq.Status` == `PatientStatusInvalidValue`)
   */
  SetPatientStatus(
    request: DeepPartial<SetPatientStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientStatusResp>;
  /** Permission: `PatientPermissions.CanEditClinicalCaseDescription` */
  SetPatientClinicalCaseDescription(
    request: DeepPartial<SetPatientClinicalCaseDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientClinicalCaseDescriptionResp>;
  /** Permission: `PatientPermissions.CanEditClinicalCaseDescription` */
  SetPatientTreatmentDueDate(
    request: DeepPartial<SetPatientTreatmentDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTreatmentDueDateResp>;
  /**
   * Mark patient as deleted using patient id
   * - this patient mark as deleted
   * - all patient studies and reports mark as deleted
   * - all invitations to this patient are closed
   * - all invitations to the studies/reports of this patient are closed
   *
   * Permission: `PatientPermissions.CanEditPatient`
   */
  DeletePatient(
    request: DeepPartial<DeletePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePatientResp>;
}

export class PatientServiceClientImpl implements PatientService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.PatientProfileStream = this.PatientProfileStream.bind(this);
    this.PatientListStream = this.PatientListStream.bind(this);
    this.CreatePatient = this.CreatePatient.bind(this);
    this.SetPatientPersonalData = this.SetPatientPersonalData.bind(this);
    this.SetPatientExternalID = this.SetPatientExternalID.bind(this);
    this.SetPatientGender = this.SetPatientGender.bind(this);
    this.SetPatientStatus = this.SetPatientStatus.bind(this);
    this.SetPatientClinicalCaseDescription =
      this.SetPatientClinicalCaseDescription.bind(this);
    this.SetPatientTreatmentDueDate =
      this.SetPatientTreatmentDueDate.bind(this);
    this.DeletePatient = this.DeletePatient.bind(this);
  }

  PatientProfileStream(
    request: DeepPartial<PatientProfileStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientProfileStreamResp> {
    return this.rpc.invoke(
      PatientServicePatientProfileStreamDesc,
      PatientProfileStreamReq.fromPartial(request),
      metadata,
    );
  }

  PatientListStream(
    request: DeepPartial<PatientListStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientListStreamResp> {
    return this.rpc.invoke(
      PatientServicePatientListStreamDesc,
      PatientListStreamReq.fromPartial(request),
      metadata,
    );
  }

  CreatePatient(
    request: DeepPartial<CreatePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientResp> {
    return this.rpc.unary(
      PatientServiceCreatePatientDesc,
      CreatePatientReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientPersonalData(
    request: DeepPartial<SetPatientPersonalDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientPersonalDataResp> {
    return this.rpc.unary(
      PatientServiceSetPatientPersonalDataDesc,
      SetPatientPersonalDataReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientExternalID(
    request: DeepPartial<SetPatientExternalIDReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientExternalIDResp> {
    return this.rpc.unary(
      PatientServiceSetPatientExternalIDDesc,
      SetPatientExternalIDReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientGender(
    request: DeepPartial<SetPatientGenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientGenderResp> {
    return this.rpc.unary(
      PatientServiceSetPatientGenderDesc,
      SetPatientGenderReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientStatus(
    request: DeepPartial<SetPatientStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientStatusResp> {
    return this.rpc.unary(
      PatientServiceSetPatientStatusDesc,
      SetPatientStatusReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientClinicalCaseDescription(
    request: DeepPartial<SetPatientClinicalCaseDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientClinicalCaseDescriptionResp> {
    return this.rpc.unary(
      PatientServiceSetPatientClinicalCaseDescriptionDesc,
      SetPatientClinicalCaseDescriptionReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientTreatmentDueDate(
    request: DeepPartial<SetPatientTreatmentDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTreatmentDueDateResp> {
    return this.rpc.unary(
      PatientServiceSetPatientTreatmentDueDateDesc,
      SetPatientTreatmentDueDateReq.fromPartial(request),
      metadata,
    );
  }

  DeletePatient(
    request: DeepPartial<DeletePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePatientResp> {
    return this.rpc.unary(
      PatientServiceDeletePatientDesc,
      DeletePatientReq.fromPartial(request),
      metadata,
    );
  }
}

export const PatientServiceDesc = {
  serviceName: 'com.diagnocat.core.PatientService',
};

export const PatientServicePatientProfileStreamDesc: UnaryMethodDefinitionish =
  {
    methodName: 'PatientProfileStream',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
      serializeBinary() {
        return PatientProfileStreamReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...PatientProfileStreamResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServicePatientListStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'PatientListStream',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return PatientListStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...PatientListStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceCreatePatientDesc: UnaryMethodDefinitionish = {
  methodName: 'CreatePatient',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreatePatientReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CreatePatientResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceSetPatientPersonalDataDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientPersonalData',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientPersonalDataReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientPersonalDataResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServiceSetPatientExternalIDDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientExternalID',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientExternalIDReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientExternalIDResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServiceSetPatientGenderDesc: UnaryMethodDefinitionish = {
  methodName: 'SetPatientGender',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPatientGenderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetPatientGenderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceSetPatientStatusDesc: UnaryMethodDefinitionish = {
  methodName: 'SetPatientStatus',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPatientStatusReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetPatientStatusResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceSetPatientClinicalCaseDescriptionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientClinicalCaseDescription',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientClinicalCaseDescriptionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientClinicalCaseDescriptionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServiceSetPatientTreatmentDueDateDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientTreatmentDueDate',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientTreatmentDueDateReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientTreatmentDueDateResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServiceDeletePatientDesc: UnaryMethodDefinitionish = {
  methodName: 'DeletePatient',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeletePatientReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeletePatientResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Empty } from '../../google/protobuf/empty';
import { AuthState } from '../../api/auth/dto_auth';
import { BrowserHeaders } from 'browser-headers';
import {
  InvitationTarget,
  InvitationTargetDetails,
} from '../../model/dto_access';

export const protobufPackage = 'com.diagnocat.auth';

export interface SignInReq {
  Email: string;
  Password: string;
}

export interface SignUpByOneself1SendUserInfoReq {
  Email: string;
  FirstName: string;
  LastName: string;
  Language: string;
  Country: string;
  /** optional */
  PhoneNumber: string;
}

export interface SignUpByOneself2ConfirmIdentityReq {
  ConfirmationCode: string | undefined;
  Resend: SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode | undefined;
}

export interface SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode {}

export interface SignUpByOneself3SetPasswordReq {
  Password: string;
}

export interface CheckInviteTokenReq {
  Token: string;
}

export interface CheckInviteTokenResp {
  IsAlreadyRegistered: boolean;
  InvitationInfo: CheckInviteTokenResp_Invitation | undefined;
  SignUpViaSalesmanInfo: CheckInviteTokenResp_SignUpViaSalesman | undefined;
}

export interface CheckInviteTokenResp_Invitation {
  Target: InvitationTarget | undefined;
  TargetDetails: InvitationTargetDetails | undefined;
  TargetOrganizationCountry: string;
}

export interface CheckInviteTokenResp_SignUpViaSalesman {
  FirstName: string;
  LastName: string;
  Language: string;
  Country: string;
}

export interface SignUpViaSharedPatientReq {
  InviteToken: string;
  FirstName: string;
  LastName: string;
  Password: string;
  Language: string;
  Country: string;
}

export interface SignUpViaOrganizationInviteReq {
  InviteToken: string;
  FirstName: string;
  LastName: string;
  Password: string;
  Language: string;
}

export interface SignUpViaSalesmanInviteReq {
  InviteToken: string;
  Password: string;
}

export interface RecoverByEmail1StartReq {
  Email: string;
}

export interface RecoverByEmail2SetPasswordReq {
  Token: string;
  Password: string;
}

export interface LogoutReq {
  Option: LogoutReq_LogoutOption;
}

export enum LogoutReq_LogoutOption {
  None = 0,
  SteppedAway = 1,
  LogoutEverywhere = 2,
  UNRECOGNIZED = -1,
}

export function logoutReq_LogoutOptionFromJSON(
  object: any,
): LogoutReq_LogoutOption {
  switch (object) {
    case 0:
    case 'None':
      return LogoutReq_LogoutOption.None;
    case 1:
    case 'SteppedAway':
      return LogoutReq_LogoutOption.SteppedAway;
    case 2:
    case 'LogoutEverywhere':
      return LogoutReq_LogoutOption.LogoutEverywhere;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LogoutReq_LogoutOption.UNRECOGNIZED;
  }
}

export function logoutReq_LogoutOptionToJSON(
  object: LogoutReq_LogoutOption,
): string {
  switch (object) {
    case LogoutReq_LogoutOption.None:
      return 'None';
    case LogoutReq_LogoutOption.SteppedAway:
      return 'SteppedAway';
    case LogoutReq_LogoutOption.LogoutEverywhere:
      return 'LogoutEverywhere';
    case LogoutReq_LogoutOption.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface RefreshResp {
  OK: boolean;
  /** in milliseconds */
  AccessTokenTTL: number;
  /** in milliseconds */
  RefreshTokenTTL: number;
}

export interface GetTTLsResp {
  /** in milliseconds */
  AccessTokenTTL: number;
  /** in milliseconds */
  RefreshTokenTTL: number;
}

function createBaseSignInReq(): SignInReq {
  return { Email: '', Password: '' };
}

export const SignInReq = {
  encode(
    message: SignInReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Email !== '') {
      writer.uint32(10).string(message.Email);
    }
    if (message.Password !== '') {
      writer.uint32(18).string(message.Password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignInReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignInReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Email = reader.string();
          break;
        case 2:
          message.Password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignInReq {
    return {
      Email: isSet(object.Email) ? String(object.Email) : '',
      Password: isSet(object.Password) ? String(object.Password) : '',
    };
  },

  toJSON(message: SignInReq): unknown {
    const obj: any = {};
    message.Email !== undefined && (obj.Email = message.Email);
    message.Password !== undefined && (obj.Password = message.Password);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignInReq>, I>>(
    object: I,
  ): SignInReq {
    const message = createBaseSignInReq();
    message.Email = object.Email ?? '';
    message.Password = object.Password ?? '';
    return message;
  },
};

function createBaseSignUpByOneself1SendUserInfoReq(): SignUpByOneself1SendUserInfoReq {
  return {
    Email: '',
    FirstName: '',
    LastName: '',
    Language: '',
    Country: '',
    PhoneNumber: '',
  };
}

export const SignUpByOneself1SendUserInfoReq = {
  encode(
    message: SignUpByOneself1SendUserInfoReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Email !== '') {
      writer.uint32(10).string(message.Email);
    }
    if (message.FirstName !== '') {
      writer.uint32(18).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(26).string(message.LastName);
    }
    if (message.Language !== '') {
      writer.uint32(34).string(message.Language);
    }
    if (message.Country !== '') {
      writer.uint32(42).string(message.Country);
    }
    if (message.PhoneNumber !== '') {
      writer.uint32(50).string(message.PhoneNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpByOneself1SendUserInfoReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpByOneself1SendUserInfoReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Email = reader.string();
          break;
        case 2:
          message.FirstName = reader.string();
          break;
        case 3:
          message.LastName = reader.string();
          break;
        case 4:
          message.Language = reader.string();
          break;
        case 5:
          message.Country = reader.string();
          break;
        case 6:
          message.PhoneNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignUpByOneself1SendUserInfoReq {
    return {
      Email: isSet(object.Email) ? String(object.Email) : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
      Country: isSet(object.Country) ? String(object.Country) : '',
      PhoneNumber: isSet(object.PhoneNumber) ? String(object.PhoneNumber) : '',
    };
  },

  toJSON(message: SignUpByOneself1SendUserInfoReq): unknown {
    const obj: any = {};
    message.Email !== undefined && (obj.Email = message.Email);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.Language !== undefined && (obj.Language = message.Language);
    message.Country !== undefined && (obj.Country = message.Country);
    message.PhoneNumber !== undefined &&
      (obj.PhoneNumber = message.PhoneNumber);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignUpByOneself1SendUserInfoReq>, I>>(
    object: I,
  ): SignUpByOneself1SendUserInfoReq {
    const message = createBaseSignUpByOneself1SendUserInfoReq();
    message.Email = object.Email ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Language = object.Language ?? '';
    message.Country = object.Country ?? '';
    message.PhoneNumber = object.PhoneNumber ?? '';
    return message;
  },
};

function createBaseSignUpByOneself2ConfirmIdentityReq(): SignUpByOneself2ConfirmIdentityReq {
  return { ConfirmationCode: undefined, Resend: undefined };
}

export const SignUpByOneself2ConfirmIdentityReq = {
  encode(
    message: SignUpByOneself2ConfirmIdentityReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ConfirmationCode !== undefined) {
      writer.uint32(10).string(message.ConfirmationCode);
    }
    if (message.Resend !== undefined) {
      SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode.encode(
        message.Resend,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpByOneself2ConfirmIdentityReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpByOneself2ConfirmIdentityReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ConfirmationCode = reader.string();
          break;
        case 2:
          message.Resend =
            SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignUpByOneself2ConfirmIdentityReq {
    return {
      ConfirmationCode: isSet(object.ConfirmationCode)
        ? String(object.ConfirmationCode)
        : undefined,
      Resend: isSet(object.Resend)
        ? SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode.fromJSON(
            object.Resend,
          )
        : undefined,
    };
  },

  toJSON(message: SignUpByOneself2ConfirmIdentityReq): unknown {
    const obj: any = {};
    message.ConfirmationCode !== undefined &&
      (obj.ConfirmationCode = message.ConfirmationCode);
    message.Resend !== undefined &&
      (obj.Resend = message.Resend
        ? SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode.toJSON(
            message.Resend,
          )
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SignUpByOneself2ConfirmIdentityReq>, I>,
  >(object: I): SignUpByOneself2ConfirmIdentityReq {
    const message = createBaseSignUpByOneself2ConfirmIdentityReq();
    message.ConfirmationCode = object.ConfirmationCode ?? undefined;
    message.Resend =
      object.Resend !== undefined && object.Resend !== null
        ? SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode.fromPartial(
            object.Resend,
          )
        : undefined;
    return message;
  },
};

function createBaseSignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode(): SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode {
  return {};
}

export const SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode = {
  encode(
    _: SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseSignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode {
    return {};
  },

  toJSON(
    _: SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode,
  ): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode>,
      I
    >,
  >(_: I): SignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode {
    const message =
      createBaseSignUpByOneself2ConfirmIdentityReq_ResendConfirmationCode();
    return message;
  },
};

function createBaseSignUpByOneself3SetPasswordReq(): SignUpByOneself3SetPasswordReq {
  return { Password: '' };
}

export const SignUpByOneself3SetPasswordReq = {
  encode(
    message: SignUpByOneself3SetPasswordReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Password !== '') {
      writer.uint32(10).string(message.Password);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpByOneself3SetPasswordReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpByOneself3SetPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignUpByOneself3SetPasswordReq {
    return {
      Password: isSet(object.Password) ? String(object.Password) : '',
    };
  },

  toJSON(message: SignUpByOneself3SetPasswordReq): unknown {
    const obj: any = {};
    message.Password !== undefined && (obj.Password = message.Password);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignUpByOneself3SetPasswordReq>, I>>(
    object: I,
  ): SignUpByOneself3SetPasswordReq {
    const message = createBaseSignUpByOneself3SetPasswordReq();
    message.Password = object.Password ?? '';
    return message;
  },
};

function createBaseCheckInviteTokenReq(): CheckInviteTokenReq {
  return { Token: '' };
}

export const CheckInviteTokenReq = {
  encode(
    message: CheckInviteTokenReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Token !== '') {
      writer.uint32(10).string(message.Token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckInviteTokenReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckInviteTokenReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CheckInviteTokenReq {
    return {
      Token: isSet(object.Token) ? String(object.Token) : '',
    };
  },

  toJSON(message: CheckInviteTokenReq): unknown {
    const obj: any = {};
    message.Token !== undefined && (obj.Token = message.Token);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CheckInviteTokenReq>, I>>(
    object: I,
  ): CheckInviteTokenReq {
    const message = createBaseCheckInviteTokenReq();
    message.Token = object.Token ?? '';
    return message;
  },
};

function createBaseCheckInviteTokenResp(): CheckInviteTokenResp {
  return {
    IsAlreadyRegistered: false,
    InvitationInfo: undefined,
    SignUpViaSalesmanInfo: undefined,
  };
}

export const CheckInviteTokenResp = {
  encode(
    message: CheckInviteTokenResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IsAlreadyRegistered === true) {
      writer.uint32(8).bool(message.IsAlreadyRegistered);
    }
    if (message.InvitationInfo !== undefined) {
      CheckInviteTokenResp_Invitation.encode(
        message.InvitationInfo,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    if (message.SignUpViaSalesmanInfo !== undefined) {
      CheckInviteTokenResp_SignUpViaSalesman.encode(
        message.SignUpViaSalesmanInfo,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CheckInviteTokenResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckInviteTokenResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IsAlreadyRegistered = reader.bool();
          break;
        case 100:
          message.InvitationInfo = CheckInviteTokenResp_Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.SignUpViaSalesmanInfo =
            CheckInviteTokenResp_SignUpViaSalesman.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CheckInviteTokenResp {
    return {
      IsAlreadyRegistered: isSet(object.IsAlreadyRegistered)
        ? Boolean(object.IsAlreadyRegistered)
        : false,
      InvitationInfo: isSet(object.InvitationInfo)
        ? CheckInviteTokenResp_Invitation.fromJSON(object.InvitationInfo)
        : undefined,
      SignUpViaSalesmanInfo: isSet(object.SignUpViaSalesmanInfo)
        ? CheckInviteTokenResp_SignUpViaSalesman.fromJSON(
            object.SignUpViaSalesmanInfo,
          )
        : undefined,
    };
  },

  toJSON(message: CheckInviteTokenResp): unknown {
    const obj: any = {};
    message.IsAlreadyRegistered !== undefined &&
      (obj.IsAlreadyRegistered = message.IsAlreadyRegistered);
    message.InvitationInfo !== undefined &&
      (obj.InvitationInfo = message.InvitationInfo
        ? CheckInviteTokenResp_Invitation.toJSON(message.InvitationInfo)
        : undefined);
    message.SignUpViaSalesmanInfo !== undefined &&
      (obj.SignUpViaSalesmanInfo = message.SignUpViaSalesmanInfo
        ? CheckInviteTokenResp_SignUpViaSalesman.toJSON(
            message.SignUpViaSalesmanInfo,
          )
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CheckInviteTokenResp>, I>>(
    object: I,
  ): CheckInviteTokenResp {
    const message = createBaseCheckInviteTokenResp();
    message.IsAlreadyRegistered = object.IsAlreadyRegistered ?? false;
    message.InvitationInfo =
      object.InvitationInfo !== undefined && object.InvitationInfo !== null
        ? CheckInviteTokenResp_Invitation.fromPartial(object.InvitationInfo)
        : undefined;
    message.SignUpViaSalesmanInfo =
      object.SignUpViaSalesmanInfo !== undefined &&
      object.SignUpViaSalesmanInfo !== null
        ? CheckInviteTokenResp_SignUpViaSalesman.fromPartial(
            object.SignUpViaSalesmanInfo,
          )
        : undefined;
    return message;
  },
};

function createBaseCheckInviteTokenResp_Invitation(): CheckInviteTokenResp_Invitation {
  return {
    Target: undefined,
    TargetDetails: undefined,
    TargetOrganizationCountry: '',
  };
}

export const CheckInviteTokenResp_Invitation = {
  encode(
    message: CheckInviteTokenResp_Invitation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Target !== undefined) {
      InvitationTarget.encode(
        message.Target,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.TargetDetails !== undefined) {
      InvitationTargetDetails.encode(
        message.TargetDetails,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.TargetOrganizationCountry !== '') {
      writer.uint32(26).string(message.TargetOrganizationCountry);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CheckInviteTokenResp_Invitation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckInviteTokenResp_Invitation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Target = InvitationTarget.decode(reader, reader.uint32());
          break;
        case 2:
          message.TargetDetails = InvitationTargetDetails.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.TargetOrganizationCountry = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CheckInviteTokenResp_Invitation {
    return {
      Target: isSet(object.Target)
        ? InvitationTarget.fromJSON(object.Target)
        : undefined,
      TargetDetails: isSet(object.TargetDetails)
        ? InvitationTargetDetails.fromJSON(object.TargetDetails)
        : undefined,
      TargetOrganizationCountry: isSet(object.TargetOrganizationCountry)
        ? String(object.TargetOrganizationCountry)
        : '',
    };
  },

  toJSON(message: CheckInviteTokenResp_Invitation): unknown {
    const obj: any = {};
    message.Target !== undefined &&
      (obj.Target = message.Target
        ? InvitationTarget.toJSON(message.Target)
        : undefined);
    message.TargetDetails !== undefined &&
      (obj.TargetDetails = message.TargetDetails
        ? InvitationTargetDetails.toJSON(message.TargetDetails)
        : undefined);
    message.TargetOrganizationCountry !== undefined &&
      (obj.TargetOrganizationCountry = message.TargetOrganizationCountry);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CheckInviteTokenResp_Invitation>, I>>(
    object: I,
  ): CheckInviteTokenResp_Invitation {
    const message = createBaseCheckInviteTokenResp_Invitation();
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? InvitationTarget.fromPartial(object.Target)
        : undefined;
    message.TargetDetails =
      object.TargetDetails !== undefined && object.TargetDetails !== null
        ? InvitationTargetDetails.fromPartial(object.TargetDetails)
        : undefined;
    message.TargetOrganizationCountry = object.TargetOrganizationCountry ?? '';
    return message;
  },
};

function createBaseCheckInviteTokenResp_SignUpViaSalesman(): CheckInviteTokenResp_SignUpViaSalesman {
  return { FirstName: '', LastName: '', Language: '', Country: '' };
}

export const CheckInviteTokenResp_SignUpViaSalesman = {
  encode(
    message: CheckInviteTokenResp_SignUpViaSalesman,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FirstName !== '') {
      writer.uint32(10).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(18).string(message.LastName);
    }
    if (message.Language !== '') {
      writer.uint32(26).string(message.Language);
    }
    if (message.Country !== '') {
      writer.uint32(34).string(message.Country);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CheckInviteTokenResp_SignUpViaSalesman {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckInviteTokenResp_SignUpViaSalesman();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FirstName = reader.string();
          break;
        case 2:
          message.LastName = reader.string();
          break;
        case 3:
          message.Language = reader.string();
          break;
        case 4:
          message.Country = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CheckInviteTokenResp_SignUpViaSalesman {
    return {
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
      Country: isSet(object.Country) ? String(object.Country) : '',
    };
  },

  toJSON(message: CheckInviteTokenResp_SignUpViaSalesman): unknown {
    const obj: any = {};
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.Language !== undefined && (obj.Language = message.Language);
    message.Country !== undefined && (obj.Country = message.Country);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CheckInviteTokenResp_SignUpViaSalesman>, I>,
  >(object: I): CheckInviteTokenResp_SignUpViaSalesman {
    const message = createBaseCheckInviteTokenResp_SignUpViaSalesman();
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Language = object.Language ?? '';
    message.Country = object.Country ?? '';
    return message;
  },
};

function createBaseSignUpViaSharedPatientReq(): SignUpViaSharedPatientReq {
  return {
    InviteToken: '',
    FirstName: '',
    LastName: '',
    Password: '',
    Language: '',
    Country: '',
  };
}

export const SignUpViaSharedPatientReq = {
  encode(
    message: SignUpViaSharedPatientReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InviteToken !== '') {
      writer.uint32(10).string(message.InviteToken);
    }
    if (message.FirstName !== '') {
      writer.uint32(802).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(810).string(message.LastName);
    }
    if (message.Password !== '') {
      writer.uint32(818).string(message.Password);
    }
    if (message.Language !== '') {
      writer.uint32(826).string(message.Language);
    }
    if (message.Country !== '') {
      writer.uint32(834).string(message.Country);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpViaSharedPatientReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpViaSharedPatientReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InviteToken = reader.string();
          break;
        case 100:
          message.FirstName = reader.string();
          break;
        case 101:
          message.LastName = reader.string();
          break;
        case 102:
          message.Password = reader.string();
          break;
        case 103:
          message.Language = reader.string();
          break;
        case 104:
          message.Country = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignUpViaSharedPatientReq {
    return {
      InviteToken: isSet(object.InviteToken) ? String(object.InviteToken) : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Password: isSet(object.Password) ? String(object.Password) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
      Country: isSet(object.Country) ? String(object.Country) : '',
    };
  },

  toJSON(message: SignUpViaSharedPatientReq): unknown {
    const obj: any = {};
    message.InviteToken !== undefined &&
      (obj.InviteToken = message.InviteToken);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.Password !== undefined && (obj.Password = message.Password);
    message.Language !== undefined && (obj.Language = message.Language);
    message.Country !== undefined && (obj.Country = message.Country);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignUpViaSharedPatientReq>, I>>(
    object: I,
  ): SignUpViaSharedPatientReq {
    const message = createBaseSignUpViaSharedPatientReq();
    message.InviteToken = object.InviteToken ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Password = object.Password ?? '';
    message.Language = object.Language ?? '';
    message.Country = object.Country ?? '';
    return message;
  },
};

function createBaseSignUpViaOrganizationInviteReq(): SignUpViaOrganizationInviteReq {
  return {
    InviteToken: '',
    FirstName: '',
    LastName: '',
    Password: '',
    Language: '',
  };
}

export const SignUpViaOrganizationInviteReq = {
  encode(
    message: SignUpViaOrganizationInviteReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InviteToken !== '') {
      writer.uint32(10).string(message.InviteToken);
    }
    if (message.FirstName !== '') {
      writer.uint32(802).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(810).string(message.LastName);
    }
    if (message.Password !== '') {
      writer.uint32(818).string(message.Password);
    }
    if (message.Language !== '') {
      writer.uint32(826).string(message.Language);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpViaOrganizationInviteReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpViaOrganizationInviteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InviteToken = reader.string();
          break;
        case 100:
          message.FirstName = reader.string();
          break;
        case 101:
          message.LastName = reader.string();
          break;
        case 102:
          message.Password = reader.string();
          break;
        case 103:
          message.Language = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignUpViaOrganizationInviteReq {
    return {
      InviteToken: isSet(object.InviteToken) ? String(object.InviteToken) : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Password: isSet(object.Password) ? String(object.Password) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
    };
  },

  toJSON(message: SignUpViaOrganizationInviteReq): unknown {
    const obj: any = {};
    message.InviteToken !== undefined &&
      (obj.InviteToken = message.InviteToken);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.Password !== undefined && (obj.Password = message.Password);
    message.Language !== undefined && (obj.Language = message.Language);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignUpViaOrganizationInviteReq>, I>>(
    object: I,
  ): SignUpViaOrganizationInviteReq {
    const message = createBaseSignUpViaOrganizationInviteReq();
    message.InviteToken = object.InviteToken ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Password = object.Password ?? '';
    message.Language = object.Language ?? '';
    return message;
  },
};

function createBaseSignUpViaSalesmanInviteReq(): SignUpViaSalesmanInviteReq {
  return { InviteToken: '', Password: '' };
}

export const SignUpViaSalesmanInviteReq = {
  encode(
    message: SignUpViaSalesmanInviteReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InviteToken !== '') {
      writer.uint32(10).string(message.InviteToken);
    }
    if (message.Password !== '') {
      writer.uint32(802).string(message.Password);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SignUpViaSalesmanInviteReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpViaSalesmanInviteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InviteToken = reader.string();
          break;
        case 100:
          message.Password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignUpViaSalesmanInviteReq {
    return {
      InviteToken: isSet(object.InviteToken) ? String(object.InviteToken) : '',
      Password: isSet(object.Password) ? String(object.Password) : '',
    };
  },

  toJSON(message: SignUpViaSalesmanInviteReq): unknown {
    const obj: any = {};
    message.InviteToken !== undefined &&
      (obj.InviteToken = message.InviteToken);
    message.Password !== undefined && (obj.Password = message.Password);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignUpViaSalesmanInviteReq>, I>>(
    object: I,
  ): SignUpViaSalesmanInviteReq {
    const message = createBaseSignUpViaSalesmanInviteReq();
    message.InviteToken = object.InviteToken ?? '';
    message.Password = object.Password ?? '';
    return message;
  },
};

function createBaseRecoverByEmail1StartReq(): RecoverByEmail1StartReq {
  return { Email: '' };
}

export const RecoverByEmail1StartReq = {
  encode(
    message: RecoverByEmail1StartReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Email !== '') {
      writer.uint32(10).string(message.Email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RecoverByEmail1StartReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecoverByEmail1StartReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RecoverByEmail1StartReq {
    return {
      Email: isSet(object.Email) ? String(object.Email) : '',
    };
  },

  toJSON(message: RecoverByEmail1StartReq): unknown {
    const obj: any = {};
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RecoverByEmail1StartReq>, I>>(
    object: I,
  ): RecoverByEmail1StartReq {
    const message = createBaseRecoverByEmail1StartReq();
    message.Email = object.Email ?? '';
    return message;
  },
};

function createBaseRecoverByEmail2SetPasswordReq(): RecoverByEmail2SetPasswordReq {
  return { Token: '', Password: '' };
}

export const RecoverByEmail2SetPasswordReq = {
  encode(
    message: RecoverByEmail2SetPasswordReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Token !== '') {
      writer.uint32(10).string(message.Token);
    }
    if (message.Password !== '') {
      writer.uint32(18).string(message.Password);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RecoverByEmail2SetPasswordReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecoverByEmail2SetPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Token = reader.string();
          break;
        case 2:
          message.Password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RecoverByEmail2SetPasswordReq {
    return {
      Token: isSet(object.Token) ? String(object.Token) : '',
      Password: isSet(object.Password) ? String(object.Password) : '',
    };
  },

  toJSON(message: RecoverByEmail2SetPasswordReq): unknown {
    const obj: any = {};
    message.Token !== undefined && (obj.Token = message.Token);
    message.Password !== undefined && (obj.Password = message.Password);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RecoverByEmail2SetPasswordReq>, I>>(
    object: I,
  ): RecoverByEmail2SetPasswordReq {
    const message = createBaseRecoverByEmail2SetPasswordReq();
    message.Token = object.Token ?? '';
    message.Password = object.Password ?? '';
    return message;
  },
};

function createBaseLogoutReq(): LogoutReq {
  return { Option: 0 };
}

export const LogoutReq = {
  encode(
    message: LogoutReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Option !== 0) {
      writer.uint32(8).int32(message.Option);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Option = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LogoutReq {
    return {
      Option: isSet(object.Option)
        ? logoutReq_LogoutOptionFromJSON(object.Option)
        : 0,
    };
  },

  toJSON(message: LogoutReq): unknown {
    const obj: any = {};
    message.Option !== undefined &&
      (obj.Option = logoutReq_LogoutOptionToJSON(message.Option));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<LogoutReq>, I>>(
    object: I,
  ): LogoutReq {
    const message = createBaseLogoutReq();
    message.Option = object.Option ?? 0;
    return message;
  },
};

function createBaseRefreshResp(): RefreshResp {
  return { OK: false, AccessTokenTTL: 0, RefreshTokenTTL: 0 };
}

export const RefreshResp = {
  encode(
    message: RefreshResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OK === true) {
      writer.uint32(8).bool(message.OK);
    }
    if (message.AccessTokenTTL !== 0) {
      writer.uint32(16).uint64(message.AccessTokenTTL);
    }
    if (message.RefreshTokenTTL !== 0) {
      writer.uint32(24).uint64(message.RefreshTokenTTL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OK = reader.bool();
          break;
        case 2:
          message.AccessTokenTTL = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.RefreshTokenTTL = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RefreshResp {
    return {
      OK: isSet(object.OK) ? Boolean(object.OK) : false,
      AccessTokenTTL: isSet(object.AccessTokenTTL)
        ? Number(object.AccessTokenTTL)
        : 0,
      RefreshTokenTTL: isSet(object.RefreshTokenTTL)
        ? Number(object.RefreshTokenTTL)
        : 0,
    };
  },

  toJSON(message: RefreshResp): unknown {
    const obj: any = {};
    message.OK !== undefined && (obj.OK = message.OK);
    message.AccessTokenTTL !== undefined &&
      (obj.AccessTokenTTL = Math.round(message.AccessTokenTTL));
    message.RefreshTokenTTL !== undefined &&
      (obj.RefreshTokenTTL = Math.round(message.RefreshTokenTTL));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RefreshResp>, I>>(
    object: I,
  ): RefreshResp {
    const message = createBaseRefreshResp();
    message.OK = object.OK ?? false;
    message.AccessTokenTTL = object.AccessTokenTTL ?? 0;
    message.RefreshTokenTTL = object.RefreshTokenTTL ?? 0;
    return message;
  },
};

function createBaseGetTTLsResp(): GetTTLsResp {
  return { AccessTokenTTL: 0, RefreshTokenTTL: 0 };
}

export const GetTTLsResp = {
  encode(
    message: GetTTLsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccessTokenTTL !== 0) {
      writer.uint32(8).uint64(message.AccessTokenTTL);
    }
    if (message.RefreshTokenTTL !== 0) {
      writer.uint32(16).uint64(message.RefreshTokenTTL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTTLsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTTLsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccessTokenTTL = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.RefreshTokenTTL = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetTTLsResp {
    return {
      AccessTokenTTL: isSet(object.AccessTokenTTL)
        ? Number(object.AccessTokenTTL)
        : 0,
      RefreshTokenTTL: isSet(object.RefreshTokenTTL)
        ? Number(object.RefreshTokenTTL)
        : 0,
    };
  },

  toJSON(message: GetTTLsResp): unknown {
    const obj: any = {};
    message.AccessTokenTTL !== undefined &&
      (obj.AccessTokenTTL = Math.round(message.AccessTokenTTL));
    message.RefreshTokenTTL !== undefined &&
      (obj.RefreshTokenTTL = Math.round(message.RefreshTokenTTL));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetTTLsResp>, I>>(
    object: I,
  ): GetTTLsResp {
    const message = createBaseGetTTLsResp();
    message.AccessTokenTTL = object.AccessTokenTTL ?? 0;
    message.RefreshTokenTTL = object.RefreshTokenTTL ?? 0;
    return message;
  },
};

export interface Authentication {
  GetState(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  CheckInviteToken(
    request: DeepPartial<CheckInviteTokenReq>,
    metadata?: grpc.Metadata,
  ): Promise<CheckInviteTokenResp>;
  Logout(
    request: DeepPartial<LogoutReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  Refresh(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshResp>;
  GetTTLs(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<GetTTLsResp>;
  /**
   * UserBadRequestError:
   * - Code: email_taken (user with this email is already registered)
   */
  SignUp_ByOneself_1_SendUserInfo(
    request: DeepPartial<SignUpByOneself1SendUserInfoReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  /**
   * UserBadRequestError:
   * - Code: code_retries_limit (too many attempts)
   * - Code: code_is_expired (code lifetime exceeded)
   * - Code: wrong_code (code is incorrect)
   * - Code: already_registered (user is already registered)
   */
  SignUp_ByOneself_2_ConfirmIdentity(
    request: DeepPartial<SignUpByOneself2ConfirmIdentityReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  SignUp_ByOneself_3_SetPassword(
    request: DeepPartial<SignUpByOneself3SetPasswordReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  /**  */
  SignUp_ViaSharedPatient(
    request: DeepPartial<SignUpViaSharedPatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  /**  */
  SignUp_ViaOrganizationInvite(
    request: DeepPartial<SignUpViaOrganizationInviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  /**  */
  SignUp_ViaSalesmanInvite(
    request: DeepPartial<SignUpViaSalesmanInviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  /**
   * UserBadRequestError:
   * - Code: reset_invalid_creds (user with this email is already registered)
   */
  Recover_ByEmail_1_Start(
    request: DeepPartial<RecoverByEmail1StartReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  Recover_ByEmail_2_SetPassword(
    request: DeepPartial<RecoverByEmail2SetPasswordReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  /**
   * UserBadRequestError:
   * - Code: ErrInvalidCreds (either password or email is wrong)
   */
  SignIn(
    request: DeepPartial<SignInReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
}

export class AuthenticationClientImpl implements Authentication {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetState = this.GetState.bind(this);
    this.CheckInviteToken = this.CheckInviteToken.bind(this);
    this.Logout = this.Logout.bind(this);
    this.Refresh = this.Refresh.bind(this);
    this.GetTTLs = this.GetTTLs.bind(this);
    this.SignUp_ByOneself_1_SendUserInfo =
      this.SignUp_ByOneself_1_SendUserInfo.bind(this);
    this.SignUp_ByOneself_2_ConfirmIdentity =
      this.SignUp_ByOneself_2_ConfirmIdentity.bind(this);
    this.SignUp_ByOneself_3_SetPassword =
      this.SignUp_ByOneself_3_SetPassword.bind(this);
    this.SignUp_ViaSharedPatient = this.SignUp_ViaSharedPatient.bind(this);
    this.SignUp_ViaOrganizationInvite =
      this.SignUp_ViaOrganizationInvite.bind(this);
    this.SignUp_ViaSalesmanInvite = this.SignUp_ViaSalesmanInvite.bind(this);
    this.Recover_ByEmail_1_Start = this.Recover_ByEmail_1_Start.bind(this);
    this.Recover_ByEmail_2_SetPassword =
      this.Recover_ByEmail_2_SetPassword.bind(this);
    this.SignIn = this.SignIn.bind(this);
  }

  GetState(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationGetStateDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }

  CheckInviteToken(
    request: DeepPartial<CheckInviteTokenReq>,
    metadata?: grpc.Metadata,
  ): Promise<CheckInviteTokenResp> {
    return this.rpc.unary(
      AuthenticationCheckInviteTokenDesc,
      CheckInviteTokenReq.fromPartial(request),
      metadata,
    );
  }

  Logout(
    request: DeepPartial<LogoutReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      AuthenticationLogoutDesc,
      LogoutReq.fromPartial(request),
      metadata,
    );
  }

  Refresh(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshResp> {
    return this.rpc.unary(
      AuthenticationRefreshDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }

  GetTTLs(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<GetTTLsResp> {
    return this.rpc.unary(
      AuthenticationGetTTLsDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }

  SignUp_ByOneself_1_SendUserInfo(
    request: DeepPartial<SignUpByOneself1SendUserInfoReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationSignUp_ByOneself_1_SendUserInfoDesc,
      SignUpByOneself1SendUserInfoReq.fromPartial(request),
      metadata,
    );
  }

  SignUp_ByOneself_2_ConfirmIdentity(
    request: DeepPartial<SignUpByOneself2ConfirmIdentityReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationSignUp_ByOneself_2_ConfirmIdentityDesc,
      SignUpByOneself2ConfirmIdentityReq.fromPartial(request),
      metadata,
    );
  }

  SignUp_ByOneself_3_SetPassword(
    request: DeepPartial<SignUpByOneself3SetPasswordReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationSignUp_ByOneself_3_SetPasswordDesc,
      SignUpByOneself3SetPasswordReq.fromPartial(request),
      metadata,
    );
  }

  SignUp_ViaSharedPatient(
    request: DeepPartial<SignUpViaSharedPatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationSignUp_ViaSharedPatientDesc,
      SignUpViaSharedPatientReq.fromPartial(request),
      metadata,
    );
  }

  SignUp_ViaOrganizationInvite(
    request: DeepPartial<SignUpViaOrganizationInviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationSignUp_ViaOrganizationInviteDesc,
      SignUpViaOrganizationInviteReq.fromPartial(request),
      metadata,
    );
  }

  SignUp_ViaSalesmanInvite(
    request: DeepPartial<SignUpViaSalesmanInviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationSignUp_ViaSalesmanInviteDesc,
      SignUpViaSalesmanInviteReq.fromPartial(request),
      metadata,
    );
  }

  Recover_ByEmail_1_Start(
    request: DeepPartial<RecoverByEmail1StartReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationRecover_ByEmail_1_StartDesc,
      RecoverByEmail1StartReq.fromPartial(request),
      metadata,
    );
  }

  Recover_ByEmail_2_SetPassword(
    request: DeepPartial<RecoverByEmail2SetPasswordReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationRecover_ByEmail_2_SetPasswordDesc,
      RecoverByEmail2SetPasswordReq.fromPartial(request),
      metadata,
    );
  }

  SignIn(
    request: DeepPartial<SignInReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationSignInDesc,
      SignInReq.fromPartial(request),
      metadata,
    );
  }
}

export const AuthenticationDesc = {
  serviceName: 'com.diagnocat.auth.Authentication',
};

export const AuthenticationGetStateDesc: UnaryMethodDefinitionish = {
  methodName: 'GetState',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthState.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationCheckInviteTokenDesc: UnaryMethodDefinitionish = {
  methodName: 'CheckInviteToken',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CheckInviteTokenReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CheckInviteTokenResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationLogoutDesc: UnaryMethodDefinitionish = {
  methodName: 'Logout',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LogoutReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Empty.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationRefreshDesc: UnaryMethodDefinitionish = {
  methodName: 'Refresh',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RefreshResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationGetTTLsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetTTLs',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetTTLsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationSignUp_ByOneself_1_SendUserInfoDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SignUp_ByOneself_1_SendUserInfo',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SignUpByOneself1SendUserInfoReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationSignUp_ByOneself_2_ConfirmIdentityDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SignUp_ByOneself_2_ConfirmIdentity',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SignUpByOneself2ConfirmIdentityReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationSignUp_ByOneself_3_SetPasswordDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SignUp_ByOneself_3_SetPassword',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SignUpByOneself3SetPasswordReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationSignUp_ViaSharedPatientDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SignUp_ViaSharedPatient',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SignUpViaSharedPatientReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationSignUp_ViaOrganizationInviteDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SignUp_ViaOrganizationInvite',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SignUpViaOrganizationInviteReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationSignUp_ViaSalesmanInviteDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SignUp_ViaSalesmanInvite',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SignUpViaSalesmanInviteReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationRecover_ByEmail_1_StartDesc: UnaryMethodDefinitionish =
  {
    methodName: 'Recover_ByEmail_1_Start',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RecoverByEmail1StartReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationRecover_ByEmail_2_SetPasswordDesc: UnaryMethodDefinitionish =
  {
    methodName: 'Recover_ByEmail_2_SetPassword',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RecoverByEmail2SetPasswordReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationSignInDesc: UnaryMethodDefinitionish = {
  methodName: 'SignIn',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SignInReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthState.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

// @ts-nocheck
import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

export type ObjectFitContaintContextType = {
  objectWidth: number;
  objectHeight: number;
  aspect: number;
  left: number;
  top: number;
  width: number;
  height: number;
};

export const ObjectFitContaintContext =
  React.createContext<ObjectFitContaintContextType>({
    objectWidth: 0,
    objectHeight: 0,
    aspect: 0,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
// Fits itself like objectFit: contain for <img>
export const ObjectFitContain = ({
  objectWidth,
  objectHeight,
  onAspectChanged,
  children,
}: {
  onAspectChanged?: (aspect: number) => void;
  objectWidth: number;
  objectHeight: number;
  children: ReactNode;
}) => {
  const [style, setStyle] = useState<CSSProperties>({
    display: 'block',
    width: '100%',
    height: '100%',
  });

  const [context, setContext] = useState<ObjectFitContaintContextType>({
    objectWidth: 0,
    objectHeight: 0,
    aspect: 0,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (objectWidth === 0 || objectHeight === 0) return;
    const node = ref.current;
    if (!node) return;

    const parentNode = node.parentElement;
    const calculations = () => {
      const aspect = Math.min(
        parentNode.clientWidth / objectWidth,
        parentNode.clientHeight / objectHeight,
      );

      const w = objectWidth * aspect;
      const h = objectHeight * aspect;
      const l = (parentNode.clientWidth - w) / 2;
      const t = (parentNode.clientHeight - h) / 2;

      onAspectChanged?.(aspect);

      setStyle({
        display: 'block',
        position: 'relative',
        width: w,
        height: h,
        left: l,
        top: t,
      });

      setContext({
        objectWidth,
        objectHeight,
        left: l,
        top: t,
        aspect,
        width: w,
        height: h,
      });
    };

    // This feature raises "ResizeObserver loop completed with undelivered notifications.",
    // http://localhost:6006/runtime-error but I can't do anything with that. It's by design and can be ignored.
    const resizeObserver = new ResizeObserver((entries) => {
      calculations();
    });

    resizeObserver.observe(node.parentElement);
    calculations();
  }, [ref, objectWidth, objectHeight]);

  return (
    <ObjectFitContaintContext.Provider value={context}>
      <div dir="ltr" ref={ref} style={style}>
        {children}
      </div>
    </ObjectFitContaintContext.Provider>
  );
};

import { useEffect, useRef } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Avatar } from '@/shared/ui';

import { MAX_ROW_HEIGHT } from '../../config/constants';

import styles from './CurrentOrganizationRow.module.scss';

type RowProps = {
  organizationName: string;
  initials: string;
  avatarSrc: string;
  showTooltipHandler?: (shouldShow: boolean) => void;
  onClick: () => void;
};

export const Row = (props: RowProps) => {
  const { organizationName, avatarSrc, initials, onClick, showTooltipHandler } =
    props;

  const orgNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!showTooltipHandler) {
      return;
    }

    const scrollHeight = orgNameRef.current?.scrollHeight ?? 0;

    showTooltipHandler(scrollHeight > MAX_ROW_HEIGHT);
  }, [organizationName]);

  return (
    <div
      className={cn(styles.organizationSettings, styles.organizationRow)}
      onClick={onClick}
    >
      <Avatar
        initials={initials}
        src={avatarSrc}
        alt={organizationName}
        size={72}
      />

      <div className={styles.organizationSettingsInnerWrapper}>
        <h6 ref={orgNameRef} className={cn(styles.currentOrganization, 'h6')}>
          {organizationName}
        </h6>

        <span className={cn(styles.breakWord, styles.purpleText)}>
          <FormattedMessage
            id="controlPanel.clinicSettings"
            defaultMessage="Clinic settings"
          />
        </span>
      </div>
    </div>
  );
};

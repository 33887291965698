import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedDate, FormattedNumber } from 'react-intl/lib';

import { Button, Description } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { shouldAskAdditionalInfo } from '@/shared/lib';
import { Order } from '@/shared/api/protocol_gen/api/billing_new/dto_order_new';
import { PayOrderReq } from '@/shared/api/protocol_gen/api/billing_new/svc_billing_new';

import { billingModel } from '@/entities/billing';
import { ModalID, modalModel } from '@/entities/modal';

import { InvoiceStatuses } from '../../config/type';
import { invoiceStatusDefaultMessages } from '../../config/i18n';
import { getInvoiceStatus } from '../../lib/getInvoiceStatus';

import styles from './Invoice.module.scss';

type InvoiceRowProps = {
  invoice: Order;
  className?: string;
  testID?: string;
};

const invoiceStatusesBGStyles = {
  new: styles.newInvoice,
  overdue: styles.overdueInvoice,
} as Record<InvoiceStatuses, string>;

const invoiceStatusesTextStyles = {
  paid: styles.paidInvoiceText,
  overdue: styles.overdueInvoiceText,
} as Record<InvoiceStatuses, string>;

export const Invoice: FC<InvoiceRowProps> = (props) => {
  const { className, testID, invoice } = props;

  const { ID, SequentialID, Billed, Effect, Created } = invoice;

  const paid = Billed?.Paid ?? false;
  const overdue = Billed?.Overdue ?? false;
  const amount = Billed?.Amount ?? '0';
  const invoicePDFURL = Billed?.InvoicePDFURL ?? '';
  const currency = Billed?.Currency ?? 'USD';

  const { formatMessage, formatNumber } = useIntl();

  const { BillingZones, BillingInformation } = useAppSelector(
    billingModel.selectors.selectAccount,
  );

  const dispatch = useAppDispatch();

  const billingZone = BillingZones?.at(0);

  const invoiceStatus = getInvoiceStatus(paid, overdue);

  const shouldAskBillingInfo = useMemo(
    () => shouldAskAdditionalInfo(billingZone, BillingInformation),
    [billingZone, BillingInformation],
  );

  const handlePayClick = useCallback(async () => {
    if (shouldAskBillingInfo) {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.BillingInformationModal,
          data: {
            isEdit: false,
            invoiceID: ID,
          },
        }),
      );
    } else {
      const { URL, Paid } = await dispatch(
        billingModel.thunks.payOrder({
          OrderID: ID,
        } as PayOrderReq),
      ).unwrap();

      if (!Paid && URL) {
        window.open(URL, '_blank');
      }
    }
  }, [ID, shouldAskBillingInfo]);

  return (
    <div
      data-testid={testID}
      className={cn(
        styles.container,
        className,
        invoiceStatus in invoiceStatusesBGStyles &&
          invoiceStatusesBGStyles[invoiceStatus],
      )}
    >
      <Description
        isVertical
        contentClassName="p2"
        label={formatMessage({
          id: 'invoices.invoiceRow.invoiceNumber',
          defaultMessage: 'Invoice number',
        })}
      >
        #{SequentialID}
      </Description>

      <Description
        isVertical
        contentClassName="p2"
        label={formatMessage({
          id: 'global.date',
          defaultMessage: 'Date',
        })}
      >
        {Created && <FormattedDate value={Created?.At} />}
      </Description>

      <Description
        isVertical
        contentClassName="p2"
        label={formatMessage({
          id: 'global.price',
          defaultMessage: 'Price',
        })}
      >
        <FormattedNumber
          maximumFractionDigits={0}
          value={parseInt(amount, 10)}
          style="currency"
          currencyDisplay="narrowSymbol"
          currency={currency}
        />
      </Description>

      <Description
        isVertical
        contentClassName={cn(
          'p2',
          invoiceStatus in invoiceStatusesTextStyles &&
            invoiceStatusesTextStyles[invoiceStatus],
        )}
        label={formatMessage({
          id: 'global.status',
          defaultMessage: 'Status',
        })}
      >
        {formatMessage(invoiceStatusDefaultMessages[invoiceStatus])}
      </Description>

      <Description
        isVertical
        contentClassName="p2"
        label={formatMessage({
          id: 'invoices.invoiceRow.productName',
          defaultMessage: 'Product name',
        })}
      >
        {Effect?.SubscriptionAdd?.Subscription?.Name ??
          // remove RenewSubscriptionStateID when backenders added subscription name
          Effect?.SubscriptionRenew?.RenewSubscriptionStateID}
      </Description>

      {paid && invoicePDFURL ? (
        <a
          href={invoicePDFURL}
          target="_blank"
          rel="noreferrer"
          className={cn(styles.downloadLink, 'p2')}
        >
          <FormattedMessage id="global.download" defaultMessage="Download" />
        </a>
      ) : (
        Number(amount) > 0 && (
          <Button
            size="small"
            style={{ minWidth: '140px' }}
            onClick={handlePayClick}
          >
            <FormattedMessage
              id="invoices.invoiceRow.payButton"
              defaultMessage="Pay {value}"
              values={{
                value: formatNumber(parseInt(amount, 10), {
                  maximumFractionDigits: 0,
                  style: 'currency',
                  currencyDisplay: 'narrowSymbol',
                  currency,
                }),
              }}
            />
          </Button>
        )
      )}
    </div>
  );
};

import { filter, both } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { ConditionGroups } from '../config/const';

import { isConditionToothIDEquals } from './isConditionToothIDEquals';
import { isConditionInGroup } from './isConditionInGroup';

export const filterConditionsByToothIDAndGroupCode = (
  conditions: Condition[],
  toothID: string,
  conditionGroupCode: ConditionGroups,
) =>
  filter(
    both(
      isConditionToothIDEquals(toothID),
      isConditionInGroup(conditionGroupCode),
    ),
  )(conditions);

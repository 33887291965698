import ColorHash from 'color-hash';

const COLOR_GROUP_HUE = {
  purpleflat: { min: 274, max: 274 },
  magenta: { min: 310, max: 310 },
  medicalgreen: { min: 165, max: 165 },
  green: { min: 126, max: 126 },
};

export const avatarColorHash = new ColorHash({
  lightness: 0.5,
  saturation: 0.7,
  // TODO: Discuss color group with designer (light/dark theme, bunch of ~10 colors)
  hue: [
    COLOR_GROUP_HUE.purpleflat,
    COLOR_GROUP_HUE.magenta,
    COLOR_GROUP_HUE.medicalgreen,
    COLOR_GROUP_HUE.green,
  ],
});

import { useContext } from "react";
import { ObjectFitContaintContext } from "../ObjectFitContain";
import { Annotation } from "./Annotation";
import { cssToLogical } from "./csstoLogical";

export type LineProps = Omit<Extract<Annotation, { kind: 'ruler' }>, 'kind'>

const strokeColor = 'lime';

export const Line = ({ x1, y1, x2, y2 }: LineProps) => {

    const objectFit = useContext(ObjectFitContaintContext);

    return (
        <line
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke={strokeColor}
            strokeWidth={cssToLogical(2, objectFit)}
            strokeOpacity="0.4"
        />
    )

}
import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Popup } from '@/shared/ui';
import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';

import {
  LOWER_JAW_PERMANENT,
  LOWER_JAW_PRIMARY,
  LocalizationWithISO,
  UPPER_JAW_PERMANENT,
  UPPER_JAW_PRIMARY,
  getDisplayToothNumber,
} from '../..';

import styles from './TeethNumbersPopup.module.scss';

type TeethNumbersPopupProps = {
  isOpen: boolean;
  title: string;
  isToothRemoving: boolean;
  selectedToothLocalization: LocalizationWithISO | undefined;
  selectedAssetISONumbers: number[] | undefined;
  dentalNotationFormat: DentalNotationFormat;
  animatedToothNumber?: number;
  onRemoveTooth: (toothLocalizationID: string) => void;
  onChangeToothNumber: (
    toothLocalizationID: string,
    newToothISONumber: number,
  ) => void;
  onChangeSelectedLocalization: (
    toothLocalizationID: LocalizationWithISO | undefined,
  ) => void;
};

export const TeethNumbersPopup: FC<TeethNumbersPopupProps> = ({
  isOpen,
  title,
  isToothRemoving,
  dentalNotationFormat,
  animatedToothNumber,
  selectedToothLocalization,
  onChangeSelectedLocalization,
  onChangeToothNumber,
  onRemoveTooth,
}) => {
  const teethNumbers = [...UPPER_JAW_PERMANENT, ...LOWER_JAW_PERMANENT];
  const teethNumbersPrimary = [...UPPER_JAW_PRIMARY, ...LOWER_JAW_PRIMARY];

  return (
    <Popup
      isOpen={isOpen}
      overlayClassname={styles.popupOverlay}
      bodyClassname={styles.popupBody}
      showHeader={false}
      onClose={() => onChangeSelectedLocalization(undefined)}
    >
      <div className={styles.heading}>
        <h2 className={styles.title}>{title}</h2>
        <Button
          size="medium"
          onClick={() => {
            if (selectedToothLocalization) {
              onRemoveTooth(selectedToothLocalization.ID);
            }
          }}
          icon="delete"
          variant="secondary"
          loading={isToothRemoving}
          danger
        >
          <FormattedMessage id="patient.remove" defaultMessage="Remove" />
        </Button>
      </div>

      <div className={styles.teeth}>
        {teethNumbers.map((num) => (
          <div
            key={num}
            className={cn(
              'p3',
              styles.number,

              selectedToothLocalization?.ISONumber === num
                ? styles.selected
                : '',
            )}
            onClick={() => {
              if (selectedToothLocalization) {
                onChangeToothNumber(selectedToothLocalization.ID, num);
              }
            }}
          >
            {getDisplayToothNumber(num, dentalNotationFormat)}
          </div>
        ))}
      </div>

      <div className={styles.primaryTeeth}>
        {teethNumbersPrimary.map((num) => (
          <div
            key={num}
            className={cn(
              'p3',
              styles.number,

              {
                [styles.animated]: animatedToothNumber === num,
                [styles.selected]: selectedToothLocalization?.ISONumber === num,
              },
            )}
            onClick={() => {
              if (selectedToothLocalization) {
                onChangeToothNumber(selectedToothLocalization.ID, num);
              }
            }}
          >
            {getDisplayToothNumber(num, dentalNotationFormat)}
          </div>
        ))}
      </div>
    </Popup>
  );
};

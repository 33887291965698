import { useAppDispatch } from '@/shared/hooks';

import { assetsModel } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';

export const useRemoveAsset = () => {
  const dispatch = useAppDispatch();

  return async (assetID: string) => {
    const { Organization } = await dispatch(
      assetsModel.thunks.deleteAsset({ AssetID: assetID }),
    ).unwrap();

    if (Organization) {
      dispatch(organizationModel.actions.setOne(Organization));
    }
  };
};

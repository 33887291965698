import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import optionDefaultImage from '@/shared/assets/images/StudyCardPlaceholder.png';
import { Study } from '@/shared/api/protocol_gen/model/dto_study';

import { getImageSrc } from '@/entities/assets';

import styles from '../ui/studiesOptions.module.scss';

export const useGetOptionsFromStudies = (studies: Study[]) => {
  const { formatMessage } = useIntl();

  return studies.map((study) => {
    const imgSrc = study.DisplayAssetID
      ? getImageSrc(study.DisplayAssetID, 'preview')
      : optionDefaultImage;

    const createDate = format(new Date(study.Created?.At ?? 0), 'd MMMM, y');

    return {
      label: (
        <div className={styles.selectOption}>
          <img
            src={imgSrc}
            alt={formatMessage({
              id: 'Order3dSegmentronModal.optionAlt',
              defaultMessage: 'Study',
            })}
          />
          <p className="p2">{createDate}</p>
        </div>
      ),
      value: study.ID,
    };
  });
};

export const JAW = {
  quartile1: [18, 17, 16, 15, 14, 13, 12, 11],
  quartile2: [21, 22, 23, 24, 25, 26, 27, 28],
  quartile3: [31, 32, 33, 34, 35, 36, 37, 38],
  quartile4: [48, 47, 46, 45, 44, 43, 42, 41],
  primaryQuartile1: [55, 54, 53, 52, 51],
  primaryQuartile2: [61, 62, 63, 64, 65],
  primaryQuartile3: [71, 72, 73, 74, 75],
  primaryQuartile4: [85, 84, 83, 82, 81],
};

export const LOWER_JAW_TEETH_ISO_NUMBERS = [
  ...JAW.quartile3,
  ...JAW.quartile4,
  ...JAW.primaryQuartile3,
  ...JAW.primaryQuartile4,
];

export const UPPER_JAW_TEETH_ISO_NUMBERS = [
  ...JAW.quartile1,
  ...JAW.quartile2,
  ...JAW.primaryQuartile1,
  ...JAW.primaryQuartile2,
];

export const RIGHT_QUARTILE = [
  'quartile2',
  'quartile3',
  'primaryQuartile2',
  'primaryQuartile3',
];

export const LOWER_JAW_QUARTILE = [
  'quartile3',
  'quartile4',
  'primaryQuartile3',
  'primaryQuartile4',
];

export const RUDIMENT_TEETH_MAP = {
  55: 15,
  54: 14,
  53: 13,
  52: 12,
  51: 11,
  61: 21,
  62: 22,
  63: 23,
  64: 24,
  65: 25,
  71: 31,
  72: 32,
  73: 33,
  74: 34,
  75: 35,
  85: 45,
  84: 44,
  83: 43,
  82: 42,
  81: 41,
} as Record<number, number>;

export const primaryTeeth = [
  ...JAW.primaryQuartile1,
  ...JAW.primaryQuartile2,
  ...JAW.primaryQuartile3,
  ...JAW.primaryQuartile4,
];

export const ISO_PERMANENT_TOOTH_NUMBERS = [
  ...JAW.quartile1,
  ...JAW.quartile2,
  ...JAW.quartile3.reverse(),
  ...JAW.quartile4.reverse(),
];

export const UNIVERSAL_PERMANENT_TOOTH_NUMBERS = [
  ...JAW.quartile1,
  ...JAW.quartile2,
  ...JAW.quartile3,
  ...JAW.quartile4,
];

export const UNIVERSAL_PRIMARY_TOOTH_NUMBERS = {
  55: 'A',
  54: 'B',
  53: 'C',
  52: 'D',
  51: 'E',
  61: 'F',
  62: 'G',
  63: 'H',
  64: 'I',
  65: 'J',
  71: 'O',
  72: 'N',
  73: 'M',
  74: 'L',
  75: 'K',
  85: 'T',
  84: 'S',
  83: 'R',
  82: 'Q',
  81: 'P',
} as const;

export type Quartiles = keyof typeof JAW;

export type UniversalToothKey = keyof typeof UNIVERSAL_PRIMARY_TOOTH_NUMBERS;
export type UniversalToothValue =
  (typeof UNIVERSAL_PRIMARY_TOOTH_NUMBERS)[UniversalToothKey];

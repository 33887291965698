import { useState } from 'react';

import { useModal } from '@/shared/hooks';

export const useRemoveDoctorModal = () => {
  const [currentDoctorID, setCurrentDoctorID] = useState<string>();

  const { isOpen, openModal, closeModal } = useModal();

  const openRemoveDoctorModal = (doctorID: string) => {
    setCurrentDoctorID(doctorID);
    openModal();
  };

  const closeRemoveDoctorModal = () => {
    setCurrentDoctorID(undefined);
    closeModal();
  };

  return {
    currentDoctorID,
    isRemoveDoctorModalOpen: isOpen,
    openRemoveDoctorModal,
    closeRemoveDoctorModal,
  };
};

import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileWithPath } from 'react-dropzone';
import { useParams } from 'react-router';

import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Modal } from '@/shared/ui';

import { ModalID, modalModel } from '@/entities/modal';
import { patientModel } from '@/entities/patient';
// import { useBillingAccess } from '@/entities/billing';

import { useUploadAssetContext } from '@/features/uploadAsset';
import { useNewReport } from '@/features/addNewReport';

import {
  ORDER_REPORT_ASSET_TYPE,
  ORDER_REPORT_STUDY_TYPE,
  OrderReportModalStep,
  UPLOAD_STUDY_FORM_ID,
  UploadStudyPayload,
  uploadStudySchema,
} from '../../config';
import { selectFirstStudyID } from '../../model/orderReportModal.selectors';
import { orderReportTitle } from '../../config/i18n';
import { OrderReportStep } from '../OrderReportStep/OrderReportStep.tsx';

import styles from './OrderReportModal.module.scss';

export const OrderReportModal = () => {
  const dispatch = useAppDispatch();
  const { patientID } = useParams();
  const { formatMessage } = useIntl();

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [STLFiles, setSTLFiles] = useState<FileWithPath[]>([]);
  const [dentalPhotoFiles, setDentalPhotoFiles] = useState<FileWithPath[]>([]);
  const [filesUploadErrorMessage, setFilesUploadErrorMessage] = useState('');
  const [STLFilesUploadErrorMessage, setSTLFilesUploadErrorMessage] =
    useState('');
  const [
    dentalPhotoFilesUploadErrorMessage,
    setDentalPhotoFilesUploadErrorMessage,
  ] = useState('');

  const {
    visible,
    data: { reportType },
  } = useAppSelector(modalModel.selectors.selectOrderReportModal);

  // const services = useBillingAccess();

  const { startUploadAsset: startUploadStudy } = useUploadAssetContext();

  const {
    requestCBCTGPReport,
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
  } = useNewReport();

  const patientName = useAppSelector(
    patientModel.selectors.selectPatientFullName(patientID as string),
  );
  const firstStudyID = useAppSelector(
    selectFirstStudyID(ORDER_REPORT_STUDY_TYPE[reportType]),
  );
  const firstSTLStudyID = useAppSelector(
    selectFirstStudyID(StudyType.StudyType_IOS_Meshes),
  );

  const {
    watch,
    control,
    handleSubmit,
    reset,
    // formState: { isSubmitting },
  } = useForm<UploadStudyPayload>({
    resolver: yupResolver(uploadStudySchema),
  });

  const isSegmentronReport =
    reportType === 'ImplantStudio' || reportType === 'OrthoStudio';
  const firstStepStudyAssetType = ORDER_REPORT_ASSET_TYPE[reportType];

  const currentStudyID = watch('studyID');
  const currentSTLStudyID = watch('STLStudyID');
  const currentDentalPhotoStudyID = watch('DentalPhotoStudyID');

  const uploadFilesWithError = !!(
    filesUploadErrorMessage ||
    STLFilesUploadErrorMessage ||
    dentalPhotoFilesUploadErrorMessage
  );

  const setErrorMessage =
    (filesStep: OrderReportModalStep) => (errorMessage: string) => {
      switch (filesStep) {
        case 1: {
          setFilesUploadErrorMessage(errorMessage);
          break;
        }
        case 2: {
          setSTLFilesUploadErrorMessage(errorMessage);
          break;
        }
        case 3: {
          setDentalPhotoFilesUploadErrorMessage(errorMessage);
        }
      }
    };

  const addFiles =
    (filesStep: OrderReportModalStep) => (acceptedFiles: FileWithPath[]) => {
      switch (filesStep) {
        case 1: {
          setFilesUploadErrorMessage('');
          setFiles((currentFiles) => currentFiles.concat(acceptedFiles));
          break;
        }
        case 2: {
          setSTLFiles((currentFiles) => currentFiles.concat(acceptedFiles));
          break;
        }
        case 3: {
          setDentalPhotoFiles((currentFiles) =>
            currentFiles.concat(acceptedFiles),
          );
          break;
        }
      }
    };

  const removeFiles = (filesStep: OrderReportModalStep) => () => {
    switch (filesStep) {
      case 1: {
        setFiles([]);
        break;
      }
      case 2: {
        setSTLFiles([]);
        break;
      }
      case 3: {
        setDentalPhotoFiles([]);
        break;
      }
    }
  };

  const removeAllFiles = () => {
    setFiles([]);
    setSTLFiles([]);
    setDentalPhotoFiles([]);
  };

  const handleClose = useCallback(() => {
    reset();
    dispatch(modalModel.actions.closeModal(ModalID.OrderReport));

    removeAllFiles();
  }, [dispatch, removeAllFiles]);

  const onSubmit: SubmitHandler<UploadStudyPayload> = async (data) => {
    handleClose();

    const uploadStudyPromises: Promise<void>[] = [];
    const {
      studyID: currentStudyID,
      STLStudyID: currentSTLStudyID,
      DentalPhotoStudyID: currentDentalPhotoStudyID,
    } = data;

    let studyID = currentStudyID;
    let STLStudyID = currentSTLStudyID;
    let DentalPhotoStudyID = currentDentalPhotoStudyID;

    if (files.length > 0) {
      const uploadStudyPromise = startUploadStudy({
        patientID,
        files,
        assetType: firstStepStudyAssetType,
        meta: {
          studyType: firstStepStudyAssetType,
          patientName,
        },
      }).then(({ studyID: currentStudyID }) => {
        studyID = currentStudyID;
      });

      uploadStudyPromises.push(uploadStudyPromise);
    }

    if (STLFiles.length > 0) {
      const uploadSTLStudyPromise = startUploadStudy({
        patientID,
        files: STLFiles,
        assetType: AssetType.AssetType_Study_IOS_Meshes,
        meta: {
          studyType: AssetType.AssetType_Study_IOS_Meshes,
          patientName,
        },
      }).then(({ studyID }) => {
        STLStudyID = studyID;
      });

      uploadStudyPromises.push(uploadSTLStudyPromise);
    }

    // Add the uploadDentalPhotoStudy promise if there are dental photo files
    if (dentalPhotoFiles.length > 0) {
      const uploadDentalPhotoStudyPromise = startUploadStudy({
        patientID,
        files: dentalPhotoFiles,
        assetType: AssetType.AssetType_Study_DentalPhoto,
        meta: {
          studyType: AssetType.AssetType_Study_DentalPhoto,
          patientName,
        },
      }).then(({ studyID }) => {
        DentalPhotoStudyID = studyID;
      });

      uploadStudyPromises.push(uploadDentalPhotoStudyPromise);
    }

    await Promise.allSettled(uploadStudyPromises);

    if (studyID) {
      switch (reportType) {
        case 'CBCT': {
          requestCBCTGPReport(studyID);
          break;
        }
        case 'Pano': {
          requestPanoGPReport(studyID);
          break;
        }
        case 'IOXRay': {
          requestIOXRayGPReport(studyID);
          break;
        }
        case '3DStudio': {
          requestCBCTSegmentationReport(studyID);
          break;
        }
        case 'ImplantStudio': {
          requestStudioImplantReport({
            CBCTStudyID: studyID,
            IOSMeshesStudyID: STLStudyID as string,
            DentalPhotoStudyID: DentalPhotoStudyID as string,
          });
          break;
        }
        case 'OrthoStudio': {
          requestStudioOrthoReport({
            CBCTStudyID: studyID,
            IOSMeshesStudyID: STLStudyID as string,
            DentalPhotoStudyID: DentalPhotoStudyID as string,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  useEffect(() => {
    reset({
      studyID: firstStudyID,
      STLStudyID: firstSTLStudyID,
    });
  }, [firstStudyID, firstSTLStudyID]);

  return (
    <Modal
      containerClassName={styles.modalcontainer}
      title={formatMessage(orderReportTitle[reportType])}
      isOpen={visible}
      onCancel={handleClose}
      borderless
      okButtonText={
        <FormattedMessage
          id="orderReportModal.applyButtonText"
          defaultMessage="Plan"
        />
      }
      okButtonProps={{
        type: 'submit',
        form: UPLOAD_STUDY_FORM_ID,
        disabled:
          (!currentStudyID && files.length === 0) || uploadFilesWithError,
      }}
      cancelButtonProps={{
        variant: 'tertiary',
      }}
    >
      <form
        id={UPLOAD_STUDY_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.container}
      >
        <OrderReportStep
          control={control}
          currentStudyID={currentStudyID}
          step={isSegmentronReport ? 1 : undefined}
          orderReportType={reportType}
          studyType={ORDER_REPORT_STUDY_TYPE[reportType]}
          assetType={firstStepStudyAssetType}
          errorMessage={filesUploadErrorMessage}
          files={files}
          onAddFiles={addFiles(1)}
          onDeleteFiles={removeFiles(1)}
          onError={setErrorMessage(1)}
        />
        {isSegmentronReport && (
          <>
            <OrderReportStep
              control={control}
              currentStudyID={currentSTLStudyID}
              step={2}
              orderReportType={reportType}
              studyType={StudyType.StudyType_IOS_Meshes}
              assetType={AssetType.AssetType_Study_IOS_Meshes}
              errorMessage={STLFilesUploadErrorMessage}
              files={STLFiles}
              onAddFiles={addFiles(2)}
              onDeleteFiles={removeFiles(2)}
              onError={setErrorMessage(2)}
            />

            <OrderReportStep
              control={control}
              currentStudyID={currentDentalPhotoStudyID}
              step={3}
              orderReportType={reportType}
              studyType={StudyType.StudyType_DentalPhoto}
              assetType={AssetType.AssetType_Study_DentalPhoto}
              errorMessage={dentalPhotoFilesUploadErrorMessage}
              files={dentalPhotoFiles}
              onAddFiles={addFiles(3)}
              onDeleteFiles={removeFiles(3)}
              onError={setErrorMessage(3)}
            />
          </>
        )}
      </form>
    </Modal>
  );
};

import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Icon, Tooltip, WidgetCard } from '@/shared/ui';
import { useAppSelector, useModal } from '@/shared/hooks';

import { DoctorRow } from '@/entities/doctors';
import { getPatientYourPermissions, patientModel } from '@/entities/patient';

import {
  RemoveDoctorModal,
  useRemoveDoctorModal,
} from '@/features/removeDoctor';
import { AddDoctorsModal } from '@/features/addDoctors';
import { ShowAllTreatingDoctorsModal } from '@/features/showAllTreatingDoctors';
import { patientDoctorsModel } from '@/features/patientDoctors';

import { getShortedList } from '../../utils/getShortedList';
import { MAX_ROWS_COUNT } from '../../config/constants';

import styles from './TreatingDoctors.module.scss';

type TreatingDoctorsProps = {
  className?: string;
  patientID: string;
};

export const TreatingDoctors: FC<TreatingDoctorsProps> = (props) => {
  const { className, patientID } = props;

  const patientTreatingDoctors = useAppSelector(
    patientDoctorsModel.selectors.selectPatientThreatingDoctors(patientID),
  );

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const {
    openRemoveDoctorModal,
    closeRemoveDoctorModal,
    isRemoveDoctorModalOpen,
    currentDoctorID,
  } = useRemoveDoctorModal();

  const {
    isOpen: isShowAllModalOpen,
    openModal: openShowAllModal,
    closeModal: closeShowAllModal,
  } = useModal();

  const { CanViewTeam, CanEditTeam } = getPatientYourPermissions(patient);

  const {
    isOpen: isAddDoctorsModalOpen,
    openModal: openAddDoctorsModal,
    closeModal: closeAddDoctorsModal,
  } = useModal();

  if (!CanViewTeam || !patientTreatingDoctors) {
    return null;
  }

  const currentDoctorIDs = patientTreatingDoctors.map(
    (treatingDoctor) => treatingDoctor?.UserID,
  );

  const shouldShowAllAppear = patientTreatingDoctors.length > MAX_ROWS_COUNT;

  const shortedTreatingDoctorsList = getShortedList(patientTreatingDoctors);

  return (
    <>
      <WidgetCard className={cn(styles.container, className)}>
        <div className={styles.header}>
          <h4 className="h4">
            <FormattedMessage
              id="patientInfo.treatingDoctors"
              defaultMessage="Treating doctors"
            />
          </h4>

          {CanEditTeam && (
            <Tooltip.Primary
              content={
                <FormattedMessage
                  id="patientInfo.addTreatingDoctor"
                  defaultMessage="Add a treating doctor"
                />
              }
            >
              <Icon
                name="boldPlus"
                className={styles.plusIcon}
                onClick={openAddDoctorsModal}
                size={32}
              />
            </Tooltip.Primary>
          )}
        </div>

        {shortedTreatingDoctorsList.length > 0 ? (
          <div className={styles.doctorsList}>
            {shortedTreatingDoctorsList?.map((treatingDoctor) => (
              <DoctorRow
                key={treatingDoctor.UserID}
                doctor={treatingDoctor}
                onDelete={() => openRemoveDoctorModal(treatingDoctor?.UserID)}
              />
            ))}
          </div>
        ) : (
          <div className={styles.emptyList}>
            <Icon className={styles.emptyListIcon} name="users" size={64} />
          </div>
        )}

        {shouldShowAllAppear && (
          <Button variant="tertiary" size="medium" onClick={openShowAllModal}>
            <FormattedMessage
              id="patientInfo.showAll"
              defaultMessage="Show all"
            />
          </Button>
        )}
      </WidgetCard>

      <RemoveDoctorModal
        patientID={patientID}
        doctorID={currentDoctorID}
        isOpen={isRemoveDoctorModalOpen}
        onCancel={closeRemoveDoctorModal}
      />

      <AddDoctorsModal
        currentDoctorIDs={currentDoctorIDs}
        patientID={patientID}
        isOpen={isAddDoctorsModalOpen}
        onCancel={closeAddDoctorsModal}
      />

      <ShowAllTreatingDoctorsModal
        isOpen={isShowAllModalOpen}
        onClose={closeShowAllModal}
        treatingDoctors={patientTreatingDoctors}
        openRemoveDoctorModal={openRemoveDoctorModal}
      />
    </>
  );
};

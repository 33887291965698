/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  JawType,
  jawTypeFromJSON,
  jawTypeToJSON,
} from '../model/dto_report_common';
import {
  ClosedPolygon,
  ProjectionTransform,
  BBox,
  PlanePosition,
} from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

/** note: this is emitted only if dental photo was passed as report study */
export interface SegmentronPhotoIOSSuperimposition {
  /** >> Asset.ID with AssetType_Study_DentalPhoto */
  PhotoAssetID: string;
  Mask: ClosedPolygon | undefined;
  FocalLength: number;
  ViewToCbctTransform: ProjectionTransform | undefined;
  BBox: BBox | undefined;
  PlanePosition: PlanePosition | undefined;
}

export interface IOSJawTypeClassification {
  /**
   * [required]: ID of the file (corresponding to one of the files in
   * `AssetContent_Study_IOS_Meshes`) for the classification is done.
   */
  TargetFileID: string;
  /** [required] */
  Type: JawType;
}

function createBaseSegmentronPhotoIOSSuperimposition(): SegmentronPhotoIOSSuperimposition {
  return {
    PhotoAssetID: '',
    Mask: undefined,
    FocalLength: 0,
    ViewToCbctTransform: undefined,
    BBox: undefined,
    PlanePosition: undefined,
  };
}

export const SegmentronPhotoIOSSuperimposition = {
  encode(
    message: SegmentronPhotoIOSSuperimposition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PhotoAssetID !== '') {
      writer.uint32(10).string(message.PhotoAssetID);
    }
    if (message.Mask !== undefined) {
      ClosedPolygon.encode(message.Mask, writer.uint32(58).fork()).ldelim();
    }
    if (message.FocalLength !== 0) {
      writer.uint32(29).float(message.FocalLength);
    }
    if (message.ViewToCbctTransform !== undefined) {
      ProjectionTransform.encode(
        message.ViewToCbctTransform,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.BBox !== undefined) {
      BBox.encode(message.BBox, writer.uint32(42).fork()).ldelim();
    }
    if (message.PlanePosition !== undefined) {
      PlanePosition.encode(
        message.PlanePosition,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SegmentronPhotoIOSSuperimposition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegmentronPhotoIOSSuperimposition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PhotoAssetID = reader.string();
          break;
        case 7:
          message.Mask = ClosedPolygon.decode(reader, reader.uint32());
          break;
        case 3:
          message.FocalLength = reader.float();
          break;
        case 4:
          message.ViewToCbctTransform = ProjectionTransform.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.BBox = BBox.decode(reader, reader.uint32());
          break;
        case 6:
          message.PlanePosition = PlanePosition.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SegmentronPhotoIOSSuperimposition {
    return {
      PhotoAssetID: isSet(object.PhotoAssetID)
        ? String(object.PhotoAssetID)
        : '',
      Mask: isSet(object.Mask)
        ? ClosedPolygon.fromJSON(object.Mask)
        : undefined,
      FocalLength: isSet(object.FocalLength) ? Number(object.FocalLength) : 0,
      ViewToCbctTransform: isSet(object.ViewToCbctTransform)
        ? ProjectionTransform.fromJSON(object.ViewToCbctTransform)
        : undefined,
      BBox: isSet(object.BBox) ? BBox.fromJSON(object.BBox) : undefined,
      PlanePosition: isSet(object.PlanePosition)
        ? PlanePosition.fromJSON(object.PlanePosition)
        : undefined,
    };
  },

  toJSON(message: SegmentronPhotoIOSSuperimposition): unknown {
    const obj: any = {};
    message.PhotoAssetID !== undefined &&
      (obj.PhotoAssetID = message.PhotoAssetID);
    message.Mask !== undefined &&
      (obj.Mask = message.Mask
        ? ClosedPolygon.toJSON(message.Mask)
        : undefined);
    message.FocalLength !== undefined &&
      (obj.FocalLength = message.FocalLength);
    message.ViewToCbctTransform !== undefined &&
      (obj.ViewToCbctTransform = message.ViewToCbctTransform
        ? ProjectionTransform.toJSON(message.ViewToCbctTransform)
        : undefined);
    message.BBox !== undefined &&
      (obj.BBox = message.BBox ? BBox.toJSON(message.BBox) : undefined);
    message.PlanePosition !== undefined &&
      (obj.PlanePosition = message.PlanePosition
        ? PlanePosition.toJSON(message.PlanePosition)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SegmentronPhotoIOSSuperimposition>, I>,
  >(object: I): SegmentronPhotoIOSSuperimposition {
    const message = createBaseSegmentronPhotoIOSSuperimposition();
    message.PhotoAssetID = object.PhotoAssetID ?? '';
    message.Mask =
      object.Mask !== undefined && object.Mask !== null
        ? ClosedPolygon.fromPartial(object.Mask)
        : undefined;
    message.FocalLength = object.FocalLength ?? 0;
    message.ViewToCbctTransform =
      object.ViewToCbctTransform !== undefined &&
      object.ViewToCbctTransform !== null
        ? ProjectionTransform.fromPartial(object.ViewToCbctTransform)
        : undefined;
    message.BBox =
      object.BBox !== undefined && object.BBox !== null
        ? BBox.fromPartial(object.BBox)
        : undefined;
    message.PlanePosition =
      object.PlanePosition !== undefined && object.PlanePosition !== null
        ? PlanePosition.fromPartial(object.PlanePosition)
        : undefined;
    return message;
  },
};

function createBaseIOSJawTypeClassification(): IOSJawTypeClassification {
  return { TargetFileID: '', Type: 0 };
}

export const IOSJawTypeClassification = {
  encode(
    message: IOSJawTypeClassification,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TargetFileID !== '') {
      writer.uint32(10).string(message.TargetFileID);
    }
    if (message.Type !== 0) {
      writer.uint32(16).int32(message.Type);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): IOSJawTypeClassification {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIOSJawTypeClassification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TargetFileID = reader.string();
          break;
        case 2:
          message.Type = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IOSJawTypeClassification {
    return {
      TargetFileID: isSet(object.TargetFileID)
        ? String(object.TargetFileID)
        : '',
      Type: isSet(object.Type) ? jawTypeFromJSON(object.Type) : 0,
    };
  },

  toJSON(message: IOSJawTypeClassification): unknown {
    const obj: any = {};
    message.TargetFileID !== undefined &&
      (obj.TargetFileID = message.TargetFileID);
    message.Type !== undefined && (obj.Type = jawTypeToJSON(message.Type));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<IOSJawTypeClassification>, I>>(
    object: I,
  ): IOSJawTypeClassification {
    const message = createBaseIOSJawTypeClassification();
    message.TargetFileID = object.TargetFileID ?? '';
    message.Type = object.Type ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

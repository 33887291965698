import { useEffect, useState } from 'react';

interface WindowSizes {
  width: number;
  height: number;
  aspect: number;
}

export const useWindowSize = (): WindowSizes => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    aspect: window.innerWidth / window.innerHeight,
  });

  const resizeHandle = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      aspect: window.innerWidth / window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    };
  }, []);

  return dimensions;
};

export default useWindowSize;

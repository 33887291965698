import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Accordion, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';

import { LabelingHeader } from './LabelingHeader/LabelingHeader';
import { LabelingContent } from './LabelingContent/LabelingContent';
import styles from './AboutLabelingModal.module.scss';

type AboutLabelingModalProps = {
  className?: string;
};

export const AboutLabelingModal: FC<AboutLabelingModalProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const [country, setCountry] = useState<string>('');

  const { visible } = useAppSelector(
    modalModel.selectors.selectAboutLabelingModal,
  );

  const handleChange = (newValue: string | string[]) => {
    if (typeof newValue === 'string') {
      setCountry(newValue);
    }
  };

  const handleClose = () => {
    dispatch(modalModel.actions.closeModal(ModalID.AboutLabelingModal));
  };

  return (
    <Modal
      isOpen={visible}
      onCancel={handleClose}
      className={cn(styles.container, className)}
      title={
        <FormattedMessage
          id="labeling.aboutDiagnocat"
          defaultMessage="About Diagnocat"
        />
      }
      hideFooter
    >
      <Accordion.Root type="single" defaultValue="labeling">
        <Accordion.Item value="labeling" className={styles.item}>
          <Accordion.Header>
            <LabelingHeader onChange={handleChange} country={country} />
          </Accordion.Header>

          {country && (
            <Accordion.Content>
              <LabelingContent country={country} />
            </Accordion.Content>
          )}
        </Accordion.Item>
      </Accordion.Root>
    </Modal>
  );
};

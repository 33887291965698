import { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';

import { useMedia } from '@/shared/hooks';
import { MODAL_ANIMATION_DURATION } from '@/shared/config';

import { Portal } from '../Portal/Portal';
import { Button, ButtonProps } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import styles from './Modal.module.scss';

export type ModalProps = {
  isOpen: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  className?: string;
  testID?: string;
  containerClassName?: string;
  bodyClassName?: string;
  title?: string | JSX.Element;
  okButtonText?: string | JSX.Element;
  cancelButtonText?: string | JSX.Element;
  shouldRenderCloseIconButton?: boolean;
  shouldRenderCancelButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  children?: ReactNode;
  footer?: ReactNode;
  hideFooter?: boolean;
  borderless?: boolean;
  modalInlineStyle?: CSSProperties;
};

export const Modal: FC<ModalProps> = ({
  isOpen,
  title,
  onCancel: onClose = () => {},
  onOk: onApply = () => {},
  className,
  testID,
  bodyClassName,
  containerClassName,
  shouldRenderCloseIconButton = true,
  shouldRenderCancelButton = true,
  shouldCloseOnOverlayClick = true,
  okButtonText: applyButtonText = (
    <FormattedMessage id="global.apply" defaultMessage="Apply" />
  ),
  cancelButtonText = (
    <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
  ),
  okButtonProps: applyButtonProps,
  cancelButtonProps,
  children,
  footer,
  hideFooter = false,
  borderless = false,
  modalInlineStyle,
}) => {
  const { isMobile } = useMedia();

  const showCloseIcon =
    typeof onClose === 'function' && shouldRenderCloseIconButton;

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <div className={cn(styles.modal, className)} data-testid={testID}>
            <motion.div
              className={styles.overlay}
              {...(shouldCloseOnOverlayClick ? { onClick: onClose } : {})}
              key="modal-overlay"
              transition={{ duration: MODAL_ANIMATION_DURATION }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />

            <motion.div
              className={cn(styles.container, containerClassName)}
              style={{ ...modalInlineStyle }}
              key="modal"
              exit="exit"
              variants={{
                initial: {
                  scale: 0,
                  opacity: 0,
                },
                exit: {
                  scale: 0,
                  opacity: 0,
                },
                open: {
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: MODAL_ANIMATION_DURATION,
                  },
                },
                closed: {
                  scale: 0,
                  opacity: 0,
                  transition: {
                    duration: MODAL_ANIMATION_DURATION,
                  },
                },
              }}
              initial="initial"
              animate={isOpen ? 'open' : 'closed'}
            >
              <header
                className={cn(styles.header, borderless && styles.borderless)}
              >
                <h3 className={cn(styles.title, isMobile ? 'h4' : 'h3')}>
                  {title}
                </h3>
                {showCloseIcon && (
                  <Icon
                    className={styles.crossButton}
                    name="close"
                    size={40}
                    onClick={onClose}
                  />
                )}
              </header>

              <div className={cn(styles.modalBody, bodyClassName)}>
                {children}
              </div>

              {hideFooter ? null : (
                <footer
                  className={cn(styles.footer, borderless && styles.borderless)}
                >
                  {footer || (
                    <>
                      {shouldRenderCancelButton && (
                        <Button
                          variant="secondary"
                          onClick={onClose}
                          {...cancelButtonProps}
                        >
                          {cancelButtonText}
                        </Button>
                      )}

                      <Button onClick={onApply} {...applyButtonProps}>
                        {applyButtonText}
                      </Button>
                    </>
                  )}
                </footer>
              )}
            </motion.div>
          </div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

import { JAW, Quartiles } from '../config/toothConfig';

export const getJawQuartile = (toothNumber: number): Quartiles => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const targetJawEntry = Object.entries(JAW).find(([_, teeth]) =>
    teeth.includes(toothNumber),
  );

  if (!targetJawEntry) {
    throw new Error(`Could not find jaw for tooth number ${toothNumber}`);
  }

  return targetJawEntry[0] as unknown as Quartiles;
};

export const getDatePlaceholderByLocale = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'ДД.ММ.ГГГГ';

    case 'en-GB':
      return 'DD/MM/YYYY';

    case 'zh-CN':
      return 'YYYY-MM-DD';

    default:
      return 'MM-DD-YYYY';
  }
};

import cn from 'classnames';
import { useIntl } from 'react-intl';
import { compose, sum, map, prop } from 'ramda';

import { Icon } from '@/shared/ui';
import { useFormatBytes } from '@/shared/hooks';

import styles from './UploadStudyFile.module.scss';

type UploadStudyFileProps = {
  files: File[];
  onDelete: () => void;
  errorText?: string;
};

export const UploadStudyFile = (props: UploadStudyFileProps) => {
  const { files, onDelete, errorText } = props;

  const { formatMessage } = useIntl();
  const formatBytes = useFormatBytes();

  const singleFile = files.length === 1;

  const fileName = singleFile
    ? files[0]?.name
    : formatMessage(
        { id: 'UploadStudyFile.files', defaultMessage: '{amount} files' },
        { amount: files.length },
      );

  const fileSize = singleFile
    ? files[0]?.size
    : compose(sum, map<File, number>(prop('size')))(files);

  return (
    <div className={cn(styles.container, errorText && styles.invalid)}>
      <Icon className={styles.fileIcon} name="doc" size={32} />

      <h4 className={cn(styles.name, 'p2')}>{fileName}</h4>

      <span className={cn(styles.size, 'p2')}>{formatBytes(fileSize)}</span>

      <button className={styles.deleteButton} type="button" onClick={onDelete}>
        <Icon className={styles.deleteIcon} name="delete" size={32} />
      </button>
      {errorText && (
        <span className={cn(styles.errorText, 'p3')}>{errorText}</span>
      )}
    </div>
  );
};

/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { Notification } from '../../api/notifications/dto_notification';
import { Empty } from '../../google/protobuf/empty';

export const protobufPackage = 'com.diagnocat.notifications';

export interface NotificationsStreamReq {}

export interface NotificationsStreamResp {
  Historical: Notification | undefined;
  Live: Notification | undefined;
  Ping: Empty | undefined;
}

export interface NotificationsMarkAsReadReq {
  IDs: NotificationsMarkAsReadReq_SelectedIDs | undefined;
  All: Empty | undefined;
}

export interface NotificationsMarkAsReadReq_SelectedIDs {
  IDs: string[];
}

export interface NotificationsMarkAsReadResp {}

export interface RegisterPushSubscriptionReq {
  UserID: string;
  /**
   * Just unique device ID; Obtain it somehow on-device or just generate and store.
   * FIXME: May be removed in future.
   */
  DeviceID: string;
  WebPushSubscription: string | undefined;
  APNS2DeviceToken: string | undefined;
}

export interface RegisterPushSubscriptionResp {}

function createBaseNotificationsStreamReq(): NotificationsStreamReq {
  return {};
}

export const NotificationsStreamReq = {
  encode(
    _: NotificationsStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationsStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationsStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): NotificationsStreamReq {
    return {};
  },

  toJSON(_: NotificationsStreamReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationsStreamReq>, I>>(
    _: I,
  ): NotificationsStreamReq {
    const message = createBaseNotificationsStreamReq();
    return message;
  },
};

function createBaseNotificationsStreamResp(): NotificationsStreamResp {
  return { Historical: undefined, Live: undefined, Ping: undefined };
}

export const NotificationsStreamResp = {
  encode(
    message: NotificationsStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Historical !== undefined) {
      Notification.encode(
        message.Historical,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Live !== undefined) {
      Notification.encode(message.Live, writer.uint32(18).fork()).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationsStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationsStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Historical = Notification.decode(reader, reader.uint32());
          break;
        case 2:
          message.Live = Notification.decode(reader, reader.uint32());
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationsStreamResp {
    return {
      Historical: isSet(object.Historical)
        ? Notification.fromJSON(object.Historical)
        : undefined,
      Live: isSet(object.Live) ? Notification.fromJSON(object.Live) : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: NotificationsStreamResp): unknown {
    const obj: any = {};
    message.Historical !== undefined &&
      (obj.Historical = message.Historical
        ? Notification.toJSON(message.Historical)
        : undefined);
    message.Live !== undefined &&
      (obj.Live = message.Live ? Notification.toJSON(message.Live) : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationsStreamResp>, I>>(
    object: I,
  ): NotificationsStreamResp {
    const message = createBaseNotificationsStreamResp();
    message.Historical =
      object.Historical !== undefined && object.Historical !== null
        ? Notification.fromPartial(object.Historical)
        : undefined;
    message.Live =
      object.Live !== undefined && object.Live !== null
        ? Notification.fromPartial(object.Live)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseNotificationsMarkAsReadReq(): NotificationsMarkAsReadReq {
  return { IDs: undefined, All: undefined };
}

export const NotificationsMarkAsReadReq = {
  encode(
    message: NotificationsMarkAsReadReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IDs !== undefined) {
      NotificationsMarkAsReadReq_SelectedIDs.encode(
        message.IDs,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.All !== undefined) {
      Empty.encode(message.All, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationsMarkAsReadReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationsMarkAsReadReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IDs = NotificationsMarkAsReadReq_SelectedIDs.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.All = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationsMarkAsReadReq {
    return {
      IDs: isSet(object.IDs)
        ? NotificationsMarkAsReadReq_SelectedIDs.fromJSON(object.IDs)
        : undefined,
      All: isSet(object.All) ? Empty.fromJSON(object.All) : undefined,
    };
  },

  toJSON(message: NotificationsMarkAsReadReq): unknown {
    const obj: any = {};
    message.IDs !== undefined &&
      (obj.IDs = message.IDs
        ? NotificationsMarkAsReadReq_SelectedIDs.toJSON(message.IDs)
        : undefined);
    message.All !== undefined &&
      (obj.All = message.All ? Empty.toJSON(message.All) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationsMarkAsReadReq>, I>>(
    object: I,
  ): NotificationsMarkAsReadReq {
    const message = createBaseNotificationsMarkAsReadReq();
    message.IDs =
      object.IDs !== undefined && object.IDs !== null
        ? NotificationsMarkAsReadReq_SelectedIDs.fromPartial(object.IDs)
        : undefined;
    message.All =
      object.All !== undefined && object.All !== null
        ? Empty.fromPartial(object.All)
        : undefined;
    return message;
  },
};

function createBaseNotificationsMarkAsReadReq_SelectedIDs(): NotificationsMarkAsReadReq_SelectedIDs {
  return { IDs: [] };
}

export const NotificationsMarkAsReadReq_SelectedIDs = {
  encode(
    message: NotificationsMarkAsReadReq_SelectedIDs,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.IDs) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationsMarkAsReadReq_SelectedIDs {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationsMarkAsReadReq_SelectedIDs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationsMarkAsReadReq_SelectedIDs {
    return {
      IDs: Array.isArray(object?.IDs)
        ? object.IDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: NotificationsMarkAsReadReq_SelectedIDs): unknown {
    const obj: any = {};
    if (message.IDs) {
      obj.IDs = message.IDs.map((e) => e);
    } else {
      obj.IDs = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationsMarkAsReadReq_SelectedIDs>, I>,
  >(object: I): NotificationsMarkAsReadReq_SelectedIDs {
    const message = createBaseNotificationsMarkAsReadReq_SelectedIDs();
    message.IDs = object.IDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseNotificationsMarkAsReadResp(): NotificationsMarkAsReadResp {
  return {};
}

export const NotificationsMarkAsReadResp = {
  encode(
    _: NotificationsMarkAsReadResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationsMarkAsReadResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationsMarkAsReadResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): NotificationsMarkAsReadResp {
    return {};
  },

  toJSON(_: NotificationsMarkAsReadResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationsMarkAsReadResp>, I>>(
    _: I,
  ): NotificationsMarkAsReadResp {
    const message = createBaseNotificationsMarkAsReadResp();
    return message;
  },
};

function createBaseRegisterPushSubscriptionReq(): RegisterPushSubscriptionReq {
  return {
    UserID: '',
    DeviceID: '',
    WebPushSubscription: undefined,
    APNS2DeviceToken: undefined,
  };
}

export const RegisterPushSubscriptionReq = {
  encode(
    message: RegisterPushSubscriptionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.DeviceID !== '') {
      writer.uint32(18).string(message.DeviceID);
    }
    if (message.WebPushSubscription !== undefined) {
      writer.uint32(66).string(message.WebPushSubscription);
    }
    if (message.APNS2DeviceToken !== undefined) {
      writer.uint32(74).string(message.APNS2DeviceToken);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RegisterPushSubscriptionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterPushSubscriptionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.DeviceID = reader.string();
          break;
        case 8:
          message.WebPushSubscription = reader.string();
          break;
        case 9:
          message.APNS2DeviceToken = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RegisterPushSubscriptionReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      DeviceID: isSet(object.DeviceID) ? String(object.DeviceID) : '',
      WebPushSubscription: isSet(object.WebPushSubscription)
        ? String(object.WebPushSubscription)
        : undefined,
      APNS2DeviceToken: isSet(object.APNS2DeviceToken)
        ? String(object.APNS2DeviceToken)
        : undefined,
    };
  },

  toJSON(message: RegisterPushSubscriptionReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.DeviceID !== undefined && (obj.DeviceID = message.DeviceID);
    message.WebPushSubscription !== undefined &&
      (obj.WebPushSubscription = message.WebPushSubscription);
    message.APNS2DeviceToken !== undefined &&
      (obj.APNS2DeviceToken = message.APNS2DeviceToken);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RegisterPushSubscriptionReq>, I>>(
    object: I,
  ): RegisterPushSubscriptionReq {
    const message = createBaseRegisterPushSubscriptionReq();
    message.UserID = object.UserID ?? '';
    message.DeviceID = object.DeviceID ?? '';
    message.WebPushSubscription = object.WebPushSubscription ?? undefined;
    message.APNS2DeviceToken = object.APNS2DeviceToken ?? undefined;
    return message;
  },
};

function createBaseRegisterPushSubscriptionResp(): RegisterPushSubscriptionResp {
  return {};
}

export const RegisterPushSubscriptionResp = {
  encode(
    _: RegisterPushSubscriptionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RegisterPushSubscriptionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterPushSubscriptionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): RegisterPushSubscriptionResp {
    return {};
  },

  toJSON(_: RegisterPushSubscriptionResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RegisterPushSubscriptionResp>, I>>(
    _: I,
  ): RegisterPushSubscriptionResp {
    const message = createBaseRegisterPushSubscriptionResp();
    return message;
  },
};

export interface Notifications {
  Stream(
    request: DeepPartial<NotificationsStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<NotificationsStreamResp>;
  MarkAsRead(
    request: DeepPartial<NotificationsMarkAsReadReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotificationsMarkAsReadResp>;
  RegisterPushSubscription(
    request: DeepPartial<RegisterPushSubscriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<RegisterPushSubscriptionResp>;
}

export class NotificationsClientImpl implements Notifications {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Stream = this.Stream.bind(this);
    this.MarkAsRead = this.MarkAsRead.bind(this);
    this.RegisterPushSubscription = this.RegisterPushSubscription.bind(this);
  }

  Stream(
    request: DeepPartial<NotificationsStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<NotificationsStreamResp> {
    return this.rpc.invoke(
      NotificationsStreamDesc,
      NotificationsStreamReq.fromPartial(request),
      metadata,
    );
  }

  MarkAsRead(
    request: DeepPartial<NotificationsMarkAsReadReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotificationsMarkAsReadResp> {
    return this.rpc.unary(
      NotificationsMarkAsReadDesc,
      NotificationsMarkAsReadReq.fromPartial(request),
      metadata,
    );
  }

  RegisterPushSubscription(
    request: DeepPartial<RegisterPushSubscriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<RegisterPushSubscriptionResp> {
    return this.rpc.unary(
      NotificationsRegisterPushSubscriptionDesc,
      RegisterPushSubscriptionReq.fromPartial(request),
      metadata,
    );
  }
}

export const NotificationsDesc = {
  serviceName: 'com.diagnocat.notifications.Notifications',
};

export const NotificationsStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'Stream',
  service: NotificationsDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return NotificationsStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...NotificationsStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const NotificationsMarkAsReadDesc: UnaryMethodDefinitionish = {
  methodName: 'MarkAsRead',
  service: NotificationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return NotificationsMarkAsReadReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...NotificationsMarkAsReadResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const NotificationsRegisterPushSubscriptionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'RegisterPushSubscription',
    service: NotificationsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RegisterPushSubscriptionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...RegisterPushSubscriptionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal, ModalProps } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { accessModel } from '@/entities/access';
import { organizationModel } from '@/entities/organization';

type RemoveStaffMemberModalProps = {
  className?: string;
  testID?: string;
  deletingUserId: string;
  isInvite?: boolean;
} & Pick<ModalProps, 'isOpen' | 'onCancel'>;

export const RemoveStaffMemberModal: FC<RemoveStaffMemberModalProps> = (
  props,
) => {
  const {
    className,
    testID,
    isOpen,
    onCancel: onCancel,
    deletingUserId,
    isInvite,
  } = props;
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const { formatMessage } = useIntl();

  const handleDeleteStaffMember = async () => {
    setIsDeleting(true);
    try {
      const { Organization: updatedOrganization } = await dispatch(
        accessModel.thunks.setUserOrganizationRoles({
          UserID: deletingUserId,
          OrganizationID: organizationID,
          Roles: [],
        }),
      ).unwrap();

      if (updatedOrganization) {
        dispatch(organizationModel.actions.setOne(updatedOrganization));
      }
    } finally {
      setIsDeleting(false);
      if (onCancel) {
        onCancel();
      }
    }
  };

  const handleDeleteInviteStaffMember = async () => {
    setIsDeleting(true);
    try {
      const { Invitation } = await dispatch(
        accessModel.thunks.cancelInvitation({
          InvitationID: deletingUserId,
        }),
      ).unwrap();

      if (Invitation) {
        dispatch(accessModel.actions.removeOne(Invitation.ID));
      }
    } finally {
      setIsDeleting(false);
      if (onCancel) {
        onCancel();
      }
    }
  };

  return (
    <Modal
      className={className}
      data-testid={testID}
      title={formatMessage({
        id: 'removeStaffMemberModal.title',
        defaultMessage: 'Remove this staff member?',
      })}
      isOpen={isOpen}
      onCancel={onCancel}
      onOk={isInvite ? handleDeleteInviteStaffMember : handleDeleteStaffMember}
      okButtonText={formatMessage({
        id: 'global.applyRemove',
        defaultMessage: 'Yes, remove',
      })}
      okButtonProps={{
        danger: true,
        loading: isDeleting,
      }}
    />
  );
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Message } from '../../api/messenger/dto_message';
import {
  StudyInfo,
  PatientInfo,
  DoctorInfo,
  ProjectInfo,
  ReportInfo,
  ProjectTaskInfo,
  ProjectTaskAssignment,
} from '../../backbone/aux_enrichment_models';

export const protobufPackage = 'com.diagnocat.notifications';

export interface NotificationMessagePosted {
  Message: Message | undefined;
  Study: StudyInfo | undefined;
  Patient: PatientInfo | undefined;
  Sender: DoctorInfo | undefined;
  TreatingDoctors: DoctorInfo[];
}

export interface NotificationMessageEdited {
  Message: Message | undefined;
}

export interface NotificationMessageDeleted {
  Message: Message | undefined;
}

export interface NotificationStudyUploaded {
  Study: StudyInfo | undefined;
  Patient: PatientInfo | undefined;
  Project: ProjectInfo | undefined;
  TreatingDoctors: DoctorInfo[];
  Doctor: DoctorInfo | undefined;
}

export interface NotificationReportRequested {
  Report: ReportInfo | undefined;
  Study: StudyInfo | undefined;
  Patient: PatientInfo | undefined;
  Project: ProjectInfo | undefined;
  TreatingDoctors: DoctorInfo[];
  Doctor: DoctorInfo | undefined;
}

export interface NotificationReportCompleted {
  Report: ReportInfo | undefined;
  Study: StudyInfo | undefined;
  Patient: PatientInfo | undefined;
  Project: ProjectInfo | undefined;
  TreatingDoctors: DoctorInfo[];
  Doctor: DoctorInfo | undefined;
  Success: NotificationReportCompletedEventSuccess | undefined;
  Error: NotificationReportCompletedEventError | undefined;
}

export interface NotificationReportCompletedEventSuccess {}

export interface NotificationReportCompletedEventError {
  Code: string;
  Message: string;
}

export interface NotificationPatientStatusChanged {
  Patient: PatientInfo | undefined;
  Doctor: DoctorInfo | undefined;
  TreatingDoctors: DoctorInfo[];
  PreviousStatus: string;
}

export interface NotificationPatientProfileChanged {
  OriginalPatient: PatientInfo | undefined;
  ModifiedPatient: PatientInfo | undefined;
  Doctor: DoctorInfo | undefined;
  TreatingDoctors: DoctorInfo[];
}

export interface NotificationPatientInviteAccepted {
  Patient: PatientInfo | undefined;
  SenderDoctor: DoctorInfo | undefined;
  RecipientDoctor: DoctorInfo | undefined;
  TreatingDoctors: DoctorInfo[];
}

export interface NotificationPatientTreatingDoctorAdded {
  Patient: PatientInfo | undefined;
  Doctor: DoctorInfo | undefined;
  TreatingDoctors: DoctorInfo[];
  AddedDoctor: DoctorInfo | undefined;
}

export interface NotificationPatientTreatingDoctorRemoved {
  Patient: PatientInfo | undefined;
  Doctor: DoctorInfo | undefined;
  TreatingDoctors: DoctorInfo[];
  RemovedDoctor: DoctorInfo | undefined;
}

export interface NotificationProjectTaskAddAssignee {
  Task: ProjectTaskInfo | undefined;
  Assignment: ProjectTaskAssignment | undefined;
  Patient: PatientInfo | undefined;
  TreatingDoctors: DoctorInfo[];
}

function createBaseNotificationMessagePosted(): NotificationMessagePosted {
  return {
    Message: undefined,
    Study: undefined,
    Patient: undefined,
    Sender: undefined,
    TreatingDoctors: [],
  };
}

export const NotificationMessagePosted = {
  encode(
    message: NotificationMessagePosted,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Message !== undefined) {
      Message.encode(message.Message, writer.uint32(10).fork()).ldelim();
    }
    if (message.Study !== undefined) {
      StudyInfo.encode(message.Study, writer.uint32(18).fork()).ldelim();
    }
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(26).fork()).ldelim();
    }
    if (message.Sender !== undefined) {
      DoctorInfo.encode(message.Sender, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationMessagePosted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationMessagePosted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Message = Message.decode(reader, reader.uint32());
          break;
        case 2:
          message.Study = StudyInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.Sender = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 5:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationMessagePosted {
    return {
      Message: isSet(object.Message)
        ? Message.fromJSON(object.Message)
        : undefined,
      Study: isSet(object.Study) ? StudyInfo.fromJSON(object.Study) : undefined,
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      Sender: isSet(object.Sender)
        ? DoctorInfo.fromJSON(object.Sender)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NotificationMessagePosted): unknown {
    const obj: any = {};
    message.Message !== undefined &&
      (obj.Message = message.Message
        ? Message.toJSON(message.Message)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study ? StudyInfo.toJSON(message.Study) : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.Sender !== undefined &&
      (obj.Sender = message.Sender
        ? DoctorInfo.toJSON(message.Sender)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationMessagePosted>, I>>(
    object: I,
  ): NotificationMessagePosted {
    const message = createBaseNotificationMessagePosted();
    message.Message =
      object.Message !== undefined && object.Message !== null
        ? Message.fromPartial(object.Message)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? StudyInfo.fromPartial(object.Study)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.Sender =
      object.Sender !== undefined && object.Sender !== null
        ? DoctorInfo.fromPartial(object.Sender)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNotificationMessageEdited(): NotificationMessageEdited {
  return { Message: undefined };
}

export const NotificationMessageEdited = {
  encode(
    message: NotificationMessageEdited,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Message !== undefined) {
      Message.encode(message.Message, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationMessageEdited {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationMessageEdited();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Message = Message.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationMessageEdited {
    return {
      Message: isSet(object.Message)
        ? Message.fromJSON(object.Message)
        : undefined,
    };
  },

  toJSON(message: NotificationMessageEdited): unknown {
    const obj: any = {};
    message.Message !== undefined &&
      (obj.Message = message.Message
        ? Message.toJSON(message.Message)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationMessageEdited>, I>>(
    object: I,
  ): NotificationMessageEdited {
    const message = createBaseNotificationMessageEdited();
    message.Message =
      object.Message !== undefined && object.Message !== null
        ? Message.fromPartial(object.Message)
        : undefined;
    return message;
  },
};

function createBaseNotificationMessageDeleted(): NotificationMessageDeleted {
  return { Message: undefined };
}

export const NotificationMessageDeleted = {
  encode(
    message: NotificationMessageDeleted,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Message !== undefined) {
      Message.encode(message.Message, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationMessageDeleted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationMessageDeleted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Message = Message.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationMessageDeleted {
    return {
      Message: isSet(object.Message)
        ? Message.fromJSON(object.Message)
        : undefined,
    };
  },

  toJSON(message: NotificationMessageDeleted): unknown {
    const obj: any = {};
    message.Message !== undefined &&
      (obj.Message = message.Message
        ? Message.toJSON(message.Message)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationMessageDeleted>, I>>(
    object: I,
  ): NotificationMessageDeleted {
    const message = createBaseNotificationMessageDeleted();
    message.Message =
      object.Message !== undefined && object.Message !== null
        ? Message.fromPartial(object.Message)
        : undefined;
    return message;
  },
};

function createBaseNotificationStudyUploaded(): NotificationStudyUploaded {
  return {
    Study: undefined,
    Patient: undefined,
    Project: undefined,
    TreatingDoctors: [],
    Doctor: undefined,
  };
}

export const NotificationStudyUploaded = {
  encode(
    message: NotificationStudyUploaded,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Study !== undefined) {
      StudyInfo.encode(message.Study, writer.uint32(10).fork()).ldelim();
    }
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(18).fork()).ldelim();
    }
    if (message.Project !== undefined) {
      ProjectInfo.encode(message.Project, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.Doctor !== undefined) {
      DoctorInfo.encode(message.Doctor, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationStudyUploaded {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationStudyUploaded();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Study = StudyInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.Project = ProjectInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        case 5:
          message.Doctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationStudyUploaded {
    return {
      Study: isSet(object.Study) ? StudyInfo.fromJSON(object.Study) : undefined,
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      Project: isSet(object.Project)
        ? ProjectInfo.fromJSON(object.Project)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
      Doctor: isSet(object.Doctor)
        ? DoctorInfo.fromJSON(object.Doctor)
        : undefined,
    };
  },

  toJSON(message: NotificationStudyUploaded): unknown {
    const obj: any = {};
    message.Study !== undefined &&
      (obj.Study = message.Study ? StudyInfo.toJSON(message.Study) : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? ProjectInfo.toJSON(message.Project)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    message.Doctor !== undefined &&
      (obj.Doctor = message.Doctor
        ? DoctorInfo.toJSON(message.Doctor)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationStudyUploaded>, I>>(
    object: I,
  ): NotificationStudyUploaded {
    const message = createBaseNotificationStudyUploaded();
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? StudyInfo.fromPartial(object.Study)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? ProjectInfo.fromPartial(object.Project)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    message.Doctor =
      object.Doctor !== undefined && object.Doctor !== null
        ? DoctorInfo.fromPartial(object.Doctor)
        : undefined;
    return message;
  },
};

function createBaseNotificationReportRequested(): NotificationReportRequested {
  return {
    Report: undefined,
    Study: undefined,
    Patient: undefined,
    Project: undefined,
    TreatingDoctors: [],
    Doctor: undefined,
  };
}

export const NotificationReportRequested = {
  encode(
    message: NotificationReportRequested,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      ReportInfo.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    if (message.Study !== undefined) {
      StudyInfo.encode(message.Study, writer.uint32(18).fork()).ldelim();
    }
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(26).fork()).ldelim();
    }
    if (message.Project !== undefined) {
      ProjectInfo.encode(message.Project, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.Doctor !== undefined) {
      DoctorInfo.encode(message.Doctor, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationReportRequested {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationReportRequested();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = ReportInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Study = StudyInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.Project = ProjectInfo.decode(reader, reader.uint32());
          break;
        case 5:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        case 6:
          message.Doctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationReportRequested {
    return {
      Report: isSet(object.Report)
        ? ReportInfo.fromJSON(object.Report)
        : undefined,
      Study: isSet(object.Study) ? StudyInfo.fromJSON(object.Study) : undefined,
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      Project: isSet(object.Project)
        ? ProjectInfo.fromJSON(object.Project)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
      Doctor: isSet(object.Doctor)
        ? DoctorInfo.fromJSON(object.Doctor)
        : undefined,
    };
  },

  toJSON(message: NotificationReportRequested): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report
        ? ReportInfo.toJSON(message.Report)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study ? StudyInfo.toJSON(message.Study) : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? ProjectInfo.toJSON(message.Project)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    message.Doctor !== undefined &&
      (obj.Doctor = message.Doctor
        ? DoctorInfo.toJSON(message.Doctor)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationReportRequested>, I>>(
    object: I,
  ): NotificationReportRequested {
    const message = createBaseNotificationReportRequested();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? ReportInfo.fromPartial(object.Report)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? StudyInfo.fromPartial(object.Study)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? ProjectInfo.fromPartial(object.Project)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    message.Doctor =
      object.Doctor !== undefined && object.Doctor !== null
        ? DoctorInfo.fromPartial(object.Doctor)
        : undefined;
    return message;
  },
};

function createBaseNotificationReportCompleted(): NotificationReportCompleted {
  return {
    Report: undefined,
    Study: undefined,
    Patient: undefined,
    Project: undefined,
    TreatingDoctors: [],
    Doctor: undefined,
    Success: undefined,
    Error: undefined,
  };
}

export const NotificationReportCompleted = {
  encode(
    message: NotificationReportCompleted,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      ReportInfo.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    if (message.Study !== undefined) {
      StudyInfo.encode(message.Study, writer.uint32(18).fork()).ldelim();
    }
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(26).fork()).ldelim();
    }
    if (message.Project !== undefined) {
      ProjectInfo.encode(message.Project, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.Doctor !== undefined) {
      DoctorInfo.encode(message.Doctor, writer.uint32(50).fork()).ldelim();
    }
    if (message.Success !== undefined) {
      NotificationReportCompletedEventSuccess.encode(
        message.Success,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    if (message.Error !== undefined) {
      NotificationReportCompletedEventError.encode(
        message.Error,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationReportCompleted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationReportCompleted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = ReportInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Study = StudyInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.Project = ProjectInfo.decode(reader, reader.uint32());
          break;
        case 5:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        case 6:
          message.Doctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 100:
          message.Success = NotificationReportCompletedEventSuccess.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.Error = NotificationReportCompletedEventError.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationReportCompleted {
    return {
      Report: isSet(object.Report)
        ? ReportInfo.fromJSON(object.Report)
        : undefined,
      Study: isSet(object.Study) ? StudyInfo.fromJSON(object.Study) : undefined,
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      Project: isSet(object.Project)
        ? ProjectInfo.fromJSON(object.Project)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
      Doctor: isSet(object.Doctor)
        ? DoctorInfo.fromJSON(object.Doctor)
        : undefined,
      Success: isSet(object.Success)
        ? NotificationReportCompletedEventSuccess.fromJSON(object.Success)
        : undefined,
      Error: isSet(object.Error)
        ? NotificationReportCompletedEventError.fromJSON(object.Error)
        : undefined,
    };
  },

  toJSON(message: NotificationReportCompleted): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report
        ? ReportInfo.toJSON(message.Report)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study ? StudyInfo.toJSON(message.Study) : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? ProjectInfo.toJSON(message.Project)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    message.Doctor !== undefined &&
      (obj.Doctor = message.Doctor
        ? DoctorInfo.toJSON(message.Doctor)
        : undefined);
    message.Success !== undefined &&
      (obj.Success = message.Success
        ? NotificationReportCompletedEventSuccess.toJSON(message.Success)
        : undefined);
    message.Error !== undefined &&
      (obj.Error = message.Error
        ? NotificationReportCompletedEventError.toJSON(message.Error)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotificationReportCompleted>, I>>(
    object: I,
  ): NotificationReportCompleted {
    const message = createBaseNotificationReportCompleted();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? ReportInfo.fromPartial(object.Report)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? StudyInfo.fromPartial(object.Study)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? ProjectInfo.fromPartial(object.Project)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    message.Doctor =
      object.Doctor !== undefined && object.Doctor !== null
        ? DoctorInfo.fromPartial(object.Doctor)
        : undefined;
    message.Success =
      object.Success !== undefined && object.Success !== null
        ? NotificationReportCompletedEventSuccess.fromPartial(object.Success)
        : undefined;
    message.Error =
      object.Error !== undefined && object.Error !== null
        ? NotificationReportCompletedEventError.fromPartial(object.Error)
        : undefined;
    return message;
  },
};

function createBaseNotificationReportCompletedEventSuccess(): NotificationReportCompletedEventSuccess {
  return {};
}

export const NotificationReportCompletedEventSuccess = {
  encode(
    _: NotificationReportCompletedEventSuccess,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationReportCompletedEventSuccess {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationReportCompletedEventSuccess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): NotificationReportCompletedEventSuccess {
    return {};
  },

  toJSON(_: NotificationReportCompletedEventSuccess): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationReportCompletedEventSuccess>, I>,
  >(_: I): NotificationReportCompletedEventSuccess {
    const message = createBaseNotificationReportCompletedEventSuccess();
    return message;
  },
};

function createBaseNotificationReportCompletedEventError(): NotificationReportCompletedEventError {
  return { Code: '', Message: '' };
}

export const NotificationReportCompletedEventError = {
  encode(
    message: NotificationReportCompletedEventError,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Code !== '') {
      writer.uint32(10).string(message.Code);
    }
    if (message.Message !== '') {
      writer.uint32(18).string(message.Message);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationReportCompletedEventError {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationReportCompletedEventError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Code = reader.string();
          break;
        case 2:
          message.Message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationReportCompletedEventError {
    return {
      Code: isSet(object.Code) ? String(object.Code) : '',
      Message: isSet(object.Message) ? String(object.Message) : '',
    };
  },

  toJSON(message: NotificationReportCompletedEventError): unknown {
    const obj: any = {};
    message.Code !== undefined && (obj.Code = message.Code);
    message.Message !== undefined && (obj.Message = message.Message);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationReportCompletedEventError>, I>,
  >(object: I): NotificationReportCompletedEventError {
    const message = createBaseNotificationReportCompletedEventError();
    message.Code = object.Code ?? '';
    message.Message = object.Message ?? '';
    return message;
  },
};

function createBaseNotificationPatientStatusChanged(): NotificationPatientStatusChanged {
  return {
    Patient: undefined,
    Doctor: undefined,
    TreatingDoctors: [],
    PreviousStatus: '',
  };
}

export const NotificationPatientStatusChanged = {
  encode(
    message: NotificationPatientStatusChanged,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    if (message.Doctor !== undefined) {
      DoctorInfo.encode(message.Doctor, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.PreviousStatus !== '') {
      writer.uint32(34).string(message.PreviousStatus);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPatientStatusChanged {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPatientStatusChanged();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Doctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        case 4:
          message.PreviousStatus = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationPatientStatusChanged {
    return {
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      Doctor: isSet(object.Doctor)
        ? DoctorInfo.fromJSON(object.Doctor)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
      PreviousStatus: isSet(object.PreviousStatus)
        ? String(object.PreviousStatus)
        : '',
    };
  },

  toJSON(message: NotificationPatientStatusChanged): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.Doctor !== undefined &&
      (obj.Doctor = message.Doctor
        ? DoctorInfo.toJSON(message.Doctor)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    message.PreviousStatus !== undefined &&
      (obj.PreviousStatus = message.PreviousStatus);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationPatientStatusChanged>, I>,
  >(object: I): NotificationPatientStatusChanged {
    const message = createBaseNotificationPatientStatusChanged();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.Doctor =
      object.Doctor !== undefined && object.Doctor !== null
        ? DoctorInfo.fromPartial(object.Doctor)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    message.PreviousStatus = object.PreviousStatus ?? '';
    return message;
  },
};

function createBaseNotificationPatientProfileChanged(): NotificationPatientProfileChanged {
  return {
    OriginalPatient: undefined,
    ModifiedPatient: undefined,
    Doctor: undefined,
    TreatingDoctors: [],
  };
}

export const NotificationPatientProfileChanged = {
  encode(
    message: NotificationPatientProfileChanged,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OriginalPatient !== undefined) {
      PatientInfo.encode(
        message.OriginalPatient,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.ModifiedPatient !== undefined) {
      PatientInfo.encode(
        message.ModifiedPatient,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Doctor !== undefined) {
      DoctorInfo.encode(message.Doctor, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPatientProfileChanged {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPatientProfileChanged();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OriginalPatient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.ModifiedPatient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.Doctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationPatientProfileChanged {
    return {
      OriginalPatient: isSet(object.OriginalPatient)
        ? PatientInfo.fromJSON(object.OriginalPatient)
        : undefined,
      ModifiedPatient: isSet(object.ModifiedPatient)
        ? PatientInfo.fromJSON(object.ModifiedPatient)
        : undefined,
      Doctor: isSet(object.Doctor)
        ? DoctorInfo.fromJSON(object.Doctor)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NotificationPatientProfileChanged): unknown {
    const obj: any = {};
    message.OriginalPatient !== undefined &&
      (obj.OriginalPatient = message.OriginalPatient
        ? PatientInfo.toJSON(message.OriginalPatient)
        : undefined);
    message.ModifiedPatient !== undefined &&
      (obj.ModifiedPatient = message.ModifiedPatient
        ? PatientInfo.toJSON(message.ModifiedPatient)
        : undefined);
    message.Doctor !== undefined &&
      (obj.Doctor = message.Doctor
        ? DoctorInfo.toJSON(message.Doctor)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationPatientProfileChanged>, I>,
  >(object: I): NotificationPatientProfileChanged {
    const message = createBaseNotificationPatientProfileChanged();
    message.OriginalPatient =
      object.OriginalPatient !== undefined && object.OriginalPatient !== null
        ? PatientInfo.fromPartial(object.OriginalPatient)
        : undefined;
    message.ModifiedPatient =
      object.ModifiedPatient !== undefined && object.ModifiedPatient !== null
        ? PatientInfo.fromPartial(object.ModifiedPatient)
        : undefined;
    message.Doctor =
      object.Doctor !== undefined && object.Doctor !== null
        ? DoctorInfo.fromPartial(object.Doctor)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNotificationPatientInviteAccepted(): NotificationPatientInviteAccepted {
  return {
    Patient: undefined,
    SenderDoctor: undefined,
    RecipientDoctor: undefined,
    TreatingDoctors: [],
  };
}

export const NotificationPatientInviteAccepted = {
  encode(
    message: NotificationPatientInviteAccepted,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    if (message.SenderDoctor !== undefined) {
      DoctorInfo.encode(
        message.SenderDoctor,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.RecipientDoctor !== undefined) {
      DoctorInfo.encode(
        message.RecipientDoctor,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPatientInviteAccepted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPatientInviteAccepted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.SenderDoctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.RecipientDoctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationPatientInviteAccepted {
    return {
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      SenderDoctor: isSet(object.SenderDoctor)
        ? DoctorInfo.fromJSON(object.SenderDoctor)
        : undefined,
      RecipientDoctor: isSet(object.RecipientDoctor)
        ? DoctorInfo.fromJSON(object.RecipientDoctor)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NotificationPatientInviteAccepted): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.SenderDoctor !== undefined &&
      (obj.SenderDoctor = message.SenderDoctor
        ? DoctorInfo.toJSON(message.SenderDoctor)
        : undefined);
    message.RecipientDoctor !== undefined &&
      (obj.RecipientDoctor = message.RecipientDoctor
        ? DoctorInfo.toJSON(message.RecipientDoctor)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationPatientInviteAccepted>, I>,
  >(object: I): NotificationPatientInviteAccepted {
    const message = createBaseNotificationPatientInviteAccepted();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.SenderDoctor =
      object.SenderDoctor !== undefined && object.SenderDoctor !== null
        ? DoctorInfo.fromPartial(object.SenderDoctor)
        : undefined;
    message.RecipientDoctor =
      object.RecipientDoctor !== undefined && object.RecipientDoctor !== null
        ? DoctorInfo.fromPartial(object.RecipientDoctor)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNotificationPatientTreatingDoctorAdded(): NotificationPatientTreatingDoctorAdded {
  return {
    Patient: undefined,
    Doctor: undefined,
    TreatingDoctors: [],
    AddedDoctor: undefined,
  };
}

export const NotificationPatientTreatingDoctorAdded = {
  encode(
    message: NotificationPatientTreatingDoctorAdded,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    if (message.Doctor !== undefined) {
      DoctorInfo.encode(message.Doctor, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.AddedDoctor !== undefined) {
      DoctorInfo.encode(message.AddedDoctor, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPatientTreatingDoctorAdded {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPatientTreatingDoctorAdded();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Doctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        case 4:
          message.AddedDoctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationPatientTreatingDoctorAdded {
    return {
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      Doctor: isSet(object.Doctor)
        ? DoctorInfo.fromJSON(object.Doctor)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
      AddedDoctor: isSet(object.AddedDoctor)
        ? DoctorInfo.fromJSON(object.AddedDoctor)
        : undefined,
    };
  },

  toJSON(message: NotificationPatientTreatingDoctorAdded): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.Doctor !== undefined &&
      (obj.Doctor = message.Doctor
        ? DoctorInfo.toJSON(message.Doctor)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    message.AddedDoctor !== undefined &&
      (obj.AddedDoctor = message.AddedDoctor
        ? DoctorInfo.toJSON(message.AddedDoctor)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationPatientTreatingDoctorAdded>, I>,
  >(object: I): NotificationPatientTreatingDoctorAdded {
    const message = createBaseNotificationPatientTreatingDoctorAdded();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.Doctor =
      object.Doctor !== undefined && object.Doctor !== null
        ? DoctorInfo.fromPartial(object.Doctor)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    message.AddedDoctor =
      object.AddedDoctor !== undefined && object.AddedDoctor !== null
        ? DoctorInfo.fromPartial(object.AddedDoctor)
        : undefined;
    return message;
  },
};

function createBaseNotificationPatientTreatingDoctorRemoved(): NotificationPatientTreatingDoctorRemoved {
  return {
    Patient: undefined,
    Doctor: undefined,
    TreatingDoctors: [],
    RemovedDoctor: undefined,
  };
}

export const NotificationPatientTreatingDoctorRemoved = {
  encode(
    message: NotificationPatientTreatingDoctorRemoved,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    if (message.Doctor !== undefined) {
      DoctorInfo.encode(message.Doctor, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.RemovedDoctor !== undefined) {
      DoctorInfo.encode(
        message.RemovedDoctor,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPatientTreatingDoctorRemoved {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPatientTreatingDoctorRemoved();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Doctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        case 4:
          message.RemovedDoctor = DoctorInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationPatientTreatingDoctorRemoved {
    return {
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      Doctor: isSet(object.Doctor)
        ? DoctorInfo.fromJSON(object.Doctor)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
      RemovedDoctor: isSet(object.RemovedDoctor)
        ? DoctorInfo.fromJSON(object.RemovedDoctor)
        : undefined,
    };
  },

  toJSON(message: NotificationPatientTreatingDoctorRemoved): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    message.Doctor !== undefined &&
      (obj.Doctor = message.Doctor
        ? DoctorInfo.toJSON(message.Doctor)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    message.RemovedDoctor !== undefined &&
      (obj.RemovedDoctor = message.RemovedDoctor
        ? DoctorInfo.toJSON(message.RemovedDoctor)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationPatientTreatingDoctorRemoved>, I>,
  >(object: I): NotificationPatientTreatingDoctorRemoved {
    const message = createBaseNotificationPatientTreatingDoctorRemoved();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.Doctor =
      object.Doctor !== undefined && object.Doctor !== null
        ? DoctorInfo.fromPartial(object.Doctor)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    message.RemovedDoctor =
      object.RemovedDoctor !== undefined && object.RemovedDoctor !== null
        ? DoctorInfo.fromPartial(object.RemovedDoctor)
        : undefined;
    return message;
  },
};

function createBaseNotificationProjectTaskAddAssignee(): NotificationProjectTaskAddAssignee {
  return {
    Task: undefined,
    Assignment: undefined,
    Patient: undefined,
    TreatingDoctors: [],
  };
}

export const NotificationProjectTaskAddAssignee = {
  encode(
    message: NotificationProjectTaskAddAssignee,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      ProjectTaskInfo.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    if (message.Assignment !== undefined) {
      ProjectTaskAssignment.encode(
        message.Assignment,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Patient !== undefined) {
      PatientInfo.encode(message.Patient, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.TreatingDoctors) {
      DoctorInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationProjectTaskAddAssignee {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationProjectTaskAddAssignee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = ProjectTaskInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Assignment = ProjectTaskAssignment.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.Patient = PatientInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.TreatingDoctors.push(
            DoctorInfo.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotificationProjectTaskAddAssignee {
    return {
      Task: isSet(object.Task)
        ? ProjectTaskInfo.fromJSON(object.Task)
        : undefined,
      Assignment: isSet(object.Assignment)
        ? ProjectTaskAssignment.fromJSON(object.Assignment)
        : undefined,
      Patient: isSet(object.Patient)
        ? PatientInfo.fromJSON(object.Patient)
        : undefined,
      TreatingDoctors: Array.isArray(object?.TreatingDoctors)
        ? object.TreatingDoctors.map((e: any) => DoctorInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NotificationProjectTaskAddAssignee): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task
        ? ProjectTaskInfo.toJSON(message.Task)
        : undefined);
    message.Assignment !== undefined &&
      (obj.Assignment = message.Assignment
        ? ProjectTaskAssignment.toJSON(message.Assignment)
        : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientInfo.toJSON(message.Patient)
        : undefined);
    if (message.TreatingDoctors) {
      obj.TreatingDoctors = message.TreatingDoctors.map((e) =>
        e ? DoctorInfo.toJSON(e) : undefined,
      );
    } else {
      obj.TreatingDoctors = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<NotificationProjectTaskAddAssignee>, I>,
  >(object: I): NotificationProjectTaskAddAssignee {
    const message = createBaseNotificationProjectTaskAddAssignee();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? ProjectTaskInfo.fromPartial(object.Task)
        : undefined;
    message.Assignment =
      object.Assignment !== undefined && object.Assignment !== null
        ? ProjectTaskAssignment.fromPartial(object.Assignment)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientInfo.fromPartial(object.Patient)
        : undefined;
    message.TreatingDoctors =
      object.TreatingDoctors?.map((e) => DoctorInfo.fromPartial(e)) || [];
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

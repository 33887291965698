import { CBCTAnatomy } from '@/shared/api/protocol_gen/model/dto_common_anatomy';
import { JAW } from '@/shared/config';

import { RUDIMENT_TEETH_MAP } from '@/entities/tooth';

const getTeethISONumbersWithRudimentTeeth = (teethISONumbers: number[]) => {
  const teethISONumbersWithRudimentTeeth = [...teethISONumbers];

  Object.entries(RUDIMENT_TEETH_MAP).forEach(([rudimentTooth, tooth]) => {
    if (teethISONumbers.includes(Number(tooth))) {
      teethISONumbersWithRudimentTeeth.push(Number(rudimentTooth));
    }
  });

  return teethISONumbersWithRudimentTeeth;
};

const getSegmentsOfTeeth = (ISONumbers: number[]) =>
  ISONumbers.map((ISONumber) => ({
    CBCTToothISO: ISONumber,
  }));

const getSegmentsOfAllCBCTAnatomies = (): { CBCTAnatomy: number }[] =>
  Object.entries(CBCTAnatomy)
    .filter(
      ([, value]) =>
        !isNaN(Number(value)) &&
        value !== CBCTAnatomy.CBCT_Anatomy_InvalidValue &&
        value !== CBCTAnatomy.UNRECOGNIZED,
    )
    .map(([, value]) => ({
      CBCTAnatomy: Number(value),
    }));

export const getSegmentsWithTeethAndAnatomies = () => [
  ...getSegmentsOfTeeth(
    getTeethISONumbersWithRudimentTeeth([
      ...JAW.quartile1,
      ...JAW.quartile2,
      ...JAW.quartile3,
      ...JAW.quartile4,
    ]),
  ),
  ...getSegmentsOfAllCBCTAnatomies(),
];

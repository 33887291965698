import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import logoImg from '@/shared/assets/logo.svg';
import { PATHS } from '@/shared/config';

import styles from './Logo.module.scss';

type LogoProps = {
  className?: string;
};

export const Logo: FC<LogoProps> = (props) => {
  const { className } = props;

  const { formatMessage } = useIntl();

  return (
    <Link className={cn(styles.container, className)} to={PATHS.main}>
      <img
        src={logoImg}
        alt={formatMessage({
          id: 'header.logo',
          defaultMessage: 'Diagnocat',
        })}
      />
    </Link>
  );
};

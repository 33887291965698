// TODO [8/l] Fix types
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { equals } from 'ramda';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { InlineFormError, Input, Modal, Select } from '@/shared/ui';
import { UserBadRequestError } from '@/shared/config';
import {
  OrganizationUserRole,
  OrganizationUserRoleType,
} from '@/shared/api/protocol_gen/model/dto_organization';
import { InvitationTarget_OrganizationMembership } from '@/shared/api/protocol_gen/model/dto_access';
import { ApiError } from '@/shared/api/api';
import { InviteReq } from '@/shared/api/protocol_gen/api/core/svc_access';

import { ModalID, modalModel } from '@/entities/modal';
import {
  i18n,
  InvitedDoctorInterface,
  organizationModel,
  useGetOrganizationDoctorsInterfaces,
  useSpecializationsOptions,
} from '@/entities/organization';
import { accessModel } from '@/entities/access';

import { usePermissionOptions } from '../hooks/usePermissionOptions';
import { getValidationSchema, StaffFormPayload } from '../config/formSchema';

import {
  StaffModalMultiValueRemove,
  StaffModalSelectMultiValueLabel,
  StaffModalSelectOption,
} from './AddStafModalSelect.components';
import { addStaffModalSelectStyles } from './AddStafModalSelect.styles';
import styles from './AddStaffModal.module.scss';

type InvitedDoctor = InvitationTarget_OrganizationMembership & {
  email: string;
};

export type AddStaffModalProps = {
  className?: string;
};

export const AddStaffModal: FC<AddStaffModalProps> = () => {
  const dispatch = useAppDispatch();
  const {
    visible,
    data: { actionType, editingUserID, doctorInfo },
  } = useAppSelector((state) => state.modal.AddStaffModal);

  const companyDoctors = useGetOrganizationDoctorsInterfaces();

  const { formatMessage } = useIntl();

  const specializationOptions = useSpecializationsOptions();
  const permissionOptions = usePermissionOptions();

  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.AddStaffModal));
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { isSubmitting, isSubmitted, errors },
  } = useForm<StaffFormPayload>({
    resolver: yupResolver(getValidationSchema(actionType === 'Add')),
    mode: 'all',
  });

  const onSubmitEditStaff: SubmitHandler<StaffFormPayload> = async ({
    firstName,
    lastName,
    accessLevel,
    position,
  }: StaffFormPayload) => {
    try {
      await dispatch(
        organizationModel.thunks.setOrganizationUserName({
          UserID: editingUserID,
          LastName: lastName,
          OrganizationID: organizationID,
          FirstName: firstName,
        }),
      ).unwrap();

      await dispatch(
        accessModel.thunks.setUserOrganizationRoles({
          UserID: editingUserID,
          OrganizationID: organizationID,
          Roles: accessLevel,
        }),
      ).unwrap();

      const { Organization: updatedOrganization } = await dispatch(
        organizationModel.thunks.setOrganizationUserJobPositions({
          UserID: editingUserID,
          OrganizationID: organizationID,
          JobPositions: position,
        }),
      ).unwrap();

      if (updatedOrganization) {
        dispatch(organizationModel.actions.setOne(updatedOrganization));
      }
    } finally {
      handleClose();
    }
  };

  const getDefaultValues = (doctor: InvitedDoctor | OrganizationUserRole) => {
    const doctorIsAdded = 'PersonalData' in doctor;

    return {
      firstName: doctorIsAdded
        ? doctor.PersonalData?.FirstName
        : doctor.FirstName,
      lastName: doctorIsAdded ? doctor.PersonalData?.LastName : doctor.LastName,
      accessLevel: doctor.Roles,
      position: doctor.JobPositions,
      email: doctorIsAdded ? doctor.PersonalData?.Email : doctor.email,
    };
  };

  const onSubmitEditInvitationStaff: SubmitHandler<StaffFormPayload> = async (
    data,
  ) => {
    const { lastName, firstName, position, accessLevel } = data;

    const invitationID =
      doctorInfo && 'InvitationID' in doctorInfo ? doctorInfo.InvitationID : '';

    try {
      const { Invitation } = await dispatch(
        accessModel.thunks.setOrganizationInvitationUserInfo({
          InvitationID: invitationID,
          Membership: {
            OrganizationID: organizationID,
            FirstName: firstName,
            LastName: lastName,
            Roles: accessLevel,
            JobPositions: position,
          },
        }),
      ).unwrap();

      if (Invitation) {
        dispatch(accessModel.actions.setOne(Invitation));
      }
    } finally {
      handleClose();
    }
  };

  const handleExistingEmail = (value: string) => {
    const alreadyExistingDoctor = companyDoctors.find(
      (doctor) =>
        (doctor.doctorInfo as OrganizationUserRole).PersonalData?.Email ===
          value ||
        (doctor.doctorInfo as InvitedDoctorInterface)?.email === value,
    );

    if (alreadyExistingDoctor) {
      setError('email', {
        type: 'custom',
        message: formatMessage({
          id: 'addStaffModal.emailError',
          defaultMessage: 'Invitation to this organization role already exist.',
        }),
      });
    }
  };

  const onSubmitAddStaff: SubmitHandler<StaffFormPayload> = async (data) => {
    const { email, lastName, firstName, position, accessLevel } = data;

    try {
      await dispatch(
        accessModel.thunks.inviteForSharingPatient({
          Recipient: { Email: email },
          Target: {
            Organization: {
              OrganizationID: organizationID,
              FirstName: firstName,
              LastName: lastName,
              JobPositions: position,
              Roles: accessLevel,
            },
          },
        } as InviteReq),
      ).unwrap();

      handleClose();
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);

      if (
        parsedMessage?.code ===
        UserBadRequestError.INVITATION_RECIPIENT_ALREADY_HAS_ORGANIZATION_ROLE
      ) {
        setError('email', {
          type: UserBadRequestError.INVITATION_RECIPIENT_ALREADY_HAS_ORGANIZATION_ROLE,
          message: formatMessage({
            id: 'addStaffModal.alreadyInviteError',
            defaultMessage:
              'Team member with provided email has already been invited.',
          }),
        });
      }
    }
  };

  const submitFunction = (data: StaffFormPayload) => {
    if (actionType === 'Add') {
      return onSubmitAddStaff(data);
    }
    if (doctorInfo && 'PersonalData' in doctorInfo) {
      return onSubmitEditStaff(data);
    }
    return onSubmitEditInvitationStaff(data);
  };

  // Set default
  useEffect(() => {
    if (actionType === 'Edit' && doctorInfo) {
      reset(getDefaultValues(doctorInfo));
    }

    return () => {
      reset({
        firstName: '',
        lastName: '',
        email: '',
        accessLevel: [],
        position: [],
      });
    };
  }, [reset, doctorInfo, visible]);

  const isAlreadyInvitedEmailError =
    errors?.email &&
    equals(
      errors.email.type,
      UserBadRequestError.INVITATION_RECIPIENT_ALREADY_HAS_ORGANIZATION_ROLE,
    );

  return (
    <Modal
      title={
        actionType === 'Add'
          ? formatMessage({
              id: 'addStaffModal.title',
              defaultMessage: 'Add Staff',
            })
          : formatMessage({
              id: 'addStaffModal.editTitle',
              defaultMessage: 'Edit Staff',
            })
      }
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      borderless
      okButtonText={formatMessage({
        id: 'global.save',
        defaultMessage: 'Save',
      })}
      okButtonProps={{
        type: 'submit',
        form: 'addStaff',
        loading: isSubmitting,
      }}
      cancelButtonText={formatMessage({
        id: 'global.cancel',
        defaultMessage: 'Cancel',
      })}
    >
      <form
        id="addStaff"
        onSubmit={handleSubmit(submitFunction)}
        className={styles.form}
      >
        <div className={styles.row}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                ref={ref}
                value={value}
                name={name}
                onBlur={() => {
                  if (isSubmitted) {
                    onBlur();
                  }
                }}
                onChange={(event) => {
                  if (!isSubmitted) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                type="text"
                label={formatMessage({
                  id: 'addStaff.firstName',
                  defaultMessage: 'First name',
                })}
                error={error?.message}
                autoComplete="given-name"
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                ref={ref}
                value={value}
                name={name}
                onBlur={() => {
                  if (isSubmitted) {
                    onBlur();
                  }
                }}
                onChange={(event) => {
                  if (!isSubmitted) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                type="text"
                label={formatMessage({
                  id: 'addStaff.lastName',
                  defaultMessage: 'Last name',
                })}
                error={error?.message}
                autoComplete="family-name"
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="email"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error, isTouched },
          }) => (
            <Input
              required
              ref={ref}
              disabled={actionType === 'Edit'}
              value={value}
              name={name}
              onBlur={() => {
                onBlur();
                handleExistingEmail(value);
              }}
              onChange={(event) => {
                if (!isTouched) {
                  setValue(name, event.target.value);
                } else {
                  onChange(event);
                }
              }}
              label={formatMessage({
                id: 'addStaff.email',
                defaultMessage: 'Email',
              })}
              error={isAlreadyInvitedEmailError ? ' ' : error?.message}
              autoComplete="email"
            />
          )}
        />

        <Controller
          control={control}
          name="accessLevel"
          render={({
            field: { ref, value, name, onBlur },
            fieldState: { error },
          }) => {
            const isValueArray = Array.isArray(value);

            const isOwnerRole =
              isValueArray &&
              value[0] ===
                OrganizationUserRoleType.OrganizationUserRoleTypeOwner;

            return (
              <Select
                label={formatMessage({
                  id: 'addStaff.accessLevel',
                  defaultMessage: 'Access level',
                })}
                options={
                  isOwnerRole
                    ? [
                        {
                          label: formatMessage(
                            i18n.permissionOwners[
                              OrganizationUserRoleType
                                .OrganizationUserRoleTypeOwner
                            ],
                          ),
                          value:
                            OrganizationUserRoleType.OrganizationUserRoleTypeOwner,
                          comment: formatMessage(
                            i18n.permissionOwnersComments[
                              OrganizationUserRoleType
                                .OrganizationUserRoleTypeOwner
                            ],
                          ),
                        },
                      ]
                    : permissionOptions
                }
                isRequired
                ref={ref}
                value={isValueArray ? value[0] : undefined}
                components={{
                  Option: StaffModalSelectOption,
                }}
                name={name}
                onBlur={onBlur}
                onChange={(newValue) => {
                  setValue(name, [newValue]);
                }}
                error={error?.message}
                isDisabled={isOwnerRole}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="position"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Select
              label={formatMessage({
                id: 'addStaff.jobPosition',
                defaultMessage: 'Job position',
              })}
              options={specializationOptions}
              isRequired
              isMulti
              styles={addStaffModalSelectStyles}
              components={{
                MultiValueLabel: StaffModalSelectMultiValueLabel,
                MultiValueRemove: StaffModalMultiValueRemove,
              }}
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        {isAlreadyInvitedEmailError && (
          <InlineFormError
            className={styles.inlineFormError}
            errorMessage={errors.email.message}
          />
        )}
      </form>
    </Modal>
  );
};

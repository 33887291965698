import { useEffect, useState } from 'react';

export const useHeaderDivider = () => {
  const [isDividerLineVisible, setIsDividerLineVisible] = useState(false);

  const borderVisibleHandle = () => {
    setIsDividerLineVisible(Boolean(window.scrollY));
  };

  useEffect(() => {
    document.addEventListener('scroll', borderVisibleHandle, {
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', borderVisibleHandle);
    };
  }, []);

  return { isDividerLineVisible };
};

import { createSelector } from '@reduxjs/toolkit';
import { filter, propEq } from 'ramda';

import { Study, StudyType } from '@/shared/api/protocol_gen/model/dto_study';

import { RootState } from '@/app/model/store';

import { studyAdapter } from './studySlice';

export const studySelectors = studyAdapter.getSelectors(
  (state: RootState) => state.study,
);

export const { selectIds, selectEntities, selectTotal, selectById, selectAll } =
  studySelectors;

export const selectByType = (studyType: StudyType) =>
  createSelector(studySelectors.selectAll, (studies) =>
    filter(propEq<keyof Study>('Type', studyType))(studies),
  );

export const selectByID = (studyID: string) => (state: RootState) =>
  selectById(state, studyID);

export const selectByPatientID = (patientID: string) =>
  createSelector(studySelectors.selectAll, (studies) =>
    filter(propEq<keyof Study>('PatientID', patientID))(studies),
  );

export const selectStudyStatus = (studyID: string) =>
  createSelector(
    studySelectors.selectAll,
    (studies) => studies.find((study) => study.ID === studyID)?.Status,
  );

export const selectNotDeletedStudiesByPatientID = (patientID: string) =>
  createSelector(selectByPatientID(patientID), (studies) =>
    studies.filter((study) => !study.Deleted?.Deleted),
  );

// @ts-nocheck
import { useState } from "react";
import { ObjectFitContain } from "../ObjectFitContain";
import { RCContainer } from "../RCContainer/RCContainer";

export type Guide = { x1: number; y1: number; x2: number; y2: number, assetID: string };

export type RCGuidesProps = {
    imageSize: { width: number, height: number };
    guides: Guide[];
    hoveredGuide: string;
    onHoveredGuideChanged?: (index: string) => void;
    onGuideClick?: (index: string) => void;
}

// TODO: remove old component after refactoring
export const RCGuides = (props: RCGuidesProps) => {

    const { imageSize, guides, hoveredGuide, onHoveredGuideChanged, onGuideClick } = props;
    const { width, height } = imageSize;


    //canvas outside scales down to fit. thats why detection misses the canvas
    const [aspect, setAspect] = useState(1);

    return (
        <ObjectFitContain
            objectWidth={imageSize.width}
            objectHeight={imageSize.height}
            onAspectChanged={(aspect) => { setAspect(aspect) }}
        >
            <RCContainer style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                <svg
                    // css size
                    width="100%"
                    height="100%"
                    // logical size
                    viewBox={`0 0 ${width} ${height}`}
                    style={{ display: 'block' }}
                >
                    {guides.map((guide, index) =>
                        guide.assetID === hoveredGuide ? (
                            <HoveredLine
                                key={index}
                                guide={guide}
                                onMouseLeave={() => onHoveredGuideChanged('')}
                                onClick={() => { onGuideClick?.(hoveredGuide) }}
                            />
                        ) : (
                            <UsualLine
                                key={index}
                                guide={guide}
                                onMouseEnter={() => onHoveredGuideChanged(guide.assetID)}
                            />
                        ),
                    )}
                </svg>
            </RCContainer>
        </ObjectFitContain>
    );
};

const UsualLine = ({
    guide,
    onMouseEnter,
}: {
    guide: Guide;
    onMouseEnter: () => void;
}) => (
    <>
        {/* 
	A little wider transparent line to make user mouse pointing easier.
	Don't make stroke-wdith higher than HoveredLine in case of preventing exit that instantly entering the same line and stuck
	*/}
        <line
            {...guide}
            stroke="red"
            strokeWidth="3"
            strokeOpacity="0"
            onMouseEnter={onMouseEnter}
        />
        <line {...guide} stroke="lime" strokeWidth="1" strokeOpacity="0.4" />
    </>
);

const HoveredLine = ({
    guide,
    onMouseLeave,
    onClick,
}: {
    guide: Guide;
    onMouseLeave: () => void;
    onClick: () => void
}) => (
    <line
        {...guide}
        stroke="white"
        strokeWidth="1"
        strokeOpacity="1"
        onMouseLeave={onMouseLeave}
        onClick={onClick}
    />
);

import { BBox } from '@/shared/api/protocol_gen/model/dto_common_geometry';

const defaultBBox: BBox = {
  X: {
    Min: 0,
    Max: 0,
    Size: 0,
  },
  Y: {
    Min: 0,
    Max: 0,
    Size: 0,
  },
  Z: {
    Min: 0,
    Max: 0,
    Size: 0,
  },
};

export const transformBBoxToCrop = (bBox: BBox = defaultBBox) => {
  const xMin = bBox.X?.Min ?? 0;
  const yMin = bBox.Y?.Min ?? 0;
  const xMax = bBox.X?.Max ?? 0;
  const yMax = bBox.Y?.Max ?? 0;

  return {
    x: xMin,
    y: yMin,
    w: xMax - xMin,
    h: yMax - yMin,
  };
};

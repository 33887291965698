// @ts-nocheck

import { useEffect, useState } from 'react';

const PHONE_WIDTH = 420;
const TABLET_WIDTH = 768;
const DESKTOP_WIDTH = 1024;
const LARGE_WIDTH = 1280;
const WIDE_WIDTH = 1400;

export interface MediaQueries {
  isPhone: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isSmallDesktop: boolean;
  isDesktop: boolean;
  isLarge: boolean;
  isWide: boolean;
}

const queries = {
  isPhone: `screen and (max-width: ${PHONE_WIDTH}px)`,
  isTablet: `screen and (min-width: ${PHONE_WIDTH}px) and (max-width: ${
    TABLET_WIDTH - 1
  }px)`,
  isMobile: `screen and (max-width: ${TABLET_WIDTH}px)`,
  isSmallDesktop: `screen and (min-width: ${TABLET_WIDTH}px) and (max-width: ${DESKTOP_WIDTH}px)`,
  isDesktop: `screen and (min-width: ${DESKTOP_WIDTH}px) and (max-width: ${LARGE_WIDTH}px)`,
  isLarge: `(min-width: ${LARGE_WIDTH}px)`,
  isWide: `(min-width: ${WIDE_WIDTH}px)`,
};

const initialState: MediaQueries = {
  isPhone: false,
  isTablet: false,
  isMobile: false,
  isSmallDesktop: false,
  isDesktop: true,
  isLarge: false,
  isWide: false,
};

export const useMedia = (): MediaQueries => {
  const [queryMatch, setQueryMatch] = useState(null);

  useEffect(() => {
    const mediaQueryLists = {};
    const keys = Object.keys(queries);

    // To check whether event listener is attached or not
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      // Setting state to the updated matches
      // when document either starts or stops matching a query
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      // Setting state to initial matching queries
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      // If event listener is attached then remove it when deps change
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return queryMatch || initialState;
};

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { getReportGenerationInterval } from '@/features/reportRow';

export const getRelativeProgress = (
  reportType: ReportType,
  createdAt?: Date,
): number => {
  if (!createdAt) {
    return 0;
  }

  const reportCreatedDiffSeconds =
    new Date().getTime() - new Date(createdAt).getTime();

  const reportAverageCreationTime =
    getReportGenerationInterval(reportType) * 1000;

  const relativeProgress = Math.floor(
    (reportCreatedDiffSeconds / reportAverageCreationTime) * 100,
  );

  return relativeProgress;
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { ClosedPolygon, BBox } from '../model/dto_common_geometry';
import { MedicalImageFeatures } from '../model/dto_common_image_medical_features';

export const protobufPackage = 'com.diagnocat.model';

export enum LocalizationType {
  LocalizationTypeInvalidValue = 0,
  LocalizationType2D = 1,
  LocalizationType3D = 2,
  UNRECOGNIZED = -1,
}

export function localizationTypeFromJSON(object: any): LocalizationType {
  switch (object) {
    case 0:
    case 'LocalizationTypeInvalidValue':
      return LocalizationType.LocalizationTypeInvalidValue;
    case 1:
    case 'LocalizationType2D':
      return LocalizationType.LocalizationType2D;
    case 2:
    case 'LocalizationType3D':
      return LocalizationType.LocalizationType3D;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LocalizationType.UNRECOGNIZED;
  }
}

export function localizationTypeToJSON(object: LocalizationType): string {
  switch (object) {
    case LocalizationType.LocalizationTypeInvalidValue:
      return 'LocalizationTypeInvalidValue';
    case LocalizationType.LocalizationType2D:
      return 'LocalizationType2D';
    case LocalizationType.LocalizationType3D:
      return 'LocalizationType3D';
    case LocalizationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * //////////////////////////////////////////////////////////////
 * //  Localization
 * //
 * //  Localization of something on an image;
 * //
 */
export interface Localization {
  ID: string;
  /** `TargetAssetID`: [required] */
  TargetAssetID: string;
  /** `Type`: [required] */
  Type: LocalizationType;
  /** @deprecated */
  Mask: ClosedPolygon | undefined;
  /**
   * `PolygonalMask`: [optional]: Vector mask with one or multiple
   * unconnected components.
   */
  PolygonalMask: ClosedPolygon[];
  /**
   * `BBox`: [optional]
   * NOTE: Mutually exclusive (oneof) with `LocalizationPBLLandmarks`, explicit oneof
   * was not used for backward compatibility reasons.
   */
  BBox: BBox | undefined;
  /**
   * `PBLLandmarks`: [optional]: PBL condition localization.
   * NOTE: Mutually exclusive (oneof) with `BBox`, explicit oneof
   * was not used for backward compatibility reasons.
   */
  PeriodontalBoneLossLandmarks: LocalizationPBLLandmarks | undefined;
  /**
   * `CropMedicalImageFeatures`: [optional]
   *
   * @deprecated
   */
  CropMedicalImageFeatures: MedicalImageFeatures | undefined;
}

export interface LocalizationPBLLandmarks {
  /** >> ToothLandmark.ID */
  CementoenamelJunctionLandmarkID: string;
  /** >> ToothLandmark.ID */
  BoneLevelLandmarkID: string;
}

function createBaseLocalization(): Localization {
  return {
    ID: '',
    TargetAssetID: '',
    Type: 0,
    Mask: undefined,
    PolygonalMask: [],
    BBox: undefined,
    PeriodontalBoneLossLandmarks: undefined,
    CropMedicalImageFeatures: undefined,
  };
}

export const Localization = {
  encode(
    message: Localization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.TargetAssetID !== '') {
      writer.uint32(18).string(message.TargetAssetID);
    }
    if (message.Type !== 0) {
      writer.uint32(88).int32(message.Type);
    }
    if (message.Mask !== undefined) {
      ClosedPolygon.encode(message.Mask, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.PolygonalMask) {
      ClosedPolygon.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.BBox !== undefined) {
      BBox.encode(message.BBox, writer.uint32(114).fork()).ldelim();
    }
    if (message.PeriodontalBoneLossLandmarks !== undefined) {
      LocalizationPBLLandmarks.encode(
        message.PeriodontalBoneLossLandmarks,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    if (message.CropMedicalImageFeatures !== undefined) {
      MedicalImageFeatures.encode(
        message.CropMedicalImageFeatures,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Localization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocalization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.TargetAssetID = reader.string();
          break;
        case 11:
          message.Type = reader.int32() as any;
          break;
        case 12:
          message.Mask = ClosedPolygon.decode(reader, reader.uint32());
          break;
        case 13:
          message.PolygonalMask.push(
            ClosedPolygon.decode(reader, reader.uint32()),
          );
          break;
        case 14:
          message.BBox = BBox.decode(reader, reader.uint32());
          break;
        case 15:
          message.PeriodontalBoneLossLandmarks =
            LocalizationPBLLandmarks.decode(reader, reader.uint32());
          break;
        case 21:
          message.CropMedicalImageFeatures = MedicalImageFeatures.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Localization {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      TargetAssetID: isSet(object.TargetAssetID)
        ? String(object.TargetAssetID)
        : '',
      Type: isSet(object.Type) ? localizationTypeFromJSON(object.Type) : 0,
      Mask: isSet(object.Mask)
        ? ClosedPolygon.fromJSON(object.Mask)
        : undefined,
      PolygonalMask: Array.isArray(object?.PolygonalMask)
        ? object.PolygonalMask.map((e: any) => ClosedPolygon.fromJSON(e))
        : [],
      BBox: isSet(object.BBox) ? BBox.fromJSON(object.BBox) : undefined,
      PeriodontalBoneLossLandmarks: isSet(object.PeriodontalBoneLossLandmarks)
        ? LocalizationPBLLandmarks.fromJSON(object.PeriodontalBoneLossLandmarks)
        : undefined,
      CropMedicalImageFeatures: isSet(object.CropMedicalImageFeatures)
        ? MedicalImageFeatures.fromJSON(object.CropMedicalImageFeatures)
        : undefined,
    };
  },

  toJSON(message: Localization): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.TargetAssetID !== undefined &&
      (obj.TargetAssetID = message.TargetAssetID);
    message.Type !== undefined &&
      (obj.Type = localizationTypeToJSON(message.Type));
    message.Mask !== undefined &&
      (obj.Mask = message.Mask
        ? ClosedPolygon.toJSON(message.Mask)
        : undefined);
    if (message.PolygonalMask) {
      obj.PolygonalMask = message.PolygonalMask.map((e) =>
        e ? ClosedPolygon.toJSON(e) : undefined,
      );
    } else {
      obj.PolygonalMask = [];
    }
    message.BBox !== undefined &&
      (obj.BBox = message.BBox ? BBox.toJSON(message.BBox) : undefined);
    message.PeriodontalBoneLossLandmarks !== undefined &&
      (obj.PeriodontalBoneLossLandmarks = message.PeriodontalBoneLossLandmarks
        ? LocalizationPBLLandmarks.toJSON(message.PeriodontalBoneLossLandmarks)
        : undefined);
    message.CropMedicalImageFeatures !== undefined &&
      (obj.CropMedicalImageFeatures = message.CropMedicalImageFeatures
        ? MedicalImageFeatures.toJSON(message.CropMedicalImageFeatures)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Localization>, I>>(
    object: I,
  ): Localization {
    const message = createBaseLocalization();
    message.ID = object.ID ?? '';
    message.TargetAssetID = object.TargetAssetID ?? '';
    message.Type = object.Type ?? 0;
    message.Mask =
      object.Mask !== undefined && object.Mask !== null
        ? ClosedPolygon.fromPartial(object.Mask)
        : undefined;
    message.PolygonalMask =
      object.PolygonalMask?.map((e) => ClosedPolygon.fromPartial(e)) || [];
    message.BBox =
      object.BBox !== undefined && object.BBox !== null
        ? BBox.fromPartial(object.BBox)
        : undefined;
    message.PeriodontalBoneLossLandmarks =
      object.PeriodontalBoneLossLandmarks !== undefined &&
      object.PeriodontalBoneLossLandmarks !== null
        ? LocalizationPBLLandmarks.fromPartial(
            object.PeriodontalBoneLossLandmarks,
          )
        : undefined;
    message.CropMedicalImageFeatures =
      object.CropMedicalImageFeatures !== undefined &&
      object.CropMedicalImageFeatures !== null
        ? MedicalImageFeatures.fromPartial(object.CropMedicalImageFeatures)
        : undefined;
    return message;
  },
};

function createBaseLocalizationPBLLandmarks(): LocalizationPBLLandmarks {
  return { CementoenamelJunctionLandmarkID: '', BoneLevelLandmarkID: '' };
}

export const LocalizationPBLLandmarks = {
  encode(
    message: LocalizationPBLLandmarks,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CementoenamelJunctionLandmarkID !== '') {
      writer.uint32(10).string(message.CementoenamelJunctionLandmarkID);
    }
    if (message.BoneLevelLandmarkID !== '') {
      writer.uint32(18).string(message.BoneLevelLandmarkID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): LocalizationPBLLandmarks {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocalizationPBLLandmarks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CementoenamelJunctionLandmarkID = reader.string();
          break;
        case 2:
          message.BoneLevelLandmarkID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LocalizationPBLLandmarks {
    return {
      CementoenamelJunctionLandmarkID: isSet(
        object.CementoenamelJunctionLandmarkID,
      )
        ? String(object.CementoenamelJunctionLandmarkID)
        : '',
      BoneLevelLandmarkID: isSet(object.BoneLevelLandmarkID)
        ? String(object.BoneLevelLandmarkID)
        : '',
    };
  },

  toJSON(message: LocalizationPBLLandmarks): unknown {
    const obj: any = {};
    message.CementoenamelJunctionLandmarkID !== undefined &&
      (obj.CementoenamelJunctionLandmarkID =
        message.CementoenamelJunctionLandmarkID);
    message.BoneLevelLandmarkID !== undefined &&
      (obj.BoneLevelLandmarkID = message.BoneLevelLandmarkID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<LocalizationPBLLandmarks>, I>>(
    object: I,
  ): LocalizationPBLLandmarks {
    const message = createBaseLocalizationPBLLandmarks();
    message.CementoenamelJunctionLandmarkID =
      object.CementoenamelJunctionLandmarkID ?? '';
    message.BoneLevelLandmarkID = object.BoneLevelLandmarkID ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

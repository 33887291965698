import {
  GroupedIOXRayImages,
  IOXRayMatrixType,
  IOXrayImageInterface,
  ImageSizeType,
} from '..';

export const getMatrixMaxWidth = (
  groupedImages: GroupedIOXRayImages,
  imageSizeType: ImageSizeType,
) => {
  const IOXRayMatrixWidth: IOXRayMatrixType = [
    [[], [], []],
    [[], [], []],
    [[], [], []],
  ];

  const getWidthByOrientation = (
    imageItem: IOXrayImageInterface,
    imageSizeType: ImageSizeType,
  ): number => {
    if (
      imageItem.imageMeta.OrientationAngle === 90 ||
      imageItem.imageMeta.OrientationAngle === 270
    ) {
      return imageItem[`${imageSizeType}Size`].height;
    } else {
      return imageItem[`${imageSizeType}Size`].width;
    }
  };

  const getIOXRayImageWidth = (imageItem: IOXrayImageInterface): number => {
    switch (imageSizeType) {
      case 'original':
        return getWidthByOrientation(imageItem, 'original');

      case 'preview':
        return getWidthByOrientation(imageItem, 'preview');

      case 'thumbnail':
        return getWidthByOrientation(imageItem, 'thumbnail');

      default:
        return getWidthByOrientation(imageItem, 'original');
    }
  };

  IOXRayMatrixWidth[0][0] = groupedImages.UpperLeft.map(getIOXRayImageWidth);
  IOXRayMatrixWidth[0][1] = groupedImages.MiddleLeft.map(getIOXRayImageWidth);
  IOXRayMatrixWidth[0][2] = groupedImages.LowerLeft.map(getIOXRayImageWidth);

  IOXRayMatrixWidth[1][0] = groupedImages.UpperMiddle.map(getIOXRayImageWidth);
  IOXRayMatrixWidth[1][1] = groupedImages.MiddleMiddle.map(getIOXRayImageWidth);
  IOXRayMatrixWidth[1][2] = groupedImages.LowerMiddle.map(getIOXRayImageWidth);

  IOXRayMatrixWidth[2][0] = groupedImages.UpperRight.map(getIOXRayImageWidth);
  IOXRayMatrixWidth[2][1] = groupedImages.MiddleRight.map(getIOXRayImageWidth);
  IOXRayMatrixWidth[2][2] = groupedImages.LowerRight.map(getIOXRayImageWidth);

  return IOXRayMatrixWidth.reduce((maxWidth, column) => {
    const maxColValue = column.reduce((columnMaxWidth, row) => {
      const maxRowValue = row.reduce(
        (rowMaxWidth, width) => rowMaxWidth + width,
        0,
      );

      if (maxRowValue > columnMaxWidth) {
        return maxRowValue;
      }

      return columnMaxWidth;
    }, 0);

    return maxWidth + maxColValue;
  }, 0);
};

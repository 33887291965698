import { filter } from 'ramda';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { ConditionInterface } from '../config/types';

import {
  isConditionInGroups,
  isConditionItemInGroups,
} from './isConditionInGroups';

export const filterConditionsByGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditions: Condition[]): Condition[] =>
    filter(isConditionInGroups(groups))(conditions);

export const filterConditionItemsByGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditionItems: ConditionInterface[]): ConditionInterface[] =>
    filter(isConditionItemInGroups(groups))(conditionItems);

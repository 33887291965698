import { useState } from 'react';

import { useModal } from '@/shared/hooks';

export const useRemoveAssetModal = () => {
  const [currentAssetID, setCurrentAssetID] = useState<string>();

  const { isOpen, openModal, closeModal } = useModal();

  const openRemoveAssetModal = (assetID: string) => {
    setCurrentAssetID(assetID);
    openModal();
  };

  const closeRemoveAssetModal = () => {
    setCurrentAssetID(undefined);
    closeModal();
  };

  return {
    currentAssetID,
    isRemoveAssetModalOpen: isOpen,
    openRemoveAssetModal,
    closeRemoveAssetModal,
  };
};

import { useAppDispatch } from '@/shared/hooks';

import { reportsModel } from '@/entities/reports';

export const useRemoveReport = () => {
  const dispatch = useAppDispatch();

  return async (reportID: string) => {
    const { DeletedReportID } = await dispatch(
      reportsModel.thunks.deleteReport({ ReportID: reportID }),
    ).unwrap();

    dispatch(reportsModel.actions.removeOne(DeletedReportID));
  };
};

import { FC } from 'react';
import cn from 'classnames';

import { Avatar } from '@/shared/ui';
import { getInitials } from '@/shared/lib';

import styles from './DoctorLabel.module.scss';

type DoctorLabelProps = {
  className?: string;
  fullName?: string;
  avatarSrc?: string;
};

export const DoctorLabel: FC<DoctorLabelProps> = (props) => {
  const { className, fullName, avatarSrc } = props;

  const doctorInitials = getInitials(fullName ?? '');

  return (
    <div className={cn(styles.container, className)}>
      <Avatar src={avatarSrc} alt={fullName} initials={doctorInitials} />
      <p className={cn(styles.text, 'p2')}>{fullName}</p>
    </div>
  );
};

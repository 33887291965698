import { CBCTSliceOrientation } from '@/shared/api/protocol_gen/model/dto_asset_common';

export const getMedicalImageOrient = (orient: CBCTSliceOrientation) => {
  switch (orient) {
    case CBCTSliceOrientation.SliceOrientationMesial:
      return 'M';
    case CBCTSliceOrientation.SliceOrientationLingual:
      return 'L';
    case CBCTSliceOrientation.SliceOrientationBuccal:
      return 'B';
    case CBCTSliceOrientation.SliceOrientationDistal:
      return 'D';
    default:
      return '';
  }
};

import { both, find, propEq } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { isConditionToothIDEquals } from './isConditionToothIDEquals';

export const findConditionByToothIDAndCode = (
  conditions: Condition[],
  toothID: string,
  conditionCode: ConditionCode,
) =>
  find(
    both(
      isConditionToothIDEquals(toothID),
      propEq<keyof Condition>('Code', conditionCode),
    ),
  )(conditions);

import { createColumnHelper } from '@tanstack/react-table';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';

import { Avatar, Button, Icon } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { OrganizationUserRole } from '@/shared/api/protocol_gen/model/dto_organization';

import { ModalID, modalModel } from '@/entities/modal';
import {
  DoctorInterface,
  InvitedDoctorInterface,
} from '@/entities/organization';

import styles from '../ui/StaffMembers/StaffMembers.module.scss';

export const useGetColumnsScheme = (
  handleOpenCloseRemoveModal: (userID: string) => void,
) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const handleOpenPermissionsModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.PermissionsModal,
      }),
    );
  }, [dispatch]);

  const handleOpenEditInfoModal = useCallback(
    (user: OrganizationUserRole | InvitedDoctorInterface) => {
      const editingUserID = 'UserID' in user ? user?.UserID : '';

      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.AddStaffModal,
          data: {
            actionType: 'Edit',
            editingUserID,
            doctorInfo: user,
          },
        }),
      );
    },
    [dispatch],
  );

  const columnHelper = createColumnHelper<DoctorInterface>();

  const columnsScheme = [
    columnHelper.accessor(
      (row) => ({
        fullName: row.fullName,
        avatarSrc: row.avatarSrc,
        initials: row.initials,
      }),
      {
        id: 'fullName',
        enableSorting: false,
        sortingFn: 'auto',
        minSize: 180,
        header: formatMessage({
          id: 'staffMembers.name',
          defaultMessage: 'Name',
        }),
        cell: (cell) => {
          const { fullName, avatarSrc, initials } = cell.getValue();

          const emptyAvatarImgAlt = formatMessage({
            id: 'staffMembers.emptyAvatar',
            defaultMessage: 'avatar-pic',
          });

          return (
            <div className={styles.rowName}>
              <Avatar
                src={avatarSrc}
                alt={fullName ?? emptyAvatarImgAlt}
                initials={initials}
              />

              <span className={cn(styles.fullName, styles.forWhiteSpace, 'p2')}>
                {fullName}
              </span>
            </div>
          );
        },
      },
    ),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      enableSorting: false,
      sortingFn: 'auto',
      minSize: 180,
      header: formatMessage({
        id: 'staffMembers.email',
        defaultMessage: 'Email',
      }),
      cell: (cell) => {
        const email = cell.getValue();

        return (
          <div className={styles.infoInCell}>
            <span className={styles.forWhiteSpace}>{email}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.specializations, {
      id: 'specializations',
      enableSorting: false,
      sortingFn: 'auto',
      // minSize: 180,
      header: formatMessage({
        id: 'staffMembers.specialization',
        defaultMessage: 'Specialization',
      }),
      cell: (cell) => {
        const specializations = cell.getValue();

        return (
          <div className={cn(styles.infoInCell, styles.textColumn)}>
            {specializations.map((specialization) => (
              <span key={specialization}>{specialization}</span>
            ))}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.roles, {
      id: 'roles',
      enableSorting: false,
      sortingFn: 'auto',
      minSize: 180,
      header: () => (
        <div className={styles.permissionsCol}>
          <p className="p2">
            <FormattedMessage
              id="staffMembers.permissions"
              defaultMessage="Permissions"
            />
          </p>

          <Icon
            name="info"
            className={cn(styles.icon, styles.infoIcon)}
            size={24}
            onClick={handleOpenPermissionsModal}
          />
        </div>
      ),
      cell: (cell) => {
        const roles = cell.getValue();

        return (
          <div className={cn(styles.infoInCell, styles.textColumn)}>
            {roles.map((role) => (
              <span key={role}>{role}</span>
            ))}
          </div>
        );
      },
    }),
    columnHelper.accessor(
      (row) => ({
        userID: row.id,
        doctorInfo: row.doctorInfo,
      }),
      {
        id: 'delete',
        enableSorting: false,
        minSize: 100,
        header: '',
        cell: (cell) => {
          const { userID, doctorInfo } = cell.getValue();

          return (
            <div className={styles.actions}>
              <Button
                variant="tertiary"
                className={cn(styles.icon, styles.editIcon)}
                onClick={() => {
                  if (doctorInfo) {
                    handleOpenEditInfoModal(doctorInfo);
                  }
                }}
                icon="pen"
                size="medium"
              />

              <Button
                className={cn(styles.icon, styles.deleteIcon)}
                variant="tertiary"
                onClick={() => {
                  if (doctorInfo) {
                    handleOpenCloseRemoveModal(
                      'InvitationID' in doctorInfo
                        ? doctorInfo.InvitationID
                        : userID,
                    );
                  }
                }}
                icon="delete"
                size="medium"
              />
            </div>
          );
        },
      },
    ),
  ];

  return columnsScheme;
};

import { FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { WidgetCard } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';

import { assetsModel, useGetSelectedMedicalImages } from '@/entities/assets';

import { transformUserAssetsToMedicalImages } from '../lib/transformUserAssetsToMedicalImages';

import { UploadedSlicesList } from './UploadSlicesList/UploadedSlicesList';
import { UploadSlicesDropzone } from './UploadSlicesDropzone/UploadSlicesDropzone';
import styles from './UploadedSlices.module.scss';

type UploadedSlicesProps = {
  toothID: string;
  onDragMedicalImage: (medicalImage: MedicalImageInterface) => void;
  toggleSelected: (medicalImage: MedicalImageInterface) => void;
  className?: string;
  testID?: string;
};

export const UploadedSlices: FC<UploadedSlicesProps> = memo((props) => {
  const { className, testID, toothID, toggleSelected, onDragMedicalImage } =
    props;

  const { formatMessage } = useIntl();

  const selectUserAssets =
    assetsModel.selectors.makeSelectorUserUploadedAssetsByToothID(toothID);

  const userAssets = useAppSelector(selectUserAssets);

  const selectedMedicalImages = useGetSelectedMedicalImages(toothID);

  const medicalImages = useMemo(
    () => transformUserAssetsToMedicalImages(userAssets),
    [userAssets],
  );

  return (
    <WidgetCard
      data-testid={testID}
      className={cn(styles.container, className)}
    >
      <h4 className={cn(styles.header, 'h4')}>
        {formatMessage({
          id: 'tooth.uploadedSlices.header',
          defaultMessage: 'Uploaded slices',
        })}
      </h4>
      {/* TODO: [16|h] refactor this into normal components */}
      {medicalImages.length > 0 && (
        <div className={styles.userSlices}>
          <UploadedSlicesList
            selectedMedicalImages={selectedMedicalImages}
            toggleSelected={toggleSelected}
            onDragMedicalImage={onDragMedicalImage}
            medicalImages={medicalImages}
            canUserDeleteSlices
          />
        </div>
      )}

      <UploadSlicesDropzone toothID={toothID} />
    </WidgetCard>
  );
});

import { useLocation, useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { Button } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { LocationStateType, PATHS } from '@/shared/config';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

import { useSignReport } from '@/features/signReport';

import styles from './ReportActions.module.scss';

type ReportActionsProps = {
  className?: string;
  patientID: string;
  reportID: string;
};

export const ReportActions = (props: ReportActionsProps) => {
  const { patientID, reportID, className } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const isReportSigned = useAppSelector(
    reportsModel.selectors.selectCurrentReportSigned,
  );
  const canChangeToothApproved = useAppSelector(
    reportsModel.selectors.selectCurrentReportCanChangeToothApproved,
  );
  const reportSignStatus = useAppSelector(
    reportsModel.selectors.selectCurrentReportSignStatus,
  );
  const allTeethIsApproved = useAppSelector(
    toothModel.selectors.selectAllTeethIsApproved(reportID),
  );

  const openConfirmSignReportModal = useSignReport(allTeethIsApproved);

  const handlePrintButton = () => {
    const locationState = location?.state as LocationStateType;

    navigate(
      generatePath(PATHS.patient + '/' + PATHS.preview2D, {
        patientID,
        reportID,
      }),
      {
        state: {
          ...locationState,
          lastPositionReport: window.pageYOffset,
          toothID: undefined,
        },
      },
    );
  };

  return (
    <footer className={cn(styles.container, className)}>
      <Button
        variant={isReportSigned ? 'primary' : 'tertiary'}
        onClick={handlePrintButton}
        icon={isReportSigned ? 'check' : undefined}
      >
        {isReportSigned ? (
          <FormattedMessage
            id="report.printReport"
            defaultMessage="Print report"
          />
        ) : (
          <FormattedMessage
            id="report.printReportWithoutSignature"
            defaultMessage="Print report without signature"
          />
        )}
      </Button>

      {!isReportSigned && (
        <Button
          icon="pen"
          disabled={
            reportSignStatus === 'withoutSign' || !canChangeToothApproved
          }
          onClick={openConfirmSignReportModal}
        >
          {allTeethIsApproved ? (
            <FormattedMessage id="report.sign" defaultMessage="Sign" />
          ) : (
            <FormattedMessage
              id="report.approveAndSign"
              defaultMessage="Approve all and sign"
            />
          )}
        </Button>
      )}
    </footer>
  );
};

import { useAppDispatch } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

export const useAddAndRemoveMedicalImage = () => {
  const dispatch = useAppDispatch();

  const { checkReportSignature } = useCheckReportSignature();

  const addMedicalImageToSelected = (assetID: string, toothID: string) => {
    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        const currentTooth = await dispatch(
          reportsModel.thunks.addToothDisplaySlice({
            AssetID: assetID,
            ToothID: toothID,
          }),
        ).unwrap();

        if (currentTooth.Tooth) {
          dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
        }
      },
    });
  };

  const removeMedicalImageFromSelected = async (
    assetID: string,
    toothID: string,
  ) => {
    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        const currentTooth = await dispatch(
          reportsModel.thunks.removeToothDisplaySlice({
            AssetID: assetID,
            ToothID: toothID,
          }),
        ).unwrap();

        if (currentTooth.Tooth) {
          dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
        }
      },
    });
  };

  const toggleMedicalImageSelection = ({
    toothID,
    medicalImage,
    selectedMedicalImages,
  }: {
    toothID: string;
    medicalImage: MedicalImageInterface;
    selectedMedicalImages: MedicalImageInterface[];
  }) => {
    const isImageSelected = selectedMedicalImages.some(
      (item) => item.src === medicalImage.src,
    );

    if (isImageSelected) {
      removeMedicalImageFromSelected(medicalImage.assetID, toothID);
    } else {
      addMedicalImageToSelected(medicalImage.assetID, toothID);
    }
  };

  return {
    addMedicalImageToSelected,
    removeMedicalImageFromSelected,
    toggleMedicalImageSelection,
  };
};

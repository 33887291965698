import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MedicalImageInterface,
  OrderReportType,
  SliceName,
} from '@/shared/config';
import { OrganizationUserRole } from '@/shared/api/protocol_gen/model/dto_organization';
import {
  EffectAddonAdd,
  EffectSubscriptionAdd,
} from '@/shared/api/protocol_gen/api/billing_new/dto_order_new';
import { DeepPartial } from '@/shared/api/protocol_gen/api/billing_new/svc_billing_new';
import { ButtonProps } from '@/shared/ui';

import { InvitedDoctorInterface } from '../../organization/config';
import { ModalID } from '../config';

type ConfirmModalData = {
  title: string;
  description?: string;
  okText?: string;
  cancelText?: string;
  okButtonProps?: ButtonProps;
  cancelButtonVariant?: 'primary' | 'secondary' | 'tertiary';
  bodyClassName?: string;
  danger?: boolean;
  onConfirm: () => void;
};

type ZoomedMedicalImageModalData = {
  toothID: string;
  medicalImage: MedicalImageInterface;
  medicalImageType?: 'default' | 'cropped';
  isSelectionEnabled?: boolean;
};

type TeethNumberingModalData = {
  image: {
    src: string;
    width?: number;
    height?: number;
    path?: string;
    viewOptions?: {
      sharpness: number;
      invert: boolean;
      wwwc: { ww: number; wc: number };
    };
    zoom?: number;
  };
};

type AddConditionModalData = {
  toothID: string;
  displayToothNumber: string;
  reportID: string;
  conditionID?: string;
};

type MPRFullScreenModalData = {
  nrrd: string;
};

type UploadStudyModalData = {
  patientID: string;
};

type OrderReportModalData = {
  reportType: OrderReportType;
};

type SharePatientModalData = {
  patientID: string;
};

type ProtectedSharingModalData = {
  accessCode: string;
  invitationCreateDate: number | Date;
};

type RevokeSharedPatientModalData = {
  patientID: string;
  sharedPatientDoctorID: string;
  invitationID: string;
  isInviteAccepted: boolean;
};

type AddStaffModalData = {
  actionType: 'Add' | 'Edit';
  editingUserID: string;
  doctorInfo?: OrganizationUserRole | InvitedDoctorInterface;
};

type OrderModelData = {
  title?: string;
  type?: 'InputStudioImplant' | 'InputStudioOrtho';
};

type CongratsModalData = {
  redirectTo: string;
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  buttonText?: string | JSX.Element;
  isTertiaryButton?: boolean;
  expiredTime?: number;
};

type ResetPasswordErrorModalData = {
  errorType: 'WrongEmail' | 'ExpiredTime';
};

type PatientModalData = {
  patientID: string;
};

type BillingInfoModalData = {
  isEdit: boolean;
  invoiceID?: string;
  effectToAdd?: DeepPartial<{
    SubscriptionAdd?: EffectSubscriptionAdd;
    AddonAdd?: EffectAddonAdd;
  }>;
};

// TODO: [m/8] data validation should take place when opening a modal window.
//  At the moment you can open a modal window without passing all the necessary
// fields to it

type OpenModalPayload = {
  modalID: ModalID;
  data?:
    | ConfirmModalData
    | AddConditionModalData
    | ZoomedMedicalImageModalData
    | MPRFullScreenModalData
    | TeethNumberingModalData
    | UploadStudyModalData
    | ProtectedSharingModalData
    | RevokeSharedPatientModalData
    | OrderModelData
    | AddStaffModalData
    | CongratsModalData
    | ResetPasswordErrorModalData
    | PatientModalData
    | BillingInfoModalData
    | OrderReportModalData;
};

type InitialState = {
  [ModalID.AddCondition]: {
    visible: boolean;
    data: AddConditionModalData;
  };
  [ModalID.Confirm]: {
    visible: boolean;
    data: ConfirmModalData;
    loading?: boolean;
  };
  [ModalID.ZoomedMedicalImage]: {
    visible: boolean;
    data: ZoomedMedicalImageModalData;
  };
  [ModalID.TeethNumberingModal]: {
    visible: boolean;
    data: TeethNumberingModalData;
  };
  [ModalID.MPRFullScreenModal]: {
    visible: boolean;
    data: {
      nrrd: string;
      toothID: string;
    };
  };
  [ModalID.UploadStudy_DEPRECATED]: {
    visible: boolean;
    data: UploadStudyModalData;
  };
  [ModalID.OrderReport]: {
    visible: boolean;
    data: OrderReportModalData;
  };
  [ModalID.SharePatient]: {
    visible: boolean;
    data: SharePatientModalData;
  };
  [ModalID.ProtectedSharing]: {
    visible: boolean;
    data: ProtectedSharingModalData;
  };
  [ModalID.EditInfoModal]: {
    visible: boolean;
    data: unknown;
  };
  [ModalID.PermissionsModal]: {
    visible: boolean;
  };
  [ModalID.AddStaffModal]: {
    visible: boolean;
    data: AddStaffModalData;
  };
  [ModalID.EditCompanyInfoModal]: {
    visible: boolean;
  };
  [ModalID.GetFullAccessModal]: {
    visible: boolean;
  };
  [ModalID.CongratsModal]: {
    visible: boolean;
    data: CongratsModalData;
  };
  [ModalID.NewAccountModal]: {
    visible: boolean;
  };
  [ModalID.RevokeSharedPatientModal]: {
    visible: boolean;
    data: RevokeSharedPatientModalData;
  };
  [ModalID.OrderOrthoORImplantModal]: {
    visible: boolean;
    data: OrderModelData;
  };
  [ModalID.Order3DSegmentronModal]: {
    visible: boolean;
  };
  [ModalID.BillingInformationModal]: {
    visible: boolean;
    data: BillingInfoModalData;
  };
  [ModalID.CancelSubscriptionModal]: {
    visible: boolean;
  };
  [ModalID.ResetPasswordErrorModal]: {
    visible: boolean;
    data: ResetPasswordErrorModalData;
  };
  [ModalID.PatientRemovalConfirmationModal]: {
    visible: boolean;
    data: PatientModalData;
  };
  [ModalID.PatientModalForm]: {
    visible: boolean;
    data: PatientModalData;
  };
  [ModalID.TopUpReportLimitsModal]: {
    visible: boolean;
  };
  [ModalID.PauseOrActivateSubscriptionModal]: {
    visible: boolean;
  };
  [ModalID.AboutLabelingModal]: {
    visible: boolean;
  };
};

const initialState: InitialState = {
  [ModalID.AddCondition]: {
    visible: false,
    data: {
      toothID: '',
      displayToothNumber: '',
      reportID: '',
      conditionID: '',
    },
  },
  [ModalID.Confirm]: {
    visible: false,
    loading: false,
    data: {
      title: '',
      description: '',
      okText: '',
      okButtonProps: {},
      cancelText: '',
      onConfirm: () => {},
      bodyClassName: '',
      cancelButtonVariant: 'secondary',
    },
  },
  [ModalID.ZoomedMedicalImage]: {
    visible: false,
    data: {
      toothID: '',
      medicalImage: {
        id: '',
        src: '',
        assetID: '',
        image: {
          Height: 0,
          Width: 0,
        },
        annotations: [
          {
            kind: 'line',
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
          },
        ],
        strideMm: 0,
        thicknessMm: 0,
        path: '',
        localizationID: '',
        rightSideOrient: '',
        leftSideOrient: '',
        TargetAssetID: '',
        BBox: {
          X: { Min: 0, Max: 0, Size: 0 },
          Y: { Min: 0, Max: 0, Size: 0 },
          Z: { Min: 0, Max: 0, Size: 0 },
        },
        viewOptions: {
          sharpness: 0,
          invert: false,
          wwwc: { ww: 0, wc: 0 },
        },
        groupName: 0,
        order: 0,
      },
    },
  },
  [ModalID.TeethNumberingModal]: {
    visible: false,
    data: {
      image: {
        src: '',
      },
    },
  },
  [ModalID.MPRFullScreenModal]: {
    visible: false,
    data: {
      nrrd: '',
      toothID: '',
    },
  },
  [ModalID.UploadStudy_DEPRECATED]: {
    visible: false,
    data: {
      patientID: '',
    },
  },
  [ModalID.OrderReport]: {
    visible: false,
    data: {
      reportType: 'CBCT',
    },
  },
  [ModalID.SharePatient]: {
    visible: false,
    data: {
      patientID: '',
    },
  },
  [ModalID.ProtectedSharing]: {
    visible: false,
    data: {
      accessCode: '',
      invitationCreateDate: 0,
    },
  },
  [ModalID.EditInfoModal]: {
    visible: false,
    data: {},
  },
  [ModalID.AddStaffModal]: {
    visible: false,
    data: {
      actionType: 'Add',
      editingUserID: '',
    },
  },
  [ModalID.PermissionsModal]: {
    visible: false,
  },
  [ModalID.EditCompanyInfoModal]: {
    visible: false,
  },
  [ModalID.GetFullAccessModal]: {
    visible: false,
  },
  [ModalID.CongratsModal]: {
    visible: false,
    data: {
      title: '',
      message: '',
      redirectTo: '',
      buttonText: '',
      isTertiaryButton: false,
    },
  },
  [ModalID.NewAccountModal]: {
    visible: true,
  },
  [ModalID.RevokeSharedPatientModal]: {
    visible: false,
    data: {
      patientID: '',
      sharedPatientDoctorID: '',
      invitationID: '',
      isInviteAccepted: false,
    },
  },
  [ModalID.OrderOrthoORImplantModal]: {
    visible: false,
    data: {
      title: '',
      type: 'InputStudioImplant',
    },
  },
  [ModalID.Order3DSegmentronModal]: {
    visible: false,
  },
  [ModalID.BillingInformationModal]: {
    visible: false,
    data: {
      isEdit: true,
      invoiceID: '',
      effectToAdd: {},
    },
  },
  [ModalID.CancelSubscriptionModal]: {
    visible: false,
  },
  [ModalID.ResetPasswordErrorModal]: {
    visible: false,
    data: {
      errorType: 'WrongEmail',
    },
  },
  [ModalID.PatientRemovalConfirmationModal]: {
    visible: false,
    data: {
      patientID: '',
    },
  },
  [ModalID.PatientModalForm]: {
    visible: false,
    data: {
      patientID: '',
    },
  },
  [ModalID.TopUpReportLimitsModal]: {
    visible: false,
  },
  [ModalID.PauseOrActivateSubscriptionModal]: {
    visible: false,
  },
  [ModalID.AboutLabelingModal]: {
    visible: false,
  },
};

const modalSlice = createSlice({
  name: SliceName.modal,
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      state[action.payload.modalID].visible = true;
      // @ts-expect-error WARN: some modals has not data field. Need to find the way to fix it.
      state[action.payload.modalID].data = action.payload.data;
    },
    closeModal: (state, { payload }: PayloadAction<ModalID>) => {
      // @ts-expect-error WARN: some modals has not data field. Need to find the way to fix it.
      state[payload] = initialState[payload];
    },
    setConfirmModalLoading: (state, action: PayloadAction<boolean>) => {
      state[ModalID.Confirm].loading = action.payload;
    },
  },
});

export const { actions } = modalSlice;

export default modalSlice.reducer;

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { File, DerivedDataImage } from '../model/dto_asset_common';
import {
  CBCTSeriesGeometryData,
  FloatPoint3D,
  StudioColorProfile,
} from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentGeneratedStudioCBCTDownscaled {
  Volume: File | undefined;
  DownscaleFactor: number;
  Geometry: CBCTSeriesGeometryData | undefined;
}

export interface AssetContentGeneratedStudioPanoramaReformatGeneral {
  PanoramaSingle: File | undefined;
  Derived: DerivedDataImage | undefined;
  Geometry:
    | AssetContentGeneratedStudioPanoramaReformatGeneralGeometry
    | undefined;
}

export interface AssetContentGeneratedStudioPanoramaReformatGeneralGeometry {
  Points: FloatPoint3D[];
  Up: FloatPoint3D | undefined;
  ColorProfile: StudioColorProfile | undefined;
  Shape:
    | AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape
    | undefined;
  DepthStep: number;
}

export interface AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape {
  Width: number;
  Height: number;
  Depth: number;
}

function createBaseAssetContentGeneratedStudioCBCTDownscaled(): AssetContentGeneratedStudioCBCTDownscaled {
  return { Volume: undefined, DownscaleFactor: 0, Geometry: undefined };
}

export const AssetContentGeneratedStudioCBCTDownscaled = {
  encode(
    message: AssetContentGeneratedStudioCBCTDownscaled,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Volume !== undefined) {
      File.encode(message.Volume, writer.uint32(10).fork()).ldelim();
    }
    if (message.DownscaleFactor !== 0) {
      writer.uint32(21).float(message.DownscaleFactor);
    }
    if (message.Geometry !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.Geometry,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedStudioCBCTDownscaled {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedStudioCBCTDownscaled();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Volume = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.DownscaleFactor = reader.float();
          break;
        case 3:
          message.Geometry = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedStudioCBCTDownscaled {
    return {
      Volume: isSet(object.Volume) ? File.fromJSON(object.Volume) : undefined,
      DownscaleFactor: isSet(object.DownscaleFactor)
        ? Number(object.DownscaleFactor)
        : 0,
      Geometry: isSet(object.Geometry)
        ? CBCTSeriesGeometryData.fromJSON(object.Geometry)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedStudioCBCTDownscaled): unknown {
    const obj: any = {};
    message.Volume !== undefined &&
      (obj.Volume = message.Volume ? File.toJSON(message.Volume) : undefined);
    message.DownscaleFactor !== undefined &&
      (obj.DownscaleFactor = message.DownscaleFactor);
    message.Geometry !== undefined &&
      (obj.Geometry = message.Geometry
        ? CBCTSeriesGeometryData.toJSON(message.Geometry)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedStudioCBCTDownscaled>, I>,
  >(object: I): AssetContentGeneratedStudioCBCTDownscaled {
    const message = createBaseAssetContentGeneratedStudioCBCTDownscaled();
    message.Volume =
      object.Volume !== undefined && object.Volume !== null
        ? File.fromPartial(object.Volume)
        : undefined;
    message.DownscaleFactor = object.DownscaleFactor ?? 0;
    message.Geometry =
      object.Geometry !== undefined && object.Geometry !== null
        ? CBCTSeriesGeometryData.fromPartial(object.Geometry)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedStudioPanoramaReformatGeneral(): AssetContentGeneratedStudioPanoramaReformatGeneral {
  return { PanoramaSingle: undefined, Derived: undefined, Geometry: undefined };
}

export const AssetContentGeneratedStudioPanoramaReformatGeneral = {
  encode(
    message: AssetContentGeneratedStudioPanoramaReformatGeneral,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaSingle !== undefined) {
      File.encode(message.PanoramaSingle, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Geometry !== undefined) {
      AssetContentGeneratedStudioPanoramaReformatGeneralGeometry.encode(
        message.Geometry,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedStudioPanoramaReformatGeneral {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedStudioPanoramaReformatGeneral();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaSingle = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        case 3:
          message.Geometry =
            AssetContentGeneratedStudioPanoramaReformatGeneralGeometry.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedStudioPanoramaReformatGeneral {
    return {
      PanoramaSingle: isSet(object.PanoramaSingle)
        ? File.fromJSON(object.PanoramaSingle)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
      Geometry: isSet(object.Geometry)
        ? AssetContentGeneratedStudioPanoramaReformatGeneralGeometry.fromJSON(
            object.Geometry,
          )
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedStudioPanoramaReformatGeneral): unknown {
    const obj: any = {};
    message.PanoramaSingle !== undefined &&
      (obj.PanoramaSingle = message.PanoramaSingle
        ? File.toJSON(message.PanoramaSingle)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    message.Geometry !== undefined &&
      (obj.Geometry = message.Geometry
        ? AssetContentGeneratedStudioPanoramaReformatGeneralGeometry.toJSON(
            message.Geometry,
          )
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedStudioPanoramaReformatGeneral>,
      I
    >,
  >(object: I): AssetContentGeneratedStudioPanoramaReformatGeneral {
    const message =
      createBaseAssetContentGeneratedStudioPanoramaReformatGeneral();
    message.PanoramaSingle =
      object.PanoramaSingle !== undefined && object.PanoramaSingle !== null
        ? File.fromPartial(object.PanoramaSingle)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    message.Geometry =
      object.Geometry !== undefined && object.Geometry !== null
        ? AssetContentGeneratedStudioPanoramaReformatGeneralGeometry.fromPartial(
            object.Geometry,
          )
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedStudioPanoramaReformatGeneralGeometry(): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry {
  return {
    Points: [],
    Up: undefined,
    ColorProfile: undefined,
    Shape: undefined,
    DepthStep: 0,
  };
}

export const AssetContentGeneratedStudioPanoramaReformatGeneralGeometry = {
  encode(
    message: AssetContentGeneratedStudioPanoramaReformatGeneralGeometry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Points) {
      FloatPoint3D.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Up !== undefined) {
      FloatPoint3D.encode(message.Up, writer.uint32(18).fork()).ldelim();
    }
    if (message.ColorProfile !== undefined) {
      StudioColorProfile.encode(
        message.ColorProfile,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Shape !== undefined) {
      AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape.encode(
        message.Shape,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.DepthStep !== 0) {
      writer.uint32(45).float(message.DepthStep);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedStudioPanoramaReformatGeneralGeometry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Points.push(FloatPoint3D.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Up = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 3:
          message.ColorProfile = StudioColorProfile.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.Shape =
            AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 5:
          message.DepthStep = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry {
    return {
      Points: Array.isArray(object?.Points)
        ? object.Points.map((e: any) => FloatPoint3D.fromJSON(e))
        : [],
      Up: isSet(object.Up) ? FloatPoint3D.fromJSON(object.Up) : undefined,
      ColorProfile: isSet(object.ColorProfile)
        ? StudioColorProfile.fromJSON(object.ColorProfile)
        : undefined,
      Shape: isSet(object.Shape)
        ? AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape.fromJSON(
            object.Shape,
          )
        : undefined,
      DepthStep: isSet(object.DepthStep) ? Number(object.DepthStep) : 0,
    };
  },

  toJSON(
    message: AssetContentGeneratedStudioPanoramaReformatGeneralGeometry,
  ): unknown {
    const obj: any = {};
    if (message.Points) {
      obj.Points = message.Points.map((e) =>
        e ? FloatPoint3D.toJSON(e) : undefined,
      );
    } else {
      obj.Points = [];
    }
    message.Up !== undefined &&
      (obj.Up = message.Up ? FloatPoint3D.toJSON(message.Up) : undefined);
    message.ColorProfile !== undefined &&
      (obj.ColorProfile = message.ColorProfile
        ? StudioColorProfile.toJSON(message.ColorProfile)
        : undefined);
    message.Shape !== undefined &&
      (obj.Shape = message.Shape
        ? AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape.toJSON(
            message.Shape,
          )
        : undefined);
    message.DepthStep !== undefined && (obj.DepthStep = message.DepthStep);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedStudioPanoramaReformatGeneralGeometry>,
      I
    >,
  >(object: I): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry {
    const message =
      createBaseAssetContentGeneratedStudioPanoramaReformatGeneralGeometry();
    message.Points =
      object.Points?.map((e) => FloatPoint3D.fromPartial(e)) || [];
    message.Up =
      object.Up !== undefined && object.Up !== null
        ? FloatPoint3D.fromPartial(object.Up)
        : undefined;
    message.ColorProfile =
      object.ColorProfile !== undefined && object.ColorProfile !== null
        ? StudioColorProfile.fromPartial(object.ColorProfile)
        : undefined;
    message.Shape =
      object.Shape !== undefined && object.Shape !== null
        ? AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape.fromPartial(
            object.Shape,
          )
        : undefined;
    message.DepthStep = object.DepthStep ?? 0;
    return message;
  },
};

function createBaseAssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape(): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape {
  return { Width: 0, Height: 0, Depth: 0 };
}

export const AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape =
  {
    encode(
      message: AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape,
      writer: _m0.Writer = _m0.Writer.create(),
    ): _m0.Writer {
      if (message.Width !== 0) {
        writer.uint32(13).float(message.Width);
      }
      if (message.Height !== 0) {
        writer.uint32(21).float(message.Height);
      }
      if (message.Depth !== 0) {
        writer.uint32(29).float(message.Depth);
      }
      return writer;
    },

    decode(
      input: _m0.Reader | Uint8Array,
      length?: number,
    ): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape {
      const reader =
        input instanceof _m0.Reader ? input : new _m0.Reader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message =
        createBaseAssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.Width = reader.float();
            break;
          case 2:
            message.Height = reader.float();
            break;
          case 3:
            message.Depth = reader.float();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    },

    fromJSON(
      object: any,
    ): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape {
      return {
        Width: isSet(object.Width) ? Number(object.Width) : 0,
        Height: isSet(object.Height) ? Number(object.Height) : 0,
        Depth: isSet(object.Depth) ? Number(object.Depth) : 0,
      };
    },

    toJSON(
      message: AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape,
    ): unknown {
      const obj: any = {};
      message.Width !== undefined && (obj.Width = message.Width);
      message.Height !== undefined && (obj.Height = message.Height);
      message.Depth !== undefined && (obj.Depth = message.Depth);
      return obj;
    },

    fromPartial<
      I extends Exact<
        DeepPartial<AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape>,
        I
      >,
    >(
      object: I,
    ): AssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape {
      const message =
        createBaseAssetContentGeneratedStudioPanoramaReformatGeneralGeometry_GeometryShape();
      message.Width = object.Width ?? 0;
      message.Height = object.Height ?? 0;
      message.Depth = object.Depth ?? 0;
      return message;
    },
  };

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { GroupedIOXRayImages, IOXrayImageInterface, ImageSizeType } from '..';

const getMaxRowHeight = (rowElements: number[]) =>
  rowElements.length > 0 ? Math.max(...rowElements) : 0;

export const getMatrixMaxHeight = (
  groupedImages: GroupedIOXRayImages,
  imageSizeType: ImageSizeType,
): number => {
  const getHeightByOrientation = (
    imageItem: IOXrayImageInterface,
    imageSizeType: ImageSizeType,
  ): number => {
    if (
      imageItem.imageMeta.OrientationAngle === 90 ||
      imageItem.imageMeta.OrientationAngle === 270
    ) {
      return imageItem[`${imageSizeType}Size`].width;
    } else {
      return imageItem[`${imageSizeType}Size`].height;
    }
  };

  const getIOXRayImageHeight = (imageItem: IOXrayImageInterface): number => {
    switch (imageSizeType) {
      case 'original':
        return getHeightByOrientation(imageItem, 'original');

      case 'preview':
        return getHeightByOrientation(imageItem, 'preview');

      case 'thumbnail':
        return getHeightByOrientation(imageItem, 'thumbnail');

      default:
        return getHeightByOrientation(imageItem, 'original');
    }
  };

  const upperMaximum = getMaxRowHeight(
    [
      ...groupedImages.UpperLeft,
      ...groupedImages.UpperMiddle,
      ...groupedImages.UpperRight,
    ].map(getIOXRayImageHeight),
  );

  const middleMaximum = getMaxRowHeight(
    [
      ...groupedImages.MiddleLeft,
      ...groupedImages.MiddleMiddle,
      ...groupedImages.MiddleRight,
    ].map(getIOXRayImageHeight),
  );

  const lowerMaximum = getMaxRowHeight(
    [
      ...groupedImages.LowerLeft,
      ...groupedImages.LowerMiddle,
      ...groupedImages.LowerRight,
    ].map(getIOXRayImageHeight),
  );

  return upperMaximum + middleMaximum + lowerMaximum;
};

export function increaseImageWidth({
  height,
  width,
  newHeight,
}: Record<'width' | 'height' | 'newHeight', number>) {
  const aspectRatio = width / height;
  const newWidth = aspectRatio * newHeight;

  return newWidth;
}

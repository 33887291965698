import { Study } from '@/shared/api/protocol_gen/model/dto_study';

import {
  GroupedReportsBySourceStudyIDsAndType,
  GroupedReportsWithStudies,
} from '../../../pages/PatientProfile/config/types';

export const getGroupedReportsWithStudies = (
  groupedReportsBySourceStudyIDsAndType: GroupedReportsBySourceStudyIDsAndType,
  studies: Study[],
): GroupedReportsWithStudies[] =>
  groupedReportsBySourceStudyIDsAndType.map((group) => ({
    ...group,
    studies: studies.filter((study) => group.sourceStudyIDs.includes(study.ID)),
  }));

import { FC, useLayoutEffect } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Accordion } from '@/shared/ui';
import { scrollToElementByID } from '@/shared/lib';

import {
  ConditionDecisionChange,
  ConditionGroups,
  ConditionInterface,
  i18n,
} from '../../config';
import { ConditionToggleList } from '../ConditionToggleList/ConditionToggleList';
import { ConditionToggleContainer } from '../ConditionToggleContainer/ConditionToggleContianer';
import { ConditionToggle } from '../ConditionToggle/ConditionToggle';
import { ConditionChildGroup } from '../ConditionChildGroup/ConditionChildGroup';
import { useConditionGroupsList } from '../../hooks/useConditionGroupsList';

import styles from './ConditionListByCategory.module.scss';

type ConditionListByCategoryProps = {
  className?: string;
  testID?: string;
  conditionItemsByGroups: Record<ConditionGroups, ConditionInterface[]>;
  updateConditionDecision: ConditionDecisionChange;
  disabled?: boolean;
  toothID: string;
  conditionID?: string;
};

export const ConditionListByCategory: FC<ConditionListByCategoryProps> = (
  props,
) => {
  const {
    className,
    testID,
    conditionItemsByGroups,
    updateConditionDecision,
    disabled,
    toothID,
    conditionID,
  } = props;

  const { formatMessage } = useIntl();

  const { conditionGroupsList, openConditionGroups } = useConditionGroupsList(
    conditionItemsByGroups,
    toothID,
  );

  useLayoutEffect(() => {
    const scrollWithDelay = () => {
      if (conditionID) {
        scrollToElementByID(conditionID, 'smooth', 'start');
      }
    };

    const timeoutID = setTimeout(scrollWithDelay, 300);

    return () => clearTimeout(timeoutID);
  }, [conditionID]);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <Accordion.Root
        type="multiple"
        defaultValue={openConditionGroups}
        className={cn(styles.list)}
      >
        {conditionGroupsList.map((group: ConditionGroups) => (
          <Accordion.Item key={group} value={group}>
            <Accordion.Header>
              <Accordion.Trigger>
                {formatMessage(
                  i18n.conditionGroupText[group as ConditionGroups],
                )}
              </Accordion.Trigger>
            </Accordion.Header>

            <Accordion.Content>
              <ConditionToggleList className={styles.content}>
                {conditionItemsByGroups[group].map((data) =>
                  data.childConditionInterfaces && data?.isChecked ? (
                    <ConditionToggleContainer key={data.code}>
                      <ConditionToggle
                        className={styles.conditionLabel}
                        data={data}
                        onChange={updateConditionDecision}
                        disabled={disabled}
                      />

                      <ConditionChildGroup
                        className={styles.childGroup}
                        data={data.childConditionInterfaces}
                        onChange={updateConditionDecision}
                        disabled={disabled || !data?.isChecked}
                      />
                    </ConditionToggleContainer>
                  ) : (
                    <ConditionToggle
                      key={data.code}
                      data={data}
                      onChange={updateConditionDecision}
                      disabled={disabled}
                    />
                  ),
                )}
              </ConditionToggleList>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </div>
  );
};

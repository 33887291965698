import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { difference, uniq } from 'lodash';

import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { LoadingStateType, SliceName } from '@/shared/config';

import { deleteAsset, setMedicalImageViewOptions } from './assetsSlice.thunks';

export const assetsAdapter = createEntityAdapter<Asset>({
  selectId: (asset: Asset) => asset?.ID,
});

const initialState = {
  guardedIDs: [] as string[],
  loading: 'idle' as LoadingStateType,
};

const assetState = assetsAdapter.getInitialState(initialState);

const setNewestOne = (
  state: typeof assetState,
  action: PayloadAction<Asset>,
) => {
  const currentRevisionNumber =
    state.entities[action.payload.ID]?.Revision?.Number ?? 0;
  const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

  if (payloadRevisionNumber > currentRevisionNumber) {
    assetsAdapter.setOne(state, action.payload);
  }
};

const assetsSlice = createSlice({
  name: SliceName.assets,
  initialState: assetState,
  reducers: {
    addOne: assetsAdapter.addOne,
    addMany: assetsAdapter.addMany,
    setAll: assetsAdapter.setAll,
    upsertMany: assetsAdapter.upsertMany,
    updateMany: assetsAdapter.updateMany,
    setNewestOne,
    setMany: assetsAdapter.setMany,
    removeOne: assetsAdapter.removeOne,
    removeAll: assetsAdapter.removeAll,
    initGuardedIDs: (state, action: PayloadAction<string[]>) => {
      state.guardedIDs = action.payload;
    },
    addGuardedIDs: (state, action: PayloadAction<string[]>) => {
      const updatedIDs = uniq(state.guardedIDs.concat(action.payload));
      state.guardedIDs = updatedIDs;
    },
    removeAllButNoGuarded: (state) => {
      const ids = difference(state.ids, state.guardedIDs);
      assetsAdapter.removeMany(state, ids);
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    reset: () => assetsAdapter.getInitialState(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(deleteAsset.fulfilled, (state, { payload }) => {
      if (payload?.Asset) {
        assetsAdapter.removeOne(state, payload.Asset.ID);
      }
    });
    builder.addCase(
      setMedicalImageViewOptions.fulfilled,
      (state, { payload }) => {
        if (payload.Asset) {
          setNewestOne(state, {
            type: `${SliceName.assets}/setNewestOne`,
            payload: payload.Asset,
          });
        }
      },
    );
  },
});

export const { actions } = assetsSlice;

export default assetsSlice.reducer;

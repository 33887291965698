/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  Gender,
  PersonalData,
  Attribute,
  Created,
  Revision,
  Deleted,
  genderFromJSON,
  genderToJSON,
} from '../model/dto_base';
import { Timestamp } from '../google/protobuf/timestamp';
import { PatientPermissions } from '../model/dto_patient_permissions';

export const protobufPackage = 'com.diagnocat.model';

export enum PatientStatus {
  /** PatientStatusInvalidValue - default value */
  PatientStatusInvalidValue = 0,
  PatientStatusUnassigned = 1,
  PatientStatusUnderTreatment = 2,
  PatientStatusInReview = 3,
  PatientStatusCaseFinished = 4,
  UNRECOGNIZED = -1,
}

export function patientStatusFromJSON(object: any): PatientStatus {
  switch (object) {
    case 0:
    case 'PatientStatusInvalidValue':
      return PatientStatus.PatientStatusInvalidValue;
    case 1:
    case 'PatientStatusUnassigned':
      return PatientStatus.PatientStatusUnassigned;
    case 2:
    case 'PatientStatusUnderTreatment':
      return PatientStatus.PatientStatusUnderTreatment;
    case 3:
    case 'PatientStatusInReview':
      return PatientStatus.PatientStatusInReview;
    case 4:
    case 'PatientStatusCaseFinished':
      return PatientStatus.PatientStatusCaseFinished;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PatientStatus.UNRECOGNIZED;
  }
}

export function patientStatusToJSON(object: PatientStatus): string {
  switch (object) {
    case PatientStatus.PatientStatusInvalidValue:
      return 'PatientStatusInvalidValue';
    case PatientStatus.PatientStatusUnassigned:
      return 'PatientStatusUnassigned';
    case PatientStatus.PatientStatusUnderTreatment:
      return 'PatientStatusUnderTreatment';
    case PatientStatus.PatientStatusInReview:
      return 'PatientStatusInReview';
    case PatientStatus.PatientStatusCaseFinished:
      return 'PatientStatusCaseFinished';
    case PatientStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Patient {
  ID: string;
  OrganizationID: string;
  ExternalID: string;
  /** // `PersonalData`: PERM: PatientPermissions.CanViewPersonalData == true */
  PersonalData: PersonalData | undefined;
  Age: number;
  Gender: Gender;
  Status: PatientStatus;
  DisplayAssetID: string;
  /** // `Doctors`: PERM: PatientPermissions.CanViewSharings == true || PatientPermissions.CanViewTeam == true */
  Doctors: PatientDoctor[];
  Attributes: Attribute[];
  /** // `ClinicalCaseDescription`: PERM: PatientPermissions.CanViewClinicalCaseDescription == true */
  ClinicalCaseDescription: string;
  /** // `TreatmentDueDate`: PERM: PatientPermissions.CanViewClinicalCaseDescription == true */
  TreatmentDueDate: Date | undefined;
  YourPermissions: PatientPermissions | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
  BlackCatID: number;
}

export interface PatientDoctor {
  UserID: string;
  /** // PERM: PatientPermissions.CanViewTeam == true */
  TeamMember: PatientDoctor_PatientTeamMember | undefined;
  /** // PERM: PatientPermissions.CanViewSharings == true */
  Sharing: PatientDoctor_PatientSharingRole | undefined;
}

/** only accepted sharings */
export interface PatientDoctor_PatientSharingRole {
  RecipientEmail: string;
  CreatedAt: Date | undefined;
  /** creator identifier */
  CreatedBy: string;
}

export interface PatientDoctor_PatientTeamMember {}

function createBasePatient(): Patient {
  return {
    ID: '',
    OrganizationID: '',
    ExternalID: '',
    PersonalData: undefined,
    Age: 0,
    Gender: 0,
    Status: 0,
    DisplayAssetID: '',
    Doctors: [],
    Attributes: [],
    ClinicalCaseDescription: '',
    TreatmentDueDate: undefined,
    YourPermissions: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
    BlackCatID: 0,
  };
}

export const Patient = {
  encode(
    message: Patient,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.ExternalID !== '') {
      writer.uint32(26).string(message.ExternalID);
    }
    if (message.PersonalData !== undefined) {
      PersonalData.encode(
        message.PersonalData,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.Age !== 0) {
      writer.uint32(40).uint32(message.Age);
    }
    if (message.Gender !== 0) {
      writer.uint32(48).int32(message.Gender);
    }
    if (message.Status !== 0) {
      writer.uint32(56).int32(message.Status);
    }
    if (message.DisplayAssetID !== '') {
      writer.uint32(66).string(message.DisplayAssetID);
    }
    for (const v of message.Doctors) {
      PatientDoctor.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.Attributes) {
      Attribute.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    if (message.ClinicalCaseDescription !== '') {
      writer.uint32(250).string(message.ClinicalCaseDescription);
    }
    if (message.TreatmentDueDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.TreatmentDueDate),
        writer.uint32(258).fork(),
      ).ldelim();
    }
    if (message.YourPermissions !== undefined) {
      PatientPermissions.encode(
        message.YourPermissions,
        writer.uint32(80162).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    if (message.BlackCatID !== 0) {
      writer.uint32(799992).int64(message.BlackCatID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Patient {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.ExternalID = reader.string();
          break;
        case 4:
          message.PersonalData = PersonalData.decode(reader, reader.uint32());
          break;
        case 5:
          message.Age = reader.uint32();
          break;
        case 6:
          message.Gender = reader.int32() as any;
          break;
        case 7:
          message.Status = reader.int32() as any;
          break;
        case 8:
          message.DisplayAssetID = reader.string();
          break;
        case 23:
          message.Doctors.push(PatientDoctor.decode(reader, reader.uint32()));
          break;
        case 24:
          message.Attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 31:
          message.ClinicalCaseDescription = reader.string();
          break;
        case 32:
          message.TreatmentDueDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 10020:
          message.YourPermissions = PatientPermissions.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        case 99999:
          message.BlackCatID = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Patient {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      ExternalID: isSet(object.ExternalID) ? String(object.ExternalID) : '',
      PersonalData: isSet(object.PersonalData)
        ? PersonalData.fromJSON(object.PersonalData)
        : undefined,
      Age: isSet(object.Age) ? Number(object.Age) : 0,
      Gender: isSet(object.Gender) ? genderFromJSON(object.Gender) : 0,
      Status: isSet(object.Status) ? patientStatusFromJSON(object.Status) : 0,
      DisplayAssetID: isSet(object.DisplayAssetID)
        ? String(object.DisplayAssetID)
        : '',
      Doctors: Array.isArray(object?.Doctors)
        ? object.Doctors.map((e: any) => PatientDoctor.fromJSON(e))
        : [],
      Attributes: Array.isArray(object?.Attributes)
        ? object.Attributes.map((e: any) => Attribute.fromJSON(e))
        : [],
      ClinicalCaseDescription: isSet(object.ClinicalCaseDescription)
        ? String(object.ClinicalCaseDescription)
        : '',
      TreatmentDueDate: isSet(object.TreatmentDueDate)
        ? fromJsonTimestamp(object.TreatmentDueDate)
        : undefined,
      YourPermissions: isSet(object.YourPermissions)
        ? PatientPermissions.fromJSON(object.YourPermissions)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
      BlackCatID: isSet(object.BlackCatID) ? Number(object.BlackCatID) : 0,
    };
  },

  toJSON(message: Patient): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.ExternalID !== undefined && (obj.ExternalID = message.ExternalID);
    message.PersonalData !== undefined &&
      (obj.PersonalData = message.PersonalData
        ? PersonalData.toJSON(message.PersonalData)
        : undefined);
    message.Age !== undefined && (obj.Age = Math.round(message.Age));
    message.Gender !== undefined && (obj.Gender = genderToJSON(message.Gender));
    message.Status !== undefined &&
      (obj.Status = patientStatusToJSON(message.Status));
    message.DisplayAssetID !== undefined &&
      (obj.DisplayAssetID = message.DisplayAssetID);
    if (message.Doctors) {
      obj.Doctors = message.Doctors.map((e) =>
        e ? PatientDoctor.toJSON(e) : undefined,
      );
    } else {
      obj.Doctors = [];
    }
    if (message.Attributes) {
      obj.Attributes = message.Attributes.map((e) =>
        e ? Attribute.toJSON(e) : undefined,
      );
    } else {
      obj.Attributes = [];
    }
    message.ClinicalCaseDescription !== undefined &&
      (obj.ClinicalCaseDescription = message.ClinicalCaseDescription);
    message.TreatmentDueDate !== undefined &&
      (obj.TreatmentDueDate = message.TreatmentDueDate.toISOString());
    message.YourPermissions !== undefined &&
      (obj.YourPermissions = message.YourPermissions
        ? PatientPermissions.toJSON(message.YourPermissions)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    message.BlackCatID !== undefined &&
      (obj.BlackCatID = Math.round(message.BlackCatID));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Patient>, I>>(object: I): Patient {
    const message = createBasePatient();
    message.ID = object.ID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.ExternalID = object.ExternalID ?? '';
    message.PersonalData =
      object.PersonalData !== undefined && object.PersonalData !== null
        ? PersonalData.fromPartial(object.PersonalData)
        : undefined;
    message.Age = object.Age ?? 0;
    message.Gender = object.Gender ?? 0;
    message.Status = object.Status ?? 0;
    message.DisplayAssetID = object.DisplayAssetID ?? '';
    message.Doctors =
      object.Doctors?.map((e) => PatientDoctor.fromPartial(e)) || [];
    message.Attributes =
      object.Attributes?.map((e) => Attribute.fromPartial(e)) || [];
    message.ClinicalCaseDescription = object.ClinicalCaseDescription ?? '';
    message.TreatmentDueDate = object.TreatmentDueDate ?? undefined;
    message.YourPermissions =
      object.YourPermissions !== undefined && object.YourPermissions !== null
        ? PatientPermissions.fromPartial(object.YourPermissions)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    message.BlackCatID = object.BlackCatID ?? 0;
    return message;
  },
};

function createBasePatientDoctor(): PatientDoctor {
  return { UserID: '', TeamMember: undefined, Sharing: undefined };
}

export const PatientDoctor = {
  encode(
    message: PatientDoctor,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.TeamMember !== undefined) {
      PatientDoctor_PatientTeamMember.encode(
        message.TeamMember,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.Sharing !== undefined) {
      PatientDoctor_PatientSharingRole.encode(
        message.Sharing,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientDoctor {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientDoctor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 11:
          message.TeamMember = PatientDoctor_PatientTeamMember.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 12:
          message.Sharing = PatientDoctor_PatientSharingRole.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientDoctor {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      TeamMember: isSet(object.TeamMember)
        ? PatientDoctor_PatientTeamMember.fromJSON(object.TeamMember)
        : undefined,
      Sharing: isSet(object.Sharing)
        ? PatientDoctor_PatientSharingRole.fromJSON(object.Sharing)
        : undefined,
    };
  },

  toJSON(message: PatientDoctor): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.TeamMember !== undefined &&
      (obj.TeamMember = message.TeamMember
        ? PatientDoctor_PatientTeamMember.toJSON(message.TeamMember)
        : undefined);
    message.Sharing !== undefined &&
      (obj.Sharing = message.Sharing
        ? PatientDoctor_PatientSharingRole.toJSON(message.Sharing)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientDoctor>, I>>(
    object: I,
  ): PatientDoctor {
    const message = createBasePatientDoctor();
    message.UserID = object.UserID ?? '';
    message.TeamMember =
      object.TeamMember !== undefined && object.TeamMember !== null
        ? PatientDoctor_PatientTeamMember.fromPartial(object.TeamMember)
        : undefined;
    message.Sharing =
      object.Sharing !== undefined && object.Sharing !== null
        ? PatientDoctor_PatientSharingRole.fromPartial(object.Sharing)
        : undefined;
    return message;
  },
};

function createBasePatientDoctor_PatientSharingRole(): PatientDoctor_PatientSharingRole {
  return { RecipientEmail: '', CreatedAt: undefined, CreatedBy: '' };
}

export const PatientDoctor_PatientSharingRole = {
  encode(
    message: PatientDoctor_PatientSharingRole,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.RecipientEmail !== '') {
      writer.uint32(10).string(message.RecipientEmail);
    }
    if (message.CreatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.CreatedAt),
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.CreatedBy !== '') {
      writer.uint32(26).string(message.CreatedBy);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientDoctor_PatientSharingRole {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientDoctor_PatientSharingRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.RecipientEmail = reader.string();
          break;
        case 2:
          message.CreatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.CreatedBy = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientDoctor_PatientSharingRole {
    return {
      RecipientEmail: isSet(object.RecipientEmail)
        ? String(object.RecipientEmail)
        : '',
      CreatedAt: isSet(object.CreatedAt)
        ? fromJsonTimestamp(object.CreatedAt)
        : undefined,
      CreatedBy: isSet(object.CreatedBy) ? String(object.CreatedBy) : '',
    };
  },

  toJSON(message: PatientDoctor_PatientSharingRole): unknown {
    const obj: any = {};
    message.RecipientEmail !== undefined &&
      (obj.RecipientEmail = message.RecipientEmail);
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = message.CreatedAt.toISOString());
    message.CreatedBy !== undefined && (obj.CreatedBy = message.CreatedBy);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<PatientDoctor_PatientSharingRole>, I>,
  >(object: I): PatientDoctor_PatientSharingRole {
    const message = createBasePatientDoctor_PatientSharingRole();
    message.RecipientEmail = object.RecipientEmail ?? '';
    message.CreatedAt = object.CreatedAt ?? undefined;
    message.CreatedBy = object.CreatedBy ?? '';
    return message;
  },
};

function createBasePatientDoctor_PatientTeamMember(): PatientDoctor_PatientTeamMember {
  return {};
}

export const PatientDoctor_PatientTeamMember = {
  encode(
    _: PatientDoctor_PatientTeamMember,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientDoctor_PatientTeamMember {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientDoctor_PatientTeamMember();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): PatientDoctor_PatientTeamMember {
    return {};
  },

  toJSON(_: PatientDoctor_PatientTeamMember): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientDoctor_PatientTeamMember>, I>>(
    _: I,
  ): PatientDoctor_PatientTeamMember {
    const message = createBasePatientDoctor_PatientTeamMember();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import React, { FC } from 'react';
import cn from 'classnames';

import styles from './BasicLayout.module.scss';

export type BasicLayoutProps = {
  fullWidth?: boolean;
  footerWithSettings?: boolean;
  className?: string;
  testID?: string;
  children?: React.ReactNode;
};

export const BasicLayout: FC<BasicLayoutProps> = (props) => {
  const {
    fullWidth = false,
    footerWithSettings = false,
    className,
    testID,
    children,
  } = props;

  return (
    <div
      className={cn(
        styles.container,
        fullWidth && styles.fullWidth,
        footerWithSettings && styles.footerWithSettings,
        className,
      )}
      data-testid={testID}
    >
      {children}
    </div>
  );
};

import {
  components,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select';
import cn from 'classnames';

import { Icon } from '@/shared/ui';

import styles from './AddStaffModal.module.scss';

export const StaffModalSelectOption = (
  props: OptionProps<{ comment: string; label: string }>,
) => {
  const {
    data: { comment, label },
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <p className="p2">{label}</p>
      <p className={cn('p4', styles.comment)}>{comment}</p>
    </components.Option>
  );
};

export const StaffModalSelectMultiValueLabel = (
  props: MultiValueProps<{ label: string }>,
) => {
  const {
    data: { label },
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValueLabel {...props}>
      <p className="p2">{label}</p>
    </components.MultiValueLabel>
  );
};

export const StaffModalMultiValueRemove = (props: MultiValueRemoveProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.MultiValueRemove {...props}>
    <Icon name="close" size={24} />
  </components.MultiValueRemove>
);

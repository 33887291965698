import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { Study, StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { getDateTimestamp } from '@/shared/lib';

export const getLatestDate = (
  report: Report | undefined,
  studies: Study[],
): number | undefined => {
  const cbctStudy = studies.find(
    (study) => study.Type === StudyType.StudyType_CBCT,
  );

  if (cbctStudy && cbctStudy.Created?.At) {
    return getDateTimestamp(cbctStudy.Created.At);
  }

  const studiesDate = studies.filter((study) => study.Created?.At);

  if (studiesDate.length > 0) {
    return Math.max(
      ...studiesDate.map((study) => getDateTimestamp(study.Created!.At!)),
      0,
    );
  }

  const reportsDate = report && report.Created?.At;

  if (reportsDate) {
    return getDateTimestamp(reportsDate);
  }

  return undefined;
};

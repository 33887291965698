/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.billing_new';

export enum ServiceType {
  ServiceTypeInvalidValue = 0,
  ServiceType_Upload_CBCT = 1,
  ServiceType_Upload_IOXRay = 2,
  ServiceType_Upload_Panorama = 3,
  ServiceType_Upload_STL = 4,
  ServiceType_Upload_DentalPhoto = 5,
  ServiceType_Upload_Documents = 6,
  ServiceType_Order_CBCT_GP_Analysis = 100,
  ServiceType_Order_CBCT_Endodontic_Analysis = 101,
  ServiceType_Order_CBCT_Implantology_Analysis = 102,
  ServiceType_Order_CBCT_ThirdMolar_Analysis = 103,
  ServiceType_Order_CBCT_STL_Analysis = 104,
  ServiceType_Order_CBCT_Orthodontic_Analysis = 105,
  ServiceType_Order_CBCT_IOS_Superimposition_Analysis = 106,
  ServiceType_Order_IOS_Segmentation_Analysis = 107,
  ServiceType_Order_IOXRay_GP_Analysis = 108,
  ServiceType_Order_Panoramic_GP_Analysis = 109,
  ServiceType_Order_DentalPhoto_GP_Analysis = 110,
  ServiceType_Order_ImplantStudio_Analysis = 111,
  ServiceType_Order_OrthoStudio_Analysis = 112,
  ServiceType_Enable_CollaborationTool = 200,
  ServiceType_Enable_STLViewer = 201,
  ServiceType_Enable_DesktopApp = 202,
  UNRECOGNIZED = -1,
}

export function serviceTypeFromJSON(object: any): ServiceType {
  switch (object) {
    case 0:
    case 'ServiceTypeInvalidValue':
      return ServiceType.ServiceTypeInvalidValue;
    case 1:
    case 'ServiceType_Upload_CBCT':
      return ServiceType.ServiceType_Upload_CBCT;
    case 2:
    case 'ServiceType_Upload_IOXRay':
      return ServiceType.ServiceType_Upload_IOXRay;
    case 3:
    case 'ServiceType_Upload_Panorama':
      return ServiceType.ServiceType_Upload_Panorama;
    case 4:
    case 'ServiceType_Upload_STL':
      return ServiceType.ServiceType_Upload_STL;
    case 5:
    case 'ServiceType_Upload_DentalPhoto':
      return ServiceType.ServiceType_Upload_DentalPhoto;
    case 6:
    case 'ServiceType_Upload_Documents':
      return ServiceType.ServiceType_Upload_Documents;
    case 100:
    case 'ServiceType_Order_CBCT_GP_Analysis':
      return ServiceType.ServiceType_Order_CBCT_GP_Analysis;
    case 101:
    case 'ServiceType_Order_CBCT_Endodontic_Analysis':
      return ServiceType.ServiceType_Order_CBCT_Endodontic_Analysis;
    case 102:
    case 'ServiceType_Order_CBCT_Implantology_Analysis':
      return ServiceType.ServiceType_Order_CBCT_Implantology_Analysis;
    case 103:
    case 'ServiceType_Order_CBCT_ThirdMolar_Analysis':
      return ServiceType.ServiceType_Order_CBCT_ThirdMolar_Analysis;
    case 104:
    case 'ServiceType_Order_CBCT_STL_Analysis':
      return ServiceType.ServiceType_Order_CBCT_STL_Analysis;
    case 105:
    case 'ServiceType_Order_CBCT_Orthodontic_Analysis':
      return ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis;
    case 106:
    case 'ServiceType_Order_CBCT_IOS_Superimposition_Analysis':
      return ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis;
    case 107:
    case 'ServiceType_Order_IOS_Segmentation_Analysis':
      return ServiceType.ServiceType_Order_IOS_Segmentation_Analysis;
    case 108:
    case 'ServiceType_Order_IOXRay_GP_Analysis':
      return ServiceType.ServiceType_Order_IOXRay_GP_Analysis;
    case 109:
    case 'ServiceType_Order_Panoramic_GP_Analysis':
      return ServiceType.ServiceType_Order_Panoramic_GP_Analysis;
    case 110:
    case 'ServiceType_Order_DentalPhoto_GP_Analysis':
      return ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis;
    case 111:
    case 'ServiceType_Order_ImplantStudio_Analysis':
      return ServiceType.ServiceType_Order_ImplantStudio_Analysis;
    case 112:
    case 'ServiceType_Order_OrthoStudio_Analysis':
      return ServiceType.ServiceType_Order_OrthoStudio_Analysis;
    case 200:
    case 'ServiceType_Enable_CollaborationTool':
      return ServiceType.ServiceType_Enable_CollaborationTool;
    case 201:
    case 'ServiceType_Enable_STLViewer':
      return ServiceType.ServiceType_Enable_STLViewer;
    case 202:
    case 'ServiceType_Enable_DesktopApp':
      return ServiceType.ServiceType_Enable_DesktopApp;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ServiceType.UNRECOGNIZED;
  }
}

export function serviceTypeToJSON(object: ServiceType): string {
  switch (object) {
    case ServiceType.ServiceTypeInvalidValue:
      return 'ServiceTypeInvalidValue';
    case ServiceType.ServiceType_Upload_CBCT:
      return 'ServiceType_Upload_CBCT';
    case ServiceType.ServiceType_Upload_IOXRay:
      return 'ServiceType_Upload_IOXRay';
    case ServiceType.ServiceType_Upload_Panorama:
      return 'ServiceType_Upload_Panorama';
    case ServiceType.ServiceType_Upload_STL:
      return 'ServiceType_Upload_STL';
    case ServiceType.ServiceType_Upload_DentalPhoto:
      return 'ServiceType_Upload_DentalPhoto';
    case ServiceType.ServiceType_Upload_Documents:
      return 'ServiceType_Upload_Documents';
    case ServiceType.ServiceType_Order_CBCT_GP_Analysis:
      return 'ServiceType_Order_CBCT_GP_Analysis';
    case ServiceType.ServiceType_Order_CBCT_Endodontic_Analysis:
      return 'ServiceType_Order_CBCT_Endodontic_Analysis';
    case ServiceType.ServiceType_Order_CBCT_Implantology_Analysis:
      return 'ServiceType_Order_CBCT_Implantology_Analysis';
    case ServiceType.ServiceType_Order_CBCT_ThirdMolar_Analysis:
      return 'ServiceType_Order_CBCT_ThirdMolar_Analysis';
    case ServiceType.ServiceType_Order_CBCT_STL_Analysis:
      return 'ServiceType_Order_CBCT_STL_Analysis';
    case ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis:
      return 'ServiceType_Order_CBCT_Orthodontic_Analysis';
    case ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis:
      return 'ServiceType_Order_CBCT_IOS_Superimposition_Analysis';
    case ServiceType.ServiceType_Order_IOS_Segmentation_Analysis:
      return 'ServiceType_Order_IOS_Segmentation_Analysis';
    case ServiceType.ServiceType_Order_IOXRay_GP_Analysis:
      return 'ServiceType_Order_IOXRay_GP_Analysis';
    case ServiceType.ServiceType_Order_Panoramic_GP_Analysis:
      return 'ServiceType_Order_Panoramic_GP_Analysis';
    case ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis:
      return 'ServiceType_Order_DentalPhoto_GP_Analysis';
    case ServiceType.ServiceType_Order_ImplantStudio_Analysis:
      return 'ServiceType_Order_ImplantStudio_Analysis';
    case ServiceType.ServiceType_Order_OrthoStudio_Analysis:
      return 'ServiceType_Order_OrthoStudio_Analysis';
    case ServiceType.ServiceType_Enable_CollaborationTool:
      return 'ServiceType_Enable_CollaborationTool';
    case ServiceType.ServiceType_Enable_STLViewer:
      return 'ServiceType_Enable_STLViewer';
    case ServiceType.ServiceType_Enable_DesktopApp:
      return 'ServiceType_Enable_DesktopApp';
    case ServiceType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ServiceGroupName {
  ServiceGroupNameInvalidValue = 0,
  ServiceGroupNameEmpty = 1,
  ServiceGroupNameAnalysis3D = 2,
  ServiceGroupNameAnalysis2D = 3,
  ServiceGroupNameUpload3D = 4,
  ServiceGroupNameUpload2D = 5,
  UNRECOGNIZED = -1,
}

export function serviceGroupNameFromJSON(object: any): ServiceGroupName {
  switch (object) {
    case 0:
    case 'ServiceGroupNameInvalidValue':
      return ServiceGroupName.ServiceGroupNameInvalidValue;
    case 1:
    case 'ServiceGroupNameEmpty':
      return ServiceGroupName.ServiceGroupNameEmpty;
    case 2:
    case 'ServiceGroupNameAnalysis3D':
      return ServiceGroupName.ServiceGroupNameAnalysis3D;
    case 3:
    case 'ServiceGroupNameAnalysis2D':
      return ServiceGroupName.ServiceGroupNameAnalysis2D;
    case 4:
    case 'ServiceGroupNameUpload3D':
      return ServiceGroupName.ServiceGroupNameUpload3D;
    case 5:
    case 'ServiceGroupNameUpload2D':
      return ServiceGroupName.ServiceGroupNameUpload2D;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ServiceGroupName.UNRECOGNIZED;
  }
}

export function serviceGroupNameToJSON(object: ServiceGroupName): string {
  switch (object) {
    case ServiceGroupName.ServiceGroupNameInvalidValue:
      return 'ServiceGroupNameInvalidValue';
    case ServiceGroupName.ServiceGroupNameEmpty:
      return 'ServiceGroupNameEmpty';
    case ServiceGroupName.ServiceGroupNameAnalysis3D:
      return 'ServiceGroupNameAnalysis3D';
    case ServiceGroupName.ServiceGroupNameAnalysis2D:
      return 'ServiceGroupNameAnalysis2D';
    case ServiceGroupName.ServiceGroupNameUpload3D:
      return 'ServiceGroupNameUpload3D';
    case ServiceGroupName.ServiceGroupNameUpload2D:
      return 'ServiceGroupNameUpload2D';
    case ServiceGroupName.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Service {
  Types: ServiceType[];
  /** `Group`: [optional]: If ServiceType has more then one element, then group name should be specified. */
  Group: ServiceGroupName;
  Visible: boolean;
  Countable: Service_CountableKind | undefined;
  Enabler: Service_EnablerKind | undefined;
}

export interface Service_CountableKind {
  Limit: number;
  Leftover: number;
}

export interface Service_EnablerKind {}

function createBaseService(): Service {
  return {
    Types: [],
    Group: 0,
    Visible: false,
    Countable: undefined,
    Enabler: undefined,
  };
}

export const Service = {
  encode(
    message: Service,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.Types) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.Group !== 0) {
      writer.uint32(16).int32(message.Group);
    }
    if (message.Visible === true) {
      writer.uint32(24).bool(message.Visible);
    }
    if (message.Countable !== undefined) {
      Service_CountableKind.encode(
        message.Countable,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.Enabler !== undefined) {
      Service_EnablerKind.encode(
        message.Enabler,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Service {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Types.push(reader.int32() as any);
            }
          } else {
            message.Types.push(reader.int32() as any);
          }
          break;
        case 2:
          message.Group = reader.int32() as any;
          break;
        case 3:
          message.Visible = reader.bool();
          break;
        case 4:
          message.Countable = Service_CountableKind.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.Enabler = Service_EnablerKind.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Service {
    return {
      Types: Array.isArray(object?.Types)
        ? object.Types.map((e: any) => serviceTypeFromJSON(e))
        : [],
      Group: isSet(object.Group) ? serviceGroupNameFromJSON(object.Group) : 0,
      Visible: isSet(object.Visible) ? Boolean(object.Visible) : false,
      Countable: isSet(object.Countable)
        ? Service_CountableKind.fromJSON(object.Countable)
        : undefined,
      Enabler: isSet(object.Enabler)
        ? Service_EnablerKind.fromJSON(object.Enabler)
        : undefined,
    };
  },

  toJSON(message: Service): unknown {
    const obj: any = {};
    if (message.Types) {
      obj.Types = message.Types.map((e) => serviceTypeToJSON(e));
    } else {
      obj.Types = [];
    }
    message.Group !== undefined &&
      (obj.Group = serviceGroupNameToJSON(message.Group));
    message.Visible !== undefined && (obj.Visible = message.Visible);
    message.Countable !== undefined &&
      (obj.Countable = message.Countable
        ? Service_CountableKind.toJSON(message.Countable)
        : undefined);
    message.Enabler !== undefined &&
      (obj.Enabler = message.Enabler
        ? Service_EnablerKind.toJSON(message.Enabler)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Service>, I>>(object: I): Service {
    const message = createBaseService();
    message.Types = object.Types?.map((e) => e) || [];
    message.Group = object.Group ?? 0;
    message.Visible = object.Visible ?? false;
    message.Countable =
      object.Countable !== undefined && object.Countable !== null
        ? Service_CountableKind.fromPartial(object.Countable)
        : undefined;
    message.Enabler =
      object.Enabler !== undefined && object.Enabler !== null
        ? Service_EnablerKind.fromPartial(object.Enabler)
        : undefined;
    return message;
  },
};

function createBaseService_CountableKind(): Service_CountableKind {
  return { Limit: 0, Leftover: 0 };
}

export const Service_CountableKind = {
  encode(
    message: Service_CountableKind,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Limit !== 0) {
      writer.uint32(8).int64(message.Limit);
    }
    if (message.Leftover !== 0) {
      writer.uint32(16).int64(message.Leftover);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Service_CountableKind {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseService_CountableKind();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Limit = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.Leftover = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Service_CountableKind {
    return {
      Limit: isSet(object.Limit) ? Number(object.Limit) : 0,
      Leftover: isSet(object.Leftover) ? Number(object.Leftover) : 0,
    };
  },

  toJSON(message: Service_CountableKind): unknown {
    const obj: any = {};
    message.Limit !== undefined && (obj.Limit = Math.round(message.Limit));
    message.Leftover !== undefined &&
      (obj.Leftover = Math.round(message.Leftover));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Service_CountableKind>, I>>(
    object: I,
  ): Service_CountableKind {
    const message = createBaseService_CountableKind();
    message.Limit = object.Limit ?? 0;
    message.Leftover = object.Leftover ?? 0;
    return message;
  },
};

function createBaseService_EnablerKind(): Service_EnablerKind {
  return {};
}

export const Service_EnablerKind = {
  encode(
    _: Service_EnablerKind,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Service_EnablerKind {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseService_EnablerKind();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): Service_EnablerKind {
    return {};
  },

  toJSON(_: Service_EnablerKind): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Service_EnablerKind>, I>>(
    _: I,
  ): Service_EnablerKind {
    const message = createBaseService_EnablerKind();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

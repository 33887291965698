import React from 'react';

import { TWO_D_REPORT_RIGHT_COLUMN_ID } from '../..';

import styles from './ReportLayout.module.scss';

type BasicProps = {
  children: React.ReactNode;
};

export const Container = ({ children }: BasicProps) => {
  return <div className={styles.container}>{children}</div>;
};

export const Main = ({ children }: BasicProps) => (
  <main className={styles.main}>{children}</main>
);

export const LeftColumn = ({ children }: BasicProps) => (
  <div className={styles.leftColumn}>{children}</div>
);

export const RightColumn = ({ children }: BasicProps) => {
  return (
    <div id={TWO_D_REPORT_RIGHT_COLUMN_ID} className={styles.rightColumn}>
      {children}
    </div>
  );
};

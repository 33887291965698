/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Empty } from '../google/protobuf/empty';

export const protobufPackage = 'com.diagnocat.model';

export enum CBCTSectionOrientation {
  SectionOrientationInvalidValue = 0,
  /** SectionOrientationAxial - Axial */
  SectionOrientationAxial = 1,
  /** SectionOrientationVestibuloOral - Coronal || Frontal */
  SectionOrientationVestibuloOral = 2,
  /** SectionOrientationMesioDistal - Sagittal */
  SectionOrientationMesioDistal = 3,
  UNRECOGNIZED = -1,
}

export function cBCTSectionOrientationFromJSON(
  object: any,
): CBCTSectionOrientation {
  switch (object) {
    case 0:
    case 'SectionOrientationInvalidValue':
      return CBCTSectionOrientation.SectionOrientationInvalidValue;
    case 1:
    case 'SectionOrientationAxial':
      return CBCTSectionOrientation.SectionOrientationAxial;
    case 2:
    case 'SectionOrientationVestibuloOral':
      return CBCTSectionOrientation.SectionOrientationVestibuloOral;
    case 3:
    case 'SectionOrientationMesioDistal':
      return CBCTSectionOrientation.SectionOrientationMesioDistal;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTSectionOrientation.UNRECOGNIZED;
  }
}

export function cBCTSectionOrientationToJSON(
  object: CBCTSectionOrientation,
): string {
  switch (object) {
    case CBCTSectionOrientation.SectionOrientationInvalidValue:
      return 'SectionOrientationInvalidValue';
    case CBCTSectionOrientation.SectionOrientationAxial:
      return 'SectionOrientationAxial';
    case CBCTSectionOrientation.SectionOrientationVestibuloOral:
      return 'SectionOrientationVestibuloOral';
    case CBCTSectionOrientation.SectionOrientationMesioDistal:
      return 'SectionOrientationMesioDistal';
    case CBCTSectionOrientation.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum CBCTSliceOrientation {
  SliceOrientationInvalidValue = 0,
  /** SliceOrientationMesial - previously "M" */
  SliceOrientationMesial = 1,
  /** SliceOrientationDistal - previously "D" */
  SliceOrientationDistal = 2,
  /** SliceOrientationLingual - previously "L" */
  SliceOrientationLingual = 3,
  /** SliceOrientationBuccal - previously "B" */
  SliceOrientationBuccal = 4,
  UNRECOGNIZED = -1,
}

export function cBCTSliceOrientationFromJSON(
  object: any,
): CBCTSliceOrientation {
  switch (object) {
    case 0:
    case 'SliceOrientationInvalidValue':
      return CBCTSliceOrientation.SliceOrientationInvalidValue;
    case 1:
    case 'SliceOrientationMesial':
      return CBCTSliceOrientation.SliceOrientationMesial;
    case 2:
    case 'SliceOrientationDistal':
      return CBCTSliceOrientation.SliceOrientationDistal;
    case 3:
    case 'SliceOrientationLingual':
      return CBCTSliceOrientation.SliceOrientationLingual;
    case 4:
    case 'SliceOrientationBuccal':
      return CBCTSliceOrientation.SliceOrientationBuccal;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTSliceOrientation.UNRECOGNIZED;
  }
}

export function cBCTSliceOrientationToJSON(
  object: CBCTSliceOrientation,
): string {
  switch (object) {
    case CBCTSliceOrientation.SliceOrientationInvalidValue:
      return 'SliceOrientationInvalidValue';
    case CBCTSliceOrientation.SliceOrientationMesial:
      return 'SliceOrientationMesial';
    case CBCTSliceOrientation.SliceOrientationDistal:
      return 'SliceOrientationDistal';
    case CBCTSliceOrientation.SliceOrientationLingual:
      return 'SliceOrientationLingual';
    case CBCTSliceOrientation.SliceOrientationBuccal:
      return 'SliceOrientationBuccal';
    case CBCTSliceOrientation.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface File {
  ID: string;
  Path: string;
  Size: number;
  MIME: string;
  CompressSize: number;
  NoMetadata: Empty | undefined;
  Image: FileMetadataImage | undefined;
}

export interface FileMetadataImage {
  Width: number;
  /** TODO */
  Height: number;
}

/**
 * generated by backend if not emitted by pipelines, otherwise by pipelines
 * specification: https://diagnocat.atlassian.net/l/cp/c09mQHCk
 */
export interface DerivedDataImage {
  Thumbnail: File | undefined;
  Preview: File | undefined;
}

/**
 * generated by backend if not emitted by pipelines, otherwise by pipelines
 * specification: https://diagnocat.atlassian.net/l/cp/c09mQHCk
 */
export interface DerivedData3DModel {
  FrontalPreview: File | undefined;
  LeftPreview: File | undefined;
  RightPreview: File | undefined;
  FrontalThumbnail: File | undefined;
  LeftThumbnail: File | undefined;
  RightThumbnail: File | undefined;
  RotatingPreview: File | undefined;
}

function createBaseFile(): File {
  return {
    ID: '',
    Path: '',
    Size: 0,
    MIME: '',
    CompressSize: 0,
    NoMetadata: undefined,
    Image: undefined,
  };
}

export const File = {
  encode(message: File, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Path !== '') {
      writer.uint32(18).string(message.Path);
    }
    if (message.Size !== 0) {
      writer.uint32(24).int64(message.Size);
    }
    if (message.MIME !== '') {
      writer.uint32(34).string(message.MIME);
    }
    if (message.CompressSize !== 0) {
      writer.uint32(40).int64(message.CompressSize);
    }
    if (message.NoMetadata !== undefined) {
      Empty.encode(message.NoMetadata, writer.uint32(810).fork()).ldelim();
    }
    if (message.Image !== undefined) {
      FileMetadataImage.encode(
        message.Image,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): File {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Path = reader.string();
          break;
        case 3:
          message.Size = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.MIME = reader.string();
          break;
        case 5:
          message.CompressSize = longToNumber(reader.int64() as Long);
          break;
        case 101:
          message.NoMetadata = Empty.decode(reader, reader.uint32());
          break;
        case 102:
          message.Image = FileMetadataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): File {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Path: isSet(object.Path) ? String(object.Path) : '',
      Size: isSet(object.Size) ? Number(object.Size) : 0,
      MIME: isSet(object.MIME) ? String(object.MIME) : '',
      CompressSize: isSet(object.CompressSize)
        ? Number(object.CompressSize)
        : 0,
      NoMetadata: isSet(object.NoMetadata)
        ? Empty.fromJSON(object.NoMetadata)
        : undefined,
      Image: isSet(object.Image)
        ? FileMetadataImage.fromJSON(object.Image)
        : undefined,
    };
  },

  toJSON(message: File): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Path !== undefined && (obj.Path = message.Path);
    message.Size !== undefined && (obj.Size = Math.round(message.Size));
    message.MIME !== undefined && (obj.MIME = message.MIME);
    message.CompressSize !== undefined &&
      (obj.CompressSize = Math.round(message.CompressSize));
    message.NoMetadata !== undefined &&
      (obj.NoMetadata = message.NoMetadata
        ? Empty.toJSON(message.NoMetadata)
        : undefined);
    message.Image !== undefined &&
      (obj.Image = message.Image
        ? FileMetadataImage.toJSON(message.Image)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<File>, I>>(object: I): File {
    const message = createBaseFile();
    message.ID = object.ID ?? '';
    message.Path = object.Path ?? '';
    message.Size = object.Size ?? 0;
    message.MIME = object.MIME ?? '';
    message.CompressSize = object.CompressSize ?? 0;
    message.NoMetadata =
      object.NoMetadata !== undefined && object.NoMetadata !== null
        ? Empty.fromPartial(object.NoMetadata)
        : undefined;
    message.Image =
      object.Image !== undefined && object.Image !== null
        ? FileMetadataImage.fromPartial(object.Image)
        : undefined;
    return message;
  },
};

function createBaseFileMetadataImage(): FileMetadataImage {
  return { Width: 0, Height: 0 };
}

export const FileMetadataImage = {
  encode(
    message: FileMetadataImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Width !== 0) {
      writer.uint32(8).int64(message.Width);
    }
    if (message.Height !== 0) {
      writer.uint32(16).int64(message.Height);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FileMetadataImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileMetadataImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Width = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.Height = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FileMetadataImage {
    return {
      Width: isSet(object.Width) ? Number(object.Width) : 0,
      Height: isSet(object.Height) ? Number(object.Height) : 0,
    };
  },

  toJSON(message: FileMetadataImage): unknown {
    const obj: any = {};
    message.Width !== undefined && (obj.Width = Math.round(message.Width));
    message.Height !== undefined && (obj.Height = Math.round(message.Height));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FileMetadataImage>, I>>(
    object: I,
  ): FileMetadataImage {
    const message = createBaseFileMetadataImage();
    message.Width = object.Width ?? 0;
    message.Height = object.Height ?? 0;
    return message;
  },
};

function createBaseDerivedDataImage(): DerivedDataImage {
  return { Thumbnail: undefined, Preview: undefined };
}

export const DerivedDataImage = {
  encode(
    message: DerivedDataImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Thumbnail !== undefined) {
      File.encode(message.Thumbnail, writer.uint32(10).fork()).ldelim();
    }
    if (message.Preview !== undefined) {
      File.encode(message.Preview, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DerivedDataImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDerivedDataImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Thumbnail = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Preview = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DerivedDataImage {
    return {
      Thumbnail: isSet(object.Thumbnail)
        ? File.fromJSON(object.Thumbnail)
        : undefined,
      Preview: isSet(object.Preview)
        ? File.fromJSON(object.Preview)
        : undefined,
    };
  },

  toJSON(message: DerivedDataImage): unknown {
    const obj: any = {};
    message.Thumbnail !== undefined &&
      (obj.Thumbnail = message.Thumbnail
        ? File.toJSON(message.Thumbnail)
        : undefined);
    message.Preview !== undefined &&
      (obj.Preview = message.Preview
        ? File.toJSON(message.Preview)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DerivedDataImage>, I>>(
    object: I,
  ): DerivedDataImage {
    const message = createBaseDerivedDataImage();
    message.Thumbnail =
      object.Thumbnail !== undefined && object.Thumbnail !== null
        ? File.fromPartial(object.Thumbnail)
        : undefined;
    message.Preview =
      object.Preview !== undefined && object.Preview !== null
        ? File.fromPartial(object.Preview)
        : undefined;
    return message;
  },
};

function createBaseDerivedData3DModel(): DerivedData3DModel {
  return {
    FrontalPreview: undefined,
    LeftPreview: undefined,
    RightPreview: undefined,
    FrontalThumbnail: undefined,
    LeftThumbnail: undefined,
    RightThumbnail: undefined,
    RotatingPreview: undefined,
  };
}

export const DerivedData3DModel = {
  encode(
    message: DerivedData3DModel,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FrontalPreview !== undefined) {
      File.encode(message.FrontalPreview, writer.uint32(10).fork()).ldelim();
    }
    if (message.LeftPreview !== undefined) {
      File.encode(message.LeftPreview, writer.uint32(18).fork()).ldelim();
    }
    if (message.RightPreview !== undefined) {
      File.encode(message.RightPreview, writer.uint32(26).fork()).ldelim();
    }
    if (message.FrontalThumbnail !== undefined) {
      File.encode(message.FrontalThumbnail, writer.uint32(34).fork()).ldelim();
    }
    if (message.LeftThumbnail !== undefined) {
      File.encode(message.LeftThumbnail, writer.uint32(42).fork()).ldelim();
    }
    if (message.RightThumbnail !== undefined) {
      File.encode(message.RightThumbnail, writer.uint32(50).fork()).ldelim();
    }
    if (message.RotatingPreview !== undefined) {
      File.encode(message.RotatingPreview, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DerivedData3DModel {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDerivedData3DModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FrontalPreview = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.LeftPreview = File.decode(reader, reader.uint32());
          break;
        case 3:
          message.RightPreview = File.decode(reader, reader.uint32());
          break;
        case 4:
          message.FrontalThumbnail = File.decode(reader, reader.uint32());
          break;
        case 5:
          message.LeftThumbnail = File.decode(reader, reader.uint32());
          break;
        case 6:
          message.RightThumbnail = File.decode(reader, reader.uint32());
          break;
        case 7:
          message.RotatingPreview = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DerivedData3DModel {
    return {
      FrontalPreview: isSet(object.FrontalPreview)
        ? File.fromJSON(object.FrontalPreview)
        : undefined,
      LeftPreview: isSet(object.LeftPreview)
        ? File.fromJSON(object.LeftPreview)
        : undefined,
      RightPreview: isSet(object.RightPreview)
        ? File.fromJSON(object.RightPreview)
        : undefined,
      FrontalThumbnail: isSet(object.FrontalThumbnail)
        ? File.fromJSON(object.FrontalThumbnail)
        : undefined,
      LeftThumbnail: isSet(object.LeftThumbnail)
        ? File.fromJSON(object.LeftThumbnail)
        : undefined,
      RightThumbnail: isSet(object.RightThumbnail)
        ? File.fromJSON(object.RightThumbnail)
        : undefined,
      RotatingPreview: isSet(object.RotatingPreview)
        ? File.fromJSON(object.RotatingPreview)
        : undefined,
    };
  },

  toJSON(message: DerivedData3DModel): unknown {
    const obj: any = {};
    message.FrontalPreview !== undefined &&
      (obj.FrontalPreview = message.FrontalPreview
        ? File.toJSON(message.FrontalPreview)
        : undefined);
    message.LeftPreview !== undefined &&
      (obj.LeftPreview = message.LeftPreview
        ? File.toJSON(message.LeftPreview)
        : undefined);
    message.RightPreview !== undefined &&
      (obj.RightPreview = message.RightPreview
        ? File.toJSON(message.RightPreview)
        : undefined);
    message.FrontalThumbnail !== undefined &&
      (obj.FrontalThumbnail = message.FrontalThumbnail
        ? File.toJSON(message.FrontalThumbnail)
        : undefined);
    message.LeftThumbnail !== undefined &&
      (obj.LeftThumbnail = message.LeftThumbnail
        ? File.toJSON(message.LeftThumbnail)
        : undefined);
    message.RightThumbnail !== undefined &&
      (obj.RightThumbnail = message.RightThumbnail
        ? File.toJSON(message.RightThumbnail)
        : undefined);
    message.RotatingPreview !== undefined &&
      (obj.RotatingPreview = message.RotatingPreview
        ? File.toJSON(message.RotatingPreview)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DerivedData3DModel>, I>>(
    object: I,
  ): DerivedData3DModel {
    const message = createBaseDerivedData3DModel();
    message.FrontalPreview =
      object.FrontalPreview !== undefined && object.FrontalPreview !== null
        ? File.fromPartial(object.FrontalPreview)
        : undefined;
    message.LeftPreview =
      object.LeftPreview !== undefined && object.LeftPreview !== null
        ? File.fromPartial(object.LeftPreview)
        : undefined;
    message.RightPreview =
      object.RightPreview !== undefined && object.RightPreview !== null
        ? File.fromPartial(object.RightPreview)
        : undefined;
    message.FrontalThumbnail =
      object.FrontalThumbnail !== undefined && object.FrontalThumbnail !== null
        ? File.fromPartial(object.FrontalThumbnail)
        : undefined;
    message.LeftThumbnail =
      object.LeftThumbnail !== undefined && object.LeftThumbnail !== null
        ? File.fromPartial(object.LeftThumbnail)
        : undefined;
    message.RightThumbnail =
      object.RightThumbnail !== undefined && object.RightThumbnail !== null
        ? File.fromPartial(object.RightThumbnail)
        : undefined;
    message.RotatingPreview =
      object.RotatingPreview !== undefined && object.RotatingPreview !== null
        ? File.fromPartial(object.RotatingPreview)
        : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

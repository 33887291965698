/* eslint-disable */

import amplitude from 'amplitude-js';
import { ENVIRONMENT } from '../config';

amplitude.getInstance().init('062ae4c1297e85cac2c2ef7a8536b4bd');

const amplitudeProject = amplitude.getInstance();

const trackEvent = (eventName: string, properties: any = {}): void => {
  const hostname = window.location.hostname;
  const page = (window as unknown as any).currentPage;

  if (amplitudeProject) {
    amplitudeProject.logEvent(eventName, {
      ...properties,
      hostname,
      env: ENVIRONMENT,
      page,
    });
  }

  if ((window as any).ga) {
    const gaEventObj = {
      hitType: 'event',
      eventAction: eventName,
      ...properties,
      hostname,
      env: ENVIRONMENT,
      page,
    };

    (window as any).ga('send', gaEventObj);
  }
};

const trackPageView = (pageName: string, parameters: any = {}): void => {
  (window as any).currentPage = pageName;
  const hostname = (window as any).location.hostname;

  if (amplitudeProject) {
    amplitudeProject.logEvent(pageName, {
      ...parameters,
      hostname,
      env: ENVIRONMENT,
      page: pageName,
    });
  }

  if ((window as any).ga) {
    const gaEventObj = {
      hitType: 'pageview',
      ...parameters,
      hostname,
      env: ENVIRONMENT,
      page: pageName,
    };

    (window as any).ga('send', gaEventObj);
  }
};

const identify = (userId: string, email: string): void => {
  const amplitudeIdentify = new amplitude.Identify()
    .set('userId', userId)
    .set('email', email);
  amplitudeProject.identify(amplitudeIdentify);
};

export { trackEvent, trackPageView, identify };

/* eslint-enable */

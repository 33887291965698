/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { ReportCBCTIOSSegment } from '../model/dto_report_common';
import { SegmentronPhotoIOSSuperimposition } from '../model/dto_report_segmentron_common';
import { ToothLandmark } from '../model/dto_report_landmark';
import { ReportStudioToothPosition } from '../model/dto_report_type_studio_common';
import { ToothPeriodontalStatus } from '../model/dto_report_periodontal_status';

export const protobufPackage = 'com.diagnocat.model';

export enum ReportCBCTIOSSuperimpositionOrientation {
  /** CBCT_IOS_Superimposition_Orientation_CBCT - all models will be in CBCT coordinates. In this case, IOS Mandible will change its position relative to IOS Maxilla, so any occlusion information in IOS will be lost */
  CBCT_IOS_Superimposition_Orientation_CBCT = 0,
  /** CBCT_IOS_Superimposition_Orientation_IOS - all models will be in IOS coordinates. Occlusion information in IOS will be preserved. Models will not be aligned to CBCT when opened in the viewer */
  CBCT_IOS_Superimposition_Orientation_IOS = 1,
  /** CBCT_IOS_Superimposition_Orientation_Zero_index - will align to CBCT but ignore the original DICOM origin coordinate offset (can be helpful for opening in some software, such as Blue Sky Plan) */
  CBCT_IOS_Superimposition_Orientation_Zero_index = 2,
  UNRECOGNIZED = -1,
}

export function reportCBCTIOSSuperimpositionOrientationFromJSON(
  object: any,
): ReportCBCTIOSSuperimpositionOrientation {
  switch (object) {
    case 0:
    case 'CBCT_IOS_Superimposition_Orientation_CBCT':
      return ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_CBCT;
    case 1:
    case 'CBCT_IOS_Superimposition_Orientation_IOS':
      return ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_IOS;
    case 2:
    case 'CBCT_IOS_Superimposition_Orientation_Zero_index':
      return ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_Zero_index;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportCBCTIOSSuperimpositionOrientation.UNRECOGNIZED;
  }
}

export function reportCBCTIOSSuperimpositionOrientationToJSON(
  object: ReportCBCTIOSSuperimpositionOrientation,
): string {
  switch (object) {
    case ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_CBCT:
      return 'CBCT_IOS_Superimposition_Orientation_CBCT';
    case ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_IOS:
      return 'CBCT_IOS_Superimposition_Orientation_IOS';
    case ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_Zero_index:
      return 'CBCT_IOS_Superimposition_Orientation_Zero_index';
    case ReportCBCTIOSSuperimpositionOrientation.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ReportCBCTIOSSuperimposition {
  /** set from request */
  Orientation: ReportCBCTIOSSuperimpositionOrientation;
  AlignmentSuccessful: boolean;
  Segments: ReportCBCTIOSSegment[];
  PhotoIOSSuperimpositions: SegmentronPhotoIOSSuperimposition[];
  TeethLandmarks: ToothLandmark[];
  TeethPositions: ReportStudioToothPosition[];
  TeethPeriodontalStatuses: ToothPeriodontalStatus[];
}

function createBaseReportCBCTIOSSuperimposition(): ReportCBCTIOSSuperimposition {
  return {
    Orientation: 0,
    AlignmentSuccessful: false,
    Segments: [],
    PhotoIOSSuperimpositions: [],
    TeethLandmarks: [],
    TeethPositions: [],
    TeethPeriodontalStatuses: [],
  };
}

export const ReportCBCTIOSSuperimposition = {
  encode(
    message: ReportCBCTIOSSuperimposition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Orientation !== 0) {
      writer.uint32(8).int32(message.Orientation);
    }
    if (message.AlignmentSuccessful === true) {
      writer.uint32(16).bool(message.AlignmentSuccessful);
    }
    for (const v of message.Segments) {
      ReportCBCTIOSSegment.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.PhotoIOSSuperimpositions) {
      SegmentronPhotoIOSSuperimposition.encode(
        v!,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    for (const v of message.TeethLandmarks) {
      ToothLandmark.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.TeethPositions) {
      ReportStudioToothPosition.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.TeethPeriodontalStatuses) {
      ToothPeriodontalStatus.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTIOSSuperimposition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTIOSSuperimposition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Orientation = reader.int32() as any;
          break;
        case 2:
          message.AlignmentSuccessful = reader.bool();
          break;
        case 3:
          message.Segments.push(
            ReportCBCTIOSSegment.decode(reader, reader.uint32()),
          );
          break;
        case 4:
          message.PhotoIOSSuperimpositions.push(
            SegmentronPhotoIOSSuperimposition.decode(reader, reader.uint32()),
          );
          break;
        case 5:
          message.TeethLandmarks.push(
            ToothLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 6:
          message.TeethPositions.push(
            ReportStudioToothPosition.decode(reader, reader.uint32()),
          );
          break;
        case 7:
          message.TeethPeriodontalStatuses.push(
            ToothPeriodontalStatus.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTIOSSuperimposition {
    return {
      Orientation: isSet(object.Orientation)
        ? reportCBCTIOSSuperimpositionOrientationFromJSON(object.Orientation)
        : 0,
      AlignmentSuccessful: isSet(object.AlignmentSuccessful)
        ? Boolean(object.AlignmentSuccessful)
        : false,
      Segments: Array.isArray(object?.Segments)
        ? object.Segments.map((e: any) => ReportCBCTIOSSegment.fromJSON(e))
        : [],
      PhotoIOSSuperimpositions: Array.isArray(object?.PhotoIOSSuperimpositions)
        ? object.PhotoIOSSuperimpositions.map((e: any) =>
            SegmentronPhotoIOSSuperimposition.fromJSON(e),
          )
        : [],
      TeethLandmarks: Array.isArray(object?.TeethLandmarks)
        ? object.TeethLandmarks.map((e: any) => ToothLandmark.fromJSON(e))
        : [],
      TeethPositions: Array.isArray(object?.TeethPositions)
        ? object.TeethPositions.map((e: any) =>
            ReportStudioToothPosition.fromJSON(e),
          )
        : [],
      TeethPeriodontalStatuses: Array.isArray(object?.TeethPeriodontalStatuses)
        ? object.TeethPeriodontalStatuses.map((e: any) =>
            ToothPeriodontalStatus.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ReportCBCTIOSSuperimposition): unknown {
    const obj: any = {};
    message.Orientation !== undefined &&
      (obj.Orientation = reportCBCTIOSSuperimpositionOrientationToJSON(
        message.Orientation,
      ));
    message.AlignmentSuccessful !== undefined &&
      (obj.AlignmentSuccessful = message.AlignmentSuccessful);
    if (message.Segments) {
      obj.Segments = message.Segments.map((e) =>
        e ? ReportCBCTIOSSegment.toJSON(e) : undefined,
      );
    } else {
      obj.Segments = [];
    }
    if (message.PhotoIOSSuperimpositions) {
      obj.PhotoIOSSuperimpositions = message.PhotoIOSSuperimpositions.map((e) =>
        e ? SegmentronPhotoIOSSuperimposition.toJSON(e) : undefined,
      );
    } else {
      obj.PhotoIOSSuperimpositions = [];
    }
    if (message.TeethLandmarks) {
      obj.TeethLandmarks = message.TeethLandmarks.map((e) =>
        e ? ToothLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.TeethLandmarks = [];
    }
    if (message.TeethPositions) {
      obj.TeethPositions = message.TeethPositions.map((e) =>
        e ? ReportStudioToothPosition.toJSON(e) : undefined,
      );
    } else {
      obj.TeethPositions = [];
    }
    if (message.TeethPeriodontalStatuses) {
      obj.TeethPeriodontalStatuses = message.TeethPeriodontalStatuses.map((e) =>
        e ? ToothPeriodontalStatus.toJSON(e) : undefined,
      );
    } else {
      obj.TeethPeriodontalStatuses = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTIOSSuperimposition>, I>>(
    object: I,
  ): ReportCBCTIOSSuperimposition {
    const message = createBaseReportCBCTIOSSuperimposition();
    message.Orientation = object.Orientation ?? 0;
    message.AlignmentSuccessful = object.AlignmentSuccessful ?? false;
    message.Segments =
      object.Segments?.map((e) => ReportCBCTIOSSegment.fromPartial(e)) || [];
    message.PhotoIOSSuperimpositions =
      object.PhotoIOSSuperimpositions?.map((e) =>
        SegmentronPhotoIOSSuperimposition.fromPartial(e),
      ) || [];
    message.TeethLandmarks =
      object.TeethLandmarks?.map((e) => ToothLandmark.fromPartial(e)) || [];
    message.TeethPositions =
      object.TeethPositions?.map((e) =>
        ReportStudioToothPosition.fromPartial(e),
      ) || [];
    message.TeethPeriodontalStatuses =
      object.TeethPeriodontalStatuses?.map((e) =>
        ToothPeriodontalStatus.fromPartial(e),
      ) || [];
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { FC } from 'react';
import cn from 'classnames';

import { Layout } from '@/shared/ui';

import { Header } from '@/widgets/Header';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';

import styles from './ImplantReport.module.scss';

type ReportProps = {
  className?: string;
};

export const ImplantReport: FC<ReportProps> = (props) => {
  const { className } = props;

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <h1>Implant Page</h1>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <GetFullAccessModal />
    </Layout>
  );
};

import { ToolNames } from '@/shared/config';

export const POPUP_DEFAULT_SIZE = 320;

export const MEDICAL_IMAGE_MAX_SIZE = 600;

export const CONTROLS: ToolNames[] = [
  'ruler',
  'arrow',
  'eraser',
  'brightness',
  'sharpness',
  'angle',
  'reset',
];

export const RASTER_CONTROLS: ToolNames[] = ['brightness', 'reset'];

/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { BrowserHeaders } from 'browser-headers';
import { Timestamp } from '../../google/protobuf/timestamp';
import { PatientTask } from '../../model/dto_patient_task';
import { Patient } from '../../model/dto_patient';

export const protobufPackage = 'com.diagnocat.core';

export interface CreatePatientTaskReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `Title`: [required] */
  Title: string;
  /** `Description`: [optional] */
  Description: string;
  /** `DueDate`: [optional] */
  DueDate: Date | undefined;
  /** `AssigneeIDs`: [optional] */
  AssigneeIDs: string[];
}

export interface CreatePatientTaskResp {
  Task: PatientTask | undefined;
  Patient: Patient | undefined;
}

export interface SetPatientTaskAssigneesReq {
  /** `TaskID`: [required] */
  TaskID: string;
  /** `AssigneeIDs`: [optional] */
  AssigneeIDs: string[];
}

export interface SetPatientTaskAssigneesResp {
  Task: PatientTask | undefined;
  Patient: Patient | undefined;
}

export interface SetPatientTaskTitleReq {
  /** `TaskID`: [required] */
  TaskID: string;
  /** `Title`: [required] */
  Title: string;
}

export interface SetPatientTaskTitleResp {
  Task: PatientTask | undefined;
}

export interface SetPatientTaskDescriptionReq {
  /** `TaskID`: [required] */
  TaskID: string;
  /** `Description`: [optional] */
  Description: string;
}

export interface SetPatientTaskDescriptionResp {
  Task: PatientTask | undefined;
}

export interface SetPatientTaskDueDateReq {
  /** `TaskID`: [required] */
  TaskID: string;
  /** `DueDate`: [optional] */
  DueDate: Date | undefined;
}

export interface SetPatientTaskDueDateResp {
  Task: PatientTask | undefined;
}

export interface ClosePatientTaskReq {
  /** `TaskID`: [required] */
  TaskID: string;
}

export interface ClosePatientTaskResp {
  Task: PatientTask | undefined;
}

export interface ReopenPatientTaskReq {
  /** `TaskID`: [required] */
  TaskID: string;
}

export interface ReopenPatientTaskResp {
  Task: PatientTask | undefined;
}

export interface MarkAsReadPatientTasksReq {
  /** `TaskIDs`: [required] */
  TaskIDs: string[];
}

export interface MarkAsReadPatientTasksResp {
  Tasks: PatientTask[];
}

export interface SetPatientTaskOrderReq {
  /** `TaskID`: [required] */
  TaskID: string;
  /** `Order`: [required] */
  Order: number;
}

export interface SetPatientTaskOrderResp {
  Tasks: PatientTask[];
}

export interface DeletePatientTaskReq {
  /** `TaskID`: [required] */
  TaskID: string;
}

export interface DeletePatientTaskResp {
  /** one: deleted, other: with new Order */
  Tasks: PatientTask[];
}

function createBaseCreatePatientTaskReq(): CreatePatientTaskReq {
  return {
    PatientID: '',
    Title: '',
    Description: '',
    DueDate: undefined,
    AssigneeIDs: [],
  };
}

export const CreatePatientTaskReq = {
  encode(
    message: CreatePatientTaskReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.Title !== '') {
      writer.uint32(18).string(message.Title);
    }
    if (message.Description !== '') {
      writer.uint32(26).string(message.Description);
    }
    if (message.DueDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.DueDate),
        writer.uint32(34).fork(),
      ).ldelim();
    }
    for (const v of message.AssigneeIDs) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreatePatientTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientTaskReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.Title = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.DueDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 5:
          message.AssigneeIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientTaskReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Title: isSet(object.Title) ? String(object.Title) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      DueDate: isSet(object.DueDate)
        ? fromJsonTimestamp(object.DueDate)
        : undefined,
      AssigneeIDs: Array.isArray(object?.AssigneeIDs)
        ? object.AssigneeIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: CreatePatientTaskReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Title !== undefined && (obj.Title = message.Title);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.DueDate !== undefined &&
      (obj.DueDate = message.DueDate.toISOString());
    if (message.AssigneeIDs) {
      obj.AssigneeIDs = message.AssigneeIDs.map((e) => e);
    } else {
      obj.AssigneeIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientTaskReq>, I>>(
    object: I,
  ): CreatePatientTaskReq {
    const message = createBaseCreatePatientTaskReq();
    message.PatientID = object.PatientID ?? '';
    message.Title = object.Title ?? '';
    message.Description = object.Description ?? '';
    message.DueDate = object.DueDate ?? undefined;
    message.AssigneeIDs = object.AssigneeIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreatePatientTaskResp(): CreatePatientTaskResp {
  return { Task: undefined, Patient: undefined };
}

export const CreatePatientTaskResp = {
  encode(
    message: CreatePatientTaskResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      PatientTask.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreatePatientTaskResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientTaskResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = PatientTask.decode(reader, reader.uint32());
          break;
        case 2:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientTaskResp {
    return {
      Task: isSet(object.Task) ? PatientTask.fromJSON(object.Task) : undefined,
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: CreatePatientTaskResp): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? PatientTask.toJSON(message.Task) : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientTaskResp>, I>>(
    object: I,
  ): CreatePatientTaskResp {
    const message = createBaseCreatePatientTaskResp();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? PatientTask.fromPartial(object.Task)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientTaskAssigneesReq(): SetPatientTaskAssigneesReq {
  return { TaskID: '', AssigneeIDs: [] };
}

export const SetPatientTaskAssigneesReq = {
  encode(
    message: SetPatientTaskAssigneesReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    for (const v of message.AssigneeIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskAssigneesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskAssigneesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.AssigneeIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskAssigneesReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      AssigneeIDs: Array.isArray(object?.AssigneeIDs)
        ? object.AssigneeIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: SetPatientTaskAssigneesReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    if (message.AssigneeIDs) {
      obj.AssigneeIDs = message.AssigneeIDs.map((e) => e);
    } else {
      obj.AssigneeIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskAssigneesReq>, I>>(
    object: I,
  ): SetPatientTaskAssigneesReq {
    const message = createBaseSetPatientTaskAssigneesReq();
    message.TaskID = object.TaskID ?? '';
    message.AssigneeIDs = object.AssigneeIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetPatientTaskAssigneesResp(): SetPatientTaskAssigneesResp {
  return { Task: undefined, Patient: undefined };
}

export const SetPatientTaskAssigneesResp = {
  encode(
    message: SetPatientTaskAssigneesResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      PatientTask.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskAssigneesResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskAssigneesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = PatientTask.decode(reader, reader.uint32());
          break;
        case 2:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskAssigneesResp {
    return {
      Task: isSet(object.Task) ? PatientTask.fromJSON(object.Task) : undefined,
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientTaskAssigneesResp): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? PatientTask.toJSON(message.Task) : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskAssigneesResp>, I>>(
    object: I,
  ): SetPatientTaskAssigneesResp {
    const message = createBaseSetPatientTaskAssigneesResp();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? PatientTask.fromPartial(object.Task)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientTaskTitleReq(): SetPatientTaskTitleReq {
  return { TaskID: '', Title: '' };
}

export const SetPatientTaskTitleReq = {
  encode(
    message: SetPatientTaskTitleReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    if (message.Title !== '') {
      writer.uint32(18).string(message.Title);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskTitleReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskTitleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.Title = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskTitleReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      Title: isSet(object.Title) ? String(object.Title) : '',
    };
  },

  toJSON(message: SetPatientTaskTitleReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Title !== undefined && (obj.Title = message.Title);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskTitleReq>, I>>(
    object: I,
  ): SetPatientTaskTitleReq {
    const message = createBaseSetPatientTaskTitleReq();
    message.TaskID = object.TaskID ?? '';
    message.Title = object.Title ?? '';
    return message;
  },
};

function createBaseSetPatientTaskTitleResp(): SetPatientTaskTitleResp {
  return { Task: undefined };
}

export const SetPatientTaskTitleResp = {
  encode(
    message: SetPatientTaskTitleResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      PatientTask.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskTitleResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskTitleResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = PatientTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskTitleResp {
    return {
      Task: isSet(object.Task) ? PatientTask.fromJSON(object.Task) : undefined,
    };
  },

  toJSON(message: SetPatientTaskTitleResp): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? PatientTask.toJSON(message.Task) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskTitleResp>, I>>(
    object: I,
  ): SetPatientTaskTitleResp {
    const message = createBaseSetPatientTaskTitleResp();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? PatientTask.fromPartial(object.Task)
        : undefined;
    return message;
  },
};

function createBaseSetPatientTaskDescriptionReq(): SetPatientTaskDescriptionReq {
  return { TaskID: '', Description: '' };
}

export const SetPatientTaskDescriptionReq = {
  encode(
    message: SetPatientTaskDescriptionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    if (message.Description !== '') {
      writer.uint32(18).string(message.Description);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskDescriptionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskDescriptionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.Description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskDescriptionReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
    };
  },

  toJSON(message: SetPatientTaskDescriptionReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskDescriptionReq>, I>>(
    object: I,
  ): SetPatientTaskDescriptionReq {
    const message = createBaseSetPatientTaskDescriptionReq();
    message.TaskID = object.TaskID ?? '';
    message.Description = object.Description ?? '';
    return message;
  },
};

function createBaseSetPatientTaskDescriptionResp(): SetPatientTaskDescriptionResp {
  return { Task: undefined };
}

export const SetPatientTaskDescriptionResp = {
  encode(
    message: SetPatientTaskDescriptionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      PatientTask.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskDescriptionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskDescriptionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = PatientTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskDescriptionResp {
    return {
      Task: isSet(object.Task) ? PatientTask.fromJSON(object.Task) : undefined,
    };
  },

  toJSON(message: SetPatientTaskDescriptionResp): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? PatientTask.toJSON(message.Task) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskDescriptionResp>, I>>(
    object: I,
  ): SetPatientTaskDescriptionResp {
    const message = createBaseSetPatientTaskDescriptionResp();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? PatientTask.fromPartial(object.Task)
        : undefined;
    return message;
  },
};

function createBaseSetPatientTaskDueDateReq(): SetPatientTaskDueDateReq {
  return { TaskID: '', DueDate: undefined };
}

export const SetPatientTaskDueDateReq = {
  encode(
    message: SetPatientTaskDueDateReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    if (message.DueDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.DueDate),
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskDueDateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskDueDateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.DueDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskDueDateReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      DueDate: isSet(object.DueDate)
        ? fromJsonTimestamp(object.DueDate)
        : undefined,
    };
  },

  toJSON(message: SetPatientTaskDueDateReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.DueDate !== undefined &&
      (obj.DueDate = message.DueDate.toISOString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskDueDateReq>, I>>(
    object: I,
  ): SetPatientTaskDueDateReq {
    const message = createBaseSetPatientTaskDueDateReq();
    message.TaskID = object.TaskID ?? '';
    message.DueDate = object.DueDate ?? undefined;
    return message;
  },
};

function createBaseSetPatientTaskDueDateResp(): SetPatientTaskDueDateResp {
  return { Task: undefined };
}

export const SetPatientTaskDueDateResp = {
  encode(
    message: SetPatientTaskDueDateResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      PatientTask.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskDueDateResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskDueDateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = PatientTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskDueDateResp {
    return {
      Task: isSet(object.Task) ? PatientTask.fromJSON(object.Task) : undefined,
    };
  },

  toJSON(message: SetPatientTaskDueDateResp): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? PatientTask.toJSON(message.Task) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskDueDateResp>, I>>(
    object: I,
  ): SetPatientTaskDueDateResp {
    const message = createBaseSetPatientTaskDueDateResp();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? PatientTask.fromPartial(object.Task)
        : undefined;
    return message;
  },
};

function createBaseClosePatientTaskReq(): ClosePatientTaskReq {
  return { TaskID: '' };
}

export const ClosePatientTaskReq = {
  encode(
    message: ClosePatientTaskReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClosePatientTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClosePatientTaskReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ClosePatientTaskReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
    };
  },

  toJSON(message: ClosePatientTaskReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ClosePatientTaskReq>, I>>(
    object: I,
  ): ClosePatientTaskReq {
    const message = createBaseClosePatientTaskReq();
    message.TaskID = object.TaskID ?? '';
    return message;
  },
};

function createBaseClosePatientTaskResp(): ClosePatientTaskResp {
  return { Task: undefined };
}

export const ClosePatientTaskResp = {
  encode(
    message: ClosePatientTaskResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      PatientTask.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ClosePatientTaskResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClosePatientTaskResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = PatientTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ClosePatientTaskResp {
    return {
      Task: isSet(object.Task) ? PatientTask.fromJSON(object.Task) : undefined,
    };
  },

  toJSON(message: ClosePatientTaskResp): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? PatientTask.toJSON(message.Task) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ClosePatientTaskResp>, I>>(
    object: I,
  ): ClosePatientTaskResp {
    const message = createBaseClosePatientTaskResp();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? PatientTask.fromPartial(object.Task)
        : undefined;
    return message;
  },
};

function createBaseReopenPatientTaskReq(): ReopenPatientTaskReq {
  return { TaskID: '' };
}

export const ReopenPatientTaskReq = {
  encode(
    message: ReopenPatientTaskReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReopenPatientTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReopenPatientTaskReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReopenPatientTaskReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
    };
  },

  toJSON(message: ReopenPatientTaskReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReopenPatientTaskReq>, I>>(
    object: I,
  ): ReopenPatientTaskReq {
    const message = createBaseReopenPatientTaskReq();
    message.TaskID = object.TaskID ?? '';
    return message;
  },
};

function createBaseReopenPatientTaskResp(): ReopenPatientTaskResp {
  return { Task: undefined };
}

export const ReopenPatientTaskResp = {
  encode(
    message: ReopenPatientTaskResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Task !== undefined) {
      PatientTask.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReopenPatientTaskResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReopenPatientTaskResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = PatientTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReopenPatientTaskResp {
    return {
      Task: isSet(object.Task) ? PatientTask.fromJSON(object.Task) : undefined,
    };
  },

  toJSON(message: ReopenPatientTaskResp): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? PatientTask.toJSON(message.Task) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReopenPatientTaskResp>, I>>(
    object: I,
  ): ReopenPatientTaskResp {
    const message = createBaseReopenPatientTaskResp();
    message.Task =
      object.Task !== undefined && object.Task !== null
        ? PatientTask.fromPartial(object.Task)
        : undefined;
    return message;
  },
};

function createBaseMarkAsReadPatientTasksReq(): MarkAsReadPatientTasksReq {
  return { TaskIDs: [] };
}

export const MarkAsReadPatientTasksReq = {
  encode(
    message: MarkAsReadPatientTasksReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.TaskIDs) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MarkAsReadPatientTasksReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkAsReadPatientTasksReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarkAsReadPatientTasksReq {
    return {
      TaskIDs: Array.isArray(object?.TaskIDs)
        ? object.TaskIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: MarkAsReadPatientTasksReq): unknown {
    const obj: any = {};
    if (message.TaskIDs) {
      obj.TaskIDs = message.TaskIDs.map((e) => e);
    } else {
      obj.TaskIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MarkAsReadPatientTasksReq>, I>>(
    object: I,
  ): MarkAsReadPatientTasksReq {
    const message = createBaseMarkAsReadPatientTasksReq();
    message.TaskIDs = object.TaskIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseMarkAsReadPatientTasksResp(): MarkAsReadPatientTasksResp {
  return { Tasks: [] };
}

export const MarkAsReadPatientTasksResp = {
  encode(
    message: MarkAsReadPatientTasksResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Tasks) {
      PatientTask.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MarkAsReadPatientTasksResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkAsReadPatientTasksResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tasks.push(PatientTask.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarkAsReadPatientTasksResp {
    return {
      Tasks: Array.isArray(object?.Tasks)
        ? object.Tasks.map((e: any) => PatientTask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MarkAsReadPatientTasksResp): unknown {
    const obj: any = {};
    if (message.Tasks) {
      obj.Tasks = message.Tasks.map((e) =>
        e ? PatientTask.toJSON(e) : undefined,
      );
    } else {
      obj.Tasks = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MarkAsReadPatientTasksResp>, I>>(
    object: I,
  ): MarkAsReadPatientTasksResp {
    const message = createBaseMarkAsReadPatientTasksResp();
    message.Tasks = object.Tasks?.map((e) => PatientTask.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetPatientTaskOrderReq(): SetPatientTaskOrderReq {
  return { TaskID: '', Order: 0 };
}

export const SetPatientTaskOrderReq = {
  encode(
    message: SetPatientTaskOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    if (message.Order !== 0) {
      writer.uint32(16).uint32(message.Order);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.Order = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskOrderReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      Order: isSet(object.Order) ? Number(object.Order) : 0,
    };
  },

  toJSON(message: SetPatientTaskOrderReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskOrderReq>, I>>(
    object: I,
  ): SetPatientTaskOrderReq {
    const message = createBaseSetPatientTaskOrderReq();
    message.TaskID = object.TaskID ?? '';
    message.Order = object.Order ?? 0;
    return message;
  },
};

function createBaseSetPatientTaskOrderResp(): SetPatientTaskOrderResp {
  return { Tasks: [] };
}

export const SetPatientTaskOrderResp = {
  encode(
    message: SetPatientTaskOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Tasks) {
      PatientTask.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTaskOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTaskOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tasks.push(PatientTask.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTaskOrderResp {
    return {
      Tasks: Array.isArray(object?.Tasks)
        ? object.Tasks.map((e: any) => PatientTask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetPatientTaskOrderResp): unknown {
    const obj: any = {};
    if (message.Tasks) {
      obj.Tasks = message.Tasks.map((e) =>
        e ? PatientTask.toJSON(e) : undefined,
      );
    } else {
      obj.Tasks = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTaskOrderResp>, I>>(
    object: I,
  ): SetPatientTaskOrderResp {
    const message = createBaseSetPatientTaskOrderResp();
    message.Tasks = object.Tasks?.map((e) => PatientTask.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeletePatientTaskReq(): DeletePatientTaskReq {
  return { TaskID: '' };
}

export const DeletePatientTaskReq = {
  encode(
    message: DeletePatientTaskReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TaskID !== '') {
      writer.uint32(10).string(message.TaskID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeletePatientTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePatientTaskReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletePatientTaskReq {
    return {
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
    };
  },

  toJSON(message: DeletePatientTaskReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeletePatientTaskReq>, I>>(
    object: I,
  ): DeletePatientTaskReq {
    const message = createBaseDeletePatientTaskReq();
    message.TaskID = object.TaskID ?? '';
    return message;
  },
};

function createBaseDeletePatientTaskResp(): DeletePatientTaskResp {
  return { Tasks: [] };
}

export const DeletePatientTaskResp = {
  encode(
    message: DeletePatientTaskResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Tasks) {
      PatientTask.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeletePatientTaskResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePatientTaskResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tasks.push(PatientTask.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletePatientTaskResp {
    return {
      Tasks: Array.isArray(object?.Tasks)
        ? object.Tasks.map((e: any) => PatientTask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DeletePatientTaskResp): unknown {
    const obj: any = {};
    if (message.Tasks) {
      obj.Tasks = message.Tasks.map((e) =>
        e ? PatientTask.toJSON(e) : undefined,
      );
    } else {
      obj.Tasks = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeletePatientTaskResp>, I>>(
    object: I,
  ): DeletePatientTaskResp {
    const message = createBaseDeletePatientTaskResp();
    message.Tasks = object.Tasks?.map((e) => PatientTask.fromPartial(e)) || [];
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: TaskID/Title
 * - type: NotFoundError, Entity: patient task (if task not fount)
 * - type: BadRequestError, Reason: patient deleted (if remote patient found by PatientID)
 */
export interface PatientTaskService {
  /**
   * Create new PatientTask
   *
   * Permission: `PatientPermissions.CanEditTasks`
   *
   * Errors:
   * - type: BadRequestError, Reason: assignee does not have organization role
   * - type: BadRequestError, Reason: dueDate cannot be less than current
   */
  CreatePatientTask(
    request: DeepPartial<CreatePatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientTaskResp>;
  /**
   * Set assignees list for the task
   *
   * Permission: `PatientPermissions.CanEditTasks`
   *
   * Errors:
   * - type: BadRequestError, Reason: assignee does not have organization role
   */
  SetPatientTaskAssignees(
    request: DeepPartial<SetPatientTaskAssigneesReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskAssigneesResp>;
  /**
   * Set patient task title
   *
   * Permission: `PatientPermissions.CanEditTasks`
   */
  SetPatientTaskTitle(
    request: DeepPartial<SetPatientTaskTitleReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskTitleResp>;
  /**
   * Set patient task description
   *
   * Permission: `PatientPermissions.CanEditTasks`
   */
  SetPatientTaskDescription(
    request: DeepPartial<SetPatientTaskDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskDescriptionResp>;
  /**
   * Set patient task due date
   *
   * Permission: `PatientPermissions.CanEditTasks`
   *
   * Errors:
   * - type: BadRequestError, Reason: dueDate cannot be less than current
   */
  SetPatientTaskDueDate(
    request: DeepPartial<SetPatientTaskDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskDueDateResp>;
  /**
   * Close patient task
   *
   * Permission: `PatientPermissions.CanEditTasks`
   */
  ClosePatientTask(
    request: DeepPartial<ClosePatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<ClosePatientTaskResp>;
  /**
   * Reopen patient task
   *
   * Permission: `PatientPermissions.CanEditTasks`
   */
  ReopenPatientTask(
    request: DeepPartial<ReopenPatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<ReopenPatientTaskResp>;
  /**
   * Mark as read patient tasks
   *
   * Permission: without permissions
   */
  MarkAsReadPatientTasks(
    request: DeepPartial<MarkAsReadPatientTasksReq>,
    metadata?: grpc.Metadata,
  ): Promise<MarkAsReadPatientTasksResp>;
  /**
   * Set new order to patient task
   *
   * Permission: `PatientPermissions.CanEditTasks`
   */
  SetPatientTaskOrder(
    request: DeepPartial<SetPatientTaskOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskOrderResp>;
  /**
   * Delete patient task
   *
   * Permission: `PatientPermissions.CanEditTasks`
   */
  DeletePatientTask(
    request: DeepPartial<DeletePatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePatientTaskResp>;
}

export class PatientTaskServiceClientImpl implements PatientTaskService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreatePatientTask = this.CreatePatientTask.bind(this);
    this.SetPatientTaskAssignees = this.SetPatientTaskAssignees.bind(this);
    this.SetPatientTaskTitle = this.SetPatientTaskTitle.bind(this);
    this.SetPatientTaskDescription = this.SetPatientTaskDescription.bind(this);
    this.SetPatientTaskDueDate = this.SetPatientTaskDueDate.bind(this);
    this.ClosePatientTask = this.ClosePatientTask.bind(this);
    this.ReopenPatientTask = this.ReopenPatientTask.bind(this);
    this.MarkAsReadPatientTasks = this.MarkAsReadPatientTasks.bind(this);
    this.SetPatientTaskOrder = this.SetPatientTaskOrder.bind(this);
    this.DeletePatientTask = this.DeletePatientTask.bind(this);
  }

  CreatePatientTask(
    request: DeepPartial<CreatePatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientTaskResp> {
    return this.rpc.unary(
      PatientTaskServiceCreatePatientTaskDesc,
      CreatePatientTaskReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientTaskAssignees(
    request: DeepPartial<SetPatientTaskAssigneesReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskAssigneesResp> {
    return this.rpc.unary(
      PatientTaskServiceSetPatientTaskAssigneesDesc,
      SetPatientTaskAssigneesReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientTaskTitle(
    request: DeepPartial<SetPatientTaskTitleReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskTitleResp> {
    return this.rpc.unary(
      PatientTaskServiceSetPatientTaskTitleDesc,
      SetPatientTaskTitleReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientTaskDescription(
    request: DeepPartial<SetPatientTaskDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskDescriptionResp> {
    return this.rpc.unary(
      PatientTaskServiceSetPatientTaskDescriptionDesc,
      SetPatientTaskDescriptionReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientTaskDueDate(
    request: DeepPartial<SetPatientTaskDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskDueDateResp> {
    return this.rpc.unary(
      PatientTaskServiceSetPatientTaskDueDateDesc,
      SetPatientTaskDueDateReq.fromPartial(request),
      metadata,
    );
  }

  ClosePatientTask(
    request: DeepPartial<ClosePatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<ClosePatientTaskResp> {
    return this.rpc.unary(
      PatientTaskServiceClosePatientTaskDesc,
      ClosePatientTaskReq.fromPartial(request),
      metadata,
    );
  }

  ReopenPatientTask(
    request: DeepPartial<ReopenPatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<ReopenPatientTaskResp> {
    return this.rpc.unary(
      PatientTaskServiceReopenPatientTaskDesc,
      ReopenPatientTaskReq.fromPartial(request),
      metadata,
    );
  }

  MarkAsReadPatientTasks(
    request: DeepPartial<MarkAsReadPatientTasksReq>,
    metadata?: grpc.Metadata,
  ): Promise<MarkAsReadPatientTasksResp> {
    return this.rpc.unary(
      PatientTaskServiceMarkAsReadPatientTasksDesc,
      MarkAsReadPatientTasksReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientTaskOrder(
    request: DeepPartial<SetPatientTaskOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTaskOrderResp> {
    return this.rpc.unary(
      PatientTaskServiceSetPatientTaskOrderDesc,
      SetPatientTaskOrderReq.fromPartial(request),
      metadata,
    );
  }

  DeletePatientTask(
    request: DeepPartial<DeletePatientTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePatientTaskResp> {
    return this.rpc.unary(
      PatientTaskServiceDeletePatientTaskDesc,
      DeletePatientTaskReq.fromPartial(request),
      metadata,
    );
  }
}

export const PatientTaskServiceDesc = {
  serviceName: 'com.diagnocat.core.PatientTaskService',
};

export const PatientTaskServiceCreatePatientTaskDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreatePatientTask',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreatePatientTaskReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...CreatePatientTaskResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceSetPatientTaskAssigneesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientTaskAssignees',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientTaskAssigneesReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientTaskAssigneesResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceSetPatientTaskTitleDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientTaskTitle',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientTaskTitleReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientTaskTitleResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceSetPatientTaskDescriptionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientTaskDescription',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientTaskDescriptionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientTaskDescriptionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceSetPatientTaskDueDateDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientTaskDueDate',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientTaskDueDateReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientTaskDueDateResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceClosePatientTaskDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ClosePatientTask',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ClosePatientTaskReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ClosePatientTaskResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceReopenPatientTaskDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ReopenPatientTask',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ReopenPatientTaskReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ReopenPatientTaskResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceMarkAsReadPatientTasksDesc: UnaryMethodDefinitionish =
  {
    methodName: 'MarkAsReadPatientTasks',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return MarkAsReadPatientTasksReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...MarkAsReadPatientTasksResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceSetPatientTaskOrderDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientTaskOrder',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientTaskOrderReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientTaskOrderResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientTaskServiceDeletePatientTaskDesc: UnaryMethodDefinitionish =
  {
    methodName: 'DeletePatientTask',
    service: PatientTaskServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return DeletePatientTaskReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...DeletePatientTaskResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

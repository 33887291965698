/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { BrowserHeaders } from 'browser-headers';
import { Empty } from '../../google/protobuf/empty';
import { UploadSession, UploadTarget } from '../../model/dto_upload_session';
import { Attribute } from '../../model/dto_base';
import {
  AssetContentReportUserUploadedToothSlice,
  AssetContentReportUserUploadedMPRPlane,
} from '../../model/dto_asset_report_uploaded';
import { Study } from '../../model/dto_study';
import {
  GeneratedReportAsset,
  AssetContentStudyCBCT,
  AssetContentStudyIntraoralXRay,
  AssetContentStudyIOSMeshes,
  AssetContentStudyDentalPhoto,
  Asset,
} from '../../model/dto_asset';
import { User } from '../../model/dto_user';
import { Organization } from '../../model/dto_organization';
import { Report } from '../../model/dto_report';
import { Tooth } from '../../model/dto_report_tooth';

export const protobufPackage = 'com.diagnocat.core';

export interface FailSessionReq {
  UploadSessionID: string;
  Cancelled: Empty | undefined;
  Error: FailSessionReq_UploadError | undefined;
}

export interface FailSessionReq_UploadError {
  FileID: string;
  Message: string;
}

export interface FailSessionResp {
  UploadSession: UploadSession | undefined;
}

export interface StartUploadSessionReq {
  UserAvatar: StartUploadSessionReq_UserSimpleFileUpload | undefined;
  UserSignature: StartUploadSessionReq_UserSimpleFileUpload | undefined;
  OrganizationLogo:
    | StartUploadSessionReq_OrganizationSimpleFileUpload
    | undefined;
  OrganizationStamp:
    | StartUploadSessionReq_OrganizationSimpleFileUpload
    | undefined;
  OrganizationEmployeeStamp:
    | StartUploadSessionReq_OrganizationUserSimpleFileUpload
    | undefined;
  StudyCBCT:
    | StartUploadSessionReq_PatientStudySimpleMultiFileUpload
    | undefined;
  StudyPanoramicXRay:
    | StartUploadSessionReq_PatientStudySimpleFileUpload
    | undefined;
  StudyIntraoralXRay:
    | StartUploadSessionReq_PatientStudySimpleMultiFileUpload
    | undefined;
  StudyIOSMeshes:
    | StartUploadSessionReq_PatientStudySimpleMultiFileUpload
    | undefined;
  StudyDentalPhoto:
    | StartUploadSessionReq_PatientStudySimpleMultiFileUpload
    | undefined;
  PatientDocument:
    | StartUploadSessionReq_PatientSimpleMultiFileUpload
    | undefined;
  ToothUserUploadedSlice:
    | StartUploadSessionReq_ToothUserUploadedSlices
    | undefined;
  ToothUserUploadedMPRPlanes:
    | StartUploadSessionReq_ToothUserUploadedMPRPlanes
    | undefined;
  ReportStudioApplicationStateSnapshot:
    | StartUploadSessionReq_StudioReportFileUpload
    | undefined;
  ReportStudioGeometry:
    | StartUploadSessionReq_StudioReportFileUpload
    | undefined;
  ReportDisplayAsset: StartUploadSessionReq_ReportSimpleFileUpload | undefined;
  Attributes: Attribute[];
}

export interface StartUploadSessionReq_UserSimpleFileUpload {
  /** `UserID`: [required] */
  UserID: string;
  /** `FilePath`: [required] */
  FilePath: string;
}

export interface StartUploadSessionReq_OrganizationSimpleFileUpload {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `FilePath`: [required] */
  FilePath: string;
}

export interface StartUploadSessionReq_OrganizationUserSimpleFileUpload {
  /** `UserID`: [required] */
  UserID: string;
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `FilePath`: [required] */
  FilePath: string;
}

export interface StartUploadSessionReq_PatientStudySimpleFileUpload {
  PatientID: string | undefined;
  StudyID: string | undefined;
  /** `FilePath`: [required] */
  FilePath: string;
}

export interface StartUploadSessionReq_PatientStudySimpleMultiFileUpload {
  PatientID: string | undefined;
  StudyID: string | undefined;
  /** `FilePaths`: [required] */
  FilePaths: string[];
}

export interface StartUploadSessionReq_PatientSimpleMultiFileUpload {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `FilePaths`: [required] */
  FilePaths: string[];
}

export interface StartUploadSessionReq_ReportSimpleFileUpload {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `FilePath`: [required] */
  FilePath: string;
}

export interface StartUploadSessionReq_StudioReportFileUpload {
  /** `ReportID`: [required] */
  ReportID: string;
}

export interface StartUploadSessionReq_ToothUserUploadedSlices {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `Slices`: [required] */
  Slices: AssetContentReportUserUploadedToothSlice[];
}

export interface StartUploadSessionReq_ToothUserUploadedMPRPlanes {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `MPRPanes`: [required] */
  MPRPanes: AssetContentReportUserUploadedMPRPlane[];
}

export interface StartUploadSessionResp {
  UploadSession: UploadSession | undefined;
  UploadTargets: UploadTarget[];
  /** optional */
  Study: Study | undefined;
}

export interface StartPipelinesUploadSessionReq {
  /** `AssetID`: [required] */
  AssetID: string;
  /** `PipelinesRunID`: [required] */
  PipelinesRunID: string;
  /** `GeneratedReport`: [required] */
  GeneratedReport: GeneratedReportAsset | undefined;
}

/** only if UploadSession is not closed yet */
export interface AddFilesToUploadSessionReq {
  /** `UploadSessionID`: [required] */
  UploadSessionID: string;
  CBCT: AssetContentStudyCBCT | undefined;
  IntraoralXRay: AssetContentStudyIntraoralXRay | undefined;
  IOSMeshes: AssetContentStudyIOSMeshes | undefined;
  DentalPhoto: AssetContentStudyDentalPhoto | undefined;
}

export interface AddFilesToUploadSessionResp {
  UploadSession: UploadSession | undefined;
  UploadTargets: UploadTarget[];
}

export interface NotifyFileUploadStartedReq {
  /** `FileID`: [required] */
  FileID: string;
}

export interface NotifyFileUploadStartedResp {
  UploadSession: UploadSession | undefined;
}

export interface NotifyFileUploadProgressReq {
  /** `FileID`: [required] */
  FileID: string;
}

export interface NotifyFileUploadProgressResp {
  UploadSession: UploadSession | undefined;
}

export interface NotifyFileUploadFinishedReq {
  /** `FileID`: [required] */
  FileID: string;
}

export interface NotifyFileUploadFinishedResp {
  UploadSession: UploadSession | undefined;
}

export interface CloseSessionReq {
  /** `UploadSessionID`: [required] */
  UploadSessionID: string;
}

export interface CloseSessionResp {
  Assets: Asset[];
  User: User | undefined;
  Organization: Organization | undefined;
  Study: Study | undefined;
  Reports: Report[];
  Tooth: Tooth | undefined;
}

function createBaseFailSessionReq(): FailSessionReq {
  return { UploadSessionID: '', Cancelled: undefined, Error: undefined };
}

export const FailSessionReq = {
  encode(
    message: FailSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    if (message.Cancelled !== undefined) {
      Empty.encode(message.Cancelled, writer.uint32(802).fork()).ldelim();
    }
    if (message.Error !== undefined) {
      FailSessionReq_UploadError.encode(
        message.Error,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FailSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFailSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        case 100:
          message.Cancelled = Empty.decode(reader, reader.uint32());
          break;
        case 101:
          message.Error = FailSessionReq_UploadError.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FailSessionReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
      Cancelled: isSet(object.Cancelled)
        ? Empty.fromJSON(object.Cancelled)
        : undefined,
      Error: isSet(object.Error)
        ? FailSessionReq_UploadError.fromJSON(object.Error)
        : undefined,
    };
  },

  toJSON(message: FailSessionReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    message.Cancelled !== undefined &&
      (obj.Cancelled = message.Cancelled
        ? Empty.toJSON(message.Cancelled)
        : undefined);
    message.Error !== undefined &&
      (obj.Error = message.Error
        ? FailSessionReq_UploadError.toJSON(message.Error)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FailSessionReq>, I>>(
    object: I,
  ): FailSessionReq {
    const message = createBaseFailSessionReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    message.Cancelled =
      object.Cancelled !== undefined && object.Cancelled !== null
        ? Empty.fromPartial(object.Cancelled)
        : undefined;
    message.Error =
      object.Error !== undefined && object.Error !== null
        ? FailSessionReq_UploadError.fromPartial(object.Error)
        : undefined;
    return message;
  },
};

function createBaseFailSessionReq_UploadError(): FailSessionReq_UploadError {
  return { FileID: '', Message: '' };
}

export const FailSessionReq_UploadError = {
  encode(
    message: FailSessionReq_UploadError,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FileID !== '') {
      writer.uint32(10).string(message.FileID);
    }
    if (message.Message !== '') {
      writer.uint32(18).string(message.Message);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FailSessionReq_UploadError {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFailSessionReq_UploadError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FileID = reader.string();
          break;
        case 2:
          message.Message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FailSessionReq_UploadError {
    return {
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
      Message: isSet(object.Message) ? String(object.Message) : '',
    };
  },

  toJSON(message: FailSessionReq_UploadError): unknown {
    const obj: any = {};
    message.FileID !== undefined && (obj.FileID = message.FileID);
    message.Message !== undefined && (obj.Message = message.Message);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FailSessionReq_UploadError>, I>>(
    object: I,
  ): FailSessionReq_UploadError {
    const message = createBaseFailSessionReq_UploadError();
    message.FileID = object.FileID ?? '';
    message.Message = object.Message ?? '';
    return message;
  },
};

function createBaseFailSessionResp(): FailSessionResp {
  return { UploadSession: undefined };
}

export const FailSessionResp = {
  encode(
    message: FailSessionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FailSessionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFailSessionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FailSessionResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
    };
  },

  toJSON(message: FailSessionResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FailSessionResp>, I>>(
    object: I,
  ): FailSessionResp {
    const message = createBaseFailSessionResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    return message;
  },
};

function createBaseStartUploadSessionReq(): StartUploadSessionReq {
  return {
    UserAvatar: undefined,
    UserSignature: undefined,
    OrganizationLogo: undefined,
    OrganizationStamp: undefined,
    OrganizationEmployeeStamp: undefined,
    StudyCBCT: undefined,
    StudyPanoramicXRay: undefined,
    StudyIntraoralXRay: undefined,
    StudyIOSMeshes: undefined,
    StudyDentalPhoto: undefined,
    PatientDocument: undefined,
    ToothUserUploadedSlice: undefined,
    ToothUserUploadedMPRPlanes: undefined,
    ReportStudioApplicationStateSnapshot: undefined,
    ReportStudioGeometry: undefined,
    ReportDisplayAsset: undefined,
    Attributes: [],
  };
}

export const StartUploadSessionReq = {
  encode(
    message: StartUploadSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserAvatar !== undefined) {
      StartUploadSessionReq_UserSimpleFileUpload.encode(
        message.UserAvatar,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.UserSignature !== undefined) {
      StartUploadSessionReq_UserSimpleFileUpload.encode(
        message.UserSignature,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.OrganizationLogo !== undefined) {
      StartUploadSessionReq_OrganizationSimpleFileUpload.encode(
        message.OrganizationLogo,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.OrganizationStamp !== undefined) {
      StartUploadSessionReq_OrganizationSimpleFileUpload.encode(
        message.OrganizationStamp,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.OrganizationEmployeeStamp !== undefined) {
      StartUploadSessionReq_OrganizationUserSimpleFileUpload.encode(
        message.OrganizationEmployeeStamp,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.StudyCBCT !== undefined) {
      StartUploadSessionReq_PatientStudySimpleMultiFileUpload.encode(
        message.StudyCBCT,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.StudyPanoramicXRay !== undefined) {
      StartUploadSessionReq_PatientStudySimpleFileUpload.encode(
        message.StudyPanoramicXRay,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.StudyIntraoralXRay !== undefined) {
      StartUploadSessionReq_PatientStudySimpleMultiFileUpload.encode(
        message.StudyIntraoralXRay,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.StudyIOSMeshes !== undefined) {
      StartUploadSessionReq_PatientStudySimpleMultiFileUpload.encode(
        message.StudyIOSMeshes,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.StudyDentalPhoto !== undefined) {
      StartUploadSessionReq_PatientStudySimpleMultiFileUpload.encode(
        message.StudyDentalPhoto,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    if (message.PatientDocument !== undefined) {
      StartUploadSessionReq_PatientSimpleMultiFileUpload.encode(
        message.PatientDocument,
        writer.uint32(890).fork(),
      ).ldelim();
    }
    if (message.ToothUserUploadedSlice !== undefined) {
      StartUploadSessionReq_ToothUserUploadedSlices.encode(
        message.ToothUserUploadedSlice,
        writer.uint32(906).fork(),
      ).ldelim();
    }
    if (message.ToothUserUploadedMPRPlanes !== undefined) {
      StartUploadSessionReq_ToothUserUploadedMPRPlanes.encode(
        message.ToothUserUploadedMPRPlanes,
        writer.uint32(914).fork(),
      ).ldelim();
    }
    if (message.ReportStudioApplicationStateSnapshot !== undefined) {
      StartUploadSessionReq_StudioReportFileUpload.encode(
        message.ReportStudioApplicationStateSnapshot,
        writer.uint32(970).fork(),
      ).ldelim();
    }
    if (message.ReportStudioGeometry !== undefined) {
      StartUploadSessionReq_StudioReportFileUpload.encode(
        message.ReportStudioGeometry,
        writer.uint32(978).fork(),
      ).ldelim();
    }
    if (message.ReportDisplayAsset !== undefined) {
      StartUploadSessionReq_ReportSimpleFileUpload.encode(
        message.ReportDisplayAsset,
        writer.uint32(986).fork(),
      ).ldelim();
    }
    for (const v of message.Attributes) {
      Attribute.encode(v!, writer.uint32(8002).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserAvatar =
            StartUploadSessionReq_UserSimpleFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 2:
          message.UserSignature =
            StartUploadSessionReq_UserSimpleFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 11:
          message.OrganizationLogo =
            StartUploadSessionReq_OrganizationSimpleFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 12:
          message.OrganizationStamp =
            StartUploadSessionReq_OrganizationSimpleFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 13:
          message.OrganizationEmployeeStamp =
            StartUploadSessionReq_OrganizationUserSimpleFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 101:
          message.StudyCBCT =
            StartUploadSessionReq_PatientStudySimpleMultiFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 102:
          message.StudyPanoramicXRay =
            StartUploadSessionReq_PatientStudySimpleFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 103:
          message.StudyIntraoralXRay =
            StartUploadSessionReq_PatientStudySimpleMultiFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 104:
          message.StudyIOSMeshes =
            StartUploadSessionReq_PatientStudySimpleMultiFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 105:
          message.StudyDentalPhoto =
            StartUploadSessionReq_PatientStudySimpleMultiFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 111:
          message.PatientDocument =
            StartUploadSessionReq_PatientSimpleMultiFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 113:
          message.ToothUserUploadedSlice =
            StartUploadSessionReq_ToothUserUploadedSlices.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 114:
          message.ToothUserUploadedMPRPlanes =
            StartUploadSessionReq_ToothUserUploadedMPRPlanes.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 121:
          message.ReportStudioApplicationStateSnapshot =
            StartUploadSessionReq_StudioReportFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 122:
          message.ReportStudioGeometry =
            StartUploadSessionReq_StudioReportFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 123:
          message.ReportDisplayAsset =
            StartUploadSessionReq_ReportSimpleFileUpload.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 1000:
          message.Attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq {
    return {
      UserAvatar: isSet(object.UserAvatar)
        ? StartUploadSessionReq_UserSimpleFileUpload.fromJSON(object.UserAvatar)
        : undefined,
      UserSignature: isSet(object.UserSignature)
        ? StartUploadSessionReq_UserSimpleFileUpload.fromJSON(
            object.UserSignature,
          )
        : undefined,
      OrganizationLogo: isSet(object.OrganizationLogo)
        ? StartUploadSessionReq_OrganizationSimpleFileUpload.fromJSON(
            object.OrganizationLogo,
          )
        : undefined,
      OrganizationStamp: isSet(object.OrganizationStamp)
        ? StartUploadSessionReq_OrganizationSimpleFileUpload.fromJSON(
            object.OrganizationStamp,
          )
        : undefined,
      OrganizationEmployeeStamp: isSet(object.OrganizationEmployeeStamp)
        ? StartUploadSessionReq_OrganizationUserSimpleFileUpload.fromJSON(
            object.OrganizationEmployeeStamp,
          )
        : undefined,
      StudyCBCT: isSet(object.StudyCBCT)
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromJSON(
            object.StudyCBCT,
          )
        : undefined,
      StudyPanoramicXRay: isSet(object.StudyPanoramicXRay)
        ? StartUploadSessionReq_PatientStudySimpleFileUpload.fromJSON(
            object.StudyPanoramicXRay,
          )
        : undefined,
      StudyIntraoralXRay: isSet(object.StudyIntraoralXRay)
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromJSON(
            object.StudyIntraoralXRay,
          )
        : undefined,
      StudyIOSMeshes: isSet(object.StudyIOSMeshes)
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromJSON(
            object.StudyIOSMeshes,
          )
        : undefined,
      StudyDentalPhoto: isSet(object.StudyDentalPhoto)
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromJSON(
            object.StudyDentalPhoto,
          )
        : undefined,
      PatientDocument: isSet(object.PatientDocument)
        ? StartUploadSessionReq_PatientSimpleMultiFileUpload.fromJSON(
            object.PatientDocument,
          )
        : undefined,
      ToothUserUploadedSlice: isSet(object.ToothUserUploadedSlice)
        ? StartUploadSessionReq_ToothUserUploadedSlices.fromJSON(
            object.ToothUserUploadedSlice,
          )
        : undefined,
      ToothUserUploadedMPRPlanes: isSet(object.ToothUserUploadedMPRPlanes)
        ? StartUploadSessionReq_ToothUserUploadedMPRPlanes.fromJSON(
            object.ToothUserUploadedMPRPlanes,
          )
        : undefined,
      ReportStudioApplicationStateSnapshot: isSet(
        object.ReportStudioApplicationStateSnapshot,
      )
        ? StartUploadSessionReq_StudioReportFileUpload.fromJSON(
            object.ReportStudioApplicationStateSnapshot,
          )
        : undefined,
      ReportStudioGeometry: isSet(object.ReportStudioGeometry)
        ? StartUploadSessionReq_StudioReportFileUpload.fromJSON(
            object.ReportStudioGeometry,
          )
        : undefined,
      ReportDisplayAsset: isSet(object.ReportDisplayAsset)
        ? StartUploadSessionReq_ReportSimpleFileUpload.fromJSON(
            object.ReportDisplayAsset,
          )
        : undefined,
      Attributes: Array.isArray(object?.Attributes)
        ? object.Attributes.map((e: any) => Attribute.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StartUploadSessionReq): unknown {
    const obj: any = {};
    message.UserAvatar !== undefined &&
      (obj.UserAvatar = message.UserAvatar
        ? StartUploadSessionReq_UserSimpleFileUpload.toJSON(message.UserAvatar)
        : undefined);
    message.UserSignature !== undefined &&
      (obj.UserSignature = message.UserSignature
        ? StartUploadSessionReq_UserSimpleFileUpload.toJSON(
            message.UserSignature,
          )
        : undefined);
    message.OrganizationLogo !== undefined &&
      (obj.OrganizationLogo = message.OrganizationLogo
        ? StartUploadSessionReq_OrganizationSimpleFileUpload.toJSON(
            message.OrganizationLogo,
          )
        : undefined);
    message.OrganizationStamp !== undefined &&
      (obj.OrganizationStamp = message.OrganizationStamp
        ? StartUploadSessionReq_OrganizationSimpleFileUpload.toJSON(
            message.OrganizationStamp,
          )
        : undefined);
    message.OrganizationEmployeeStamp !== undefined &&
      (obj.OrganizationEmployeeStamp = message.OrganizationEmployeeStamp
        ? StartUploadSessionReq_OrganizationUserSimpleFileUpload.toJSON(
            message.OrganizationEmployeeStamp,
          )
        : undefined);
    message.StudyCBCT !== undefined &&
      (obj.StudyCBCT = message.StudyCBCT
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.toJSON(
            message.StudyCBCT,
          )
        : undefined);
    message.StudyPanoramicXRay !== undefined &&
      (obj.StudyPanoramicXRay = message.StudyPanoramicXRay
        ? StartUploadSessionReq_PatientStudySimpleFileUpload.toJSON(
            message.StudyPanoramicXRay,
          )
        : undefined);
    message.StudyIntraoralXRay !== undefined &&
      (obj.StudyIntraoralXRay = message.StudyIntraoralXRay
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.toJSON(
            message.StudyIntraoralXRay,
          )
        : undefined);
    message.StudyIOSMeshes !== undefined &&
      (obj.StudyIOSMeshes = message.StudyIOSMeshes
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.toJSON(
            message.StudyIOSMeshes,
          )
        : undefined);
    message.StudyDentalPhoto !== undefined &&
      (obj.StudyDentalPhoto = message.StudyDentalPhoto
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.toJSON(
            message.StudyDentalPhoto,
          )
        : undefined);
    message.PatientDocument !== undefined &&
      (obj.PatientDocument = message.PatientDocument
        ? StartUploadSessionReq_PatientSimpleMultiFileUpload.toJSON(
            message.PatientDocument,
          )
        : undefined);
    message.ToothUserUploadedSlice !== undefined &&
      (obj.ToothUserUploadedSlice = message.ToothUserUploadedSlice
        ? StartUploadSessionReq_ToothUserUploadedSlices.toJSON(
            message.ToothUserUploadedSlice,
          )
        : undefined);
    message.ToothUserUploadedMPRPlanes !== undefined &&
      (obj.ToothUserUploadedMPRPlanes = message.ToothUserUploadedMPRPlanes
        ? StartUploadSessionReq_ToothUserUploadedMPRPlanes.toJSON(
            message.ToothUserUploadedMPRPlanes,
          )
        : undefined);
    message.ReportStudioApplicationStateSnapshot !== undefined &&
      (obj.ReportStudioApplicationStateSnapshot =
        message.ReportStudioApplicationStateSnapshot
          ? StartUploadSessionReq_StudioReportFileUpload.toJSON(
              message.ReportStudioApplicationStateSnapshot,
            )
          : undefined);
    message.ReportStudioGeometry !== undefined &&
      (obj.ReportStudioGeometry = message.ReportStudioGeometry
        ? StartUploadSessionReq_StudioReportFileUpload.toJSON(
            message.ReportStudioGeometry,
          )
        : undefined);
    message.ReportDisplayAsset !== undefined &&
      (obj.ReportDisplayAsset = message.ReportDisplayAsset
        ? StartUploadSessionReq_ReportSimpleFileUpload.toJSON(
            message.ReportDisplayAsset,
          )
        : undefined);
    if (message.Attributes) {
      obj.Attributes = message.Attributes.map((e) =>
        e ? Attribute.toJSON(e) : undefined,
      );
    } else {
      obj.Attributes = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StartUploadSessionReq>, I>>(
    object: I,
  ): StartUploadSessionReq {
    const message = createBaseStartUploadSessionReq();
    message.UserAvatar =
      object.UserAvatar !== undefined && object.UserAvatar !== null
        ? StartUploadSessionReq_UserSimpleFileUpload.fromPartial(
            object.UserAvatar,
          )
        : undefined;
    message.UserSignature =
      object.UserSignature !== undefined && object.UserSignature !== null
        ? StartUploadSessionReq_UserSimpleFileUpload.fromPartial(
            object.UserSignature,
          )
        : undefined;
    message.OrganizationLogo =
      object.OrganizationLogo !== undefined && object.OrganizationLogo !== null
        ? StartUploadSessionReq_OrganizationSimpleFileUpload.fromPartial(
            object.OrganizationLogo,
          )
        : undefined;
    message.OrganizationStamp =
      object.OrganizationStamp !== undefined &&
      object.OrganizationStamp !== null
        ? StartUploadSessionReq_OrganizationSimpleFileUpload.fromPartial(
            object.OrganizationStamp,
          )
        : undefined;
    message.OrganizationEmployeeStamp =
      object.OrganizationEmployeeStamp !== undefined &&
      object.OrganizationEmployeeStamp !== null
        ? StartUploadSessionReq_OrganizationUserSimpleFileUpload.fromPartial(
            object.OrganizationEmployeeStamp,
          )
        : undefined;
    message.StudyCBCT =
      object.StudyCBCT !== undefined && object.StudyCBCT !== null
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromPartial(
            object.StudyCBCT,
          )
        : undefined;
    message.StudyPanoramicXRay =
      object.StudyPanoramicXRay !== undefined &&
      object.StudyPanoramicXRay !== null
        ? StartUploadSessionReq_PatientStudySimpleFileUpload.fromPartial(
            object.StudyPanoramicXRay,
          )
        : undefined;
    message.StudyIntraoralXRay =
      object.StudyIntraoralXRay !== undefined &&
      object.StudyIntraoralXRay !== null
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromPartial(
            object.StudyIntraoralXRay,
          )
        : undefined;
    message.StudyIOSMeshes =
      object.StudyIOSMeshes !== undefined && object.StudyIOSMeshes !== null
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromPartial(
            object.StudyIOSMeshes,
          )
        : undefined;
    message.StudyDentalPhoto =
      object.StudyDentalPhoto !== undefined && object.StudyDentalPhoto !== null
        ? StartUploadSessionReq_PatientStudySimpleMultiFileUpload.fromPartial(
            object.StudyDentalPhoto,
          )
        : undefined;
    message.PatientDocument =
      object.PatientDocument !== undefined && object.PatientDocument !== null
        ? StartUploadSessionReq_PatientSimpleMultiFileUpload.fromPartial(
            object.PatientDocument,
          )
        : undefined;
    message.ToothUserUploadedSlice =
      object.ToothUserUploadedSlice !== undefined &&
      object.ToothUserUploadedSlice !== null
        ? StartUploadSessionReq_ToothUserUploadedSlices.fromPartial(
            object.ToothUserUploadedSlice,
          )
        : undefined;
    message.ToothUserUploadedMPRPlanes =
      object.ToothUserUploadedMPRPlanes !== undefined &&
      object.ToothUserUploadedMPRPlanes !== null
        ? StartUploadSessionReq_ToothUserUploadedMPRPlanes.fromPartial(
            object.ToothUserUploadedMPRPlanes,
          )
        : undefined;
    message.ReportStudioApplicationStateSnapshot =
      object.ReportStudioApplicationStateSnapshot !== undefined &&
      object.ReportStudioApplicationStateSnapshot !== null
        ? StartUploadSessionReq_StudioReportFileUpload.fromPartial(
            object.ReportStudioApplicationStateSnapshot,
          )
        : undefined;
    message.ReportStudioGeometry =
      object.ReportStudioGeometry !== undefined &&
      object.ReportStudioGeometry !== null
        ? StartUploadSessionReq_StudioReportFileUpload.fromPartial(
            object.ReportStudioGeometry,
          )
        : undefined;
    message.ReportDisplayAsset =
      object.ReportDisplayAsset !== undefined &&
      object.ReportDisplayAsset !== null
        ? StartUploadSessionReq_ReportSimpleFileUpload.fromPartial(
            object.ReportDisplayAsset,
          )
        : undefined;
    message.Attributes =
      object.Attributes?.map((e) => Attribute.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStartUploadSessionReq_UserSimpleFileUpload(): StartUploadSessionReq_UserSimpleFileUpload {
  return { UserID: '', FilePath: '' };
}

export const StartUploadSessionReq_UserSimpleFileUpload = {
  encode(
    message: StartUploadSessionReq_UserSimpleFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.FilePath !== '') {
      writer.uint32(18).string(message.FilePath);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_UserSimpleFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq_UserSimpleFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.FilePath = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_UserSimpleFileUpload {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      FilePath: isSet(object.FilePath) ? String(object.FilePath) : '',
    };
  },

  toJSON(message: StartUploadSessionReq_UserSimpleFileUpload): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.FilePath !== undefined && (obj.FilePath = message.FilePath);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<StartUploadSessionReq_UserSimpleFileUpload>, I>,
  >(object: I): StartUploadSessionReq_UserSimpleFileUpload {
    const message = createBaseStartUploadSessionReq_UserSimpleFileUpload();
    message.UserID = object.UserID ?? '';
    message.FilePath = object.FilePath ?? '';
    return message;
  },
};

function createBaseStartUploadSessionReq_OrganizationSimpleFileUpload(): StartUploadSessionReq_OrganizationSimpleFileUpload {
  return { OrganizationID: '', FilePath: '' };
}

export const StartUploadSessionReq_OrganizationSimpleFileUpload = {
  encode(
    message: StartUploadSessionReq_OrganizationSimpleFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.FilePath !== '') {
      writer.uint32(18).string(message.FilePath);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_OrganizationSimpleFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseStartUploadSessionReq_OrganizationSimpleFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.FilePath = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_OrganizationSimpleFileUpload {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      FilePath: isSet(object.FilePath) ? String(object.FilePath) : '',
    };
  },

  toJSON(message: StartUploadSessionReq_OrganizationSimpleFileUpload): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.FilePath !== undefined && (obj.FilePath = message.FilePath);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_OrganizationSimpleFileUpload>,
      I
    >,
  >(object: I): StartUploadSessionReq_OrganizationSimpleFileUpload {
    const message =
      createBaseStartUploadSessionReq_OrganizationSimpleFileUpload();
    message.OrganizationID = object.OrganizationID ?? '';
    message.FilePath = object.FilePath ?? '';
    return message;
  },
};

function createBaseStartUploadSessionReq_OrganizationUserSimpleFileUpload(): StartUploadSessionReq_OrganizationUserSimpleFileUpload {
  return { UserID: '', OrganizationID: '', FilePath: '' };
}

export const StartUploadSessionReq_OrganizationUserSimpleFileUpload = {
  encode(
    message: StartUploadSessionReq_OrganizationUserSimpleFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.FilePath !== '') {
      writer.uint32(26).string(message.FilePath);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_OrganizationUserSimpleFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseStartUploadSessionReq_OrganizationUserSimpleFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.FilePath = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): StartUploadSessionReq_OrganizationUserSimpleFileUpload {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      FilePath: isSet(object.FilePath) ? String(object.FilePath) : '',
    };
  },

  toJSON(
    message: StartUploadSessionReq_OrganizationUserSimpleFileUpload,
  ): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.FilePath !== undefined && (obj.FilePath = message.FilePath);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_OrganizationUserSimpleFileUpload>,
      I
    >,
  >(object: I): StartUploadSessionReq_OrganizationUserSimpleFileUpload {
    const message =
      createBaseStartUploadSessionReq_OrganizationUserSimpleFileUpload();
    message.UserID = object.UserID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.FilePath = object.FilePath ?? '';
    return message;
  },
};

function createBaseStartUploadSessionReq_PatientStudySimpleFileUpload(): StartUploadSessionReq_PatientStudySimpleFileUpload {
  return { PatientID: undefined, StudyID: undefined, FilePath: '' };
}

export const StartUploadSessionReq_PatientStudySimpleFileUpload = {
  encode(
    message: StartUploadSessionReq_PatientStudySimpleFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== undefined) {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.StudyID !== undefined) {
      writer.uint32(18).string(message.StudyID);
    }
    if (message.FilePath !== '') {
      writer.uint32(26).string(message.FilePath);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_PatientStudySimpleFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseStartUploadSessionReq_PatientStudySimpleFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.StudyID = reader.string();
          break;
        case 3:
          message.FilePath = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_PatientStudySimpleFileUpload {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : undefined,
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : undefined,
      FilePath: isSet(object.FilePath) ? String(object.FilePath) : '',
    };
  },

  toJSON(message: StartUploadSessionReq_PatientStudySimpleFileUpload): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    message.FilePath !== undefined && (obj.FilePath = message.FilePath);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_PatientStudySimpleFileUpload>,
      I
    >,
  >(object: I): StartUploadSessionReq_PatientStudySimpleFileUpload {
    const message =
      createBaseStartUploadSessionReq_PatientStudySimpleFileUpload();
    message.PatientID = object.PatientID ?? undefined;
    message.StudyID = object.StudyID ?? undefined;
    message.FilePath = object.FilePath ?? '';
    return message;
  },
};

function createBaseStartUploadSessionReq_PatientStudySimpleMultiFileUpload(): StartUploadSessionReq_PatientStudySimpleMultiFileUpload {
  return { PatientID: undefined, StudyID: undefined, FilePaths: [] };
}

export const StartUploadSessionReq_PatientStudySimpleMultiFileUpload = {
  encode(
    message: StartUploadSessionReq_PatientStudySimpleMultiFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== undefined) {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.StudyID !== undefined) {
      writer.uint32(18).string(message.StudyID);
    }
    for (const v of message.FilePaths) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_PatientStudySimpleMultiFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseStartUploadSessionReq_PatientStudySimpleMultiFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.StudyID = reader.string();
          break;
        case 3:
          message.FilePaths.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): StartUploadSessionReq_PatientStudySimpleMultiFileUpload {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : undefined,
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : undefined,
      FilePaths: Array.isArray(object?.FilePaths)
        ? object.FilePaths.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(
    message: StartUploadSessionReq_PatientStudySimpleMultiFileUpload,
  ): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    if (message.FilePaths) {
      obj.FilePaths = message.FilePaths.map((e) => e);
    } else {
      obj.FilePaths = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_PatientStudySimpleMultiFileUpload>,
      I
    >,
  >(object: I): StartUploadSessionReq_PatientStudySimpleMultiFileUpload {
    const message =
      createBaseStartUploadSessionReq_PatientStudySimpleMultiFileUpload();
    message.PatientID = object.PatientID ?? undefined;
    message.StudyID = object.StudyID ?? undefined;
    message.FilePaths = object.FilePaths?.map((e) => e) || [];
    return message;
  },
};

function createBaseStartUploadSessionReq_PatientSimpleMultiFileUpload(): StartUploadSessionReq_PatientSimpleMultiFileUpload {
  return { PatientID: '', FilePaths: [] };
}

export const StartUploadSessionReq_PatientSimpleMultiFileUpload = {
  encode(
    message: StartUploadSessionReq_PatientSimpleMultiFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    for (const v of message.FilePaths) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_PatientSimpleMultiFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseStartUploadSessionReq_PatientSimpleMultiFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 3:
          message.FilePaths.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_PatientSimpleMultiFileUpload {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      FilePaths: Array.isArray(object?.FilePaths)
        ? object.FilePaths.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: StartUploadSessionReq_PatientSimpleMultiFileUpload): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    if (message.FilePaths) {
      obj.FilePaths = message.FilePaths.map((e) => e);
    } else {
      obj.FilePaths = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_PatientSimpleMultiFileUpload>,
      I
    >,
  >(object: I): StartUploadSessionReq_PatientSimpleMultiFileUpload {
    const message =
      createBaseStartUploadSessionReq_PatientSimpleMultiFileUpload();
    message.PatientID = object.PatientID ?? '';
    message.FilePaths = object.FilePaths?.map((e) => e) || [];
    return message;
  },
};

function createBaseStartUploadSessionReq_ReportSimpleFileUpload(): StartUploadSessionReq_ReportSimpleFileUpload {
  return { ReportID: '', FilePath: '' };
}

export const StartUploadSessionReq_ReportSimpleFileUpload = {
  encode(
    message: StartUploadSessionReq_ReportSimpleFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.FilePath !== '') {
      writer.uint32(18).string(message.FilePath);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_ReportSimpleFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq_ReportSimpleFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.FilePath = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_ReportSimpleFileUpload {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      FilePath: isSet(object.FilePath) ? String(object.FilePath) : '',
    };
  },

  toJSON(message: StartUploadSessionReq_ReportSimpleFileUpload): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.FilePath !== undefined && (obj.FilePath = message.FilePath);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_ReportSimpleFileUpload>,
      I
    >,
  >(object: I): StartUploadSessionReq_ReportSimpleFileUpload {
    const message = createBaseStartUploadSessionReq_ReportSimpleFileUpload();
    message.ReportID = object.ReportID ?? '';
    message.FilePath = object.FilePath ?? '';
    return message;
  },
};

function createBaseStartUploadSessionReq_StudioReportFileUpload(): StartUploadSessionReq_StudioReportFileUpload {
  return { ReportID: '' };
}

export const StartUploadSessionReq_StudioReportFileUpload = {
  encode(
    message: StartUploadSessionReq_StudioReportFileUpload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_StudioReportFileUpload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq_StudioReportFileUpload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_StudioReportFileUpload {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: StartUploadSessionReq_StudioReportFileUpload): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_StudioReportFileUpload>,
      I
    >,
  >(object: I): StartUploadSessionReq_StudioReportFileUpload {
    const message = createBaseStartUploadSessionReq_StudioReportFileUpload();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseStartUploadSessionReq_ToothUserUploadedSlices(): StartUploadSessionReq_ToothUserUploadedSlices {
  return { ToothID: '', Slices: [] };
}

export const StartUploadSessionReq_ToothUserUploadedSlices = {
  encode(
    message: StartUploadSessionReq_ToothUserUploadedSlices,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    for (const v of message.Slices) {
      AssetContentReportUserUploadedToothSlice.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_ToothUserUploadedSlices {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq_ToothUserUploadedSlices();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.Slices.push(
            AssetContentReportUserUploadedToothSlice.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_ToothUserUploadedSlices {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      Slices: Array.isArray(object?.Slices)
        ? object.Slices.map((e: any) =>
            AssetContentReportUserUploadedToothSlice.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: StartUploadSessionReq_ToothUserUploadedSlices): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    if (message.Slices) {
      obj.Slices = message.Slices.map((e) =>
        e ? AssetContentReportUserUploadedToothSlice.toJSON(e) : undefined,
      );
    } else {
      obj.Slices = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_ToothUserUploadedSlices>,
      I
    >,
  >(object: I): StartUploadSessionReq_ToothUserUploadedSlices {
    const message = createBaseStartUploadSessionReq_ToothUserUploadedSlices();
    message.ToothID = object.ToothID ?? '';
    message.Slices =
      object.Slices?.map((e) =>
        AssetContentReportUserUploadedToothSlice.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseStartUploadSessionReq_ToothUserUploadedMPRPlanes(): StartUploadSessionReq_ToothUserUploadedMPRPlanes {
  return { ToothID: '', MPRPanes: [] };
}

export const StartUploadSessionReq_ToothUserUploadedMPRPlanes = {
  encode(
    message: StartUploadSessionReq_ToothUserUploadedMPRPlanes,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    for (const v of message.MPRPanes) {
      AssetContentReportUserUploadedMPRPlane.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_ToothUserUploadedMPRPlanes {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseStartUploadSessionReq_ToothUserUploadedMPRPlanes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.MPRPanes.push(
            AssetContentReportUserUploadedMPRPlane.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_ToothUserUploadedMPRPlanes {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      MPRPanes: Array.isArray(object?.MPRPanes)
        ? object.MPRPanes.map((e: any) =>
            AssetContentReportUserUploadedMPRPlane.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: StartUploadSessionReq_ToothUserUploadedMPRPlanes): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    if (message.MPRPanes) {
      obj.MPRPanes = message.MPRPanes.map((e) =>
        e ? AssetContentReportUserUploadedMPRPlane.toJSON(e) : undefined,
      );
    } else {
      obj.MPRPanes = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<StartUploadSessionReq_ToothUserUploadedMPRPlanes>,
      I
    >,
  >(object: I): StartUploadSessionReq_ToothUserUploadedMPRPlanes {
    const message =
      createBaseStartUploadSessionReq_ToothUserUploadedMPRPlanes();
    message.ToothID = object.ToothID ?? '';
    message.MPRPanes =
      object.MPRPanes?.map((e) =>
        AssetContentReportUserUploadedMPRPlane.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseStartUploadSessionResp(): StartUploadSessionResp {
  return { UploadSession: undefined, UploadTargets: [], Study: undefined };
}

export const StartUploadSessionResp = {
  encode(
    message: StartUploadSessionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.UploadTargets) {
      UploadTarget.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.Study !== undefined) {
      Study.encode(message.Study, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        case 2:
          message.UploadTargets.push(
            UploadTarget.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.Study = Study.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
      UploadTargets: Array.isArray(object?.UploadTargets)
        ? object.UploadTargets.map((e: any) => UploadTarget.fromJSON(e))
        : [],
      Study: isSet(object.Study) ? Study.fromJSON(object.Study) : undefined,
    };
  },

  toJSON(message: StartUploadSessionResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    if (message.UploadTargets) {
      obj.UploadTargets = message.UploadTargets.map((e) =>
        e ? UploadTarget.toJSON(e) : undefined,
      );
    } else {
      obj.UploadTargets = [];
    }
    message.Study !== undefined &&
      (obj.Study = message.Study ? Study.toJSON(message.Study) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StartUploadSessionResp>, I>>(
    object: I,
  ): StartUploadSessionResp {
    const message = createBaseStartUploadSessionResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    message.UploadTargets =
      object.UploadTargets?.map((e) => UploadTarget.fromPartial(e)) || [];
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? Study.fromPartial(object.Study)
        : undefined;
    return message;
  },
};

function createBaseStartPipelinesUploadSessionReq(): StartPipelinesUploadSessionReq {
  return { AssetID: '', PipelinesRunID: '', GeneratedReport: undefined };
}

export const StartPipelinesUploadSessionReq = {
  encode(
    message: StartPipelinesUploadSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AssetID !== '') {
      writer.uint32(10).string(message.AssetID);
    }
    if (message.PipelinesRunID !== '') {
      writer.uint32(90).string(message.PipelinesRunID);
    }
    if (message.GeneratedReport !== undefined) {
      GeneratedReportAsset.encode(
        message.GeneratedReport,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartPipelinesUploadSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartPipelinesUploadSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AssetID = reader.string();
          break;
        case 11:
          message.PipelinesRunID = reader.string();
          break;
        case 2:
          message.GeneratedReport = GeneratedReportAsset.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartPipelinesUploadSessionReq {
    return {
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
      PipelinesRunID: isSet(object.PipelinesRunID)
        ? String(object.PipelinesRunID)
        : '',
      GeneratedReport: isSet(object.GeneratedReport)
        ? GeneratedReportAsset.fromJSON(object.GeneratedReport)
        : undefined,
    };
  },

  toJSON(message: StartPipelinesUploadSessionReq): unknown {
    const obj: any = {};
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    message.PipelinesRunID !== undefined &&
      (obj.PipelinesRunID = message.PipelinesRunID);
    message.GeneratedReport !== undefined &&
      (obj.GeneratedReport = message.GeneratedReport
        ? GeneratedReportAsset.toJSON(message.GeneratedReport)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StartPipelinesUploadSessionReq>, I>>(
    object: I,
  ): StartPipelinesUploadSessionReq {
    const message = createBaseStartPipelinesUploadSessionReq();
    message.AssetID = object.AssetID ?? '';
    message.PipelinesRunID = object.PipelinesRunID ?? '';
    message.GeneratedReport =
      object.GeneratedReport !== undefined && object.GeneratedReport !== null
        ? GeneratedReportAsset.fromPartial(object.GeneratedReport)
        : undefined;
    return message;
  },
};

function createBaseAddFilesToUploadSessionReq(): AddFilesToUploadSessionReq {
  return {
    UploadSessionID: '',
    CBCT: undefined,
    IntraoralXRay: undefined,
    IOSMeshes: undefined,
    DentalPhoto: undefined,
  };
}

export const AddFilesToUploadSessionReq = {
  encode(
    message: AddFilesToUploadSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    if (message.CBCT !== undefined) {
      AssetContentStudyCBCT.encode(
        message.CBCT,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.IntraoralXRay !== undefined) {
      AssetContentStudyIntraoralXRay.encode(
        message.IntraoralXRay,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.IOSMeshes !== undefined) {
      AssetContentStudyIOSMeshes.encode(
        message.IOSMeshes,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.DentalPhoto !== undefined) {
      AssetContentStudyDentalPhoto.encode(
        message.DentalPhoto,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddFilesToUploadSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFilesToUploadSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        case 101:
          message.CBCT = AssetContentStudyCBCT.decode(reader, reader.uint32());
          break;
        case 102:
          message.IntraoralXRay = AssetContentStudyIntraoralXRay.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.IOSMeshes = AssetContentStudyIOSMeshes.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 104:
          message.DentalPhoto = AssetContentStudyDentalPhoto.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddFilesToUploadSessionReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
      CBCT: isSet(object.CBCT)
        ? AssetContentStudyCBCT.fromJSON(object.CBCT)
        : undefined,
      IntraoralXRay: isSet(object.IntraoralXRay)
        ? AssetContentStudyIntraoralXRay.fromJSON(object.IntraoralXRay)
        : undefined,
      IOSMeshes: isSet(object.IOSMeshes)
        ? AssetContentStudyIOSMeshes.fromJSON(object.IOSMeshes)
        : undefined,
      DentalPhoto: isSet(object.DentalPhoto)
        ? AssetContentStudyDentalPhoto.fromJSON(object.DentalPhoto)
        : undefined,
    };
  },

  toJSON(message: AddFilesToUploadSessionReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    message.CBCT !== undefined &&
      (obj.CBCT = message.CBCT
        ? AssetContentStudyCBCT.toJSON(message.CBCT)
        : undefined);
    message.IntraoralXRay !== undefined &&
      (obj.IntraoralXRay = message.IntraoralXRay
        ? AssetContentStudyIntraoralXRay.toJSON(message.IntraoralXRay)
        : undefined);
    message.IOSMeshes !== undefined &&
      (obj.IOSMeshes = message.IOSMeshes
        ? AssetContentStudyIOSMeshes.toJSON(message.IOSMeshes)
        : undefined);
    message.DentalPhoto !== undefined &&
      (obj.DentalPhoto = message.DentalPhoto
        ? AssetContentStudyDentalPhoto.toJSON(message.DentalPhoto)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddFilesToUploadSessionReq>, I>>(
    object: I,
  ): AddFilesToUploadSessionReq {
    const message = createBaseAddFilesToUploadSessionReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    message.CBCT =
      object.CBCT !== undefined && object.CBCT !== null
        ? AssetContentStudyCBCT.fromPartial(object.CBCT)
        : undefined;
    message.IntraoralXRay =
      object.IntraoralXRay !== undefined && object.IntraoralXRay !== null
        ? AssetContentStudyIntraoralXRay.fromPartial(object.IntraoralXRay)
        : undefined;
    message.IOSMeshes =
      object.IOSMeshes !== undefined && object.IOSMeshes !== null
        ? AssetContentStudyIOSMeshes.fromPartial(object.IOSMeshes)
        : undefined;
    message.DentalPhoto =
      object.DentalPhoto !== undefined && object.DentalPhoto !== null
        ? AssetContentStudyDentalPhoto.fromPartial(object.DentalPhoto)
        : undefined;
    return message;
  },
};

function createBaseAddFilesToUploadSessionResp(): AddFilesToUploadSessionResp {
  return { UploadSession: undefined, UploadTargets: [] };
}

export const AddFilesToUploadSessionResp = {
  encode(
    message: AddFilesToUploadSessionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.UploadTargets) {
      UploadTarget.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddFilesToUploadSessionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFilesToUploadSessionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        case 2:
          message.UploadTargets.push(
            UploadTarget.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddFilesToUploadSessionResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
      UploadTargets: Array.isArray(object?.UploadTargets)
        ? object.UploadTargets.map((e: any) => UploadTarget.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddFilesToUploadSessionResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    if (message.UploadTargets) {
      obj.UploadTargets = message.UploadTargets.map((e) =>
        e ? UploadTarget.toJSON(e) : undefined,
      );
    } else {
      obj.UploadTargets = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddFilesToUploadSessionResp>, I>>(
    object: I,
  ): AddFilesToUploadSessionResp {
    const message = createBaseAddFilesToUploadSessionResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    message.UploadTargets =
      object.UploadTargets?.map((e) => UploadTarget.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNotifyFileUploadStartedReq(): NotifyFileUploadStartedReq {
  return { FileID: '' };
}

export const NotifyFileUploadStartedReq = {
  encode(
    message: NotifyFileUploadStartedReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FileID !== '') {
      writer.uint32(10).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadStartedReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadStartedReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadStartedReq {
    return {
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: NotifyFileUploadStartedReq): unknown {
    const obj: any = {};
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadStartedReq>, I>>(
    object: I,
  ): NotifyFileUploadStartedReq {
    const message = createBaseNotifyFileUploadStartedReq();
    message.FileID = object.FileID ?? '';
    return message;
  },
};

function createBaseNotifyFileUploadStartedResp(): NotifyFileUploadStartedResp {
  return { UploadSession: undefined };
}

export const NotifyFileUploadStartedResp = {
  encode(
    message: NotifyFileUploadStartedResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadStartedResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadStartedResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadStartedResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
    };
  },

  toJSON(message: NotifyFileUploadStartedResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadStartedResp>, I>>(
    object: I,
  ): NotifyFileUploadStartedResp {
    const message = createBaseNotifyFileUploadStartedResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    return message;
  },
};

function createBaseNotifyFileUploadProgressReq(): NotifyFileUploadProgressReq {
  return { FileID: '' };
}

export const NotifyFileUploadProgressReq = {
  encode(
    message: NotifyFileUploadProgressReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FileID !== '') {
      writer.uint32(10).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadProgressReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadProgressReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadProgressReq {
    return {
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: NotifyFileUploadProgressReq): unknown {
    const obj: any = {};
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadProgressReq>, I>>(
    object: I,
  ): NotifyFileUploadProgressReq {
    const message = createBaseNotifyFileUploadProgressReq();
    message.FileID = object.FileID ?? '';
    return message;
  },
};

function createBaseNotifyFileUploadProgressResp(): NotifyFileUploadProgressResp {
  return { UploadSession: undefined };
}

export const NotifyFileUploadProgressResp = {
  encode(
    message: NotifyFileUploadProgressResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadProgressResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadProgressResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadProgressResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
    };
  },

  toJSON(message: NotifyFileUploadProgressResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadProgressResp>, I>>(
    object: I,
  ): NotifyFileUploadProgressResp {
    const message = createBaseNotifyFileUploadProgressResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    return message;
  },
};

function createBaseNotifyFileUploadFinishedReq(): NotifyFileUploadFinishedReq {
  return { FileID: '' };
}

export const NotifyFileUploadFinishedReq = {
  encode(
    message: NotifyFileUploadFinishedReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FileID !== '') {
      writer.uint32(10).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadFinishedReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadFinishedReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadFinishedReq {
    return {
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: NotifyFileUploadFinishedReq): unknown {
    const obj: any = {};
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadFinishedReq>, I>>(
    object: I,
  ): NotifyFileUploadFinishedReq {
    const message = createBaseNotifyFileUploadFinishedReq();
    message.FileID = object.FileID ?? '';
    return message;
  },
};

function createBaseNotifyFileUploadFinishedResp(): NotifyFileUploadFinishedResp {
  return { UploadSession: undefined };
}

export const NotifyFileUploadFinishedResp = {
  encode(
    message: NotifyFileUploadFinishedResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadFinishedResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadFinishedResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadFinishedResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
    };
  },

  toJSON(message: NotifyFileUploadFinishedResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadFinishedResp>, I>>(
    object: I,
  ): NotifyFileUploadFinishedResp {
    const message = createBaseNotifyFileUploadFinishedResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    return message;
  },
};

function createBaseCloseSessionReq(): CloseSessionReq {
  return { UploadSessionID: '' };
}

export const CloseSessionReq = {
  encode(
    message: CloseSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloseSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloseSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CloseSessionReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
    };
  },

  toJSON(message: CloseSessionReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CloseSessionReq>, I>>(
    object: I,
  ): CloseSessionReq {
    const message = createBaseCloseSessionReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    return message;
  },
};

function createBaseCloseSessionResp(): CloseSessionResp {
  return {
    Assets: [],
    User: undefined,
    Organization: undefined,
    Study: undefined,
    Reports: [],
    Tooth: undefined,
  };
}

export const CloseSessionResp = {
  encode(
    message: CloseSessionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(18).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Study !== undefined) {
      Study.encode(message.Study, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.Reports) {
      Report.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloseSessionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloseSessionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Assets.push(Asset.decode(reader, reader.uint32()));
          break;
        case 2:
          message.User = User.decode(reader, reader.uint32());
          break;
        case 3:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 4:
          message.Study = Study.decode(reader, reader.uint32());
          break;
        case 5:
          message.Reports.push(Report.decode(reader, reader.uint32()));
          break;
        case 6:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CloseSessionResp {
    return {
      Assets: Array.isArray(object?.Assets)
        ? object.Assets.map((e: any) => Asset.fromJSON(e))
        : [],
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      Study: isSet(object.Study) ? Study.fromJSON(object.Study) : undefined,
      Reports: Array.isArray(object?.Reports)
        ? object.Reports.map((e: any) => Report.fromJSON(e))
        : [],
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: CloseSessionResp): unknown {
    const obj: any = {};
    if (message.Assets) {
      obj.Assets = message.Assets.map((e) => (e ? Asset.toJSON(e) : undefined));
    } else {
      obj.Assets = [];
    }
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study ? Study.toJSON(message.Study) : undefined);
    if (message.Reports) {
      obj.Reports = message.Reports.map((e) =>
        e ? Report.toJSON(e) : undefined,
      );
    } else {
      obj.Reports = [];
    }
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CloseSessionResp>, I>>(
    object: I,
  ): CloseSessionResp {
    const message = createBaseCloseSessionResp();
    message.Assets = object.Assets?.map((e) => Asset.fromPartial(e)) || [];
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? Study.fromPartial(object.Study)
        : undefined;
    message.Reports = object.Reports?.map((e) => Report.fromPartial(e)) || [];
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

export interface StorageService {
  /**
   * Permission: `UserPermissions.CanEditPersonalData`: user assets
   * Permission: `OrganizationPermissions.CanEditInfo`: AssetType_Organization_Logo || AssetType_Organization_Stamp
   * Permission: `OrganizationPermissions.CanEditStaff`: AssetType_Organization_EmployeeStamp
   * Permission: `PatientPermissions.CanUploadDocuments`: AssetType_Document
   * Permission: `PatientPermissions.CanUploadCBCT`: AssetType_Study_CBCT
   * Permission: `PatientPermissions.CanUploadIOXRay`: AssetType_Study_IntraoralXRay
   * Permission: `PatientPermissions.CanUploadPanoramicXRay`: AssetType_Study_PanoramicXRay
   * Permission: `PatientPermissions.CanUploadSTL`: AssetType_Study_IOS_Meshes
   * Permission: `PatientPermissions.CanUploadDentalPhoto`: AssetType_Study_DentalPhoto
   * Permission: `ReportPermissions.CanChangeReportAssets`: report assets
   * Errors:
   * 1. Multiple images were provided for the pano study
   * - type: UserBadRequestError, Code: multiple_panoramic_xrays_provided
   */
  StartUploadSession(
    request: DeepPartial<StartUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<StartUploadSessionResp>;
  StartPipelinesUploadSession(
    request: DeepPartial<StartPipelinesUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<StartUploadSessionResp>;
  /** only if UploadSession.Closed == false */
  AddFilesToUploadSession(
    request: DeepPartial<AddFilesToUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddFilesToUploadSessionResp>;
  NotifyFileUploadStarted(
    request: DeepPartial<NotifyFileUploadStartedReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadStartedResp>;
  NotifyFileUploadProgress(
    request: DeepPartial<NotifyFileUploadProgressReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadProgressResp>;
  NotifyFileUploadFinished(
    request: DeepPartial<NotifyFileUploadFinishedReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadFinishedResp>;
  CloseSession(
    request: DeepPartial<CloseSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<CloseSessionResp>;
  FailSession(
    request: DeepPartial<FailSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<FailSessionResp>;
}

export class StorageServiceClientImpl implements StorageService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.StartUploadSession = this.StartUploadSession.bind(this);
    this.StartPipelinesUploadSession =
      this.StartPipelinesUploadSession.bind(this);
    this.AddFilesToUploadSession = this.AddFilesToUploadSession.bind(this);
    this.NotifyFileUploadStarted = this.NotifyFileUploadStarted.bind(this);
    this.NotifyFileUploadProgress = this.NotifyFileUploadProgress.bind(this);
    this.NotifyFileUploadFinished = this.NotifyFileUploadFinished.bind(this);
    this.CloseSession = this.CloseSession.bind(this);
    this.FailSession = this.FailSession.bind(this);
  }

  StartUploadSession(
    request: DeepPartial<StartUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<StartUploadSessionResp> {
    return this.rpc.unary(
      StorageServiceStartUploadSessionDesc,
      StartUploadSessionReq.fromPartial(request),
      metadata,
    );
  }

  StartPipelinesUploadSession(
    request: DeepPartial<StartPipelinesUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<StartUploadSessionResp> {
    return this.rpc.unary(
      StorageServiceStartPipelinesUploadSessionDesc,
      StartPipelinesUploadSessionReq.fromPartial(request),
      metadata,
    );
  }

  AddFilesToUploadSession(
    request: DeepPartial<AddFilesToUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddFilesToUploadSessionResp> {
    return this.rpc.unary(
      StorageServiceAddFilesToUploadSessionDesc,
      AddFilesToUploadSessionReq.fromPartial(request),
      metadata,
    );
  }

  NotifyFileUploadStarted(
    request: DeepPartial<NotifyFileUploadStartedReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadStartedResp> {
    return this.rpc.unary(
      StorageServiceNotifyFileUploadStartedDesc,
      NotifyFileUploadStartedReq.fromPartial(request),
      metadata,
    );
  }

  NotifyFileUploadProgress(
    request: DeepPartial<NotifyFileUploadProgressReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadProgressResp> {
    return this.rpc.unary(
      StorageServiceNotifyFileUploadProgressDesc,
      NotifyFileUploadProgressReq.fromPartial(request),
      metadata,
    );
  }

  NotifyFileUploadFinished(
    request: DeepPartial<NotifyFileUploadFinishedReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadFinishedResp> {
    return this.rpc.unary(
      StorageServiceNotifyFileUploadFinishedDesc,
      NotifyFileUploadFinishedReq.fromPartial(request),
      metadata,
    );
  }

  CloseSession(
    request: DeepPartial<CloseSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<CloseSessionResp> {
    return this.rpc.unary(
      StorageServiceCloseSessionDesc,
      CloseSessionReq.fromPartial(request),
      metadata,
    );
  }

  FailSession(
    request: DeepPartial<FailSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<FailSessionResp> {
    return this.rpc.unary(
      StorageServiceFailSessionDesc,
      FailSessionReq.fromPartial(request),
      metadata,
    );
  }
}

export const StorageServiceDesc = {
  serviceName: 'com.diagnocat.core.StorageService',
};

export const StorageServiceStartUploadSessionDesc: UnaryMethodDefinitionish = {
  methodName: 'StartUploadSession',
  service: StorageServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartUploadSessionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...StartUploadSessionResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const StorageServiceStartPipelinesUploadSessionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'StartPipelinesUploadSession',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return StartPipelinesUploadSessionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...StartUploadSessionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceAddFilesToUploadSessionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'AddFilesToUploadSession',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return AddFilesToUploadSessionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AddFilesToUploadSessionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceNotifyFileUploadStartedDesc: UnaryMethodDefinitionish =
  {
    methodName: 'NotifyFileUploadStarted',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return NotifyFileUploadStartedReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...NotifyFileUploadStartedResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceNotifyFileUploadProgressDesc: UnaryMethodDefinitionish =
  {
    methodName: 'NotifyFileUploadProgress',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return NotifyFileUploadProgressReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...NotifyFileUploadProgressResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceNotifyFileUploadFinishedDesc: UnaryMethodDefinitionish =
  {
    methodName: 'NotifyFileUploadFinished',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return NotifyFileUploadFinishedReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...NotifyFileUploadFinishedResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceCloseSessionDesc: UnaryMethodDefinitionish = {
  methodName: 'CloseSession',
  service: StorageServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CloseSessionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CloseSessionResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const StorageServiceFailSessionDesc: UnaryMethodDefinitionish = {
  methodName: 'FailSession',
  service: StorageServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FailSessionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...FailSessionResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import React, { ChangeEvent, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Switch.module.scss';

type SwitchSize = 'default' | 'small';

export type SwitchProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  size?: SwitchSize;
  className?: string;
  labelClassName?: string;
  testID?: string;
  disabled?: boolean;
  name?: string;
  children?: ReactNode;
};

export const Switch: React.FC<SwitchProps> = (props) => {
  const {
    onChange,
    checked,
    size,
    className,
    labelClassName,
    testID,
    disabled,
    name,
    children,
  } = props;
  return (
    <label
      className={cn(
        styles.checkboxLabel,
        disabled && styles.disabled,
        size === 'small' && styles.small,
        className,
      )}
      data-testid={testID}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
      />
      <div className={styles.checkboxDiv} />
      {children && (
        <span className={cn(styles.label, labelClassName)}>{children}</span>
      )}
    </label>
  );
};

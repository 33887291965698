import { Report } from '@/shared/api/protocol_gen/model/dto_report';

export const getGroupedReportsBySourceStudyIDs = (reports: Report[]) => {
  const groupBySourceStudyIDs = (reports: Report[]) => {
    const groupedReports = reports.reduce(
      (acc, report) => {
        const sourceStudyIDs = [...report.SourceStudyIDs].sort();
        const key = sourceStudyIDs.join(',');
        return {
          ...acc,
          [key]: [...(acc[key] || []), report],
        };
      },
      {} as Record<string, Report[]>,
    );
    return Object.values(groupedReports);
  };

  return groupBySourceStudyIDs(reports);
};

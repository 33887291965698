import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { JAW, Quartiles } from '../config/toothConfig';

import { getJawQuartile } from './getJawQuartile';

export const makeJawObject = (teeth: Partial<Tooth>[]) =>
  teeth.reduce(
    (acc, tooth) => {
      const quartile = getJawQuartile(tooth.Numeration?.ISO ?? 0);

      return {
        ...acc,
        [quartile]: teeth.filter(({ Numeration }) => {
          const ISONumber = Numeration?.ISO;

          // TODO type error?
          return JAW[quartile].includes(ISONumber ?? 0);
        }),
      };
    },
    {
      quartile1: [] as Tooth[],
      quartile2: [] as Tooth[],
      quartile3: [] as Tooth[],
      quartile4: [] as Tooth[],
    } as Record<Quartiles, Tooth[]>,
  );

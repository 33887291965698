import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@/shared/ui';

import styles from './RoiColumn.module.scss';

type RoiColumnProps = {
  roi: number[];
  isAllTeethInROI?: boolean;
  className?: string;
  testID?: string;
};

const ROI_LENGTH_WITHOUT_TOOLTIP = 6;

export const RoiColumn: FC<RoiColumnProps> = (props) => {
  const { className, testID, roi, isAllTeethInROI = false } = props;

  const roiRangeList = roi
    .sort((a, b) => a - b)
    .reduce(
      (acc, toothNumber, index) =>
        index === 0 || Math.abs(toothNumber - roi[index - 1]) > 1
          ? [...acc, [toothNumber]]
          : [
              ...acc.slice(0, acc.length - 1),
              [...acc[acc.length - 1], toothNumber],
            ],
      [] as number[][],
    )
    .map((range) =>
      range.length === 1
        ? range[0].toString()
        : `${range[0]}-${range[range.length - 1]}`,
    )
    .join(', ');

  const shouldDisplayTooltip = roi.length > ROI_LENGTH_WITHOUT_TOOLTIP;

  return shouldDisplayTooltip ? (
    <Tooltip.Primary
      content={
        <div className={styles.tooltipContent}>
          <h6 className={cn(styles.tooltipTitle, 'h6')}>
            <FormattedMessage
              id="reportRow.roi"
              defaultMessage="Region of interest"
            />
          </h6>
          {roiRangeList}
        </div>
      }
      side="top"
    >
      <p className={cn(styles.container, 'p2', className)} data-testid={testID}>
        {isAllTeethInROI ? (
          <FormattedMessage id="global.all" defaultMessage="All" />
        ) : (
          roiRangeList
        )}
      </p>
    </Tooltip.Primary>
  ) : (
    <p className={cn(styles.container, 'p2', className)} data-testid={testID}>
      {isAllTeethInROI ? (
        <FormattedMessage id="global.all" defaultMessage="All" />
      ) : (
        roiRangeList
      )}
    </p>
  );
};

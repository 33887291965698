/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../google/protobuf/timestamp';

export const protobufPackage = 'com.diagnocat.auth';

export interface Identity {
  Phone: string | undefined;
  Email: string | undefined;
}

export interface AuthState {
  Objective: AuthState_AuthObjective;
  Status: AuthState_AuthStatus;
  CurrentStep: number;
  CreatedAt: Date | undefined;
  ExpiresAt: Date | undefined;
}

export enum AuthState_AuthObjective {
  INVALID_OBJECTIVE = 0,
  SIGNUP = 100,
  SIGNUP_VIA_SHARED_PATIENT_INVITATION = 101,
  SIGNUP_VIA_ORGANIZATION_INVITATION = 102,
  SIGNUP_VIA_SALESMAN_INVITATION = 103,
  SIGNIN = 104,
  RECOVER = 105,
  UNRECOGNIZED = -1,
}

export function authState_AuthObjectiveFromJSON(
  object: any,
): AuthState_AuthObjective {
  switch (object) {
    case 0:
    case 'INVALID_OBJECTIVE':
      return AuthState_AuthObjective.INVALID_OBJECTIVE;
    case 100:
    case 'SIGNUP':
      return AuthState_AuthObjective.SIGNUP;
    case 101:
    case 'SIGNUP_VIA_SHARED_PATIENT_INVITATION':
      return AuthState_AuthObjective.SIGNUP_VIA_SHARED_PATIENT_INVITATION;
    case 102:
    case 'SIGNUP_VIA_ORGANIZATION_INVITATION':
      return AuthState_AuthObjective.SIGNUP_VIA_ORGANIZATION_INVITATION;
    case 103:
    case 'SIGNUP_VIA_SALESMAN_INVITATION':
      return AuthState_AuthObjective.SIGNUP_VIA_SALESMAN_INVITATION;
    case 104:
    case 'SIGNIN':
      return AuthState_AuthObjective.SIGNIN;
    case 105:
    case 'RECOVER':
      return AuthState_AuthObjective.RECOVER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AuthState_AuthObjective.UNRECOGNIZED;
  }
}

export function authState_AuthObjectiveToJSON(
  object: AuthState_AuthObjective,
): string {
  switch (object) {
    case AuthState_AuthObjective.INVALID_OBJECTIVE:
      return 'INVALID_OBJECTIVE';
    case AuthState_AuthObjective.SIGNUP:
      return 'SIGNUP';
    case AuthState_AuthObjective.SIGNUP_VIA_SHARED_PATIENT_INVITATION:
      return 'SIGNUP_VIA_SHARED_PATIENT_INVITATION';
    case AuthState_AuthObjective.SIGNUP_VIA_ORGANIZATION_INVITATION:
      return 'SIGNUP_VIA_ORGANIZATION_INVITATION';
    case AuthState_AuthObjective.SIGNUP_VIA_SALESMAN_INVITATION:
      return 'SIGNUP_VIA_SALESMAN_INVITATION';
    case AuthState_AuthObjective.SIGNIN:
      return 'SIGNIN';
    case AuthState_AuthObjective.RECOVER:
      return 'RECOVER';
    case AuthState_AuthObjective.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum AuthState_AuthStatus {
  INVALID_STATUS = 0,
  IN_PROGRESS = 100,
  SUCCESSFUL = 101,
  FAILED = 102,
  UNRECOGNIZED = -1,
}

export function authState_AuthStatusFromJSON(
  object: any,
): AuthState_AuthStatus {
  switch (object) {
    case 0:
    case 'INVALID_STATUS':
      return AuthState_AuthStatus.INVALID_STATUS;
    case 100:
    case 'IN_PROGRESS':
      return AuthState_AuthStatus.IN_PROGRESS;
    case 101:
    case 'SUCCESSFUL':
      return AuthState_AuthStatus.SUCCESSFUL;
    case 102:
    case 'FAILED':
      return AuthState_AuthStatus.FAILED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AuthState_AuthStatus.UNRECOGNIZED;
  }
}

export function authState_AuthStatusToJSON(
  object: AuthState_AuthStatus,
): string {
  switch (object) {
    case AuthState_AuthStatus.INVALID_STATUS:
      return 'INVALID_STATUS';
    case AuthState_AuthStatus.IN_PROGRESS:
      return 'IN_PROGRESS';
    case AuthState_AuthStatus.SUCCESSFUL:
      return 'SUCCESSFUL';
    case AuthState_AuthStatus.FAILED:
      return 'FAILED';
    case AuthState_AuthStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

function createBaseIdentity(): Identity {
  return { Phone: undefined, Email: undefined };
}

export const Identity = {
  encode(
    message: Identity,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Phone !== undefined) {
      writer.uint32(10).string(message.Phone);
    }
    if (message.Email !== undefined) {
      writer.uint32(18).string(message.Email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Identity {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Phone = reader.string();
          break;
        case 2:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Identity {
    return {
      Phone: isSet(object.Phone) ? String(object.Phone) : undefined,
      Email: isSet(object.Email) ? String(object.Email) : undefined,
    };
  },

  toJSON(message: Identity): unknown {
    const obj: any = {};
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Identity>, I>>(object: I): Identity {
    const message = createBaseIdentity();
    message.Phone = object.Phone ?? undefined;
    message.Email = object.Email ?? undefined;
    return message;
  },
};

function createBaseAuthState(): AuthState {
  return {
    Objective: 0,
    Status: 0,
    CurrentStep: 0,
    CreatedAt: undefined,
    ExpiresAt: undefined,
  };
}

export const AuthState = {
  encode(
    message: AuthState,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Objective !== 0) {
      writer.uint32(800).int32(message.Objective);
    }
    if (message.Status !== 0) {
      writer.uint32(808).int32(message.Status);
    }
    if (message.CurrentStep !== 0) {
      writer.uint32(816).uint32(message.CurrentStep);
    }
    if (message.CreatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.CreatedAt),
        writer.uint32(2402).fork(),
      ).ldelim();
    }
    if (message.ExpiresAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.ExpiresAt),
        writer.uint32(2410).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          message.Objective = reader.int32() as any;
          break;
        case 101:
          message.Status = reader.int32() as any;
          break;
        case 102:
          message.CurrentStep = reader.uint32();
          break;
        case 300:
          message.CreatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 301:
          message.ExpiresAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuthState {
    return {
      Objective: isSet(object.Objective)
        ? authState_AuthObjectiveFromJSON(object.Objective)
        : 0,
      Status: isSet(object.Status)
        ? authState_AuthStatusFromJSON(object.Status)
        : 0,
      CurrentStep: isSet(object.CurrentStep) ? Number(object.CurrentStep) : 0,
      CreatedAt: isSet(object.CreatedAt)
        ? fromJsonTimestamp(object.CreatedAt)
        : undefined,
      ExpiresAt: isSet(object.ExpiresAt)
        ? fromJsonTimestamp(object.ExpiresAt)
        : undefined,
    };
  },

  toJSON(message: AuthState): unknown {
    const obj: any = {};
    message.Objective !== undefined &&
      (obj.Objective = authState_AuthObjectiveToJSON(message.Objective));
    message.Status !== undefined &&
      (obj.Status = authState_AuthStatusToJSON(message.Status));
    message.CurrentStep !== undefined &&
      (obj.CurrentStep = Math.round(message.CurrentStep));
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = message.CreatedAt.toISOString());
    message.ExpiresAt !== undefined &&
      (obj.ExpiresAt = message.ExpiresAt.toISOString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AuthState>, I>>(
    object: I,
  ): AuthState {
    const message = createBaseAuthState();
    message.Objective = object.Objective ?? 0;
    message.Status = object.Status ?? 0;
    message.CurrentStep = object.CurrentStep ?? 0;
    message.CreatedAt = object.CreatedAt ?? undefined;
    message.ExpiresAt = object.ExpiresAt ?? undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { PATHS, UserBadRequestError } from '@/shared/config';
import { ApiError } from '@/shared/api/api';
import { confirmEmailErrorMessages } from '@/shared/i18n';

export const useConfirmEmailError = (): {
  callConfirmEmailError: (error: ApiError) => void;
  inlineError: string;
} => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const [inlineError, setInlineError] = useState<string>('');

  const callConfirmEmailError = (error: ApiError) => {
    const parsedMessage = JSON.parse(error.message);
    const errorCode = parsedMessage.code ?? parsedMessage.text;

    if (errorCode === UserBadRequestError.NON_RESENDABLE_AUTH_METHOD) {
      return navigate(PATHS.main);
    }

    if (
      errorCode === UserBadRequestError.CODE_RETRIES_LIMIT ||
      errorCode === UserBadRequestError.CODE_IS_EXPIRED
    ) {
      return navigate(PATHS.signUp);
    }

    if (errorCode in confirmEmailErrorMessages) {
      setInlineError(formatMessage(confirmEmailErrorMessages[errorCode]));
    }
  };

  return {
    callConfirmEmailError,
    inlineError,
  };
};

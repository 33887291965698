export const AbsoluteBlock = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { style, ...restProps } = props;
  return (
    <div
      style={{
        ...style,
        position: 'absolute',
        display: 'block',
        width: '100%',
        height: '100%',
      }}
      {...restProps}
    >
      {props.children}
    </div>
  );
};

import {
  components,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  Props,
} from 'react-select';

import { Icon } from '../Icon/Icon';

import { OptionType } from './Select.types';

const ClearIndicator = (props: ClearIndicatorProps<OptionType>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.ClearIndicator {...props}>
    <Icon name="close" size={24} />
  </components.ClearIndicator>
);

const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <Icon name="arrowDown" size={24} />
  </components.DropdownIndicator>
);

export const selectComponents: Props<OptionType>['components'] = {
  IndicatorSeparator: null,
  ClearIndicator,
  DropdownIndicator,
};

import { defineMessages } from 'react-intl';

import { InvoiceStatuses } from '@/widgets/Invoices/config/type';

export const invoiceStatusDefaultMessages = defineMessages<InvoiceStatuses>({
  paid: {
    id: 'invoices.status.paid',
    defaultMessage: 'Paid',
  },
  new: {
    id: 'invoices.status.new',
    defaultMessage: 'New',
  },
  overdue: {
    id: 'invoices.status.overdue',
    defaultMessage: 'Overdue',
  },
});

import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { trackPageView } from '@/shared/lib';

import { ReportLayout, i18n } from '@/entities/reports';

import { MaskTooltip, MaskFilters } from '@/features/renderMasks';

import { Conclusion } from '@/widgets/Conclusion';
import { IOXRayReportRender } from '@/widgets/IOXRayReportRender';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';

import { IOXRayToothCardList } from '../IOXRayToothCardList/IOXRayToothCardList';
import { Toolbar } from '../Toolbar/Toolbar';
import { IOXRayImageSlider } from '../IOXrayImageSlider/IOXRayImageSlider';
import { IOXRayChangeTeethNumberModal } from '../IOXRayChangeTeethNumberModal/IOXRayChangeTeethNumberModal';

import styles from './IOXRayReport.module.scss';

export const IOXRayReport = () => {
  const { patientID, reportID } = useParams();
  const { formatMessage } = useIntl();

  useEffect(() => {
    trackPageView('IOXRayReport Page Viewed');
  }, []);

  return (
    <>
      <ReportLayout.LeftColumn>
        <h1>{formatMessage(i18n[ReportType.ReportType_IOXRay_GP])}</h1>
        <IOXRayReportRender>
          <Toolbar />
          <MaskFilters className={styles.maskFilters} />
          <MaskTooltip />
          <IOXRayImageSlider />
        </IOXRayReportRender>
      </ReportLayout.LeftColumn>
      <ReportLayout.RightColumn>
        <Report2DToothChart />
        <IOXRayToothCardList
          reportID={reportID as string}
          patientID={patientID as string}
        />

        <Conclusion />

        <ReportActions
          className={styles.reportActions}
          patientID={patientID as string}
          reportID={reportID as string}
        />
      </ReportLayout.RightColumn>
      {/* Modals */}
      <IOXRayChangeTeethNumberModal />
    </>
  );
};

import difference from 'lodash/difference';
import { intersection, sortBy } from 'lodash';

// TODO: When import file contains import to the graphic, then graphic try to run when test is running and fails.
// Because of this the test fails too.
import {
  JAW,
  LOWER_JAW_TEETH_ISO_NUMBERS,
  UPPER_JAW_TEETH_ISO_NUMBERS,
} from '@/shared/config';

import { GroupedIOXRayImages, IOXrayImageInterface } from '..';

// remove type to fix fsd

// TODO: write documentation for FMX matrix

const getCenterToothNumber = (teethNumbers: number[]) =>
  teethNumbers[Math.floor(teethNumbers.length / 2)];

const isUpperJaw = (teethNumbers: number[]) =>
  teethNumbers.every((tooth) => UPPER_JAW_TEETH_ISO_NUMBERS.includes(tooth));
const isLowerJaw = (teethNumbers: number[]) =>
  teethNumbers.every((tooth) => LOWER_JAW_TEETH_ISO_NUMBERS.includes(tooth));
const isTeethInBothJaws = (teethNumbers: number[]) =>
  !isUpperJaw(teethNumbers) && !isLowerJaw(teethNumbers);

const isUpperLeft = (teethNumbers: number[]) => {
  const sortedTeeth = teethNumbers.sort();

  const upperLeftQuartile = [...JAW.quartile1, ...JAW.primaryQuartile1];
  const isTeethInFirstQuartile =
    difference(sortedTeeth, upperLeftQuartile).length === 0;
  const centerToothNumber = getCenterToothNumber(sortedTeeth);

  return (
    isTeethInFirstQuartile &&
    ((centerToothNumber <= 18 && centerToothNumber >= 15) ||
      (centerToothNumber <= 55 && centerToothNumber >= 53))
  );
};
const isUpperRight = (teethNumbers: number[]) => {
  const upperRightQuartile = [...JAW.quartile2, ...JAW.primaryQuartile2];
  const isTeethInSecondQuartile =
    difference(teethNumbers, upperRightQuartile).length === 0;
  const centerToothNumber = getCenterToothNumber(teethNumbers);

  return (
    isTeethInSecondQuartile &&
    ((centerToothNumber <= 28 && centerToothNumber >= 25) ||
      (centerToothNumber <= 65 && centerToothNumber >= 63))
  );
};
const isUpperMiddle = (teethNumbers: number[]) => {
  return (
    isUpperJaw(teethNumbers) &&
    !isUpperLeft(teethNumbers) &&
    !isUpperRight(teethNumbers)
  );
};

const isMiddleLeft = (teethNumbers: number[]) => {
  const leftJawsTeethNumbers = [
    ...JAW.quartile1,
    ...JAW.quartile4,
    ...JAW.primaryQuartile1,
    ...JAW.primaryQuartile4,
  ];
  const isOnlyLeftTeeth = teethNumbers.some((tooth) =>
    leftJawsTeethNumbers.includes(tooth),
  );

  return isTeethInBothJaws(teethNumbers) && isOnlyLeftTeeth;
};
const isMiddleRight = (teethNumbers: number[]) => {
  const rightJawsTeethNumbers = [
    ...JAW.quartile2,
    ...JAW.quartile3,
    ...JAW.primaryQuartile2,
    ...JAW.primaryQuartile3,
  ];
  const isOnlyRightTeeth = teethNumbers.some((tooth) =>
    rightJawsTeethNumbers.includes(tooth),
  );

  return isTeethInBothJaws(teethNumbers) && isOnlyRightTeeth;
};
const isMiddleMiddle = (teethNumbers: number[]) => {
  return (
    isTeethInBothJaws(teethNumbers) &&
    !isMiddleLeft(teethNumbers) &&
    !isMiddleRight(teethNumbers)
  );
};

const isLowerLeft = (teethNumbers: number[]) => {
  const lowerLeftQuartiles = [...JAW.quartile4, ...JAW.primaryQuartile4];
  const isTeethInThirdQuartile =
    difference(teethNumbers, lowerLeftQuartiles).length === 0;
  const centerToothNumber = getCenterToothNumber(teethNumbers);
  const hasFirstTooth = teethNumbers.includes(41);

  return (
    isTeethInThirdQuartile &&
    !hasFirstTooth &&
    ((centerToothNumber <= 48 && centerToothNumber >= 45) ||
      (centerToothNumber <= 85 && centerToothNumber >= 83))
  );
};
const isLowerRight = (teethNumbers: number[]) => {
  const lowerRightQuartiles = [...JAW.quartile3, ...JAW.primaryQuartile3];
  const isTeethInThirdQuartile =
    difference(teethNumbers, lowerRightQuartiles).length === 0;
  const centerToothNumber = getCenterToothNumber(teethNumbers);
  const hasFirstToothInQuartile = teethNumbers.includes(31);

  return (
    isTeethInThirdQuartile &&
    !hasFirstToothInQuartile &&
    ((centerToothNumber <= 38 && centerToothNumber >= 35) ||
      (centerToothNumber <= 75 && centerToothNumber >= 73))
  );
};
const isLowerMiddle = (teethNumbers: number[]) => {
  return (
    isLowerJaw(teethNumbers) &&
    !isLowerLeft(teethNumbers) &&
    !isLowerRight(teethNumbers)
  );
};

const sortSubImagesByCenterTooth = (
  IOXRayImagesInterface: IOXrayImageInterface[],
) =>
  sortBy(IOXRayImagesInterface, (imageInterface) => {
    const { teethISONumbers } = imageInterface;
    const centerNumber = getCenterToothNumber(teethISONumbers);
    const maxNumber = Math.max(...teethISONumbers);

    return [maxNumber, centerNumber];
  });

const sortSubImagesInMiddlePartitionByCenterTooth = (
  IOXRayImagesInterface: IOXrayImageInterface[],
) =>
  sortBy(IOXRayImagesInterface, (subImage) => {
    const { teethISONumbers } = subImage;
    const upperTeeth = intersection(
      teethISONumbers,
      UPPER_JAW_TEETH_ISO_NUMBERS,
    );
    const centerNumber = getCenterToothNumber(upperTeeth);
    const maxNumber = Math.max(...upperTeeth);

    return [maxNumber, centerNumber];
  });

// TODO: Need to use generic which should extends from simple type { teethISONumbers: number[] }
export const groupIOXRayImagesByPartition = (
  iOXRayImages: IOXrayImageInterface[],
): GroupedIOXRayImages => {
  const groupedIOXRayImages: GroupedIOXRayImages = {
    UpperLeft: [],
    UpperMiddle: [],
    UpperRight: [],
    MiddleLeft: [],
    MiddleMiddle: [],
    MiddleRight: [],
    LowerLeft: [],
    LowerMiddle: [],
    LowerRight: [],
    // TODO: [1|h] add field for images with no detected teeth
  };

  if (!iOXRayImages) {
    return groupedIOXRayImages;
  }

  iOXRayImages.forEach((IOXRayImageInterface) => {
    if (isUpperLeft(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.UpperLeft.push(IOXRayImageInterface);
    } else if (isUpperRight(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.UpperRight.push(IOXRayImageInterface);
    } else if (isUpperMiddle(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.UpperMiddle.push(IOXRayImageInterface);
    } else if (isMiddleLeft(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.MiddleLeft.push(IOXRayImageInterface);
    } else if (isMiddleRight(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.MiddleRight.push(IOXRayImageInterface);
    } else if (isMiddleMiddle(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.MiddleMiddle.push(IOXRayImageInterface);
    } else if (isLowerLeft(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.LowerLeft.push(IOXRayImageInterface);
    } else if (isLowerRight(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.LowerRight.push(IOXRayImageInterface);
    } else if (isLowerMiddle(IOXRayImageInterface.teethISONumbers)) {
      groupedIOXRayImages.LowerMiddle.push(IOXRayImageInterface);
    }
  });

  groupedIOXRayImages.UpperLeft = sortSubImagesByCenterTooth(
    groupedIOXRayImages.UpperLeft,
  ).reverse();
  groupedIOXRayImages.UpperRight = sortSubImagesByCenterTooth(
    groupedIOXRayImages.UpperRight,
  );
  groupedIOXRayImages.UpperMiddle = sortSubImagesInMiddlePartitionByCenterTooth(
    groupedIOXRayImages.UpperMiddle,
  );
  groupedIOXRayImages.MiddleLeft = sortSubImagesInMiddlePartitionByCenterTooth(
    groupedIOXRayImages.MiddleLeft,
  ).reverse();
  groupedIOXRayImages.MiddleRight = sortSubImagesInMiddlePartitionByCenterTooth(
    groupedIOXRayImages.MiddleRight,
  );
  groupedIOXRayImages.LowerLeft = sortSubImagesByCenterTooth(
    groupedIOXRayImages.LowerLeft,
  ).reverse();
  groupedIOXRayImages.LowerRight = sortSubImagesByCenterTooth(
    groupedIOXRayImages.LowerRight,
  );
  groupedIOXRayImages.LowerMiddle = sortSubImagesByCenterTooth(
    groupedIOXRayImages.LowerMiddle,
  ).reverse();

  return groupedIOXRayImages;
};

import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Portal } from '../Portal/Portal';
import { Icon } from '../Icon/Icon';

import styles from './Popup.module.scss';

export type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  testID?: string;
  shouldRenderCloseIconButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  children?: ReactNode;
  showHeader?: boolean;
  overlayClassname?: string;
  bodyClassname?: string;
};

export const Popup: FC<PopupProps> = ({
  isOpen,
  onClose,
  className,
  testID,
  shouldRenderCloseIconButton = true,
  shouldCloseOnOverlayClick = true,
  showHeader = true,
  children,
  overlayClassname,
  bodyClassname,
}) => {
  if (!isOpen) return null;

  return (
    <Portal>
      <div className={cn(styles.popup, className)} data-testid={testID}>
        <div
          className={cn(styles.overlay, overlayClassname)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(shouldCloseOnOverlayClick ? { onClick: onClose } : {})}
        />
        <div className={styles.container}>
          {showHeader && (
            <header
              className={cn(
                styles.header,
                shouldRenderCloseIconButton && styles.rowReverse,
              )}
            >
              {shouldRenderCloseIconButton && (
                <Icon
                  className={styles.crossBtn}
                  name="close"
                  size={40}
                  onClick={onClose}
                />
              )}
            </header>
          )}

          <div className={cn(styles.popupBody, bodyClassname)}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};

import { FC, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Description } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { billingModel } from '@/entities/billing';
import { userModel } from '@/entities/user';
import { ModalID, modalModel } from '@/entities/modal';
import {
  organizationModel,
  useCountriesOptions,
} from '@/entities/organization';

import styles from './BillingInformation.module.scss';

type BillingInfoProps = {
  className?: string;
};

export const BillingInformation: FC<BillingInfoProps> = (props) => {
  const { className } = props;

  const { formatMessage } = useIntl();

  const billingAccount = useAppSelector(billingModel.selectors.selectAccount);

  const { PersonalData } = useAppSelector(
    userModel.selectors.selectCurrentUser,
  );

  const currentOrganization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const locale = useAppSelector(userModel.selectors.selectUserLocale);

  const countryOptions = useCountriesOptions(locale);

  const billingAccountCountry = billingAccount?.Country;

  const currentCountry = useMemo(
    () =>
      countryOptions?.find(
        (countryOption) => countryOption.value === billingAccountCountry,
      )?.label ?? '',
    [countryOptions, billingAccountCountry],
  );

  const dispatch = useAppDispatch();

  const handleEditClick = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.BillingInformationModal,
        data: {
          isEdit: true,
        },
      }),
    );
  };

  const firstName = PersonalData?.FirstName;
  const lastName = PersonalData?.LastName;
  const email = PersonalData?.Emails?.at(0);
  const phoneNumber = billingAccount?.BillingInformation?.Phone;
  const companyName = billingAccount?.BillingInformation?.CompanyName;
  const taxID = billingAccount?.BillingInformation?.TaxID;
  const region = billingAccount?.BillingInformation?.Address?.Region;
  const city = billingAccount?.BillingInformation?.Address?.City;
  const zipCode = billingAccount?.BillingInformation?.Address?.ZIP;
  const companyAddress = billingAccount?.BillingInformation?.Address?.Address;

  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.header}>
        <h2 className={cn(styles.title, 'h4')}>
          <FormattedMessage
            id="billingInfo.title"
            defaultMessage="Billing information"
          />
        </h2>

        <Button
          variant="tertiary"
          size="medium"
          disabled={!currentOrganization?.YourPermissions?.CanEditBillingInfo}
          onClick={handleEditClick}
        >
          <FormattedMessage id="global.edit" defaultMessage="Edit" />
        </Button>
      </div>

      <div className={styles.innerContainer}>
        {firstName && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.firstName',
              defaultMessage: 'First name',
            })}
            fontType="p2"
          >
            {firstName}
          </Description>
        )}

        {lastName && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.lastName',
              defaultMessage: 'Last name',
            })}
            fontType="p2"
          >
            {lastName}
          </Description>
        )}

        {email && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.email',
              defaultMessage: 'Email',
            })}
            fontType="p2"
          >
            {email}
          </Description>
        )}

        {phoneNumber && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.phoneNumber',
              defaultMessage: 'Phone number',
            })}
            fontType="p2"
          >
            {phoneNumber}
          </Description>
        )}

        {companyName && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.companyName',
              defaultMessage: 'Company name',
            })}
            fontType="p2"
          >
            {companyName}
          </Description>
        )}

        {taxID && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.taxID',
              defaultMessage: 'Tax ID',
            })}
            fontType="p2"
          >
            {taxID}
          </Description>
        )}

        {currentCountry && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.country',
              defaultMessage: 'Country',
            })}
            fontType="p2"
          >
            {currentCountry}
          </Description>
        )}

        {region && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.stateRegion',
              defaultMessage: 'State/Region',
            })}
            fontType="p2"
          >
            {region}
          </Description>
        )}

        {city && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.city',
              defaultMessage: 'City',
            })}
            fontType="p2"
          >
            {city}
          </Description>
        )}

        {zipCode && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.zipCode',
              defaultMessage: 'ZIP code',
            })}
            fontType="p2"
          >
            {zipCode}
          </Description>
        )}

        {companyAddress && (
          <Description
            className={styles.description}
            label={formatMessage({
              id: 'global.companyAddress',
              defaultMessage: 'Company address',
            })}
            fontType="p2"
          >
            {companyAddress}
          </Description>
        )}
      </div>
    </div>
  );
};

export const getDateFormatStringByLocale = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'dd.MM.yyyy';

    case 'en-GB':
      return 'dd/MM/yyyy';

    case 'zh-CN':
      return 'yyyy-MM-dd';

    default:
      return 'MM-dd-yyyy';
  }
};

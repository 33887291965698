import { defineMessages } from 'react-intl';

import { Gender } from '@/shared/api/protocol_gen/model/dto_base';

export const gender = defineMessages<Gender>({
  [Gender.GenderMale]: {
    id: 'gender.male',
    defaultMessage: 'Male',
  },
  [Gender.GenderFemale]: {
    id: 'gender.female',
    defaultMessage: 'Female',
  },
  [Gender.GenderOther]: {
    id: 'gender.other',
    defaultMessage: 'Other',
  },
  [Gender.GenderUnspecified]: {
    id: 'gender.unspecified',
    defaultMessage: 'Unspecified',
  },
  [Gender.UNRECOGNIZED]: {
    id: 'gender.unrecognized',
    defaultMessage: 'Unrecognized',
  },
});

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface OrganizationPermissions {
  /** - only OrganizationUserRoleTypeOwner */
  CanView: boolean;
  /**
   * if the user has Owner role in the organization
   * - name
   * - type
   * - contacts
   * - logo
   * -stamp
   */
  CanEditInfo: boolean;
  /** - only OrganizationUserRoleTypeOwner */
  CanViewSettings: boolean;
  /** - only OrganizationUserRoleTypeOwner */
  CanEditSettings: boolean;
  /** - only OrganizationUserRoleTypeOwner */
  CanEditBranches: boolean;
  /** user with any organization role */
  CanViewStaff: boolean;
  /**
   * - OrganizationUserRoleTypeOwner
   * - OrganizationUserRoleTypeClinicalLeader
   */
  CanEditStaff: boolean;
  /** user with any organization role */
  CanCreateTheirOwnPatients: boolean;
  /**
   * - OrganizationUserRoleTypeOwner
   * - OrganizationUserRoleTypeClinicalLeader
   * - OrganizationUserRoleTypeNonClinicalMember
   */
  CanCreateOtherDoctorsPatients: boolean;
  /** user with any organization role */
  CanViewMyPatients: boolean;
  /**
   * - OrganizationUserRoleTypeOwner
   * - OrganizationUserRoleTypeClinicalLeader
   * - OrganizationUserRoleTypeNonClinicalMember
   */
  CanViewAllPatients: boolean;
  /** - only OrganizationUserRoleTypeOwner */
  CanEditBillingInfo: boolean;
  /**
   * - OrganizationUserRoleTypeOwner
   * - OrganizationUserRoleTypeClinicalLeader
   */
  CanViewStatistics: boolean;
}

function createBaseOrganizationPermissions(): OrganizationPermissions {
  return {
    CanView: false,
    CanEditInfo: false,
    CanViewSettings: false,
    CanEditSettings: false,
    CanEditBranches: false,
    CanViewStaff: false,
    CanEditStaff: false,
    CanCreateTheirOwnPatients: false,
    CanCreateOtherDoctorsPatients: false,
    CanViewMyPatients: false,
    CanViewAllPatients: false,
    CanEditBillingInfo: false,
    CanViewStatistics: false,
  };
}

export const OrganizationPermissions = {
  encode(
    message: OrganizationPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanView === true) {
      writer.uint32(8).bool(message.CanView);
    }
    if (message.CanEditInfo === true) {
      writer.uint32(16).bool(message.CanEditInfo);
    }
    if (message.CanViewSettings === true) {
      writer.uint32(24).bool(message.CanViewSettings);
    }
    if (message.CanEditSettings === true) {
      writer.uint32(32).bool(message.CanEditSettings);
    }
    if (message.CanEditBranches === true) {
      writer.uint32(40).bool(message.CanEditBranches);
    }
    if (message.CanViewStaff === true) {
      writer.uint32(48).bool(message.CanViewStaff);
    }
    if (message.CanEditStaff === true) {
      writer.uint32(56).bool(message.CanEditStaff);
    }
    if (message.CanCreateTheirOwnPatients === true) {
      writer.uint32(88).bool(message.CanCreateTheirOwnPatients);
    }
    if (message.CanCreateOtherDoctorsPatients === true) {
      writer.uint32(96).bool(message.CanCreateOtherDoctorsPatients);
    }
    if (message.CanViewMyPatients === true) {
      writer.uint32(104).bool(message.CanViewMyPatients);
    }
    if (message.CanViewAllPatients === true) {
      writer.uint32(112).bool(message.CanViewAllPatients);
    }
    if (message.CanEditBillingInfo === true) {
      writer.uint32(168).bool(message.CanEditBillingInfo);
    }
    if (message.CanViewStatistics === true) {
      writer.uint32(176).bool(message.CanViewStatistics);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanView = reader.bool();
          break;
        case 2:
          message.CanEditInfo = reader.bool();
          break;
        case 3:
          message.CanViewSettings = reader.bool();
          break;
        case 4:
          message.CanEditSettings = reader.bool();
          break;
        case 5:
          message.CanEditBranches = reader.bool();
          break;
        case 6:
          message.CanViewStaff = reader.bool();
          break;
        case 7:
          message.CanEditStaff = reader.bool();
          break;
        case 11:
          message.CanCreateTheirOwnPatients = reader.bool();
          break;
        case 12:
          message.CanCreateOtherDoctorsPatients = reader.bool();
          break;
        case 13:
          message.CanViewMyPatients = reader.bool();
          break;
        case 14:
          message.CanViewAllPatients = reader.bool();
          break;
        case 21:
          message.CanEditBillingInfo = reader.bool();
          break;
        case 22:
          message.CanViewStatistics = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationPermissions {
    return {
      CanView: isSet(object.CanView) ? Boolean(object.CanView) : false,
      CanEditInfo: isSet(object.CanEditInfo)
        ? Boolean(object.CanEditInfo)
        : false,
      CanViewSettings: isSet(object.CanViewSettings)
        ? Boolean(object.CanViewSettings)
        : false,
      CanEditSettings: isSet(object.CanEditSettings)
        ? Boolean(object.CanEditSettings)
        : false,
      CanEditBranches: isSet(object.CanEditBranches)
        ? Boolean(object.CanEditBranches)
        : false,
      CanViewStaff: isSet(object.CanViewStaff)
        ? Boolean(object.CanViewStaff)
        : false,
      CanEditStaff: isSet(object.CanEditStaff)
        ? Boolean(object.CanEditStaff)
        : false,
      CanCreateTheirOwnPatients: isSet(object.CanCreateTheirOwnPatients)
        ? Boolean(object.CanCreateTheirOwnPatients)
        : false,
      CanCreateOtherDoctorsPatients: isSet(object.CanCreateOtherDoctorsPatients)
        ? Boolean(object.CanCreateOtherDoctorsPatients)
        : false,
      CanViewMyPatients: isSet(object.CanViewMyPatients)
        ? Boolean(object.CanViewMyPatients)
        : false,
      CanViewAllPatients: isSet(object.CanViewAllPatients)
        ? Boolean(object.CanViewAllPatients)
        : false,
      CanEditBillingInfo: isSet(object.CanEditBillingInfo)
        ? Boolean(object.CanEditBillingInfo)
        : false,
      CanViewStatistics: isSet(object.CanViewStatistics)
        ? Boolean(object.CanViewStatistics)
        : false,
    };
  },

  toJSON(message: OrganizationPermissions): unknown {
    const obj: any = {};
    message.CanView !== undefined && (obj.CanView = message.CanView);
    message.CanEditInfo !== undefined &&
      (obj.CanEditInfo = message.CanEditInfo);
    message.CanViewSettings !== undefined &&
      (obj.CanViewSettings = message.CanViewSettings);
    message.CanEditSettings !== undefined &&
      (obj.CanEditSettings = message.CanEditSettings);
    message.CanEditBranches !== undefined &&
      (obj.CanEditBranches = message.CanEditBranches);
    message.CanViewStaff !== undefined &&
      (obj.CanViewStaff = message.CanViewStaff);
    message.CanEditStaff !== undefined &&
      (obj.CanEditStaff = message.CanEditStaff);
    message.CanCreateTheirOwnPatients !== undefined &&
      (obj.CanCreateTheirOwnPatients = message.CanCreateTheirOwnPatients);
    message.CanCreateOtherDoctorsPatients !== undefined &&
      (obj.CanCreateOtherDoctorsPatients =
        message.CanCreateOtherDoctorsPatients);
    message.CanViewMyPatients !== undefined &&
      (obj.CanViewMyPatients = message.CanViewMyPatients);
    message.CanViewAllPatients !== undefined &&
      (obj.CanViewAllPatients = message.CanViewAllPatients);
    message.CanEditBillingInfo !== undefined &&
      (obj.CanEditBillingInfo = message.CanEditBillingInfo);
    message.CanViewStatistics !== undefined &&
      (obj.CanViewStatistics = message.CanViewStatistics);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationPermissions>, I>>(
    object: I,
  ): OrganizationPermissions {
    const message = createBaseOrganizationPermissions();
    message.CanView = object.CanView ?? false;
    message.CanEditInfo = object.CanEditInfo ?? false;
    message.CanViewSettings = object.CanViewSettings ?? false;
    message.CanEditSettings = object.CanEditSettings ?? false;
    message.CanEditBranches = object.CanEditBranches ?? false;
    message.CanViewStaff = object.CanViewStaff ?? false;
    message.CanEditStaff = object.CanEditStaff ?? false;
    message.CanCreateTheirOwnPatients =
      object.CanCreateTheirOwnPatients ?? false;
    message.CanCreateOtherDoctorsPatients =
      object.CanCreateOtherDoctorsPatients ?? false;
    message.CanViewMyPatients = object.CanViewMyPatients ?? false;
    message.CanViewAllPatients = object.CanViewAllPatients ?? false;
    message.CanEditBillingInfo = object.CanEditBillingInfo ?? false;
    message.CanViewStatistics = object.CanViewStatistics ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

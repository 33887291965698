import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import {
  ENDODONTIC_PATHOLOGY_GROUP,
  ENDO_REPORT_GROUPS,
} from '../config/constants';
import { makeEndodonticGroup } from '../lib/makeEndodonticGroup';
import { makePathologyGroup } from '../lib/makePathologyGroup';
import * as assetsModel from '../model';

// TODO add tests
export const useGetEndodonticMedicalImages = (reportID: string) => {
  const { formatMessage } = useIntl();

  const reportAssets = useAppSelector(
    assetsModel.selectors.selectByReportID(reportID),
  );

  const endodonticGroups = ENDO_REPORT_GROUPS.map((group) =>
    makeEndodonticGroup(group, reportAssets, formatMessage),
  );

  const pathologyMedicalImagesImagesGroup = makePathologyGroup(
    ENDODONTIC_PATHOLOGY_GROUP,
    reportAssets,
  );

  endodonticGroups.push(pathologyMedicalImagesImagesGroup);

  return endodonticGroups;
};

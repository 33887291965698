import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Input, Modal } from '@/shared/ui';
import { PATHS } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';
import { userModel } from '@/entities/user';

import {
  getFullAccessFormSchema,
  GetFullAccessFormPayload,
} from '../config/formConfig';

import styles from './GetFullAccessModal.module.scss';

export type PermissionsModalProps = {
  className?: string;
};

export const GetFullAccessModal: FC<PermissionsModalProps> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { visible } = useAppSelector((state) => state.modal.GetFullAccessModal);
  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.GetFullAccessModal));
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<GetFullAccessFormPayload>({
    resolver: yupResolver(getFullAccessFormSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<GetFullAccessFormPayload> = async (data) => {
    const { phoneNumber } = data;

    navigate(PATHS.additionalInfo, { state: { phoneNumber } });

    handleClose();
  };

  const userEmail = user?.PersonalData?.Emails[0];

  const userPhoneNumber = user?.PersonalData?.Phones[0];

  // Set default
  useEffect(() => {
    if (userPhoneNumber) {
      reset({ phoneNumber: userPhoneNumber });
    }
  }, [userPhoneNumber, reset]);

  return (
    <Modal
      title={formatMessage({
        id: 'getFullAccess.title',
        defaultMessage: 'Get full access',
      })}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      borderless
      okButtonText={formatMessage({
        id: 'getFullAccess.getFullAccess',
        defaultMessage: 'Get full access',
      })}
      okButtonProps={{
        type: 'submit',
        form: 'getFullAccessForm',
        disabled: !isValid,
        loading: isSubmitting,
      }}
    >
      <div className={cn(styles.subTitle, 'p2')}>
        <p className="p2">
          <FormattedMessage
            id="getFullAccess.email"
            defaultMessage="Your company will be registered on your email:"
          />{' '}
        </p>

        <p className="p2 bold">{userEmail}</p>
      </div>

      <form id="getFullAccessForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="phoneNumber"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Input
              ref={ref}
              value={value}
              name={name}
              inputMode="tel"
              type="tel"
              onBlur={onBlur}
              onChange={onChange}
              label={formatMessage({
                id: 'getFullAccess.phoneNumber',
                defaultMessage: 'Phone number',
              })}
              error={error?.message}
              autoComplete="tel"
            />
          )}
        />
      </form>
    </Modal>
  );
};

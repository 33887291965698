import { compose, filter, map, prop, propEq } from 'ramda';

import { ConditionInterface } from '../config/types';

export const getConditionIDs = map<ConditionInterface, string>(prop<keyof ConditionInterface>('id'));

export const getConditionIDsByColor = (
  color: string,
  conditionInterface: ConditionInterface[],
) =>
  compose(getConditionIDs, filter(propEq('color', color)))(conditionInterface);

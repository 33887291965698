import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@/shared/api/api';
import {
  CreateOrganizationReq,
  CreateOrganizationResp,
  DeleteOrganizationReq,
  DeleteOrganizationResp,
  //   HackSetOrganizationUserContactsReq,
  OrganizationServiceClientImpl,
  SetOrganizationContactsReq,
  SetOrganizationContactsResp,
  SetOrganizationNameReq,
  SetOrganizationNameResp,
  SetOrganizationSettingsReq,
  SetOrganizationSettingsResp,
  SetOrganizationTypeReq,
  SetOrganizationTypeResp,
  SetOrganizationUserJobPositionsReq,
  SetOrganizationUserJobPositionsResp,
  SetOrganizationUserNameReq,
  SetOrganizationUserNameResp,
} from '@/shared/api/protocol_gen/api/core/svc_organization';
import { LocalStorageKey, SliceName } from '@/shared/config';
import { createThunkGenerator } from '@/shared/lib';

type OrganizationRequests = keyof OrganizationServiceClientImpl;

const generateOrganizationThunk = createThunkGenerator<OrganizationRequests>(
  SliceName.organization,
  'organization',
);

export const createOrganization = generateOrganizationThunk<
  CreateOrganizationReq,
  CreateOrganizationResp
>('CreateOrganization', api.organization.CreateOrganization);

export const setOrganizationName = generateOrganizationThunk<
  SetOrganizationNameReq,
  SetOrganizationNameResp
>('SetOrganizationName', api.organization.SetOrganizationName);
export const setOrganizationType = generateOrganizationThunk<
  SetOrganizationTypeReq,
  SetOrganizationTypeResp
>('SetOrganizationType', api.organization.SetOrganizationType);
export const setOrganizationContacts = generateOrganizationThunk<
  SetOrganizationContactsReq,
  SetOrganizationContactsResp
>('SetOrganizationContacts', api.organization.SetOrganizationContacts);

export const setOrganizationSettings = generateOrganizationThunk<
  SetOrganizationSettingsReq,
  SetOrganizationSettingsResp
>('SetOrganizationSettings', api.organization.SetOrganizationSettings);

export const deleteOrganization = generateOrganizationThunk<
  DeleteOrganizationReq,
  DeleteOrganizationResp
>('DeleteOrganization', api.organization.DeleteOrganization);

export const setCurrentOrganizationByID = createAsyncThunk(
  'organization/setCurrentOrganizationID',
  async (organizationID: string) => {
    if (organizationID) {
      localStorage.setItem(LocalStorageKey.organizationID, organizationID);
    }

    return organizationID;
  },
);

export const setOrganizationUserJobPositions = generateOrganizationThunk<
  SetOrganizationUserJobPositionsReq,
  SetOrganizationUserJobPositionsResp
>(
  'SetOrganizationUserJobPositions',
  api.organization.SetOrganizationUserJobPositions,
);

export const setOrganizationUserName = generateOrganizationThunk<
  SetOrganizationUserNameReq,
  SetOrganizationUserNameResp
>('SetOrganizationUserName', api.organization.SetOrganizationUserName);

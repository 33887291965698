import React from 'react';
import cn from 'classnames';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import styles from './Tabs.module.scss';

type TabSize = 'normal' | 'small';

type TabsListProps = TabsPrimitive.TabsListProps & { size?: TabSize };

type TabsTriggerProps = TabsPrimitive.TabsTriggerProps & { size?: TabSize };

const sizeStyle: Record<TabSize, string> = {
  normal: styles.sizeNormal,
  small: styles.sizeSmall,
};

const InternalTabsRoot: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TabsPrimitive.TabsProps
> = (props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <TabsPrimitive.Root
      ref={ref}
      className={cn(styles.root, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </TabsPrimitive.Root>
  );
};

const InternalTabsTrigger: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  TabsTriggerProps
> = (props, ref) => {
  const { className, children, size = 'normal', ...rest } = props;

  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        styles.trigger,
        sizeStyle[size],
        size === 'small' && 'p3',
        size === 'normal' && 'p1',
        className,
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </TabsPrimitive.Trigger>
  );
};

const InternalTabsList: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TabsListProps
> = (props, ref) => {
  const { className, children, size = 'normal', ...rest } = props;

  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn(styles.list, sizeStyle[size], className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </TabsPrimitive.List>
  );
};

const TabsList = React.forwardRef<HTMLDivElement, TabsListProps>(
  InternalTabsList,
);

const TabsTrigger = React.forwardRef<HTMLButtonElement, TabsTriggerProps>(
  InternalTabsTrigger,
);

const TabsRoot = React.forwardRef<HTMLDivElement, TabsPrimitive.TabsProps>(
  InternalTabsRoot,
);

type CompoundedComponent = {
  Root: typeof TabsRoot;
  List: typeof TabsList;
  Trigger: typeof TabsTrigger;
  Content: typeof TabsPrimitive.Content;
};

export const Tabs: CompoundedComponent = {
  Root: TabsRoot,
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsPrimitive.Content,
};

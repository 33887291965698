import { FC } from 'react';
import cn from 'classnames';

import eye from '@/shared/assets/images/eye.png';

import styles from './SpinnerEye.module.scss';

type SpinnerEyeProps = {
  size?: number;
  className?: string;
  testID?: string;
};

export const SpinnerEye: FC<SpinnerEyeProps> = (props) => {
  const { size = 28, className, testID } = props;

  return (
    <img
      src={eye}
      alt="Spinner Eye"
      style={{ width: size, height: size }}
      className={cn(styles.container, className)}
      data-testid={testID}
    />
  );
};

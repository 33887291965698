import React, { RefObject, useState } from 'react';
import RcDatePicker, { registerLocale } from 'react-datepicker';
import InputMask, { ReactInputMask } from 'react-input-mask';
import { PulseLoader } from 'react-spinners';
import cn from 'classnames';
import {
  de,
  enGB,
  enUS,
  es,
  fr,
  he,
  it,
  ja,
  pl,
  pt,
  ru,
  zhCN,
} from 'date-fns/locale';

import {
  getDateFormatStringByLocale,
  getDatePlaceholderByLocale,
} from '@/shared/lib';

import { Icon } from '../Icon/Icon';
import { InputLabel } from '../InputLabel/InputLabel';
import { ErrorText } from '../ErrorText/ErrorText';
import { CURRENT_YEAR, MIN_DATE_OF_BIRTH } from '../../lib/ISO';

import { getMaskByLocale } from './lib/getMaskByLocale';
import styles from './DatePicker.module.scss';

import './DatePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('ru', ru);
registerLocale('fr', fr);
registerLocale('de', de);
registerLocale('it', it);
registerLocale('es', es);
registerLocale('ja', ja);
registerLocale('pt', pt);
registerLocale('zh-CN', zhCN);
registerLocale('he', he);
registerLocale('pl', pl);

export interface DatePickerProps {
  locale?: string;
  ref?: unknown;
  label?: string | JSX.Element;
  value?: Date;
  error?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  testID?: string;
  onChange?: (val: Date | null) => void;
  datepickerClassname?: string;
  maxDate?: Date;
  minDate?: Date;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    ref,
    locale = 'en-US',
    placeholder,
    label,
    value,
    error,
    required,
    disabled,
    loading,
    className,
    testID,
    onChange,
    maxDate = new Date(String(CURRENT_YEAR + 5)),
    minDate = new Date(MIN_DATE_OF_BIRTH),
    datepickerClassname,
  } = props;
  const [focus, setFocus] = useState(false);

  return (
    <div className={cn(className, styles.container)}>
      <InputLabel label={label} required={required} />
      <div className={styles.inputCont}>
        <RcDatePicker
          data-testid={testID}
          className={cn(
            styles.input,
            focus && styles.focus,
            disabled && styles.disabled,
            error && styles.error,
            datepickerClassname,
          )}
          dateFormat={getDateFormatStringByLocale(locale)}
          placeholderText={placeholder ?? getDatePlaceholderByLocale(locale)}
          calendarStartDay={1}
          selected={value}
          onChange={(date) => onChange?.(date)}
          onCalendarOpen={() => setFocus(true)}
          onCalendarClose={() => setFocus(false)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          locale={locale}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          customInput={
            <InputMask
              ref={ref as RefObject<ReactInputMask>}
              mask={getMaskByLocale(locale)}
            />
          }
        />
        {loading ? (
          <div className={`${styles.icon} ${styles.loadingIcon}`}>
            <PulseLoader size={4} />
          </div>
        ) : (
          <Icon
            name="calendar"
            className={cn(
              styles.icon,
              focus && styles.focus,
              disabled && styles.disabled,
            )}
          />
        )}
      </div>
      <ErrorText error={error} withInput />
    </div>
  );
};

import { FileWithPath } from 'react-dropzone';
import { MessageDescriptor } from 'react-intl';

import {
  UPLOADING_IMAGE_ACCEPTED_FORMATS,
  UPLOADING_IMAGE_MAX_SIZE,
} from '../config';

type FormatMessageFunction = (
  messageDescriptor: MessageDescriptor,
  values?: Record<string, string>,
) => string;

export const validateImageFile = (
  image: FileWithPath,
  onError: (message: string) => void,
  formatMessage: FormatMessageFunction,
) => {
  if (
    !UPLOADING_IMAGE_ACCEPTED_FORMATS.some((format) =>
      image?.path?.includes(format),
    )
  ) {
    onError(
      formatMessage({
        id: 'uploadImage.wrongFormat',
        defaultMessage:
          'Wrong file format: The selected file format is not supported. Please upload a file in JPEG, JPG, or PNG format.',
      }),
    );
    return true;
  }
  if (image.size > UPLOADING_IMAGE_MAX_SIZE) {
    onError(
      formatMessage({
        id: 'uploadImage.sizeError',
        defaultMessage:
          'The file is too big: The file size exceeds the limit. The maximum allowable size is 5 MB.',
      }),
    );
    return true;
  }

  return false;
};

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

const isPblCondition = (condition: Condition) =>
  condition.Localizations.length &&
  condition.Localizations.some(
    (localization) => localization.PeriodontalBoneLossLandmarks,
  );

export const filterPblConditions = (conditions: Condition[]) =>
  conditions.filter(isPblCondition);

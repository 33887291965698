import { useState, useEffect } from 'react';
import { TouchableState } from '../TouchableLayer/TouchableLayer';
import { Annotation, AnnotationEditContext } from './Annotation';
import { RenderAnnotation } from './RenderAnnotation';

export const EditableSomething = ({
  context,
  annotation,
  touchableState,
  pixelSpacing,
  onFinishedChanging,
  onStepChange,
}: {
  context: AnnotationEditContext;
  annotation: Annotation;
  touchableState: TouchableState;
  pixelSpacing: [number, number];
  onFinishedChanging: (x: Annotation) => void;
  // onStepChange is required when editable is complicated enough and we cannot finish creating it when touch is up
  // so we send the state of annotation back and it gets back here and we can continue to make changes after that
  // for example see 'angle' annotation with multiple steps to create
  onStepChange: (annotation: Annotation, context: AnnotationEditContext) => void;
}) => {
  const [annotationState, setAnnotationState] = useState<Annotation>(annotation);

  useEffect(() => {
    // console.log(touchableState);
    if (touchableState.kind === 'none') {
      return;
    }
    // transform touchableState to annotationState
    if (annotation.kind === 'ruler' || annotation.kind === 'arrow' || annotation.kind === 'line') {
      if (context.value === 'creation' || context.value === 'dragEndingPoint') {
        setAnnotationState({
          ...touchableState,
          x1: annotation.x1,
          y1: annotation.y1,
          kind: annotation.kind,
        });
      } else if (context.value === 'dragStartingPoint') {
        setAnnotationState({ ...annotation, x1: touchableState.x2, y1: touchableState.y2 });
      }

      if (touchableState.kind === 'up') {
        if (
          annotationState.x1 === annotationState.x2 &&
          annotationState.y1 === annotationState.y2
        ) {
          return;
        }
        onFinishedChanging(annotationState);
      }
    } else if (annotation.kind === 'angle') {
      if (context.value === 'creation') {
        setAnnotationState({
          ...touchableState,
          x3: annotation.x3,
          y3: annotation.y3,
          kind: annotation.kind,
        });

        if (touchableState.kind === 'up') {
          onStepChange(annotationState, { kind: 'angle', value: 'creationStep2' });
          return;
        }
      } else if (context.value === 'creationStep2') {
        setAnnotationState({
          x1: annotation.x1,
          y1: annotation.y1,
          x2: annotation.x2,
          y2: annotation.y2,
          x3: touchableState.x2,
          y3: touchableState.y2,
          kind: annotation.kind,
        });
      } else if (context.value === 'dragStartingPoint') {
        setAnnotationState({ ...annotation, x1: touchableState.x2, y1: touchableState.y2 });
      } else if (context.value === 'dragMidPoint') {
        setAnnotationState({ ...annotation, x2: touchableState.x2, y2: touchableState.y2 });
      } else if (context.value === 'dragEndingPoint') {
        setAnnotationState({ ...annotation, x3: touchableState.x2, y3: touchableState.y2 });
      }
      if (touchableState.kind === 'up') {
        onFinishedChanging(annotationState);
        return;
      }
    }
  }, [annotation, touchableState, onFinishedChanging, context]);
  return <RenderAnnotation annotation={annotationState} pixelSpacing={pixelSpacing} />;
};

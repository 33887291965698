import React, { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';

import {
  Input,
  WidgetCard,
  TableWithInfiniteScroll,
  Result,
} from '@/shared/ui';
import { NEW_LINE, SliceName } from '@/shared/config';
import sadCatImg from '@/shared/assets/images/sad-cat.png';

import {
  DoctorInterface,
  useGetOrganizationDoctorsInterfaces,
} from '@/entities/organization';

import { RemoveStaffMemberModal } from '../RemoveStaffMember/RemoveStaffMember';
import { useGetColumnsScheme } from '../../hooks/useGetColumnsScheme';

import styles from './StaffMembers.module.scss';

type StaffMembersProps = {
  className?: string;
};

export const StaffMembers: FC<StaffMembersProps> = (props) => {
  const { className } = props;

  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [search, setSearch] = useState('');
  const [deletingUserId, setDeletingUserId] = useState('');

  const { formatMessage } = useIntl();

  const companyDoctors = useGetOrganizationDoctorsInterfaces();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleOpenCloseRemoveModal = () => {
    setRemoveModalOpened(!removeModalOpened);
  };

  const handleOpenRemoveModal = (userId: string) => {
    setDeletingUserId(userId);
    setRemoveModalOpened(!removeModalOpened);
  };

  const columnsScheme = useGetColumnsScheme(handleOpenRemoveModal);

  const doctors = useMemo(() => {
    const filtered =
      companyDoctors?.filter(
        (doc) =>
          doc.fullName.toLowerCase().includes(search.toLowerCase()) ||
          doc.specializations
            .join()
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          doc.roles.join().toLowerCase().includes(search.toLowerCase()),
      ) || [];

    return filtered;
  }, [search, companyDoctors]);

  const showNothingFound = !doctors.length && Boolean(search);

  const isDeletingUserInvited = useMemo(
    () =>
      doctors.some(
        (doctor) =>
          doctor.doctorInfo &&
          'InvitationID' in doctor.doctorInfo &&
          doctor.id === deletingUserId,
      ),
    [deletingUserId, doctors],
  );

  return (
    <div className={cn(className, styles.container)}>
      <Input
        className={styles.search}
        type="search"
        name="search-staff"
        value={search}
        placeholder={formatMessage({
          id: 'companySettings.searchPlaceholder',
          defaultMessage: 'Search by name, job position, or access level',
        })}
        icon="search"
        onChange={handleSearch}
      />
      <WidgetCard className={styles.widgetCard}>
        <TableWithInfiniteScroll
          listData={doctors}
          reFetchList={() => {}}
          itemCounter={100}
          clickOnRow={() => {}}
          columnsScheme={columnsScheme as ColumnDef<DoctorInterface>[]}
          className={styles.table}
          loadingState={SliceName.access}
          showEmpty={showNothingFound}
          emptyComponent={
            <Result
              className={styles.emptySearchResult}
              icon={
                <img
                  src={sadCatImg}
                  width={130}
                  height={130}
                  alt={formatMessage({
                    id: 'imgAltText.sadCat',
                    defaultMessage: 'Sad cat',
                  })}
                />
              }
              text={formatMessage(
                {
                  id: 'companySettings.emptySearchResult',
                  defaultMessage:
                    'It looks like there are no team members with these search parameters.{newLine}Try to change search or filtering parameters.',
                },
                { newLine: NEW_LINE },
              )}
            />
          }
        />

        <RemoveStaffMemberModal
          isOpen={removeModalOpened}
          onCancel={handleOpenCloseRemoveModal}
          deletingUserId={deletingUserId}
          isInvite={isDeletingUserInvited}
        />
      </WidgetCard>
    </div>
  );
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface PatientPermissions {
  /**
   * - users with OrganizationUserRoleTypeOwner/OrganizationUserRoleTypeClinicalLeader/OrganizationUserRoleTypeNonClinicalMember role in the organization <br>
   * - users with any patient role (team member or sharing) <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanViewPatient` == false
   */
  CanView: boolean;
  /**
   * - users with OrganizationUserRoleTypeOwner/OrganizationUserRoleTypeClinicalLeader/OrganizationUserRoleTypeNonClinicalMember role in the organization <br>
   * - users with any patient role (team member or sharing) <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanViewPersonalData` == false <br>
   */
  CanViewPersonalData: boolean;
  /**
   * - users with OrganizationUserRoleTypeOwner/OrganizationUserRoleTypeClinicalLeader/OrganizationUserRoleTypeNonClinicalMember role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == false <br>
   * - only patient team members if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == true
   */
  CanEditPersonalData: boolean;
  /**
   * - users with OrganizationUserRoleTypeOwner/OrganizationUserRoleTypeClinicalLeader/OrganizationUserRoleTypeNonClinicalMember role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == false <br>
   * - only patient team members if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == true
   */
  CanEditPatient: boolean;
  /** all organization employees if they can view patient */
  CanViewTeam: boolean;
  /** users with OrganizationUserRoleTypeOwner/OrganizationUserRoleTypeClinicalLeader/OrganizationUserRoleTypeNonClinicalMember role in the organization <br> */
  CanEditTeam: boolean;
  /** all organization employees if they can view patient */
  CanViewSharings: boolean;
  /** all organization employees if they can view patient */
  CanShare: boolean;
  /** users with Owner or Administrative role in the organization */
  CanCancelOthersSharings: boolean;
  /** all organization employees if they can view patient */
  CanViewClinicalCaseDescription: boolean;
  /** all organization employees if they can view patient */
  CanEditClinicalCaseDescription: boolean;
  /** all organization employees if they can view patient */
  CanViewTasks: boolean;
  /** all organization employees if they can view patient */
  CanEditTasks: boolean;
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanRequestReport` == false <br>
   * - patient team members <br>
   */
  CanRequestReport: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   */
  CanUploadCBCT: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   */
  CanUploadIOXRay: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   */
  CanUploadPanoramicXRay: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   */
  CanUploadSTL: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   */
  CanUploadDentalPhoto: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   */
  CanUploadDocuments: boolean;
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - users with any patient role (team member or sharing) <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanViewPatient` == false
   */
  CanViewStudies: boolean;
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - users with any patient role (team member or sharing) <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanViewPatient` == false
   */
  CanViewReports: boolean;
}

function createBasePatientPermissions(): PatientPermissions {
  return {
    CanView: false,
    CanViewPersonalData: false,
    CanEditPersonalData: false,
    CanEditPatient: false,
    CanViewTeam: false,
    CanEditTeam: false,
    CanViewSharings: false,
    CanShare: false,
    CanCancelOthersSharings: false,
    CanViewClinicalCaseDescription: false,
    CanEditClinicalCaseDescription: false,
    CanViewTasks: false,
    CanEditTasks: false,
    CanRequestReport: false,
    CanUploadCBCT: false,
    CanUploadIOXRay: false,
    CanUploadPanoramicXRay: false,
    CanUploadSTL: false,
    CanUploadDentalPhoto: false,
    CanUploadDocuments: false,
    CanViewStudies: false,
    CanViewReports: false,
  };
}

export const PatientPermissions = {
  encode(
    message: PatientPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanView === true) {
      writer.uint32(8).bool(message.CanView);
    }
    if (message.CanViewPersonalData === true) {
      writer.uint32(16).bool(message.CanViewPersonalData);
    }
    if (message.CanEditPersonalData === true) {
      writer.uint32(24).bool(message.CanEditPersonalData);
    }
    if (message.CanEditPatient === true) {
      writer.uint32(32).bool(message.CanEditPatient);
    }
    if (message.CanViewTeam === true) {
      writer.uint32(40).bool(message.CanViewTeam);
    }
    if (message.CanEditTeam === true) {
      writer.uint32(48).bool(message.CanEditTeam);
    }
    if (message.CanViewSharings === true) {
      writer.uint32(64).bool(message.CanViewSharings);
    }
    if (message.CanShare === true) {
      writer.uint32(72).bool(message.CanShare);
    }
    if (message.CanCancelOthersSharings === true) {
      writer.uint32(80).bool(message.CanCancelOthersSharings);
    }
    if (message.CanViewClinicalCaseDescription === true) {
      writer.uint32(88).bool(message.CanViewClinicalCaseDescription);
    }
    if (message.CanEditClinicalCaseDescription === true) {
      writer.uint32(96).bool(message.CanEditClinicalCaseDescription);
    }
    if (message.CanViewTasks === true) {
      writer.uint32(168).bool(message.CanViewTasks);
    }
    if (message.CanEditTasks === true) {
      writer.uint32(176).bool(message.CanEditTasks);
    }
    if (message.CanRequestReport === true) {
      writer.uint32(408).bool(message.CanRequestReport);
    }
    if (message.CanUploadCBCT === true) {
      writer.uint32(808).bool(message.CanUploadCBCT);
    }
    if (message.CanUploadIOXRay === true) {
      writer.uint32(816).bool(message.CanUploadIOXRay);
    }
    if (message.CanUploadPanoramicXRay === true) {
      writer.uint32(824).bool(message.CanUploadPanoramicXRay);
    }
    if (message.CanUploadSTL === true) {
      writer.uint32(832).bool(message.CanUploadSTL);
    }
    if (message.CanUploadDentalPhoto === true) {
      writer.uint32(840).bool(message.CanUploadDentalPhoto);
    }
    if (message.CanUploadDocuments === true) {
      writer.uint32(848).bool(message.CanUploadDocuments);
    }
    if (message.CanViewStudies === true) {
      writer.uint32(1608).bool(message.CanViewStudies);
    }
    if (message.CanViewReports === true) {
      writer.uint32(1616).bool(message.CanViewReports);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanView = reader.bool();
          break;
        case 2:
          message.CanViewPersonalData = reader.bool();
          break;
        case 3:
          message.CanEditPersonalData = reader.bool();
          break;
        case 4:
          message.CanEditPatient = reader.bool();
          break;
        case 5:
          message.CanViewTeam = reader.bool();
          break;
        case 6:
          message.CanEditTeam = reader.bool();
          break;
        case 8:
          message.CanViewSharings = reader.bool();
          break;
        case 9:
          message.CanShare = reader.bool();
          break;
        case 10:
          message.CanCancelOthersSharings = reader.bool();
          break;
        case 11:
          message.CanViewClinicalCaseDescription = reader.bool();
          break;
        case 12:
          message.CanEditClinicalCaseDescription = reader.bool();
          break;
        case 21:
          message.CanViewTasks = reader.bool();
          break;
        case 22:
          message.CanEditTasks = reader.bool();
          break;
        case 51:
          message.CanRequestReport = reader.bool();
          break;
        case 101:
          message.CanUploadCBCT = reader.bool();
          break;
        case 102:
          message.CanUploadIOXRay = reader.bool();
          break;
        case 103:
          message.CanUploadPanoramicXRay = reader.bool();
          break;
        case 104:
          message.CanUploadSTL = reader.bool();
          break;
        case 105:
          message.CanUploadDentalPhoto = reader.bool();
          break;
        case 106:
          message.CanUploadDocuments = reader.bool();
          break;
        case 201:
          message.CanViewStudies = reader.bool();
          break;
        case 202:
          message.CanViewReports = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientPermissions {
    return {
      CanView: isSet(object.CanView) ? Boolean(object.CanView) : false,
      CanViewPersonalData: isSet(object.CanViewPersonalData)
        ? Boolean(object.CanViewPersonalData)
        : false,
      CanEditPersonalData: isSet(object.CanEditPersonalData)
        ? Boolean(object.CanEditPersonalData)
        : false,
      CanEditPatient: isSet(object.CanEditPatient)
        ? Boolean(object.CanEditPatient)
        : false,
      CanViewTeam: isSet(object.CanViewTeam)
        ? Boolean(object.CanViewTeam)
        : false,
      CanEditTeam: isSet(object.CanEditTeam)
        ? Boolean(object.CanEditTeam)
        : false,
      CanViewSharings: isSet(object.CanViewSharings)
        ? Boolean(object.CanViewSharings)
        : false,
      CanShare: isSet(object.CanShare) ? Boolean(object.CanShare) : false,
      CanCancelOthersSharings: isSet(object.CanCancelOthersSharings)
        ? Boolean(object.CanCancelOthersSharings)
        : false,
      CanViewClinicalCaseDescription: isSet(
        object.CanViewClinicalCaseDescription,
      )
        ? Boolean(object.CanViewClinicalCaseDescription)
        : false,
      CanEditClinicalCaseDescription: isSet(
        object.CanEditClinicalCaseDescription,
      )
        ? Boolean(object.CanEditClinicalCaseDescription)
        : false,
      CanViewTasks: isSet(object.CanViewTasks)
        ? Boolean(object.CanViewTasks)
        : false,
      CanEditTasks: isSet(object.CanEditTasks)
        ? Boolean(object.CanEditTasks)
        : false,
      CanRequestReport: isSet(object.CanRequestReport)
        ? Boolean(object.CanRequestReport)
        : false,
      CanUploadCBCT: isSet(object.CanUploadCBCT)
        ? Boolean(object.CanUploadCBCT)
        : false,
      CanUploadIOXRay: isSet(object.CanUploadIOXRay)
        ? Boolean(object.CanUploadIOXRay)
        : false,
      CanUploadPanoramicXRay: isSet(object.CanUploadPanoramicXRay)
        ? Boolean(object.CanUploadPanoramicXRay)
        : false,
      CanUploadSTL: isSet(object.CanUploadSTL)
        ? Boolean(object.CanUploadSTL)
        : false,
      CanUploadDentalPhoto: isSet(object.CanUploadDentalPhoto)
        ? Boolean(object.CanUploadDentalPhoto)
        : false,
      CanUploadDocuments: isSet(object.CanUploadDocuments)
        ? Boolean(object.CanUploadDocuments)
        : false,
      CanViewStudies: isSet(object.CanViewStudies)
        ? Boolean(object.CanViewStudies)
        : false,
      CanViewReports: isSet(object.CanViewReports)
        ? Boolean(object.CanViewReports)
        : false,
    };
  },

  toJSON(message: PatientPermissions): unknown {
    const obj: any = {};
    message.CanView !== undefined && (obj.CanView = message.CanView);
    message.CanViewPersonalData !== undefined &&
      (obj.CanViewPersonalData = message.CanViewPersonalData);
    message.CanEditPersonalData !== undefined &&
      (obj.CanEditPersonalData = message.CanEditPersonalData);
    message.CanEditPatient !== undefined &&
      (obj.CanEditPatient = message.CanEditPatient);
    message.CanViewTeam !== undefined &&
      (obj.CanViewTeam = message.CanViewTeam);
    message.CanEditTeam !== undefined &&
      (obj.CanEditTeam = message.CanEditTeam);
    message.CanViewSharings !== undefined &&
      (obj.CanViewSharings = message.CanViewSharings);
    message.CanShare !== undefined && (obj.CanShare = message.CanShare);
    message.CanCancelOthersSharings !== undefined &&
      (obj.CanCancelOthersSharings = message.CanCancelOthersSharings);
    message.CanViewClinicalCaseDescription !== undefined &&
      (obj.CanViewClinicalCaseDescription =
        message.CanViewClinicalCaseDescription);
    message.CanEditClinicalCaseDescription !== undefined &&
      (obj.CanEditClinicalCaseDescription =
        message.CanEditClinicalCaseDescription);
    message.CanViewTasks !== undefined &&
      (obj.CanViewTasks = message.CanViewTasks);
    message.CanEditTasks !== undefined &&
      (obj.CanEditTasks = message.CanEditTasks);
    message.CanRequestReport !== undefined &&
      (obj.CanRequestReport = message.CanRequestReport);
    message.CanUploadCBCT !== undefined &&
      (obj.CanUploadCBCT = message.CanUploadCBCT);
    message.CanUploadIOXRay !== undefined &&
      (obj.CanUploadIOXRay = message.CanUploadIOXRay);
    message.CanUploadPanoramicXRay !== undefined &&
      (obj.CanUploadPanoramicXRay = message.CanUploadPanoramicXRay);
    message.CanUploadSTL !== undefined &&
      (obj.CanUploadSTL = message.CanUploadSTL);
    message.CanUploadDentalPhoto !== undefined &&
      (obj.CanUploadDentalPhoto = message.CanUploadDentalPhoto);
    message.CanUploadDocuments !== undefined &&
      (obj.CanUploadDocuments = message.CanUploadDocuments);
    message.CanViewStudies !== undefined &&
      (obj.CanViewStudies = message.CanViewStudies);
    message.CanViewReports !== undefined &&
      (obj.CanViewReports = message.CanViewReports);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientPermissions>, I>>(
    object: I,
  ): PatientPermissions {
    const message = createBasePatientPermissions();
    message.CanView = object.CanView ?? false;
    message.CanViewPersonalData = object.CanViewPersonalData ?? false;
    message.CanEditPersonalData = object.CanEditPersonalData ?? false;
    message.CanEditPatient = object.CanEditPatient ?? false;
    message.CanViewTeam = object.CanViewTeam ?? false;
    message.CanEditTeam = object.CanEditTeam ?? false;
    message.CanViewSharings = object.CanViewSharings ?? false;
    message.CanShare = object.CanShare ?? false;
    message.CanCancelOthersSharings = object.CanCancelOthersSharings ?? false;
    message.CanViewClinicalCaseDescription =
      object.CanViewClinicalCaseDescription ?? false;
    message.CanEditClinicalCaseDescription =
      object.CanEditClinicalCaseDescription ?? false;
    message.CanViewTasks = object.CanViewTasks ?? false;
    message.CanEditTasks = object.CanEditTasks ?? false;
    message.CanRequestReport = object.CanRequestReport ?? false;
    message.CanUploadCBCT = object.CanUploadCBCT ?? false;
    message.CanUploadIOXRay = object.CanUploadIOXRay ?? false;
    message.CanUploadPanoramicXRay = object.CanUploadPanoramicXRay ?? false;
    message.CanUploadSTL = object.CanUploadSTL ?? false;
    message.CanUploadDentalPhoto = object.CanUploadDentalPhoto ?? false;
    message.CanUploadDocuments = object.CanUploadDocuments ?? false;
    message.CanViewStudies = object.CanViewStudies ?? false;
    message.CanViewReports = object.CanViewReports ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.marketing';

export interface OrganizationOnboardingQuestionnaireV1 {
  OwnerSpecialization: string;
  CompanyProfile: string;
  CBCTMachineType: string[];
  CBCTImagingSoftware: string[];
  XRayImagingSoftware: string[];
  FocusOfInterest: string[];
}

function createBaseOrganizationOnboardingQuestionnaireV1(): OrganizationOnboardingQuestionnaireV1 {
  return {
    OwnerSpecialization: '',
    CompanyProfile: '',
    CBCTMachineType: [],
    CBCTImagingSoftware: [],
    XRayImagingSoftware: [],
    FocusOfInterest: [],
  };
}

export const OrganizationOnboardingQuestionnaireV1 = {
  encode(
    message: OrganizationOnboardingQuestionnaireV1,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OwnerSpecialization !== '') {
      writer.uint32(10).string(message.OwnerSpecialization);
    }
    if (message.CompanyProfile !== '') {
      writer.uint32(18).string(message.CompanyProfile);
    }
    for (const v of message.CBCTMachineType) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.CBCTImagingSoftware) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.XRayImagingSoftware) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.FocusOfInterest) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationOnboardingQuestionnaireV1 {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationOnboardingQuestionnaireV1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OwnerSpecialization = reader.string();
          break;
        case 2:
          message.CompanyProfile = reader.string();
          break;
        case 3:
          message.CBCTMachineType.push(reader.string());
          break;
        case 4:
          message.CBCTImagingSoftware.push(reader.string());
          break;
        case 5:
          message.XRayImagingSoftware.push(reader.string());
          break;
        case 6:
          message.FocusOfInterest.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationOnboardingQuestionnaireV1 {
    return {
      OwnerSpecialization: isSet(object.OwnerSpecialization)
        ? String(object.OwnerSpecialization)
        : '',
      CompanyProfile: isSet(object.CompanyProfile)
        ? String(object.CompanyProfile)
        : '',
      CBCTMachineType: Array.isArray(object?.CBCTMachineType)
        ? object.CBCTMachineType.map((e: any) => String(e))
        : [],
      CBCTImagingSoftware: Array.isArray(object?.CBCTImagingSoftware)
        ? object.CBCTImagingSoftware.map((e: any) => String(e))
        : [],
      XRayImagingSoftware: Array.isArray(object?.XRayImagingSoftware)
        ? object.XRayImagingSoftware.map((e: any) => String(e))
        : [],
      FocusOfInterest: Array.isArray(object?.FocusOfInterest)
        ? object.FocusOfInterest.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: OrganizationOnboardingQuestionnaireV1): unknown {
    const obj: any = {};
    message.OwnerSpecialization !== undefined &&
      (obj.OwnerSpecialization = message.OwnerSpecialization);
    message.CompanyProfile !== undefined &&
      (obj.CompanyProfile = message.CompanyProfile);
    if (message.CBCTMachineType) {
      obj.CBCTMachineType = message.CBCTMachineType.map((e) => e);
    } else {
      obj.CBCTMachineType = [];
    }
    if (message.CBCTImagingSoftware) {
      obj.CBCTImagingSoftware = message.CBCTImagingSoftware.map((e) => e);
    } else {
      obj.CBCTImagingSoftware = [];
    }
    if (message.XRayImagingSoftware) {
      obj.XRayImagingSoftware = message.XRayImagingSoftware.map((e) => e);
    } else {
      obj.XRayImagingSoftware = [];
    }
    if (message.FocusOfInterest) {
      obj.FocusOfInterest = message.FocusOfInterest.map((e) => e);
    } else {
      obj.FocusOfInterest = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<OrganizationOnboardingQuestionnaireV1>, I>,
  >(object: I): OrganizationOnboardingQuestionnaireV1 {
    const message = createBaseOrganizationOnboardingQuestionnaireV1();
    message.OwnerSpecialization = object.OwnerSpecialization ?? '';
    message.CompanyProfile = object.CompanyProfile ?? '';
    message.CBCTMachineType = object.CBCTMachineType?.map((e) => e) || [];
    message.CBCTImagingSoftware =
      object.CBCTImagingSoftware?.map((e) => e) || [];
    message.XRayImagingSoftware =
      object.XRayImagingSoftware?.map((e) => e) || [];
    message.FocusOfInterest = object.FocusOfInterest?.map((e) => e) || [];
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

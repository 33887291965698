/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { Study } from '../../model/dto_study';
import { Patient } from '../../model/dto_patient';
import { Asset } from '../../model/dto_asset';
import { Empty } from '../../google/protobuf/empty';

export const protobufPackage = 'com.diagnocat.core';

export interface StudyDataStreamReq {
  /** `StudyID`: [required] */
  StudyID: string;
}

export interface StudyDataStreamResp {
  HistoricalStudy: Study | undefined;
  HistoricalPatient: Patient | undefined;
  HistoricalAsset: Asset | undefined;
  /** `EndOfHistoricalAssets` comes right after all historical assets are received */
  EndOfHistoricalAssets: Empty | undefined;
  UpdatedStudy: Study | undefined;
  UpdatedPatient: Patient | undefined;
  UpdatedAsset: Asset | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface DeleteStudyReq {
  /** `StudyID`: [required] */
  StudyID: string;
}

export interface DeleteStudyResp {
  DeletedStudyID: string;
  DeletedReportIDs: string[];
  DeletedToothIDs: string[];
  DeletedConditionIDs: string[];
  DeletedTeethLandmarkIDs: string[];
  DeletedAssetIDs: string[];
  /** display asset may change */
  ChangedPatient: Patient | undefined;
}

function createBaseStudyDataStreamReq(): StudyDataStreamReq {
  return { StudyID: '' };
}

export const StudyDataStreamReq = {
  encode(
    message: StudyDataStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.StudyID !== '') {
      writer.uint32(10).string(message.StudyID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StudyDataStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStudyDataStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.StudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StudyDataStreamReq {
    return {
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : '',
    };
  },

  toJSON(message: StudyDataStreamReq): unknown {
    const obj: any = {};
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StudyDataStreamReq>, I>>(
    object: I,
  ): StudyDataStreamReq {
    const message = createBaseStudyDataStreamReq();
    message.StudyID = object.StudyID ?? '';
    return message;
  },
};

function createBaseStudyDataStreamResp(): StudyDataStreamResp {
  return {
    HistoricalStudy: undefined,
    HistoricalPatient: undefined,
    HistoricalAsset: undefined,
    EndOfHistoricalAssets: undefined,
    UpdatedStudy: undefined,
    UpdatedPatient: undefined,
    UpdatedAsset: undefined,
    Ping: undefined,
  };
}

export const StudyDataStreamResp = {
  encode(
    message: StudyDataStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HistoricalStudy !== undefined) {
      Study.encode(message.HistoricalStudy, writer.uint32(10).fork()).ldelim();
    }
    if (message.HistoricalPatient !== undefined) {
      Patient.encode(
        message.HistoricalPatient,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.HistoricalAsset !== undefined) {
      Asset.encode(message.HistoricalAsset, writer.uint32(26).fork()).ldelim();
    }
    if (message.EndOfHistoricalAssets !== undefined) {
      Empty.encode(
        message.EndOfHistoricalAssets,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.UpdatedStudy !== undefined) {
      Study.encode(message.UpdatedStudy, writer.uint32(90).fork()).ldelim();
    }
    if (message.UpdatedPatient !== undefined) {
      Patient.encode(message.UpdatedPatient, writer.uint32(98).fork()).ldelim();
    }
    if (message.UpdatedAsset !== undefined) {
      Asset.encode(message.UpdatedAsset, writer.uint32(106).fork()).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StudyDataStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStudyDataStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HistoricalStudy = Study.decode(reader, reader.uint32());
          break;
        case 2:
          message.HistoricalPatient = Patient.decode(reader, reader.uint32());
          break;
        case 3:
          message.HistoricalAsset = Asset.decode(reader, reader.uint32());
          break;
        case 4:
          message.EndOfHistoricalAssets = Empty.decode(reader, reader.uint32());
          break;
        case 11:
          message.UpdatedStudy = Study.decode(reader, reader.uint32());
          break;
        case 12:
          message.UpdatedPatient = Patient.decode(reader, reader.uint32());
          break;
        case 13:
          message.UpdatedAsset = Asset.decode(reader, reader.uint32());
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StudyDataStreamResp {
    return {
      HistoricalStudy: isSet(object.HistoricalStudy)
        ? Study.fromJSON(object.HistoricalStudy)
        : undefined,
      HistoricalPatient: isSet(object.HistoricalPatient)
        ? Patient.fromJSON(object.HistoricalPatient)
        : undefined,
      HistoricalAsset: isSet(object.HistoricalAsset)
        ? Asset.fromJSON(object.HistoricalAsset)
        : undefined,
      EndOfHistoricalAssets: isSet(object.EndOfHistoricalAssets)
        ? Empty.fromJSON(object.EndOfHistoricalAssets)
        : undefined,
      UpdatedStudy: isSet(object.UpdatedStudy)
        ? Study.fromJSON(object.UpdatedStudy)
        : undefined,
      UpdatedPatient: isSet(object.UpdatedPatient)
        ? Patient.fromJSON(object.UpdatedPatient)
        : undefined,
      UpdatedAsset: isSet(object.UpdatedAsset)
        ? Asset.fromJSON(object.UpdatedAsset)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: StudyDataStreamResp): unknown {
    const obj: any = {};
    message.HistoricalStudy !== undefined &&
      (obj.HistoricalStudy = message.HistoricalStudy
        ? Study.toJSON(message.HistoricalStudy)
        : undefined);
    message.HistoricalPatient !== undefined &&
      (obj.HistoricalPatient = message.HistoricalPatient
        ? Patient.toJSON(message.HistoricalPatient)
        : undefined);
    message.HistoricalAsset !== undefined &&
      (obj.HistoricalAsset = message.HistoricalAsset
        ? Asset.toJSON(message.HistoricalAsset)
        : undefined);
    message.EndOfHistoricalAssets !== undefined &&
      (obj.EndOfHistoricalAssets = message.EndOfHistoricalAssets
        ? Empty.toJSON(message.EndOfHistoricalAssets)
        : undefined);
    message.UpdatedStudy !== undefined &&
      (obj.UpdatedStudy = message.UpdatedStudy
        ? Study.toJSON(message.UpdatedStudy)
        : undefined);
    message.UpdatedPatient !== undefined &&
      (obj.UpdatedPatient = message.UpdatedPatient
        ? Patient.toJSON(message.UpdatedPatient)
        : undefined);
    message.UpdatedAsset !== undefined &&
      (obj.UpdatedAsset = message.UpdatedAsset
        ? Asset.toJSON(message.UpdatedAsset)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StudyDataStreamResp>, I>>(
    object: I,
  ): StudyDataStreamResp {
    const message = createBaseStudyDataStreamResp();
    message.HistoricalStudy =
      object.HistoricalStudy !== undefined && object.HistoricalStudy !== null
        ? Study.fromPartial(object.HistoricalStudy)
        : undefined;
    message.HistoricalPatient =
      object.HistoricalPatient !== undefined &&
      object.HistoricalPatient !== null
        ? Patient.fromPartial(object.HistoricalPatient)
        : undefined;
    message.HistoricalAsset =
      object.HistoricalAsset !== undefined && object.HistoricalAsset !== null
        ? Asset.fromPartial(object.HistoricalAsset)
        : undefined;
    message.EndOfHistoricalAssets =
      object.EndOfHistoricalAssets !== undefined &&
      object.EndOfHistoricalAssets !== null
        ? Empty.fromPartial(object.EndOfHistoricalAssets)
        : undefined;
    message.UpdatedStudy =
      object.UpdatedStudy !== undefined && object.UpdatedStudy !== null
        ? Study.fromPartial(object.UpdatedStudy)
        : undefined;
    message.UpdatedPatient =
      object.UpdatedPatient !== undefined && object.UpdatedPatient !== null
        ? Patient.fromPartial(object.UpdatedPatient)
        : undefined;
    message.UpdatedAsset =
      object.UpdatedAsset !== undefined && object.UpdatedAsset !== null
        ? Asset.fromPartial(object.UpdatedAsset)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseDeleteStudyReq(): DeleteStudyReq {
  return { StudyID: '' };
}

export const DeleteStudyReq = {
  encode(
    message: DeleteStudyReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.StudyID !== '') {
      writer.uint32(10).string(message.StudyID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteStudyReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteStudyReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.StudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteStudyReq {
    return {
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : '',
    };
  },

  toJSON(message: DeleteStudyReq): unknown {
    const obj: any = {};
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteStudyReq>, I>>(
    object: I,
  ): DeleteStudyReq {
    const message = createBaseDeleteStudyReq();
    message.StudyID = object.StudyID ?? '';
    return message;
  },
};

function createBaseDeleteStudyResp(): DeleteStudyResp {
  return {
    DeletedStudyID: '',
    DeletedReportIDs: [],
    DeletedToothIDs: [],
    DeletedConditionIDs: [],
    DeletedTeethLandmarkIDs: [],
    DeletedAssetIDs: [],
    ChangedPatient: undefined,
  };
}

export const DeleteStudyResp = {
  encode(
    message: DeleteStudyResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DeletedStudyID !== '') {
      writer.uint32(10).string(message.DeletedStudyID);
    }
    for (const v of message.DeletedReportIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.DeletedToothIDs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.DeletedConditionIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.DeletedTeethLandmarkIDs) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.DeletedAssetIDs) {
      writer.uint32(42).string(v!);
    }
    if (message.ChangedPatient !== undefined) {
      Patient.encode(message.ChangedPatient, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteStudyResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteStudyResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DeletedStudyID = reader.string();
          break;
        case 2:
          message.DeletedReportIDs.push(reader.string());
          break;
        case 3:
          message.DeletedToothIDs.push(reader.string());
          break;
        case 4:
          message.DeletedConditionIDs.push(reader.string());
          break;
        case 7:
          message.DeletedTeethLandmarkIDs.push(reader.string());
          break;
        case 5:
          message.DeletedAssetIDs.push(reader.string());
          break;
        case 6:
          message.ChangedPatient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteStudyResp {
    return {
      DeletedStudyID: isSet(object.DeletedStudyID)
        ? String(object.DeletedStudyID)
        : '',
      DeletedReportIDs: Array.isArray(object?.DeletedReportIDs)
        ? object.DeletedReportIDs.map((e: any) => String(e))
        : [],
      DeletedToothIDs: Array.isArray(object?.DeletedToothIDs)
        ? object.DeletedToothIDs.map((e: any) => String(e))
        : [],
      DeletedConditionIDs: Array.isArray(object?.DeletedConditionIDs)
        ? object.DeletedConditionIDs.map((e: any) => String(e))
        : [],
      DeletedTeethLandmarkIDs: Array.isArray(object?.DeletedTeethLandmarkIDs)
        ? object.DeletedTeethLandmarkIDs.map((e: any) => String(e))
        : [],
      DeletedAssetIDs: Array.isArray(object?.DeletedAssetIDs)
        ? object.DeletedAssetIDs.map((e: any) => String(e))
        : [],
      ChangedPatient: isSet(object.ChangedPatient)
        ? Patient.fromJSON(object.ChangedPatient)
        : undefined,
    };
  },

  toJSON(message: DeleteStudyResp): unknown {
    const obj: any = {};
    message.DeletedStudyID !== undefined &&
      (obj.DeletedStudyID = message.DeletedStudyID);
    if (message.DeletedReportIDs) {
      obj.DeletedReportIDs = message.DeletedReportIDs.map((e) => e);
    } else {
      obj.DeletedReportIDs = [];
    }
    if (message.DeletedToothIDs) {
      obj.DeletedToothIDs = message.DeletedToothIDs.map((e) => e);
    } else {
      obj.DeletedToothIDs = [];
    }
    if (message.DeletedConditionIDs) {
      obj.DeletedConditionIDs = message.DeletedConditionIDs.map((e) => e);
    } else {
      obj.DeletedConditionIDs = [];
    }
    if (message.DeletedTeethLandmarkIDs) {
      obj.DeletedTeethLandmarkIDs = message.DeletedTeethLandmarkIDs.map(
        (e) => e,
      );
    } else {
      obj.DeletedTeethLandmarkIDs = [];
    }
    if (message.DeletedAssetIDs) {
      obj.DeletedAssetIDs = message.DeletedAssetIDs.map((e) => e);
    } else {
      obj.DeletedAssetIDs = [];
    }
    message.ChangedPatient !== undefined &&
      (obj.ChangedPatient = message.ChangedPatient
        ? Patient.toJSON(message.ChangedPatient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteStudyResp>, I>>(
    object: I,
  ): DeleteStudyResp {
    const message = createBaseDeleteStudyResp();
    message.DeletedStudyID = object.DeletedStudyID ?? '';
    message.DeletedReportIDs = object.DeletedReportIDs?.map((e) => e) || [];
    message.DeletedToothIDs = object.DeletedToothIDs?.map((e) => e) || [];
    message.DeletedConditionIDs =
      object.DeletedConditionIDs?.map((e) => e) || [];
    message.DeletedTeethLandmarkIDs =
      object.DeletedTeethLandmarkIDs?.map((e) => e) || [];
    message.DeletedAssetIDs = object.DeletedAssetIDs?.map((e) => e) || [];
    message.ChangedPatient =
      object.ChangedPatient !== undefined && object.ChangedPatient !== null
        ? Patient.fromPartial(object.ChangedPatient)
        : undefined;
    return message;
  },
};

export interface StudyService {
  StudyDataStream(
    request: DeepPartial<StudyDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<StudyDataStreamResp>;
  DeleteStudy(
    request: DeepPartial<DeleteStudyReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteStudyResp>;
}

export class StudyServiceClientImpl implements StudyService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.StudyDataStream = this.StudyDataStream.bind(this);
    this.DeleteStudy = this.DeleteStudy.bind(this);
  }

  StudyDataStream(
    request: DeepPartial<StudyDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<StudyDataStreamResp> {
    return this.rpc.invoke(
      StudyServiceStudyDataStreamDesc,
      StudyDataStreamReq.fromPartial(request),
      metadata,
    );
  }

  DeleteStudy(
    request: DeepPartial<DeleteStudyReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteStudyResp> {
    return this.rpc.unary(
      StudyServiceDeleteStudyDesc,
      DeleteStudyReq.fromPartial(request),
      metadata,
    );
  }
}

export const StudyServiceDesc = {
  serviceName: 'com.diagnocat.core.StudyService',
};

export const StudyServiceStudyDataStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'StudyDataStream',
  service: StudyServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return StudyDataStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...StudyDataStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const StudyServiceDeleteStudyDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteStudy',
  service: StudyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteStudyReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteStudyResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

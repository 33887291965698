import { useEffect, useState } from 'react';

export const useMouseCoords = (): { x: number; y: number } => {
  const [{ x, y }, setCoordinates] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const changeMouseCoordsHandle = (e: MouseEvent) => {
      setCoordinates({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', changeMouseCoordsHandle);

    return () => {
      document.removeEventListener('mousemove', changeMouseCoordsHandle);
    };
  }, []);

  return { x, y };
};

import { StylesConfig } from 'react-select';

export const doctorsSelectStyles: StylesConfig = {
  input: (base) => ({
    ...base,
    padding: 12,
  }),
  placeholder: (base) => ({
    ...base,
    marginLeft: 14,
    marginRight: 14,
  }),
  option: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: 0,
    margin: '2px 12px',
    width: 'calc(100% - 24px)', // margin-left + margin-right
    borderRadius: 24,
    cursor: 'pointer',
    transitionProperty: 'box-shadow',
    transitionDuration: 'var(--transition-time) ease-in-out',
    backgroundColor: 'transparent',
    color: 'var(--grey9)',
    ':active': {
      backgroundColor: 'transparent',
    },
    ':hover': {
      boxShadow: '0 0 0 2px var(--purpleflat)',
    },
    ':not(:last-child)': {
      marginBottom: '4px',
    },
  }),
  menuList: (base) => ({
    ...base,
    padding: '12px 0px',
  }),
  valueContainer: (base) => ({
    ...base,
    gap: '8px 16px',
    margin: 0,
    padding: 0,
  }),
  multiValue: (base) => ({
    ...base,
    gap: 8,
    borderRadius: 24,
    backgroundColor: 'rgba(var(--grey10-rgb), 0.4)',
    border: 'none',
  }),
  multiValueLabel: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: 0,
    paddingLeft: 0,
    color: 'var(--grey9)',
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    color: 'var(--grey9)',

    ':hover': {
      color: 'var(--redrose)',
    },
  }),
};

import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Description.module.scss';

type DescriptionProps = {
  className?: string;
  fontType?: string;
  children?: ReactNode;
  isVertical?: boolean;
  label: JSX.Element | string;
  contentClassName?: string;
};

export const Description: FC<DescriptionProps> = (props) => {
  const {
    className,
    children,
    label,
    fontType = 'p3',
    isVertical,
    contentClassName,
  } = props;

  return (
    <div
      className={cn(
        styles.container,
        className,
        fontType,
        isVertical && styles.vertical,
      )}
    >
      <span className={styles.label}>{label}: </span>
      <span className={cn(styles.content, contentClassName)}>{children}</span>
    </div>
  );
};

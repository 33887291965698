import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';

import { toothLandmarksAdapter } from './toothLandmarksSlice';

const toothLandmarksSelectors = toothLandmarksAdapter.getSelectors(
  (state: RootState) => state.toothLandmarks,
);

export const { selectById } = toothLandmarksSelectors;

export const selectLandmarkByID = (landmarkID: string) => (state: RootState) =>
  selectById(state, landmarkID);

export const selectAll = (state: RootState) =>
  toothLandmarksSelectors.selectAll(state);

export const selectByToothID = (toothID: string) =>
  createSelector(selectAll, (landmarks) =>
    landmarks.filter((landmark) => landmark.ToothID === toothID),
  );

export const selectByTeethIDs = (teethIDs: string[]) =>
  createSelector(selectAll, (landmarks) =>
    landmarks.filter((landmark) => teethIDs.includes(landmark.ToothID)),
  );

import { FC, memo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Checkbox, Icon, Radio } from '@/shared/ui';

import {
  ConditionDecisionChange,
  ConditionColor,
  ConditionInterface,
  ChildConditionInterface,
} from '../../config/types';

import styles from './ConditionToggle.module.scss';

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

type ConditionToggleProps = {
  className?: string;
  data: ConditionInterface | ChildConditionInterface;
  disabled?: boolean;
  onChange: ConditionDecisionChange;
};

type ConditionToggleProbabilityProps = {
  probability: ConditionInterface['probability'];
  probabilityText: ConditionInterface['probabilityText'];
  modelPositive: ConditionInterface['modelPositive'];
};

const ConditionToggleProbability = (props: ConditionToggleProbabilityProps) => {
  const { probability, probabilityText, modelPositive } = props;

  if (modelPositive) {
    return (
      <div className={styles.probabilityCat}>
        <Icon name="cat" size={16} />
        <span className={styles.probabilitCatText}>{probabilityText}</span>
      </div>
    );
  }

  if (typeof probability === 'number') {
    return <span className={styles.probability}>{probabilityText}</span>;
  }

  return null;
};

const InnerConditionToggle: FC<ConditionToggleProps> = (props) => {
  const { className, data, disabled, onChange } = props;

  if (isEmpty(data)) {
    return null;
  }

  const name = data.isOneOf ? data.group : data.code.toString();
  const Component = data.isOneOf ? Radio : Checkbox;

  const handleChange = () => {
    onChange({
      conditionIDs: data.ids,
      parentConditionId: (data as ChildConditionInterface)?.parentConditionId,
      conditionCode: data.code,
      userDecision: data.isChecked
        ? Decision.NegativeDecision
        : Decision.PositiveDecision,
    });
  };

  return (
    <Component
      id={data?.id}
      className={cn(
        styles.container,
        { [styles.disabled]: disabled },
        className,
      )}
      checked={data.isChecked ?? false}
      disabled={disabled}
      onChange={handleChange}
      name={name}
    >
      <div className={styles.label}>
        <span className={cn(styles.text, colorStyle[data.color])}>
          {data.text}
        </span>
        <ConditionToggleProbability
          probability={data.probability}
          probabilityText={data.probabilityText}
          modelPositive={data.modelPositive}
        />
      </div>
    </Component>
  );
};

export const ConditionToggle = memo(InnerConditionToggle);

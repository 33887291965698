/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../google/protobuf/timestamp';
import { Service } from '../../api/billing_new/dto_services_new';
import { Created, RevisionNew, DeletedNew } from '../../model/dto_base';

export const protobufPackage = 'com.diagnocat.billing_new';

export enum ProductStateStatus {
  ProductStateStatusInvalidValue = 0,
  ProductStateStatusActive = 1,
  ProductStateStatusAwaitingActivation = 2,
  ProductStateStatusDeleted = 3,
  ProductStateStatusLifeTimeIsOver = 4,
  UNRECOGNIZED = -1,
}

export function productStateStatusFromJSON(object: any): ProductStateStatus {
  switch (object) {
    case 0:
    case 'ProductStateStatusInvalidValue':
      return ProductStateStatus.ProductStateStatusInvalidValue;
    case 1:
    case 'ProductStateStatusActive':
      return ProductStateStatus.ProductStateStatusActive;
    case 2:
    case 'ProductStateStatusAwaitingActivation':
      return ProductStateStatus.ProductStateStatusAwaitingActivation;
    case 3:
    case 'ProductStateStatusDeleted':
      return ProductStateStatus.ProductStateStatusDeleted;
    case 4:
    case 'ProductStateStatusLifeTimeIsOver':
      return ProductStateStatus.ProductStateStatusLifeTimeIsOver;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ProductStateStatus.UNRECOGNIZED;
  }
}

export function productStateStatusToJSON(object: ProductStateStatus): string {
  switch (object) {
    case ProductStateStatus.ProductStateStatusInvalidValue:
      return 'ProductStateStatusInvalidValue';
    case ProductStateStatus.ProductStateStatusActive:
      return 'ProductStateStatusActive';
    case ProductStateStatus.ProductStateStatusAwaitingActivation:
      return 'ProductStateStatusAwaitingActivation';
    case ProductStateStatus.ProductStateStatusDeleted:
      return 'ProductStateStatusDeleted';
    case ProductStateStatus.ProductStateStatusLifeTimeIsOver:
      return 'ProductStateStatusLifeTimeIsOver';
    case ProductStateStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ProductModelGroupName {
  ProductModelGroupNameInvalidValue = 0,
  ProductModelGroupNameEmpty = 1,
  ProductModelGroupNameDentalPractice = 2,
  ProductModelGroupNameDigitalSegmentation = 3,
  ProductModelGroupNameMaintenancePlans = 4,
  ProductModelGroupNameDentalClinic = 5,
  ProductModelGroupNameImagingCenter = 6,
  ProductModelGroupNameDigitalLab = 7,
  ProductModelGroupNameClinicalTestingPlans = 8,
  UNRECOGNIZED = -1,
}

export function productModelGroupNameFromJSON(
  object: any,
): ProductModelGroupName {
  switch (object) {
    case 0:
    case 'ProductModelGroupNameInvalidValue':
      return ProductModelGroupName.ProductModelGroupNameInvalidValue;
    case 1:
    case 'ProductModelGroupNameEmpty':
      return ProductModelGroupName.ProductModelGroupNameEmpty;
    case 2:
    case 'ProductModelGroupNameDentalPractice':
      return ProductModelGroupName.ProductModelGroupNameDentalPractice;
    case 3:
    case 'ProductModelGroupNameDigitalSegmentation':
      return ProductModelGroupName.ProductModelGroupNameDigitalSegmentation;
    case 4:
    case 'ProductModelGroupNameMaintenancePlans':
      return ProductModelGroupName.ProductModelGroupNameMaintenancePlans;
    case 5:
    case 'ProductModelGroupNameDentalClinic':
      return ProductModelGroupName.ProductModelGroupNameDentalClinic;
    case 6:
    case 'ProductModelGroupNameImagingCenter':
      return ProductModelGroupName.ProductModelGroupNameImagingCenter;
    case 7:
    case 'ProductModelGroupNameDigitalLab':
      return ProductModelGroupName.ProductModelGroupNameDigitalLab;
    case 8:
    case 'ProductModelGroupNameClinicalTestingPlans':
      return ProductModelGroupName.ProductModelGroupNameClinicalTestingPlans;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ProductModelGroupName.UNRECOGNIZED;
  }
}

export function productModelGroupNameToJSON(
  object: ProductModelGroupName,
): string {
  switch (object) {
    case ProductModelGroupName.ProductModelGroupNameInvalidValue:
      return 'ProductModelGroupNameInvalidValue';
    case ProductModelGroupName.ProductModelGroupNameEmpty:
      return 'ProductModelGroupNameEmpty';
    case ProductModelGroupName.ProductModelGroupNameDentalPractice:
      return 'ProductModelGroupNameDentalPractice';
    case ProductModelGroupName.ProductModelGroupNameDigitalSegmentation:
      return 'ProductModelGroupNameDigitalSegmentation';
    case ProductModelGroupName.ProductModelGroupNameMaintenancePlans:
      return 'ProductModelGroupNameMaintenancePlans';
    case ProductModelGroupName.ProductModelGroupNameDentalClinic:
      return 'ProductModelGroupNameDentalClinic';
    case ProductModelGroupName.ProductModelGroupNameImagingCenter:
      return 'ProductModelGroupNameImagingCenter';
    case ProductModelGroupName.ProductModelGroupNameDigitalLab:
      return 'ProductModelGroupNameDigitalLab';
    case ProductModelGroupName.ProductModelGroupNameClinicalTestingPlans:
      return 'ProductModelGroupNameClinicalTestingPlans';
    case ProductModelGroupName.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ProductState {
  ID: string;
  AccountID: string;
  Model: ProductModel | undefined;
  Start: Date | undefined;
  End: Date | undefined;
  AutoRenewal: boolean;
  AutoCharge: boolean;
  Paid: boolean;
  Status: ProductStateStatus;
  Services: Service[];
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: RevisionNew | undefined;
  /** deletion information */
  Deleted: DeletedNew | undefined;
}

export interface ProductModel {
  SKU: string;
  Name: string;
  Description: string;
  StripeProductID: string;
  Services: Service[];
  BillingZone: string;
  Currency: string;
  Price: string;
  CrossedOutPrice: string;
  AvailableForAdminsOnly: boolean;
  GroupName: ProductModelGroupName;
  IsCustom: boolean;
  Subscription: ProductModel_SubscriptionKind | undefined;
  Addon: ProductModel_AddonKind | undefined;
  Package: ProductModel_PackageKind | undefined;
}

export interface ProductModel_SubscriptionKind {
  /**
   * Total lifetime is sum of LifeTimeMonths + LifeTimeDays
   *
   * Usually only months are used.
   *
   * Days can be used for a trial subscription, where the length of the
   * period is lower than a month.
   * But it is possible to use both.
   *
   * End date will be calculated as sum of current time and LifeTimeMonths and LifeTimeDays.
   */
  LifeTimeMonths: number;
  LifeTimeDays: number;
  CanUserAddAddons: boolean;
}

export interface ProductModel_AddonKind {}

export interface ProductModel_PackageKind {
  /**
   * Total lifetime is sum of LifeTimeMonths + LifeTimeDays
   *
   * 0  in each means unlimited
   *
   * Usually only months are used.
   *
   * Days can be used for a package, where the length of the
   * period is lower than a month.
   * But it is possible to use both.
   *
   * End date will be calculated as sum of current time and LifeTimeMonths and LifeTimeDays.
   */
  LifeTimeMonths: number;
  LifeTimeDays: number;
}

function createBaseProductState(): ProductState {
  return {
    ID: '',
    AccountID: '',
    Model: undefined,
    Start: undefined,
    End: undefined,
    AutoRenewal: false,
    AutoCharge: false,
    Paid: false,
    Status: 0,
    Services: [],
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const ProductState = {
  encode(
    message: ProductState,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.AccountID !== '') {
      writer.uint32(18).string(message.AccountID);
    }
    if (message.Model !== undefined) {
      ProductModel.encode(message.Model, writer.uint32(26).fork()).ldelim();
    }
    if (message.Start !== undefined) {
      Timestamp.encode(
        toTimestamp(message.Start),
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.End !== undefined) {
      Timestamp.encode(
        toTimestamp(message.End),
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.AutoRenewal === true) {
      writer.uint32(48).bool(message.AutoRenewal);
    }
    if (message.AutoCharge === true) {
      writer.uint32(56).bool(message.AutoCharge);
    }
    if (message.Paid === true) {
      writer.uint32(64).bool(message.Paid);
    }
    if (message.Status !== 0) {
      writer.uint32(72).int32(message.Status);
    }
    for (const v of message.Services) {
      Service.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      RevisionNew.encode(
        message.Revision,
        writer.uint32(80322).fork(),
      ).ldelim();
    }
    if (message.Deleted !== undefined) {
      DeletedNew.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.AccountID = reader.string();
          break;
        case 3:
          message.Model = ProductModel.decode(reader, reader.uint32());
          break;
        case 4:
          message.Start = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 5:
          message.End = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 6:
          message.AutoRenewal = reader.bool();
          break;
        case 7:
          message.AutoCharge = reader.bool();
          break;
        case 8:
          message.Paid = reader.bool();
          break;
        case 9:
          message.Status = reader.int32() as any;
          break;
        case 10:
          message.Services.push(Service.decode(reader, reader.uint32()));
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = RevisionNew.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = DeletedNew.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProductState {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      Model: isSet(object.Model)
        ? ProductModel.fromJSON(object.Model)
        : undefined,
      Start: isSet(object.Start) ? fromJsonTimestamp(object.Start) : undefined,
      End: isSet(object.End) ? fromJsonTimestamp(object.End) : undefined,
      AutoRenewal: isSet(object.AutoRenewal)
        ? Boolean(object.AutoRenewal)
        : false,
      AutoCharge: isSet(object.AutoCharge) ? Boolean(object.AutoCharge) : false,
      Paid: isSet(object.Paid) ? Boolean(object.Paid) : false,
      Status: isSet(object.Status)
        ? productStateStatusFromJSON(object.Status)
        : 0,
      Services: Array.isArray(object?.Services)
        ? object.Services.map((e: any) => Service.fromJSON(e))
        : [],
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? RevisionNew.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? DeletedNew.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: ProductState): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.Model !== undefined &&
      (obj.Model = message.Model
        ? ProductModel.toJSON(message.Model)
        : undefined);
    message.Start !== undefined && (obj.Start = message.Start.toISOString());
    message.End !== undefined && (obj.End = message.End.toISOString());
    message.AutoRenewal !== undefined &&
      (obj.AutoRenewal = message.AutoRenewal);
    message.AutoCharge !== undefined && (obj.AutoCharge = message.AutoCharge);
    message.Paid !== undefined && (obj.Paid = message.Paid);
    message.Status !== undefined &&
      (obj.Status = productStateStatusToJSON(message.Status));
    if (message.Services) {
      obj.Services = message.Services.map((e) =>
        e ? Service.toJSON(e) : undefined,
      );
    } else {
      obj.Services = [];
    }
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? RevisionNew.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? DeletedNew.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProductState>, I>>(
    object: I,
  ): ProductState {
    const message = createBaseProductState();
    message.ID = object.ID ?? '';
    message.AccountID = object.AccountID ?? '';
    message.Model =
      object.Model !== undefined && object.Model !== null
        ? ProductModel.fromPartial(object.Model)
        : undefined;
    message.Start = object.Start ?? undefined;
    message.End = object.End ?? undefined;
    message.AutoRenewal = object.AutoRenewal ?? false;
    message.AutoCharge = object.AutoCharge ?? false;
    message.Paid = object.Paid ?? false;
    message.Status = object.Status ?? 0;
    message.Services =
      object.Services?.map((e) => Service.fromPartial(e)) || [];
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? RevisionNew.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? DeletedNew.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseProductModel(): ProductModel {
  return {
    SKU: '',
    Name: '',
    Description: '',
    StripeProductID: '',
    Services: [],
    BillingZone: '',
    Currency: '',
    Price: '',
    CrossedOutPrice: '',
    AvailableForAdminsOnly: false,
    GroupName: 0,
    IsCustom: false,
    Subscription: undefined,
    Addon: undefined,
    Package: undefined,
  };
}

export const ProductModel = {
  encode(
    message: ProductModel,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.SKU !== '') {
      writer.uint32(10).string(message.SKU);
    }
    if (message.Name !== '') {
      writer.uint32(18).string(message.Name);
    }
    if (message.Description !== '') {
      writer.uint32(26).string(message.Description);
    }
    if (message.StripeProductID !== '') {
      writer.uint32(34).string(message.StripeProductID);
    }
    for (const v of message.Services) {
      Service.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.BillingZone !== '') {
      writer.uint32(50).string(message.BillingZone);
    }
    if (message.Currency !== '') {
      writer.uint32(58).string(message.Currency);
    }
    if (message.Price !== '') {
      writer.uint32(66).string(message.Price);
    }
    if (message.CrossedOutPrice !== '') {
      writer.uint32(74).string(message.CrossedOutPrice);
    }
    if (message.AvailableForAdminsOnly === true) {
      writer.uint32(80).bool(message.AvailableForAdminsOnly);
    }
    if (message.GroupName !== 0) {
      writer.uint32(88).int32(message.GroupName);
    }
    if (message.IsCustom === true) {
      writer.uint32(96).bool(message.IsCustom);
    }
    if (message.Subscription !== undefined) {
      ProductModel_SubscriptionKind.encode(
        message.Subscription,
        writer.uint32(162).fork(),
      ).ldelim();
    }
    if (message.Addon !== undefined) {
      ProductModel_AddonKind.encode(
        message.Addon,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    if (message.Package !== undefined) {
      ProductModel_PackageKind.encode(
        message.Package,
        writer.uint32(178).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductModel {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SKU = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.StripeProductID = reader.string();
          break;
        case 5:
          message.Services.push(Service.decode(reader, reader.uint32()));
          break;
        case 6:
          message.BillingZone = reader.string();
          break;
        case 7:
          message.Currency = reader.string();
          break;
        case 8:
          message.Price = reader.string();
          break;
        case 9:
          message.CrossedOutPrice = reader.string();
          break;
        case 10:
          message.AvailableForAdminsOnly = reader.bool();
          break;
        case 11:
          message.GroupName = reader.int32() as any;
          break;
        case 12:
          message.IsCustom = reader.bool();
          break;
        case 20:
          message.Subscription = ProductModel_SubscriptionKind.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 21:
          message.Addon = ProductModel_AddonKind.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 22:
          message.Package = ProductModel_PackageKind.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProductModel {
    return {
      SKU: isSet(object.SKU) ? String(object.SKU) : '',
      Name: isSet(object.Name) ? String(object.Name) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      StripeProductID: isSet(object.StripeProductID)
        ? String(object.StripeProductID)
        : '',
      Services: Array.isArray(object?.Services)
        ? object.Services.map((e: any) => Service.fromJSON(e))
        : [],
      BillingZone: isSet(object.BillingZone) ? String(object.BillingZone) : '',
      Currency: isSet(object.Currency) ? String(object.Currency) : '',
      Price: isSet(object.Price) ? String(object.Price) : '',
      CrossedOutPrice: isSet(object.CrossedOutPrice)
        ? String(object.CrossedOutPrice)
        : '',
      AvailableForAdminsOnly: isSet(object.AvailableForAdminsOnly)
        ? Boolean(object.AvailableForAdminsOnly)
        : false,
      GroupName: isSet(object.GroupName)
        ? productModelGroupNameFromJSON(object.GroupName)
        : 0,
      IsCustom: isSet(object.IsCustom) ? Boolean(object.IsCustom) : false,
      Subscription: isSet(object.Subscription)
        ? ProductModel_SubscriptionKind.fromJSON(object.Subscription)
        : undefined,
      Addon: isSet(object.Addon)
        ? ProductModel_AddonKind.fromJSON(object.Addon)
        : undefined,
      Package: isSet(object.Package)
        ? ProductModel_PackageKind.fromJSON(object.Package)
        : undefined,
    };
  },

  toJSON(message: ProductModel): unknown {
    const obj: any = {};
    message.SKU !== undefined && (obj.SKU = message.SKU);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.StripeProductID !== undefined &&
      (obj.StripeProductID = message.StripeProductID);
    if (message.Services) {
      obj.Services = message.Services.map((e) =>
        e ? Service.toJSON(e) : undefined,
      );
    } else {
      obj.Services = [];
    }
    message.BillingZone !== undefined &&
      (obj.BillingZone = message.BillingZone);
    message.Currency !== undefined && (obj.Currency = message.Currency);
    message.Price !== undefined && (obj.Price = message.Price);
    message.CrossedOutPrice !== undefined &&
      (obj.CrossedOutPrice = message.CrossedOutPrice);
    message.AvailableForAdminsOnly !== undefined &&
      (obj.AvailableForAdminsOnly = message.AvailableForAdminsOnly);
    message.GroupName !== undefined &&
      (obj.GroupName = productModelGroupNameToJSON(message.GroupName));
    message.IsCustom !== undefined && (obj.IsCustom = message.IsCustom);
    message.Subscription !== undefined &&
      (obj.Subscription = message.Subscription
        ? ProductModel_SubscriptionKind.toJSON(message.Subscription)
        : undefined);
    message.Addon !== undefined &&
      (obj.Addon = message.Addon
        ? ProductModel_AddonKind.toJSON(message.Addon)
        : undefined);
    message.Package !== undefined &&
      (obj.Package = message.Package
        ? ProductModel_PackageKind.toJSON(message.Package)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProductModel>, I>>(
    object: I,
  ): ProductModel {
    const message = createBaseProductModel();
    message.SKU = object.SKU ?? '';
    message.Name = object.Name ?? '';
    message.Description = object.Description ?? '';
    message.StripeProductID = object.StripeProductID ?? '';
    message.Services =
      object.Services?.map((e) => Service.fromPartial(e)) || [];
    message.BillingZone = object.BillingZone ?? '';
    message.Currency = object.Currency ?? '';
    message.Price = object.Price ?? '';
    message.CrossedOutPrice = object.CrossedOutPrice ?? '';
    message.AvailableForAdminsOnly = object.AvailableForAdminsOnly ?? false;
    message.GroupName = object.GroupName ?? 0;
    message.IsCustom = object.IsCustom ?? false;
    message.Subscription =
      object.Subscription !== undefined && object.Subscription !== null
        ? ProductModel_SubscriptionKind.fromPartial(object.Subscription)
        : undefined;
    message.Addon =
      object.Addon !== undefined && object.Addon !== null
        ? ProductModel_AddonKind.fromPartial(object.Addon)
        : undefined;
    message.Package =
      object.Package !== undefined && object.Package !== null
        ? ProductModel_PackageKind.fromPartial(object.Package)
        : undefined;
    return message;
  },
};

function createBaseProductModel_SubscriptionKind(): ProductModel_SubscriptionKind {
  return { LifeTimeMonths: 0, LifeTimeDays: 0, CanUserAddAddons: false };
}

export const ProductModel_SubscriptionKind = {
  encode(
    message: ProductModel_SubscriptionKind,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.LifeTimeMonths !== 0) {
      writer.uint32(8).int32(message.LifeTimeMonths);
    }
    if (message.LifeTimeDays !== 0) {
      writer.uint32(24).int32(message.LifeTimeDays);
    }
    if (message.CanUserAddAddons === true) {
      writer.uint32(16).bool(message.CanUserAddAddons);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ProductModel_SubscriptionKind {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductModel_SubscriptionKind();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.LifeTimeMonths = reader.int32();
          break;
        case 3:
          message.LifeTimeDays = reader.int32();
          break;
        case 2:
          message.CanUserAddAddons = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProductModel_SubscriptionKind {
    return {
      LifeTimeMonths: isSet(object.LifeTimeMonths)
        ? Number(object.LifeTimeMonths)
        : 0,
      LifeTimeDays: isSet(object.LifeTimeDays)
        ? Number(object.LifeTimeDays)
        : 0,
      CanUserAddAddons: isSet(object.CanUserAddAddons)
        ? Boolean(object.CanUserAddAddons)
        : false,
    };
  },

  toJSON(message: ProductModel_SubscriptionKind): unknown {
    const obj: any = {};
    message.LifeTimeMonths !== undefined &&
      (obj.LifeTimeMonths = Math.round(message.LifeTimeMonths));
    message.LifeTimeDays !== undefined &&
      (obj.LifeTimeDays = Math.round(message.LifeTimeDays));
    message.CanUserAddAddons !== undefined &&
      (obj.CanUserAddAddons = message.CanUserAddAddons);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProductModel_SubscriptionKind>, I>>(
    object: I,
  ): ProductModel_SubscriptionKind {
    const message = createBaseProductModel_SubscriptionKind();
    message.LifeTimeMonths = object.LifeTimeMonths ?? 0;
    message.LifeTimeDays = object.LifeTimeDays ?? 0;
    message.CanUserAddAddons = object.CanUserAddAddons ?? false;
    return message;
  },
};

function createBaseProductModel_AddonKind(): ProductModel_AddonKind {
  return {};
}

export const ProductModel_AddonKind = {
  encode(
    _: ProductModel_AddonKind,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ProductModel_AddonKind {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductModel_AddonKind();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ProductModel_AddonKind {
    return {};
  },

  toJSON(_: ProductModel_AddonKind): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProductModel_AddonKind>, I>>(
    _: I,
  ): ProductModel_AddonKind {
    const message = createBaseProductModel_AddonKind();
    return message;
  },
};

function createBaseProductModel_PackageKind(): ProductModel_PackageKind {
  return { LifeTimeMonths: 0, LifeTimeDays: 0 };
}

export const ProductModel_PackageKind = {
  encode(
    message: ProductModel_PackageKind,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.LifeTimeMonths !== 0) {
      writer.uint32(8).int32(message.LifeTimeMonths);
    }
    if (message.LifeTimeDays !== 0) {
      writer.uint32(16).int32(message.LifeTimeDays);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ProductModel_PackageKind {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductModel_PackageKind();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.LifeTimeMonths = reader.int32();
          break;
        case 2:
          message.LifeTimeDays = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProductModel_PackageKind {
    return {
      LifeTimeMonths: isSet(object.LifeTimeMonths)
        ? Number(object.LifeTimeMonths)
        : 0,
      LifeTimeDays: isSet(object.LifeTimeDays)
        ? Number(object.LifeTimeDays)
        : 0,
    };
  },

  toJSON(message: ProductModel_PackageKind): unknown {
    const obj: any = {};
    message.LifeTimeMonths !== undefined &&
      (obj.LifeTimeMonths = Math.round(message.LifeTimeMonths));
    message.LifeTimeDays !== undefined &&
      (obj.LifeTimeDays = Math.round(message.LifeTimeDays));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProductModel_PackageKind>, I>>(
    object: I,
  ): ProductModel_PackageKind {
    const message = createBaseProductModel_PackageKind();
    message.LifeTimeMonths = object.LifeTimeMonths ?? 0;
    message.LifeTimeDays = object.LifeTimeDays ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

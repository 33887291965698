import { createAsyncThunk } from '@reduxjs/toolkit';
// import { DeepPartial } from 'react-hook-form';
import { BrowserHeaders } from 'browser-headers';

import { ApiError, ApiServices } from '@/shared/api/api';

import { SliceName } from '../config';

export type ThunkGeneratorRejectedValuePayload = Pick<
  ApiError,
  'message' | 'type'
> & {
  apiService: ApiServices;
};

type ThunkRequestFunction<Request, Response> = (
  request: Request,
  metadata?: BrowserHeaders,
) => Promise<Response>;

export const createThunkGenerator =
  <RequestsNames>(sliceName: SliceName, apiService: ApiServices) =>
  <Request, Response>(
    requestName: RequestsNames,
    requestFunction: ThunkRequestFunction<Request, Response>,
  ) =>
    createAsyncThunk(
      `${sliceName}/${requestName}`,
      async (request: Request, { rejectWithValue }) => {
        try {
          const response = (await requestFunction(
            request,
          )) as unknown as Response;

          return response;
        } catch (error: unknown) {
          const { message, type } = error as ApiError;

          return rejectWithValue({
            message,
            type,
            apiService,
          } as ThunkGeneratorRejectedValuePayload);
        }
      },
    );

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { BASE_URI } from '@/shared/config';

import { getModelTypeForUrl } from './getModelTypeForUrl';

export const getSegmentronReportLink = (report: Report) => {
  const orderType = getModelTypeForUrl(
    report?.Type ?? ReportType.ReportType_CBCT_Segmentation,
  );

  return `${BASE_URI}/studio/${orderType}/?analysis_uid=${report?.ID}`;
};

import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './ConditionToggleList.module.scss';

type ConditionToggleListProps = {
  children: ReactNode;
  className?: string;
};

export const ConditionToggleList: FC<ConditionToggleListProps> = (props) => {
  const { children, className } = props;

  return <div className={cn(styles.container, className)}>{children}</div>;
};

import React, { FC, ReactNode } from 'react';
import { toast, TypeOptions, ToastPosition } from 'react-toastify';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';
import { IconNames } from '../Icon/Icon.types';

import styles from './Toast.module.scss';
import './Toast.scss';
import 'react-toastify/dist/ReactToastify.css';

const toastIcons: Record<string, { icon: IconNames; className: string }> = {
  success: { icon: 'check', className: 'success' },
  error: { icon: 'info', className: 'error' },
  info: { icon: 'info', className: 'info' },
  warning: { icon: 'alert', className: 'warning' },
};

export type Props = {
  heading?: React.ReactNode;
  message: React.ReactNode;
  type: TypeOptions;
  className?: string;
  children?: ReactNode;
};

type ToastCallerTypes = Props & {
  autoClose?: number | false;
  position?: ToastPosition;
};

const ToastComponent: FC<Props> = (props) => {
  const { message, type, heading, children, className } = props;

  return (
    <div className={cn(styles.container, className)}>
      {type !== 'default' ? (
        <div className={styles.icon}>
          <Icon
            name={toastIcons[type].icon}
            className={toastIcons[type].className}
          />
        </div>
      ) : null}
      <div className={styles.body}>
        <p className="h5">{heading}</p>
        <p className="p2">{message}</p>
        {children}
      </div>
    </div>
  );
};

export const toastCaller = (props: ToastCallerTypes) => {
  const {
    message,
    type,
    heading,
    position = 'top-right',
    children,
    autoClose = 3000,
    className,
  } = props;

  return toast(
    <ToastComponent
      message={message}
      heading={heading}
      type={type}
      className={className}
    >
      {children}
    </ToastComponent>,
    {
      type,
      position,
      autoClose,
      pauseOnHover: true,
      draggable: true,
      className: styles.toast,
    },
  );
};

import { AngleHandle } from './AngleHandle';
import { Annotation, AnnotationEditContext } from './Annotation';
import { ArrowHandle } from './ArrowHandle';
import { LineHandle } from './LineHandle';
import { RulerHandle } from './RulerHandle';

export const RenderAnnotationHandle = ({
  index,
  annotation,
  onIntentToEdit,
}: {
  index: number;
  annotation: Annotation;
  onIntentToEdit: (annotation: Annotation, index: number, context: AnnotationEditContext) => void;
}) => {
  return (
    <>
      {annotation.kind === 'ruler' && (
        <RulerHandle
          pixelSpacing={[1, 1]}
          {...annotation}
          onIntentToEdit={(context) => {
            onIntentToEdit(annotation, index, context);
          }}
        />
      )}
      {annotation.kind === 'arrow' && (
        <ArrowHandle
          {...annotation}
          onIntentToEdit={(context) => {
            onIntentToEdit(annotation, index, context);
          }}
        />
      )}
      {annotation.kind === 'angle' && (
        <AngleHandle
          {...annotation}
          onIntentToEdit={(context) => {
            onIntentToEdit(annotation, index, context);
          }}
        />
      )}
      {annotation.kind === 'line' && (
        <LineHandle
          {...annotation}
          onIntentToEdit={(context) => {
            onIntentToEdit(annotation, index, context);
          }}
        />
      )}
    </>
  );
};

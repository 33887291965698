/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Annotations } from '../model/dto_common_image_annotations';
import { MedicalImageViewOptions } from '../model/dto_common_image_view_options';

export const protobufPackage = 'com.diagnocat.model';

export interface MedicalImageFeatures {
  Annotations: Annotations | undefined;
  ViewOptions: MedicalImageViewOptions | undefined;
}

function createBaseMedicalImageFeatures(): MedicalImageFeatures {
  return { Annotations: undefined, ViewOptions: undefined };
}

export const MedicalImageFeatures = {
  encode(
    message: MedicalImageFeatures,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Annotations !== undefined) {
      Annotations.encode(
        message.Annotations,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.ViewOptions !== undefined) {
      MedicalImageViewOptions.encode(
        message.ViewOptions,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MedicalImageFeatures {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicalImageFeatures();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Annotations = Annotations.decode(reader, reader.uint32());
          break;
        case 2:
          message.ViewOptions = MedicalImageViewOptions.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MedicalImageFeatures {
    return {
      Annotations: isSet(object.Annotations)
        ? Annotations.fromJSON(object.Annotations)
        : undefined,
      ViewOptions: isSet(object.ViewOptions)
        ? MedicalImageViewOptions.fromJSON(object.ViewOptions)
        : undefined,
    };
  },

  toJSON(message: MedicalImageFeatures): unknown {
    const obj: any = {};
    message.Annotations !== undefined &&
      (obj.Annotations = message.Annotations
        ? Annotations.toJSON(message.Annotations)
        : undefined);
    message.ViewOptions !== undefined &&
      (obj.ViewOptions = message.ViewOptions
        ? MedicalImageViewOptions.toJSON(message.ViewOptions)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MedicalImageFeatures>, I>>(
    object: I,
  ): MedicalImageFeatures {
    const message = createBaseMedicalImageFeatures();
    message.Annotations =
      object.Annotations !== undefined && object.Annotations !== null
        ? Annotations.fromPartial(object.Annotations)
        : undefined;
    message.ViewOptions =
      object.ViewOptions !== undefined && object.ViewOptions !== null
        ? MedicalImageViewOptions.fromPartial(object.ViewOptions)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

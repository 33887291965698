// @ts-nocheck
import { ContextRequest, ProcessingUniforms, ProcessingUnit, ProcessingUnitContext } from 'graphics';
import {
    CSSProperties,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { debug } from '@/shared/lib/debug';
import { CORS_POLICY } from '@/shared/config';

export type Repaint = (context: ProcessingUnitContext, canvas: HTMLCanvasElement) => void;

export interface RCImageBaseProps {
    src: ContextRequest;
    onLoaded?: (context: ProcessingUnitContext) => void;
    onRendered?: (getPngBlob: () => Promise<Blob>) => void;
    uniforms?: ProcessingUniforms;
    style?: CSSProperties,
    onClick?: () => void;
    repaint: Repaint
}

/**
 * Base component for rendering medical images. Features extends by providing a `repaint` method.
 */
export const RCImageBase = (props: RCImageBaseProps) => {
    const { src, uniforms, onRendered, onLoaded, style, onClick, repaint } = props;

    const ref = useRef<HTMLCanvasElement>();

    const srcWithCors = {
        ...src,
        credentials: CORS_POLICY,
    }

    const [context, setContext] = useState<ProcessingUnitContext>()

    useLayoutEffect(() => {

        // debug("request ownContext", src)
        ProcessingUnit.ownContext(srcWithCors).then(context => {
            // debugger
            setContext(context)
            onLoaded?.(context)
        })
    }, [src.url, src.kind])

    useLayoutEffect(() => {
        if (!context) return

        return () => {
            // debug("disown", src, context)
            ProcessingUnit.disownContext(context)
        }
    }, [context])

    useEffect(() => {
        if (!context) return

        const unsubscribe = context.onChange(() => {
            // debugger
            repaint(context, ref.current)
            onRendered?.(getPngBlob)
        })

        return unsubscribe

    }, [context, repaint])


    useEffect(() => {
        if (!context) return

        context.render(uniforms)

    }, [context, uniforms?.ww, uniforms?.wc, uniforms?.sharpness, uniforms?.invert, repaint])


    // To make it possible for outside actors to download an image from a DicomImage's canvas
    const getPngBlob = useMemo(() => {
        return () =>
            new Promise<Blob>((resolve) => {
                ref.current.toBlob(resolve);
            });
    }, [ref]);

    return (
        <canvas
            data-src={JSON.stringify(src)}
            ref={ref}
            style={style}
            onClick={onClick}
        />
    );
};

import { useContext } from 'react';
import { ObjectFitContaintContext } from '../ObjectFitContain';
import { add, angle, cross, len, norm, scl, sub, vec2 } from '../vec2';
import { Annotation } from './Annotation';
import { cssToLogical } from './csstoLogical';

export type AngleProps = Omit<Extract<Annotation, { kind: 'angle' }>, 'kind'>;

const strokeColor = 'lime';

export const Angle = ({ x1, x2, x3, y1, y2, y3 }: AngleProps) => {


  const objectFit = useContext(ObjectFitContaintContext);

  // Render the only line while both hands are the same (creation of the first hand)
  if (x3 === x1 && y3 === y1)
    return (
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={strokeColor}
        strokeWidth={cssToLogical(2, objectFit)}
        strokeOpacity="0.4"
      />
    )

  const firstHand = vec2(x1, y1);
  const origin = vec2(x2, y2);
  const secondHand = vec2(x3, y3);
  // Normalized vector from origin to the firstHand
  const a = norm(sub(firstHand, origin));
  // Normalized vector from origin to the secondHand
  const b = norm(sub(secondHand, origin));


  // arc radius is a half of the shortest distance between hands
  const arcRadius = Math.min(len(sub(firstHand, origin)) / 2, len(sub(secondHand, origin)) / 2, cssToLogical(50, objectFit))

  // Scaled `a` vector in world space. Point where arc starts
  const A = add(origin, scl(a, arcRadius));
  // Scaled `b` vector in world space. Point where arc ends
  const B = add(origin, scl(b, arcRadius));

  // Scaled with arcRadius angle bisector. Is a point to position text
  const C = add(origin, scl(norm(add(a, b)), arcRadius * 1.3));
  // 57.2958 makes degrees from radians
  const angleText = (angle(a, b) * 57.2958).toFixed(0);
  // flag for arc drawing.
  // angle(a,b) cannot be larger that 180. It calculated the shortest angle
  // To figure out is this angle clockwise or counter-clockwise (to render arc correctly)
  // we calculate cross-product:
  // cross(a,b) > 0 means angle is clockwise and less than 180 deg
  // cross(a,b) < 0 means angle is counther-clockwise and less than 180 deg
  const sweepFlag = cross(a, b) > 0 ? 1 : 0;


  return (
    <>
      <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={strokeColor} strokeWidth={cssToLogical(2, objectFit)} strokeOpacity="0.4" />
      <line x1={x2} y1={y2} x2={x3} y2={y3} stroke={strokeColor} strokeWidth={cssToLogical(2, objectFit)} strokeOpacity="0.4" />
      <path
        d={`M ${A.x} ${A.y} A ${arcRadius} ${arcRadius} 0 0 ${sweepFlag} ${B.x} ${B.y}`}
        stroke={strokeColor}
        strokeWidth={cssToLogical(2, objectFit)}
        fill="transparent"
        strokeOpacity="0.4"
      />
      <text
        style={{
          userSelect: 'none',
          MozUserSelect: 'none',
          WebkitUserSelect: 'none',
        }}
        transform={`translate(${C.x},${C.y} )`}
        fill={strokeColor}
        textAnchor="middle"
        fontSize={cssToLogical(12, objectFit)}
      >
        {angleText}
      </text>
    </>
  );
};

import React, { ChangeEvent, ReactNode } from 'react';
import cn from 'classnames';

import { ErrorText } from '../ErrorText/ErrorText';

import { Radio, RadioProps } from './components/Radio';
import { RadioVariant } from './RadioGroup.types';
import styles from './RadioGroup.module.scss';

type RadioGroupItem = Pick<RadioProps, 'value'> & {
  label: ReactNode;
};

type RadioGroupProps = {
  className?: string;
  testID?: string;
  variant?: RadioVariant;
  innerClassName?: string;
  items: RadioGroupItem[];
  name?: string;
  value?: string | number;
  error?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
};

const InternalRadioGroup: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioGroupProps
> = (props, ref) => {
  const {
    className,
    testID,
    variant = 'default',
    innerClassName,
    items,
    name,
    value,
    error,
    onChange,
  } = props;

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div
        className={cn(
          styles.innerContainer,
          variant === 'tab' && styles.variantTab,
          innerClassName,
        )}
      >
        {items.map((radioItem) => (
          <Radio
            key={radioItem.value}
            ref={ref}
            variant={variant}
            value={radioItem.value}
            name={name}
            checked={`${radioItem.value}` === `${value}`}
            onChange={onChange}
            {...radioItem}
          >
            {radioItem.label}
          </Radio>
        ))}
      </div>
      <ErrorText error={error} />
    </div>
  );
};

export const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(
  InternalRadioGroup,
);

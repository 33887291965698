/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface UserPermissions {
  /** all users if they get this user */
  CanView: boolean;
  /** only to myself */
  CanViewPersonalData: boolean;
  /** only to myself */
  CanEditPersonalData: boolean;
  /** only to myself */
  CanEditInfo: boolean;
  /** only to myself */
  CanViewSettings: boolean;
}

function createBaseUserPermissions(): UserPermissions {
  return {
    CanView: false,
    CanViewPersonalData: false,
    CanEditPersonalData: false,
    CanEditInfo: false,
    CanViewSettings: false,
  };
}

export const UserPermissions = {
  encode(
    message: UserPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanView === true) {
      writer.uint32(8).bool(message.CanView);
    }
    if (message.CanViewPersonalData === true) {
      writer.uint32(16).bool(message.CanViewPersonalData);
    }
    if (message.CanEditPersonalData === true) {
      writer.uint32(24).bool(message.CanEditPersonalData);
    }
    if (message.CanEditInfo === true) {
      writer.uint32(32).bool(message.CanEditInfo);
    }
    if (message.CanViewSettings === true) {
      writer.uint32(40).bool(message.CanViewSettings);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanView = reader.bool();
          break;
        case 2:
          message.CanViewPersonalData = reader.bool();
          break;
        case 3:
          message.CanEditPersonalData = reader.bool();
          break;
        case 4:
          message.CanEditInfo = reader.bool();
          break;
        case 5:
          message.CanViewSettings = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserPermissions {
    return {
      CanView: isSet(object.CanView) ? Boolean(object.CanView) : false,
      CanViewPersonalData: isSet(object.CanViewPersonalData)
        ? Boolean(object.CanViewPersonalData)
        : false,
      CanEditPersonalData: isSet(object.CanEditPersonalData)
        ? Boolean(object.CanEditPersonalData)
        : false,
      CanEditInfo: isSet(object.CanEditInfo)
        ? Boolean(object.CanEditInfo)
        : false,
      CanViewSettings: isSet(object.CanViewSettings)
        ? Boolean(object.CanViewSettings)
        : false,
    };
  },

  toJSON(message: UserPermissions): unknown {
    const obj: any = {};
    message.CanView !== undefined && (obj.CanView = message.CanView);
    message.CanViewPersonalData !== undefined &&
      (obj.CanViewPersonalData = message.CanViewPersonalData);
    message.CanEditPersonalData !== undefined &&
      (obj.CanEditPersonalData = message.CanEditPersonalData);
    message.CanEditInfo !== undefined &&
      (obj.CanEditInfo = message.CanEditInfo);
    message.CanViewSettings !== undefined &&
      (obj.CanViewSettings = message.CanViewSettings);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UserPermissions>, I>>(
    object: I,
  ): UserPermissions {
    const message = createBaseUserPermissions();
    message.CanView = object.CanView ?? false;
    message.CanViewPersonalData = object.CanViewPersonalData ?? false;
    message.CanEditPersonalData = object.CanEditPersonalData ?? false;
    message.CanEditInfo = object.CanEditInfo ?? false;
    message.CanViewSettings = object.CanViewSettings ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

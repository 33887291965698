import { FC } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';

import styles from './LanguageRow.module.scss';

type LanguageRowProps = {
  languageName: string;
  changeLanguage: () => void;
  className?: string;
  testID?: string;
  checked?: boolean;
};

export const LanguageRow: FC<LanguageRowProps> = (props) => {
  const { languageName, changeLanguage, className, testID, checked } = props;

  return (
    <div
      className={cn(styles.container, 'p2', className)}
      data-testid={testID}
      onClick={changeLanguage}
    >
      {languageName}
      {checked && <Icon name="check" size={32} className={styles.checkIcon} />}
    </div>
  );
};

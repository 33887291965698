import React, { useState } from 'react';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';

import { Input } from './Input';
import { FieldProps } from './Input.types';
import inputStyles from './Input.module.scss';

const InternalInputPassword: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldProps
> = (props, ref) => {
  const { className, ...restBaseInputProps } = props;

  const [showPassword, togglePassword] = useState(false);

  return (
    <Input
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restBaseInputProps}
      className={cn(inputStyles.withRightButton, className)}
      type={showPassword ? 'text' : 'password'}
    >
      <button
        className={inputStyles.inputControlButton}
        type="button"
        disabled={restBaseInputProps.disabled}
        onClick={() => togglePassword(!showPassword)}
      >
        <Icon size={24} name={showPassword ? 'eyeClosed' : 'eye'} />
      </button>
    </Input>
  );
};

export const InputPassword = React.forwardRef<HTMLInputElement, FieldProps>(
  InternalInputPassword,
);

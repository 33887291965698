import { FC } from 'react';
import { Outlet } from 'react-router';

import { useHubspotChat } from '@/shared/hooks';

export const HubspotRoutes: FC = () => {
  useHubspotChat();

  return <Outlet />;
};

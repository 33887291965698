import { FC } from 'react';
import cn from 'classnames';

import { BasicSkeleton } from '../BasicSkeleton/BasicSkeleton';

import styles from './SkeletonRow.module.scss';

type SkeletonRowProps = {
  className?: string;
  testID?: string;
  rowsQuantity?: number;
};

export const SkeletonRow: FC<SkeletonRowProps> = (props) => {
  const { className, testID, rowsQuantity = 7 } = props;

  const rowsQuantityArray: number[] = Array(rowsQuantity).fill(0);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {rowsQuantityArray.map((element, index) => (
        <div className={styles.rowWrapper} key={(element + index).toString()}>
          <BasicSkeleton width="80px" height="46px" borderRadius="35px" />
          <div className={styles.innerWrapper}>
            <BasicSkeleton width="100%" height="16px" borderRadius="23px" />
            <BasicSkeleton width="100%" height="16px" borderRadius="23px" />
            <BasicSkeleton width="100%" height="16px" borderRadius="23px" />
          </div>
        </div>
      ))}
    </div>
  );
};

import { FC, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { useLanguages } from '@/shared/hooks/useLanguages';
import { Icon } from '@/shared/ui';

import { userModel } from '@/entities/user';

import styles from './LanguageSelector.module.scss';
import { LanguageRow } from './ui/LanguageRow/LanguageRow';

type LanguageSelectorProps = {
  className?: string;
  testID?: string;
};

export const LanguageSelector: FC<LanguageSelectorProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const userID = useAppSelector(userModel.selectors.selectCurrentUserID);

  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const { languageOptions } = useLanguages();

  const changeLanguageHandle = async (languageLocale: string) => {
    setIsOpen(false);

    const { User } = await dispatch(
      userModel.thunks.setUserLanguage({
        UserID: userID,
        Language: languageLocale,
      }),
    ).unwrap();

    if (User) {
      dispatch(userModel.actions.setOne(User));
    }
  };

  const togglePopover = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={togglePopover}>
      <Popover.Trigger asChild>
        <button
          type="button"
          className={styles.worldIconContainer}
          aria-label="Change language"
        >
          <Icon name="world" className={styles.worldIcon} />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={styles.content}
          sideOffset={5}
          align="start"
          collisionPadding={{ right: 32 }}
        >
          <div className={styles.dropdownContainer}>
            {languageOptions.map((language) => (
              <LanguageRow
                languageName={language.label}
                changeLanguage={() => changeLanguageHandle(language.value)}
                key={language.value}
                checked={language.value === userLocale}
              />
            ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

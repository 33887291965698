import { useAppDispatch } from '@/shared/hooks';

import { accessModel } from '@/entities/access';
import { patientModel } from '@/entities/patient';

export const useRevokeSharedPatient = () => {
  const dispatch = useAppDispatch();

  return async ({
    patientID,
    sharedPatientDoctorID,
  }: {
    patientID: string;
    sharedPatientDoctorID: string;
  }) => {
    const { Invitation, Patient } = await dispatch(
      accessModel.thunks.revokeSharing({
        PatientID: patientID,
        UserID: sharedPatientDoctorID,
      }),
    ).unwrap();

    if (Invitation?.ID) {
      dispatch(accessModel.actions.removeOne(Invitation.ID));
    }

    if (Patient) {
      dispatch(patientModel.actions.setNewestOne(Patient));
    }
  };
};

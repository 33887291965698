import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useAppSelector } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';
import { ProductState } from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';

import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';

import { BillingPeriod } from '../BillingPeriod/BillingPeriod.tsx';
import { NoSubscription } from '../NoSubscription/NoSubscription';
import { TopUpLimitsModal } from '../TopUpLimitsModal/TopUpLimitsModal';
import { InventoryItemView } from '../InventoryItemView/InventoryItemView';

import styles from './CurrentSubscription.module.scss';

type CurrentSubscriptionProps = {
  className?: string;
};

export const CurrentSubscription: FC<CurrentSubscriptionProps> = (props) => {
  const { className } = props;

  const { formatNumber, formatMessage } = useIntl();

  const currentSubscription = useAppSelector(
    billingModel.selectors.selectCurrentSubscription,
  );

  const availableAddons = useAppSelector(
    billingModel.selectors.selectAvailableAddons,
  );

  const isShopAvailable = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Show_BillingShop,
    ),
  );

  const hasActiveSubscription = Boolean(currentSubscription);
  const price = Number(currentSubscription?.Model?.Price ?? 0);
  const currency = currentSubscription?.Model?.Currency ?? 'USD';
  const cost = formatNumber(price, {
    style: 'currency',
    maximumFractionDigits: 2,
    currencyDisplay: 'narrowSymbol',
    currency,
  });

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h4 className="h4">
          {formatMessage({
            id: 'subscription.currentSubscription.header',
            defaultMessage: 'Current Subscription',
          })}
        </h4>
      </header>

      {hasActiveSubscription ? (
        <>
          <InventoryItemView
            className={styles.inventoryItem}
            inventoryItem={currentSubscription as ProductState}
            cost={cost}
          />

          <BillingPeriod
            startDate={currentSubscription?.Start as Date}
            endDate={currentSubscription?.End as Date}
          />

          <footer className={styles.footer}>
            {isShopAvailable && (
              <Link
                to={`${PATHS.companySettings}?tab=SubscriptionPlans`}
                className={cn(styles.viewAllPlans, 'p2')}
              >
                {formatMessage({
                  id: 'subscription.viewAllPlans',
                  defaultMessage: 'View All Plans',
                })}
              </Link>
            )}

            {/* TODO: [2|m] add method to activate/pause subscription when backend will be ready */}
            {/* <Button */}
            {/*   variant="secondary" */}
            {/*   onClick={() => */}
            {/*     dispatch( */}
            {/*       modalModel.actions.openModal({ */}
            {/*         modalID: ModalID.PauseOrActivateSubscriptionModal, */}
            {/*       }), */}
            {/*     ) */}
            {/*   } */}
            {/* > */}
            {/*   {currentSubscription?.Status === */}
            {/*   ProductStateStatus.ProductStateStatusActive ? ( */}
            {/*     <FormattedMessage id="global.pause" defaultMessage="Pause" /> */}
            {/*   ) : ( */}
            {/*     <FormattedMessage */}
            {/*       id="global.activate" */}
            {/*       defaultMessage="Activate" */}
            {/*     /> */}
            {/*   )} */}
            {/* </Button> */}
          </footer>
        </>
      ) : (
        <NoSubscription />
      )}

      {hasActiveSubscription &&
        isShopAvailable &&
        availableAddons.length > 0 && (
          <TopUpLimitsModal availableAddons={availableAddons} />
        )}
      {/* TODO: uncomment when backend will be ready */}
      {/* {(currentSubscription?.Status === */}
      {/*   ProductStateStatus.ProductStateStatusActive || */}
      {/*   currentSubscription?.Status === */}
      {/*     ProductStateStatus.ProductStateStatusAwaitingActivation) && ( */}
      {/*   <PauseOrActivateSubscriptionModal */}
      {/*     subscriptionStatus={currentSubscription.Status} */}
      {/*   /> */}
      {/* )} */}
    </div>
  );
};

import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { ErrorText } from '@/shared/ui';

import { atLeastOneOfTypeMatch } from '../utils/atLeastOneOfTypeMatch';
import { latinLettersMatch } from '../utils/latinLettersMatch';
import { MIN_PASSWORD_LENGTH } from '../config/constants';

import styles from './CustomPasswordError.module.scss';

type CustomPasswordErrorProps = {
  className?: string;
  inputValue: string;
};

export const CustomPasswordError: FC<CustomPasswordErrorProps> = ({
  className,
  inputValue,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={cn(styles.container, className)}>
      <ErrorText
        className="p1"
        withInput
        error={formatMessage({
          id: 'finishRegistration.password.errorTitle',
          defaultMessage: 'A strong password adheres to the following rules:',
        })}
      />
      <ul className={styles.rules}>
        <li
          className={cn(
            inputValue?.length >= MIN_PASSWORD_LENGTH && styles.passed,
          )}
        >
          <ErrorText
            error={formatMessage({
              id: 'finishRegistration.password.symbolCountError',
              defaultMessage: 'At least 8 symbols',
            })}
          />
        </li>
        <li className={cn(atLeastOneOfTypeMatch(inputValue) && styles.passed)}>
          <ErrorText
            error={formatMessage({
              id: 'finishRegistration.password.requiredSymbolError',
              defaultMessage:
                'At least one lowercase letter, one uppercase letter, and one number.',
            })}
          />
        </li>
        <li className={cn(latinLettersMatch(inputValue) && styles.passed)}>
          <ErrorText
            error={formatMessage({
              id: 'finishRegistration.password.onlyLatinLettersError',
              defaultMessage: 'Only Latin letters',
            })}
          />
        </li>
      </ul>
    </div>
  );
};

/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { BrowserHeaders } from 'browser-headers';
import { Asset } from '../../model/dto_asset';
import { Annotation } from '../../model/dto_common_image_annotations';
import { MedicalImageViewOptions } from '../../model/dto_common_image_view_options';
import { User } from '../../model/dto_user';
import { Organization } from '../../model/dto_organization';
import { Tooth } from '../../model/dto_report_tooth';

export const protobufPackage = 'com.diagnocat.core';

export interface GetReportUploadedAssetsReq {
  /** `ReportID`: [required] */
  ReportID: string;
}

export interface GetReportUploadedAssetsResp {
  Assets: Asset[];
}

export interface SetTopLayerAnnotationsReq {
  /** `AssetID`: [required] */
  AssetID: string;
  /** `Annotations`: [optional] */
  Annotations: Annotation[];
}

export interface SetTopLayerAnnotationsResp {
  Asset: Asset | undefined;
}

export interface SetMedicalImageViewOptionsReq {
  /** `AssetID`: [required] */
  AssetID: string;
  /** `MedicalImageViewOptions`: [required] */
  MedicalImageViewOptions: MedicalImageViewOptions | undefined;
}

export interface SetMedicalImageViewOptionsResp {
  Asset: Asset | undefined;
}

export interface DeleteAssetReq {
  /** `AssetID`: [required] */
  AssetID: string;
}

export interface DeleteAssetByFileIDReq {
  /** `FileID`: [required] */
  FileID: string;
}

export interface DeleteAssetResp {
  Asset: Asset | undefined;
  User: User | undefined;
  Organization: Organization | undefined;
  Tooth: Tooth | undefined;
}

function createBaseGetReportUploadedAssetsReq(): GetReportUploadedAssetsReq {
  return { ReportID: '' };
}

export const GetReportUploadedAssetsReq = {
  encode(
    message: GetReportUploadedAssetsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetReportUploadedAssetsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReportUploadedAssetsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetReportUploadedAssetsReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: GetReportUploadedAssetsReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetReportUploadedAssetsReq>, I>>(
    object: I,
  ): GetReportUploadedAssetsReq {
    const message = createBaseGetReportUploadedAssetsReq();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseGetReportUploadedAssetsResp(): GetReportUploadedAssetsResp {
  return { Assets: [] };
}

export const GetReportUploadedAssetsResp = {
  encode(
    message: GetReportUploadedAssetsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetReportUploadedAssetsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReportUploadedAssetsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Assets.push(Asset.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetReportUploadedAssetsResp {
    return {
      Assets: Array.isArray(object?.Assets)
        ? object.Assets.map((e: any) => Asset.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetReportUploadedAssetsResp): unknown {
    const obj: any = {};
    if (message.Assets) {
      obj.Assets = message.Assets.map((e) => (e ? Asset.toJSON(e) : undefined));
    } else {
      obj.Assets = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetReportUploadedAssetsResp>, I>>(
    object: I,
  ): GetReportUploadedAssetsResp {
    const message = createBaseGetReportUploadedAssetsResp();
    message.Assets = object.Assets?.map((e) => Asset.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetTopLayerAnnotationsReq(): SetTopLayerAnnotationsReq {
  return { AssetID: '', Annotations: [] };
}

export const SetTopLayerAnnotationsReq = {
  encode(
    message: SetTopLayerAnnotationsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AssetID !== '') {
      writer.uint32(10).string(message.AssetID);
    }
    for (const v of message.Annotations) {
      Annotation.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTopLayerAnnotationsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTopLayerAnnotationsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AssetID = reader.string();
          break;
        case 2:
          message.Annotations.push(Annotation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetTopLayerAnnotationsReq {
    return {
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
      Annotations: Array.isArray(object?.Annotations)
        ? object.Annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetTopLayerAnnotationsReq): unknown {
    const obj: any = {};
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    if (message.Annotations) {
      obj.Annotations = message.Annotations.map((e) =>
        e ? Annotation.toJSON(e) : undefined,
      );
    } else {
      obj.Annotations = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetTopLayerAnnotationsReq>, I>>(
    object: I,
  ): SetTopLayerAnnotationsReq {
    const message = createBaseSetTopLayerAnnotationsReq();
    message.AssetID = object.AssetID ?? '';
    message.Annotations =
      object.Annotations?.map((e) => Annotation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetTopLayerAnnotationsResp(): SetTopLayerAnnotationsResp {
  return { Asset: undefined };
}

export const SetTopLayerAnnotationsResp = {
  encode(
    message: SetTopLayerAnnotationsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Asset !== undefined) {
      Asset.encode(message.Asset, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTopLayerAnnotationsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTopLayerAnnotationsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Asset = Asset.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetTopLayerAnnotationsResp {
    return {
      Asset: isSet(object.Asset) ? Asset.fromJSON(object.Asset) : undefined,
    };
  },

  toJSON(message: SetTopLayerAnnotationsResp): unknown {
    const obj: any = {};
    message.Asset !== undefined &&
      (obj.Asset = message.Asset ? Asset.toJSON(message.Asset) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetTopLayerAnnotationsResp>, I>>(
    object: I,
  ): SetTopLayerAnnotationsResp {
    const message = createBaseSetTopLayerAnnotationsResp();
    message.Asset =
      object.Asset !== undefined && object.Asset !== null
        ? Asset.fromPartial(object.Asset)
        : undefined;
    return message;
  },
};

function createBaseSetMedicalImageViewOptionsReq(): SetMedicalImageViewOptionsReq {
  return { AssetID: '', MedicalImageViewOptions: undefined };
}

export const SetMedicalImageViewOptionsReq = {
  encode(
    message: SetMedicalImageViewOptionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AssetID !== '') {
      writer.uint32(10).string(message.AssetID);
    }
    if (message.MedicalImageViewOptions !== undefined) {
      MedicalImageViewOptions.encode(
        message.MedicalImageViewOptions,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetMedicalImageViewOptionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetMedicalImageViewOptionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AssetID = reader.string();
          break;
        case 2:
          message.MedicalImageViewOptions = MedicalImageViewOptions.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetMedicalImageViewOptionsReq {
    return {
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
      MedicalImageViewOptions: isSet(object.MedicalImageViewOptions)
        ? MedicalImageViewOptions.fromJSON(object.MedicalImageViewOptions)
        : undefined,
    };
  },

  toJSON(message: SetMedicalImageViewOptionsReq): unknown {
    const obj: any = {};
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    message.MedicalImageViewOptions !== undefined &&
      (obj.MedicalImageViewOptions = message.MedicalImageViewOptions
        ? MedicalImageViewOptions.toJSON(message.MedicalImageViewOptions)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetMedicalImageViewOptionsReq>, I>>(
    object: I,
  ): SetMedicalImageViewOptionsReq {
    const message = createBaseSetMedicalImageViewOptionsReq();
    message.AssetID = object.AssetID ?? '';
    message.MedicalImageViewOptions =
      object.MedicalImageViewOptions !== undefined &&
      object.MedicalImageViewOptions !== null
        ? MedicalImageViewOptions.fromPartial(object.MedicalImageViewOptions)
        : undefined;
    return message;
  },
};

function createBaseSetMedicalImageViewOptionsResp(): SetMedicalImageViewOptionsResp {
  return { Asset: undefined };
}

export const SetMedicalImageViewOptionsResp = {
  encode(
    message: SetMedicalImageViewOptionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Asset !== undefined) {
      Asset.encode(message.Asset, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetMedicalImageViewOptionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetMedicalImageViewOptionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Asset = Asset.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetMedicalImageViewOptionsResp {
    return {
      Asset: isSet(object.Asset) ? Asset.fromJSON(object.Asset) : undefined,
    };
  },

  toJSON(message: SetMedicalImageViewOptionsResp): unknown {
    const obj: any = {};
    message.Asset !== undefined &&
      (obj.Asset = message.Asset ? Asset.toJSON(message.Asset) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetMedicalImageViewOptionsResp>, I>>(
    object: I,
  ): SetMedicalImageViewOptionsResp {
    const message = createBaseSetMedicalImageViewOptionsResp();
    message.Asset =
      object.Asset !== undefined && object.Asset !== null
        ? Asset.fromPartial(object.Asset)
        : undefined;
    return message;
  },
};

function createBaseDeleteAssetReq(): DeleteAssetReq {
  return { AssetID: '' };
}

export const DeleteAssetReq = {
  encode(
    message: DeleteAssetReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AssetID !== '') {
      writer.uint32(10).string(message.AssetID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAssetReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAssetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteAssetReq {
    return {
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
    };
  },

  toJSON(message: DeleteAssetReq): unknown {
    const obj: any = {};
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteAssetReq>, I>>(
    object: I,
  ): DeleteAssetReq {
    const message = createBaseDeleteAssetReq();
    message.AssetID = object.AssetID ?? '';
    return message;
  },
};

function createBaseDeleteAssetByFileIDReq(): DeleteAssetByFileIDReq {
  return { FileID: '' };
}

export const DeleteAssetByFileIDReq = {
  encode(
    message: DeleteAssetByFileIDReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FileID !== '') {
      writer.uint32(10).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteAssetByFileIDReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAssetByFileIDReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteAssetByFileIDReq {
    return {
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: DeleteAssetByFileIDReq): unknown {
    const obj: any = {};
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteAssetByFileIDReq>, I>>(
    object: I,
  ): DeleteAssetByFileIDReq {
    const message = createBaseDeleteAssetByFileIDReq();
    message.FileID = object.FileID ?? '';
    return message;
  },
};

function createBaseDeleteAssetResp(): DeleteAssetResp {
  return {
    Asset: undefined,
    User: undefined,
    Organization: undefined,
    Tooth: undefined,
  };
}

export const DeleteAssetResp = {
  encode(
    message: DeleteAssetResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Asset !== undefined) {
      Asset.encode(message.Asset, writer.uint32(10).fork()).ldelim();
    }
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(90).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAssetResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAssetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Asset = Asset.decode(reader, reader.uint32());
          break;
        case 11:
          message.User = User.decode(reader, reader.uint32());
          break;
        case 12:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 13:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteAssetResp {
    return {
      Asset: isSet(object.Asset) ? Asset.fromJSON(object.Asset) : undefined,
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: DeleteAssetResp): unknown {
    const obj: any = {};
    message.Asset !== undefined &&
      (obj.Asset = message.Asset ? Asset.toJSON(message.Asset) : undefined);
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteAssetResp>, I>>(
    object: I,
  ): DeleteAssetResp {
    const message = createBaseDeleteAssetResp();
    message.Asset =
      object.Asset !== undefined && object.Asset !== null
        ? Asset.fromPartial(object.Asset)
        : undefined;
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

export interface AssetService {
  GetReportUploadedAssets(
    request: DeepPartial<GetReportUploadedAssetsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetReportUploadedAssetsResp>;
  /**
   * Update annotations in Layer with ReadOnly == false. If this layer not exists -> create new Layer
   *
   * Permission: `ReportPermissions.CanChangeReportAssets`
   */
  SetTopLayerAnnotations(
    request: DeepPartial<SetTopLayerAnnotationsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetTopLayerAnnotationsResp>;
  /**
   * Update viewOptions in report assets (AssetType_Report_Generated || AssetType_Report_Uploaded)
   *
   * Permission: `ReportPermissions.CanChangeReportAssets`
   */
  SetMedicalImageViewOptions(
    request: DeepPartial<SetMedicalImageViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetMedicalImageViewOptionsResp>;
  /**
   * Only for User, Organization or Patient assets
   *
   * Permission: `OrganizationPermissions.CanEditInfo` - organization assets
   * Permission: `UserPermissions.CanEditInfo` - user assets
   * Permission: `PatientPermissions.CanEditPatient` - patient documents
   * Permission: `ReportPermissions.CanChangeReportAssets` - report assets
   * Permission: `ReportPermissions.CanChangeToothAssets` - teeth assets
   */
  DeleteAsset(
    request: DeepPartial<DeleteAssetReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAssetResp>;
  DeleteAssetByFileID(
    request: DeepPartial<DeleteAssetByFileIDReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAssetResp>;
}

export class AssetServiceClientImpl implements AssetService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetReportUploadedAssets = this.GetReportUploadedAssets.bind(this);
    this.SetTopLayerAnnotations = this.SetTopLayerAnnotations.bind(this);
    this.SetMedicalImageViewOptions =
      this.SetMedicalImageViewOptions.bind(this);
    this.DeleteAsset = this.DeleteAsset.bind(this);
    this.DeleteAssetByFileID = this.DeleteAssetByFileID.bind(this);
  }

  GetReportUploadedAssets(
    request: DeepPartial<GetReportUploadedAssetsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetReportUploadedAssetsResp> {
    return this.rpc.unary(
      AssetServiceGetReportUploadedAssetsDesc,
      GetReportUploadedAssetsReq.fromPartial(request),
      metadata,
    );
  }

  SetTopLayerAnnotations(
    request: DeepPartial<SetTopLayerAnnotationsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetTopLayerAnnotationsResp> {
    return this.rpc.unary(
      AssetServiceSetTopLayerAnnotationsDesc,
      SetTopLayerAnnotationsReq.fromPartial(request),
      metadata,
    );
  }

  SetMedicalImageViewOptions(
    request: DeepPartial<SetMedicalImageViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetMedicalImageViewOptionsResp> {
    return this.rpc.unary(
      AssetServiceSetMedicalImageViewOptionsDesc,
      SetMedicalImageViewOptionsReq.fromPartial(request),
      metadata,
    );
  }

  DeleteAsset(
    request: DeepPartial<DeleteAssetReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAssetResp> {
    return this.rpc.unary(
      AssetServiceDeleteAssetDesc,
      DeleteAssetReq.fromPartial(request),
      metadata,
    );
  }

  DeleteAssetByFileID(
    request: DeepPartial<DeleteAssetByFileIDReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAssetResp> {
    return this.rpc.unary(
      AssetServiceDeleteAssetByFileIDDesc,
      DeleteAssetByFileIDReq.fromPartial(request),
      metadata,
    );
  }
}

export const AssetServiceDesc = {
  serviceName: 'com.diagnocat.core.AssetService',
};

export const AssetServiceGetReportUploadedAssetsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetReportUploadedAssets',
    service: AssetServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetReportUploadedAssetsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...GetReportUploadedAssetsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AssetServiceSetTopLayerAnnotationsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetTopLayerAnnotations',
    service: AssetServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetTopLayerAnnotationsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetTopLayerAnnotationsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AssetServiceSetMedicalImageViewOptionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetMedicalImageViewOptions',
    service: AssetServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetMedicalImageViewOptionsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetMedicalImageViewOptionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AssetServiceDeleteAssetDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteAsset',
  service: AssetServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteAssetReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteAssetResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AssetServiceDeleteAssetByFileIDDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteAssetByFileID',
  service: AssetServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteAssetByFileIDReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteAssetResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

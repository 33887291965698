import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import { ReportUserSignature } from '@/shared/api/protocol_gen/model/dto_report';
import { useAppSelector } from '@/shared/hooks';

import { organizationModel, i18n } from '@/entities/organization';
import { getImageSrc } from '@/entities/assets';

import styles from './Signature.module.scss';

type SignatureProps = {
  className?: string;
  testID?: string;
  signature: ReportUserSignature;
};

export const Signature: FC<SignatureProps> = (props) => {
  const { signature, className, testID } = props;

  const { formatMessage } = useIntl();

  const currentOrganization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const allOrganizationDoctors = useAppSelector(
    organizationModel.selectors.selectAllUsers,
  );

  const signedUser = allOrganizationDoctors.find(
    (user) => user.UserID === signature.UserID,
  );

  const { FirstName, LastName } = signedUser?.PersonalData ?? {};
  const speciality = signedUser?.JobPositions;

  const isSpecialityExists = !isEmpty(speciality) && speciality !== undefined;
  const isSignatureImageExists = !isEmpty(signature.SignatureAssetID);
  const isStampImageExists = !isEmpty(signature.StampAssetID);
  const isOrganizationStampExists = !isEmpty(currentOrganization?.StampAssetID);

  const signatureImageSrc = getImageSrc(signature.SignatureAssetID, 'original');
  const stampImageSrc = getImageSrc(signature.StampAssetID, 'original');
  const organizationStampImage = getImageSrc(
    currentOrganization?.StampAssetID ?? '',
    'original',
  );

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.nameAndSpeciality}>
        <h2 className={cn(styles.signedUserName, 'h5')}>
          {`${FirstName}\n${LastName}`}
        </h2>

        {isSpecialityExists && (
          <p className="p2">
            {formatMessage(i18n.specialityType[speciality[0]])}
          </p>
        )}
      </div>

      {isSignatureImageExists && (
        <div className={styles.imgFrame}>
          <img
            src={signatureImageSrc}
            alt={formatMessage({
              id: 'global.signature',
              defaultMessage: 'Signature',
            })}
            className={styles.signatureOrStampImage}
          />
        </div>
      )}

      {isStampImageExists && (
        <div className={styles.imgFrame}>
          <img
            src={stampImageSrc}
            alt={formatMessage({
              id: 'global.stamp',
              defaultMessage: 'Stamp',
            })}
            className={styles.signatureOrStampImage}
          />
        </div>
      )}

      {isOrganizationStampExists && (
        <div className={styles.imgFrame}>
          <img
            src={organizationStampImage}
            alt={formatMessage({
              id: 'imgAltText.stamp',
              defaultMessage: 'Stamp',
            })}
            className={styles.signatureOrStampImage}
          />
        </div>
      )}
    </div>
  );
};

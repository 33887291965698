import { StylesConfig } from 'react-select';

export const addStaffModalSelectStyles: StylesConfig = {
  input: (base) => ({
    ...base,
    padding: 12,
  }),
  placeholder: (base) => ({
    ...base,
    marginLeft: 16,
    marginRight: 16,
  }),
  option: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 4,
  }),
  valueContainer: (base) => ({
    ...base,
    gap: '8px 16px',
    margin: 0,
    padding: 0,
  }),
  multiValue: (base) => ({
    ...base,
    gap: 8,
    borderRadius: 24,
    backgroundColor: 'rgba(var(--grey10-rgb), 0.4)',
    border: 'none',
    minHeight: 46,
    padding: 4,
  }),
  multiValueLabel: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: 0,
    paddingLeft: 8,
    color: 'var(--bw)',
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    color: 'var(--grey9)',
    ':hover': {
      color: 'var(--redrose)',
    },
  }),
};

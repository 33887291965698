import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';

import { DentalNotations } from '@/entities/tooth';

export enum CompanyInfoTitles {
  Emails = 'Emails',
  Phones = 'Phones',
  WebSite = 'WebSite',
  Country = 'Country',
  City = 'City',
  Address = 'Address',
  ZipCode = 'ZipCode',
  Region = 'Region',
}

export enum TabValue {
  CompanyInfo = 'CompanyInfo',
  Staff = 'Staff',
  BillingInfo = 'BillingInfo',
  SubscriptionPlans = 'SubscriptionPlans',
}

export const CONTACTS_SORT = [
  'Country',
  'Region',
  'City',
  'Address',
  'ZipCode',
  'Emails',
  'Phones',
  'WebSite',
];

export const DENTAL_NOTATIONS = [
  {
    value: DentalNotationFormat.DentalNotationFormatISO,
    label: DentalNotations.ISO,
  },
  {
    value: DentalNotationFormat.DentalNotationFormatUniversal,
    label: DentalNotations.UNIVERSAL,
  },
];

import { Outlet } from 'react-router';

import { Layout } from '@/shared/ui';

import { Header } from '@/widgets/Header';

export const GPReportLayout = () => {
  return (
    <Layout footerWithSettings fullWidth>
      <Layout.Header>
        <Header fullWidth />
      </Layout.Header>
      <Outlet />
    </Layout>
  );
};

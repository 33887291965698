import { FormattedMessage } from 'react-intl';

import { ApiError } from '@/shared/api/api';
import { ALL_PERMISSIONS } from '@/shared/config';
import {
  confirmEmailErrorMessages,
  newPasswordErrorMessages,
  sharePatientErrorMessages,
} from '@/shared/i18n';

import { ErrorHandlers } from '../config';

export const getErrorMessage = (error: ApiError) => {
  const { type: errorType, message } = error;
  const {
    code: errorCode,
    reason: errorReason,
    entity: errorEntity,
  } = JSON.parse(message);

  const defaultError = (
    <FormattedMessage
      id="global.defaultError"
      defaultMessage="Something went wrong. Try again later or contact our support team."
    />
  );

  const errorHandlers: ErrorHandlers = {
    UnauthenticatedError: () => (
      <FormattedMessage
        id="unauthenticatedError.loggedOff"
        defaultMessage="You were logged off. Sign in again."
      />
    ),
    // NOTE: [4/m] sync types with backend
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    UserBadRequestError: () => {
      const message = confirmEmailErrorMessages[errorCode];

      return message ? (
        <FormattedMessage
          id={message.id}
          defaultMessage={message.defaultMessage}
        />
      ) : (
        defaultError
      );
    },
    PermissionDeniedError: () =>
      // NOTE: We check that for PermissionDeniedError, the errorReason contains fields from ReportPermissions or PatientPermissions
      ALL_PERMISSIONS.some((permission) => errorReason.includes(permission)) ? (
        <FormattedMessage
          id="permissionDeniedError.noAccess"
          defaultMessage="Your access level does not allow you to perform the chosen action."
        />
      ) : (
        defaultError
      ),

    AlreadyExistError: () => {
      const message = sharePatientErrorMessages[errorEntity];

      return message ? (
        <FormattedMessage
          id={message.id}
          defaultMessage={message.defaultMessage}
        />
      ) : (
        defaultError
      );
    },

    NotFoundError: () => {
      const message = newPasswordErrorMessages[errorEntity];

      return message ? (
        <FormattedMessage
          id={message.id}
          defaultMessage={message.defaultMessage}
        />
      ) : (
        defaultError
      );
    },

    default: () => defaultError,
  };

  return (errorHandlers[errorType] || errorHandlers.default)();
};

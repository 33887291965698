import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '@/shared/api/protocol_gen/model/dto_user';
import { LoadingStateType } from '@/shared/config';

import { getInitialUserSettings } from '../lib/getInitialUserSettings';

import { setSettings } from './userSlice.thunks';

export type UserOrganizationLegacy = {
  id: string;
  name: string;
  isCurrentUserAdmin: boolean;
};

// TODO:  [1/l] maybe need remove UserOrganizationLegacy
const initialState = {
  user: {} as User,
  userOrganizationLegacy: {
    id: '',
    name: '',
    isCurrentUserAdmin: true,
  },
  settings: getInitialUserSettings(),
  loading: 'idle' as LoadingStateType,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setOne: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setUserOrganizationLegacy: (
      state,
      action: PayloadAction<UserOrganizationLegacy>,
    ) => {
      state.userOrganizationLegacy = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSettings.fulfilled, (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    });
  },
});

export const { actions } = userSlice;

export default userSlice.reducer;

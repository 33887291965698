/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.messenger';

export interface Message {
  ID: string;
  Time: string;
  Sender: string;
  Links: Link[];
  Content: Content[];
  Service: boolean;
  Edited: MessageEdited | undefined;
}

export interface MessageEdited {
  Edited: boolean;
  Editor: string;
  Time: string;
}

export interface Link {
  EntityType: string;
  EntityID: string;
  LinkType: string;
}

export interface Content {
  Service: ContentService | undefined;
  Text: ContentText | undefined;
  Mention: ContentMention | undefined;
}

export interface ContentService {}

export interface ContentText {
  Text: string;
}

export interface ContentMention {
  Kind: string;
  ID: string;
  Name: string;
}

function createBaseMessage(): Message {
  return {
    ID: '',
    Time: '',
    Sender: '',
    Links: [],
    Content: [],
    Service: false,
    Edited: undefined,
  };
}

export const Message = {
  encode(
    message: Message,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Time !== '') {
      writer.uint32(18).string(message.Time);
    }
    if (message.Sender !== '') {
      writer.uint32(26).string(message.Sender);
    }
    for (const v of message.Links) {
      Link.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.Content) {
      Content.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.Service === true) {
      writer.uint32(48).bool(message.Service);
    }
    if (message.Edited !== undefined) {
      MessageEdited.encode(message.Edited, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Time = reader.string();
          break;
        case 3:
          message.Sender = reader.string();
          break;
        case 4:
          message.Links.push(Link.decode(reader, reader.uint32()));
          break;
        case 5:
          message.Content.push(Content.decode(reader, reader.uint32()));
          break;
        case 6:
          message.Service = reader.bool();
          break;
        case 7:
          message.Edited = MessageEdited.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Message {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Time: isSet(object.Time) ? String(object.Time) : '',
      Sender: isSet(object.Sender) ? String(object.Sender) : '',
      Links: Array.isArray(object?.Links)
        ? object.Links.map((e: any) => Link.fromJSON(e))
        : [],
      Content: Array.isArray(object?.Content)
        ? object.Content.map((e: any) => Content.fromJSON(e))
        : [],
      Service: isSet(object.Service) ? Boolean(object.Service) : false,
      Edited: isSet(object.Edited)
        ? MessageEdited.fromJSON(object.Edited)
        : undefined,
    };
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Time !== undefined && (obj.Time = message.Time);
    message.Sender !== undefined && (obj.Sender = message.Sender);
    if (message.Links) {
      obj.Links = message.Links.map((e) => (e ? Link.toJSON(e) : undefined));
    } else {
      obj.Links = [];
    }
    if (message.Content) {
      obj.Content = message.Content.map((e) =>
        e ? Content.toJSON(e) : undefined,
      );
    } else {
      obj.Content = [];
    }
    message.Service !== undefined && (obj.Service = message.Service);
    message.Edited !== undefined &&
      (obj.Edited = message.Edited
        ? MessageEdited.toJSON(message.Edited)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    message.ID = object.ID ?? '';
    message.Time = object.Time ?? '';
    message.Sender = object.Sender ?? '';
    message.Links = object.Links?.map((e) => Link.fromPartial(e)) || [];
    message.Content = object.Content?.map((e) => Content.fromPartial(e)) || [];
    message.Service = object.Service ?? false;
    message.Edited =
      object.Edited !== undefined && object.Edited !== null
        ? MessageEdited.fromPartial(object.Edited)
        : undefined;
    return message;
  },
};

function createBaseMessageEdited(): MessageEdited {
  return { Edited: false, Editor: '', Time: '' };
}

export const MessageEdited = {
  encode(
    message: MessageEdited,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Edited === true) {
      writer.uint32(8).bool(message.Edited);
    }
    if (message.Editor !== '') {
      writer.uint32(18).string(message.Editor);
    }
    if (message.Time !== '') {
      writer.uint32(26).string(message.Time);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageEdited {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageEdited();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Edited = reader.bool();
          break;
        case 2:
          message.Editor = reader.string();
          break;
        case 3:
          message.Time = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MessageEdited {
    return {
      Edited: isSet(object.Edited) ? Boolean(object.Edited) : false,
      Editor: isSet(object.Editor) ? String(object.Editor) : '',
      Time: isSet(object.Time) ? String(object.Time) : '',
    };
  },

  toJSON(message: MessageEdited): unknown {
    const obj: any = {};
    message.Edited !== undefined && (obj.Edited = message.Edited);
    message.Editor !== undefined && (obj.Editor = message.Editor);
    message.Time !== undefined && (obj.Time = message.Time);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MessageEdited>, I>>(
    object: I,
  ): MessageEdited {
    const message = createBaseMessageEdited();
    message.Edited = object.Edited ?? false;
    message.Editor = object.Editor ?? '';
    message.Time = object.Time ?? '';
    return message;
  },
};

function createBaseLink(): Link {
  return { EntityType: '', EntityID: '', LinkType: '' };
}

export const Link = {
  encode(message: Link, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.EntityType !== '') {
      writer.uint32(10).string(message.EntityType);
    }
    if (message.EntityID !== '') {
      writer.uint32(18).string(message.EntityID);
    }
    if (message.LinkType !== '') {
      writer.uint32(26).string(message.LinkType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Link {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.EntityType = reader.string();
          break;
        case 2:
          message.EntityID = reader.string();
          break;
        case 3:
          message.LinkType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Link {
    return {
      EntityType: isSet(object.EntityType) ? String(object.EntityType) : '',
      EntityID: isSet(object.EntityID) ? String(object.EntityID) : '',
      LinkType: isSet(object.LinkType) ? String(object.LinkType) : '',
    };
  },

  toJSON(message: Link): unknown {
    const obj: any = {};
    message.EntityType !== undefined && (obj.EntityType = message.EntityType);
    message.EntityID !== undefined && (obj.EntityID = message.EntityID);
    message.LinkType !== undefined && (obj.LinkType = message.LinkType);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Link>, I>>(object: I): Link {
    const message = createBaseLink();
    message.EntityType = object.EntityType ?? '';
    message.EntityID = object.EntityID ?? '';
    message.LinkType = object.LinkType ?? '';
    return message;
  },
};

function createBaseContent(): Content {
  return { Service: undefined, Text: undefined, Mention: undefined };
}

export const Content = {
  encode(
    message: Content,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Service !== undefined) {
      ContentService.encode(message.Service, writer.uint32(10).fork()).ldelim();
    }
    if (message.Text !== undefined) {
      ContentText.encode(message.Text, writer.uint32(18).fork()).ldelim();
    }
    if (message.Mention !== undefined) {
      ContentMention.encode(message.Mention, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Content {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Service = ContentService.decode(reader, reader.uint32());
          break;
        case 2:
          message.Text = ContentText.decode(reader, reader.uint32());
          break;
        case 3:
          message.Mention = ContentMention.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Content {
    return {
      Service: isSet(object.Service)
        ? ContentService.fromJSON(object.Service)
        : undefined,
      Text: isSet(object.Text) ? ContentText.fromJSON(object.Text) : undefined,
      Mention: isSet(object.Mention)
        ? ContentMention.fromJSON(object.Mention)
        : undefined,
    };
  },

  toJSON(message: Content): unknown {
    const obj: any = {};
    message.Service !== undefined &&
      (obj.Service = message.Service
        ? ContentService.toJSON(message.Service)
        : undefined);
    message.Text !== undefined &&
      (obj.Text = message.Text ? ContentText.toJSON(message.Text) : undefined);
    message.Mention !== undefined &&
      (obj.Mention = message.Mention
        ? ContentMention.toJSON(message.Mention)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Content>, I>>(object: I): Content {
    const message = createBaseContent();
    message.Service =
      object.Service !== undefined && object.Service !== null
        ? ContentService.fromPartial(object.Service)
        : undefined;
    message.Text =
      object.Text !== undefined && object.Text !== null
        ? ContentText.fromPartial(object.Text)
        : undefined;
    message.Mention =
      object.Mention !== undefined && object.Mention !== null
        ? ContentMention.fromPartial(object.Mention)
        : undefined;
    return message;
  },
};

function createBaseContentService(): ContentService {
  return {};
}

export const ContentService = {
  encode(
    _: ContentService,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentService {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ContentService {
    return {};
  },

  toJSON(_: ContentService): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ContentService>, I>>(
    _: I,
  ): ContentService {
    const message = createBaseContentService();
    return message;
  },
};

function createBaseContentText(): ContentText {
  return { Text: '' };
}

export const ContentText = {
  encode(
    message: ContentText,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Text !== '') {
      writer.uint32(10).string(message.Text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentText {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentText();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Text = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContentText {
    return {
      Text: isSet(object.Text) ? String(object.Text) : '',
    };
  },

  toJSON(message: ContentText): unknown {
    const obj: any = {};
    message.Text !== undefined && (obj.Text = message.Text);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ContentText>, I>>(
    object: I,
  ): ContentText {
    const message = createBaseContentText();
    message.Text = object.Text ?? '';
    return message;
  },
};

function createBaseContentMention(): ContentMention {
  return { Kind: '', ID: '', Name: '' };
}

export const ContentMention = {
  encode(
    message: ContentMention,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Kind !== '') {
      writer.uint32(10).string(message.Kind);
    }
    if (message.ID !== '') {
      writer.uint32(18).string(message.ID);
    }
    if (message.Name !== '') {
      writer.uint32(26).string(message.Name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContentMention {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContentMention();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Kind = reader.string();
          break;
        case 2:
          message.ID = reader.string();
          break;
        case 3:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContentMention {
    return {
      Kind: isSet(object.Kind) ? String(object.Kind) : '',
      ID: isSet(object.ID) ? String(object.ID) : '',
      Name: isSet(object.Name) ? String(object.Name) : '',
    };
  },

  toJSON(message: ContentMention): unknown {
    const obj: any = {};
    message.Kind !== undefined && (obj.Kind = message.Kind);
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ContentMention>, I>>(
    object: I,
  ): ContentMention {
    const message = createBaseContentMention();
    message.Kind = object.Kind ?? '';
    message.ID = object.ID ?? '';
    message.Name = object.Name ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { useIntl } from 'react-intl';

import { ownerSpecialityType } from '../config/i18n';

export const useGetSpecializationOptions = () => {
  const { formatMessage } = useIntl();

  const companyProfileOptions = [
    {
      label: formatMessage(ownerSpecialityType.dentalTechnician),
      value: 'Dental Technician',
    },
    {
      label: formatMessage(ownerSpecialityType.endodontist),
      value: 'Endodontist',
    },
    {
      label: formatMessage(ownerSpecialityType.generalPractitioner),
      value: 'General Practitioner',
    },
    {
      label: formatMessage(ownerSpecialityType.oralSurgeon),
      value: 'Oral Surgeon',
    },
    {
      label: formatMessage(ownerSpecialityType.orthodontist),
      value: 'Orthodontist',
    },
    {
      label: formatMessage(ownerSpecialityType.pediatricDentist),
      value: 'Pediatric Dentist',
    },
    {
      label: formatMessage(ownerSpecialityType.periodontist),
      value: 'Periodontist',
    },
    {
      label: formatMessage(ownerSpecialityType.prosthodontist),
      value: 'Prosthodontist',
    },
    {
      label: formatMessage(ownerSpecialityType.radiologist),
      value: 'Radiologist',
    },
  ];

  companyProfileOptions.sort((a, b) => a.label.localeCompare(b.label));

  companyProfileOptions.push({
    label: formatMessage({ id: 'global.other', defaultMessage: 'Other' }),
    value: 'Other',
  });

  return companyProfileOptions;
};

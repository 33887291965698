import { FC } from 'react';

import {
  BasicLayout,
  BasicLayoutProps,
} from './components/BasicLayout/BasicLayout';
import { Header } from './components/Header/Header';
import { Content } from './components/Content/Content';
import { Sider } from './components/Sider/Sider';
import { Main } from './components/Main/Main';
import { Footer } from './components/Footer/Footer';

type CompoundedComponent = FC<BasicLayoutProps> & {
  Header: typeof Header;
  Content: typeof Content;
  Main: typeof Main;
  Sider: typeof Sider;
  Footer: typeof Footer;
};

const Layout = BasicLayout as CompoundedComponent;

Layout.Header = Header;
Layout.Content = Content;
Layout.Main = Main;
Layout.Sider = Sider;
Layout.Footer = Footer;

export { Layout };

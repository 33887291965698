import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import { Input, Skeleton } from '@/shared/ui';
import { useAppSelector, useSearch } from '@/shared/hooks';

import { PatientsFilters, PatientsFiltersType } from '@/entities/patient';
import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';

import { ViewSwitcher } from '@/features/viewSwitcher';
import { Filters } from '@/features/filters';

import { searchBarPlaceholders } from '../config/i18n';

import styles from './PatientListFilters.module.scss';

type PatientListFiltersProps = {
  amount: {
    sharedWithMeCount: number;
    sharedByMeCount: number;
    totalOrganizationPatientCount: number;
  };
  onInputChange: (value: string) => void;
  onFilterChange: (searchValue: string) => void;
  currentPatientsFilter: PatientsFiltersType;
  className?: string;
  // TODO: Make general handler for all filters, e.g. search, filters, doctors
};

export const PatientListFilters = ({
  onInputChange,
  onFilterChange,
  amount,
  currentPatientsFilter,
  className,
}: PatientListFiltersProps) => {
  const { sharedWithMeCount, sharedByMeCount, totalOrganizationPatientCount } =
    amount;

  const { formatMessage } = useIntl();

  const [search, updateSearch] = useSearch(onInputChange);

  const allOrganizations = useAppSelector(
    organizationModel.selectors.organizationSelectors.selectAll,
  );

  const organizationLoading = useAppSelector(
    (state) => state.organization.loading,
  );

  const showSkeleton =
    organizationLoading === 'pending' || organizationLoading === 'idle';

  const selectUserOrganizationLegacy = useAppSelector(
    userModel.selectors.selectUserOrganizationLegacy,
  );

  const { isCurrentUserAdmin } = selectUserOrganizationLegacy;

  const isAllPatientList =
    currentPatientsFilter === PatientsFilters.all ||
    currentPatientsFilter === null;

  const isAllOrganizations = !isEmpty(allOrganizations);

  const filtersItemsConfig = isAllOrganizations
    ? [
        {
          value: PatientsFilters.all,
          label: <FormattedMessage id="global.all" defaultMessage="All" />,
          amount: totalOrganizationPatientCount,
        },
        {
          value: PatientsFilters.sharedWithMe,
          label: (
            <FormattedMessage
              id="patientList.filters.sharedWithMe"
              defaultMessage="Shared with me"
            />
          ),
          amount: sharedWithMeCount,
        },

        isCurrentUserAdmin
          ? {
              value: PatientsFilters.sharedByStaff,
              label: (
                <FormattedMessage
                  id="patientList.filters.sharedByStaff"
                  defaultMessage="Shared by staff"
                />
              ),
              amount: sharedByMeCount,
            }
          : {
              value: PatientsFilters.sharedByMe,
              label: (
                <FormattedMessage
                  id="patientList.filters.sharedByMe"
                  defaultMessage="Shared by me"
                />
              ),
              amount: sharedByMeCount,
            },
      ]
    : [
        {
          value: PatientsFilters.sharedWithMe,
          label: (
            <FormattedMessage
              id="patientList.filters.sharedWithMe"
              defaultMessage="Shared with me"
            />
          ),
          amount: sharedWithMeCount,
        },
      ];

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.mainFilters}>
        {showSkeleton ? (
          <Skeleton.Filter
            filtersQuantity={3}
            height={48}
            gap={8}
            borderRadius={12}
          />
        ) : (
          <Filters<PatientsFiltersType>
            className={styles.patientFilters}
            filterName="filters"
            defaultActiveKey={
              (filtersItemsConfig.length === 1 &&
                filtersItemsConfig[0].value) ||
              PatientsFilters.all
            }
            items={filtersItemsConfig}
            onChange={(event) => onFilterChange(event)}
          />
        )}

        <Input
          className={styles.search}
          name="search"
          type="search"
          value={search}
          icon="search"
          inputMode="search"
          autoComplete="off"
          placeholder={formatMessage(
            searchBarPlaceholders[currentPatientsFilter],
          )}
          onChange={(event) => updateSearch(event.target.value)}
          onResetField={() => updateSearch('')}
        />

        {isAllPatientList && <ViewSwitcher className={styles.viewSwitcher} />}
      </div>
    </div>
  );
};

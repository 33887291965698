import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: (): { theme: 'dark' | 'light' } => {
    const theme = localStorage.getItem('theme') as 'dark' | 'light' | undefined;

    if (theme) return { theme };

    return { theme: 'light' };
  },
  reducers: {
    setTheme: (state, action: PayloadAction<'dark' | 'light'>) => {
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { SliceName } from '@/shared/config';
import { ReportDataStreamResp_AllowedToothConditionsHint } from '@/shared/api/protocol_gen/api/core/svc_report';

export const allowedToothConditionsAdapter =
  createEntityAdapter<ReportDataStreamResp_AllowedToothConditionsHint>({
    selectId: (
      allowedToothConditions: ReportDataStreamResp_AllowedToothConditionsHint,
    ) => allowedToothConditions?.ToothID,
  });

const allowedToothConditionsSlice = createSlice({
  name: SliceName.allowedToothConditions,
  initialState: allowedToothConditionsAdapter.getInitialState(),
  reducers: {
    setOne: allowedToothConditionsAdapter.setOne,
  },
});

export const { actions } = allowedToothConditionsSlice;

export default allowedToothConditionsSlice.reducer;

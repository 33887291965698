/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

/** Flags for all actions that can be disabled under User permission level */
export interface ReportPermissions {
  /**
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient doctor <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanViewPatient` == false <br>
   */
  CanView: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeROI: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeConditions: boolean;
  /**
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanInteractWithImages: boolean;
  /**
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team member <br>
   */
  CanSign: boolean;
  /**
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report
   */
  CanPrint: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeConclusion: boolean;
  /**
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeSettings: boolean;
  /**
   * - patient team member <br>
   * - report creator
   */
  CanDelete: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeReportAssets: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeToothComment: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeToothNumeration: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeToothApproved: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanResetTooth: boolean;
  /**
   * - only if the report is not signed
   * - if the user has Owner or Administrative role in the organization <br>
   * - patient team members <br>
   * - all organization employees, if they can view this report and `OrganizationSettings.OnlyTeamMembersCanEditReport` == false <br>
   */
  CanChangeToothAssets: boolean;
}

function createBaseReportPermissions(): ReportPermissions {
  return {
    CanView: false,
    CanChangeROI: false,
    CanChangeConditions: false,
    CanInteractWithImages: false,
    CanSign: false,
    CanPrint: false,
    CanChangeConclusion: false,
    CanChangeSettings: false,
    CanDelete: false,
    CanChangeReportAssets: false,
    CanChangeToothComment: false,
    CanChangeToothNumeration: false,
    CanChangeToothApproved: false,
    CanResetTooth: false,
    CanChangeToothAssets: false,
  };
}

export const ReportPermissions = {
  encode(
    message: ReportPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanView === true) {
      writer.uint32(8).bool(message.CanView);
    }
    if (message.CanChangeROI === true) {
      writer.uint32(16).bool(message.CanChangeROI);
    }
    if (message.CanChangeConditions === true) {
      writer.uint32(32).bool(message.CanChangeConditions);
    }
    if (message.CanInteractWithImages === true) {
      writer.uint32(40).bool(message.CanInteractWithImages);
    }
    if (message.CanSign === true) {
      writer.uint32(48).bool(message.CanSign);
    }
    if (message.CanPrint === true) {
      writer.uint32(56).bool(message.CanPrint);
    }
    if (message.CanChangeConclusion === true) {
      writer.uint32(72).bool(message.CanChangeConclusion);
    }
    if (message.CanChangeSettings === true) {
      writer.uint32(80).bool(message.CanChangeSettings);
    }
    if (message.CanDelete === true) {
      writer.uint32(88).bool(message.CanDelete);
    }
    if (message.CanChangeReportAssets === true) {
      writer.uint32(96).bool(message.CanChangeReportAssets);
    }
    if (message.CanChangeToothComment === true) {
      writer.uint32(168).bool(message.CanChangeToothComment);
    }
    if (message.CanChangeToothNumeration === true) {
      writer.uint32(176).bool(message.CanChangeToothNumeration);
    }
    if (message.CanChangeToothApproved === true) {
      writer.uint32(184).bool(message.CanChangeToothApproved);
    }
    if (message.CanResetTooth === true) {
      writer.uint32(192).bool(message.CanResetTooth);
    }
    if (message.CanChangeToothAssets === true) {
      writer.uint32(200).bool(message.CanChangeToothAssets);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanView = reader.bool();
          break;
        case 2:
          message.CanChangeROI = reader.bool();
          break;
        case 4:
          message.CanChangeConditions = reader.bool();
          break;
        case 5:
          message.CanInteractWithImages = reader.bool();
          break;
        case 6:
          message.CanSign = reader.bool();
          break;
        case 7:
          message.CanPrint = reader.bool();
          break;
        case 9:
          message.CanChangeConclusion = reader.bool();
          break;
        case 10:
          message.CanChangeSettings = reader.bool();
          break;
        case 11:
          message.CanDelete = reader.bool();
          break;
        case 12:
          message.CanChangeReportAssets = reader.bool();
          break;
        case 21:
          message.CanChangeToothComment = reader.bool();
          break;
        case 22:
          message.CanChangeToothNumeration = reader.bool();
          break;
        case 23:
          message.CanChangeToothApproved = reader.bool();
          break;
        case 24:
          message.CanResetTooth = reader.bool();
          break;
        case 25:
          message.CanChangeToothAssets = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportPermissions {
    return {
      CanView: isSet(object.CanView) ? Boolean(object.CanView) : false,
      CanChangeROI: isSet(object.CanChangeROI)
        ? Boolean(object.CanChangeROI)
        : false,
      CanChangeConditions: isSet(object.CanChangeConditions)
        ? Boolean(object.CanChangeConditions)
        : false,
      CanInteractWithImages: isSet(object.CanInteractWithImages)
        ? Boolean(object.CanInteractWithImages)
        : false,
      CanSign: isSet(object.CanSign) ? Boolean(object.CanSign) : false,
      CanPrint: isSet(object.CanPrint) ? Boolean(object.CanPrint) : false,
      CanChangeConclusion: isSet(object.CanChangeConclusion)
        ? Boolean(object.CanChangeConclusion)
        : false,
      CanChangeSettings: isSet(object.CanChangeSettings)
        ? Boolean(object.CanChangeSettings)
        : false,
      CanDelete: isSet(object.CanDelete) ? Boolean(object.CanDelete) : false,
      CanChangeReportAssets: isSet(object.CanChangeReportAssets)
        ? Boolean(object.CanChangeReportAssets)
        : false,
      CanChangeToothComment: isSet(object.CanChangeToothComment)
        ? Boolean(object.CanChangeToothComment)
        : false,
      CanChangeToothNumeration: isSet(object.CanChangeToothNumeration)
        ? Boolean(object.CanChangeToothNumeration)
        : false,
      CanChangeToothApproved: isSet(object.CanChangeToothApproved)
        ? Boolean(object.CanChangeToothApproved)
        : false,
      CanResetTooth: isSet(object.CanResetTooth)
        ? Boolean(object.CanResetTooth)
        : false,
      CanChangeToothAssets: isSet(object.CanChangeToothAssets)
        ? Boolean(object.CanChangeToothAssets)
        : false,
    };
  },

  toJSON(message: ReportPermissions): unknown {
    const obj: any = {};
    message.CanView !== undefined && (obj.CanView = message.CanView);
    message.CanChangeROI !== undefined &&
      (obj.CanChangeROI = message.CanChangeROI);
    message.CanChangeConditions !== undefined &&
      (obj.CanChangeConditions = message.CanChangeConditions);
    message.CanInteractWithImages !== undefined &&
      (obj.CanInteractWithImages = message.CanInteractWithImages);
    message.CanSign !== undefined && (obj.CanSign = message.CanSign);
    message.CanPrint !== undefined && (obj.CanPrint = message.CanPrint);
    message.CanChangeConclusion !== undefined &&
      (obj.CanChangeConclusion = message.CanChangeConclusion);
    message.CanChangeSettings !== undefined &&
      (obj.CanChangeSettings = message.CanChangeSettings);
    message.CanDelete !== undefined && (obj.CanDelete = message.CanDelete);
    message.CanChangeReportAssets !== undefined &&
      (obj.CanChangeReportAssets = message.CanChangeReportAssets);
    message.CanChangeToothComment !== undefined &&
      (obj.CanChangeToothComment = message.CanChangeToothComment);
    message.CanChangeToothNumeration !== undefined &&
      (obj.CanChangeToothNumeration = message.CanChangeToothNumeration);
    message.CanChangeToothApproved !== undefined &&
      (obj.CanChangeToothApproved = message.CanChangeToothApproved);
    message.CanResetTooth !== undefined &&
      (obj.CanResetTooth = message.CanResetTooth);
    message.CanChangeToothAssets !== undefined &&
      (obj.CanChangeToothAssets = message.CanChangeToothAssets);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportPermissions>, I>>(
    object: I,
  ): ReportPermissions {
    const message = createBaseReportPermissions();
    message.CanView = object.CanView ?? false;
    message.CanChangeROI = object.CanChangeROI ?? false;
    message.CanChangeConditions = object.CanChangeConditions ?? false;
    message.CanInteractWithImages = object.CanInteractWithImages ?? false;
    message.CanSign = object.CanSign ?? false;
    message.CanPrint = object.CanPrint ?? false;
    message.CanChangeConclusion = object.CanChangeConclusion ?? false;
    message.CanChangeSettings = object.CanChangeSettings ?? false;
    message.CanDelete = object.CanDelete ?? false;
    message.CanChangeReportAssets = object.CanChangeReportAssets ?? false;
    message.CanChangeToothComment = object.CanChangeToothComment ?? false;
    message.CanChangeToothNumeration = object.CanChangeToothNumeration ?? false;
    message.CanChangeToothApproved = object.CanChangeToothApproved ?? false;
    message.CanResetTooth = object.CanResetTooth ?? false;
    message.CanChangeToothAssets = object.CanChangeToothAssets ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

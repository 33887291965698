import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Icon, IconNames, Tooltip } from '@/shared/ui';

import { MPRInputMode } from 'graphics';

import { MPR_CONTROL_TOOLS } from '../../config/constants';
import { invoiceStatusDefaultMessages } from '../../config/i18n';

import styles from './MPRControlTools.module.scss';

type MPRControlsProps = {
  className?: string;
  activeTool: MPRInputMode | 'reset' | 'default';
  setActiveTool: (control: MPRInputMode | 'reset') => void;
  controls?: MPRInputMode[];
};

const defaultControls: Array<MPRInputMode | 'reset'> = [
  'normal',
  'brightness',
  'pan',
  'zoom',
  'depth',
  'reset',
];

export const MPRControlTools: FC<MPRControlsProps> = (props) => {
  const {
    className,
    controls = defaultControls,
    activeTool,
    setActiveTool,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <div className={cn(styles.container, className)}>
      {controls.map((control) => (
        <Tooltip.Primary
          key={control}
          content={formatMessage(invoiceStatusDefaultMessages[control])}
          side="bottom"
        >
          <Icon
            className={cn(
              styles.controlIcon,
              activeTool === control && styles.active,
            )}
            name={MPR_CONTROL_TOOLS[control] as IconNames}
            size={25}
            onClick={() => setActiveTool(control)}
          />
        </Tooltip.Primary>
      ))}
    </div>
  );
};

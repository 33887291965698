import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { FileWithPath } from 'react-dropzone';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  getStudySelectStyles,
  Modal,
  Select,
  UploadImageInput,
} from '@/shared/ui';
import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import { RequestReportReq } from '@/shared/api/protocol_gen/api/core/svc_report';

import { ModalID, modalModel } from '@/entities/modal';
import { reportsModel } from '@/entities/reports';
import { useGetOptionsFromStudies } from '@/entities/study';

import { useUploadAssetContext } from '@/features/uploadAsset';

import {
  OrderImplantOrOrthoModelFormPayload,
  orderImplantOrOrthoModelFormSchema,
} from '../config/formSchema';

import styles from './OrderImplantOrOrthoModelModal.module.scss';

export type OrderImplantOrOrthoModelModalProps = {
  patientID: string;
  cbctStudies: Study[];
  iosStudies: Study[];
};

// TODO: Delete after completely move to the new patient profile page
export const OrderImplantOrOrthoModelModal_DEPRECATED: FC<
  OrderImplantOrOrthoModelModalProps
> = ({ cbctStudies, iosStudies, patientID }) => {
  const [photo, setPhoto] = useState<FileWithPath>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useAppDispatch();
  const {
    visible,
    data: { title, type: modelType },
  } = useAppSelector((state) => state.modal.OrderOrthoORImplantModal);

  const { formatMessage } = useIntl();

  const { startUploadAsset } = useUploadAssetContext();

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.OrderOrthoORImplantModal));
  }, [dispatch]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<OrderImplantOrOrthoModelFormPayload>({
    resolver: yupResolver(orderImplantOrOrthoModelFormSchema),
  });

  const cbctOptions = useGetOptionsFromStudies(cbctStudies);
  const iosOptions = useGetOptionsFromStudies(iosStudies);

  const handelChangePhoto = (image: File) => {
    setPhoto(image);
  };

  const requestReport = async (
    cbctID: string,
    iosID?: string,
    dentalPhotoStudyID?: string,
  ) => {
    if (modelType) {
      await dispatch(
        reportsModel.thunks.requestReport({
          Request: {
            [modelType]: {
              CBCTStudyID: cbctID,
              IOSMeshesStudyID: iosID,
              DentalPhotoStudyID: dentalPhotoStudyID,
            },
          },
        } as unknown as RequestReportReq),
      );
    }
  };

  // Set default
  useEffect(() => {
    if (cbctOptions.length) {
      reset({ cbct: cbctOptions[0].value });
    }
  }, [reset, cbctStudies]);

  const onSubmit: SubmitHandler<OrderImplantOrOrthoModelFormPayload> = async (
    data,
  ) => {
    setIsSubmitting(true);

    const { cbct, ios } = data;

    try {
      if (photo) {
        const { studyID: dentalPhotoStudyID } = await startUploadAsset({
          assetType: AssetType.AssetType_Study_DentalPhoto,
          files: [photo],
          patientID,
        });

        await requestReport(cbct, ios, dentalPhotoStudyID);
      } else {
        await requestReport(cbct, ios);
      }
      handleClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      borderless
      okButtonProps={{
        type: 'submit',
        form: 'orderModel',
        loading: isSubmitting,
        disabled: !isValid,
      }}
      okButtonText={formatMessage({
        id: 'global.order',
        defaultMessage: 'Order',
      })}
    >
      <form id="orderModel" onSubmit={handleSubmit(onSubmit)}>
        <p className="p2 bold">
          <FormattedMessage
            id="orderModel.step1"
            defaultMessage="Step 1: Select CBCT"
          />
        </p>

        <Controller
          control={control}
          name="cbct"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Select
              styles={getStudySelectStyles()}
              options={cbctOptions}
              isRequired
              ref={ref}
              value={`${value}`}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <p className="p2 bold">
          <FormattedMessage
            id="orderModel.step2"
            defaultMessage="Step 2: Select IOS you want to combine with CBCT study"
          />
        </p>

        <Controller
          control={control}
          name="ios"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Select
              label={formatMessage({
                id: 'orderModel.selectStudyWithUpperAndLowerJaws',
                defaultMessage: 'Select study with upper and lower jaws',
              })}
              styles={getStudySelectStyles()}
              options={iosOptions}
              ref={ref}
              value={`${value}`}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <p className="p2 bold">
          <FormattedMessage
            id="orderModel.step3DentalPhoto"
            defaultMessage="Step 3: Upload dental photo"
          />
        </p>

        <div className={styles.dragDescription}>
          <UploadImageInput
            className={cn(styles.uploadImage, photo && styles.hasImage)}
            image={photo as FileWithPath}
            onChange={handelChangePhoto}
            dropDescription={
              <p className={styles.dragLabel}>
                <FormattedMessage
                  id="orderModel.UploadDentalPhoto"
                  defaultMessage="Upload dental photo"
                />
              </p>
            }
          />
        </div>
      </form>
    </Modal>
  );
};

import { defineMessages } from 'react-intl';

import { CompanyInfoTitles, TabValue } from './constants';

export const companyInfoTitles = defineMessages<CompanyInfoTitles>({
  [CompanyInfoTitles.City]: {
    id: 'global.city',
    defaultMessage: 'City',
  },
  [CompanyInfoTitles.Address]: {
    id: 'global.address',
    defaultMessage: 'Address',
  },
  [CompanyInfoTitles.Phones]: {
    id: 'companyInfo.phone',
    defaultMessage: 'Phone',
  },
  [CompanyInfoTitles.WebSite]: {
    id: 'companyInfo.Website',
    defaultMessage: 'Website',
  },
  [CompanyInfoTitles.Country]: {
    id: 'CompanyInfo.Country',
    defaultMessage: 'Country',
  },
  [CompanyInfoTitles.Emails]: {
    id: 'companyInfo.workEmail',
    defaultMessage: 'Work Email',
  },
  [CompanyInfoTitles.ZipCode]: {
    id: 'global.zipCode',
    defaultMessage: 'ZIP code',
  },
  [CompanyInfoTitles.Region]: {
    id: 'global.region',
    defaultMessage: 'Region',
  },
});

export const companySettingsTabLabels = defineMessages<TabValue>({
  [TabValue.CompanyInfo]: {
    id: 'companySettings.tab.clinicInfo',
    defaultMessage: 'Clinic info',
  },
  [TabValue.Staff]: {
    id: 'companySettings.tab.team',
    defaultMessage: 'Team',
  },
  [TabValue.BillingInfo]: {
    id: 'companySettings.tab.billingInfo',
    defaultMessage: 'Billing info',
  },
  [TabValue.SubscriptionPlans]: {
    id: 'companySettings.tab.subscriptionPlans',
    defaultMessage: 'Subscription plans',
  },
});

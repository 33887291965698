import { FC } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';

import { toothChartModel } from '@/features/toothChart';

import styles from './ToothItemStripes.module.scss';

type ToothItemStripesProps = {
  toothID?: string;
  isLowerJaw?: boolean;
  blackAndWhite?: boolean;
};

export const ToothItemStripes: FC<ToothItemStripesProps> = (props) => {
  const { toothID, isLowerJaw, blackAndWhite } = props;

  const [isSevere, isModerate, isMild] = useAppSelector(
    toothChartModel.selectors.selectPBLSeverities(toothID as string),
  );

  return (
    <div
      className={cn(
        styles.stripes,
        isMild && styles.mild,
        isModerate && styles.moderate,
        isSevere && styles.severe,
        blackAndWhite && styles.blackAndWhite,
        isLowerJaw ? styles.lowerJaw : styles.upperJaw,
      )}
    >
      <span className={styles.stripe} />
      <span className={styles.stripe} />
      <span className={styles.stripe} />
    </div>
  );
};

import { FC, ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { TooltipContentProps } from '@radix-ui/react-tooltip';
import cn from 'classnames';

import styles from './ThirdTooltip.module.scss';

type ThirdTooltipProps = {
  content: ReactNode;
  children: ReactNode;
  alignOffset?: number;
  delayDuration?: number;
  side?: TooltipContentProps['side'];
  align?: TooltipContentProps['align'];
  className?: string;
  testID?: string;
};

export const ThirdTooltip: FC<ThirdTooltipProps> = (props) => {
  const {
    content,
    children,
    delayDuration = 0,
    alignOffset = 0,
    side = 'top',
    align = 'center',
    className,
    testID,
  } = props;

  return (
    <RadixTooltip.Root delayDuration={delayDuration} disableHoverableContent>
      <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={cn(styles.content, 'p3', className)}
          data-testid={testID}
          side={side}
          align={align}
          alignOffset={alignOffset}
        >
          {content}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

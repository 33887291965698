// @ts-nocheck
import { ContextRequest, ProcessingUnitContext } from 'graphics';
import { useState } from 'react';
import { AbsoluteBlock } from '../AbsoluteBlock';
import { Annotation } from '../AnnotationsLayer/Annotation';
import { AnnotationsLayer } from '../AnnotationsLayer/AnnotationsLayer';
import { RCImage } from '../RCImage/RCImage';
import { ObjectFitContain } from '../ObjectFitContain';
import { RCContainer, RCContainerStyle } from '../RCContainer/RCContainer';
import { TouchableLayer } from '../TouchableLayer/TouchableLayer';
import { AnnotationOrEraser } from './DicomImageWithAnnotations';
import { PolygonMask, RCPolygonMask } from '../RCPolygonMask/RCPolygonMask';

export type UltimateMedicalImageMode =
  | 'view'
  | 'annotate'
  | 'sharpening'
  | 'windowing';

export type UltimateMedicalImageProps = {
  src: ContextRequest;
  ww: number;
  wc: number;
  sharpness: number;
  inverted: boolean;
  mode: UltimateMedicalImageMode;
  annotations: Annotation[];
  masks?: PolygonMask[];
  mask?: any; // stub
  currentAnnotation: AnnotationOrEraser | null;
  onAnnotationsChanged?: (newAnnotations: Annotation[]) => void;
  onSharpnessChanged?: (currentSharpness: number) => void;
  onWWWCChanged?: (currentWWWC: {
    ww: number;
    wc: number;
  }) => void;
  onLoaded: React.Dispatch<ProcessingUnitContext>;
  className?: string;
  objectFit?: 'cover' | 'contain' | 'fill';
  style?: RCContainerStyle;
};

export const UltimateMedicalImage = ({
  src,
  mode,
  annotations,
  currentAnnotation,
  onAnnotationsChanged,
  onWWWCChanged,
  onSharpnessChanged,
  onLoaded,
  ww,
  wc,
  masks,
  sharpness,
  inverted,
  className,
  objectFit = 'cover',
  style,
}: UltimateMedicalImageProps) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [pixelSpacing, setPixelSpacing] = useState<
    [number, number] | undefined
  >([1, 1]);

  const isRastr = src.kind === 'raster';

  const wwwcMultiplier = isRastr ? 1 : 10;

  return (
    <RCContainer className={className} style={style}>
      <AbsoluteBlock data-id="MedicalImage">
        <RCImage
          src={src}
          uniforms={{ ww, wc, sharpness, invert: inverted }}
          style={{
            width: '100%',
            height: '100%',
            objectFit,
          }}
          onLoaded={(context) => {
            onLoaded(context);
            setImageSize({ width: context.width, height: context.height });
            setPixelSpacing(context.pixelSpacing);
          }}
        />
      </AbsoluteBlock>
      {masks && (
        <AbsoluteBlock data-id="MedicalImage Mask">
          <ObjectFitContain
            objectWidth={imageSize.width}
            objectHeight={imageSize.height}
          >
            <RCPolygonMask masks={masks} />
          </ObjectFitContain >
        </AbsoluteBlock >
      )}
      <AbsoluteBlock data-id="Annotations">
        <ObjectFitContain
          objectWidth={imageSize.width}
          objectHeight={imageSize.height}
        >
          <AnnotationsLayer
            pixelSpacing={pixelSpacing}
            annotations={annotations}
            annotationKind={currentAnnotation}
            setAnnotations={onAnnotationsChanged}
            editable={mode === 'annotate'}
          />
        </ObjectFitContain>
      </AbsoluteBlock>
      {
        mode === 'windowing' && (
          <>
            <AbsoluteBlock data-id="TouchableLayer Windowing">
              <ObjectFitContain
                objectWidth={imageSize.width}
                objectHeight={imageSize.height}
              >
                <TouchableLayer
                  onDrag={(x1, y1, x2, y2, dx: number, dy: number) => {
                    onWWWCChanged((state) => ({
                      ww: state.ww + dx * wwwcMultiplier,
                      wc: state.wc + dy * wwwcMultiplier,
                    }));
                  }}
                />
              </ObjectFitContain>
            </AbsoluteBlock>
          </>
        )
      }
      {
        mode === 'sharpening' && (
          <>
            <AbsoluteBlock data-id="TouchableLayer Sharpening">
              <ObjectFitContain
                objectWidth={imageSize.width}
                objectHeight={imageSize.height}
              >
                <TouchableLayer
                  onDrag={(x1, y1, x2, y2, dx: number, dy: number) => {
                    onSharpnessChanged((state) => state + dx * 0.01);
                  }}
                />
              </ObjectFitContain>
            </AbsoluteBlock>
          </>
        )
      }
    </RCContainer >
  );
};

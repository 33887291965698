/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { BrowserHeaders } from 'browser-headers';
import { OrganizationOnboardingQuestionnaireV1 } from '../../api/marketing/dto_questionnaire';

export const protobufPackage = 'com.diagnocat.marketing';

export interface OrganizationOnboardingQuestionnaireReqV1 {
  OrganizationID: string;
}

export interface OrganizationOnboardingQuestionnaireRespV1 {
  Questionnaire: OrganizationOnboardingQuestionnaireV1 | undefined;
}

export interface PutOrganizationOnboardingQuestionnaireReqV1 {
  OrganizationID: string;
  Questionnaire: OrganizationOnboardingQuestionnaireV1 | undefined;
}

export interface PutOrganizationOnboardingQuestionnaireRespV1 {
  Questionnaire: OrganizationOnboardingQuestionnaireV1 | undefined;
}

function createBaseOrganizationOnboardingQuestionnaireReqV1(): OrganizationOnboardingQuestionnaireReqV1 {
  return { OrganizationID: '' };
}

export const OrganizationOnboardingQuestionnaireReqV1 = {
  encode(
    message: OrganizationOnboardingQuestionnaireReqV1,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationOnboardingQuestionnaireReqV1 {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationOnboardingQuestionnaireReqV1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationOnboardingQuestionnaireReqV1 {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
    };
  },

  toJSON(message: OrganizationOnboardingQuestionnaireReqV1): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<OrganizationOnboardingQuestionnaireReqV1>, I>,
  >(object: I): OrganizationOnboardingQuestionnaireReqV1 {
    const message = createBaseOrganizationOnboardingQuestionnaireReqV1();
    message.OrganizationID = object.OrganizationID ?? '';
    return message;
  },
};

function createBaseOrganizationOnboardingQuestionnaireRespV1(): OrganizationOnboardingQuestionnaireRespV1 {
  return { Questionnaire: undefined };
}

export const OrganizationOnboardingQuestionnaireRespV1 = {
  encode(
    message: OrganizationOnboardingQuestionnaireRespV1,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Questionnaire !== undefined) {
      OrganizationOnboardingQuestionnaireV1.encode(
        message.Questionnaire,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationOnboardingQuestionnaireRespV1 {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationOnboardingQuestionnaireRespV1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Questionnaire = OrganizationOnboardingQuestionnaireV1.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationOnboardingQuestionnaireRespV1 {
    return {
      Questionnaire: isSet(object.Questionnaire)
        ? OrganizationOnboardingQuestionnaireV1.fromJSON(object.Questionnaire)
        : undefined,
    };
  },

  toJSON(message: OrganizationOnboardingQuestionnaireRespV1): unknown {
    const obj: any = {};
    message.Questionnaire !== undefined &&
      (obj.Questionnaire = message.Questionnaire
        ? OrganizationOnboardingQuestionnaireV1.toJSON(message.Questionnaire)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<OrganizationOnboardingQuestionnaireRespV1>, I>,
  >(object: I): OrganizationOnboardingQuestionnaireRespV1 {
    const message = createBaseOrganizationOnboardingQuestionnaireRespV1();
    message.Questionnaire =
      object.Questionnaire !== undefined && object.Questionnaire !== null
        ? OrganizationOnboardingQuestionnaireV1.fromPartial(
            object.Questionnaire,
          )
        : undefined;
    return message;
  },
};

function createBasePutOrganizationOnboardingQuestionnaireReqV1(): PutOrganizationOnboardingQuestionnaireReqV1 {
  return { OrganizationID: '', Questionnaire: undefined };
}

export const PutOrganizationOnboardingQuestionnaireReqV1 = {
  encode(
    message: PutOrganizationOnboardingQuestionnaireReqV1,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.Questionnaire !== undefined) {
      OrganizationOnboardingQuestionnaireV1.encode(
        message.Questionnaire,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PutOrganizationOnboardingQuestionnaireReqV1 {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutOrganizationOnboardingQuestionnaireReqV1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.Questionnaire = OrganizationOnboardingQuestionnaireV1.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PutOrganizationOnboardingQuestionnaireReqV1 {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Questionnaire: isSet(object.Questionnaire)
        ? OrganizationOnboardingQuestionnaireV1.fromJSON(object.Questionnaire)
        : undefined,
    };
  },

  toJSON(message: PutOrganizationOnboardingQuestionnaireReqV1): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Questionnaire !== undefined &&
      (obj.Questionnaire = message.Questionnaire
        ? OrganizationOnboardingQuestionnaireV1.toJSON(message.Questionnaire)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PutOrganizationOnboardingQuestionnaireReqV1>,
      I
    >,
  >(object: I): PutOrganizationOnboardingQuestionnaireReqV1 {
    const message = createBasePutOrganizationOnboardingQuestionnaireReqV1();
    message.OrganizationID = object.OrganizationID ?? '';
    message.Questionnaire =
      object.Questionnaire !== undefined && object.Questionnaire !== null
        ? OrganizationOnboardingQuestionnaireV1.fromPartial(
            object.Questionnaire,
          )
        : undefined;
    return message;
  },
};

function createBasePutOrganizationOnboardingQuestionnaireRespV1(): PutOrganizationOnboardingQuestionnaireRespV1 {
  return { Questionnaire: undefined };
}

export const PutOrganizationOnboardingQuestionnaireRespV1 = {
  encode(
    message: PutOrganizationOnboardingQuestionnaireRespV1,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Questionnaire !== undefined) {
      OrganizationOnboardingQuestionnaireV1.encode(
        message.Questionnaire,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PutOrganizationOnboardingQuestionnaireRespV1 {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutOrganizationOnboardingQuestionnaireRespV1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Questionnaire = OrganizationOnboardingQuestionnaireV1.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PutOrganizationOnboardingQuestionnaireRespV1 {
    return {
      Questionnaire: isSet(object.Questionnaire)
        ? OrganizationOnboardingQuestionnaireV1.fromJSON(object.Questionnaire)
        : undefined,
    };
  },

  toJSON(message: PutOrganizationOnboardingQuestionnaireRespV1): unknown {
    const obj: any = {};
    message.Questionnaire !== undefined &&
      (obj.Questionnaire = message.Questionnaire
        ? OrganizationOnboardingQuestionnaireV1.toJSON(message.Questionnaire)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PutOrganizationOnboardingQuestionnaireRespV1>,
      I
    >,
  >(object: I): PutOrganizationOnboardingQuestionnaireRespV1 {
    const message = createBasePutOrganizationOnboardingQuestionnaireRespV1();
    message.Questionnaire =
      object.Questionnaire !== undefined && object.Questionnaire !== null
        ? OrganizationOnboardingQuestionnaireV1.fromPartial(
            object.Questionnaire,
          )
        : undefined;
    return message;
  },
};

export interface QuestionnaireService {
  GetOrganizationOnboardingQuestionnaire_V1(
    request: DeepPartial<OrganizationOnboardingQuestionnaireReqV1>,
    metadata?: grpc.Metadata,
  ): Promise<OrganizationOnboardingQuestionnaireRespV1>;
  /** Empty fields does not rewrite filled ones */
  PutOrganizationOnboardingQuestionnaire_V1(
    request: DeepPartial<PutOrganizationOnboardingQuestionnaireReqV1>,
    metadata?: grpc.Metadata,
  ): Promise<PutOrganizationOnboardingQuestionnaireRespV1>;
}

export class QuestionnaireServiceClientImpl implements QuestionnaireService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetOrganizationOnboardingQuestionnaire_V1 =
      this.GetOrganizationOnboardingQuestionnaire_V1.bind(this);
    this.PutOrganizationOnboardingQuestionnaire_V1 =
      this.PutOrganizationOnboardingQuestionnaire_V1.bind(this);
  }

  GetOrganizationOnboardingQuestionnaire_V1(
    request: DeepPartial<OrganizationOnboardingQuestionnaireReqV1>,
    metadata?: grpc.Metadata,
  ): Promise<OrganizationOnboardingQuestionnaireRespV1> {
    return this.rpc.unary(
      QuestionnaireServiceGetOrganizationOnboardingQuestionnaire_V1Desc,
      OrganizationOnboardingQuestionnaireReqV1.fromPartial(request),
      metadata,
    );
  }

  PutOrganizationOnboardingQuestionnaire_V1(
    request: DeepPartial<PutOrganizationOnboardingQuestionnaireReqV1>,
    metadata?: grpc.Metadata,
  ): Promise<PutOrganizationOnboardingQuestionnaireRespV1> {
    return this.rpc.unary(
      QuestionnaireServicePutOrganizationOnboardingQuestionnaire_V1Desc,
      PutOrganizationOnboardingQuestionnaireReqV1.fromPartial(request),
      metadata,
    );
  }
}

export const QuestionnaireServiceDesc = {
  serviceName: 'com.diagnocat.marketing.QuestionnaireService',
};

export const QuestionnaireServiceGetOrganizationOnboardingQuestionnaire_V1Desc: UnaryMethodDefinitionish =
  {
    methodName: 'GetOrganizationOnboardingQuestionnaire_V1',
    service: QuestionnaireServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return OrganizationOnboardingQuestionnaireReqV1.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...OrganizationOnboardingQuestionnaireRespV1.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const QuestionnaireServicePutOrganizationOnboardingQuestionnaire_V1Desc: UnaryMethodDefinitionish =
  {
    methodName: 'PutOrganizationOnboardingQuestionnaire_V1',
    service: QuestionnaireServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return PutOrganizationOnboardingQuestionnaireReqV1.encode(
          this,
        ).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...PutOrganizationOnboardingQuestionnaireRespV1.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

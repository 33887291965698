import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { PATHS } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';
import { patientModel } from '@/entities/patient';

type PatientRemovalConfirmationModalProps = {
  className?: string;
  testID?: string;
};

export const PatientRemovalConfirmationModal: FC<
  PatientRemovalConfirmationModalProps
> = (props) => {
  const { className, testID } = props;
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const { visible, data } = useAppSelector(
    modalModel.selectors.selectPatientRemovalConfirmationModal,
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleRemovePatient = async () => {
    try {
      setIsDeleting(true);

      const removePatientResponse = await dispatch(
        patientModel.thunks.deletePatient({ PatientID: data.patientID }),
      ).unwrap();

      if (removePatientResponse.DeletedPatientID) {
        dispatch(
          patientModel.actions.removeOne(
            removePatientResponse.DeletedPatientID,
          ),
        );

        dispatch(modalModel.actions.closeModal(ModalID.PatientModalForm));
        dispatch(
          modalModel.actions.closeModal(
            ModalID.PatientRemovalConfirmationModal,
          ),
        );

        navigate(PATHS.main);
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const onClose = () => {
    dispatch(
      modalModel.actions.closeModal(ModalID.PatientRemovalConfirmationModal),
    );
  };

  return (
    <Modal
      data-testid={testID}
      className={className}
      isOpen={visible}
      title={
        <FormattedMessage
          id="patientRemovalConfirmationModal.title"
          defaultMessage="Delete patient"
        />
      }
      onOk={handleRemovePatient}
      onCancel={onClose}
      okButtonText={formatMessage({
        id: 'global.delete',
        defaultMessage: 'Delete',
      })}
      okButtonProps={{
        danger: true,
        loading: isDeleting,
      }}
      cancelButtonProps={{
        disabled: isDeleting,
      }}
    >
      <FormattedMessage
        id="patientRemovalConfirmationModal.text"
        defaultMessage="Are you sure you want to delete this patient?"
      />
    </Modal>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';
import { ServiceType } from '@/shared/api/protocol_gen/api/billing_new/dto_services_new';

import { studyModel } from '@/entities/study';
import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';
import { billingModel } from '@/entities/billing';

import {
  distributeReportsAndStudies,
  filterOutSegmentronReportsIfInactive,
  getGroupedReportsBySourceStudyIDs,
  getGroupedReportsBySourceStudyIDsAndType,
  getGroupedReportsWithStudies,
  getSortedStructuredReportsAndStudies,
  getStructuredReportsAndStudies,
} from '../lib';

export const selectStructuredReportsAndStudiesByPatientID = (
  patientID: string,
) =>
  createSelector(
    studyModel.selectors.selectNotDeletedStudiesByPatientID(patientID),
    reportsModel.selectors.selectNotDeletedReportsByPatientID(patientID),
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Show_Order3DReports,
    ),
    billingModel.selectors.selectActiveServicesList,
    (studies, reports, showOrder3DReportsWidget, services) => {
      const isSegmentronActive =
        showOrder3DReportsWidget &&
        !!(
          services[ServiceType.ServiceType_Order_ImplantStudio_Analysis] ||
          services[ServiceType.ServiceType_Order_OrthoStudio_Analysis] ||
          services[
            ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis
          ] ||
          services[ServiceType.ServiceType_Order_IOS_Segmentation_Analysis] ||
          services[ServiceType.ServiceType_Order_CBCT_STL_Analysis]
        );

      const notDeletedFilteredReports = filterOutSegmentronReportsIfInactive(
        reports,
        isSegmentronActive,
      );

      const groupedReportsBySourceStudyIDs = getGroupedReportsBySourceStudyIDs(
        notDeletedFilteredReports,
      );

      const groupedReportsBySourceStudyIDsAndType =
        getGroupedReportsBySourceStudyIDsAndType(
          groupedReportsBySourceStudyIDs,
        );

      const groupedReportsWithStudies = getGroupedReportsWithStudies(
        groupedReportsBySourceStudyIDsAndType,
        studies,
      );

      const structuredReportsAndStudies = getStructuredReportsAndStudies(
        groupedReportsWithStudies,
      );

      const sortedStructuredReportsAndStudies =
        getSortedStructuredReportsAndStudies(structuredReportsAndStudies);

      const [leftColumn, rightColumn] = distributeReportsAndStudies(
        sortedStructuredReportsAndStudies,
      );

      return { leftColumn, rightColumn };
    },
  );

import { useEffect, useState } from 'react';

export function useDebouncedFunction<T, K>(
  callback: (...args: T[]) => K,
  delay: number,
) {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  function delayedCallback(...args: T[]) {
    clearTimeout(timeoutId);
    const id = setTimeout(() => callback(...(args as T[])), delay);
    setTimeoutId(id);
  }

  return delayedCallback;
}

import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';
import {
  ReportPrintSettings,
  ReportType,
} from '@/shared/api/protocol_gen/model/dto_report';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { ToothChartLegends, toothModel } from '@/entities/tooth';
import { getReportSignStatus, i18n } from '@/entities/reports';
import { useFormatPatientDateOfBirth } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';
import { conditionModel } from '@/entities/condition';
import { logicalConditionModel } from '@/entities/logicalCondition';

import { PreviewConclusion } from '@/widgets/Conclusion/ui/Conclusion/Conclusion';
import { MedicalImagesGroup } from '@/widgets/MedicalImageGroup';
import { RarePathologies } from '@/widgets/NonDentalFindings';
import { PanowingsReportRender } from '@/widgets/PanowingsReportRender';
import { IOXRayReportRender } from '@/widgets/IOXRayReportRender';

import { PanoReformatRender } from '@/pages/GpReport/ui/PanoReformatRender/PanoReformatRender';
import { PanoReportRender } from '@/pages/PanoReport/ui/PanoReportRender/PanoReportRender';

import { FooterDate } from '../FooterDate/FooterDate';
import { PreviewToothCard } from '../PreviewToothCard/PreviewToothCard';
import { PreviewFooter } from '../PreviewFooter/PreviewFooter';
import { PreviewHeader } from '../PreviewHeader/PreviewHeader';
import { usePreviewDocument } from '../../hooks/usePreviewDocument';
import { previewFooterDisclaimer } from '../../config/i18n';
import { Signature } from '../Signature/Signature';
import { PreviewScanInfo } from '../PreviewScanInfo/PreviewScanInfo';
import { PreviewToothChart } from '../PreviewToothChart/PreviewToothChart';

import styles from './PreviewDocument.module.scss';

type PreviewDocumentType = {
  settings: ReportPrintSettings;
  reportID: string;
  patientID: string;
  showLowProbability: boolean;
  organizationID: string;
};

export const PreviewDocument: FC<PreviewDocumentType> = (props) => {
  const {
    showLowProbability,
    settings: {
      IsInverted,
      BlackAndWhiteMode,
      ShowBottomJaw,
      ShowTopJaw,
      ShowSlices,
      ShowDentalChart,
      ShowDiagnosesProbabilities,
      ShowDiagnoses,
      ShowBBoxes,
      ShowStudyMetadata,
    },
    reportID,
    patientID,
    organizationID,
  } = props;

  const maxFaxConditions = useAppSelector(
    conditionModel.selectors.selectMaxFaxConditionsByReportID(reportID),
  );

  const logicalConditionLoading = useAppSelector(
    logicalConditionModel.selectors.selectLoading,
  );
  const toothLoading = useAppSelector(toothModel.selectors.selectLoading);
  const showToothChart =
    logicalConditionLoading === 'succeeded' && toothLoading === 'succeeded';

  const { formatMessage, formatDate } = useIntl();

  const {
    teeth = [],
    patient,
    report,
    CBCTPanoImage,
    patientFullName,
    endodonticGroups,
    studyMeta,
    isLoading,
  } = usePreviewDocument({
    reportID,
    patientID,
    showBottomJaw: ShowBottomJaw,
    showTopJaw: ShowTopJaw,
  });

  const organization = useAppSelector((state) =>
    organizationModel.selectors.organizationSelectors.selectById(
      state,
      organizationID,
    ),
  );

  const showNonDentalFindings = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Show_NonDentalFindings,
    ),
  );

  const patientDateOfBirth = patient?.PersonalData?.DateOfBirth;

  const patientDoB = useFormatPatientDateOfBirth({ patientDateOfBirth });

  const isReportSigned = report?.Signature?.Signed;

  const allUserSignatures = report?.Signature?.UserSignatures;

  if (isLoading) {
    return null;
  }

  // Get view data after patient and report data is fetched
  const reportCreatedDate = formatDate(report?.Created?.At ?? 0, {
    dateStyle: 'medium',
    timeStyle: 'short',
  });

  const getReportSignedDate = (date: Date | undefined) =>
    formatDate(date ?? 0, {
      dateStyle: 'medium',
      timeStyle: 'short',
    });

  const reportTitle = report?.Type ? formatMessage(i18n[report.Type]) : '';
  const reportSignStatus = getReportSignStatus(report);

  const descriptionLabel = isReportSigned
    ? formatMessage({
        id: 'previewDocument.reportSigned',
        defaultMessage: 'Report signed:',
      })
    : formatMessage({
        id: 'previewDocument.reportCreated',
        defaultMessage: 'Report created:',
      });

  const previewSettings = {
    isPreview: true,
    isInverted: IsInverted,
    isBW: BlackAndWhiteMode,
  };

  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td>
            <div className={styles.document}>
              <PreviewHeader
                title={reportTitle}
                date={reportCreatedDate}
                patientName={patientFullName}
                patientDateOfBirth={patientDoB}
                blackAndWhiteMode={BlackAndWhiteMode}
                organization={organization}
              />

              <div className={styles.panoContainer}>
                {!isEmpty(CBCTPanoImage) && (
                  <PanoReformatRender previewSettings={previewSettings} />
                )}

                {report?.Type === ReportType.ReportType_IOXRay_GP && (
                  <IOXRayReportRender previewSettings={previewSettings} />
                )}

                {report?.Type === ReportType.ReportType_Pano_Bitewings && (
                  <PanowingsReportRender
                    className={styles.IOXRayMatrixWrapper}
                    previewSettings={previewSettings}
                  />
                )}

                {report?.Type === ReportType.ReportType_Pano_GP && (
                  <PanoReportRender previewSettings={previewSettings} />
                )}
              </div>

              {ShowDentalChart &&
                report?.Type !== ReportType.ReportType_CBCT_Endo && (
                  <>
                    {showToothChart && <PreviewToothChart />}

                    <ToothChartLegends
                      blackAndWhiteMode={BlackAndWhiteMode}
                      showLowProbability={showLowProbability}
                    />
                  </>
                )}

              {showNonDentalFindings &&
                ShowBBoxes &&
                !!maxFaxConditions.length && (
                  <div className={styles.dontBreakInside}>
                    <h4 className="h4">
                      <FormattedMessage
                        id="report.rarePathology.header"
                        defaultMessage="Non-dental findings"
                      />
                    </h4>
                    <RarePathologies
                      className={styles.rarePathologies}
                      conditions={maxFaxConditions}
                    />
                  </div>
                )}

              {!!teeth.length && (
                <div className={styles.teethContainer}>
                  {teeth.map((tooth) => (
                    <PreviewToothCard
                      key={tooth.ID}
                      tooth={tooth}
                      showProbability={ShowDiagnosesProbabilities}
                      showConditions={ShowDiagnoses}
                      showMedicalImages={ShowSlices}
                      inverted={IsInverted}
                      blackAndWhiteMode={BlackAndWhiteMode}
                      report={report}
                    />
                  ))}
                </div>
              )}

              {report?.Type === ReportType.ReportType_CBCT_Endo &&
                !!endodonticGroups.length && (
                  <MedicalImagesGroup groups={endodonticGroups} />
                )}

              {!!report?.LegacyConclusion && report?.Signature?.Signed && (
                <PreviewConclusion />
              )}

              <div
                className={cn(styles.signatureAndDate, {
                  [styles.blackSignatureAndDate]: BlackAndWhiteMode,
                })}
              >
                {isReportSigned ? (
                  allUserSignatures &&
                  allUserSignatures.map((signature) => (
                    <>
                      <Signature signature={signature} key={signature.UserID} />

                      <FooterDate
                        date={getReportSignedDate(signature.SignedAt)}
                        label={descriptionLabel}
                        blackAndWhiteMode={BlackAndWhiteMode}
                      />
                    </>
                  ))
                ) : (
                  <FooterDate
                    date={reportCreatedDate}
                    label={descriptionLabel}
                    blackAndWhiteMode={BlackAndWhiteMode}
                  />
                )}
              </div>

              {ShowStudyMetadata && (
                <PreviewScanInfo
                  className={styles.scanInfo}
                  studyMeta={studyMeta}
                />
              )}

              <PreviewFooter
                text={formatMessage(previewFooterDisclaimer[reportSignStatus])}
                blackAndWhiteMode={BlackAndWhiteMode}
                date={reportCreatedDate}
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className={styles.footerSpace}>&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { Button, Modal } from '@/shared/ui';
import smileCat from '@/shared/assets/images/cats/smile.png';

import { ModalID, modalModel } from '@/entities/modal';

import styles from './CongratsModal.module.scss';

export const CongratsModal = () => {
  const {
    visible,
    data: {
      redirectTo,
      title = '',
      message = '',
      buttonText = '',
      isTertiaryButton = false,
      expiredTime,
    },
  } = useAppSelector((state) => state.modal.CongratsModal);

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const { isMobile } = useMedia();
  const navigate = useNavigate();

  const [presentTime, setPresentTime] = useState<number>(0);

  const handleNavigate = () => {
    dispatch(modalModel.actions.closeModal(ModalID.CongratsModal));

    navigate(redirectTo);
  };

  const openResetPasswordErrorModal = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.ResetPasswordErrorModal,
        data: {
          errorType: 'ExpiredTime',
        },
      }),
    );
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (expiredTime) {
        const currentPresentTime = new Date().getTime();

        if (currentPresentTime >= expiredTime) {
          clearTimeout(timeOut);
          openResetPasswordErrorModal();
        } else {
          setPresentTime(currentPresentTime);
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [expiredTime, presentTime]);

  return (
    <Modal
      isOpen={visible}
      borderless
      shouldRenderCloseIconButton={false}
      hideFooter
    >
      <div className={styles.container}>
        <img
          src={smileCat}
          width={130}
          height={130}
          alt={formatMessage({
            id: 'imgAltText.smileCat',
            defaultMessage: 'Smile cat',
          })}
        />

        <h5 className={cn(styles.title, 'h5')}>
          {title || (
            <FormattedMessage
              id="congratsModal.congratsAccountIsReady"
              defaultMessage="Congrats! Your Diagnocat account is ready. "
            />
          )}
        </h5>

        {message && <p className={cn('p2', styles.message)}>{message}</p>}

        <Button
          variant={isTertiaryButton ? 'tertiary' : 'primary'}
          onClick={handleNavigate}
          size={isMobile ? 'medium' : 'large'}
        >
          {buttonText || (
            <FormattedMessage id="global.ok" defaultMessage="Ok" />
          )}
        </Button>
      </div>
    </Modal>
  );
};

import { OptionProps, StylesConfig } from 'react-select';

import { selectStyles } from '../Select.styles';

export const getStudySelectStyles = (): StylesConfig =>
  // This logic provides styles to select component middle levels
  ({
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      marginLeft: 0,
      padding: 0,
    }),
    option: (baseStyles) => {
      const selectOptionStyles = selectStyles.option
        ? selectStyles?.option(baseStyles, {
            isMulti: false,
          } as OptionProps)
        : baseStyles;

      return {
        ...selectOptionStyles,
        padding: '2px 0',
      };
    },
    placeholder: (baseStyles) => ({
      ...baseStyles,
      marginLeft: '16px',
    }),
  });

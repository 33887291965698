import React from 'react';

import api from '@/shared/api/api.ts';

export const ensureRefreshImageSrc = async (
  e: React.SyntheticEvent<HTMLImageElement, Event>,
) => {
  await api.refresher.refresh();
  await new Promise((resolve) => setTimeout(resolve, 200));

  const elem = e.currentTarget;
  const src = elem.src;
  elem.src = src;
};

import { LabelingData } from './types';

export const labelingData: LabelingData = {
  UK: {
    nameOfProduct: '',
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: {
      title: 'Authorized representative in the European Union',
      address:
        'FREYR LIFE SCIENCES LTD\n' +
        '9 Greyfriars Road, Reading, Berkshire, \n' +
        'United Kingdom RG1 1NU',
      phoneNumber: '+44 2037012379',
      email: 'CH-REP@freyrsolutions.com',
    },
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  EU: {
    nameOfProduct: 'Diagnocat AIS',
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: {
      title: 'Authorized representative in the European Union:',
      address: 'Freyr',
      phoneNumber: '',
      email: '',
    },
    lastUpdate: '',
    UDI: '860010268018',
    productVersion: '1.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  Australia: {
    nameOfProduct: 'Diagnocat AIS',
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: {
      title: 'Australian Sponsor:',
      address:
        'Freyr Australia Pty Ltd \n' +
        '46 Dora St,\n' +
        'Blacktown,\n' +
        'NSW 2148, Australia',
      phoneNumber: '',
      email: '',
    },
    lastUpdate: '',
    UDI: '860010268018',
    productVersion: '1.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  Switzerland: {
    nameOfProduct: '',
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: {
      title: 'Authorized representative in the European Union',
      address:
        'Freyr Life Sciences GmbH,\n' +
        'Bahnhofplatz,\n' +
        'CH-6300 Zug,\n' +
        'Switzerland',
      phoneNumber: '+41 225087129',
      email: 'CH-REP@freyrsolutions.com',
    },
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  US: {
    nameOfProduct: '',
    manufacturer:
      'Diagnocat \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: '',
    marking: undefined,
  },

  Mexico: {
    nameOfProduct: '',
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  Canada: {
    nameOfProduct: 'Diagnocat AIS',
    manufacturer:
      'DGNCT LLC 333 Southeast 2nd Avenue 20th Floor – #563 Miami, FL 33131 \n' +
      'DGNCT LLC 333 Sud-Est, 2e Avenue 20ème étage#563 Miami, Floride 33131, Etats-Unis',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '860010268018',
    productVersion: '1.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  Israel: {
    nameOfProduct: '',
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  CIS: {
    nameOfProduct: '',
    manufacturer:
      'Diagnocat LLC\n' +
      '21 Palekhskaya Street, Floor/Office/Room 1/II-III/13 Yaroslavl Municipal District, Moscow Intra-City Area 129337\n' +
      'Russian Federation',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: null,
    productVersion: '2.0',
    userManual: '',
    marking: undefined,
  },
};

import React, { FC } from 'react';
import cn from 'classnames';

import styles from './Footer.module.scss';

type FooterProps = {
  settingsFooter?: boolean;
  className?: string;
  testID?: string;
  children?: React.ReactNode;
  sticky?: boolean;
};

export const Footer: FC<FooterProps> = (props) => {
  const { settingsFooter = false, className, testID, children, sticky } = props;

  return (
    <footer
      className={cn(
        styles.container,
        sticky && styles.sticky,
        settingsFooter && styles.settingsFooter,
        className,
      )}
      data-testid={testID}
    >
      {children}
    </footer>
  );
};

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

export const getPBLColor = (condition?: Condition) => {
  switch (condition?.Code) {
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild:
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate:
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe: {
      return '#E52A4D';
    }
    default:
      return '#D4D4D4';
  }
};

import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useDebounce } from './useDebounce';

export const useSearch = (
  onSearchRequest: (value: string) => void,
): [string, (newValue: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState<string>(
    () => searchParams.get('search') || '',
  );

  const debouncedSearchValue = useDebounce<string>(search);

  const updateParams = (value: string): void => {
    searchParams.set('search', value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    updateParams(debouncedSearchValue);
    onSearchRequest(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return [search, setSearch];
};

import { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';
import { maskFiltersModel } from '@/features/renderMasks';
import { convertToMIRenderInterface } from '@/features/MIRenderInterface';

import {
  MedicalImageRender,
  PBLProps,
} from '../../../../shared/graphics/medicalImageRender/MedicalImageRender';

import styles from './PanowingsReportRender.module.scss';

type PanowingsReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanowingsReportRender: FC<PanowingsReportRenderProps> = (
  props,
) => {
  const { className, children, previewSettings } = props;

  const dispatch = useAppDispatch();

  const isPreviewMode = previewSettings?.isPreview;

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const reportID = currentReport?.ID as string;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mainViewRef = useRef<HTMLDivElement>(null);

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );

  useEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef, 'main');
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  ) as IOXrayImageInterface[];

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID),
  );

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );
  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );
  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  // PBL
  const showPBL = useAppSelector(
    maskFiltersModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  //render initialization
  useEffect(() => {
    const shouldRunRender =
      !!IOXRayImagesInterface.length &&
      reportReadyForRender &&
      panoImageInterface?.imageMeta;

    if (shouldRunRender) {
      MedicalImageRender.setCredentials(CORS_POLICY);

      let matrix;
      let scale;

      let gapBetweenBitewings = 60;

      if (bitewingsImagesInterface.length) {
        matrix = [
          [[convertToMIRenderInterface(panoImageInterface)]],
          [
            [
              ...groupedIOXRayImages.MiddleLeft.map((imageItem) =>
                convertToMIRenderInterface(imageItem),
              ),
              ...groupedIOXRayImages.MiddleRight.map((imageItem) =>
                convertToMIRenderInterface(imageItem),
              ),
            ],
          ],
        ];

        const width = bitewingsImagesInterface
          .map((item) => item.originalSize.width)
          .reduce((a, b) => a + b);

        gapBetweenBitewings = Math.min(width * 0.02, gapBetweenBitewings);

        scale =
          (gapBetweenBitewings * (bitewingsImagesInterface.length - 1) +
            width) /
          panoImageInterface.originalSize.width;
      } else {
        matrix = [[[convertToMIRenderInterface(panoImageInterface)]]];
      }

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID,
        matrix,
        reportViewOptions,
        {
          [panoImageInterface.imageMeta?.GeneratedAssetID ||
          panoImageInterface.imageMeta?.StudyAssetID]: {
            viewportType: 'pano',
            scale,
          },
        },
        { contentGap: gapBetweenBitewings },
      );

      if (!isRenderStartCorrect) {
        return;
      }
      if (isPreviewMode) {
        MedicalImageRender.layoutModes.fullScreenMatrix();
        MedicalImageRender.activateMode('printMode');
      } else {
        MedicalImageRender.setTeeth(teeth);

        MedicalImageRender.addEventListener('layout', (event) => {
          if (event.mode === 'focus') {
            dispatch(reportsModel.actions.setToolbarActiveControl('view'));
            dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));
          }
        });
      }
    }
  }, [
    bitewingsImagesInterface,
    panoImageInterface,
    reportReadyForRender,
    isPreviewMode,
  ]);

  useEffect(() => {
    if ((MedicalImageRender.isRunning(), bitewingsImagesInterface.length)) {
      const matrix = [
        [[convertToMIRenderInterface(panoImageInterface)]],
        [
          [
            ...groupedIOXRayImages.MiddleLeft.map((imageItem) =>
              convertToMIRenderInterface(imageItem),
            ),
            ...groupedIOXRayImages.MiddleRight.map((imageItem) =>
              convertToMIRenderInterface(imageItem),
            ),
          ],
        ],
      ];

      const width = bitewingsImagesInterface
        .map((item) => item.originalSize.width)
        .reduce((a, b) => a + b);

      const gapBetweenBitewings = Math.min(width * 0.02, 60);

      const scale =
        (gapBetweenBitewings * (bitewingsImagesInterface.length - 1) + width) /
        panoImageInterface.originalSize.width;

      MedicalImageRender.updateLayout(
        matrix,
        { [panoImageInterface.asset.ID]: { scale } },
        { contentGap: gapBetweenBitewings },
      );
    }
  }, [groupedIOXRayImages, bitewingsImagesInterface, bitewingsImagesInterface]);

  // NOTE: PBL should be setted up just one time after render is done.
  useEffect(() => {
    if (reportPBLReadyForRender) {
      if (!isPreviewMode) {
        const PBLsData = bitewingsImagesInterface
          .map((data) => {
            const initalPBLList = getPBLsForImage(data.asset.ID);

            if (!initalPBLList) {
              return undefined;
            }

            const getPBL = (landmark: Landmark) => ({
              start: {
                x: landmark.lowPoint?.ModelPosition?.X || 0,
                y: landmark.lowPoint?.ModelPosition?.Y || 0,
              },
              end: {
                x: landmark.upPoint?.ModelPosition?.X || 0,
                y: landmark.upPoint?.ModelPosition?.Y || 0,
              },
              color: landmark.color,
              textProps: {
                color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
              },
            });

            return {
              imageID:
                data.imageMeta?.GeneratedAssetID ||
                data.imageMeta?.StudyAssetID,
              PBLs: initalPBLList.map((pbl) => {
                return getPBL(pbl);
              }),
              scale: data.imageMeta.Scale?.X || 1,
            };
          })
          .filter((data) => data) as PBLProps[];

        if (PBLsData) {
          MedicalImageRender.setPBLs(PBLsData);
          if (showPBL) {
            MedicalImageRender.showPBLs();
          }
        }
      }
    }
  }, [reportPBLReadyForRender]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportReadyForRender && MedicalImageRender.isRunning()) {
      if (!hideMasks && !hidePBLFeatureFlag && showPBL) {
        MedicalImageRender.showPBLs();
      } else {
        MedicalImageRender.hidePBLs();
      }
    }
  }, [reportReadyForRender, showPBL, hideMasks, hidePBLFeatureFlag]);

  // // Render masks
  useEffect(() => {
    if (
      MedicalImageRender.isRunning() &&
      reportReadyForRender &&
      !isPreviewMode
    ) {
      MedicalImageRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        MedicalImageRender.addMasks(masks2DRenderData);
        MedicalImageRender.showMasks(activeMaskFilters);
      }
    }
  }, [masks2DRenderData, hideMasks]);

  // BBoxes render
  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      if (hoveredBBoxes) {
        MedicalImageRender.deleteConditionBoxes();
        MedicalImageRender.addConditionBoxes(hoveredBBoxes);
      } else {
        MedicalImageRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes, reportReadyForRender]);

  return (
    <div
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
      ref={mainViewRef}
      id="report_render"
    >
      <canvas ref={canvasRef} className={styles.canvas} />
      {children}
    </div>
  );
};

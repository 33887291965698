/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export enum OrganizationJobPosition {
  OrganizationJobPositionInvalidValue = 0,
  OrganizationJobPositionChiefDentalOfficer = 1,
  OrganizationJobPositionTreatmentCoordinator = 2,
  OrganizationJobPositionDentalAssistant = 3,
  OrganizationJobPositionDentalTechnician = 4,
  OrganizationJobPositionGeneralDentist = 5,
  OrganizationJobPositionPeriodontist = 6,
  OrganizationJobPositionProsthodontist = 7,
  OrganizationJobPositionOrthodontist = 8,
  OrganizationJobPositionPediatricDentist = 9,
  OrganizationJobPositionOralAndMaxillofacialSurgeon = 10,
  OrganizationJobPositionOralAndMaxillofacialRadiologist = 11,
  OrganizationJobPositionAestheticDentist = 12,
  OrganizationJobPositionDentalHygienist = 13,
  OrganizationJobPositionOther = 14,
  OrganizationJobPositionOfficeManager = 15,
  OrganizationJobPositionEndodontist = 16,
  UNRECOGNIZED = -1,
}

export function organizationJobPositionFromJSON(
  object: any,
): OrganizationJobPosition {
  switch (object) {
    case 0:
    case 'OrganizationJobPositionInvalidValue':
      return OrganizationJobPosition.OrganizationJobPositionInvalidValue;
    case 1:
    case 'OrganizationJobPositionChiefDentalOfficer':
      return OrganizationJobPosition.OrganizationJobPositionChiefDentalOfficer;
    case 2:
    case 'OrganizationJobPositionTreatmentCoordinator':
      return OrganizationJobPosition.OrganizationJobPositionTreatmentCoordinator;
    case 3:
    case 'OrganizationJobPositionDentalAssistant':
      return OrganizationJobPosition.OrganizationJobPositionDentalAssistant;
    case 4:
    case 'OrganizationJobPositionDentalTechnician':
      return OrganizationJobPosition.OrganizationJobPositionDentalTechnician;
    case 5:
    case 'OrganizationJobPositionGeneralDentist':
      return OrganizationJobPosition.OrganizationJobPositionGeneralDentist;
    case 6:
    case 'OrganizationJobPositionPeriodontist':
      return OrganizationJobPosition.OrganizationJobPositionPeriodontist;
    case 7:
    case 'OrganizationJobPositionProsthodontist':
      return OrganizationJobPosition.OrganizationJobPositionProsthodontist;
    case 8:
    case 'OrganizationJobPositionOrthodontist':
      return OrganizationJobPosition.OrganizationJobPositionOrthodontist;
    case 9:
    case 'OrganizationJobPositionPediatricDentist':
      return OrganizationJobPosition.OrganizationJobPositionPediatricDentist;
    case 10:
    case 'OrganizationJobPositionOralAndMaxillofacialSurgeon':
      return OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialSurgeon;
    case 11:
    case 'OrganizationJobPositionOralAndMaxillofacialRadiologist':
      return OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialRadiologist;
    case 12:
    case 'OrganizationJobPositionAestheticDentist':
      return OrganizationJobPosition.OrganizationJobPositionAestheticDentist;
    case 13:
    case 'OrganizationJobPositionDentalHygienist':
      return OrganizationJobPosition.OrganizationJobPositionDentalHygienist;
    case 14:
    case 'OrganizationJobPositionOther':
      return OrganizationJobPosition.OrganizationJobPositionOther;
    case 15:
    case 'OrganizationJobPositionOfficeManager':
      return OrganizationJobPosition.OrganizationJobPositionOfficeManager;
    case 16:
    case 'OrganizationJobPositionEndodontist':
      return OrganizationJobPosition.OrganizationJobPositionEndodontist;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrganizationJobPosition.UNRECOGNIZED;
  }
}

export function organizationJobPositionToJSON(
  object: OrganizationJobPosition,
): string {
  switch (object) {
    case OrganizationJobPosition.OrganizationJobPositionInvalidValue:
      return 'OrganizationJobPositionInvalidValue';
    case OrganizationJobPosition.OrganizationJobPositionChiefDentalOfficer:
      return 'OrganizationJobPositionChiefDentalOfficer';
    case OrganizationJobPosition.OrganizationJobPositionTreatmentCoordinator:
      return 'OrganizationJobPositionTreatmentCoordinator';
    case OrganizationJobPosition.OrganizationJobPositionDentalAssistant:
      return 'OrganizationJobPositionDentalAssistant';
    case OrganizationJobPosition.OrganizationJobPositionDentalTechnician:
      return 'OrganizationJobPositionDentalTechnician';
    case OrganizationJobPosition.OrganizationJobPositionGeneralDentist:
      return 'OrganizationJobPositionGeneralDentist';
    case OrganizationJobPosition.OrganizationJobPositionPeriodontist:
      return 'OrganizationJobPositionPeriodontist';
    case OrganizationJobPosition.OrganizationJobPositionProsthodontist:
      return 'OrganizationJobPositionProsthodontist';
    case OrganizationJobPosition.OrganizationJobPositionOrthodontist:
      return 'OrganizationJobPositionOrthodontist';
    case OrganizationJobPosition.OrganizationJobPositionPediatricDentist:
      return 'OrganizationJobPositionPediatricDentist';
    case OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialSurgeon:
      return 'OrganizationJobPositionOralAndMaxillofacialSurgeon';
    case OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialRadiologist:
      return 'OrganizationJobPositionOralAndMaxillofacialRadiologist';
    case OrganizationJobPosition.OrganizationJobPositionAestheticDentist:
      return 'OrganizationJobPositionAestheticDentist';
    case OrganizationJobPosition.OrganizationJobPositionDentalHygienist:
      return 'OrganizationJobPositionDentalHygienist';
    case OrganizationJobPosition.OrganizationJobPositionOther:
      return 'OrganizationJobPositionOther';
    case OrganizationJobPosition.OrganizationJobPositionOfficeManager:
      return 'OrganizationJobPositionOfficeManager';
    case OrganizationJobPosition.OrganizationJobPositionEndodontist:
      return 'OrganizationJobPositionEndodontist';
    case OrganizationJobPosition.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

import { FC, useEffect } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  UseFormSetError,
  useForm,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Input, Layout, Modal } from '@/shared/ui';
import awaitCat from '@/shared/assets/images/cats/loading.png';
import { Invitation_InvitationStatus } from '@/shared/api/protocol_gen/model/dto_access';
import { PATHS } from '@/shared/config';
import { trackPageView } from '@/shared/lib';

import { PATH_TO_PATIENTS_SHARED_WITH_ME_LIST } from '@/entities/patient';

import { AddSharedPatientModalPayloadType } from '@/features/sharePatient/config/types';
import { addSharedPatientModalSchema } from '@/features/sharePatient/config/schema';

import styles from './OrganizationInvite.module.scss';
import { useOrganizationInvite } from './hooks/useOrganizationInvite';

export const OrganizationInvite: FC = () => {
  const { formatMessage, formatDate } = useIntl();

  const { token } = useParams();

  const navigate = useNavigate();

  const {
    invitationStatus,
    invitationCreatedAt,
    senderFullName,
    acceptSharingPatientInviteHandle,
    navigateForAcceptedInvite,
    navigateToSharedWithMeList,
    showCancelledInvitationToast,
  } = useOrganizationInvite(token as string);

  const isInvitationStatusAccepted =
    invitationStatus === Invitation_InvitationStatus.StatusAccepted;

  const isInvitationStatusCancelled =
    invitationStatus === Invitation_InvitationStatus.StatusCancelled;

  const isInvitationStatusPending =
    invitationStatus === Invitation_InvitationStatus.StatusPending;

  // Check in case the user follows the link with the token instead of entering it manually
  useEffect(() => {
    if (isInvitationStatusAccepted) {
      navigateForAcceptedInvite();
    }

    if (isInvitationStatusCancelled) {
      showCancelledInvitationToast();

      navigateToSharedWithMeList();
    }
  }, [invitationStatus]);

  useEffect(() => {
    trackPageView('OrganizationInvite Page Viewed');
  }, []);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<AddSharedPatientModalPayloadType>({
    resolver: yupResolver(addSharedPatientModalSchema),
    mode: 'onChange',
  });

  const isInviteCanceled =
    errors.accessCode && errors.accessCode.type === 'canceledCode';

  const onSubmit: SubmitHandler<AddSharedPatientModalPayloadType> = async (
    data,
  ) => {
    const { accessCode } = data;

    return isInviteCanceled
      ? navigate(PATHS.main)
      : acceptSharingPatientInviteHandle(
          accessCode,
          setError as UseFormSetError<FieldValues>,
        );
  };

  return (
    <Layout>
      <Layout.Content>
        <Layout.Main>
          <Modal
            isOpen
            className={styles.modal}
            bodyClassName={styles.modalBody}
            containerClassName={styles.modalContainer}
            shouldRenderCloseIconButton={false}
            hideFooter
          >
            <img src={awaitCat} alt="await" className={styles.awaitCatImage} />

            {isInvitationStatusPending && (
              <>
                <div className={cn(styles.notification, 'p1')}>
                  <FormattedMessage
                    id="addSharingPatient.notification"
                    defaultMessage="Patient’s page is protected."
                  />

                  <br />

                  <FormattedMessage
                    id="addSharingPatient.contact"
                    defaultMessage="Please, contact {name} to get the access code."
                    values={{
                      name: senderFullName,
                    }}
                  />
                </div>

                {!!invitationCreatedAt && (
                  <div className="p2">
                    <FormattedMessage
                      id="addSharingPatient.sharingDate"
                      defaultMessage="Sharing date:"
                    />{' '}
                    {formatDate(new Date(invitationCreatedAt), {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </div>
                )}

                <form
                  id="addSharedPatientForm"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    control={control}
                    name="accessCode"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        className={styles.input}
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        type="accessCode"
                        onBlur={onBlur}
                        onChange={onChange}
                        error={error?.message}
                        placeholder={formatMessage({
                          id: 'addSharingPatient.placeholder',
                          defaultMessage: 'Enter access code here',
                        })}
                        alignText="center"
                      />
                    )}
                  />
                </form>

                <Button
                  variant="primary"
                  size="large"
                  type="submit"
                  form="addSharedPatientForm"
                  loading={isSubmitting}
                >
                  {isInviteCanceled ? (
                    <FormattedMessage
                      id="addSharingPatient.goToMainPage"
                      defaultMessage="Go to the main page"
                    />
                  ) : (
                    <FormattedMessage
                      id="global.submit"
                      defaultMessage="Submit"
                    />
                  )}
                </Button>

                <Link
                  to={PATH_TO_PATIENTS_SHARED_WITH_ME_LIST}
                  className={cn(styles.addLater, 'p2')}
                >
                  <FormattedMessage
                    id="addSharingPatient.addLater"
                    defaultMessage="I’ll do it later"
                  />
                </Link>
              </>
            )}
          </Modal>
        </Layout.Main>
      </Layout.Content>
    </Layout>
  );
};

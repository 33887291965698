import { InferType, array, object, string } from '@/shared/config/yup';

import { QuestionnaireSteps } from '@/entities/marketing/config/types';

const step1Schema = object({
  CompanyName: string().required(),
});

type Step1Schema = InferType<typeof step1Schema>;

const step2Schema = object({
  OwnerSpecialization: string().required(),
});

type Step2Schema = InferType<typeof step2Schema>;

const step3Schema = object({
  CompanyProfile: string().required(),
});

type Step3Schema = InferType<typeof step3Schema>;

const step4Schema = object({
  CBCTMachineType: array().of(string()).nullable(),
});

type Step4Schema = InferType<typeof step4Schema>;

const step5Schema = object({
  CBCTImagingSoftware: array().of(string()).nullable(),
});

type Step5Schema = InferType<typeof step5Schema>;

const step6Schema = object({
  XRayImagingSoftware: array().of(string()).nullable(),
});

type Step6Schema = InferType<typeof step6Schema>;

const step7Schema = object({
  FocusOfInterest: array().of(string()).min(1).nullable().required(),
});

type Step7Schema = InferType<typeof step7Schema>;

export const additionalInfoFormSchema = {
  [QuestionnaireSteps.CompanyName]: step1Schema,
  [QuestionnaireSteps.OwnerSpecialization]: step2Schema,
  [QuestionnaireSteps.CompanyProfile]: step3Schema,
  [QuestionnaireSteps.CBCTMachineType]: step4Schema,
  [QuestionnaireSteps.CBCTImagingSoftware]: step5Schema,
  [QuestionnaireSteps.XRayImagingSoftware]: step6Schema,
  [QuestionnaireSteps.FocusOfInterest]: step7Schema,
};

export type AllStepsSchema =
  | Step1Schema
  | Step2Schema
  | Step3Schema
  | Step4Schema
  | Step5Schema
  | Step6Schema
  | Step7Schema;

import { FC, ReactElement, useEffect, useState } from 'react';
import RcSelect from 'react-select';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { equals } from 'ramda';

import { OptionsType, OptionType } from '../Select/Select.types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import { Menu } from './components/Menu/Menu';
import { getDropdownSelectStyles } from './getDropdownSelectStyles';
import { dropdownControls } from './components/DropdownControls/DropdownControls';
import style from './DropdownSelect.module.scss';
import { DropdownSelectStylesOptionsType } from './config/types';

export type DropdownSelectProps = {
  label: string;
  options: OptionsType;
  value: string | string[];
  className?: string;
  disabled?: boolean;
  icon?: 'arrow2' | 'arrowDown' | 'filter';
  isMulti?: boolean;
  isRtl?: boolean;
  menuTitle?: string;
  multiValuesCount?: number;
  noOptionsMessage?: ReactElement | string;
  placeholder?: string;
  stylesOptions?: DropdownSelectStylesOptionsType;
  testID?: string;
  onChange: (newValue: string | string[]) => void;
  onApply?: (value: string | string[]) => void;
  onClear?: () => void;
};

export const DropdownSelect: FC<DropdownSelectProps> = (props) => {
  const {
    label,
    options,
    value,
    className,
    disabled,
    icon = 'arrowDown',
    isMulti = false,
    isRtl = false,
    menuTitle,
    multiValuesCount,
    noOptionsMessage = (
      <FormattedMessage id="global.noData" defaultMessage="No data..." />
    ),
    placeholder = (
      <FormattedMessage id="global.select" defaultMessage="Select..." />
    ),
    stylesOptions = {},
    testID,
    onChange,
    onApply,
    onClear,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<string | string[]>(value);

  const { hideSearch, listMaxHeight } = stylesOptions;

  const multiValueText = (value?.length ?? 0) === 0 ? '' : value.length;
  const multiValueTitleText = isMulti ? multiValueText : value;

  const isValuesEqual = equals(initialValue, value);

  const handleApply = () => {
    if (onApply) {
      onApply(value);
    }

    setIsOpen(false);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
  };

  const handleCancel = () => {
    if (isMulti) {
      onChange(initialValue);
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && !isValuesEqual) {
      setInitialValue(value);
    }
  }, [isOpen]);

  return (
    <div className={cn(style.container, className)} data-testid={testID}>
      <button
        disabled={disabled}
        className={cn(style.trigger, isOpen && style.isOpen)}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {label}

        {value && (
          <span className={cn('h6', style.valueContainer)}>
            {multiValuesCount ?? multiValueTitleText}
          </span>
        )}

        <Icon
          className={cn(style[icon])}
          name={icon}
          size={icon === 'arrow2' ? 9 : 18}
        />
      </button>

      {isOpen && (
        <div className={style.overlay} onClick={() => handleCancel()} />
      )}

      {isOpen ? (
        <Menu>
          {menuTitle && (
            <div className={cn('h6', style.menuTitle)}>{menuTitle}</div>
          )}

          <RcSelect
            onChange={(newValue) => {
              if (isMulti) {
                onChange(
                  (newValue as OptionsType).map((option) => option.value),
                );
              } else {
                onChange((newValue as OptionType).value);
                setIsOpen(false);
              }
            }}
            noOptionsMessage={() => noOptionsMessage}
            backspaceRemovesValue={false}
            components={dropdownControls}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            isRtl={isRtl}
            isMulti={isMulti}
            options={options}
            styles={getDropdownSelectStyles({
              hideSearch,
              listMaxHeight,
            })}
            placeholder={placeholder}
            tabSelectsValue={false}
            value={
              isMulti
                ? options.filter((option) => value?.includes(option.value))
                : options.find((option) => option.value === value)
            }
          />

          {isMulti && (
            <>
              <div className={style.divider} />

              <div className={style.buttons}>
                <Button
                  type="button"
                  variant="tertiary"
                  size="medium"
                  onClick={handleClear}
                >
                  <FormattedMessage defaultMessage="Clear" id="global.clear" />
                </Button>

                <Button
                  disabled={value?.length === 0 || isValuesEqual}
                  type="button"
                  variant="secondary"
                  size="medium"
                  onClick={handleApply}
                >
                  <FormattedMessage defaultMessage="Apply" id="global.apply" />
                </Button>
              </div>
            </>
          )}
        </Menu>
      ) : null}
    </div>
  );
};

import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { OrganizationUserRoleType } from '@/shared/api/protocol_gen/model/dto_organization';

import { i18n } from '@/entities/organization';

export const usePermissionOptions = () => {
  const { formatMessage } = useIntl();

  const permissionOptions = useMemo(
    () => [
      {
        label: formatMessage(
          i18n.permissionOwners[
            OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor
          ],
        ),
        value: OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor,
        comment: formatMessage(
          i18n.permissionOwnersComments[
            OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor
          ],
        ),
      },
      {
        label: formatMessage(
          i18n.permissionOwners[
            OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader
          ],
        ),
        value: OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader,
        comment: formatMessage(
          i18n.permissionOwnersComments[
            OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader
          ],
        ),
      },
      {
        label: formatMessage(
          i18n.permissionOwners[
            OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember
          ],
        ),
        value:
          OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember,
        comment: formatMessage(
          i18n.permissionOwnersComments[
            OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember
          ],
        ),
      },
    ],
    [formatMessage],
  );

  return permissionOptions;
};

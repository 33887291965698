import { FC } from 'react';
import { Outlet, useParams } from 'react-router';

import { useAppSelector } from '@/shared/hooks';
import { Spinner } from '@/shared/ui';

import { userModel } from '@/entities/user';

import { UploadAssetProvider } from '@/features/uploadAsset';

import { useUserDataStream } from '@/app/hooks/useUserDataStream';

import { useAccountInfoStream } from '../hooks/useAccountInfoStream';
import { UploadAssetStatus } from '../ui/UploadAssetStatus/UploadAssetStatus';

import styles from './ProtectedRoutes.module.scss';

export const ProtectedRoutes: FC = () => {
  const { token } = useParams();

  useUserDataStream(token);
  useAccountInfoStream();

  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);
  const userLoading = useAppSelector(userModel.selectors.selectLoading);

  const userIsLoading = userLoading === 'pending' || userLoading === 'idle';

  if (userIsLoading)
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );

  if (!currentUser?.ID) {
    return null;
  }

  return (
    <UploadAssetProvider>
      <Outlet />
      <UploadAssetStatus />
    </UploadAssetProvider>
  );
};

import { equals, isEmpty, trim } from 'ramda';
import { useEffect, useState } from 'react';

import { useAddNewComment } from './useAddNewComment';

export const useCommentTooth = (comment: string) => {
  const [isCommentEditorVisible, setIsCommentEditorVisible] = useState(false);
  const [isNewCommentLoading, setIsNewCommentLoading] = useState(false);
  const [newComment, setNewComment] = useState('');

  const addNewComment = useAddNewComment();

  const newCommentWithoutWhitespace = trim(newComment);

  const isNewCommentEmpty = isEmpty(newCommentWithoutWhitespace);

  const isCommentExisting =
    Boolean(comment) || Boolean(newCommentWithoutWhitespace);

  const isNewCommentNotEqualsCurrentComment = isCommentExisting
    ? !equals(newCommentWithoutWhitespace, trim(comment))
    : undefined;

  const resetNewComment = () => setNewComment('');

  useEffect(() => {
    if (!comment) {
      resetNewComment();
    }
  }, [comment]);

  const closeAndResetTextInEditor = () => {
    setIsCommentEditorVisible(false);

    resetNewComment();
  };

  const openChangeComment = () => {
    setIsCommentEditorVisible(true);

    setNewComment(comment);
  };

  const changeCommentInEditor = (text: string) => setNewComment(text);

  const saveComment = async (toothID: string) => {
    if (isNewCommentNotEqualsCurrentComment) {
      setIsNewCommentLoading(true);

      try {
        await addNewComment(toothID, newComment);
      } finally {
        setIsNewCommentLoading(false);
      }
    }

    if (isNewCommentEmpty) {
      setIsCommentEditorVisible(false);
    } else {
      setIsCommentEditorVisible(false);
    }
  };

  return {
    newComment,
    isCommentEditorVisible,
    isNewCommentLoading,
    closeAndResetTextInEditor,
    openChangeComment,
    changeCommentInEditor,
    saveComment,
  };
};

import React, { useContext } from 'react';
import cn from 'classnames';

import { ToothChartContext } from '../ToothChartRoot/ToothChartRoot';
import { ToothChartSize } from '../../config/types';

import styles from './ToothChartGrid.module.scss';

type ToothChartGridProps = {
  children: React.ReactNode;
  showLowerJaw?: boolean;
  showUpperJaw?: boolean;
};

const sizeStyles: Record<ToothChartSize, string> = {
  default: styles.sizeDefault,
  small: styles.sizeSmall,
};

export const ToothChartGrid = (props: ToothChartGridProps) => {
  const { children, showUpperJaw = true, showLowerJaw = true } = props;

  const size = useContext(ToothChartContext);

  return (
    <div
      className={cn(
        styles.container,
        sizeStyles[size],
        !showUpperJaw && styles.hideUpperJaw,
        !showLowerJaw && styles.hideLowerJaw,
      )}
    >
      {children}
    </div>
  );
};

import { RUDIMENT_TEETH_MAP } from '@/entities/tooth';

export const getTeethISONumbersWithRudimentTeeth = (
  teethISONumbers: number[],
) => {
  const teethISONumbersWithRudimentTeeth = [...teethISONumbers];

  Object.entries(RUDIMENT_TEETH_MAP).forEach(([rudimentTooth, tooth]) => {
    if (teethISONumbers.includes(Number(tooth))) {
      teethISONumbersWithRudimentTeeth.push(Number(rudimentTooth));
    }
  });

  return teethISONumbersWithRudimentTeeth;
};

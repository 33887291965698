import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Progress from '@radix-ui/react-progress';

import { Service } from '@/shared/api/protocol_gen/api/billing_new/dto_services_new';
import {
  serviceGroupNamesDefaultMessage,
  serviceNamesDefaultMessage,
} from '@/shared/i18n/services';

import styles from './ServiceView.module.scss';

type ServiceViewProps = {
  className?: string;
  service: Service;
};

export const ServiceView: FC<ServiceViewProps> = (props) => {
  const { className, service } = props;

  const { formatMessage } = useIntl();

  const limit = service?.Countable?.Limit ?? 0;
  const leftOver = service?.Countable?.Leftover ?? 0;
  const used = limit - leftOver;
  const progress = Math.round((used / limit) * 100);
  const isServiceCountable = !service?.Enabler || !Number.isNaN(progress);

  const serviceName =
    service.Types.length > 1
      ? formatMessage(serviceGroupNamesDefaultMessage[service.Group], {
          amount: 0,
        })
      : formatMessage(serviceNamesDefaultMessage[service.Types[0]], {
          amount: 0,
        });

  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.serviceName, 'p2')}>
        {serviceName}
        {isServiceCountable && ':'}
      </div>

      {isServiceCountable && (
        <div className={styles.progressContainer}>
          <div
            className={cn(
              styles.progressBlock,
              !isServiceCountable && styles.withoutMargin,
            )}
          >
            <Progress.Root
              className={styles.progressRoot}
              value={isServiceCountable ? 100 : progress}
            >
              <Progress.Indicator
                className={styles.progressIndicator}
                style={{ transform: `translateX(-${100 - progress}%)` }}
              />
            </Progress.Root>
          </div>

          <div className={cn(styles.limits, 'p3')}>
            <FormattedMessage
              id="subscriptions.billing.used_out_of_limit"
              defaultMessage="{used} out of {limit} used"
              values={{
                used: <span className={styles.used}>{used}</span>,
                limit,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

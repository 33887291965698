import { useIntl } from 'react-intl';

import { software2DTypes } from '../config/i18n';

export const useGet2DSoftwareOptions = () => {
  const { formatMessage } = useIntl();

  const twoDSoftwareOptions = [
    {
      label: formatMessage(software2DTypes.adstra),
      value: 'Adstra',
    },
    {
      label: formatMessage(software2DTypes.AISActeon),
      value: 'AIS (Acteon)',
    },
    {
      label: formatMessage(software2DTypes.byzzOrangeDental),
      value: 'Byzz (Orange Dental)',
    },
    {
      label: formatMessage(software2DTypes.cadiSynca),
      value: 'Cadi (Synca)',
    },
    {
      label: formatMessage(software2DTypes.carestream7),
      value: 'Carestream7',
    },
    {
      label: formatMessage(software2DTypes.carestream8),
      value: 'Carestream8',
    },
    {
      label: formatMessage(software2DTypes.cliniview),
      value: 'Cliniview',
    },
    {
      label: formatMessage(software2DTypes.curveHero),
      value: 'Curve Hero',
    },
    {
      label: formatMessage(software2DTypes.dexis9Envista),
      value: 'Dexis 9 (Envista)',
    },
    {
      label: formatMessage(software2DTypes.dexis10Envista),
      value: 'Dexis 10 (Envista)',
    },
    {
      label: formatMessage(software2DTypes.DTXStudio),
      value: 'DTX Studio',
    },
    {
      label: formatMessage(software2DTypes.eagleSoftPatterson),
      value: 'Eagle Soft (Patterson)',
    },
    {
      label: formatMessage(software2DTypes.examPro),
      value: 'ExamPro',
    },
    {
      label: formatMessage(software2DTypes.EZDentiVaTech),
      value: 'EZ Dent-i (VaTech)',
    },
    {
      label: formatMessage(software2DTypes.iDental),
      value: 'iDental',
    },
    {
      label: formatMessage(software2DTypes.openDental),
      value: 'OpenDental',
    },
    {
      label: formatMessage(software2DTypes.onDemand),
      value: 'On Demand',
    },
    {
      label: formatMessage(software2DTypes.romexisPlanmeca),
      value: 'Romexis (Planmeca)',
    },
    {
      label: formatMessage(software2DTypes.sidexisXGSirona),
      value: 'Sidexis XG (Sirona)',
    },
    {
      label: formatMessage(software2DTypes.sidexis4Sirona),
      value: 'Sidexis 4 (Sirona)',
    },
    {
      label: formatMessage(software2DTypes.SOTAImaging),
      value: 'SOTA Imaging',
    },
    {
      label: formatMessage(software2DTypes.tracker),
      value: 'Tracker',
    },
    {
      label: formatMessage(software2DTypes.vixWinDexis),
      value: 'VixWin (Dexis)',
    },
    {
      label: formatMessage(software2DTypes.XVWebPlanetDDS),
      value: 'XVWeb (PlanetDDS)',
    },
  ];

  twoDSoftwareOptions.sort((optionA, optionB) =>
    optionA.label.localeCompare(optionB.label, undefined, { numeric: true }),
  );

  twoDSoftwareOptions.push({
    label: formatMessage({ id: 'global.other', defaultMessage: 'Other' }),
    value: 'Other',
  });

  return twoDSoftwareOptions;
};

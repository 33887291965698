import healthyTooth11 from '../assets/images/B&WToothIcon/Healthy/tooth11.svg';
import healthyTooth12 from '../assets/images/B&WToothIcon/Healthy/tooth12.svg';
import healthyTooth13 from '../assets/images/B&WToothIcon/Healthy/tooth13.svg';
import healthyTooth14 from '../assets/images/B&WToothIcon/Healthy/tooth14.svg';
import healthyTooth15 from '../assets/images/B&WToothIcon/Healthy/tooth15.svg';
import healthyTooth16 from '../assets/images/B&WToothIcon/Healthy/tooth16.svg';
import healthyTooth17 from '../assets/images/B&WToothIcon/Healthy/tooth17.svg';
import healthyTooth18 from '../assets/images/B&WToothIcon/Healthy/tooth18.svg';
import healthyTooth21 from '../assets/images/B&WToothIcon/Healthy/tooth21.svg';
import healthyTooth22 from '../assets/images/B&WToothIcon/Healthy/tooth22.svg';
import healthyTooth23 from '../assets/images/B&WToothIcon/Healthy/tooth23.svg';
import healthyTooth24 from '../assets/images/B&WToothIcon/Healthy/tooth24.svg';
import healthyTooth25 from '../assets/images/B&WToothIcon/Healthy/tooth25.svg';
import healthyTooth26 from '../assets/images/B&WToothIcon/Healthy/tooth26.svg';
import healthyTooth27 from '../assets/images/B&WToothIcon/Healthy/tooth27.svg';
import healthyTooth28 from '../assets/images/B&WToothIcon/Healthy/tooth28.svg';
import healthyTooth31 from '../assets/images/B&WToothIcon/Healthy/tooth31.svg';
import healthyTooth32 from '../assets/images/B&WToothIcon/Healthy/tooth32.svg';
import healthyTooth33 from '../assets/images/B&WToothIcon/Healthy/tooth33.svg';
import healthyTooth34 from '../assets/images/B&WToothIcon/Healthy/tooth34.svg';
import healthyTooth35 from '../assets/images/B&WToothIcon/Healthy/tooth35.svg';
import healthyTooth36 from '../assets/images/B&WToothIcon/Healthy/tooth36.svg';
import healthyTooth37 from '../assets/images/B&WToothIcon/Healthy/tooth37.svg';
import healthyTooth38 from '../assets/images/B&WToothIcon/Healthy/tooth38.svg';
import healthyTooth41 from '../assets/images/B&WToothIcon/Healthy/tooth41.svg';
import healthyTooth42 from '../assets/images/B&WToothIcon/Healthy/tooth42.svg';
import healthyTooth43 from '../assets/images/B&WToothIcon/Healthy/tooth43.svg';
import healthyTooth44 from '../assets/images/B&WToothIcon/Healthy/tooth44.svg';
import healthyTooth45 from '../assets/images/B&WToothIcon/Healthy/tooth45.svg';
import healthyTooth46 from '../assets/images/B&WToothIcon/Healthy/tooth46.svg';
import healthyTooth47 from '../assets/images/B&WToothIcon/Healthy/tooth47.svg';
import healthyTooth48 from '../assets/images/B&WToothIcon/Healthy/tooth48.svg';
import treatedTooth11 from '../assets/images/B&WToothIcon/Treated/tooth11.svg';
import treatedTooth12 from '../assets/images/B&WToothIcon/Treated/tooth12.svg';
import treatedTooth13 from '../assets/images/B&WToothIcon/Treated/tooth13.svg';
import treatedTooth14 from '../assets/images/B&WToothIcon/Treated/tooth14.svg';
import treatedTooth15 from '../assets/images/B&WToothIcon/Treated/tooth15.svg';
import treatedTooth16 from '../assets/images/B&WToothIcon/Treated/tooth16.svg';
import treatedTooth17 from '../assets/images/B&WToothIcon/Treated/tooth17.svg';
import treatedTooth18 from '../assets/images/B&WToothIcon/Treated/tooth18.svg';
import treatedTooth21 from '../assets/images/B&WToothIcon/Treated/tooth21.svg';
import treatedTooth22 from '../assets/images/B&WToothIcon/Treated/tooth22.svg';
import treatedTooth23 from '../assets/images/B&WToothIcon/Treated/tooth23.svg';
import treatedTooth24 from '../assets/images/B&WToothIcon/Treated/tooth24.svg';
import treatedTooth25 from '../assets/images/B&WToothIcon/Treated/tooth25.svg';
import treatedTooth26 from '../assets/images/B&WToothIcon/Treated/tooth26.svg';
import treatedTooth27 from '../assets/images/B&WToothIcon/Treated/tooth27.svg';
import treatedTooth28 from '../assets/images/B&WToothIcon/Treated/tooth28.svg';
import treatedTooth31 from '../assets/images/B&WToothIcon/Treated/tooth31.svg';
import treatedTooth32 from '../assets/images/B&WToothIcon/Treated/tooth32.svg';
import treatedTooth33 from '../assets/images/B&WToothIcon/Treated/tooth33.svg';
import treatedTooth34 from '../assets/images/B&WToothIcon/Treated/tooth34.svg';
import treatedTooth35 from '../assets/images/B&WToothIcon/Treated/tooth35.svg';
import treatedTooth36 from '../assets/images/B&WToothIcon/Treated/tooth36.svg';
import treatedTooth37 from '../assets/images/B&WToothIcon/Treated/tooth37.svg';
import treatedTooth38 from '../assets/images/B&WToothIcon/Treated/tooth38.svg';
import treatedTooth41 from '../assets/images/B&WToothIcon/Treated/tooth41.svg';
import treatedTooth42 from '../assets/images/B&WToothIcon/Treated/tooth42.svg';
import treatedTooth43 from '../assets/images/B&WToothIcon/Treated/tooth43.svg';
import treatedTooth44 from '../assets/images/B&WToothIcon/Treated/tooth44.svg';
import treatedTooth45 from '../assets/images/B&WToothIcon/Treated/tooth45.svg';
import treatedTooth46 from '../assets/images/B&WToothIcon/Treated/tooth46.svg';
import treatedTooth47 from '../assets/images/B&WToothIcon/Treated/tooth47.svg';
import treatedTooth48 from '../assets/images/B&WToothIcon/Treated/tooth48.svg';
import unhealthyTooth11 from '../assets/images/B&WToothIcon/Unhealthy/tooth11.svg';
import unhealthyTooth12 from '../assets/images/B&WToothIcon/Unhealthy/tooth12.svg';
import unhealthyTooth13 from '../assets/images/B&WToothIcon/Unhealthy/tooth13.svg';
import unhealthyTooth14 from '../assets/images/B&WToothIcon/Unhealthy/tooth14.svg';
import unhealthyTooth15 from '../assets/images/B&WToothIcon/Unhealthy/tooth15.svg';
import unhealthyTooth16 from '../assets/images/B&WToothIcon/Unhealthy/tooth16.svg';
import unhealthyTooth17 from '../assets/images/B&WToothIcon/Unhealthy/tooth17.svg';
import unhealthyTooth18 from '../assets/images/B&WToothIcon/Unhealthy/tooth18.svg';
import unhealthyTooth21 from '../assets/images/B&WToothIcon/Unhealthy/tooth21.svg';
import unhealthyTooth22 from '../assets/images/B&WToothIcon/Unhealthy/tooth22.svg';
import unhealthyTooth23 from '../assets/images/B&WToothIcon/Unhealthy/tooth23.svg';
import unhealthyTooth24 from '../assets/images/B&WToothIcon/Unhealthy/tooth24.svg';
import unhealthyTooth25 from '../assets/images/B&WToothIcon/Unhealthy/tooth25.svg';
import unhealthyTooth26 from '../assets/images/B&WToothIcon/Unhealthy/tooth26.svg';
import unhealthyTooth27 from '../assets/images/B&WToothIcon/Unhealthy/tooth27.svg';
import unhealthyTooth28 from '../assets/images/B&WToothIcon/Unhealthy/tooth28.svg';
import unhealthyTooth31 from '../assets/images/B&WToothIcon/Unhealthy/tooth31.svg';
import unhealthyTooth32 from '../assets/images/B&WToothIcon/Unhealthy/tooth32.svg';
import unhealthyTooth33 from '../assets/images/B&WToothIcon/Unhealthy/tooth33.svg';
import unhealthyTooth34 from '../assets/images/B&WToothIcon/Unhealthy/tooth34.svg';
import unhealthyTooth35 from '../assets/images/B&WToothIcon/Unhealthy/tooth35.svg';
import unhealthyTooth36 from '../assets/images/B&WToothIcon/Unhealthy/tooth36.svg';
import unhealthyTooth37 from '../assets/images/B&WToothIcon/Unhealthy/tooth37.svg';
import unhealthyTooth38 from '../assets/images/B&WToothIcon/Unhealthy/tooth38.svg';
import unhealthyTooth41 from '../assets/images/B&WToothIcon/Unhealthy/tooth41.svg';
import unhealthyTooth42 from '../assets/images/B&WToothIcon/Unhealthy/tooth42.svg';
import unhealthyTooth43 from '../assets/images/B&WToothIcon/Unhealthy/tooth43.svg';
import unhealthyTooth44 from '../assets/images/B&WToothIcon/Unhealthy/tooth44.svg';
import unhealthyTooth45 from '../assets/images/B&WToothIcon/Unhealthy/tooth45.svg';
import unhealthyTooth46 from '../assets/images/B&WToothIcon/Unhealthy/tooth46.svg';
import unhealthyTooth47 from '../assets/images/B&WToothIcon/Unhealthy/tooth47.svg';
import unhealthyTooth48 from '../assets/images/B&WToothIcon/Unhealthy/tooth48.svg';
import lowProbabilityTooth11 from '../assets/images/B&WToothIcon/LowProbability/tooth11.svg';
import lowProbabilityTooth12 from '../assets/images/B&WToothIcon/LowProbability/tooth12.svg';
import lowProbabilityTooth13 from '../assets/images/B&WToothIcon/LowProbability/tooth13.svg';
import lowProbabilityTooth14 from '../assets/images/B&WToothIcon/LowProbability/tooth14.svg';
import lowProbabilityTooth15 from '../assets/images/B&WToothIcon/LowProbability/tooth15.svg';
import lowProbabilityTooth16 from '../assets/images/B&WToothIcon/LowProbability/tooth16.svg';
import lowProbabilityTooth17 from '../assets/images/B&WToothIcon/LowProbability/tooth17.svg';
import lowProbabilityTooth18 from '../assets/images/B&WToothIcon/LowProbability/tooth18.svg';
import lowProbabilityTooth21 from '../assets/images/B&WToothIcon/LowProbability/tooth21.svg';
import lowProbabilityTooth22 from '../assets/images/B&WToothIcon/LowProbability/tooth22.svg';
import lowProbabilityTooth23 from '../assets/images/B&WToothIcon/LowProbability/tooth23.svg';
import lowProbabilityTooth24 from '../assets/images/B&WToothIcon/LowProbability/tooth24.svg';
import lowProbabilityTooth25 from '../assets/images/B&WToothIcon/LowProbability/tooth25.svg';
import lowProbabilityTooth26 from '../assets/images/B&WToothIcon/LowProbability/tooth26.svg';
import lowProbabilityTooth27 from '../assets/images/B&WToothIcon/LowProbability/tooth27.svg';
import lowProbabilityTooth28 from '../assets/images/B&WToothIcon/LowProbability/tooth28.svg';
import lowProbabilityTooth31 from '../assets/images/B&WToothIcon/LowProbability/tooth31.svg';
import lowProbabilityTooth32 from '../assets/images/B&WToothIcon/LowProbability/tooth32.svg';
import lowProbabilityTooth33 from '../assets/images/B&WToothIcon/LowProbability/tooth33.svg';
import lowProbabilityTooth34 from '../assets/images/B&WToothIcon/LowProbability/tooth34.svg';
import lowProbabilityTooth35 from '../assets/images/B&WToothIcon/LowProbability/tooth35.svg';
import lowProbabilityTooth36 from '../assets/images/B&WToothIcon/LowProbability/tooth36.svg';
import lowProbabilityTooth37 from '../assets/images/B&WToothIcon/LowProbability/tooth37.svg';
import lowProbabilityTooth38 from '../assets/images/B&WToothIcon/LowProbability/tooth38.svg';
import lowProbabilityTooth41 from '../assets/images/B&WToothIcon/LowProbability/tooth41.svg';
import lowProbabilityTooth42 from '../assets/images/B&WToothIcon/LowProbability/tooth42.svg';
import lowProbabilityTooth43 from '../assets/images/B&WToothIcon/LowProbability/tooth43.svg';
import lowProbabilityTooth44 from '../assets/images/B&WToothIcon/LowProbability/tooth44.svg';
import lowProbabilityTooth45 from '../assets/images/B&WToothIcon/LowProbability/tooth45.svg';
import lowProbabilityTooth46 from '../assets/images/B&WToothIcon/LowProbability/tooth46.svg';
import lowProbabilityTooth47 from '../assets/images/B&WToothIcon/LowProbability/tooth47.svg';
import lowProbabilityTooth48 from '../assets/images/B&WToothIcon/LowProbability/tooth48.svg';

import { ToothStatus } from './constants';

export const blackAndWhiteToothImages: Record<
  Exclude<ToothStatus, ToothStatus.missing | ToothStatus.notFound>,
  Record<number, string>
> = {
  healthy: {
    11: healthyTooth11,
    12: healthyTooth12,
    13: healthyTooth13,
    14: healthyTooth14,
    15: healthyTooth15,
    16: healthyTooth16,
    17: healthyTooth17,
    18: healthyTooth18,

    21: healthyTooth21,
    22: healthyTooth22,
    23: healthyTooth23,
    24: healthyTooth24,
    25: healthyTooth25,
    26: healthyTooth26,
    27: healthyTooth27,
    28: healthyTooth28,

    31: healthyTooth31,
    33: healthyTooth33,
    32: healthyTooth32,
    34: healthyTooth34,
    35: healthyTooth35,
    36: healthyTooth36,
    37: healthyTooth37,
    38: healthyTooth38,

    41: healthyTooth41,
    42: healthyTooth42,
    43: healthyTooth43,
    44: healthyTooth44,
    45: healthyTooth45,
    46: healthyTooth46,
    47: healthyTooth47,
    48: healthyTooth48,
  },
  treated: {
    11: treatedTooth11,
    12: treatedTooth12,
    13: treatedTooth13,
    14: treatedTooth14,
    15: treatedTooth15,
    16: treatedTooth16,
    17: treatedTooth17,
    18: treatedTooth18,

    21: treatedTooth21,
    22: treatedTooth22,
    23: treatedTooth23,
    24: treatedTooth24,
    25: treatedTooth25,
    26: treatedTooth26,
    27: treatedTooth27,
    28: treatedTooth28,

    31: treatedTooth31,
    33: treatedTooth33,
    32: treatedTooth32,
    34: treatedTooth34,
    35: treatedTooth35,
    36: treatedTooth36,
    37: treatedTooth37,
    38: treatedTooth38,

    41: treatedTooth41,
    42: treatedTooth42,
    43: treatedTooth43,
    44: treatedTooth44,
    45: treatedTooth45,
    46: treatedTooth46,
    47: treatedTooth47,
    48: treatedTooth48,
  },
  unhealthy: {
    11: unhealthyTooth11,
    12: unhealthyTooth12,
    13: unhealthyTooth13,
    14: unhealthyTooth14,
    15: unhealthyTooth15,
    16: unhealthyTooth16,
    17: unhealthyTooth17,
    18: unhealthyTooth18,

    21: unhealthyTooth21,
    22: unhealthyTooth22,
    23: unhealthyTooth23,
    24: unhealthyTooth24,
    25: unhealthyTooth25,
    26: unhealthyTooth26,
    27: unhealthyTooth27,
    28: unhealthyTooth28,

    31: unhealthyTooth31,
    33: unhealthyTooth33,
    32: unhealthyTooth32,
    34: unhealthyTooth34,
    35: unhealthyTooth35,
    36: unhealthyTooth36,
    37: unhealthyTooth37,
    38: unhealthyTooth38,

    41: unhealthyTooth41,
    42: unhealthyTooth42,
    43: unhealthyTooth43,
    44: unhealthyTooth44,
    45: unhealthyTooth45,
    46: unhealthyTooth46,
    47: unhealthyTooth47,
    48: unhealthyTooth48,
  },
  lowProbability: {
    11: lowProbabilityTooth11,
    12: lowProbabilityTooth12,
    13: lowProbabilityTooth13,
    14: lowProbabilityTooth14,
    15: lowProbabilityTooth15,
    16: lowProbabilityTooth16,
    17: lowProbabilityTooth17,
    18: lowProbabilityTooth18,

    21: lowProbabilityTooth21,
    22: lowProbabilityTooth22,
    23: lowProbabilityTooth23,
    24: lowProbabilityTooth24,
    25: lowProbabilityTooth25,
    26: lowProbabilityTooth26,
    27: lowProbabilityTooth27,
    28: lowProbabilityTooth28,

    31: lowProbabilityTooth31,
    33: lowProbabilityTooth33,
    32: lowProbabilityTooth32,
    34: lowProbabilityTooth34,
    35: lowProbabilityTooth35,
    36: lowProbabilityTooth36,
    37: lowProbabilityTooth37,
    38: lowProbabilityTooth38,

    41: lowProbabilityTooth41,
    42: lowProbabilityTooth42,
    43: lowProbabilityTooth43,
    44: lowProbabilityTooth44,
    45: lowProbabilityTooth45,
    46: lowProbabilityTooth46,
    47: lowProbabilityTooth47,
    48: lowProbabilityTooth48,
  },
};

import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { RefreshPage } from '@/widgets/RefreshPage';

import { ErrorPage } from '@/pages/ErrorPage/ErrorPage';

import { IntlProvider } from './providers/IntlProvider';
import { store } from './model/store';
import { router } from './model/router';
// TODO: [4|h] Integrate tracker after update react router to the last version.
// import { HubspotEventsTracker } from './utils/HubspotEventTracker';

export const App = Sentry.withProfiler(() => (
  <Provider store={store}>
    <IntlProvider>
      <RadixTooltip.Provider skipDelayDuration={0}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <RouterProvider router={router} />
          <RefreshPage />
          <ToastContainer icon={false} hideProgressBar />
        </Sentry.ErrorBoundary>
      </RadixTooltip.Provider>
    </IntlProvider>
  </Provider>
));

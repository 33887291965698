import { useLocation } from 'react-router';

import { LocationStateType } from '../config';

export const useLocationState = () => {
  const location = useLocation();
  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const lastPositionPatientProfile = locationState?.lastPositionPatientProfile;

  const lastPositionReport = locationState?.lastPositionReport;

  const toothID = locationState?.toothID;

  const recentReportIndex = locationState?.recentReportIndex;

  const lastOpenTabStudyID = locationState?.lastOpenTabStudyID;

  return {
    lastPositionPatientProfile,
    lastPositionReport,
    toothID,
    recentReportIndex,
    locationState,
    lastOpenTabStudyID,
  };
};

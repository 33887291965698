import { Record } from 'immutable';

import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { OrderReportType } from '@/shared/config';

import { OrderReportModalStep } from './types';
import { UploadStudyPayload } from './formSchema';

export const UPLOAD_STUDY_FORM_ID = 'upload-study-form';

export const MAX_BYTES_FOR_STUDIES = 262144000; // 250MB
export const MAX_BYTES_FOR_CBCT = 10737418240; // 10GB

export type UploadStudyType =
  | AssetType.AssetType_Study_CBCT
  | AssetType.AssetType_Document
  | AssetType.AssetType_Study_IntraoralXRay
  | AssetType.AssetType_Study_DentalPhoto
  | AssetType.AssetType_Study_PanoramicXRay
  | AssetType.AssetType_Study_IOS_Meshes;

export const WITHOUT_FILE_FORMAT_ACCEPTED_STUDIES = [
  AssetType.AssetType_Study_CBCT,
  AssetType.AssetType_Study_IntraoralXRay,
  AssetType.AssetType_Study_PanoramicXRay,
];

export const ACCEPTED_FILES_FORMATS_FOR_STUDIES: Record<
  UploadStudyType,
  string[]
> = {
  [AssetType.AssetType_Study_CBCT]: ['.dcm', '.zip'],
  [AssetType.AssetType_Study_IntraoralXRay]: [
    '.jpeg',
    '.jpg',
    '.tiff',
    '.png',
    '.dcm',
    '.zip',
  ],
  [AssetType.AssetType_Study_PanoramicXRay]: [
    '.jpeg',
    '.jpg',
    '.tiff',
    '.png',
    '.dcm',
    '.zip',
  ],
  [AssetType.AssetType_Study_DentalPhoto]: [
    '.jpeg',
    '.jpg',
    '.tiff',
    '.png',
    '.zip',
  ],
  [AssetType.AssetType_Study_IOS_Meshes]: [
    '.stl',
    '.obj',
    '.ply',
    '.drc',
    '.zip',
  ],
  [AssetType.AssetType_Document]: [''],
};

export const ACCEPTED_FILE_TYPES_FOR_DROPZONE_BY_ASSET_TYPE: Record<
  UploadStudyType,
  Record<string, string[]>
> = {
  [AssetType.AssetType_Study_CBCT]: {
    'application/dicom': ['.dcm'],
    'application/zip': ['.zip'],
  },
  [AssetType.AssetType_Study_IntraoralXRay]: {
    'application/dicom': ['.dcm'],
    'application/zip': ['.zip'],
    'image/*': ['.jpeg', '.jpg', '.tiff', '.png'],
  },
  [AssetType.AssetType_Study_PanoramicXRay]: {
    'application/dicom': ['.dcm'],
    'application/zip': ['.zip'],
    'image/*': ['.jpeg', '.jpg', '.tiff', '.png'],
  },
  [AssetType.AssetType_Study_DentalPhoto]: {
    'application/zip': ['.zip'],
    'image/*': ['.jpeg', '.jpg', '.tiff', '.png'],
  },
  [AssetType.AssetType_Study_IOS_Meshes]: {
    'application/sla': ['.stl'],
    'model/obj': ['.obj'],
    'application/x-ply': ['.ply'],
    'application/x-drc': ['.drc'],
    'text/plain': ['.obj', '.ply'],
  },
  [AssetType.AssetType_Document]: {
    'text/plain': [],
  },
};

export const ORDER_REPORT_STUDY_TYPE: Record<OrderReportType, StudyType> = {
  CBCT: StudyType.StudyType_CBCT,
  Pano: StudyType.StudyType_PanoramicXRay,
  IOXRay: StudyType.StudyType_IntraoralXRay,
  '3DStudio': StudyType.StudyType_CBCT,
  ImplantStudio: StudyType.StudyType_CBCT,
  OrthoStudio: StudyType.StudyType_CBCT,
};

export const ORDER_REPORT_ASSET_TYPE: Record<OrderReportType, UploadStudyType> =
  {
    CBCT: AssetType.AssetType_Study_CBCT,
    Pano: AssetType.AssetType_Study_PanoramicXRay,
    IOXRay: AssetType.AssetType_Study_IntraoralXRay,
    '3DStudio': AssetType.AssetType_Study_CBCT,
    ImplantStudio: AssetType.AssetType_Study_CBCT,
    OrthoStudio: AssetType.AssetType_Study_CBCT,
  };

export const SELECT_INPUT_NAME: Record<
  OrderReportModalStep,
  keyof UploadStudyPayload
> = {
  '1': 'studyID',
  '2': 'STLStudyID',
  '3': 'DentalPhotoStudyID',
};

import React from 'react';
import cn from 'classnames';

import { Spinner } from '../Spinner/Spinner';
import { Icon } from '../Icon/Icon';
import { IconNames } from '../Icon/Icon.types';

import styles from './Button.module.scss';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'gray';

type ButtonType = 'button' | 'submit' | 'reset';

export type ButtonSize = 'large' | 'medium' | 'small' | 'tiny';

interface BaseButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  circle?: boolean;
  danger?: boolean;
  success?: boolean;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
  type?: ButtonType;
  icon?: IconNames;
  className?: string;
  testID?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

type MergedHTMLAttributes = React.HTMLAttributes<HTMLElement> &
  React.ButtonHTMLAttributes<HTMLElement> &
  React.AnchorHTMLAttributes<HTMLElement>;

export type ButtonProps = BaseButtonProps &
  MergedHTMLAttributes & {
    href?: string;
  };

const buttonSize: Record<ButtonSize, string> = {
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
  tiny: styles.sizeTiny,
};

const iconSize: Record<ButtonSize, number> = {
  large: 32,
  medium: 24,
  small: 24,
  tiny: 20,
};

const buttonVariant: Record<ButtonVariant, string> = {
  primary: styles.variantPrimary,
  secondary: styles.variantSecondary,
  tertiary: styles.variantTertiary,
  gray: styles.variantGray,
};

const buttonTypography: Record<ButtonVariant, Record<ButtonSize, string>> = {
  primary: {
    large: 'h5',
    medium: 'h6',
    small: 'p3b',
    tiny: 'p3b',
  },
  secondary: {
    large: 'h5',
    medium: 'h6',
    small: 'p3b',
    tiny: 'p3b',
  },
  tertiary: {
    large: 'p1',
    medium: 'p2',
    small: 'p3',
    tiny: 'p3b',
  },
  gray: {
    large: 'p1',
    medium: 'p2',
    small: 'p3',
    tiny: 'p3b',
  },
};

// TODO: Need to sync with design team for small/tiny button size
export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const {
    loading = false,
    type = 'button',
    variant = 'primary',
    size = 'large',
    danger = false,
    circle = false,
    block = false,
    icon,
    className,
    testID,
    children,
    onClick,
    disabled,
    success,
    ...restBaseButtonProps
  } = props;

  const isOnlyIcon = Boolean(icon) && !children;
  const showIcon = !loading && !!icon;
  const mergedDisabled = loading || disabled;

  const classes = cn(
    styles.button,
    buttonSize[size],
    buttonVariant[variant],
    buttonTypography[variant][size],
    isOnlyIcon && styles.onlyIcon,
    circle && styles.circle,
    danger && styles.danger,
    block && styles.block,
    success && styles.success,
    className,
  );

  const iconNode = (
    <>
      {loading && (
        <div className={styles.buttonSpinner}>
          <Spinner size={iconSize[size]} />
        </div>
      )}
      {showIcon && <Icon name={icon} size={iconSize[size]} />}
    </>
  );

  const kids = children && (
    <span className={cn(loading && styles.hiddenButtonChildren)}>
      {children}
    </span>
  );

  if (restBaseButtonProps.href !== undefined) {
    return (
      <a
        {...restBaseButtonProps}
        href={mergedDisabled ? undefined : restBaseButtonProps.href}
        className={classes}
        data-testid={testID}
        onClick={onClick}
        ref={ref as React.Ref<HTMLAnchorElement>}
        tabIndex={mergedDisabled ? -1 : 0}
      >
        {iconNode}
        {kids}
      </a>
    );
  }

  return (
    <button
      {...restBaseButtonProps}
      type={type}
      className={classes}
      data-testid={testID}
      onClick={onClick}
      disabled={mergedDisabled}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      {iconNode}
      {kids}
    </button>
  );
});

import React, { CSSProperties } from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import cn from 'classnames';

import { AvatarGroup } from './AvatarGroup';
import { avatarColorHash } from './Avatar.config';
import styles from './Avatar.module.scss';

type AvatarProps = {
  size?: number;
  src?: string;
  alt?: string;
  initials?: string;
  disabled?: boolean;
  count?: number;
  className?: string;
  testID?: string;
};

type CompoundedComponent = React.ForwardRefExoticComponent<
  AvatarProps & React.RefAttributes<HTMLSpanElement>
> & {
  Group: typeof AvatarGroup;
};

const InternalAvatar: React.ForwardRefRenderFunction<
  HTMLSpanElement,
  AvatarProps
> = (props, ref) => {
  const {
    size = 48,
    src,
    alt,
    initials,
    disabled,
    count,
    className,
    testID,
    ...restAvatarProps
  } = props;

  const isCount = Boolean(count);
  const style: CSSProperties = { width: size, height: size };

  if (!isCount && initials) {
    style.backgroundColor = avatarColorHash.hex(initials);
  }

  return (
    <AvatarPrimitive.Root
      {...restAvatarProps}
      ref={ref}
      className={cn(
        styles.avatar,
        disabled && styles.disabled,
        isCount && styles.isCount,
        className,
      )}
      data-testid={testID}
      style={style}
    >
      {isCount ? null : (
        <AvatarPrimitive.Image
          className={styles.avatarImage}
          src={src}
          alt={alt}
        />
      )}
      <AvatarPrimitive.Fallback
        className={cn(styles.avatarFallback, 'h6')}
        delayMs={600}
      >
        {count ? `${count}+` : initials}
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  );
};

const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>(
  InternalAvatar,
) as CompoundedComponent;

Avatar.Group = AvatarGroup;

export { Avatar };

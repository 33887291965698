import {
  BillingZones,
  CIS_COUNTRIES,
  EU_MAIN_COUNTRIES,
  EU_SW_COUNTRIES,
  EU_UK_COUNTRIES,
  US_CA_COUNTRIES,
  US_TIER_1_IRB_COUNTRIES,
  US_TIER_2_COUNTRIES,
} from '../config';

// TODO: [1/l] In the future, the backend promises to add a method for obtaining the billing zone by country
export const getBillingZoneByCountry = (country: string) => {
  if (EU_UK_COUNTRIES.includes(country)) {
    return BillingZones['EU_UK'];
  }

  if (EU_SW_COUNTRIES.includes(country)) {
    return BillingZones['EU_SW'];
  }

  if (EU_MAIN_COUNTRIES.includes(country)) {
    return BillingZones['EU_MAIN'];
  }

  if (CIS_COUNTRIES.includes(country)) {
    return BillingZones['CIS'];
  }

  if (US_CA_COUNTRIES.includes(country)) {
    return BillingZones['US-CA'];
  }

  if (US_TIER_1_IRB_COUNTRIES.includes(country)) {
    return BillingZones['US-TIER-1'];
  }

  if (US_TIER_2_COUNTRIES.includes(country)) {
    return BillingZones['US-TIER-2'];
  }

  return undefined;
};

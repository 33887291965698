import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  AcceptInvitationReq,
  AcceptInvitationResp,
  AccessServiceClientImpl,
  RevokeSharingReq,
  RevokeSharingResp,
  InviteReq,
  InviteResp,
  CancelInvitationReq,
  CancelInvitationResp,
  GetInvitationByTokenReq,
  GetInvitationByTokenResp,
  SetUserOrganizationRolesReq,
  SetUserOrganizationRolesResp,
  SetPatientTeamMembersReq,
  SetPatientTeamMembersResp,
  SetOrganizationInvitationUserInfoReq,
  SetOrganizationInvitationUserInfoResp,
} from '@/shared/api/protocol_gen/api/core/svc_access';

type AccessRequests = keyof AccessServiceClientImpl;

const generateAccessThunk = createThunkGenerator<AccessRequests>(
  SliceName.access,
  'access',
);

export const setUserOrganizationRoles = generateAccessThunk<
  SetUserOrganizationRolesReq,
  SetUserOrganizationRolesResp
>('SetUserOrganizationRoles', api.access.SetUserOrganizationRoles);

export const setPatientTeamMembers = generateAccessThunk<
  SetPatientTeamMembersReq,
  SetPatientTeamMembersResp
>('SetPatientTeamMembers', api.access.SetPatientTeamMembers);

export const revokeSharing = generateAccessThunk<
  RevokeSharingReq,
  RevokeSharingResp
>('RevokeSharing', api.access.RevokeSharing);

export const cancelInvitation = generateAccessThunk<
  CancelInvitationReq,
  CancelInvitationResp
>('CancelInvitation', api.access.CancelInvitation);

export const inviteForSharingPatient = generateAccessThunk<
  InviteReq,
  InviteResp
>('Invite', api.access.Invite);

export const acceptInvite = generateAccessThunk<
  AcceptInvitationReq,
  AcceptInvitationResp
>('Invite', api.access.AcceptInvitation);

export const getInvitationByToken = generateAccessThunk<
  GetInvitationByTokenReq,
  GetInvitationByTokenResp
>('GetInvitationByToken', api.access.GetInvitationByToken);

export const setOrganizationInvitationUserInfo = generateAccessThunk<
  SetOrganizationInvitationUserInfoReq,
  SetOrganizationInvitationUserInfoResp
>(
  'SetOrganizationInvitationUserInfo',
  api.access.SetOrganizationInvitationUserInfo,
);

import React, { useContext } from 'react';
import cn from 'classnames';
import * as Tooltip from '@radix-ui/react-tooltip';

import {
  ToothChartItemClickHandler,
  ToothChartSize,
  ToothItem,
} from '../../config/types';
import { ToothChartItem } from '../ToothChartItem/ToothChartItem';
import { ToothChartContext } from '../ToothChartRoot/ToothChartRoot';

import styles from './ToothChartSuperItem.module.scss';

const sizeStyles: Record<ToothChartSize, string> = {
  default: styles.sizeDefault,
  small: styles.sizeSmall,
};

type ToothChartSuperItemProps = ToothItem & {
  className?: string;
  supernumeraryIndex?: number;
  blackAndWhite?: boolean;
  onClick?: ToothChartItemClickHandler;
};

const InternalToothChartSuperItem = (props: ToothChartSuperItemProps) => {
  const {
    className,
    id,
    status,
    active,
    isLowerJaw,
    ISONumber,
    blackAndWhite = false,
    childItems,
    gridArea,
    onClick,
  } = props;

  const size = useContext(ToothChartContext);

  const mainItemIsActive = active || childItems?.some((item) => item.active);

  return (
    <Tooltip.Root delayDuration={0}>
      <Tooltip.Trigger asChild>
        <ToothChartItem
          id={id}
          status={status}
          isLowerJaw={isLowerJaw}
          ISONumber={ISONumber}
          active={Boolean(mainItemIsActive)}
          childItems={childItems}
          blackAndWhite={blackAndWhite}
          gridArea={gridArea}
        />
      </Tooltip.Trigger>

      <Tooltip.Portal>
        <Tooltip.Content className={styles.content} sideOffset={5}>
          <div className={cn(className, styles.container, sizeStyles[size])}>
            <ToothChartItem
              id={id}
              status={status}
              isLowerJaw={isLowerJaw}
              ISONumber={ISONumber}
              active={active}
              blackAndWhite={blackAndWhite}
              onClick={onClick}
              gridArea={gridArea}
            />

            {childItems?.map((childItem) => (
              <ToothChartItem
                supernumeraryIndex={childItem.supernumeraryIndex}
                key={childItem.id}
                id={childItem.id}
                status={childItem.status}
                isLowerJaw={childItem.isLowerJaw}
                ISONumber={childItem.ISONumber}
                active={childItem.active}
                blackAndWhite={blackAndWhite}
                onClick={onClick}
                gridArea={gridArea}
              />
            ))}
          </div>

          <Tooltip.Arrow className={styles.arrow} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export const ToothChartSuperItem = React.memo(InternalToothChartSuperItem);

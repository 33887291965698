import { useIntl } from 'react-intl';

import { companyProfiles } from '../config/i18n';

export const useGetCompanyProfileOptions = () => {
  const { formatMessage } = useIntl();

  const companyProfileOptions = [
    {
      label: formatMessage(companyProfiles.academicInstitution),
      value: 'Academic Institution',
    },
    {
      label: formatMessage(companyProfiles.dentalLab),
      value: 'Dental Lab',
    },
    {
      label: formatMessage(companyProfiles.diagnosticCenter),
      value: 'Diagnostic Center',
    },
    {
      label: formatMessage(companyProfiles.dso),
      value: 'DSO',
    },
    {
      label: formatMessage(companyProfiles.groupPractice),
      value: 'Group Practice',
    },
    {
      label: formatMessage(companyProfiles.multiSpecialtyPractice),
      value: 'MultiSpecialty Practice',
    },
    {
      label: formatMessage(companyProfiles.soloPractitioner),
      value: 'Solo practitioner',
    },
  ];

  companyProfileOptions.sort((optionA, optionB) =>
    optionA.label.localeCompare(optionB.label),
  );

  companyProfileOptions.push({
    label: formatMessage({ id: 'global.other', defaultMessage: 'Other' }),
    value: 'Other',
  });

  return companyProfileOptions;
};

import React, { FC, ReactNode } from 'react';
import { FileWithPath } from 'react-dropzone';
import cn from 'classnames';

import { UploadPreview } from '../UploadPreview/UploadPreview';
import { UploadDropzone } from '../UploadDropzone/UploadDropzone';

import styles from './UploadImage.module.scss';

type UploadImageProps = {
  onChange: (image: File | FileWithPath) => void;
  className?: string;
  title?: ReactNode;
  dropDescription?: React.ReactNode;
  previewImage?: string;
  onRemove?: (assetID: string) => void;
  imageAssetID?: string;
  loading?: boolean;
  error?: string;
};

export const UploadImage: FC<UploadImageProps> = (props) => {
  const {
    onChange,
    loading,
    imageAssetID,
    previewImage,
    onRemove,
    dropDescription,
    error,
    title,
    className,
  } = props;

  return (
    <div className={cn(styles.container, className)}>
      <p className="p2">{title}</p>

      {previewImage ? (
        <UploadPreview
          previewImage={previewImage}
          imageAssetID={imageAssetID}
          onRemove={onRemove}
          onChange={onChange}
        />
      ) : (
        <UploadDropzone
          description={dropDescription}
          loading={loading}
          onChange={onChange}
          error={error}
        />
      )}
    </div>
  );
};

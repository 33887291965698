import React, { forwardRef } from 'react';

export type RCContainerStyle = Omit<React.CSSProperties, "position">

export type RCContainerProps = {
    className?: string,
    style?: RCContainerStyle,
    children: React.ReactNode,
}

// The only purpose of RCContainer (Render Component Container)
// is to have render components inside, that must be overlayed.
// Every component must be wraped with <AbsoluteBlock> to be overlayed.
export const RCContainer = forwardRef<HTMLDivElement, RCContainerProps>((props, ref) => {

    return (
        <div
            ref={ref}
            style={{
                position: 'relative',
                ...props.style
            }}
            className={props.className}
        >
            {props.children}
        </div>
    )
})

import { useState } from 'react';

import { useModal } from '@/shared/hooks';

export const useRemoveReportModal = () => {
  const [currentReportIDs, setCurrentReportID] = useState<string[]>();

  const { isOpen, openModal, closeModal } = useModal();

  const openRemoveReportModal = (reportIDs: string[]) => {
    setCurrentReportID(reportIDs);
    openModal();
  };

  const closeRemoveReportModal = () => {
    setCurrentReportID(undefined);
    closeModal();
  };

  return {
    currentReportIDs,
    isRemoveReportModalOpen: isOpen,
    openRemoveReportModal,
    closeRemoveReportModal,
  };
};

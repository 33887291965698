import { AnnotationEditContext } from './Annotation';
import { ArrowProps } from './Arrow';
import { CircleHandleHelper } from './CircleHandleHelper';

type ArrowHandlesProps = ArrowProps & { onIntentToEdit: (context: AnnotationEditContext) => void };
export const ArrowHandle = ({ x1, x2, y1, y2, onIntentToEdit }: ArrowHandlesProps) => {
  return (
    <>
      <CircleHandleHelper
        x={x1}
        y={y1}
        onDown={() => {
          onIntentToEdit({ kind: 'arrow', value: 'dragStartingPoint' });
        }}
      />
      <CircleHandleHelper
        x={x2}
        y={y2}
        onDown={() => {
          onIntentToEdit({ kind: 'arrow', value: 'dragEndingPoint' });
        }}
      />
    </>
  );
};

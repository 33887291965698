import { defineMessages } from 'react-intl';

import { UploadSessionPullStatus } from '@/features/uploadAsset';

export const uploadProgressBarText = defineMessages<UploadSessionPullStatus>({
  success: {
    id: 'uploadProgressBarText.success',
    defaultMessage: 'Upload successful',
  },
  failed: {
    id: 'global.error',
    defaultMessage: 'Error',
  },
  unsuccessful: {
    id: 'uploadProgressBarTex.unsupportedFile',
    defaultMessage: 'Unsupported file',
  },
  uploading: {
    id: 'uploadProgressBarText.uploading',
    defaultMessage: 'Uploading study...',
  },
});

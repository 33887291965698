import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';
import { useAppSelector } from '@/shared/hooks';

import { organizationModel } from '@/entities/organization';

import { PatientProfile } from './PatientProfile';
import { PatientProfile_DEPRECATED } from './PatientProfile_DEPRECATED';

// WARN: Temporary solution for using old and new patieint profile pages during development
export const PatientProfilePageContainer = () => {
  const organizationLoading = useAppSelector(
    organizationModel.selectors.selectLoading,
  );

  const showNewPatientPage = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Show_NewPatientPage,
    ),
  );

  if (organizationLoading !== 'succeeded') {
    return null;
  }

  return showNewPatientPage ? (
    <PatientProfile />
  ) : (
    <PatientProfile_DEPRECATED />
  );
};

export const scrollToTop = (
  element?: HTMLElement | null,
  behavior?: ScrollBehavior,
) => {
  const scrolledElement = element || window;

  scrolledElement.scrollTo({
    top: 0,
    behavior,
  });
};

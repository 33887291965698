import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';

import {
  UNIVERSAL_PERMANENT_TOOTH_NUMBERS,
  UNIVERSAL_PRIMARY_TOOTH_NUMBERS,
  UniversalToothKey,
  UniversalToothValue,
} from '../config/toothConfig';

export const getDisplayToothNumber = (
  ISONumber: number,
  dentalNotation: DentalNotationFormat = DentalNotationFormat.DentalNotationFormatISO,
): number | UniversalToothValue => {
  if (dentalNotation === DentalNotationFormat.DentalNotationFormatUniversal) {
    const indexInPermanentToothNumbers =
      UNIVERSAL_PERMANENT_TOOTH_NUMBERS.indexOf(ISONumber);

    return indexInPermanentToothNumbers !== -1
      ? indexInPermanentToothNumbers + 1
      : UNIVERSAL_PRIMARY_TOOTH_NUMBERS[ISONumber as UniversalToothKey];
  }

  return ISONumber;
};

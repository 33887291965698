import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import * as Progress from '@radix-ui/react-progress';

import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import clearCat from '@/shared/assets/images/cats/clear.png';
import errorCat from '@/shared/assets/images/cats/error.png';
import { Icon, toastCaller, StatusCatImage } from '@/shared/ui';
import { ReportGenerationErrorCode } from '@/shared/api/protocol_gen/model/dto_report_generation_error_codes';

import { SupportTooltip } from '@/features/supportTooltip';
import {
  getReportGenerationInterval,
  reportGeneratingErrorMessages,
} from '@/features/reportRow';

import { getRelativeProgress } from '../../lib';
import { MAX_PSEUDO_PROGRESS } from '../../config';

import styles from './ReportGenerationStatus.module.scss';

type ReportGenerationStatusProps = {
  report: Report | undefined;
  className?: string;
  testID?: string;
};

export const ReportGenerationStatus: FC<ReportGenerationStatusProps> = (
  props,
) => {
  const { report, className, testID } = props;
  const { formatMessage } = useIntl();

  const [progress, setProgress] = useState<number>(0);

  const reportType = report?.Type;
  const reportStatus = report?.Status;
  const reportCreatedAt = report?.Created?.At;
  const isReportPending = reportStatus?.Pending;
  const isReportInProgress = Boolean(reportStatus?.InProgress);
  const isReportFailed = Boolean(reportStatus?.Failed);
  const reportGenerationErrorCode = reportStatus?.Failed?.Code;

  const isReportGenerating = isReportInProgress || isReportPending;

  const hasReportGenerationError =
    reportGenerationErrorCode !== undefined &&
    Object.values(ReportGenerationErrorCode).includes(
      reportGenerationErrorCode,
    );

  const reportGenerationErrorMessage = hasReportGenerationError
    ? formatMessage(reportGeneratingErrorMessages[reportGenerationErrorCode])
    : '';

  const getStatusImageSrc = () => {
    if (isReportGenerating) {
      return clearCat;
    }

    if (isReportFailed) {
      return errorCat;
    }

    return undefined;
  };

  const getStatusMessage = () => {
    if (isReportGenerating) {
      return formatMessage({
        id: 'reportGenerationStatus.generatingReport',
        defaultMessage: 'Generating report',
      });
    }

    if (isReportFailed) {
      return formatMessage({
        id: 'reportGenerationStatus.reportGenerationError',
        defaultMessage: 'Report generation error',
      });
    }

    return undefined;
  };

  const statusImageSrc = getStatusImageSrc();
  const statusMessage = getStatusMessage();

  const copyErrorMessageToClipBoard = async (copyMe: string) => {
    await navigator.clipboard.writeText(copyMe);

    toastCaller({
      message: formatMessage({
        id: 'global.copiedToClipboard',
        defaultMessage: 'Copied to clipboard',
      }),
      type: 'default',
      heading: '',
      position: 'bottom-right',
    });
  };

  useEffect(() => {
    if (reportType) {
      const pseudoProgress = setInterval(() => {
        const relativeProgress = getRelativeProgress(
          reportType,
          reportCreatedAt,
        );

        if (isReportPending) {
          setProgress(1);
        } else if (relativeProgress < MAX_PSEUDO_PROGRESS) {
          setProgress(relativeProgress);
        } else if (progress !== relativeProgress) {
          clearInterval(pseudoProgress);
        }
      }, getReportGenerationInterval(reportType));

      if (isReportFailed) {
        clearInterval(pseudoProgress);
        setProgress(0);
      }

      return () => clearInterval(pseudoProgress);
    }
  }, [progress, isReportFailed, reportType, reportCreatedAt, isReportPending]);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {statusImageSrc && (
        <StatusCatImage
          statusImageSrc={statusImageSrc}
          altText={statusMessage}
          isLoading={isReportGenerating}
        />
      )}

      {statusMessage && (
        <div className={cn(styles.status, 'p2')}>
          <div className={styles.statusMessage}>
            {statusMessage}

            {isReportFailed && hasReportGenerationError && (
              <SupportTooltip errorMessage={reportGenerationErrorMessage}>
                <Icon
                  name="attention"
                  size={24}
                  className={styles.errorIcon}
                  onClick={() =>
                    copyErrorMessageToClipBoard(reportGenerationErrorMessage)
                  }
                />
              </SupportTooltip>
            )}
          </div>

          {(isReportInProgress || isReportPending) && (
            <Progress.Root
              className={cn(styles.progressBar, className)}
              value={progress}
              max={100}
              data-testid={testID}
            >
              <Progress.Indicator
                className={cn(styles.progressIndicator)}
                style={{ transform: `translateX(-${100 - progress}%)` }}
              />
            </Progress.Root>
          )}
        </div>
      )}
    </div>
  );
};

import { RCImageBase, RCImageBaseProps } from './RCImageBase';
import { repaint } from './repaint';

export interface RCImageProps extends Omit<RCImageBaseProps, "repaint"> {
    angle?: number // degrees
}

export const RCImage = (props: RCImageProps) => {

    return (
        <RCImageBase {...props} repaint={(context, canvas) => {
            repaint({ x: 0, y: 0, w: context.width, h: context.height }, canvas, props.angle)
        }} />
    )
};


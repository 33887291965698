/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSeriesGeometryData,
  StudioColorProfile,
} from '../model/dto_common_geometry';
import {
  ReportStudioToothPosition,
  ReportStudioOriginatedPosition,
} from '../model/dto_report_type_studio_common';
import {
  ToothLandmark,
  CephalometricLandmark,
} from '../model/dto_report_landmark';
import { SegmentronPhotoIOSSuperimposition } from '../model/dto_report_segmentron_common';
import { ToothNumeration } from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportStudioImplant {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
  ColorProfile: StudioColorProfile | undefined;
  TeethPositions: ReportStudioToothPosition[];
  Plans: ReportStudioImplantCrownImplantPlan[];
  TeethLandmarks: ToothLandmark[];
  CephalometricLandmarks: CephalometricLandmark[];
  PhotoIOSSuperimpositions: SegmentronPhotoIOSSuperimposition[];
}

export interface ReportStudioImplantCrownImplantPlan {
  /** `Tooth`: [required] */
  Tooth: ToothNumeration | undefined;
  /** `CrownPlan`: [required] */
  CrownPlan: ReportStudioImplantCrownImplantPlanCrownPlan | undefined;
  /** `ImplantPlan`: [optional] */
  ImplantPlan?: ReportStudioImplantCrownImplantPlanImplantPlan | undefined;
}

export interface ReportStudioImplantCrownImplantPlanCrownPlan {
  /** `Position`: [required] */
  Position: ReportStudioOriginatedPosition | undefined;
  /** >> Asset.ID with AssetType_Report_Generated_Mesh_Model */
  ArtificialCrownAssetID: string;
  Landmarks: ToothLandmark[];
}

export interface ReportStudioImplantCrownImplantPlanImplantPlan {
  /** `Position`: [required] */
  Position: ReportStudioOriginatedPosition | undefined;
  MaximumLength: number;
  MaximumDiameter: number;
}

function createBaseReportStudioImplant(): ReportStudioImplant {
  return {
    CropGeometryData: undefined,
    ColorProfile: undefined,
    TeethPositions: [],
    Plans: [],
    TeethLandmarks: [],
    CephalometricLandmarks: [],
    PhotoIOSSuperimpositions: [],
  };
}

export const ReportStudioImplant = {
  encode(
    message: ReportStudioImplant,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.ColorProfile !== undefined) {
      StudioColorProfile.encode(
        message.ColorProfile,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    for (const v of message.TeethPositions) {
      ReportStudioToothPosition.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.Plans) {
      ReportStudioImplantCrownImplantPlan.encode(
        v!,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    for (const v of message.TeethLandmarks) {
      ToothLandmark.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.CephalometricLandmarks) {
      CephalometricLandmark.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.PhotoIOSSuperimpositions) {
      SegmentronPhotoIOSSuperimposition.encode(
        v!,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportStudioImplant {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioImplant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.ColorProfile = StudioColorProfile.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.TeethPositions.push(
            ReportStudioToothPosition.decode(reader, reader.uint32()),
          );
          break;
        case 5:
          message.Plans.push(
            ReportStudioImplantCrownImplantPlan.decode(reader, reader.uint32()),
          );
          break;
        case 6:
          message.TeethLandmarks.push(
            ToothLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 7:
          message.CephalometricLandmarks.push(
            CephalometricLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 8:
          message.PhotoIOSSuperimpositions.push(
            SegmentronPhotoIOSSuperimposition.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioImplant {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
      ColorProfile: isSet(object.ColorProfile)
        ? StudioColorProfile.fromJSON(object.ColorProfile)
        : undefined,
      TeethPositions: Array.isArray(object?.TeethPositions)
        ? object.TeethPositions.map((e: any) =>
            ReportStudioToothPosition.fromJSON(e),
          )
        : [],
      Plans: Array.isArray(object?.Plans)
        ? object.Plans.map((e: any) =>
            ReportStudioImplantCrownImplantPlan.fromJSON(e),
          )
        : [],
      TeethLandmarks: Array.isArray(object?.TeethLandmarks)
        ? object.TeethLandmarks.map((e: any) => ToothLandmark.fromJSON(e))
        : [],
      CephalometricLandmarks: Array.isArray(object?.CephalometricLandmarks)
        ? object.CephalometricLandmarks.map((e: any) =>
            CephalometricLandmark.fromJSON(e),
          )
        : [],
      PhotoIOSSuperimpositions: Array.isArray(object?.PhotoIOSSuperimpositions)
        ? object.PhotoIOSSuperimpositions.map((e: any) =>
            SegmentronPhotoIOSSuperimposition.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ReportStudioImplant): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    message.ColorProfile !== undefined &&
      (obj.ColorProfile = message.ColorProfile
        ? StudioColorProfile.toJSON(message.ColorProfile)
        : undefined);
    if (message.TeethPositions) {
      obj.TeethPositions = message.TeethPositions.map((e) =>
        e ? ReportStudioToothPosition.toJSON(e) : undefined,
      );
    } else {
      obj.TeethPositions = [];
    }
    if (message.Plans) {
      obj.Plans = message.Plans.map((e) =>
        e ? ReportStudioImplantCrownImplantPlan.toJSON(e) : undefined,
      );
    } else {
      obj.Plans = [];
    }
    if (message.TeethLandmarks) {
      obj.TeethLandmarks = message.TeethLandmarks.map((e) =>
        e ? ToothLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.TeethLandmarks = [];
    }
    if (message.CephalometricLandmarks) {
      obj.CephalometricLandmarks = message.CephalometricLandmarks.map((e) =>
        e ? CephalometricLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.CephalometricLandmarks = [];
    }
    if (message.PhotoIOSSuperimpositions) {
      obj.PhotoIOSSuperimpositions = message.PhotoIOSSuperimpositions.map((e) =>
        e ? SegmentronPhotoIOSSuperimposition.toJSON(e) : undefined,
      );
    } else {
      obj.PhotoIOSSuperimpositions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioImplant>, I>>(
    object: I,
  ): ReportStudioImplant {
    const message = createBaseReportStudioImplant();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    message.ColorProfile =
      object.ColorProfile !== undefined && object.ColorProfile !== null
        ? StudioColorProfile.fromPartial(object.ColorProfile)
        : undefined;
    message.TeethPositions =
      object.TeethPositions?.map((e) =>
        ReportStudioToothPosition.fromPartial(e),
      ) || [];
    message.Plans =
      object.Plans?.map((e) =>
        ReportStudioImplantCrownImplantPlan.fromPartial(e),
      ) || [];
    message.TeethLandmarks =
      object.TeethLandmarks?.map((e) => ToothLandmark.fromPartial(e)) || [];
    message.CephalometricLandmarks =
      object.CephalometricLandmarks?.map((e) =>
        CephalometricLandmark.fromPartial(e),
      ) || [];
    message.PhotoIOSSuperimpositions =
      object.PhotoIOSSuperimpositions?.map((e) =>
        SegmentronPhotoIOSSuperimposition.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseReportStudioImplantCrownImplantPlan(): ReportStudioImplantCrownImplantPlan {
  return { Tooth: undefined, CrownPlan: undefined, ImplantPlan: undefined };
}

export const ReportStudioImplantCrownImplantPlan = {
  encode(
    message: ReportStudioImplantCrownImplantPlan,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      ToothNumeration.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.CrownPlan !== undefined) {
      ReportStudioImplantCrownImplantPlanCrownPlan.encode(
        message.CrownPlan,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.ImplantPlan !== undefined) {
      ReportStudioImplantCrownImplantPlanImplantPlan.encode(
        message.ImplantPlan,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioImplantCrownImplantPlan {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioImplantCrownImplantPlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.CrownPlan =
            ReportStudioImplantCrownImplantPlanCrownPlan.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 3:
          message.ImplantPlan =
            ReportStudioImplantCrownImplantPlanImplantPlan.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioImplantCrownImplantPlan {
    return {
      Tooth: isSet(object.Tooth)
        ? ToothNumeration.fromJSON(object.Tooth)
        : undefined,
      CrownPlan: isSet(object.CrownPlan)
        ? ReportStudioImplantCrownImplantPlanCrownPlan.fromJSON(
            object.CrownPlan,
          )
        : undefined,
      ImplantPlan: isSet(object.ImplantPlan)
        ? ReportStudioImplantCrownImplantPlanImplantPlan.fromJSON(
            object.ImplantPlan,
          )
        : undefined,
    };
  },

  toJSON(message: ReportStudioImplantCrownImplantPlan): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth
        ? ToothNumeration.toJSON(message.Tooth)
        : undefined);
    message.CrownPlan !== undefined &&
      (obj.CrownPlan = message.CrownPlan
        ? ReportStudioImplantCrownImplantPlanCrownPlan.toJSON(message.CrownPlan)
        : undefined);
    message.ImplantPlan !== undefined &&
      (obj.ImplantPlan = message.ImplantPlan
        ? ReportStudioImplantCrownImplantPlanImplantPlan.toJSON(
            message.ImplantPlan,
          )
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportStudioImplantCrownImplantPlan>, I>,
  >(object: I): ReportStudioImplantCrownImplantPlan {
    const message = createBaseReportStudioImplantCrownImplantPlan();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? ToothNumeration.fromPartial(object.Tooth)
        : undefined;
    message.CrownPlan =
      object.CrownPlan !== undefined && object.CrownPlan !== null
        ? ReportStudioImplantCrownImplantPlanCrownPlan.fromPartial(
            object.CrownPlan,
          )
        : undefined;
    message.ImplantPlan =
      object.ImplantPlan !== undefined && object.ImplantPlan !== null
        ? ReportStudioImplantCrownImplantPlanImplantPlan.fromPartial(
            object.ImplantPlan,
          )
        : undefined;
    return message;
  },
};

function createBaseReportStudioImplantCrownImplantPlanCrownPlan(): ReportStudioImplantCrownImplantPlanCrownPlan {
  return { Position: undefined, ArtificialCrownAssetID: '', Landmarks: [] };
}

export const ReportStudioImplantCrownImplantPlanCrownPlan = {
  encode(
    message: ReportStudioImplantCrownImplantPlanCrownPlan,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Position !== undefined) {
      ReportStudioOriginatedPosition.encode(
        message.Position,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.ArtificialCrownAssetID !== '') {
      writer.uint32(18).string(message.ArtificialCrownAssetID);
    }
    for (const v of message.Landmarks) {
      ToothLandmark.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioImplantCrownImplantPlanCrownPlan {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioImplantCrownImplantPlanCrownPlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Position = ReportStudioOriginatedPosition.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.ArtificialCrownAssetID = reader.string();
          break;
        case 3:
          message.Landmarks.push(ToothLandmark.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioImplantCrownImplantPlanCrownPlan {
    return {
      Position: isSet(object.Position)
        ? ReportStudioOriginatedPosition.fromJSON(object.Position)
        : undefined,
      ArtificialCrownAssetID: isSet(object.ArtificialCrownAssetID)
        ? String(object.ArtificialCrownAssetID)
        : '',
      Landmarks: Array.isArray(object?.Landmarks)
        ? object.Landmarks.map((e: any) => ToothLandmark.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportStudioImplantCrownImplantPlanCrownPlan): unknown {
    const obj: any = {};
    message.Position !== undefined &&
      (obj.Position = message.Position
        ? ReportStudioOriginatedPosition.toJSON(message.Position)
        : undefined);
    message.ArtificialCrownAssetID !== undefined &&
      (obj.ArtificialCrownAssetID = message.ArtificialCrownAssetID);
    if (message.Landmarks) {
      obj.Landmarks = message.Landmarks.map((e) =>
        e ? ToothLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.Landmarks = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioImplantCrownImplantPlanCrownPlan>,
      I
    >,
  >(object: I): ReportStudioImplantCrownImplantPlanCrownPlan {
    const message = createBaseReportStudioImplantCrownImplantPlanCrownPlan();
    message.Position =
      object.Position !== undefined && object.Position !== null
        ? ReportStudioOriginatedPosition.fromPartial(object.Position)
        : undefined;
    message.ArtificialCrownAssetID = object.ArtificialCrownAssetID ?? '';
    message.Landmarks =
      object.Landmarks?.map((e) => ToothLandmark.fromPartial(e)) || [];
    return message;
  },
};

function createBaseReportStudioImplantCrownImplantPlanImplantPlan(): ReportStudioImplantCrownImplantPlanImplantPlan {
  return { Position: undefined, MaximumLength: 0, MaximumDiameter: 0 };
}

export const ReportStudioImplantCrownImplantPlanImplantPlan = {
  encode(
    message: ReportStudioImplantCrownImplantPlanImplantPlan,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Position !== undefined) {
      ReportStudioOriginatedPosition.encode(
        message.Position,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.MaximumLength !== 0) {
      writer.uint32(21).float(message.MaximumLength);
    }
    if (message.MaximumDiameter !== 0) {
      writer.uint32(29).float(message.MaximumDiameter);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioImplantCrownImplantPlanImplantPlan {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioImplantCrownImplantPlanImplantPlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Position = ReportStudioOriginatedPosition.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.MaximumLength = reader.float();
          break;
        case 3:
          message.MaximumDiameter = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioImplantCrownImplantPlanImplantPlan {
    return {
      Position: isSet(object.Position)
        ? ReportStudioOriginatedPosition.fromJSON(object.Position)
        : undefined,
      MaximumLength: isSet(object.MaximumLength)
        ? Number(object.MaximumLength)
        : 0,
      MaximumDiameter: isSet(object.MaximumDiameter)
        ? Number(object.MaximumDiameter)
        : 0,
    };
  },

  toJSON(message: ReportStudioImplantCrownImplantPlanImplantPlan): unknown {
    const obj: any = {};
    message.Position !== undefined &&
      (obj.Position = message.Position
        ? ReportStudioOriginatedPosition.toJSON(message.Position)
        : undefined);
    message.MaximumLength !== undefined &&
      (obj.MaximumLength = message.MaximumLength);
    message.MaximumDiameter !== undefined &&
      (obj.MaximumDiameter = message.MaximumDiameter);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioImplantCrownImplantPlanImplantPlan>,
      I
    >,
  >(object: I): ReportStudioImplantCrownImplantPlanImplantPlan {
    const message = createBaseReportStudioImplantCrownImplantPlanImplantPlan();
    message.Position =
      object.Position !== undefined && object.Position !== null
        ? ReportStudioOriginatedPosition.fromPartial(object.Position)
        : undefined;
    message.MaximumLength = object.MaximumLength ?? 0;
    message.MaximumDiameter = object.MaximumDiameter ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

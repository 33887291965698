/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTAnatomy,
  cBCTAnatomyFromJSON,
  cBCTAnatomyToJSON,
} from '../model/dto_common_anatomy';

export const protobufPackage = 'com.diagnocat.model';

export enum Decision {
  NoDecision = 0,
  NegativeDecision = 1,
  PositiveDecision = 2,
  UNRECOGNIZED = -1,
}

export function decisionFromJSON(object: any): Decision {
  switch (object) {
    case 0:
    case 'NoDecision':
      return Decision.NoDecision;
    case 1:
    case 'NegativeDecision':
      return Decision.NegativeDecision;
    case 2:
    case 'PositiveDecision':
      return Decision.PositiveDecision;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Decision.UNRECOGNIZED;
  }
}

export function decisionToJSON(object: Decision): string {
  switch (object) {
    case Decision.NoDecision:
      return 'NoDecision';
    case Decision.NegativeDecision:
      return 'NegativeDecision';
    case Decision.PositiveDecision:
      return 'PositiveDecision';
    case Decision.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum JawType {
  JawType_InvalidVariant = 0,
  JawType_Maxilla = 1,
  JawType_Mandible = 2,
  UNRECOGNIZED = -1,
}

export function jawTypeFromJSON(object: any): JawType {
  switch (object) {
    case 0:
    case 'JawType_InvalidVariant':
      return JawType.JawType_InvalidVariant;
    case 1:
    case 'JawType_Maxilla':
      return JawType.JawType_Maxilla;
    case 2:
    case 'JawType_Mandible':
      return JawType.JawType_Mandible;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return JawType.UNRECOGNIZED;
  }
}

export function jawTypeToJSON(object: JawType): string {
  switch (object) {
    case JawType.JawType_InvalidVariant:
      return 'JawType_InvalidVariant';
    case JawType.JawType_Maxilla:
      return 'JawType_Maxilla';
    case JawType.JawType_Mandible:
      return 'JawType_Mandible';
    case JawType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum IOSAnatomy {
  IOS_Anatomy_InvalidValue = 0,
  IOS_Anatomy_Original_Mandible = 1,
  IOS_Anatomy_Original_Maxilla = 2,
  IOS_Anatomy_GingivaUpper = 3,
  IOS_Anatomy_GingivaLower = 4,
  UNRECOGNIZED = -1,
}

export function iOSAnatomyFromJSON(object: any): IOSAnatomy {
  switch (object) {
    case 0:
    case 'IOS_Anatomy_InvalidValue':
      return IOSAnatomy.IOS_Anatomy_InvalidValue;
    case 1:
    case 'IOS_Anatomy_Original_Mandible':
      return IOSAnatomy.IOS_Anatomy_Original_Mandible;
    case 2:
    case 'IOS_Anatomy_Original_Maxilla':
      return IOSAnatomy.IOS_Anatomy_Original_Maxilla;
    case 3:
    case 'IOS_Anatomy_GingivaUpper':
      return IOSAnatomy.IOS_Anatomy_GingivaUpper;
    case 4:
    case 'IOS_Anatomy_GingivaLower':
      return IOSAnatomy.IOS_Anatomy_GingivaLower;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return IOSAnatomy.UNRECOGNIZED;
  }
}

export function iOSAnatomyToJSON(object: IOSAnatomy): string {
  switch (object) {
    case IOSAnatomy.IOS_Anatomy_InvalidValue:
      return 'IOS_Anatomy_InvalidValue';
    case IOSAnatomy.IOS_Anatomy_Original_Mandible:
      return 'IOS_Anatomy_Original_Mandible';
    case IOSAnatomy.IOS_Anatomy_Original_Maxilla:
      return 'IOS_Anatomy_Original_Maxilla';
    case IOSAnatomy.IOS_Anatomy_GingivaUpper:
      return 'IOS_Anatomy_GingivaUpper';
    case IOSAnatomy.IOS_Anatomy_GingivaLower:
      return 'IOS_Anatomy_GingivaLower';
    case IOSAnatomy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum CBCTToothRootType {
  RootType_InvalidValue = 0,
  /** RootType_Unspecified - if there is no root */
  RootType_Unspecified = 1,
  /** RootType_SingleRoot - "a1" */
  RootType_SingleRoot = 2,
  /** RootType_Buccal - "b" */
  RootType_Buccal = 3,
  /** RootType_Lingual - "l" */
  RootType_Lingual = 4,
  /** RootType_Palatal - "p" */
  RootType_Palatal = 5,
  /** RootType_Mesial - "m" */
  RootType_Mesial = 6,
  /** RootType_Mesial1 - "m1" */
  RootType_Mesial1 = 7,
  /** RootType_Mesial2 - "m2" */
  RootType_Mesial2 = 8,
  /** RootType_MesialBuccal - "mb" */
  RootType_MesialBuccal = 9,
  /** RootType_MesialLingual - "ml" */
  RootType_MesialLingual = 10,
  /** RootType_Distal - "d" */
  RootType_Distal = 11,
  /** RootType_DistalBuccal - "db" */
  RootType_DistalBuccal = 12,
  /** RootType_DistalLingual - "dl" */
  RootType_DistalLingual = 13,
  RootType_Average = 14,
  UNRECOGNIZED = -1,
}

export function cBCTToothRootTypeFromJSON(object: any): CBCTToothRootType {
  switch (object) {
    case 0:
    case 'RootType_InvalidValue':
      return CBCTToothRootType.RootType_InvalidValue;
    case 1:
    case 'RootType_Unspecified':
      return CBCTToothRootType.RootType_Unspecified;
    case 2:
    case 'RootType_SingleRoot':
      return CBCTToothRootType.RootType_SingleRoot;
    case 3:
    case 'RootType_Buccal':
      return CBCTToothRootType.RootType_Buccal;
    case 4:
    case 'RootType_Lingual':
      return CBCTToothRootType.RootType_Lingual;
    case 5:
    case 'RootType_Palatal':
      return CBCTToothRootType.RootType_Palatal;
    case 6:
    case 'RootType_Mesial':
      return CBCTToothRootType.RootType_Mesial;
    case 7:
    case 'RootType_Mesial1':
      return CBCTToothRootType.RootType_Mesial1;
    case 8:
    case 'RootType_Mesial2':
      return CBCTToothRootType.RootType_Mesial2;
    case 9:
    case 'RootType_MesialBuccal':
      return CBCTToothRootType.RootType_MesialBuccal;
    case 10:
    case 'RootType_MesialLingual':
      return CBCTToothRootType.RootType_MesialLingual;
    case 11:
    case 'RootType_Distal':
      return CBCTToothRootType.RootType_Distal;
    case 12:
    case 'RootType_DistalBuccal':
      return CBCTToothRootType.RootType_DistalBuccal;
    case 13:
    case 'RootType_DistalLingual':
      return CBCTToothRootType.RootType_DistalLingual;
    case 14:
    case 'RootType_Average':
      return CBCTToothRootType.RootType_Average;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTToothRootType.UNRECOGNIZED;
  }
}

export function cBCTToothRootTypeToJSON(object: CBCTToothRootType): string {
  switch (object) {
    case CBCTToothRootType.RootType_InvalidValue:
      return 'RootType_InvalidValue';
    case CBCTToothRootType.RootType_Unspecified:
      return 'RootType_Unspecified';
    case CBCTToothRootType.RootType_SingleRoot:
      return 'RootType_SingleRoot';
    case CBCTToothRootType.RootType_Buccal:
      return 'RootType_Buccal';
    case CBCTToothRootType.RootType_Lingual:
      return 'RootType_Lingual';
    case CBCTToothRootType.RootType_Palatal:
      return 'RootType_Palatal';
    case CBCTToothRootType.RootType_Mesial:
      return 'RootType_Mesial';
    case CBCTToothRootType.RootType_Mesial1:
      return 'RootType_Mesial1';
    case CBCTToothRootType.RootType_Mesial2:
      return 'RootType_Mesial2';
    case CBCTToothRootType.RootType_MesialBuccal:
      return 'RootType_MesialBuccal';
    case CBCTToothRootType.RootType_MesialLingual:
      return 'RootType_MesialLingual';
    case CBCTToothRootType.RootType_Distal:
      return 'RootType_Distal';
    case CBCTToothRootType.RootType_DistalBuccal:
      return 'RootType_DistalBuccal';
    case CBCTToothRootType.RootType_DistalLingual:
      return 'RootType_DistalLingual';
    case CBCTToothRootType.RootType_Average:
      return 'RootType_Average';
    case CBCTToothRootType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ToothNumeration {
  ISO: number;
  SupernumeraryIndex: number;
}

export interface ReportCBCTIOSSegment {
  /** filled in by backend */
  ID: string;
  CBCTToothISO: number | undefined;
  IOSToothISO: number | undefined;
  FusedToothISO: number | undefined;
  IOSAnatomy: IOSAnatomy | undefined;
  CBCTAnatomy: CBCTAnatomy | undefined;
}

function createBaseToothNumeration(): ToothNumeration {
  return { ISO: 0, SupernumeraryIndex: 0 };
}

export const ToothNumeration = {
  encode(
    message: ToothNumeration,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ISO !== 0) {
      writer.uint32(8).uint32(message.ISO);
    }
    if (message.SupernumeraryIndex !== 0) {
      writer.uint32(16).uint32(message.SupernumeraryIndex);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ToothNumeration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToothNumeration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ISO = reader.uint32();
          break;
        case 2:
          message.SupernumeraryIndex = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ToothNumeration {
    return {
      ISO: isSet(object.ISO) ? Number(object.ISO) : 0,
      SupernumeraryIndex: isSet(object.SupernumeraryIndex)
        ? Number(object.SupernumeraryIndex)
        : 0,
    };
  },

  toJSON(message: ToothNumeration): unknown {
    const obj: any = {};
    message.ISO !== undefined && (obj.ISO = Math.round(message.ISO));
    message.SupernumeraryIndex !== undefined &&
      (obj.SupernumeraryIndex = Math.round(message.SupernumeraryIndex));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ToothNumeration>, I>>(
    object: I,
  ): ToothNumeration {
    const message = createBaseToothNumeration();
    message.ISO = object.ISO ?? 0;
    message.SupernumeraryIndex = object.SupernumeraryIndex ?? 0;
    return message;
  },
};

function createBaseReportCBCTIOSSegment(): ReportCBCTIOSSegment {
  return {
    ID: '',
    CBCTToothISO: undefined,
    IOSToothISO: undefined,
    FusedToothISO: undefined,
    IOSAnatomy: undefined,
    CBCTAnatomy: undefined,
  };
}

export const ReportCBCTIOSSegment = {
  encode(
    message: ReportCBCTIOSSegment,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.CBCTToothISO !== undefined) {
      writer.uint32(16).uint32(message.CBCTToothISO);
    }
    if (message.IOSToothISO !== undefined) {
      writer.uint32(24).uint32(message.IOSToothISO);
    }
    if (message.FusedToothISO !== undefined) {
      writer.uint32(32).uint32(message.FusedToothISO);
    }
    if (message.IOSAnatomy !== undefined) {
      writer.uint32(40).int32(message.IOSAnatomy);
    }
    if (message.CBCTAnatomy !== undefined) {
      writer.uint32(48).int32(message.CBCTAnatomy);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTIOSSegment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTIOSSegment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.CBCTToothISO = reader.uint32();
          break;
        case 3:
          message.IOSToothISO = reader.uint32();
          break;
        case 4:
          message.FusedToothISO = reader.uint32();
          break;
        case 5:
          message.IOSAnatomy = reader.int32() as any;
          break;
        case 6:
          message.CBCTAnatomy = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTIOSSegment {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      CBCTToothISO: isSet(object.CBCTToothISO)
        ? Number(object.CBCTToothISO)
        : undefined,
      IOSToothISO: isSet(object.IOSToothISO)
        ? Number(object.IOSToothISO)
        : undefined,
      FusedToothISO: isSet(object.FusedToothISO)
        ? Number(object.FusedToothISO)
        : undefined,
      IOSAnatomy: isSet(object.IOSAnatomy)
        ? iOSAnatomyFromJSON(object.IOSAnatomy)
        : undefined,
      CBCTAnatomy: isSet(object.CBCTAnatomy)
        ? cBCTAnatomyFromJSON(object.CBCTAnatomy)
        : undefined,
    };
  },

  toJSON(message: ReportCBCTIOSSegment): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.CBCTToothISO !== undefined &&
      (obj.CBCTToothISO = Math.round(message.CBCTToothISO));
    message.IOSToothISO !== undefined &&
      (obj.IOSToothISO = Math.round(message.IOSToothISO));
    message.FusedToothISO !== undefined &&
      (obj.FusedToothISO = Math.round(message.FusedToothISO));
    message.IOSAnatomy !== undefined &&
      (obj.IOSAnatomy =
        message.IOSAnatomy !== undefined
          ? iOSAnatomyToJSON(message.IOSAnatomy)
          : undefined);
    message.CBCTAnatomy !== undefined &&
      (obj.CBCTAnatomy =
        message.CBCTAnatomy !== undefined
          ? cBCTAnatomyToJSON(message.CBCTAnatomy)
          : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTIOSSegment>, I>>(
    object: I,
  ): ReportCBCTIOSSegment {
    const message = createBaseReportCBCTIOSSegment();
    message.ID = object.ID ?? '';
    message.CBCTToothISO = object.CBCTToothISO ?? undefined;
    message.IOSToothISO = object.IOSToothISO ?? undefined;
    message.FusedToothISO = object.FusedToothISO ?? undefined;
    message.IOSAnatomy = object.IOSAnatomy ?? undefined;
    message.CBCTAnatomy = object.CBCTAnatomy ?? undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { FC } from 'react';

import { SecondaryTooltip } from './components/SecondaryTooltip/SecondaryTooltip';
import { PrimaryTooltip } from './components/PrimaryTooltip/PrimaryTooltip';
import { ThirdTooltip } from './components/ThirdTooltip/ThirdTooltip';

type CompoundedComponent = FC & {
  Primary: typeof PrimaryTooltip;
  Secondary: typeof SecondaryTooltip;
  Third: typeof ThirdTooltip;
};
const Tooltip = {
  Primary: PrimaryTooltip,
  Secondary: SecondaryTooltip,
  Third: ThirdTooltip,
} as CompoundedComponent;

export { Tooltip };

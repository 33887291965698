import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { ConditionGroups, CONDITION_CODES_BY_GROUPS } from '../config/const';

export const getConditionGroupByCode = (conditionCode: ConditionCode) =>
  (Object.keys(CONDITION_CODES_BY_GROUPS) as ConditionGroups[]).find(
    (conditionGroup) =>
      CONDITION_CODES_BY_GROUPS[
        conditionGroup as Exclude<
          ConditionGroups,
          ConditionGroups.mucosalThickening
        >
      ].includes(conditionCode),
  ) as ConditionGroups;

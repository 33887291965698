import { useMemo } from 'react';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { useAppSelector } from '@/shared/hooks';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';
import {
  AssetContentStudyCBCT_CBCTMetadata,
  GeneratedReportAsset,
} from '@/shared/api/protocol_gen/model/dto_asset';

import {
  assetsModel,
  getFileSrc,
  PathologyGroup,
  useGetEndodonticMedicalImages,
} from '@/entities/assets';
import {
  LOWER_JAW_TEETH_ISO_NUMBERS,
  toothModel,
  UPPER_JAW_TEETH_ISO_NUMBERS,
} from '@/entities/tooth';

import { useReport_DEPRECATED } from '@/features/reportStream';

type UsePreviewDocument = {
  isLoading: boolean;
  isReportSigned: boolean;
  teeth?: Tooth[];
  allTeeth: Tooth[];
  report?: Report;
  patient?: Patient;
  CBCTPanoImage: GeneratedReportAsset;
  CBCTPanoImageUrl: string;
  panoImageURL: string;
  endoImageURL: string | undefined;
  endodonticGroups: PathologyGroup[];
  ROI: number[];
  studyMeta: AssetContentStudyCBCT_CBCTMetadata;
  patientFullName: string;
};

export const usePreviewDocument = (options: {
  reportID: string;
  patientID: string;
  showBottomJaw: boolean;
  showTopJaw: boolean;
}): UsePreviewDocument => {
  const { reportID, patientID, showBottomJaw, showTopJaw } = options;

  const {
    report,
    patient,
    isReportLoading,
    isReportSigned,
    ROITeeth,
    isPatientLoading,
    patientFullName,
  } = useReport_DEPRECATED(reportID, patientID);

  const allTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID),
  );

  const ROI = useAppSelector(
    toothModel.selectors.selectISONumbersByReportIDAndROI(reportID),
  );

  const assetByStudyID = useAppSelector(
    assetsModel.selectors.selectAssetByStudyID(report?.SourceStudyIDs[0] ?? ''),
  );

  const panoImage = assetByStudyID?.Study?.PanoramicXRay?.PanoramaImage;

  const panoImageURL = useMemo(
    () => getFileSrc(panoImage?.ID ?? ''),
    [panoImage],
  );

  const CBCTPanoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const CBCTPanoImageID =
    CBCTPanoImage?.CBCTGPPanoramaReformatGeneral?.PanoramaSingle;

  const CBCTPanoImageUrl = useMemo(
    () => getFileSrc(CBCTPanoImageID?.ID ?? ''),
    [CBCTPanoImageID?.ID],
  );

  // Endo
  const endoImage = useAppSelector(
    assetsModel.selectors.selectEndodonticPanoImageByReportID(reportID),
  );

  const endodonticGroups = useGetEndodonticMedicalImages(reportID);

  const endoImageID = endoImage?.CBCTEndoPanoramaSplit?.PanoramaSplit;

  const endoImageURL = useMemo(
    () => getFileSrc(endoImageID?.ID ?? ''),
    [endoImageID?.ID],
  );

  const studyMeta = useAppSelector(
    assetsModel.selectors.selectCBCTMetaDataByStudyID(assetByStudyID?.ID ?? ''),
  );

  const isLoading = isReportLoading || isPatientLoading;

  const filteredTeeth = ROITeeth.filter((tooth) => {
    const ISONumber = tooth?.Numeration?.ISO ?? 0;

    return (
      (showTopJaw && UPPER_JAW_TEETH_ISO_NUMBERS.includes(ISONumber)) ||
      (showBottomJaw && LOWER_JAW_TEETH_ISO_NUMBERS.includes(ISONumber))
    );
  });

  return {
    teeth: filteredTeeth,
    allTeeth,
    report,
    patient,
    CBCTPanoImage: CBCTPanoImage ?? ({} as GeneratedReportAsset),
    CBCTPanoImageUrl,
    panoImageURL,
    endoImageURL,
    endodonticGroups,
    ROI,
    patientFullName,
    isReportSigned: !!isReportSigned,
    studyMeta: studyMeta ?? ({} as AssetContentStudyCBCT_CBCTMetadata),
    isLoading,
  };
};

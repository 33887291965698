import React, { useEffect, useRef } from 'react';

const isOutside = (domRect: DOMRect, e: MouseEvent) => {
  const x = e.clientX - domRect.x;
  const y = e.clientY - domRect.y;
  return x < 0 || y < 0 || x > domRect.width || y > domRect.height;
};

export type OnDown = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  targetIsChild: boolean,
) => void;
export type OnDrag = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  dx: number,
  dy: number,
  outside: boolean,
) => void;
export type OnUp = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  outside: boolean,
) => void;

export type TouchableState =
  | {
      kind: 'down' | 'drag' | 'up';
      x1: number;
      x2: number;
      y1: number;
      y2: number;
    }
  | { kind: 'none' };

export type TouchableLayerProps = {
  onDown?: OnDown;
  onDrag?: OnDrag;
  onUp?: OnUp;
  children?: React.ReactNode;
};

export const TouchableLayer = ({
  onDown,
  onDrag,
  onUp,
  children,
}: TouchableLayerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const domNode = ref.current;

    const state = {
      down: false,
      deltaFlag: false,
      x: 0,
      y: 0,
      downX: 0,
      downY: 0,
    };

    const mousemove = (e: MouseEvent) => {
      if (!onDrag) return;
      const domRect = domNode?.getBoundingClientRect();
      const x = e.clientX - domRect.x;
      const y = e.clientY - domRect.y;
      const dx = state.deltaFlag ? x - state.x : 0;
      const dy = state.deltaFlag ? y - state.y : 0;

      state.x = x;
      state.y = y;
      state.deltaFlag = true;
      onDrag(state.downX, state.downY, x, y, dx, dy, isOutside(domRect, e));
    };

    const mousedown = (e: MouseEvent) => {
      // LMB only
      if (e.button !== 0) return;

      const domRect = domNode?.getBoundingClientRect();

      state.downX = e.clientX - domRect.x;
      state.downY = e.clientY - domRect.y;

      onDown?.(
        state.downX,
        state.downY,
        state.downX,
        state.downY,
        e.target !== domNode,
      );

      window.addEventListener('mousemove', mousemove);
      window.addEventListener(
        'mouseup',
        (e) => {
          state.down = false;

          window.removeEventListener('mousemove', mousemove);

          const domRect = domNode?.getBoundingClientRect();

          onUp?.(
            state.downX,
            state.downY,
            e.clientX - domRect.x,
            e.clientY - domRect.y,
            isOutside(domRect, e),
          );
        },
        { once: true },
      );

      state.down = true;

      state.deltaFlag = false;
    };

    domNode.addEventListener('mousedown', mousedown);

    return () => {
      domNode.removeEventListener('mousedown', mousedown);
    };
  }, [ref, onDown, onDrag, onUp]);

  return (
    <div
      data-name="TouchableLayer"
      ref={ref}
      style={{
        display: 'block',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

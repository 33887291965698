/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSliceOrientation,
  File,
  DerivedDataImage,
  cBCTSliceOrientationFromJSON,
  cBCTSliceOrientationToJSON,
} from '../model/dto_asset_common';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentGeneratedCBCTImplantPanorama {
  Panorama: File | undefined;
  Annotated: boolean;
  Derived: DerivedDataImage | undefined;
}

/**
 * aka "cut"
 * key example cut-3-23
 * key structure "cut-<order>-<total_images>"
 * order maps to CBCT_Implant_PanoramaAnnotated.Cuts guides
 *
 * {"type": "File", "file": {"task_id": "5c23e643-51f6-91a8-62dd-1efbd113a76d", "key": "cut-3-23", "filename": "/tmp/tmp_i_sophg", "content_type": "application/dicom", "size": 49400, "meta": {"image_height": 200, "image_width": 121, "orientation_markers": {"left": "B", "right": "L"}, "image_annotations": {"length": {"data": [{"active": false, "length": 53.75872022286245, "visible": true, "invalidated": true, "handles": {"start": {"x": 98.0, "y": 72.0, "active": false, "highlight": true}, "end": {"x": 45.0, "y": 63.0, "active": false, "highlight": true}, "textBox": {"x": 103.0, "y": 70.5, "active": false, "hasMoved": true, "hasBoundingBox": true, "drawnIndependently": true, "movesIndependently": false, "allowedOutsideImage": false}}}, {"active": false, "length": 57.00476675659348, "visible": true, "invalidated": true, "handles": {"start": {"x": 58.27542167493328, "y": 122.00016157191547, "active": false, "highlight": true}, "end": {"x": 59, "y": 65, "active": false, "highlight": true}, "textBox": {"x": 64, "y": 135.00016157191547, "active": false, "hasMoved": true, "hasBoundingBox": true, "drawnIndependently": true, "movesIndependently": false, "allowedOutsideImage": false}}}]}}, "y_pix": [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 176, 184, 192], "x_pix": [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120], "y_num": [0.0, 2.0, 4.0, 6.0, 8.0, 10.0, 12.0, 14.0, 16.0, 18.0, 20.0, 22.0, 24.0, 26.0, 28.0, 30.0, 32.0, 34.0, 36.0, 38.0, 40.0, 42.0, 44.0, 46.0, 48.0], "x_num": [0.0, 2.0, 4.0, 6.0, 8.0, 10.0, 12.0, 14.0, 16.0, 18.0, 20.0, 22.0, 24.0, 26.0, 28.0, 30.0]}, "type": "File"}, "event": "execution-event"}
 */
export interface AssetContentGeneratedCBCTImplantSlice {
  Slice: File | undefined;
  /** TODO - move to typeSpecific? */
  Order: number;
  TotalImages: number;
  /** maps to `"orientation_markers": {"left": "B", "right": "L"}` */
  RightSideOrient: CBCTSliceOrientation;
  /** maps to `"orientation_markers": {"left": "B", "right": "L"}` */
  LeftSideOrient: CBCTSliceOrientation;
}

function createBaseAssetContentGeneratedCBCTImplantPanorama(): AssetContentGeneratedCBCTImplantPanorama {
  return { Panorama: undefined, Annotated: false, Derived: undefined };
}

export const AssetContentGeneratedCBCTImplantPanorama = {
  encode(
    message: AssetContentGeneratedCBCTImplantPanorama,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Panorama !== undefined) {
      File.encode(message.Panorama, writer.uint32(10).fork()).ldelim();
    }
    if (message.Annotated === true) {
      writer.uint32(16).bool(message.Annotated);
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTImplantPanorama {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTImplantPanorama();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Panorama = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Annotated = reader.bool();
          break;
        case 101:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTImplantPanorama {
    return {
      Panorama: isSet(object.Panorama)
        ? File.fromJSON(object.Panorama)
        : undefined,
      Annotated: isSet(object.Annotated) ? Boolean(object.Annotated) : false,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTImplantPanorama): unknown {
    const obj: any = {};
    message.Panorama !== undefined &&
      (obj.Panorama = message.Panorama
        ? File.toJSON(message.Panorama)
        : undefined);
    message.Annotated !== undefined && (obj.Annotated = message.Annotated);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTImplantPanorama>, I>,
  >(object: I): AssetContentGeneratedCBCTImplantPanorama {
    const message = createBaseAssetContentGeneratedCBCTImplantPanorama();
    message.Panorama =
      object.Panorama !== undefined && object.Panorama !== null
        ? File.fromPartial(object.Panorama)
        : undefined;
    message.Annotated = object.Annotated ?? false;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTImplantSlice(): AssetContentGeneratedCBCTImplantSlice {
  return {
    Slice: undefined,
    Order: 0,
    TotalImages: 0,
    RightSideOrient: 0,
    LeftSideOrient: 0,
  };
}

export const AssetContentGeneratedCBCTImplantSlice = {
  encode(
    message: AssetContentGeneratedCBCTImplantSlice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Slice !== undefined) {
      File.encode(message.Slice, writer.uint32(10).fork()).ldelim();
    }
    if (message.Order !== 0) {
      writer.uint32(40).uint32(message.Order);
    }
    if (message.TotalImages !== 0) {
      writer.uint32(48).uint32(message.TotalImages);
    }
    if (message.RightSideOrient !== 0) {
      writer.uint32(80).int32(message.RightSideOrient);
    }
    if (message.LeftSideOrient !== 0) {
      writer.uint32(88).int32(message.LeftSideOrient);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTImplantSlice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTImplantSlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Slice = File.decode(reader, reader.uint32());
          break;
        case 5:
          message.Order = reader.uint32();
          break;
        case 6:
          message.TotalImages = reader.uint32();
          break;
        case 10:
          message.RightSideOrient = reader.int32() as any;
          break;
        case 11:
          message.LeftSideOrient = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTImplantSlice {
    return {
      Slice: isSet(object.Slice) ? File.fromJSON(object.Slice) : undefined,
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      TotalImages: isSet(object.TotalImages) ? Number(object.TotalImages) : 0,
      RightSideOrient: isSet(object.RightSideOrient)
        ? cBCTSliceOrientationFromJSON(object.RightSideOrient)
        : 0,
      LeftSideOrient: isSet(object.LeftSideOrient)
        ? cBCTSliceOrientationFromJSON(object.LeftSideOrient)
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTImplantSlice): unknown {
    const obj: any = {};
    message.Slice !== undefined &&
      (obj.Slice = message.Slice ? File.toJSON(message.Slice) : undefined);
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    message.TotalImages !== undefined &&
      (obj.TotalImages = Math.round(message.TotalImages));
    message.RightSideOrient !== undefined &&
      (obj.RightSideOrient = cBCTSliceOrientationToJSON(
        message.RightSideOrient,
      ));
    message.LeftSideOrient !== undefined &&
      (obj.LeftSideOrient = cBCTSliceOrientationToJSON(message.LeftSideOrient));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTImplantSlice>, I>,
  >(object: I): AssetContentGeneratedCBCTImplantSlice {
    const message = createBaseAssetContentGeneratedCBCTImplantSlice();
    message.Slice =
      object.Slice !== undefined && object.Slice !== null
        ? File.fromPartial(object.Slice)
        : undefined;
    message.Order = object.Order ?? 0;
    message.TotalImages = object.TotalImages ?? 0;
    message.RightSideOrient = object.RightSideOrient ?? 0;
    message.LeftSideOrient = object.LeftSideOrient ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

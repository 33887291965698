import { MouseEventHandler } from 'react';
import { DropzoneState } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import fileIcon from '../../assets/file.svg';
import folderIcon from '../../assets/folder.svg';

import styles from './UploadStudydropzone.module.scss';

// https://github.com/facebook/react/issues/3468#issuecomment-1031366038
declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

type UploadStudyDropzoneProps = {
  dropzoneState: DropzoneState;
  canUploadFolder?: boolean;
};

export const UploadStudyDropzone = (props: UploadStudyDropzoneProps) => {
  const {
    dropzoneState: { getRootProps, getInputProps, inputRef },
    canUploadFolder = true,
  } = props;

  const handleUploadFolder: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    inputRef.current?.click();
  };

  return (
    <div className={styles.container} {...getRootProps()}>
      <div className={cn(styles.uploadButton)}>
        <input {...getInputProps()} />

        <img src={fileIcon} alt="" />

        <h3 className={cn(styles.title, 'h6')}>
          <FormattedMessage
            id="uploadStudyDropzone.title"
            defaultMessage="Upload files from your computer"
          />
        </h3>

        <p className={cn(styles.text, 'p3')}>
          <FormattedMessage
            id="uploadStudyDropzone.text"
            defaultMessage="Or just  Drag and drop file or folder here"
          />
        </p>
      </div>

      {canUploadFolder && (
        <div className={cn(styles.uploadButton)} onClick={handleUploadFolder}>
          <input
            id="uploadFolderInput"
            {...getInputProps()}
            multiple
            type="file"
            webkitdirectory=""
          />

          <img src={folderIcon} alt="" />

          <h3 className={cn(styles.title, 'h6')}>
            <FormattedMessage
              id="uploadStudyDropzone.titleFolder"
              defaultMessage="Select folder to upload"
            />
          </h3>
        </div>
      )}
    </div>
  );
};

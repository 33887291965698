import { FileWithPath } from 'react-dropzone';

export const checkFormatValidation = (
  files: FileWithPath[],
  acceptedFormats: string[],
  canBeWithoutFormat?: boolean,
) => {
  const isValid = files.every((file) => {
    if (canBeWithoutFormat && !file.path?.includes('.')) {
      return true;
    }

    return acceptedFormats.some((format) => file.path?.includes(format));
  });

  return isValid;
};

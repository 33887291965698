import { useState } from 'react';
import cn from 'classnames';

import { Tooltip } from '@/shared/ui';

import { Row } from './Row';
import styles from './OrganizationRow.module.scss';

type OrganizationRowProps = {
  className?: string;
  organizationName: string;
  initials: string;
  avatarSrc: string;
  onClick: () => void;
  isCurrentOrganization?: boolean;
};

export const OrganizationRow = (props: OrganizationRowProps) => {
  const {
    className,
    organizationName,
    avatarSrc,
    initials,
    onClick,
    isCurrentOrganization,
  } = props;

  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  return (
    <div className={cn(styles.container, 'p2', className)} onClick={onClick}>
      {shouldShowTooltip ? (
        <Tooltip.Primary content={organizationName}>
          <Row
            organizationName={organizationName}
            initials={initials}
            isCurrentOrganization={isCurrentOrganization}
            avatarSrc={avatarSrc}
            showTooltipHandler={setShouldShowTooltip}
          />
        </Tooltip.Primary>
      ) : (
        <Row
          organizationName={organizationName}
          initials={initials}
          isCurrentOrganization={isCurrentOrganization}
          avatarSrc={avatarSrc}
          showTooltipHandler={setShouldShowTooltip}
        />
      )}
    </div>
  );
};

import React, { FC } from 'react';
import cn from 'classnames';

import styles from './Sider.module.scss';

type SiderProps = {
  className?: string;
  testID?: string;
  children?: React.ReactNode;
};

export const Sider: FC<SiderProps> = (props) => {
  const { className, testID, children } = props;

  return (
    <div
      className={cn(styles.container, styles.slider, className)}
      data-testid={testID}
    >
      {children}
    </div>
  );
};

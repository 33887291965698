import { useCallback } from 'react';

import { useAppDispatch } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';

import {
  MedicalImageToolbar,
  ToolbarOptions,
} from '@/features/MedilcalImageToolbar';

export const Toolbar = () => {
  const dispatch = useAppDispatch();

  const handleOpenEditModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TeethNumberingModal,
        data: {},
      }),
    );
  }, [dispatch]);

  const controlsOptions: ToolbarOptions[] = [
    {
      control: 'expand',
    },
    {
      control: 'sharpness',
    },
    {
      control: 'brightness',
    },
    {
      control: 'reset',
      notToggle: true,
    },
    {
      control: 'editNumbers',
      onClick: handleOpenEditModal,
      notToggle: true,
    },
  ];

  return (
    <MedicalImageToolbar
      defaultControl="view"
      controlsOptions={controlsOptions}
    />
  );
};

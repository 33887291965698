import { LocalizationWithISO } from '@/entities/tooth';

// NOTE: 404 - this is a tooth that has been deleted
export const checkDuplicatesInLocalizations = (
  localizations: LocalizationWithISO[],
): boolean =>
  localizations.some(
    (localization, index, array) =>
      localization.ISONumber !== 404 &&
      array.findIndex((other) => other.ISONumber === localization.ISONumber) !==
        index,
  );

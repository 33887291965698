import { useContext, useState } from 'react';
import { ObjectFitContaintContext } from '../ObjectFitContain';
import { cssToLogical } from './csstoLogical';

export const CircleHandleHelper = ({
  x,
  y,
  onDown,
}: {
  x: number;
  y: number;
  onDown: () => void;
}) => {

  const objectFit = useContext(ObjectFitContaintContext);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <circle
        opacity={visible ? 1 : 0}
        cx={x}
        cy={y}
        r={cssToLogical(6, objectFit)}
        fill="none"
        stroke="white"
        strokeWidth={cssToLogical(3, objectFit)}
        style={{ pointerEvents: 'all' }}
        onMouseEnter={() => {
          setVisible(true);
        }}
        onMouseLeave={() => {
          setVisible(false);
        }}
        onMouseDown={() => {
          onDown();
        }}
      />
    </>
  );
};

import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import { object, number, boolean, InferType } from '@/shared/config/yup';

export const uploadStudySchema = object({
  createReportAfterLoading: boolean(),
  studyAssetType: number()
    .required()
    .oneOf([
      AssetType.AssetType_Study_CBCT,
      AssetType.AssetType_Document,
      AssetType.AssetType_Study_IntraoralXRay,
      AssetType.AssetType_Study_DentalPhoto,
      AssetType.AssetType_Study_PanoramicXRay,
      AssetType.AssetType_Study_IOS_Meshes,
    ]),
});

export type UploadStudyPayload = InferType<typeof uploadStudySchema>;

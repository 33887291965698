// TODO: Resolve schema types issue
import { FC, useEffect } from 'react';
import cn from 'classnames';
import { equals } from 'ramda';
import { Controller, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import {
  Button,
  Checkbox,
  InlineFormError,
  Input,
  InputPassword,
  Layout,
  Modal,
  Select,
} from '@/shared/ui/';
import { LocalStorageKey, PATHS, UserBadRequestError } from '@/shared/config';
import { useLanguages } from '@/shared/hooks/useLanguages';
import { trackPageView } from '@/shared/lib';

import { useCountries } from '@/entities/organization';

import { CongratsModal } from '@/widgets/CongratsModal';
import { CustomPasswordError } from '@/widgets/CustomPasswordError';

import styles from './SignUp.module.scss';
import { useSignUp } from './hooks/useSignUp';
import { SignUpFormDataType } from './config/types';

type SignUpProps = {
  className?: string;
  testID?: string;
};

export const SignUp: FC<SignUpProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();
  const location = useLocation();

  // type casting is necessary for typification location.state
  const invitationToken = (location?.state as { invitationToken: string })
    ?.invitationToken;

  const {
    isInviteFromOrganization,
    isInviteFromDoctor,
    isInviteFromSalesman,
    invitingOrganizationName,
    isLoading,
    sharedDoctorName,
    userLinksAgreements,
    signUpHandle,
    signUpViaOrganizationInviteHandle,
    signUpViaSharedPatientHandle,
    saveSignUpFormDataHandle,
    setSelectCountry,
    navigateToFinishRegistration,
    hookFormData,
  } = useSignUp(invitationToken);

  // If this is an invite through a salesman, then to complete the registration, we proceed to FinishRegistration
  useEffect(() => {
    if (isInviteFromSalesman) {
      navigateToFinishRegistration();
    }
  }, [isInviteFromSalesman]);

  const {
    agreementOffer,
    personalDataProcessing,
    termsAndConditions,
    dataProcessingAddendum,
    privacyPolicy,
  } = userLinksAgreements;

  const isInvite = isInviteFromOrganization || isInviteFromDoctor;

  const {
    handleSubmit,
    control,
    formState: { isSubmitted, errors },
    reset,
    resetField,
    setValue,
  } = hookFormData;

  const { languageOptions } = useLanguages();

  const countryOptions = useCountries({ control });

  const onSubmit: SubmitHandler<SignUpFormDataType> = async (data) => {
    const {
      email = '',
      country = '',
      language,
      firstName,
      lastName,
      phoneNumber,
      password = '',
    } = data;

    if (isInviteFromOrganization) {
      signUpViaOrganizationInviteHandle({
        firstName,
        lastName,
        password,
        language,
      });
    } else if (isInviteFromDoctor) {
      signUpViaSharedPatientHandle({
        firstName,
        lastName,
        password,
        language,
        country,
      });
    } else {
      signUpHandle({
        email,
        country,
        language,
        firstName,
        lastName,
        phoneNumber,
      });
    }
  };

  // Recovery data from localStorage after returning to the page
  useEffect(() => {
    trackPageView('SignUp Page Viewed');

    const savedSignUpFormData = JSON.parse(
      localStorage.getItem(LocalStorageKey.signUpFormData) ?? '{}',
    );

    if (savedSignUpFormData) {
      reset(savedSignUpFormData);
    }

    if (savedSignUpFormData?.country) {
      setSelectCountry(savedSignUpFormData.country);
    }
  }, []);

  useEffect(() => {
    // TODO: with default selecting US-en, until we will support other languages
    if (languageOptions.length) {
      const enUSOption = languageOptions.find(
        (option) => option.value === 'en-US',
      ) ?? { value: 'en-US' };

      saveSignUpFormDataHandle('language', enUSOption?.value);
      setValue('language', enUSOption?.value);
    }
  }, [languageOptions]);

  const isEmailTakenError =
    errors?.email && equals(errors.email.type, UserBadRequestError.EMAIL_TAKEN);

  return (
    <Layout>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            <Modal
              containerClassName={styles.container}
              shouldRenderCloseIconButton={false}
              title={formatMessage({
                id: 'signUp.signUp',
                defaultMessage: 'Sign Up',
              })}
              isOpen
              onCancel={() => {}}
              footer={
                <div
                  className={cn(
                    styles.modalFooter,
                    isInvite && styles.onlyButtonInFooter,
                  )}
                >
                  {!isInvite && (
                    <div>
                      <p className="p2">
                        <FormattedMessage
                          id="signUp.alreadyHaveAnAccount"
                          defaultMessage="Already have an account?"
                        />
                      </p>

                      <Link className={cn(styles.link, 'p2')} to={PATHS.signIn}>
                        <FormattedMessage
                          id="signUp.signIn"
                          defaultMessage="Sign in"
                        />
                      </Link>
                    </div>
                  )}

                  <Button
                    className={styles.submitButton}
                    type="submit"
                    form="signupform"
                    onClick={handleSubmit(onSubmit)}
                    loading={isLoading}
                  >
                    <FormattedMessage
                      id="signUp.submit"
                      defaultMessage="Sign up"
                    />
                  </Button>
                </div>
              }
            >
              {isInvite && (
                <div className={cn(styles.inviteSignUpTitle, 'p2')}>
                  {isInviteFromOrganization && (
                    <FormattedMessage
                      id="signUp.inviteFromOrganizationTitle"
                      defaultMessage="Invitation from {invitingOrganizationName}"
                      values={{
                        invitingOrganizationName,
                      }}
                    />
                  )}
                  {isInviteFromDoctor && (
                    <FormattedMessage
                      id="signUp.inviteFromDoctorTitle"
                      defaultMessage="{sharedDoctorName} granted access to the patient profile. Create a Diagnocat account for review"
                      values={{
                        sharedDoctorName,
                      }}
                    />
                  )}
                </div>
              )}

              <form
                id="signupform"
                onSubmit={handleSubmit(onSubmit)}
                className={styles.form}
              >
                <div className={styles.row}>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        onBlur={() => {
                          if (isSubmitted) {
                            onBlur();
                          }
                        }}
                        onChange={(event) => {
                          if (!isSubmitted) {
                            setValue(name, event.target.value);
                          } else {
                            onChange(event);
                          }
                          saveSignUpFormDataHandle(name, event.target.value);
                        }}
                        type="text"
                        label={formatMessage({
                          id: 'signUp.firstName',
                          defaultMessage: 'First name',
                        })}
                        error={error?.message}
                        inputMode="text"
                        autoComplete="given-name"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="lastName"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        onBlur={() => {
                          if (isSubmitted) {
                            onBlur();
                          }
                        }}
                        onChange={(event) => {
                          if (!isSubmitted) {
                            setValue(name, event.target.value);
                          } else {
                            onChange(event);
                          }
                          saveSignUpFormDataHandle(name, event.target.value);
                        }}
                        type="text"
                        label={formatMessage({
                          id: 'signUp.lastName',
                          defaultMessage: 'Last name',
                        })}
                        error={error?.message}
                        inputMode="text"
                        autoComplete="family-name"
                      />
                    )}
                  />
                </div>

                {!isInvite && (
                  <p className="p2">
                    <FormattedMessage
                      id="signUp.accessEmailDescription"
                      defaultMessage="Enter email for your company you will use to access Diagnocat"
                    />
                  </p>
                )}

                {isInvite ? (
                  <div className={styles.row}>
                    <Controller
                      control={control}
                      name="password"
                      render={({
                        field: { ref, value, name, onBlur, onChange },
                        fieldState: { error },
                      }) => (
                        <div className={styles.passwordInput}>
                          <InputPassword
                            required
                            ref={ref}
                            value={value}
                            name={name}
                            inputMode="text"
                            onBlur={onBlur}
                            onChange={onChange}
                            label={formatMessage({
                              id: 'global.password',
                              defaultMessage: 'Password',
                            })}
                            autoComplete="new-password"
                          />
                          {error && (
                            <CustomPasswordError inputValue={value ?? ''} />
                          )}
                        </div>
                      )}
                    />

                    <Controller
                      control={control}
                      name="confirmPassword"
                      render={({
                        field: { ref, value, name, onBlur, onChange },
                        fieldState: { error },
                      }) => (
                        <InputPassword
                          required
                          ref={ref}
                          value={value}
                          name={name}
                          inputMode="text"
                          onBlur={onBlur}
                          onChange={onChange}
                          label={formatMessage({
                            id: 'finishRegistration.confirmPassword',
                            defaultMessage: 'Confirm password',
                          })}
                          error={error?.message}
                          autoComplete="new-password"
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div className={styles.row}>
                    <Controller
                      control={control}
                      name="email"
                      render={({
                        field: { ref, value, name, onBlur, onChange },
                        fieldState: { error, isTouched },
                      }) => (
                        <Input
                          required
                          ref={ref}
                          value={value}
                          name={name}
                          inputMode="email"
                          type="email"
                          onBlur={onBlur}
                          onChange={(event) => {
                            if (!isTouched) {
                              setValue(name, event.target.value);
                            } else {
                              onChange(event);
                            }
                          }}
                          label={formatMessage({
                            id: 'signUp.email',
                            defaultMessage: 'Company email',
                          })}
                          error={isEmailTakenError ? ' ' : error?.message}
                          autoComplete="email"
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="confirmEmail"
                      render={({
                        field: { ref, value, name, onBlur, onChange },
                        fieldState: { error, isTouched },
                      }) => (
                        <Input
                          required
                          ref={ref}
                          value={value}
                          name={name}
                          inputMode="email"
                          type="email"
                          onBlur={onBlur}
                          onChange={(event) => {
                            if (!isSubmitted && !isTouched) {
                              setValue(name, event.target.value);
                            } else {
                              onChange(event);
                            }
                            saveSignUpFormDataHandle(name, event.target.value);
                          }}
                          label={formatMessage({
                            id: 'signUp.confirmEmail',
                            defaultMessage: 'Confirm email',
                          })}
                          error={error?.message}
                          autoComplete="email"
                        />
                      )}
                    />
                  </div>
                )}

                <div className={styles.row}>
                  {(!isInvite || isInviteFromDoctor) && (
                    <Controller
                      control={control}
                      name="country"
                      render={({
                        field: { ref, value, name, onBlur, onChange },
                        fieldState: { error },
                      }) => (
                        <Select
                          label={formatMessage({
                            id: 'signUp.chooseYourCountry',
                            defaultMessage: 'Choose your country',
                          })}
                          options={countryOptions}
                          isRequired
                          ref={ref}
                          value={`${value}`}
                          name={name}
                          onBlur={onBlur}
                          onChange={(country) => {
                            saveSignUpFormDataHandle(name, country as string);

                            setSelectCountry(country as string);

                            // When changing the country, we reset the checkboxes
                            resetField('firstAgreements');
                            resetField('secondAgreements');

                            onChange(country);
                          }}
                          error={error?.message}
                        />
                      )}
                    />
                  )}

                  <Controller
                    control={control}
                    name="language"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Select
                        label={formatMessage({
                          id: 'signUp.chooseYourLanguage',
                          defaultMessage: 'Choose your language',
                        })}
                        options={languageOptions}
                        isRequired
                        ref={ref}
                        value={`${value}`}
                        name={name}
                        onBlur={onBlur}
                        onChange={(language) => {
                          saveSignUpFormDataHandle(name, language as string);

                          onChange(language);
                        }}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
                {!isInvite && (
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        ref={ref}
                        value={value}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        type="text"
                        label={formatMessage({
                          id: 'global.phoneNumber',
                          defaultMessage: 'Phone number',
                        })}
                        error={error?.message}
                        inputMode="tel"
                        autoComplete="tel"
                      />
                    )}
                  />
                )}

                <div className={styles.checkboxWrapper}>
                  <Controller
                    control={control}
                    name="firstAgreements"
                    render={({
                      field: { onChange, value = false, name },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        error={error?.message}
                        name={name}
                        onChange={onChange}
                        checked={value}
                        required
                      >
                        <div>
                          <FormattedMessage
                            id="signUp.iConfirm"
                            defaultMessage="I confirm, I am a registered/licensed dental professional and I agree with"
                          />{' '}
                          {termsAndConditions && (
                            <a
                              className={styles.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={termsAndConditions}
                            >
                              <FormattedMessage
                                id="signUp.termsAndConditions"
                                defaultMessage="Terms and conditions"
                              />
                            </a>
                          )}
                          {agreementOffer && (
                            <a
                              className={styles.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={agreementOffer}
                            >
                              <FormattedMessage
                                id="signUp.agreementOffer"
                                defaultMessage="License agreement-offer"
                              />
                            </a>
                          )}{' '}
                          {dataProcessingAddendum && (
                            <>
                              <FormattedMessage
                                id="signUp.and"
                                defaultMessage="and"
                              />{' '}
                              <a
                                className={styles.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={dataProcessingAddendum}
                              >
                                <FormattedMessage
                                  id="signUp.dataProcessingAddendum"
                                  defaultMessage="Data Processing Addendum"
                                />
                              </a>
                            </>
                          )}
                        </div>
                      </Checkbox>
                    )}
                  />

                  <Controller
                    control={control}
                    name="secondAgreements"
                    render={({
                      field: { onChange, value = false, name },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        error={error?.message}
                        name={name}
                        onChange={onChange}
                        checked={value}
                        required
                      >
                        <div>
                          <FormattedMessage
                            id="signUp.iAgree"
                            defaultMessage="I agree with"
                          />{' '}
                          {privacyPolicy && (
                            <a
                              className={styles.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={privacyPolicy}
                            >
                              <FormattedMessage
                                id="signUp.thePrivacyPolicy"
                                defaultMessage="The Privacy Policy"
                              />
                            </a>
                          )}
                          {personalDataProcessing && (
                            <a
                              className={styles.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={personalDataProcessing}
                            >
                              <FormattedMessage
                                id="signUp.personalDataProcessing"
                                defaultMessage="Terms of personal data processing"
                              />
                            </a>
                          )}
                        </div>
                      </Checkbox>
                    )}
                  />

                  {isInvite && (
                    <Controller
                      control={control}
                      name="marketingCommunicationConsent"
                      render={({
                        field: { onChange, value = false, name },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          error={error?.message}
                          name={name}
                          onChange={onChange}
                          checked={value}
                        >
                          <FormattedMessage
                            id="signUp.marketingCommunicationConsent"
                            defaultMessage="I agree that DGNCT LLC and its affiliates (hereinafter – Diagnocat) may contact me by e-mail, mobile phone or text messages with information about the products and services of Diagnocat, which may include special offers and feedback requests."
                          />
                        </Checkbox>
                      )}
                    />
                  )}
                </div>

                {isEmailTakenError && (
                  <InlineFormError
                    className={styles.inlineFormError}
                    errorMessage={errors.email?.message}
                  />
                )}
              </form>
            </Modal>
          </div>
        </Layout.Main>
      </Layout.Content>

      <CongratsModal />
    </Layout>
  );
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.backbone';

export interface UserInfo {
  UserID: string;
  OrganizationID: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Country: string;
  Language: string;
  BillingZone: string;
}

export interface DoctorInfo {
  OrganizationParticipantID: string;
  UserID: string;
  OrganizationID: string;
  FirstName: string;
  LastName: string;
  SpecializationLegacy: string;
  Email: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface PatientInfo {
  ID: string;
  /** patient.patient_id */
  ManualID: string;
  FirstName: string;
  LastName: string;
  BirthDate: string;
  Gender: string;
  Status: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface ReportInfo {
  ID: string;
  Type: string;
  CreatedAt: string;
  UpdatedAt: string;
  LegacyReportID: string;
}

export interface StudyInfo {
  ID: string;
  Type: string;
  DateOfStudy: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface ProjectInfo {
  ID: string;
  Active: boolean;
  CaseDescription: string;
  DueDate: string;
  CreatedAt: string;
  ClosedAt: string;
}

export interface ProjectTaskInfo {
  ID: string;
  Creator: DoctorInfo | undefined;
  Project: ProjectInfo | undefined;
  Title: string;
  Description: string;
  Status: string;
  DueDate: string;
  Assignments: ProjectTaskAssignment[];
}

export interface ProjectTaskAssignment {
  Assigner: DoctorInfo | undefined;
  Assignee: DoctorInfo | undefined;
}

function createBaseUserInfo(): UserInfo {
  return {
    UserID: '',
    OrganizationID: '',
    FirstName: '',
    LastName: '',
    Email: '',
    Country: '',
    Language: '',
    BillingZone: '',
  };
}

export const UserInfo = {
  encode(
    message: UserInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.FirstName !== '') {
      writer.uint32(26).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(34).string(message.LastName);
    }
    if (message.Email !== '') {
      writer.uint32(42).string(message.Email);
    }
    if (message.Country !== '') {
      writer.uint32(50).string(message.Country);
    }
    if (message.Language !== '') {
      writer.uint32(58).string(message.Language);
    }
    if (message.BillingZone !== '') {
      writer.uint32(66).string(message.BillingZone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.FirstName = reader.string();
          break;
        case 4:
          message.LastName = reader.string();
          break;
        case 5:
          message.Email = reader.string();
          break;
        case 6:
          message.Country = reader.string();
          break;
        case 7:
          message.Language = reader.string();
          break;
        case 8:
          message.BillingZone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserInfo {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
      Country: isSet(object.Country) ? String(object.Country) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
      BillingZone: isSet(object.BillingZone) ? String(object.BillingZone) : '',
    };
  },

  toJSON(message: UserInfo): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.Email !== undefined && (obj.Email = message.Email);
    message.Country !== undefined && (obj.Country = message.Country);
    message.Language !== undefined && (obj.Language = message.Language);
    message.BillingZone !== undefined &&
      (obj.BillingZone = message.BillingZone);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UserInfo>, I>>(object: I): UserInfo {
    const message = createBaseUserInfo();
    message.UserID = object.UserID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Email = object.Email ?? '';
    message.Country = object.Country ?? '';
    message.Language = object.Language ?? '';
    message.BillingZone = object.BillingZone ?? '';
    return message;
  },
};

function createBaseDoctorInfo(): DoctorInfo {
  return {
    OrganizationParticipantID: '',
    UserID: '',
    OrganizationID: '',
    FirstName: '',
    LastName: '',
    SpecializationLegacy: '',
    Email: '',
    CreatedAt: '',
    UpdatedAt: '',
  };
}

export const DoctorInfo = {
  encode(
    message: DoctorInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationParticipantID !== '') {
      writer.uint32(10).string(message.OrganizationParticipantID);
    }
    if (message.UserID !== '') {
      writer.uint32(18).string(message.UserID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(74).string(message.OrganizationID);
    }
    if (message.FirstName !== '') {
      writer.uint32(26).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(34).string(message.LastName);
    }
    if (message.SpecializationLegacy !== '') {
      writer.uint32(42).string(message.SpecializationLegacy);
    }
    if (message.Email !== '') {
      writer.uint32(50).string(message.Email);
    }
    if (message.CreatedAt !== '') {
      writer.uint32(58).string(message.CreatedAt);
    }
    if (message.UpdatedAt !== '') {
      writer.uint32(66).string(message.UpdatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DoctorInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoctorInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationParticipantID = reader.string();
          break;
        case 2:
          message.UserID = reader.string();
          break;
        case 9:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.FirstName = reader.string();
          break;
        case 4:
          message.LastName = reader.string();
          break;
        case 5:
          message.SpecializationLegacy = reader.string();
          break;
        case 6:
          message.Email = reader.string();
          break;
        case 7:
          message.CreatedAt = reader.string();
          break;
        case 8:
          message.UpdatedAt = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DoctorInfo {
    return {
      OrganizationParticipantID: isSet(object.OrganizationParticipantID)
        ? String(object.OrganizationParticipantID)
        : '',
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      SpecializationLegacy: isSet(object.SpecializationLegacy)
        ? String(object.SpecializationLegacy)
        : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
      CreatedAt: isSet(object.CreatedAt) ? String(object.CreatedAt) : '',
      UpdatedAt: isSet(object.UpdatedAt) ? String(object.UpdatedAt) : '',
    };
  },

  toJSON(message: DoctorInfo): unknown {
    const obj: any = {};
    message.OrganizationParticipantID !== undefined &&
      (obj.OrganizationParticipantID = message.OrganizationParticipantID);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.SpecializationLegacy !== undefined &&
      (obj.SpecializationLegacy = message.SpecializationLegacy);
    message.Email !== undefined && (obj.Email = message.Email);
    message.CreatedAt !== undefined && (obj.CreatedAt = message.CreatedAt);
    message.UpdatedAt !== undefined && (obj.UpdatedAt = message.UpdatedAt);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DoctorInfo>, I>>(
    object: I,
  ): DoctorInfo {
    const message = createBaseDoctorInfo();
    message.OrganizationParticipantID = object.OrganizationParticipantID ?? '';
    message.UserID = object.UserID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.SpecializationLegacy = object.SpecializationLegacy ?? '';
    message.Email = object.Email ?? '';
    message.CreatedAt = object.CreatedAt ?? '';
    message.UpdatedAt = object.UpdatedAt ?? '';
    return message;
  },
};

function createBasePatientInfo(): PatientInfo {
  return {
    ID: '',
    ManualID: '',
    FirstName: '',
    LastName: '',
    BirthDate: '',
    Gender: '',
    Status: '',
    CreatedAt: '',
    UpdatedAt: '',
  };
}

export const PatientInfo = {
  encode(
    message: PatientInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.ManualID !== '') {
      writer.uint32(18).string(message.ManualID);
    }
    if (message.FirstName !== '') {
      writer.uint32(26).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(34).string(message.LastName);
    }
    if (message.BirthDate !== '') {
      writer.uint32(42).string(message.BirthDate);
    }
    if (message.Gender !== '') {
      writer.uint32(50).string(message.Gender);
    }
    if (message.Status !== '') {
      writer.uint32(58).string(message.Status);
    }
    if (message.CreatedAt !== '') {
      writer.uint32(66).string(message.CreatedAt);
    }
    if (message.UpdatedAt !== '') {
      writer.uint32(74).string(message.UpdatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.ManualID = reader.string();
          break;
        case 3:
          message.FirstName = reader.string();
          break;
        case 4:
          message.LastName = reader.string();
          break;
        case 5:
          message.BirthDate = reader.string();
          break;
        case 6:
          message.Gender = reader.string();
          break;
        case 7:
          message.Status = reader.string();
          break;
        case 8:
          message.CreatedAt = reader.string();
          break;
        case 9:
          message.UpdatedAt = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientInfo {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      ManualID: isSet(object.ManualID) ? String(object.ManualID) : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      BirthDate: isSet(object.BirthDate) ? String(object.BirthDate) : '',
      Gender: isSet(object.Gender) ? String(object.Gender) : '',
      Status: isSet(object.Status) ? String(object.Status) : '',
      CreatedAt: isSet(object.CreatedAt) ? String(object.CreatedAt) : '',
      UpdatedAt: isSet(object.UpdatedAt) ? String(object.UpdatedAt) : '',
    };
  },

  toJSON(message: PatientInfo): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.ManualID !== undefined && (obj.ManualID = message.ManualID);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.BirthDate !== undefined && (obj.BirthDate = message.BirthDate);
    message.Gender !== undefined && (obj.Gender = message.Gender);
    message.Status !== undefined && (obj.Status = message.Status);
    message.CreatedAt !== undefined && (obj.CreatedAt = message.CreatedAt);
    message.UpdatedAt !== undefined && (obj.UpdatedAt = message.UpdatedAt);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientInfo>, I>>(
    object: I,
  ): PatientInfo {
    const message = createBasePatientInfo();
    message.ID = object.ID ?? '';
    message.ManualID = object.ManualID ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.BirthDate = object.BirthDate ?? '';
    message.Gender = object.Gender ?? '';
    message.Status = object.Status ?? '';
    message.CreatedAt = object.CreatedAt ?? '';
    message.UpdatedAt = object.UpdatedAt ?? '';
    return message;
  },
};

function createBaseReportInfo(): ReportInfo {
  return { ID: '', Type: '', CreatedAt: '', UpdatedAt: '', LegacyReportID: '' };
}

export const ReportInfo = {
  encode(
    message: ReportInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Type !== '') {
      writer.uint32(18).string(message.Type);
    }
    if (message.CreatedAt !== '') {
      writer.uint32(26).string(message.CreatedAt);
    }
    if (message.UpdatedAt !== '') {
      writer.uint32(34).string(message.UpdatedAt);
    }
    if (message.LegacyReportID !== '') {
      writer.uint32(802).string(message.LegacyReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Type = reader.string();
          break;
        case 3:
          message.CreatedAt = reader.string();
          break;
        case 4:
          message.UpdatedAt = reader.string();
          break;
        case 100:
          message.LegacyReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportInfo {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Type: isSet(object.Type) ? String(object.Type) : '',
      CreatedAt: isSet(object.CreatedAt) ? String(object.CreatedAt) : '',
      UpdatedAt: isSet(object.UpdatedAt) ? String(object.UpdatedAt) : '',
      LegacyReportID: isSet(object.LegacyReportID)
        ? String(object.LegacyReportID)
        : '',
    };
  },

  toJSON(message: ReportInfo): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Type !== undefined && (obj.Type = message.Type);
    message.CreatedAt !== undefined && (obj.CreatedAt = message.CreatedAt);
    message.UpdatedAt !== undefined && (obj.UpdatedAt = message.UpdatedAt);
    message.LegacyReportID !== undefined &&
      (obj.LegacyReportID = message.LegacyReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportInfo>, I>>(
    object: I,
  ): ReportInfo {
    const message = createBaseReportInfo();
    message.ID = object.ID ?? '';
    message.Type = object.Type ?? '';
    message.CreatedAt = object.CreatedAt ?? '';
    message.UpdatedAt = object.UpdatedAt ?? '';
    message.LegacyReportID = object.LegacyReportID ?? '';
    return message;
  },
};

function createBaseStudyInfo(): StudyInfo {
  return { ID: '', Type: '', DateOfStudy: '', CreatedAt: '', UpdatedAt: '' };
}

export const StudyInfo = {
  encode(
    message: StudyInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Type !== '') {
      writer.uint32(18).string(message.Type);
    }
    if (message.DateOfStudy !== '') {
      writer.uint32(26).string(message.DateOfStudy);
    }
    if (message.CreatedAt !== '') {
      writer.uint32(34).string(message.CreatedAt);
    }
    if (message.UpdatedAt !== '') {
      writer.uint32(42).string(message.UpdatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StudyInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStudyInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Type = reader.string();
          break;
        case 3:
          message.DateOfStudy = reader.string();
          break;
        case 4:
          message.CreatedAt = reader.string();
          break;
        case 5:
          message.UpdatedAt = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StudyInfo {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Type: isSet(object.Type) ? String(object.Type) : '',
      DateOfStudy: isSet(object.DateOfStudy) ? String(object.DateOfStudy) : '',
      CreatedAt: isSet(object.CreatedAt) ? String(object.CreatedAt) : '',
      UpdatedAt: isSet(object.UpdatedAt) ? String(object.UpdatedAt) : '',
    };
  },

  toJSON(message: StudyInfo): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Type !== undefined && (obj.Type = message.Type);
    message.DateOfStudy !== undefined &&
      (obj.DateOfStudy = message.DateOfStudy);
    message.CreatedAt !== undefined && (obj.CreatedAt = message.CreatedAt);
    message.UpdatedAt !== undefined && (obj.UpdatedAt = message.UpdatedAt);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StudyInfo>, I>>(
    object: I,
  ): StudyInfo {
    const message = createBaseStudyInfo();
    message.ID = object.ID ?? '';
    message.Type = object.Type ?? '';
    message.DateOfStudy = object.DateOfStudy ?? '';
    message.CreatedAt = object.CreatedAt ?? '';
    message.UpdatedAt = object.UpdatedAt ?? '';
    return message;
  },
};

function createBaseProjectInfo(): ProjectInfo {
  return {
    ID: '',
    Active: false,
    CaseDescription: '',
    DueDate: '',
    CreatedAt: '',
    ClosedAt: '',
  };
}

export const ProjectInfo = {
  encode(
    message: ProjectInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Active === true) {
      writer.uint32(16).bool(message.Active);
    }
    if (message.CaseDescription !== '') {
      writer.uint32(26).string(message.CaseDescription);
    }
    if (message.DueDate !== '') {
      writer.uint32(34).string(message.DueDate);
    }
    if (message.CreatedAt !== '') {
      writer.uint32(42).string(message.CreatedAt);
    }
    if (message.ClosedAt !== '') {
      writer.uint32(50).string(message.ClosedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Active = reader.bool();
          break;
        case 3:
          message.CaseDescription = reader.string();
          break;
        case 4:
          message.DueDate = reader.string();
          break;
        case 5:
          message.CreatedAt = reader.string();
          break;
        case 6:
          message.ClosedAt = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProjectInfo {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Active: isSet(object.Active) ? Boolean(object.Active) : false,
      CaseDescription: isSet(object.CaseDescription)
        ? String(object.CaseDescription)
        : '',
      DueDate: isSet(object.DueDate) ? String(object.DueDate) : '',
      CreatedAt: isSet(object.CreatedAt) ? String(object.CreatedAt) : '',
      ClosedAt: isSet(object.ClosedAt) ? String(object.ClosedAt) : '',
    };
  },

  toJSON(message: ProjectInfo): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Active !== undefined && (obj.Active = message.Active);
    message.CaseDescription !== undefined &&
      (obj.CaseDescription = message.CaseDescription);
    message.DueDate !== undefined && (obj.DueDate = message.DueDate);
    message.CreatedAt !== undefined && (obj.CreatedAt = message.CreatedAt);
    message.ClosedAt !== undefined && (obj.ClosedAt = message.ClosedAt);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProjectInfo>, I>>(
    object: I,
  ): ProjectInfo {
    const message = createBaseProjectInfo();
    message.ID = object.ID ?? '';
    message.Active = object.Active ?? false;
    message.CaseDescription = object.CaseDescription ?? '';
    message.DueDate = object.DueDate ?? '';
    message.CreatedAt = object.CreatedAt ?? '';
    message.ClosedAt = object.ClosedAt ?? '';
    return message;
  },
};

function createBaseProjectTaskInfo(): ProjectTaskInfo {
  return {
    ID: '',
    Creator: undefined,
    Project: undefined,
    Title: '',
    Description: '',
    Status: '',
    DueDate: '',
    Assignments: [],
  };
}

export const ProjectTaskInfo = {
  encode(
    message: ProjectTaskInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Creator !== undefined) {
      DoctorInfo.encode(message.Creator, writer.uint32(18).fork()).ldelim();
    }
    if (message.Project !== undefined) {
      ProjectInfo.encode(message.Project, writer.uint32(26).fork()).ldelim();
    }
    if (message.Title !== '') {
      writer.uint32(34).string(message.Title);
    }
    if (message.Description !== '') {
      writer.uint32(42).string(message.Description);
    }
    if (message.Status !== '') {
      writer.uint32(50).string(message.Status);
    }
    if (message.DueDate !== '') {
      writer.uint32(58).string(message.DueDate);
    }
    for (const v of message.Assignments) {
      ProjectTaskAssignment.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectTaskInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectTaskInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Creator = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 3:
          message.Project = ProjectInfo.decode(reader, reader.uint32());
          break;
        case 4:
          message.Title = reader.string();
          break;
        case 5:
          message.Description = reader.string();
          break;
        case 6:
          message.Status = reader.string();
          break;
        case 7:
          message.DueDate = reader.string();
          break;
        case 8:
          message.Assignments.push(
            ProjectTaskAssignment.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProjectTaskInfo {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Creator: isSet(object.Creator)
        ? DoctorInfo.fromJSON(object.Creator)
        : undefined,
      Project: isSet(object.Project)
        ? ProjectInfo.fromJSON(object.Project)
        : undefined,
      Title: isSet(object.Title) ? String(object.Title) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      Status: isSet(object.Status) ? String(object.Status) : '',
      DueDate: isSet(object.DueDate) ? String(object.DueDate) : '',
      Assignments: Array.isArray(object?.Assignments)
        ? object.Assignments.map((e: any) => ProjectTaskAssignment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProjectTaskInfo): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Creator !== undefined &&
      (obj.Creator = message.Creator
        ? DoctorInfo.toJSON(message.Creator)
        : undefined);
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? ProjectInfo.toJSON(message.Project)
        : undefined);
    message.Title !== undefined && (obj.Title = message.Title);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Status !== undefined && (obj.Status = message.Status);
    message.DueDate !== undefined && (obj.DueDate = message.DueDate);
    if (message.Assignments) {
      obj.Assignments = message.Assignments.map((e) =>
        e ? ProjectTaskAssignment.toJSON(e) : undefined,
      );
    } else {
      obj.Assignments = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProjectTaskInfo>, I>>(
    object: I,
  ): ProjectTaskInfo {
    const message = createBaseProjectTaskInfo();
    message.ID = object.ID ?? '';
    message.Creator =
      object.Creator !== undefined && object.Creator !== null
        ? DoctorInfo.fromPartial(object.Creator)
        : undefined;
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? ProjectInfo.fromPartial(object.Project)
        : undefined;
    message.Title = object.Title ?? '';
    message.Description = object.Description ?? '';
    message.Status = object.Status ?? '';
    message.DueDate = object.DueDate ?? '';
    message.Assignments =
      object.Assignments?.map((e) => ProjectTaskAssignment.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseProjectTaskAssignment(): ProjectTaskAssignment {
  return { Assigner: undefined, Assignee: undefined };
}

export const ProjectTaskAssignment = {
  encode(
    message: ProjectTaskAssignment,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Assigner !== undefined) {
      DoctorInfo.encode(message.Assigner, writer.uint32(10).fork()).ldelim();
    }
    if (message.Assignee !== undefined) {
      DoctorInfo.encode(message.Assignee, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ProjectTaskAssignment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectTaskAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Assigner = DoctorInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Assignee = DoctorInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProjectTaskAssignment {
    return {
      Assigner: isSet(object.Assigner)
        ? DoctorInfo.fromJSON(object.Assigner)
        : undefined,
      Assignee: isSet(object.Assignee)
        ? DoctorInfo.fromJSON(object.Assignee)
        : undefined,
    };
  },

  toJSON(message: ProjectTaskAssignment): unknown {
    const obj: any = {};
    message.Assigner !== undefined &&
      (obj.Assigner = message.Assigner
        ? DoctorInfo.toJSON(message.Assigner)
        : undefined);
    message.Assignee !== undefined &&
      (obj.Assignee = message.Assignee
        ? DoctorInfo.toJSON(message.Assignee)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProjectTaskAssignment>, I>>(
    object: I,
  ): ProjectTaskAssignment {
    const message = createBaseProjectTaskAssignment();
    message.Assigner =
      object.Assigner !== undefined && object.Assigner !== null
        ? DoctorInfo.fromPartial(object.Assigner)
        : undefined;
    message.Assignee =
      object.Assignee !== undefined && object.Assignee !== null
        ? DoctorInfo.fromPartial(object.Assignee)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { ProcessingUnit } from "graphics";
import { RCCropImageCrop } from "../RCCropImage/RCCropImage";
import { RCOffScreenCanvas, RCOffScreenCtx } from "./RCOffScreenCanvas";


/** RCImageBase was intended to be able to use difference repaint methods, but them are the same essentially,
 * so this function is to reuse the same code for both
 */
export const repaint = (crop: RCCropImageCrop, canvas: HTMLCanvasElement, angle: number | undefined) => {

    const ctx = canvas.getContext('2d');
    if (!ctx) return

    // Note: only angle=0,90,180,270 are supported 
    if (angle) {
        // Render image to an off-screen canvas first
        // Then apply the rotation and redraw onto the main canvas
        const width = crop.w
        const height = crop.h

        RCOffScreenCanvas.width = width;
        RCOffScreenCanvas.height = height;

        RCOffScreenCtx.drawImage(ProcessingUnit.canvas,
            crop.x, crop.y, crop.w, crop.h,
            0, 0, crop.w, crop.h);

        if (angle === 90 || angle === 270) {
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // Apply rotation to the main canvas
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(angle * Math.PI / 180);
        ctx.translate(-RCOffScreenCanvas.width / 2, -RCOffScreenCanvas.height / 2);

        // Draw the off-screen canvas onto the main canvas
        ctx.drawImage(RCOffScreenCanvas, 0, 0);
        ctx.restore();
    } else {
        // Render image to the canvas right away
        canvas.width = crop.w;
        canvas.height = crop.h;

        ctx.drawImage(ProcessingUnit.canvas,
            crop.x, crop.y, crop.w, crop.h,
            0, 0, crop.w, crop.h);
    }


}
import { FC } from 'react';
import { useIntl } from 'react-intl/lib';

import sadCatImg from '@/shared/assets/images/sad-cat.png';
import { Result } from '@/shared/ui';

type NoSubscriptionProps = {
  className?: string;
  text?: string;
};

export const NoSubscription: FC<NoSubscriptionProps> = (props) => {
  const { className, text } = props;

  const { formatMessage } = useIntl();

  const nothingFoundMessage = formatMessage({
    id: 'subscription.currentSubscription.noSubscription',
    defaultMessage: 'No purchased plans yet',
  });

  return (
    <Result
      className={className}
      icon={
        <img
          src={sadCatImg}
          width={130}
          height={130}
          alt={nothingFoundMessage}
        />
      }
      text={text ?? nothingFoundMessage}
    />
  );
};

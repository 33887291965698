import { ToothChartCustomModeToggle } from './ToothChartCustomModeToggle/ToothChartCustomModeToggle';
import { ToothChartGrid } from './ToothChartGrid/ToothChartGrid';
import { ToothChartHeader } from './ToothChartHeader/ToothChartHeader';
import { ToothChartItem } from './ToothChartItem/ToothChartItem';
import { ToothChartMetaItem } from './ToothChartMetaItem/ToothChartMetaItem';
import { ToothChartRoot } from './ToothChartRoot/ToothChartRoot';
import { ToothChartSuperItem } from './ToothChartSuperItem/ToothChartSuperItem';
import { ToothChartTags } from './ToothChartTags/ToothChartTags';

type CompoundedComponent = {
  Root: typeof ToothChartRoot;
  Header: typeof ToothChartHeader;
  CustomModeToggle: typeof ToothChartCustomModeToggle;
  Tags: typeof ToothChartTags;
  Grid: typeof ToothChartGrid;
  Item: typeof ToothChartItem;
  SuperItem: typeof ToothChartSuperItem;
  MetaItem: typeof ToothChartMetaItem;
};

export const ToothChart: CompoundedComponent = {
  Root: ToothChartRoot,
  Header: ToothChartHeader,
  CustomModeToggle: ToothChartCustomModeToggle,
  Tags: ToothChartTags,
  Grid: ToothChartGrid,
  Item: ToothChartItem,
  SuperItem: ToothChartSuperItem,
  MetaItem: ToothChartMetaItem,
};

import { StudyStatus } from '@/shared/api/protocol_gen/model/dto_study';
import loadingCat from '@/shared/assets/images/cats/loading.png';
import smileCat from '@/shared/assets/images/cats/smile.png';
import errorCat from '@/shared/assets/images/cats/error.png';

export const studyStatusData: Record<
  number,
  { imageSrc: string; status: StudyStatus }
> = {
  [StudyStatus.StudyStatus_Uploading]: {
    imageSrc: loadingCat,
    status: StudyStatus.StudyStatus_Uploading,
  },
  [StudyStatus.StudyStatus_Finished]: {
    imageSrc: smileCat,
    status: StudyStatus.StudyStatus_Finished,
  },
  [StudyStatus.StudyStatus_Failed]: {
    imageSrc: errorCat,
    status: StudyStatus.StudyStatus_Failed,
  },
};

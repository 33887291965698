/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ServiceType,
  serviceTypeFromJSON,
  serviceTypeToJSON,
} from '../../api/billing_new/dto_services_new';
import {
  CalledVia,
  Created,
  RevisionNew,
  DeletedNew,
  calledViaFromJSON,
  calledViaToJSON,
} from '../../model/dto_base';
import { Timestamp } from '../../google/protobuf/timestamp';

export const protobufPackage = 'com.diagnocat.billing_new';

export interface Consumption {
  ID: string;
  AccountID: string;
  ServiceType: ServiceType;
  Confirmed: Confirmed | undefined;
  RolledBack: RolledBack | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: RevisionNew | undefined;
  /** deletion information */
  Deleted: DeletedNew | undefined;
}

export interface Confirmed {
  IsConfirmed: boolean;
  By: string;
  At: Date | undefined;
  Via: CalledVia;
}

export interface RolledBack {
  IsRolledBack: boolean;
  By: string;
  At: Date | undefined;
  Via: CalledVia;
}

function createBaseConsumption(): Consumption {
  return {
    ID: '',
    AccountID: '',
    ServiceType: 0,
    Confirmed: undefined,
    RolledBack: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Consumption = {
  encode(
    message: Consumption,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.AccountID !== '') {
      writer.uint32(18).string(message.AccountID);
    }
    if (message.ServiceType !== 0) {
      writer.uint32(24).int32(message.ServiceType);
    }
    if (message.Confirmed !== undefined) {
      Confirmed.encode(message.Confirmed, writer.uint32(82).fork()).ldelim();
    }
    if (message.RolledBack !== undefined) {
      RolledBack.encode(message.RolledBack, writer.uint32(90).fork()).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      RevisionNew.encode(
        message.Revision,
        writer.uint32(80322).fork(),
      ).ldelim();
    }
    if (message.Deleted !== undefined) {
      DeletedNew.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumption {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.AccountID = reader.string();
          break;
        case 3:
          message.ServiceType = reader.int32() as any;
          break;
        case 10:
          message.Confirmed = Confirmed.decode(reader, reader.uint32());
          break;
        case 11:
          message.RolledBack = RolledBack.decode(reader, reader.uint32());
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = RevisionNew.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = DeletedNew.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Consumption {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      ServiceType: isSet(object.ServiceType)
        ? serviceTypeFromJSON(object.ServiceType)
        : 0,
      Confirmed: isSet(object.Confirmed)
        ? Confirmed.fromJSON(object.Confirmed)
        : undefined,
      RolledBack: isSet(object.RolledBack)
        ? RolledBack.fromJSON(object.RolledBack)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? RevisionNew.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? DeletedNew.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Consumption): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.ServiceType !== undefined &&
      (obj.ServiceType = serviceTypeToJSON(message.ServiceType));
    message.Confirmed !== undefined &&
      (obj.Confirmed = message.Confirmed
        ? Confirmed.toJSON(message.Confirmed)
        : undefined);
    message.RolledBack !== undefined &&
      (obj.RolledBack = message.RolledBack
        ? RolledBack.toJSON(message.RolledBack)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? RevisionNew.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? DeletedNew.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Consumption>, I>>(
    object: I,
  ): Consumption {
    const message = createBaseConsumption();
    message.ID = object.ID ?? '';
    message.AccountID = object.AccountID ?? '';
    message.ServiceType = object.ServiceType ?? 0;
    message.Confirmed =
      object.Confirmed !== undefined && object.Confirmed !== null
        ? Confirmed.fromPartial(object.Confirmed)
        : undefined;
    message.RolledBack =
      object.RolledBack !== undefined && object.RolledBack !== null
        ? RolledBack.fromPartial(object.RolledBack)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? RevisionNew.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? DeletedNew.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseConfirmed(): Confirmed {
  return { IsConfirmed: false, By: '', At: undefined, Via: 0 };
}

export const Confirmed = {
  encode(
    message: Confirmed,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IsConfirmed === true) {
      writer.uint32(8).bool(message.IsConfirmed);
    }
    if (message.By !== '') {
      writer.uint32(18).string(message.By);
    }
    if (message.At !== undefined) {
      Timestamp.encode(
        toTimestamp(message.At),
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Via !== 0) {
      writer.uint32(32).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Confirmed {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfirmed();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IsConfirmed = reader.bool();
          break;
        case 2:
          message.By = reader.string();
          break;
        case 3:
          message.At = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Confirmed {
    return {
      IsConfirmed: isSet(object.IsConfirmed)
        ? Boolean(object.IsConfirmed)
        : false,
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? fromJsonTimestamp(object.At) : undefined,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: Confirmed): unknown {
    const obj: any = {};
    message.IsConfirmed !== undefined &&
      (obj.IsConfirmed = message.IsConfirmed);
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = message.At.toISOString());
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Confirmed>, I>>(
    object: I,
  ): Confirmed {
    const message = createBaseConfirmed();
    message.IsConfirmed = object.IsConfirmed ?? false;
    message.By = object.By ?? '';
    message.At = object.At ?? undefined;
    message.Via = object.Via ?? 0;
    return message;
  },
};

function createBaseRolledBack(): RolledBack {
  return { IsRolledBack: false, By: '', At: undefined, Via: 0 };
}

export const RolledBack = {
  encode(
    message: RolledBack,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IsRolledBack === true) {
      writer.uint32(8).bool(message.IsRolledBack);
    }
    if (message.By !== '') {
      writer.uint32(18).string(message.By);
    }
    if (message.At !== undefined) {
      Timestamp.encode(
        toTimestamp(message.At),
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Via !== 0) {
      writer.uint32(32).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RolledBack {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRolledBack();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IsRolledBack = reader.bool();
          break;
        case 2:
          message.By = reader.string();
          break;
        case 3:
          message.At = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RolledBack {
    return {
      IsRolledBack: isSet(object.IsRolledBack)
        ? Boolean(object.IsRolledBack)
        : false,
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? fromJsonTimestamp(object.At) : undefined,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: RolledBack): unknown {
    const obj: any = {};
    message.IsRolledBack !== undefined &&
      (obj.IsRolledBack = message.IsRolledBack);
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = message.At.toISOString());
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RolledBack>, I>>(
    object: I,
  ): RolledBack {
    const message = createBaseRolledBack();
    message.IsRolledBack = object.IsRolledBack ?? false;
    message.By = object.By ?? '';
    message.At = object.At ?? undefined;
    message.Via = object.Via ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { Outlet } from 'react-router';

import { ReportLayout } from '@/entities/reports';
import { ConfirmModal } from '@/entities/modal';

import { Header } from '@/widgets/Header';
import { AddConditionModal } from '@/widgets/AddConditionModal';

export const Report2DLayout = () => {
  return (
    <ReportLayout.Container>
      <Header fullWidth />
      <ReportLayout.Main>
        <Outlet />
      </ReportLayout.Main>
      <ConfirmModal />
      <AddConditionModal />
    </ReportLayout.Container>
  );
};

// TODO: Resolve issue with async thunks types
import {
  createSlice,
  createEntityAdapter,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';

import { deleteStudy } from './studySlice.thunks';

const sortStudy = (a: Study, b: Study) => {
  const aUpdatedAt = a.Revision?.UpdatedAt?.getTime() ?? 0;
  const bUpdatedAt = b.Revision?.UpdatedAt?.getTime() ?? 0;

  return bUpdatedAt - aUpdatedAt;
};

export const studyAdapter = createEntityAdapter<Study>({
  selectId: (study) => study.ID,
  sortComparer: sortStudy,
});

type StudySliceInitialState = DefaultInitialState & {
  isDeleteStudyPending: boolean;
};

const initialStateFlags = {
  isDeleteStudyPending: false,
};

// TODO: Need to discuss this with the team
type StateFlags = keyof typeof initialStateFlags;

const initialState: StudySliceInitialState = {
  loading: 'idle',
  ...initialStateFlags,
};

const studySlice = createSlice({
  name: SliceName.study,
  initialState: studyAdapter.getInitialState(initialState),
  reducers: {
    addOne: studyAdapter.addOne,
    addMany: studyAdapter.addMany,
    setMany: studyAdapter.setMany,
    setNewestOne: (state, action: PayloadAction<Study>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        studyAdapter.setOne(state, action.payload);
      }
    },
    removeOne: studyAdapter.removeOne,
    removeAll: studyAdapter.removeAll,
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    const asyncRequestCreator = <A, B, C>(
      // @ts-expect-error WARN: C doesn't satisfy AsyncThunkConfig, but this is not public type. Just ignore this or remove this function and use builder itself.
      thunkAction: AsyncThunk<A, B, C>,
      pendingFlagKey: StateFlags,
      fullfiledCallback?: (payload: unknown) => void,
    ) => {
      builder.addCase(thunkAction.pending, (state) => {
        state[pendingFlagKey] = true;
      });
      builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
        state[pendingFlagKey] = false;
        if (typeof fullfiledCallback === 'function') {
          fullfiledCallback(payload);
        }
      });
      builder.addCase(thunkAction.rejected, (state) => {
        state[pendingFlagKey] = false;
      });
    };

    asyncRequestCreator(deleteStudy, 'isDeleteStudyPending');
  },
});

export const { actions } = studySlice;

export default studySlice.reducer;

import { defineMessages } from 'react-intl';

export const assetStatusErrorMessages = defineMessages<
  'failed' | 'unsuccessful'
>({
  failed: {
    id: 'uploadAsset.failed',
    defaultMessage:
      'Something went wrong, try again later. If the problem persists contact our support team.',
  },
  unsuccessful: {
    id: 'uploadAsset.unsuccessful',
    defaultMessage:
      'Please check that you upload the correct file or files. If the problem persists contact our support team.',
  },
});

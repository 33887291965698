import { defineMessages } from 'react-intl';

import { OrderReportType } from '@/shared/config';

export const orderReportName = defineMessages<OrderReportType>({
  CBCT: {
    id: 'orderReportName.CBCT',
    defaultMessage: 'CBCT',
  },
  Pano: {
    id: 'orderReportName.Pano',
    defaultMessage: 'Pano',
  },
  IOXRay: {
    id: 'orderReportName.IOXRay',
    defaultMessage: 'IOXRay AI',
  },
  '3DStudio': {
    id: 'orderReportName.3DStudio',
    defaultMessage: '3D model',
  },
  ImplantStudio: {
    id: 'orderReportName.ImplantStudio',
    defaultMessage: 'Implant planning',
  },
  OrthoStudio: {
    id: 'orderReportName.OrthoStudio',
    defaultMessage: 'Ortho planning',
  },
});

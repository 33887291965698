import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MaskGroup, SliceName } from '@/shared/config';

type InitialState = {
  isLandmarksShown: boolean;
  filters: Record<
    MaskGroup,
    {
      isActive: boolean;
      disabled: boolean;
    }
  >;
  filterIDs: Record<string, boolean>;
};

const initialState: InitialState = {
  filters: {
    perio: {
      isActive: true,
      disabled: false,
    },
    restorative: {
      isActive: true,
      disabled: true,
    },
    endo: {
      isActive: true,
      disabled: true,
    },
    chart: {
      isActive: true,
      disabled: false,
    },
  },
  isLandmarksShown: true,
  filterIDs: {},
};

const renderMasksSlice = createSlice({
  name: SliceName.maskFilters,
  initialState,
  reducers: {
    toggleFilter: (state, { payload }: PayloadAction<MaskGroup>) => {
      if (payload === 'perio') {
        state.isLandmarksShown = !state.isLandmarksShown;
      }

      state.filters[payload].isActive = !state.filters[payload].isActive;
    },
    setFilterDisabled: (
      state,
      { payload }: PayloadAction<[MaskGroup, boolean]>,
    ) => {
      const [filterKey, isDisabled] = payload;

      state.filters[filterKey].disabled = isDisabled;
    },
    setFilterIDs: (state, { payload }: PayloadAction<string>) => {
      state.filterIDs[payload] = true;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
      state.filterIDs = {};
    },
  },
});

export const { actions } = renderMasksSlice;

export default renderMasksSlice.reducer;

import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { DropdownSelect } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { OptionType } from '@/shared/ui/Select/Select.types';

import { userModel } from '@/entities/user';

import { isNotAll } from '../lib';
import { ALL_DOCTORS_OPTION } from '../config';
import { useOrganizationUsersOptions } from '../hooks';

import styles from './DoctorsDropdownFilter.module.scss';

type DoctorsDropdownFilterProps = {
  onChange: (doctorsIDs: string[]) => void;
};

// TODO: add updating search params and connect with patient list stream reopening
export const DoctorsDropdownFilter: FC<DoctorsDropdownFilterProps> = (
  props,
) => {
  const { onChange } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatMessage } = useIntl();

  const doctorIDs = searchParams.get('doctorIDs')?.split(',') || [];

  const [doctors, setDoctors] = useState<string[]>(doctorIDs);

  const userID = useAppSelector(userModel.selectors.selectCurrentUserID);

  const usersOptions = useOrganizationUsersOptions(userID);

  const isUserADoctor = usersOptions.some((option) => option.value === userID);

  const uncheckAll = isUserADoctor ? [userID] : [];

  const selectedDoctorsQuantity = doctors.filter(isNotAll).length;

  const handleChange = (value: string | string[]) => {
    if (typeof value === 'string') {
      return;
    }

    const handleCheckAll = () => {
      const extractValue = (option: OptionType) => option.value;

      return usersOptions.map(extractValue);
    };

    const updateDoctors = () => {
      if (
        value.includes(ALL_DOCTORS_OPTION) &&
        !doctors.includes(ALL_DOCTORS_OPTION)
      ) {
        return handleCheckAll();
      }

      if (
        !value.includes(ALL_DOCTORS_OPTION) &&
        doctors.includes(ALL_DOCTORS_OPTION)
      ) {
        return uncheckAll;
      }

      return value;
    };

    setDoctors(updateDoctors());
  };

  const handleApply = (filters: string | string[]) => {
    if (typeof filters === 'string') {
      return;
    }

    const applyAll = () => {
      searchParams.set('doctorIDs', '');
      setSearchParams([]);
      onChange([]);
    };

    const applySpecific = (specificFilters: string[]) => {
      onChange(specificFilters);
      searchParams.set('doctorIDs', specificFilters.join(','));
      setSearchParams(searchParams);
    };

    const updateFilters = () => {
      if (filters.includes(ALL_DOCTORS_OPTION)) {
        applyAll();
      } else {
        const withoutAllKey = filters.filter(isNotAll);
        applySpecific(withoutAllKey);
      }
    };

    updateFilters();
  };

  const handleClear = () => {
    setDoctors(uncheckAll);
  };

  if (usersOptions.length === 1) {
    return null;
  }

  return (
    <DropdownSelect
      isMulti
      value={doctors}
      label={formatMessage({
        id: 'patientList.treatingDoctors',
        defaultMessage: 'Treating doctors',
      })}
      options={usersOptions}
      className={styles.container}
      stylesOptions={{ listMaxHeight: 206 }}
      icon="filter"
      multiValuesCount={
        selectedDoctorsQuantity > 0 ? selectedDoctorsQuantity : undefined
      }
      onChange={handleChange}
      onApply={handleApply}
      onClear={handleClear}
    />
  );
};

import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';

import { makeAnnotations } from '../lib/makeAnnotations';
import { makeImageViewOptions } from '../lib/makeImageViewOptions';
import { toothModel } from '../../tooth';
import { getFileSrc } from '../lib/getFileSrc';
import * as assetsModel from '../model';
import { TOOTH_PAGE_GROUPS, TOOTH_PAGE_PATHOLOGY_GROUPS } from '../config';

// To find group name you have to check two places and then match the group groupCode
// by one of the two config arrays
const getMedicalImageGroupName = (medicalImage: Asset) => {
  if (
    medicalImage?.GeneratedReport?.CBCTGPToothSlice?.Orientation !== undefined
  ) {
    const groupCode =
      medicalImage?.GeneratedReport?.CBCTGPToothSlice?.Orientation;
    return TOOTH_PAGE_GROUPS.find((group) => group.groupCode === groupCode)
      ?.groupName;
  }

  if (
    medicalImage?.GeneratedReport?.CBCTToothPathologySlice?.Pathology !==
    undefined
  ) {
    const groupCode =
      medicalImage?.GeneratedReport?.CBCTToothPathologySlice?.Pathology;
    return TOOTH_PAGE_PATHOLOGY_GROUPS.find(
      (group) => group.groupCode === groupCode,
    )?.groupName;
  }

  if (medicalImage.Report?.UserUploadedToothSlice?.Slice?.ID) {
    return 'UserUpload';
  }

  if (medicalImage?.Report?.UserUploadedMPRPlane?.MPRPlane?.ID) {
    return 'SavedMPR';
  }

  return '';
};

export const useGetSelectedMedicalImages = (
  toothID: string,
): MedicalImageInterface[] => {
  const assets = useAppSelector(assetsModel.selectors.selectEntities);

  const selectUserAssets =
    assetsModel.selectors.makeSelectorUserUploadedAssetsByToothID(toothID);

  const userAssets = useAppSelector(selectUserAssets);

  const displaySlices = useAppSelector(
    toothModel.selectors.selectDisplaySlicesByToothID(toothID),
  );

  const userAssetsIDs = userAssets.map((asset) => asset.ID);

  return useMemo(
    () =>
      displaySlices?.reduce((acc, selectedMedicalImageID) => {
        const currentMedicalImage = assets[selectedMedicalImageID];

        const medicalImageID =
          currentMedicalImage?.GeneratedReport?.CBCTGPToothSlice?.Slice?.ID ??
          currentMedicalImage?.GeneratedReport?.CBCTToothPathologySlice?.Slice
            ?.ID ??
          currentMedicalImage?.Report?.UserUploadedToothSlice?.Slice?.ID ??
          currentMedicalImage?.Report?.UserUploadedMPRPlane?.MPRPlane?.ID;
        const medicalImagePath =
          currentMedicalImage?.GeneratedReport?.CBCTGPToothSlice?.Slice?.Path ??
          currentMedicalImage?.GeneratedReport?.CBCTToothPathologySlice?.Slice
            ?.Path ??
          currentMedicalImage?.Report?.UserUploadedToothSlice?.Slice?.Path ??
          currentMedicalImage?.Report?.UserUploadedMPRPlane?.MPRPlane?.Path;

        const order =
          currentMedicalImage?.GeneratedReport?.CBCTGPToothSlice?.Regular
            ?.Order ?? userAssetsIDs.indexOf(currentMedicalImage?.ID as string);

        const annotations = makeAnnotations(
          currentMedicalImage?.GeneratedReport?.MedicalImageFeatures
            ?.Annotations?.Layers ??
            currentMedicalImage?.Report?.MedicalImageFeatures?.Annotations
              ?.Layers,
        );

        const viewOptions = makeImageViewOptions(
          currentMedicalImage?.GeneratedReport?.MedicalImageFeatures
            ?.ViewOptions ??
            currentMedicalImage?.Report?.MedicalImageFeatures?.ViewOptions,
        );

        if (medicalImageID && currentMedicalImage) {
          acc.push({
            src: getFileSrc(medicalImageID),
            kind:
              currentMedicalImage?.Report?.UserUploadedToothSlice?.Slice?.ID ||
              currentMedicalImage?.Report?.UserUploadedMPRPlane?.MPRPlane?.ID
                ? 'raster'
                : 'dicom',
            id: selectedMedicalImageID,
            path: medicalImagePath,
            assetID: currentMedicalImage?.ID,
            groupName: getMedicalImageGroupName(currentMedicalImage),
            annotations,
            viewOptions,
            order,
          });
        }

        return acc;
      }, [] as MedicalImageInterface[]) ?? [],
    [displaySlices, assets, userAssetsIDs],
  ).sort((a, b) => a?.order ?? 0 - (b?.order ?? 0));
};

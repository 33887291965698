import { Angle } from './Angle';
import { Annotation } from './Annotation';
import { Arrow } from './Arrow';
import { Line } from './Line';
import { Ruler } from './Ruler';

export const RenderAnnotation = ({
  annotation,
  pixelSpacing,
}: {
  annotation: Annotation;
  pixelSpacing: [number, number];
}) => {
  return (
    <>
      {annotation.kind === 'ruler' && <Ruler {...annotation} pixelSpacing={pixelSpacing} />}
      {annotation.kind === 'arrow' && <Arrow {...annotation} />}
      {annotation.kind === 'angle' && <Angle {...annotation} />}
      {annotation.kind === 'line' && <Line {...annotation} />}
    </>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { Invitation_InvitationStatus } from '@/shared/api/protocol_gen/model/dto_access';

import { RootState } from '@/app/model/store';

import { accessAdapter } from './accessSlice';

export const accessSelectors = accessAdapter.getSelectors(
  (state: RootState) => state.access,
);

export const { selectAll, selectById, selectTotal, selectEntities, selectIds } =
  accessSelectors;

export const selectCounters = createSelector(
  (
    state: RootState,
  ): {
    accessSharedWithMeCount: number;
    accessSharedByMeCount: number;
    accessTotalOrganizationPatientCount: number;
  } => ({
    accessSharedWithMeCount: state.access.accessSharedWithMeCount,
    accessSharedByMeCount: state.access.accessSharedByMeCount,
    accessTotalOrganizationPatientCount:
      state.access.accessTotalOrganizationPatientCount,
  }),
  (counters) => counters,
);

export const selectOrganizationDoctorInvitations = createSelector(
  accessSelectors.selectAll,
  (invitations) =>
    invitations.filter((invitation) => !!invitation?.Target?.Organization),
);

export const selectInvitationsByPatientID = (patientID: string) =>
  createSelector(accessSelectors.selectAll, (invitations) =>
    invitations.filter(
      (invitation) => invitation?.Target?.Patient?.PatientID === patientID,
    ),
  );

export const selectAcceptedOrPendingSharedPatientInvitations = createSelector(
  accessSelectors.selectAll,
  (invitations) =>
    invitations.filter(
      (invitation) =>
        invitation?.Target?.Patient &&
        (invitation.Status === Invitation_InvitationStatus.StatusPending ||
          invitation.Status === Invitation_InvitationStatus.StatusAccepted),
    ),
);

export const selectAcceptedSharedPatientInvitations = createSelector(
  accessSelectors.selectAll,
  (invitations) =>
    invitations.filter(
      (invitation) =>
        invitation?.Target?.Patient &&
        invitation.Status === Invitation_InvitationStatus.StatusAccepted,
    ),
);

export const selectInvitationByToken = (token: string) =>
  createSelector(accessSelectors.selectAll, (invitations) =>
    invitations.find((invitation) => invitation.Token === token),
  );

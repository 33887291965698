import { defineMessages } from 'react-intl';

import { CountsIconNames } from './constants';

export const patientListRowMessages = defineMessages<CountsIconNames>({
  cbctNew: {
    id: 'patientList.studies.cbct',
    defaultMessage: 'CBCT',
  },
  ioxray: {
    id: 'patientList.studies.ioxray',
    defaultMessage: 'IOXray',
  },
  pano: {
    id: 'patientList.studies.pano',
    defaultMessage: 'Pano',
  },
  photo: {
    id: 'patientList.studies.photo',
    defaultMessage: 'Photo',
  },
  ios: {
    id: 'patientList.studies.ios_stl',
    defaultMessage: 'IOS',
  },
  doc: {
    id: 'patientList.studies.doc',
    defaultMessage: 'Docs',
  },
});

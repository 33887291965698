import { FormattedMessage } from 'react-intl';

import { string } from '@/shared/config/yup';

const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;

export const passwordValidation = string()
  .required(() => (
    <FormattedMessage
      id="passwordValidation.error.required"
      defaultMessage="Password is required"
    />
  ))
  .test(
    'passwordRequirements',
    () => (
      <FormattedMessage
        id="passwordValidation.error.contain"
        defaultMessage="Password must contain at least one uppercase letter and number"
      />
    ),
    (value) => regexp.test(value || ''),
  )
  .min(8, () => (
    <FormattedMessage
      id="passwordValidation.error.minLength"
      defaultMessage="Password should be at least 8 characters"
    />
  ));

import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useLocation, useNavigate } from 'react-router';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';
import api, { ApiError } from '@/shared/api/api';
import { PATHS, StreamDataAccumulatorKey } from '@/shared/config';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { ToothLandmark } from '@/shared/api/protocol_gen/model/dto_report_landmark';
import { toastCaller } from '@/shared/ui';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { conditionModel } from '@/entities/condition';
import { assetsModel } from '@/entities/assets';
import { toothLandmarksModel } from '@/entities/toothLandmarks';
import { allowedToothConditionsModel } from '@/entities/allowedToothConditions';
import { logicalConditionModel } from '@/entities/logicalCondition';
import {
  ChildToothConditionCollectionByParentID,
  ToothConditionCollectionByCode,
  ToothConditions,
} from '@/entities/logicalCondition/model/logicalConditionSlice';

type ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
  [StreamDataAccumulatorKey.toothLandmarks]: ToothLandmark[];
  [StreamDataAccumulatorKey.initialROITeethISONumbers]: number[];
  [StreamDataAccumulatorKey.initialROITeethIDs]: string[];
};

const dataAccumulators: ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
  [StreamDataAccumulatorKey.initialROITeethISONumbers]: [],
  [StreamDataAccumulatorKey.initialROITeethIDs]: [],
  [StreamDataAccumulatorKey.toothLandmarks]: [],
};

let conditionsAccumulator: ToothConditions = {};
let nonToothConditionsAccumulator: Condition[] = [];

export const useReportDataStream = (reportID: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const reportDataStream = useRef<Subscription>();

  const openReportDataStream = () => {
    dispatch(assetsModel.actions.setLoading('pending'));
    dispatch(toothModel.actions.setLoading('pending'));
    dispatch(logicalConditionModel.actions.setLoading('pending'));
    dispatch(toothLandmarksModel.actions.setLoading('pending'));

    performance.mark('reportDataStream:start');

    reportDataStream.current = api.report
      .ReportDataStream({ ReportID: reportID })
      .subscribe({
        next: (data) => {
          if (data.EndOfHistoricalUsers) {
            performance.mark('reportDataStream:end');
            performance.measure(
              'reportDataStream',
              'reportDataStream:start',
              'reportDataStream:end',
            );
          }

          if (data.HistoricalReport) {
            dispatch(reportsModel.actions.setNewestOne(data.HistoricalReport));
            dispatch(
              reportsModel.actions.setCurrentReport(data.HistoricalReport),
            );

            dispatch(reportsModel.actions.setLoading('succeeded'));
          }

          if (data.UpdatedReport) {
            dispatch(reportsModel.actions.setNewestOne(data.UpdatedReport));
            dispatch(
              reportsModel.actions.updateCurrentReport(data.UpdatedReport),
            );
          }

          if (data.HistoricalTooth) {
            if (dataAccumulators[StreamDataAccumulatorKey.teeth].length === 0) {
              performance.mark('reportDataStream:HistoricalTooth:start');
            }
            dataAccumulators[StreamDataAccumulatorKey.teeth].push(
              data.HistoricalTooth,
            );

            // Collect initial local ROI teeth IDs
            if (data.HistoricalTooth.IsInROI) {
              if (data.HistoricalTooth.Numeration?.ISO) {
                dataAccumulators[
                  StreamDataAccumulatorKey.initialROITeethISONumbers
                ].push(data.HistoricalTooth.Numeration.ISO);
              }

              dataAccumulators[
                StreamDataAccumulatorKey.initialROITeethIDs
              ].push(data.HistoricalTooth.ID);
            }
          }

          if (data.UpdatedTooth) {
            dispatch(toothModel.actions.setNewestOne(data.UpdatedTooth));
          }

          if (data.ToothConditionsHint) {
            dispatch(
              allowedToothConditionsModel.actions.setOne(
                data.ToothConditionsHint,
              ),
            );
          }

          if (data.EndOfHistoricalTeeth) {
            performance.mark('reportDataStream:HistoricalTooth:end');
            performance.measure(
              'reportDataStream:HistoricalTooth',
              'reportDataStream:HistoricalTooth:start',
              'reportDataStream:HistoricalTooth:end',
            );

            performance.mark('toothModel.actions.setMany:start');
            dispatch(
              toothModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.teeth],
              ),
            );
            performance.mark('toothModel.actions.setMany:end');
            performance.measure(
              'toothModel.actions.setMany',
              'toothModel.actions.setMany:start',
              'toothModel.actions.setMany:end',
            );

            // TODO: Deprecated. Get rif of.
            dispatch(
              toothModel.actions.setLocalROITeethISONumbers_DEPRECATED(
                dataAccumulators[
                  StreamDataAccumulatorKey.initialROITeethISONumbers
                ],
              ),
            );

            dispatch(
              toothModel.actions.initLocalROITeethIDs(
                dataAccumulators[StreamDataAccumulatorKey.initialROITeethIDs],
              ),
            );

            dispatch(toothModel.actions.setLoading('succeeded'));

            dataAccumulators[
              StreamDataAccumulatorKey.initialROITeethISONumbers
            ] = [];
            dataAccumulators[StreamDataAccumulatorKey.initialROITeethIDs] = [];
            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];
          }

          if (data.HistoricalCondition) {
            if (
              dataAccumulators[StreamDataAccumulatorKey.conditions].length === 0
            ) {
              performance.mark('reportDataStream:HistoricalCondition:start');
            }
            dataAccumulators[StreamDataAccumulatorKey.conditions].push(
              data.HistoricalCondition,
            );

            const condition = data.HistoricalCondition;
            const toothID = condition.Tooth?.ToothID;
            const conditionCode = condition.Code;
            const parentID = condition.ParentID;

            const isChildCondition = parentID;

            if (!toothID) {
              nonToothConditionsAccumulator.push(condition);
              return;
            }

            if (toothID in conditionsAccumulator) {
              if (isChildCondition) {
                if (
                  parentID in conditionsAccumulator[toothID].childConditions
                ) {
                  if (
                    conditionCode in
                    conditionsAccumulator[toothID].childConditions[parentID]
                  ) {
                    conditionsAccumulator[toothID].childConditions[parentID][
                      conditionCode
                    ][condition.ID] = condition;
                  } else {
                    conditionsAccumulator[toothID].childConditions[parentID][
                      conditionCode
                    ] = {
                      [condition.ID]: condition,
                    };
                  }
                } else {
                  conditionsAccumulator[toothID].childConditions[parentID] = {
                    [conditionCode]: {
                      [condition.ID]: condition,
                    },
                  } as ToothConditionCollectionByCode;
                }
              } else if (
                conditionCode in conditionsAccumulator[toothID].conditions
              ) {
                conditionsAccumulator[toothID].conditions[conditionCode][
                  condition.ID
                ] = condition;
              } else {
                conditionsAccumulator[toothID].conditions[conditionCode] = {
                  [condition.ID]: condition,
                };
              }
            } else {
              conditionsAccumulator[toothID] = {
                conditions: {} as ToothConditionCollectionByCode,
                childConditions: {},
              };

              if (isChildCondition) {
                conditionsAccumulator[toothID].childConditions = {
                  [condition.ParentID]: {
                    [conditionCode]: {
                      [condition.ID]: condition,
                    },
                  },
                } as ChildToothConditionCollectionByParentID;
              } else {
                conditionsAccumulator[toothID].conditions = {
                  [conditionCode]: {
                    [condition.ID]: condition,
                  },
                } as ToothConditionCollectionByCode;
              }
            }
          }

          // NOTE: This could be both added condition or updated condition
          if (data.UpdatedCondition) {
            dispatch(
              logicalConditionModel.actions.setOne(data.UpdatedCondition),
            );
          }

          if (data.HistoricalAsset && !data.HistoricalAsset.Deleted?.Deleted) {
            if (
              dataAccumulators[StreamDataAccumulatorKey.assets].length === 0
            ) {
              performance.mark('reportDataStream:HistoricalAsset:start');
            }
            dataAccumulators[StreamDataAccumulatorKey.assets].push(
              data.HistoricalAsset,
            );
          }

          if (data.EndOfHistoricalAssets) {
            performance.mark('reportDataStream:HistoricalAsset:end');
            performance.measure(
              'reportDataStream:HistoricalAsset',
              'reportDataStream:HistoricalAsset:start',
              'reportDataStream:HistoricalAsset:end',
            );
            dispatch(
              assetsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.assets],
              ),
            );
            dispatch(assetsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
          }

          if (data.CreatedAsset) {
            dispatch(assetsModel.actions.addOne(data.CreatedAsset));
          }

          if (data.UpdatedAsset) {
            if (data.UpdatedAsset.Deleted?.Deleted) {
              dispatch(assetsModel.actions.removeOne(data.UpdatedAsset.ID));
            } else {
              dispatch(assetsModel.actions.setNewestOne(data.UpdatedAsset));
            }
          }

          if (data.HistoricalToothLandmark) {
            dataAccumulators[StreamDataAccumulatorKey.toothLandmarks].push(
              data.HistoricalToothLandmark,
            );
          }

          if (data.EndOfHistoricalTeethLandmarks) {
            dispatch(
              toothLandmarksModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.toothLandmarks],
              ),
            );
            dispatch(toothLandmarksModel.actions.setLoading('succeeded'));

            dataAccumulators[StreamDataAccumulatorKey.toothLandmarks] = [];
          }

          if (data.EndOfHistoricalConditions) {
            performance.mark('reportDataStream:HistoricalCondition:end');
            performance.measure(
              'reportDataStream:HistoricalCondition',
              'reportDataStream:HistoricalCondition:start',
              'reportDataStream:HistoricalCondition:end',
            );

            dispatch(
              conditionModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.conditions],
              ),
            );

            dispatch(conditionModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.conditions] = [];

            dispatch(
              logicalConditionModel.actions.initialize(conditionsAccumulator),
            );

            dispatch(
              logicalConditionModel.actions.initializeNonToothConditions(
                nonToothConditionsAccumulator,
              ),
            );

            dispatch(logicalConditionModel.actions.setLoading('succeeded'));
            conditionsAccumulator = {};
            nonToothConditionsAccumulator = [];
          }
        },
        error: (error) => {
          if (error.type === 'UnauthenticatedError') {
            navigate(PATHS.signIn, { state: { from: location?.pathname } });
          }

          // NOTE: Redirect only if error entity is report or the user doesn't have access to the report. In other cases it's not about report itself.
          if (
            ((error as ApiError).type === 'NotFoundError' &&
              error.entity === 'report') ||
            (error as ApiError).type === 'PermissionDeniedError'
          ) {
            toastCaller({
              type: 'error',
              message: formatMessage({
                id: 'reportStream.notExistOrNoAccess',
                defaultMessage:
                  'The page does not exist, or you do not have permission to access this page',
              }),
              autoClose: 10000,
            });

            navigate(PATHS.patients);
          }
        },
        complete: () => {
          // Do nothing
        },
      });
  };

  const closeReportDataStream = () => {
    if (reportDataStream.current) {
      reportDataStream.current.unsubscribe();

      dispatch(reportsModel.actions.resetReportViewSettings());
      dispatch(assetsModel.actions.removeAllButNoGuarded());
      dispatch(toothModel.actions.initLocalROITeethIDs([]));
      dispatch(conditionModel.actions.reset());
      dispatch(logicalConditionModel.actions.reset());
    }
  };

  useEffect(() => {
    openReportDataStream();

    return () => {
      closeReportDataStream();
    };
  }, []);
};

import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';

import styles from './InlineFormError.module.scss';

type InlineFormErrorProps = {
  className?: string;
  errorMessage?: ReactNode;
};

export const InlineFormError: FC<InlineFormErrorProps> = (props) => {
  const { className, errorMessage } = props;
  return (
    <div className={cn(styles.container, className)}>
      <Icon name="info2" size={20} className={styles.icon} />

      <p className="p3">{errorMessage}</p>
    </div>
  );
};

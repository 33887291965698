/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSectionOrientation,
  File,
  DerivedDataImage,
  cBCTSectionOrientationFromJSON,
  cBCTSectionOrientationToJSON,
} from '../model/dto_asset_common';
import { ToothNumeration } from '../model/dto_report_common';
import { BBox } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentGeneratedCBCTGPPanoramaReformatSplit {
  PanoramaSplit: File | undefined;
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
  PanoramaSingle: File | undefined;
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentGeneratedCBCTGPToothSlice {
  Slice: File | undefined;
  Numeration: ToothNumeration | undefined;
  Orientation: CBCTSectionOrientation;
  Scale: number;
  WindowWidth: number;
  WindowLevel: number;
  /** if order is negative, no order is proposed */
  ProposedToothCardOrder: number;
  Derived: DerivedDataImage | undefined;
  Regular: CBCTGPToothSliceMetadataRegular | undefined;
  Guide: CBCTGPToothSliceMetadataGuide | undefined;
  GuideFrontal: CBCTGPToothSliceMetadataGuideFrontal | undefined;
}

export interface CBCTGPToothSliceMetadataRegular {
  /** in millimetrs; maps to `thickness_mm` */
  Thickness: number;
  /** in millimeters; maps to `pos_mm` */
  Position: number;
  TotalImages: number;
  Stride: number;
  Order: number;
}

export interface CBCTGPToothSliceMetadataGuide {
  /** maps to `axis`; is it the same? */
  Axis2: number;
  OrthoAxis: number;
}

export interface CBCTGPToothSliceMetadataGuideFrontal {
  /** in millimetrs; maps to `thickness_mm` */
  Thickness: number;
  /** in millimeters; maps to `pos_mm` */
  Position: number;
  Axis2: number;
}

/** This asset is obsolete and be deleted once backend starts cutting CBCT into tiles. */
export interface AssetContentGeneratedCBCTGPMPRSubVolume {
  Volume: File | undefined;
  Numeration: ToothNumeration | undefined;
  Position: BBox | undefined;
  PositionInner: BBox | undefined;
  WindowWidth: number;
  WindowLevel: number;
}

/** {"type": "File", "file": {"task_id": "b7ac1063-b446-dc29-2544-24c1de704e47", "key": "14/periodontium-md-image", "filename": "/tmp/tmp1t_p7cp3", "content_type": "image/png", "size": 8376, "meta": {"section": "periodontium", "image_type": "slice-mesiodistal", "image_height": 137, "image_width": 64}, "type": "File"}, "event": "execution-event"} */
export interface AssetContentGeneratedCBCTGPPeriodontiumImage {
  Periodontium: File | undefined;
  Numeration: ToothNumeration | undefined;
  ImageType: AssetContentGeneratedCBCTGPPeriodontiumImage_Type;
}

export enum AssetContentGeneratedCBCTGPPeriodontiumImage_Type {
  Mesiodistal = 0,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedCBCTGPPeriodontiumImage_TypeFromJSON(
  object: any,
): AssetContentGeneratedCBCTGPPeriodontiumImage_Type {
  switch (object) {
    case 0:
    case 'Mesiodistal':
      return AssetContentGeneratedCBCTGPPeriodontiumImage_Type.Mesiodistal;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedCBCTGPPeriodontiumImage_Type.UNRECOGNIZED;
  }
}

export function assetContentGeneratedCBCTGPPeriodontiumImage_TypeToJSON(
  object: AssetContentGeneratedCBCTGPPeriodontiumImage_Type,
): string {
  switch (object) {
    case AssetContentGeneratedCBCTGPPeriodontiumImage_Type.Mesiodistal:
      return 'Mesiodistal';
    case AssetContentGeneratedCBCTGPPeriodontiumImage_Type.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

function createBaseAssetContentGeneratedCBCTGPPanoramaReformatSplit(): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
  return { PanoramaSplit: undefined, Derived: undefined };
}

export const AssetContentGeneratedCBCTGPPanoramaReformatSplit = {
  encode(
    message: AssetContentGeneratedCBCTGPPanoramaReformatSplit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaSplit !== undefined) {
      File.encode(message.PanoramaSplit, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatSplit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaSplit = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
    return {
      PanoramaSplit: isSet(object.PanoramaSplit)
        ? File.fromJSON(object.PanoramaSplit)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPPanoramaReformatSplit): unknown {
    const obj: any = {};
    message.PanoramaSplit !== undefined &&
      (obj.PanoramaSplit = message.PanoramaSplit
        ? File.toJSON(message.PanoramaSplit)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTGPPanoramaReformatSplit>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatSplit();
    message.PanoramaSplit =
      object.PanoramaSplit !== undefined && object.PanoramaSplit !== null
        ? File.fromPartial(object.PanoramaSplit)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPPanoramaReformatGeneral(): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
  return { PanoramaSingle: undefined, Derived: undefined };
}

export const AssetContentGeneratedCBCTGPPanoramaReformatGeneral = {
  encode(
    message: AssetContentGeneratedCBCTGPPanoramaReformatGeneral,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaSingle !== undefined) {
      File.encode(message.PanoramaSingle, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatGeneral();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaSingle = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
    return {
      PanoramaSingle: isSet(object.PanoramaSingle)
        ? File.fromJSON(object.PanoramaSingle)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPPanoramaReformatGeneral): unknown {
    const obj: any = {};
    message.PanoramaSingle !== undefined &&
      (obj.PanoramaSingle = message.PanoramaSingle
        ? File.toJSON(message.PanoramaSingle)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTGPPanoramaReformatGeneral>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatGeneral();
    message.PanoramaSingle =
      object.PanoramaSingle !== undefined && object.PanoramaSingle !== null
        ? File.fromPartial(object.PanoramaSingle)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPToothSlice(): AssetContentGeneratedCBCTGPToothSlice {
  return {
    Slice: undefined,
    Numeration: undefined,
    Orientation: 0,
    Scale: 0,
    WindowWidth: 0,
    WindowLevel: 0,
    ProposedToothCardOrder: 0,
    Derived: undefined,
    Regular: undefined,
    Guide: undefined,
    GuideFrontal: undefined,
  };
}

export const AssetContentGeneratedCBCTGPToothSlice = {
  encode(
    message: AssetContentGeneratedCBCTGPToothSlice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Slice !== undefined) {
      File.encode(message.Slice, writer.uint32(10).fork()).ldelim();
    }
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Orientation !== 0) {
      writer.uint32(24).int32(message.Orientation);
    }
    if (message.Scale !== 0) {
      writer.uint32(37).float(message.Scale);
    }
    if (message.WindowWidth !== 0) {
      writer.uint32(45).float(message.WindowWidth);
    }
    if (message.WindowLevel !== 0) {
      writer.uint32(53).float(message.WindowLevel);
    }
    if (message.ProposedToothCardOrder !== 0) {
      writer.uint32(56).int32(message.ProposedToothCardOrder);
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.Regular !== undefined) {
      CBCTGPToothSliceMetadataRegular.encode(
        message.Regular,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Guide !== undefined) {
      CBCTGPToothSliceMetadataGuide.encode(
        message.Guide,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.GuideFrontal !== undefined) {
      CBCTGPToothSliceMetadataGuideFrontal.encode(
        message.GuideFrontal,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPToothSlice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTGPToothSlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Slice = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 3:
          message.Orientation = reader.int32() as any;
          break;
        case 4:
          message.Scale = reader.float();
          break;
        case 5:
          message.WindowWidth = reader.float();
          break;
        case 6:
          message.WindowLevel = reader.float();
          break;
        case 7:
          message.ProposedToothCardOrder = reader.int32();
          break;
        case 8:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        case 101:
          message.Regular = CBCTGPToothSliceMetadataRegular.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.Guide = CBCTGPToothSliceMetadataGuide.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.GuideFrontal = CBCTGPToothSliceMetadataGuideFrontal.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPToothSlice {
    return {
      Slice: isSet(object.Slice) ? File.fromJSON(object.Slice) : undefined,
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      Orientation: isSet(object.Orientation)
        ? cBCTSectionOrientationFromJSON(object.Orientation)
        : 0,
      Scale: isSet(object.Scale) ? Number(object.Scale) : 0,
      WindowWidth: isSet(object.WindowWidth) ? Number(object.WindowWidth) : 0,
      WindowLevel: isSet(object.WindowLevel) ? Number(object.WindowLevel) : 0,
      ProposedToothCardOrder: isSet(object.ProposedToothCardOrder)
        ? Number(object.ProposedToothCardOrder)
        : 0,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
      Regular: isSet(object.Regular)
        ? CBCTGPToothSliceMetadataRegular.fromJSON(object.Regular)
        : undefined,
      Guide: isSet(object.Guide)
        ? CBCTGPToothSliceMetadataGuide.fromJSON(object.Guide)
        : undefined,
      GuideFrontal: isSet(object.GuideFrontal)
        ? CBCTGPToothSliceMetadataGuideFrontal.fromJSON(object.GuideFrontal)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPToothSlice): unknown {
    const obj: any = {};
    message.Slice !== undefined &&
      (obj.Slice = message.Slice ? File.toJSON(message.Slice) : undefined);
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.Orientation !== undefined &&
      (obj.Orientation = cBCTSectionOrientationToJSON(message.Orientation));
    message.Scale !== undefined && (obj.Scale = message.Scale);
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    message.ProposedToothCardOrder !== undefined &&
      (obj.ProposedToothCardOrder = Math.round(message.ProposedToothCardOrder));
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    message.Regular !== undefined &&
      (obj.Regular = message.Regular
        ? CBCTGPToothSliceMetadataRegular.toJSON(message.Regular)
        : undefined);
    message.Guide !== undefined &&
      (obj.Guide = message.Guide
        ? CBCTGPToothSliceMetadataGuide.toJSON(message.Guide)
        : undefined);
    message.GuideFrontal !== undefined &&
      (obj.GuideFrontal = message.GuideFrontal
        ? CBCTGPToothSliceMetadataGuideFrontal.toJSON(message.GuideFrontal)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTGPToothSlice>, I>,
  >(object: I): AssetContentGeneratedCBCTGPToothSlice {
    const message = createBaseAssetContentGeneratedCBCTGPToothSlice();
    message.Slice =
      object.Slice !== undefined && object.Slice !== null
        ? File.fromPartial(object.Slice)
        : undefined;
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.Orientation = object.Orientation ?? 0;
    message.Scale = object.Scale ?? 0;
    message.WindowWidth = object.WindowWidth ?? 0;
    message.WindowLevel = object.WindowLevel ?? 0;
    message.ProposedToothCardOrder = object.ProposedToothCardOrder ?? 0;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    message.Regular =
      object.Regular !== undefined && object.Regular !== null
        ? CBCTGPToothSliceMetadataRegular.fromPartial(object.Regular)
        : undefined;
    message.Guide =
      object.Guide !== undefined && object.Guide !== null
        ? CBCTGPToothSliceMetadataGuide.fromPartial(object.Guide)
        : undefined;
    message.GuideFrontal =
      object.GuideFrontal !== undefined && object.GuideFrontal !== null
        ? CBCTGPToothSliceMetadataGuideFrontal.fromPartial(object.GuideFrontal)
        : undefined;
    return message;
  },
};

function createBaseCBCTGPToothSliceMetadataRegular(): CBCTGPToothSliceMetadataRegular {
  return { Thickness: 0, Position: 0, TotalImages: 0, Stride: 0, Order: 0 };
}

export const CBCTGPToothSliceMetadataRegular = {
  encode(
    message: CBCTGPToothSliceMetadataRegular,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Thickness !== 0) {
      writer.uint32(13).float(message.Thickness);
    }
    if (message.Position !== 0) {
      writer.uint32(21).float(message.Position);
    }
    if (message.TotalImages !== 0) {
      writer.uint32(24).uint32(message.TotalImages);
    }
    if (message.Stride !== 0) {
      writer.uint32(37).float(message.Stride);
    }
    if (message.Order !== 0) {
      writer.uint32(40).uint32(message.Order);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTGPToothSliceMetadataRegular {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTGPToothSliceMetadataRegular();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Thickness = reader.float();
          break;
        case 2:
          message.Position = reader.float();
          break;
        case 3:
          message.TotalImages = reader.uint32();
          break;
        case 4:
          message.Stride = reader.float();
          break;
        case 5:
          message.Order = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTGPToothSliceMetadataRegular {
    return {
      Thickness: isSet(object.Thickness) ? Number(object.Thickness) : 0,
      Position: isSet(object.Position) ? Number(object.Position) : 0,
      TotalImages: isSet(object.TotalImages) ? Number(object.TotalImages) : 0,
      Stride: isSet(object.Stride) ? Number(object.Stride) : 0,
      Order: isSet(object.Order) ? Number(object.Order) : 0,
    };
  },

  toJSON(message: CBCTGPToothSliceMetadataRegular): unknown {
    const obj: any = {};
    message.Thickness !== undefined && (obj.Thickness = message.Thickness);
    message.Position !== undefined && (obj.Position = message.Position);
    message.TotalImages !== undefined &&
      (obj.TotalImages = Math.round(message.TotalImages));
    message.Stride !== undefined && (obj.Stride = message.Stride);
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CBCTGPToothSliceMetadataRegular>, I>>(
    object: I,
  ): CBCTGPToothSliceMetadataRegular {
    const message = createBaseCBCTGPToothSliceMetadataRegular();
    message.Thickness = object.Thickness ?? 0;
    message.Position = object.Position ?? 0;
    message.TotalImages = object.TotalImages ?? 0;
    message.Stride = object.Stride ?? 0;
    message.Order = object.Order ?? 0;
    return message;
  },
};

function createBaseCBCTGPToothSliceMetadataGuide(): CBCTGPToothSliceMetadataGuide {
  return { Axis2: 0, OrthoAxis: 0 };
}

export const CBCTGPToothSliceMetadataGuide = {
  encode(
    message: CBCTGPToothSliceMetadataGuide,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Axis2 !== 0) {
      writer.uint32(8).uint32(message.Axis2);
    }
    if (message.OrthoAxis !== 0) {
      writer.uint32(16).uint32(message.OrthoAxis);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTGPToothSliceMetadataGuide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTGPToothSliceMetadataGuide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Axis2 = reader.uint32();
          break;
        case 2:
          message.OrthoAxis = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTGPToothSliceMetadataGuide {
    return {
      Axis2: isSet(object.Axis2) ? Number(object.Axis2) : 0,
      OrthoAxis: isSet(object.OrthoAxis) ? Number(object.OrthoAxis) : 0,
    };
  },

  toJSON(message: CBCTGPToothSliceMetadataGuide): unknown {
    const obj: any = {};
    message.Axis2 !== undefined && (obj.Axis2 = Math.round(message.Axis2));
    message.OrthoAxis !== undefined &&
      (obj.OrthoAxis = Math.round(message.OrthoAxis));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CBCTGPToothSliceMetadataGuide>, I>>(
    object: I,
  ): CBCTGPToothSliceMetadataGuide {
    const message = createBaseCBCTGPToothSliceMetadataGuide();
    message.Axis2 = object.Axis2 ?? 0;
    message.OrthoAxis = object.OrthoAxis ?? 0;
    return message;
  },
};

function createBaseCBCTGPToothSliceMetadataGuideFrontal(): CBCTGPToothSliceMetadataGuideFrontal {
  return { Thickness: 0, Position: 0, Axis2: 0 };
}

export const CBCTGPToothSliceMetadataGuideFrontal = {
  encode(
    message: CBCTGPToothSliceMetadataGuideFrontal,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Thickness !== 0) {
      writer.uint32(13).float(message.Thickness);
    }
    if (message.Position !== 0) {
      writer.uint32(21).float(message.Position);
    }
    if (message.Axis2 !== 0) {
      writer.uint32(24).uint32(message.Axis2);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTGPToothSliceMetadataGuideFrontal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTGPToothSliceMetadataGuideFrontal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Thickness = reader.float();
          break;
        case 2:
          message.Position = reader.float();
          break;
        case 3:
          message.Axis2 = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTGPToothSliceMetadataGuideFrontal {
    return {
      Thickness: isSet(object.Thickness) ? Number(object.Thickness) : 0,
      Position: isSet(object.Position) ? Number(object.Position) : 0,
      Axis2: isSet(object.Axis2) ? Number(object.Axis2) : 0,
    };
  },

  toJSON(message: CBCTGPToothSliceMetadataGuideFrontal): unknown {
    const obj: any = {};
    message.Thickness !== undefined && (obj.Thickness = message.Thickness);
    message.Position !== undefined && (obj.Position = message.Position);
    message.Axis2 !== undefined && (obj.Axis2 = Math.round(message.Axis2));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CBCTGPToothSliceMetadataGuideFrontal>, I>,
  >(object: I): CBCTGPToothSliceMetadataGuideFrontal {
    const message = createBaseCBCTGPToothSliceMetadataGuideFrontal();
    message.Thickness = object.Thickness ?? 0;
    message.Position = object.Position ?? 0;
    message.Axis2 = object.Axis2 ?? 0;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPMPRSubVolume(): AssetContentGeneratedCBCTGPMPRSubVolume {
  return {
    Volume: undefined,
    Numeration: undefined,
    Position: undefined,
    PositionInner: undefined,
    WindowWidth: 0,
    WindowLevel: 0,
  };
}

export const AssetContentGeneratedCBCTGPMPRSubVolume = {
  encode(
    message: AssetContentGeneratedCBCTGPMPRSubVolume,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Volume !== undefined) {
      File.encode(message.Volume, writer.uint32(10).fork()).ldelim();
    }
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Position !== undefined) {
      BBox.encode(message.Position, writer.uint32(26).fork()).ldelim();
    }
    if (message.PositionInner !== undefined) {
      BBox.encode(message.PositionInner, writer.uint32(34).fork()).ldelim();
    }
    if (message.WindowWidth !== 0) {
      writer.uint32(45).float(message.WindowWidth);
    }
    if (message.WindowLevel !== 0) {
      writer.uint32(53).float(message.WindowLevel);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPMPRSubVolume {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTGPMPRSubVolume();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Volume = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 3:
          message.Position = BBox.decode(reader, reader.uint32());
          break;
        case 4:
          message.PositionInner = BBox.decode(reader, reader.uint32());
          break;
        case 5:
          message.WindowWidth = reader.float();
          break;
        case 6:
          message.WindowLevel = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPMPRSubVolume {
    return {
      Volume: isSet(object.Volume) ? File.fromJSON(object.Volume) : undefined,
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      Position: isSet(object.Position)
        ? BBox.fromJSON(object.Position)
        : undefined,
      PositionInner: isSet(object.PositionInner)
        ? BBox.fromJSON(object.PositionInner)
        : undefined,
      WindowWidth: isSet(object.WindowWidth) ? Number(object.WindowWidth) : 0,
      WindowLevel: isSet(object.WindowLevel) ? Number(object.WindowLevel) : 0,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPMPRSubVolume): unknown {
    const obj: any = {};
    message.Volume !== undefined &&
      (obj.Volume = message.Volume ? File.toJSON(message.Volume) : undefined);
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.Position !== undefined &&
      (obj.Position = message.Position
        ? BBox.toJSON(message.Position)
        : undefined);
    message.PositionInner !== undefined &&
      (obj.PositionInner = message.PositionInner
        ? BBox.toJSON(message.PositionInner)
        : undefined);
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTGPMPRSubVolume>, I>,
  >(object: I): AssetContentGeneratedCBCTGPMPRSubVolume {
    const message = createBaseAssetContentGeneratedCBCTGPMPRSubVolume();
    message.Volume =
      object.Volume !== undefined && object.Volume !== null
        ? File.fromPartial(object.Volume)
        : undefined;
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.Position =
      object.Position !== undefined && object.Position !== null
        ? BBox.fromPartial(object.Position)
        : undefined;
    message.PositionInner =
      object.PositionInner !== undefined && object.PositionInner !== null
        ? BBox.fromPartial(object.PositionInner)
        : undefined;
    message.WindowWidth = object.WindowWidth ?? 0;
    message.WindowLevel = object.WindowLevel ?? 0;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPPeriodontiumImage(): AssetContentGeneratedCBCTGPPeriodontiumImage {
  return { Periodontium: undefined, Numeration: undefined, ImageType: 0 };
}

export const AssetContentGeneratedCBCTGPPeriodontiumImage = {
  encode(
    message: AssetContentGeneratedCBCTGPPeriodontiumImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Periodontium !== undefined) {
      File.encode(message.Periodontium, writer.uint32(10).fork()).ldelim();
    }
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.ImageType !== 0) {
      writer.uint32(24).int32(message.ImageType);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPPeriodontiumImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTGPPeriodontiumImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Periodontium = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 3:
          message.ImageType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPPeriodontiumImage {
    return {
      Periodontium: isSet(object.Periodontium)
        ? File.fromJSON(object.Periodontium)
        : undefined,
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      ImageType: isSet(object.ImageType)
        ? assetContentGeneratedCBCTGPPeriodontiumImage_TypeFromJSON(
            object.ImageType,
          )
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPPeriodontiumImage): unknown {
    const obj: any = {};
    message.Periodontium !== undefined &&
      (obj.Periodontium = message.Periodontium
        ? File.toJSON(message.Periodontium)
        : undefined);
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.ImageType !== undefined &&
      (obj.ImageType = assetContentGeneratedCBCTGPPeriodontiumImage_TypeToJSON(
        message.ImageType,
      ));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTGPPeriodontiumImage>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTGPPeriodontiumImage {
    const message = createBaseAssetContentGeneratedCBCTGPPeriodontiumImage();
    message.Periodontium =
      object.Periodontium !== undefined && object.Periodontium !== null
        ? File.fromPartial(object.Periodontium)
        : undefined;
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.ImageType = object.ImageType ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

/** https://diagnocat.atlassian.net/wiki/spaces/RES/pages/211058694/Cephalometric+Analysis+Specification */
export enum CephalometricMeasureCode {
  CephalometricMeasure_Code_InvalidValue = 0,
  /** CephalometricMeasure_Code_SN_MP - SN-MP */
  CephalometricMeasure_Code_SN_MP = 1,
  /** CephalometricMeasure_Code_FH_OP - FH-OP */
  CephalometricMeasure_Code_FH_OP = 2,
  /** CephalometricMeasure_Code_Go_Ang - Go Ang */
  CephalometricMeasure_Code_Go_Ang = 3,
  /** CephalometricMeasure_Code_Mid_Height - Mid Height */
  CephalometricMeasure_Code_Mid_Height = 4,
  /** CephalometricMeasure_Code_Low_Height - Low Height */
  CephalometricMeasure_Code_Low_Height = 5,
  /** CephalometricMeasure_Code_Beta - Beta */
  CephalometricMeasure_Code_Beta = 6,
  /** CephalometricMeasure_Code_Wits - Wits */
  CephalometricMeasure_Code_Wits = 7,
  /** CephalometricMeasure_Code_NSeAr - NSeAr */
  CephalometricMeasure_Code_NSeAr = 8,
  /** CephalometricMeasure_Code_SNA - SNA */
  CephalometricMeasure_Code_SNA = 9,
  /** CephalometricMeasure_Code_SNB - SNB */
  CephalometricMeasure_Code_SNB = 10,
  /** CephalometricMeasure_Code_ANB - ANB */
  CephalometricMeasure_Code_ANB = 11,
  /** CephalometricMeasure_Code_MP - MP */
  CephalometricMeasure_Code_MP = 12,
  /** CephalometricMeasure_Code_S_N - S-N */
  CephalometricMeasure_Code_S_N = 13,
  /** CephalometricMeasure_Code_ULip_Eline - ULip to Eline */
  CephalometricMeasure_Code_ULip_Eline = 14,
  /** CephalometricMeasure_Code_LLip_Eline - LLip to Eline */
  CephalometricMeasure_Code_LLip_Eline = 15,
  /** CephalometricMeasure_Code_Overjet - Overjet */
  CephalometricMeasure_Code_Overjet = 16,
  /** CephalometricMeasure_Code_Overbite - Overbite */
  CephalometricMeasure_Code_Overbite = 17,
  /** CephalometricMeasure_Code_U1_SN - U1-SN */
  CephalometricMeasure_Code_U1_SN = 18,
  /** CephalometricMeasure_Code_U1_PP - U1-PP */
  CephalometricMeasure_Code_U1_PP = 19,
  /** CephalometricMeasure_Code_U1_TVL - U1-TVL (ANS) */
  CephalometricMeasure_Code_U1_TVL = 20,
  /** CephalometricMeasure_Code_Interincisal - Interincisal */
  CephalometricMeasure_Code_Interincisal = 21,
  /** CephalometricMeasure_Code_Li_MP - Li-MP */
  CephalometricMeasure_Code_Li_MP = 22,
  /** CephalometricMeasure_Code_Shimbashi - Shimbashi */
  CephalometricMeasure_Code_Shimbashi = 23,
  UNRECOGNIZED = -1,
}

export function cephalometricMeasureCodeFromJSON(
  object: any,
): CephalometricMeasureCode {
  switch (object) {
    case 0:
    case 'CephalometricMeasure_Code_InvalidValue':
      return CephalometricMeasureCode.CephalometricMeasure_Code_InvalidValue;
    case 1:
    case 'CephalometricMeasure_Code_SN_MP':
      return CephalometricMeasureCode.CephalometricMeasure_Code_SN_MP;
    case 2:
    case 'CephalometricMeasure_Code_FH_OP':
      return CephalometricMeasureCode.CephalometricMeasure_Code_FH_OP;
    case 3:
    case 'CephalometricMeasure_Code_Go_Ang':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Go_Ang;
    case 4:
    case 'CephalometricMeasure_Code_Mid_Height':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Mid_Height;
    case 5:
    case 'CephalometricMeasure_Code_Low_Height':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Low_Height;
    case 6:
    case 'CephalometricMeasure_Code_Beta':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Beta;
    case 7:
    case 'CephalometricMeasure_Code_Wits':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Wits;
    case 8:
    case 'CephalometricMeasure_Code_NSeAr':
      return CephalometricMeasureCode.CephalometricMeasure_Code_NSeAr;
    case 9:
    case 'CephalometricMeasure_Code_SNA':
      return CephalometricMeasureCode.CephalometricMeasure_Code_SNA;
    case 10:
    case 'CephalometricMeasure_Code_SNB':
      return CephalometricMeasureCode.CephalometricMeasure_Code_SNB;
    case 11:
    case 'CephalometricMeasure_Code_ANB':
      return CephalometricMeasureCode.CephalometricMeasure_Code_ANB;
    case 12:
    case 'CephalometricMeasure_Code_MP':
      return CephalometricMeasureCode.CephalometricMeasure_Code_MP;
    case 13:
    case 'CephalometricMeasure_Code_S_N':
      return CephalometricMeasureCode.CephalometricMeasure_Code_S_N;
    case 14:
    case 'CephalometricMeasure_Code_ULip_Eline':
      return CephalometricMeasureCode.CephalometricMeasure_Code_ULip_Eline;
    case 15:
    case 'CephalometricMeasure_Code_LLip_Eline':
      return CephalometricMeasureCode.CephalometricMeasure_Code_LLip_Eline;
    case 16:
    case 'CephalometricMeasure_Code_Overjet':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Overjet;
    case 17:
    case 'CephalometricMeasure_Code_Overbite':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Overbite;
    case 18:
    case 'CephalometricMeasure_Code_U1_SN':
      return CephalometricMeasureCode.CephalometricMeasure_Code_U1_SN;
    case 19:
    case 'CephalometricMeasure_Code_U1_PP':
      return CephalometricMeasureCode.CephalometricMeasure_Code_U1_PP;
    case 20:
    case 'CephalometricMeasure_Code_U1_TVL':
      return CephalometricMeasureCode.CephalometricMeasure_Code_U1_TVL;
    case 21:
    case 'CephalometricMeasure_Code_Interincisal':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Interincisal;
    case 22:
    case 'CephalometricMeasure_Code_Li_MP':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Li_MP;
    case 23:
    case 'CephalometricMeasure_Code_Shimbashi':
      return CephalometricMeasureCode.CephalometricMeasure_Code_Shimbashi;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CephalometricMeasureCode.UNRECOGNIZED;
  }
}

export function cephalometricMeasureCodeToJSON(
  object: CephalometricMeasureCode,
): string {
  switch (object) {
    case CephalometricMeasureCode.CephalometricMeasure_Code_InvalidValue:
      return 'CephalometricMeasure_Code_InvalidValue';
    case CephalometricMeasureCode.CephalometricMeasure_Code_SN_MP:
      return 'CephalometricMeasure_Code_SN_MP';
    case CephalometricMeasureCode.CephalometricMeasure_Code_FH_OP:
      return 'CephalometricMeasure_Code_FH_OP';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Go_Ang:
      return 'CephalometricMeasure_Code_Go_Ang';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Mid_Height:
      return 'CephalometricMeasure_Code_Mid_Height';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Low_Height:
      return 'CephalometricMeasure_Code_Low_Height';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Beta:
      return 'CephalometricMeasure_Code_Beta';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Wits:
      return 'CephalometricMeasure_Code_Wits';
    case CephalometricMeasureCode.CephalometricMeasure_Code_NSeAr:
      return 'CephalometricMeasure_Code_NSeAr';
    case CephalometricMeasureCode.CephalometricMeasure_Code_SNA:
      return 'CephalometricMeasure_Code_SNA';
    case CephalometricMeasureCode.CephalometricMeasure_Code_SNB:
      return 'CephalometricMeasure_Code_SNB';
    case CephalometricMeasureCode.CephalometricMeasure_Code_ANB:
      return 'CephalometricMeasure_Code_ANB';
    case CephalometricMeasureCode.CephalometricMeasure_Code_MP:
      return 'CephalometricMeasure_Code_MP';
    case CephalometricMeasureCode.CephalometricMeasure_Code_S_N:
      return 'CephalometricMeasure_Code_S_N';
    case CephalometricMeasureCode.CephalometricMeasure_Code_ULip_Eline:
      return 'CephalometricMeasure_Code_ULip_Eline';
    case CephalometricMeasureCode.CephalometricMeasure_Code_LLip_Eline:
      return 'CephalometricMeasure_Code_LLip_Eline';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Overjet:
      return 'CephalometricMeasure_Code_Overjet';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Overbite:
      return 'CephalometricMeasure_Code_Overbite';
    case CephalometricMeasureCode.CephalometricMeasure_Code_U1_SN:
      return 'CephalometricMeasure_Code_U1_SN';
    case CephalometricMeasureCode.CephalometricMeasure_Code_U1_PP:
      return 'CephalometricMeasure_Code_U1_PP';
    case CephalometricMeasureCode.CephalometricMeasure_Code_U1_TVL:
      return 'CephalometricMeasure_Code_U1_TVL';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Interincisal:
      return 'CephalometricMeasure_Code_Interincisal';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Li_MP:
      return 'CephalometricMeasure_Code_Li_MP';
    case CephalometricMeasureCode.CephalometricMeasure_Code_Shimbashi:
      return 'CephalometricMeasure_Code_Shimbashi';
    case CephalometricMeasureCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

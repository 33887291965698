import missingBW from './missingIconB&W.svg';
import unhealthyBW from './unhealthyIconB&W.svg';
import healthyBW from './healthyIconB&W.svg';
import treatedBW from './treatedIconB&W.svg';
import lowProbabilityBW from './lowProbabilityIconB&W.svg';
import missingIconColored from './missingIconColored.svg';
import unhealthyIconColored from './unhealthyIconColored.svg';
import healthyIconColored from './healthyIconColored.svg';
import treatedIconColored from './treatedIconColored.svg';
import lowProbabilityIconColored from './lowProbabilityIconColored.svg';

export const BWIcons = {
  missing: missingBW,
  unhealthy: unhealthyBW,
  healthy: healthyBW,
  treated: treatedBW,
  lowProbability: lowProbabilityBW,
};

export const ColoredIcons = {
  missing: missingIconColored,
  unhealthy: unhealthyIconColored,
  healthy: healthyIconColored,
  treated: treatedIconColored,
  lowProbability: lowProbabilityIconColored,
};

import { useModal } from '@/shared/hooks';

// TODO: add confirm and sign logic
export const useConfirmSignReportModal = () => {
  const { isOpen, openModal, closeModal } = useModal();

  const openConfirmSignReportModal = () => {
    openModal();
  };

  const closeConfirmSignReportModal = () => {
    closeModal();
  };

  return {
    isConfirmSignReportModalOpen: isOpen,
    openConfirmSignReportModal,
    closeConfirmSignReportModal,
  };
};

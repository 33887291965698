import _range from 'lodash/range';

import { JawQuartiles, JawQuartilesRanges } from './types';

export const jawQuartilesRanges: JawQuartilesRanges = {
  [JawQuartiles.topLeft]: _range(18, 10),
  [JawQuartiles.topRight]: _range(21, 29),
  [JawQuartiles.bottomLeft]: _range(48, 40),
  [JawQuartiles.bottomRight]: _range(31, 39),
  topLeftPrimary: _range(55, 50),
  topRightPrimary: _range(61, 66),
  bottomLeftPrimary: _range(85, 80),
  bottomRightPrimary: _range(71, 76),
};

export const UPPER_JAW_PERMANENT = [
  ...jawQuartilesRanges.topLeft,
  ...jawQuartilesRanges.topRight,
];

export const UPPER_JAW_PRIMARY = [
  ...jawQuartilesRanges.topLeftPrimary,
  ...jawQuartilesRanges.topRightPrimary,
];

export const LOWER_JAW_PERMANENT = [
  ...jawQuartilesRanges.bottomLeft,
  ...jawQuartilesRanges.bottomRight,
];

export const LOWER_JAW_PRIMARY = [
  ...jawQuartilesRanges.bottomLeftPrimary,
  ...jawQuartilesRanges.bottomRightPrimary,
];

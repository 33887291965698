import { useEffect, useRef } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Avatar } from '@/shared/ui';

import styles from './OrganizationRow.module.scss';

type RowProps = {
  organizationName: string;
  initials: string;
  avatarSrc: string;
  isCurrentOrganization?: boolean;
  showTooltipHandler?: (shouldShow: boolean) => void;
};

export const Row = (props: RowProps) => {
  const {
    organizationName,
    avatarSrc,
    initials,
    isCurrentOrganization,
    showTooltipHandler,
  } = props;

  const orgNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!showTooltipHandler) {
      return;
    }

    const scrollWidth = orgNameRef.current?.scrollWidth;
    const offsetWidth = orgNameRef.current?.offsetWidth;

    showTooltipHandler(
      scrollWidth && offsetWidth ? scrollWidth > offsetWidth : false,
    );
  }, [organizationName]);

  return (
    <div className={styles.info}>
      <Avatar src={avatarSrc} alt={organizationName} initials={initials} />

      <p
        className={cn(
          styles.textEllipsis,
          isCurrentOrganization && styles.currentOrganizationText,
          'p2',
        )}
        ref={orgNameRef}
      >
        {organizationName}{' '}
        {isCurrentOrganization && (
          <FormattedMessage
            id="controlPanel.myClinics.current"
            defaultMessage="(current)"
          />
        )}
      </p>
    </div>
  );
};

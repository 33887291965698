import { Organization } from '@/shared/api/protocol_gen/model/dto_organization';

// If there is text enclosed in quotation marks in the organization name, it
// returns it. Otherwise, it returns the full organization name
export const getOrganizationName = (organization: Organization): string =>
  organization?.Name.replace(/.*["'«“](.*?)["'»”].*/, '$1') ??
  organization?.Name;

export const getOrganizationInitials = (organization: Organization): string => {
  const organizationName = getOrganizationName(organization);

  const words = organizationName?.split(' ');

  if (words?.length > 1) {
    return words
      .map((word) => word?.charAt(0))
      .join('')
      .toUpperCase();
  }

  const word = words?.[0];

  if (word?.length <= 4) {
    return word;
  }

  return word?.charAt(0).toUpperCase();
};

import { ContentState, convertFromRaw } from 'draft-js';

export const getEditorContentFromValue = (value: string): ContentState => {
  if (value) {
    try {
      const parsedValue = JSON.parse(value);

      return convertFromRaw(parsedValue);
    } catch {
      return ContentState.createFromText(value);
    }
  } else {
    return ContentState.createFromText('');
  }
};

import { useCallback, useEffect } from 'react';

import { userModel } from '@/entities/user';

import api from '../api/api';

import { useAppSelector } from './store';

export const useHubspotChat = () => {
  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const email = user.PersonalData?.Emails[0];

  const disableHubspot = localStorage.getItem('disableHubspot') === 'true';

  const onConversationsAPIReady = useCallback(async () => {
    const restartHubspot = () => {
      window.HubSpotConversations?.clear({ resetWidget: true });
      window.HubSpotConversations?.widget.load();
    };

    if (email) {
      const { Token } = await api.hubspot.GetChatIdentificationToken({});

      window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: email,
        identificationToken: Token,
      };

      if (Token && window.HubSpotConversations) {
        restartHubspot();
      } else {
        window.hsConversationsOnReady = [restartHubspot];
      }
    } else {
      window.hsConversationsSettings = {
        loadImmediately: false,
      };
    }
  }, [window.HubSpotConversations, email]);

  useEffect(() => {
    if (!disableHubspot) {
      onConversationsAPIReady();
    }
  }, [email]);
};

import { MouseEventHandler } from 'react';
import { DropzoneState } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import fileIcon from '../../assets/file.svg';
import folderIcon from '../../assets/folder.svg';

import styles from './OrderReportDropzone.module.scss';

// https://github.com/facebook/react/issues/3468#issuecomment-1031366038
declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

type UploadStudyDropzoneProps = {
  dropzoneState: DropzoneState;
  canUploadFolder?: boolean;
};

export const OrderReportDropzone = (props: UploadStudyDropzoneProps) => {
  const {
    dropzoneState: { getRootProps, getInputProps, inputRef },
    canUploadFolder = true,
  } = props;

  const handleUploadFolder: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    inputRef.current?.click();
  };

  return (
    <div className={styles.container} {...getRootProps()}>
      <div className={styles.buttonsWrapper}>
        <div className={cn(styles.button)}>
          <input {...getInputProps()} />

          <img src={fileIcon} alt="" height={30} />

          <p className={cn(styles.title, 'p2')}>
            <FormattedMessage
              id="orderReportDropzone.title"
              defaultMessage="Upload files"
            />
          </p>
        </div>

        {canUploadFolder && (
          <div className={cn(styles.button)} onClick={handleUploadFolder}>
            <input
              id="uploadFolderInput"
              {...getInputProps()}
              multiple
              type="file"
              webkitdirectory=""
            />

            <img src={folderIcon} alt="" />

            <p className={cn(styles.title, 'p2')}>
              <FormattedMessage
                id="orderReportDropzone.titleFolder"
                defaultMessage="Upload folder"
              />
            </p>
          </div>
        )}
      </div>

      <p className={cn(styles.text, 'p3')}>
        <FormattedMessage
          id="orderReportDropzone.text"
          defaultMessage="Or just drag and drop file or folder here"
        />
      </p>
    </div>
  );
};

import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Column.module.scss';

type ColumnProps = {
  className?: string;
  children?: ReactNode;
};

export const Column: FC<ColumnProps> = (props) => {
  const { children, className } = props;
  return <div className={cn(styles.column, className)}>{children}</div>;
};

import { Icon } from '@/shared/ui';

import { UploadSessionPullStatus } from '@/features/uploadAsset';

import styles from './UploadStudyIcon.module.scss';

type UploadStatusIconProps = {
  status: UploadSessionPullStatus;
};

export const UploadStatusIcon = (props: UploadStatusIconProps) => {
  const { status } = props;

  switch (status) {
    case 'failed':
      return <Icon name="info" size={32} className={styles.failed} />;

    case 'uploading':
      return <Icon name="time" size={32} className={styles.uploading} />;

    case 'success':
      return <Icon name="check" size={32} className={styles.success} />;

    default:
      return null;
  }
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Timestamp } from '../google/protobuf/timestamp';
import { Created, Revision, Deleted } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

export enum PatientTaskTag {
  PatientTaskTag_Unspecified = 0,
  PatientTaskTag_New = 1,
  PatientTaskTag_Updated = 2,
  UNRECOGNIZED = -1,
}

export function patientTaskTagFromJSON(object: any): PatientTaskTag {
  switch (object) {
    case 0:
    case 'PatientTaskTag_Unspecified':
      return PatientTaskTag.PatientTaskTag_Unspecified;
    case 1:
    case 'PatientTaskTag_New':
      return PatientTaskTag.PatientTaskTag_New;
    case 2:
    case 'PatientTaskTag_Updated':
      return PatientTaskTag.PatientTaskTag_Updated;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PatientTaskTag.UNRECOGNIZED;
  }
}

export function patientTaskTagToJSON(object: PatientTaskTag): string {
  switch (object) {
    case PatientTaskTag.PatientTaskTag_Unspecified:
      return 'PatientTaskTag_Unspecified';
    case PatientTaskTag.PatientTaskTag_New:
      return 'PatientTaskTag_New';
    case PatientTaskTag.PatientTaskTag_Updated:
      return 'PatientTaskTag_Updated';
    case PatientTaskTag.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface PatientTask {
  ID: string;
  PatientID: string;
  OrganizationID: string;
  Title: string;
  Description: string;
  DueDate: Date | undefined;
  Assignees: string[];
  Order: number;
  Tag: PatientTaskTag;
  Closed: PatientTaskClosed | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

export interface PatientTaskClosed {
  Closed: boolean;
  By: string;
  At: Date | undefined;
}

function createBasePatientTask(): PatientTask {
  return {
    ID: '',
    PatientID: '',
    OrganizationID: '',
    Title: '',
    Description: '',
    DueDate: undefined,
    Assignees: [],
    Order: 0,
    Tag: 0,
    Closed: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const PatientTask = {
  encode(
    message: PatientTask,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.PatientID !== '') {
      writer.uint32(18).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(26).string(message.OrganizationID);
    }
    if (message.Title !== '') {
      writer.uint32(90).string(message.Title);
    }
    if (message.Description !== '') {
      writer.uint32(98).string(message.Description);
    }
    if (message.DueDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.DueDate),
        writer.uint32(106).fork(),
      ).ldelim();
    }
    for (const v of message.Assignees) {
      writer.uint32(114).string(v!);
    }
    if (message.Order !== 0) {
      writer.uint32(168).uint32(message.Order);
    }
    if (message.Tag !== 0) {
      writer.uint32(176).int32(message.Tag);
    }
    if (message.Closed !== undefined) {
      PatientTaskClosed.encode(
        message.Closed,
        writer.uint32(410).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientTask {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.PatientID = reader.string();
          break;
        case 3:
          message.OrganizationID = reader.string();
          break;
        case 11:
          message.Title = reader.string();
          break;
        case 12:
          message.Description = reader.string();
          break;
        case 13:
          message.DueDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 14:
          message.Assignees.push(reader.string());
          break;
        case 21:
          message.Order = reader.uint32();
          break;
        case 22:
          message.Tag = reader.int32() as any;
          break;
        case 51:
          message.Closed = PatientTaskClosed.decode(reader, reader.uint32());
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientTask {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Title: isSet(object.Title) ? String(object.Title) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      DueDate: isSet(object.DueDate)
        ? fromJsonTimestamp(object.DueDate)
        : undefined,
      Assignees: Array.isArray(object?.Assignees)
        ? object.Assignees.map((e: any) => String(e))
        : [],
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      Tag: isSet(object.Tag) ? patientTaskTagFromJSON(object.Tag) : 0,
      Closed: isSet(object.Closed)
        ? PatientTaskClosed.fromJSON(object.Closed)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: PatientTask): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Title !== undefined && (obj.Title = message.Title);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.DueDate !== undefined &&
      (obj.DueDate = message.DueDate.toISOString());
    if (message.Assignees) {
      obj.Assignees = message.Assignees.map((e) => e);
    } else {
      obj.Assignees = [];
    }
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    message.Tag !== undefined && (obj.Tag = patientTaskTagToJSON(message.Tag));
    message.Closed !== undefined &&
      (obj.Closed = message.Closed
        ? PatientTaskClosed.toJSON(message.Closed)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientTask>, I>>(
    object: I,
  ): PatientTask {
    const message = createBasePatientTask();
    message.ID = object.ID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.Title = object.Title ?? '';
    message.Description = object.Description ?? '';
    message.DueDate = object.DueDate ?? undefined;
    message.Assignees = object.Assignees?.map((e) => e) || [];
    message.Order = object.Order ?? 0;
    message.Tag = object.Tag ?? 0;
    message.Closed =
      object.Closed !== undefined && object.Closed !== null
        ? PatientTaskClosed.fromPartial(object.Closed)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBasePatientTaskClosed(): PatientTaskClosed {
  return { Closed: false, By: '', At: undefined };
}

export const PatientTaskClosed = {
  encode(
    message: PatientTaskClosed,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Closed === true) {
      writer.uint32(8).bool(message.Closed);
    }
    if (message.By !== '') {
      writer.uint32(18).string(message.By);
    }
    if (message.At !== undefined) {
      Timestamp.encode(
        toTimestamp(message.At),
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientTaskClosed {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientTaskClosed();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Closed = reader.bool();
          break;
        case 2:
          message.By = reader.string();
          break;
        case 3:
          message.At = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientTaskClosed {
    return {
      Closed: isSet(object.Closed) ? Boolean(object.Closed) : false,
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? fromJsonTimestamp(object.At) : undefined,
    };
  },

  toJSON(message: PatientTaskClosed): unknown {
    const obj: any = {};
    message.Closed !== undefined && (obj.Closed = message.Closed);
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = message.At.toISOString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientTaskClosed>, I>>(
    object: I,
  ): PatientTaskClosed {
    const message = createBasePatientTaskClosed();
    message.Closed = object.Closed ?? false;
    message.By = object.By ?? '';
    message.At = object.At ?? undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { Report2DImageMeta } from '@/shared/api/protocol_gen/model/dto_report_type_2D_common';

// NOTE: In some cases the assetID will be matched with GeneratedAssetID.
// In other cases GeneratedAssetID will be empty and assetID will be matched with StudyAssetID.
export const getImageMetaIDByAssetID = (
  imagesMeta: Report2DImageMeta[],
  assetID: string,
) =>
  imagesMeta.find(
    (imageMeta) =>
      imageMeta.GeneratedAssetID === assetID ||
      imageMeta.StudyAssetID === assetID,
  )?.ID || ''; // TODO: find more elegant solution

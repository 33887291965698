import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { getPatientFullName, patientModel } from '@/entities/patient';
import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { getROITeethIDsAfterRemoveOne, toothModel } from '@/entities/tooth';

// TODO: [8|h] Decompose code inside this hook and remove it.
export const useReport_DEPRECATED = (reportID: string, patientID: string) => {
  const dispatch = useAppDispatch();

  const selectReport = useMemo(
    () => reportsModel.selectors.selectByID(reportID),
    [reportID],
  );
  const selectPatient = useMemo(
    () => patientModel.selectors.selectPatientByID(patientID),
    [patientID],
  );

  const report = useAppSelector(selectReport);
  const patient = useAppSelector(selectPatient);

  const reportLoading = useAppSelector(reportsModel.selectors.selectLoading);
  const patientLoading = useAppSelector(patientModel.selectors.selectLoading);

  const { checkReportSignature } = useCheckReportSignature();

  const isReportLoading =
    reportLoading === 'idle' || reportLoading === 'pending';

  const isPatientLoading =
    patientLoading === 'idle' || patientLoading === 'pending';

  const ROITeeth: Tooth[] = useAppSelector(
    toothModel.selectors.selectReportROITeeth(reportID),
  );

  const isAllTeethApproved = ROITeeth.every((tooth) => tooth.IsApproved);

  const isReportSigned = report?.Signature?.UserSignatures.length;

  const patientFullName = getPatientFullName(patient);
  const patientDoB =
    patient?.PersonalData?.DateOfBirth &&
    format(new Date(patient?.PersonalData?.DateOfBirth), 'd MMMM, y');

  const handleRemoveToothFromROI = useCallback(
    (toothID: string) => {
      checkReportSignature({
        onSignatureChecked: async () => {
          const newROITeethIDs = getROITeethIDsAfterRemoveOne(
            ROITeeth,
            toothID,
          );

          const { AllReportTeeth } = await dispatch(
            reportsModel.thunks.setROI({
              ReportID: reportID,
              ROIToothIDs: newROITeethIDs ?? [],
            }),
          ).unwrap();

          dispatch(toothModel.actions.setMany(AllReportTeeth));
        },
      });
    },
    [ROITeeth, dispatch, reportID, checkReportSignature],
  );

  return {
    report,
    isReportLoading,
    ROITeeth,
    isAllTeethApproved,
    isReportSigned,
    handleRemoveToothFromROI,
    patient,
    isPatientLoading,
    patientFullName,
    patientDoB,
  };
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ConditionCode,
  conditionCodeFromJSON,
  conditionCodeToJSON,
} from '../model/dto_report_condition_codes';
import { File } from '../model/dto_asset_common';
import {
  ToothNumeration,
  IOSAnatomy,
  iOSAnatomyFromJSON,
  iOSAnatomyToJSON,
} from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export enum MeshFaceSegmentationInstancePackStrategy {
  Mesh_FaceSegmentation_Instance_PackStrategy_InvalidValue = 0,
  /**
   * Mesh_FaceSegmentation_Instance_PackStrategy_UInt32 - Each face index is packed into a single uint32.
   *
   *  Example:
   *    indices = [0, 1, 2, 3, 5, 8, 9, 10, 11, 12]
   *    packed_bytes = bytearray([0, 1, 2, 3, 5, 8, 9, 10, 11, 12])
   */
  Mesh_FaceSegmentation_Instance_PackStrategy_UInt32 = 1,
  /**
   * Mesh_FaceSegmentation_Instance_PackStrategy_RangeUInt32 - Face indices are grouped into ranges (start is included, stop is not included) and
   * each range is packed into a pair of uint32.
   *
   * Example:
   *    indices = [0, 1, 2, 3, 5, 8, 9, 10, 11, 12]
   *    ranges = [[0, 4], [5, 6], [8, 13]]
   *    packed_bytes = bytearray([0, 4, 5, 6, 8, 13])
   */
  Mesh_FaceSegmentation_Instance_PackStrategy_RangeUInt32 = 2,
  UNRECOGNIZED = -1,
}

export function meshFaceSegmentationInstancePackStrategyFromJSON(
  object: any,
): MeshFaceSegmentationInstancePackStrategy {
  switch (object) {
    case 0:
    case 'Mesh_FaceSegmentation_Instance_PackStrategy_InvalidValue':
      return MeshFaceSegmentationInstancePackStrategy.Mesh_FaceSegmentation_Instance_PackStrategy_InvalidValue;
    case 1:
    case 'Mesh_FaceSegmentation_Instance_PackStrategy_UInt32':
      return MeshFaceSegmentationInstancePackStrategy.Mesh_FaceSegmentation_Instance_PackStrategy_UInt32;
    case 2:
    case 'Mesh_FaceSegmentation_Instance_PackStrategy_RangeUInt32':
      return MeshFaceSegmentationInstancePackStrategy.Mesh_FaceSegmentation_Instance_PackStrategy_RangeUInt32;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MeshFaceSegmentationInstancePackStrategy.UNRECOGNIZED;
  }
}

export function meshFaceSegmentationInstancePackStrategyToJSON(
  object: MeshFaceSegmentationInstancePackStrategy,
): string {
  switch (object) {
    case MeshFaceSegmentationInstancePackStrategy.Mesh_FaceSegmentation_Instance_PackStrategy_InvalidValue:
      return 'Mesh_FaceSegmentation_Instance_PackStrategy_InvalidValue';
    case MeshFaceSegmentationInstancePackStrategy.Mesh_FaceSegmentation_Instance_PackStrategy_UInt32:
      return 'Mesh_FaceSegmentation_Instance_PackStrategy_UInt32';
    case MeshFaceSegmentationInstancePackStrategy.Mesh_FaceSegmentation_Instance_PackStrategy_RangeUInt32:
      return 'Mesh_FaceSegmentation_Instance_PackStrategy_RangeUInt32';
    case MeshFaceSegmentationInstancePackStrategy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Asset with per-face mesh segmentation. */
export interface AssetContentGeneratedMeshFaceSegmentation {
  /**
   * [required]: ID of the file (corresponding to one of the files in
   * `AssetContent_Study_IOS_Meshes`) for which face segmentation is done.
   */
  TargetFileID: string;
  /**
   * [required]: File as octet stream of concatenated packed structures' face indices.
   *
   * Roughly, the algorithm of packing face indices may be described with the following
   * Python-like pseudo-code:
   *    class PackStrategy(Enum): ...
   *
   *    def pack_face_indices(indices: list[int], strategy: PackStrategy) -> bytes: ...
   *
   *    # For each target file we may segment several instances (first list) that may
   *    # be assigned to multiple geometries inside the target file (second list) and
   *    # segmentation represents face indices for the geometry (third list).
   *    segmented_instances_faces_indices: list[list[list[int]]]
   *
   *    octet_stream = bytearray()
   *    for segmented_instance_faces_indices in segmented_instances_faces_indices:
   *      for instance_face_indices in segmented_instance_faces_indices:
   *        packed_face_indices = pack_face_indices(instance_face_indices, strategy)
   *        octet_stream.extend(packed_face_indices)
   *
   *    open("file_name", "wb").write(octet_stream)
   */
  FaceIndices: File | undefined;
  /** [required]: Segmented instances. */
  Instances: MeshFaceSegmentationInstance[];
}

export interface MeshFaceSegmentationInstance {
  /** [required]: Face locations. */
  FaceLocations: MeshFaceSegmentationInstanceFaceLocation[];
  Tooth: ToothNumeration | undefined;
  ToothCondition: MeshFaceSegmentationToothConditionInstance | undefined;
  MaxFaxAnatomy: IOSAnatomy | undefined;
}

export interface MeshFaceSegmentationToothConditionInstance {
  /** `Tooth`: [required] */
  Tooth: ToothNumeration | undefined;
  /**
   * `Code`: [required]
   * Expected conditions:
   * * Abfraction
   * * CariesSigns
   * * DentalCalculus
   * * MarginalDiscrepancy
   */
  Code: ConditionCode;
}

export interface MeshFaceSegmentationInstanceFaceLocation {
  /**
   * [required]: Index (zero based) of geometry within target file, segmented
   * instance is linked to.
   */
  TargetGeometryIndex: number;
  /** [required]: Strategy that was used to pack face indices into bytes. */
  PackStrategy: MeshFaceSegmentationInstancePackStrategy;
  /**
   * [required]: Starting byte (zero based) for instance's packed indices w.r.t. the
   * `AssetContent_Generated_Mesh_FaceSegmentation.File` octet stream.
   */
  StartByte: number;
  /** [required]: Size of packed indices in bytes. */
  ContentSize: number;
}

function createBaseAssetContentGeneratedMeshFaceSegmentation(): AssetContentGeneratedMeshFaceSegmentation {
  return { TargetFileID: '', FaceIndices: undefined, Instances: [] };
}

export const AssetContentGeneratedMeshFaceSegmentation = {
  encode(
    message: AssetContentGeneratedMeshFaceSegmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TargetFileID !== '') {
      writer.uint32(10).string(message.TargetFileID);
    }
    if (message.FaceIndices !== undefined) {
      File.encode(message.FaceIndices, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Instances) {
      MeshFaceSegmentationInstance.encode(
        v!,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMeshFaceSegmentation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedMeshFaceSegmentation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TargetFileID = reader.string();
          break;
        case 2:
          message.FaceIndices = File.decode(reader, reader.uint32());
          break;
        case 3:
          message.Instances.push(
            MeshFaceSegmentationInstance.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedMeshFaceSegmentation {
    return {
      TargetFileID: isSet(object.TargetFileID)
        ? String(object.TargetFileID)
        : '',
      FaceIndices: isSet(object.FaceIndices)
        ? File.fromJSON(object.FaceIndices)
        : undefined,
      Instances: Array.isArray(object?.Instances)
        ? object.Instances.map((e: any) =>
            MeshFaceSegmentationInstance.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: AssetContentGeneratedMeshFaceSegmentation): unknown {
    const obj: any = {};
    message.TargetFileID !== undefined &&
      (obj.TargetFileID = message.TargetFileID);
    message.FaceIndices !== undefined &&
      (obj.FaceIndices = message.FaceIndices
        ? File.toJSON(message.FaceIndices)
        : undefined);
    if (message.Instances) {
      obj.Instances = message.Instances.map((e) =>
        e ? MeshFaceSegmentationInstance.toJSON(e) : undefined,
      );
    } else {
      obj.Instances = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedMeshFaceSegmentation>, I>,
  >(object: I): AssetContentGeneratedMeshFaceSegmentation {
    const message = createBaseAssetContentGeneratedMeshFaceSegmentation();
    message.TargetFileID = object.TargetFileID ?? '';
    message.FaceIndices =
      object.FaceIndices !== undefined && object.FaceIndices !== null
        ? File.fromPartial(object.FaceIndices)
        : undefined;
    message.Instances =
      object.Instances?.map((e) =>
        MeshFaceSegmentationInstance.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseMeshFaceSegmentationInstance(): MeshFaceSegmentationInstance {
  return {
    FaceLocations: [],
    Tooth: undefined,
    ToothCondition: undefined,
    MaxFaxAnatomy: undefined,
  };
}

export const MeshFaceSegmentationInstance = {
  encode(
    message: MeshFaceSegmentationInstance,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.FaceLocations) {
      MeshFaceSegmentationInstanceFaceLocation.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Tooth !== undefined) {
      ToothNumeration.encode(message.Tooth, writer.uint32(18).fork()).ldelim();
    }
    if (message.ToothCondition !== undefined) {
      MeshFaceSegmentationToothConditionInstance.encode(
        message.ToothCondition,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.MaxFaxAnatomy !== undefined) {
      writer.uint32(32).int32(message.MaxFaxAnatomy);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MeshFaceSegmentationInstance {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeshFaceSegmentationInstance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FaceLocations.push(
            MeshFaceSegmentationInstanceFaceLocation.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 2:
          message.Tooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 3:
          message.ToothCondition =
            MeshFaceSegmentationToothConditionInstance.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 4:
          message.MaxFaxAnatomy = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MeshFaceSegmentationInstance {
    return {
      FaceLocations: Array.isArray(object?.FaceLocations)
        ? object.FaceLocations.map((e: any) =>
            MeshFaceSegmentationInstanceFaceLocation.fromJSON(e),
          )
        : [],
      Tooth: isSet(object.Tooth)
        ? ToothNumeration.fromJSON(object.Tooth)
        : undefined,
      ToothCondition: isSet(object.ToothCondition)
        ? MeshFaceSegmentationToothConditionInstance.fromJSON(
            object.ToothCondition,
          )
        : undefined,
      MaxFaxAnatomy: isSet(object.MaxFaxAnatomy)
        ? iOSAnatomyFromJSON(object.MaxFaxAnatomy)
        : undefined,
    };
  },

  toJSON(message: MeshFaceSegmentationInstance): unknown {
    const obj: any = {};
    if (message.FaceLocations) {
      obj.FaceLocations = message.FaceLocations.map((e) =>
        e ? MeshFaceSegmentationInstanceFaceLocation.toJSON(e) : undefined,
      );
    } else {
      obj.FaceLocations = [];
    }
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth
        ? ToothNumeration.toJSON(message.Tooth)
        : undefined);
    message.ToothCondition !== undefined &&
      (obj.ToothCondition = message.ToothCondition
        ? MeshFaceSegmentationToothConditionInstance.toJSON(
            message.ToothCondition,
          )
        : undefined);
    message.MaxFaxAnatomy !== undefined &&
      (obj.MaxFaxAnatomy =
        message.MaxFaxAnatomy !== undefined
          ? iOSAnatomyToJSON(message.MaxFaxAnatomy)
          : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MeshFaceSegmentationInstance>, I>>(
    object: I,
  ): MeshFaceSegmentationInstance {
    const message = createBaseMeshFaceSegmentationInstance();
    message.FaceLocations =
      object.FaceLocations?.map((e) =>
        MeshFaceSegmentationInstanceFaceLocation.fromPartial(e),
      ) || [];
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? ToothNumeration.fromPartial(object.Tooth)
        : undefined;
    message.ToothCondition =
      object.ToothCondition !== undefined && object.ToothCondition !== null
        ? MeshFaceSegmentationToothConditionInstance.fromPartial(
            object.ToothCondition,
          )
        : undefined;
    message.MaxFaxAnatomy = object.MaxFaxAnatomy ?? undefined;
    return message;
  },
};

function createBaseMeshFaceSegmentationToothConditionInstance(): MeshFaceSegmentationToothConditionInstance {
  return { Tooth: undefined, Code: 0 };
}

export const MeshFaceSegmentationToothConditionInstance = {
  encode(
    message: MeshFaceSegmentationToothConditionInstance,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      ToothNumeration.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.Code !== 0) {
      writer.uint32(16).int32(message.Code);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MeshFaceSegmentationToothConditionInstance {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeshFaceSegmentationToothConditionInstance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.Code = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MeshFaceSegmentationToothConditionInstance {
    return {
      Tooth: isSet(object.Tooth)
        ? ToothNumeration.fromJSON(object.Tooth)
        : undefined,
      Code: isSet(object.Code) ? conditionCodeFromJSON(object.Code) : 0,
    };
  },

  toJSON(message: MeshFaceSegmentationToothConditionInstance): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth
        ? ToothNumeration.toJSON(message.Tooth)
        : undefined);
    message.Code !== undefined &&
      (obj.Code = conditionCodeToJSON(message.Code));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<MeshFaceSegmentationToothConditionInstance>, I>,
  >(object: I): MeshFaceSegmentationToothConditionInstance {
    const message = createBaseMeshFaceSegmentationToothConditionInstance();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? ToothNumeration.fromPartial(object.Tooth)
        : undefined;
    message.Code = object.Code ?? 0;
    return message;
  },
};

function createBaseMeshFaceSegmentationInstanceFaceLocation(): MeshFaceSegmentationInstanceFaceLocation {
  return {
    TargetGeometryIndex: 0,
    PackStrategy: 0,
    StartByte: 0,
    ContentSize: 0,
  };
}

export const MeshFaceSegmentationInstanceFaceLocation = {
  encode(
    message: MeshFaceSegmentationInstanceFaceLocation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.TargetGeometryIndex !== 0) {
      writer.uint32(8).int32(message.TargetGeometryIndex);
    }
    if (message.PackStrategy !== 0) {
      writer.uint32(16).int32(message.PackStrategy);
    }
    if (message.StartByte !== 0) {
      writer.uint32(24).uint64(message.StartByte);
    }
    if (message.ContentSize !== 0) {
      writer.uint32(32).uint64(message.ContentSize);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MeshFaceSegmentationInstanceFaceLocation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeshFaceSegmentationInstanceFaceLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TargetGeometryIndex = reader.int32();
          break;
        case 2:
          message.PackStrategy = reader.int32() as any;
          break;
        case 3:
          message.StartByte = longToNumber(reader.uint64() as Long);
          break;
        case 4:
          message.ContentSize = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MeshFaceSegmentationInstanceFaceLocation {
    return {
      TargetGeometryIndex: isSet(object.TargetGeometryIndex)
        ? Number(object.TargetGeometryIndex)
        : 0,
      PackStrategy: isSet(object.PackStrategy)
        ? meshFaceSegmentationInstancePackStrategyFromJSON(object.PackStrategy)
        : 0,
      StartByte: isSet(object.StartByte) ? Number(object.StartByte) : 0,
      ContentSize: isSet(object.ContentSize) ? Number(object.ContentSize) : 0,
    };
  },

  toJSON(message: MeshFaceSegmentationInstanceFaceLocation): unknown {
    const obj: any = {};
    message.TargetGeometryIndex !== undefined &&
      (obj.TargetGeometryIndex = Math.round(message.TargetGeometryIndex));
    message.PackStrategy !== undefined &&
      (obj.PackStrategy = meshFaceSegmentationInstancePackStrategyToJSON(
        message.PackStrategy,
      ));
    message.StartByte !== undefined &&
      (obj.StartByte = Math.round(message.StartByte));
    message.ContentSize !== undefined &&
      (obj.ContentSize = Math.round(message.ContentSize));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<MeshFaceSegmentationInstanceFaceLocation>, I>,
  >(object: I): MeshFaceSegmentationInstanceFaceLocation {
    const message = createBaseMeshFaceSegmentationInstanceFaceLocation();
    message.TargetGeometryIndex = object.TargetGeometryIndex ?? 0;
    message.PackStrategy = object.PackStrategy ?? 0;
    message.StartByte = object.StartByte ?? 0;
    message.ContentSize = object.ContentSize ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { IconNames } from '@/shared/ui';

export const MPR_CONTROL_TOOLS: Record<string, IconNames> = {
  normal: 'move2',
  brightness: 'brightness',
  zoom: 'zoom',
  pan: 'movepawFilled',
  depth: 'split',
  reset: 'rotate',
};

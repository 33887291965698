import { defineMessages } from 'react-intl';

import { MPRInputMode } from 'graphics';

export const invoiceStatusDefaultMessages = defineMessages<
  MPRInputMode | 'reset'
>({
  normal: {
    id: 'mprControlTools.axes',
    defaultMessage: 'Axes',
  },
  brightness: {
    id: 'mprControlTools.brightness',
    defaultMessage: 'Brightness / Contrast',
  },
  zoom: {
    id: 'mprControlTools.zoom',
    defaultMessage: 'Zoom',
  },
  pan: {
    id: 'mprControlTools.pan',
    defaultMessage: 'Pan',
  },
  depth: {
    id: 'mprControlTools.translate',
    defaultMessage: 'Translate (Ctrl + Scroll)',
  },
  reset: {
    id: 'mprControlTools.reset',
    defaultMessage: 'Reset',
  },
});

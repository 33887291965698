import {
  AnnotationsLayer,
  AnnotationTypeGuide,
} from '@/shared/api/protocol_gen/model/dto_common_image_annotations';

export const makeGuideLines = (
  annotations: AnnotationsLayer[] = [],
): AnnotationTypeGuide[] =>
  annotations.reduce((guideLines, item) => {
    item.Annotations.forEach((annotation) => {
      if (annotation.Guide) {
        guideLines.push(annotation.Guide);
      }
    });

    return guideLines;
  }, [] as AnnotationTypeGuide[]);

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { ConditionInterface } from '../config/types';
import {
  CombinedConditionGroupCode,
  COMBINED_CONDITIONS,
} from '../config/combinedConditions';

const INITIAL_COMBINED_CONDITION_CODES = {
  [CombinedConditionGroupCode.ANATOMY]: [],
  [CombinedConditionGroupCode.CARIES_AND_CROWN]: [],
  [CombinedConditionGroupCode.ENDO]: [],
  [CombinedConditionGroupCode.PERIO]: [],
  [CombinedConditionGroupCode.MISSING_NON_RESTORABLE]: [],
  [CombinedConditionGroupCode.ORTHO]: [],
  [CombinedConditionGroupCode.CROWN_INDICATION]: [],
  [CombinedConditionGroupCode.TREATMENT]: [],
  [CombinedConditionGroupCode.SURGICAL]: [],
} as Record<CombinedConditionGroupCode, ConditionCode[]>;

const getCombinedConditionGroupCodeList = (conditionCode: ConditionCode) => {
  const combinedConditionGroupCodeList = COMBINED_CONDITIONS.reduce(
    (result, combinedConditionGroup) => {
      if (combinedConditionGroup.conditionCodes?.includes(conditionCode)) {
        return [...result, combinedConditionGroup.code];
      }

      return result;
    },
    [] as CombinedConditionGroupCode[],
  );

  return combinedConditionGroupCodeList;
};

// rename to getCombinedConditionItems ?
export const getCombinedConditions = (
  enabledConditionItems: ConditionInterface[],
) => {
  const isConditionItemEnabledByCode = (code: ConditionCode): boolean => {
    const result = !!enabledConditionItems.find(
      (condition) => condition.code === code,
    );

    return result;
  };

  const getEnabledConditionsCount = (
    conditionCodes: ConditionCode[],
  ): number => {
    const counter = conditionCodes.reduce((result, code) => {
      if (isConditionItemEnabledByCode(code)) {
        return result + 1;
      }

      return result;
    }, 0);

    return counter;
  };

  const combinedConditions = enabledConditionItems.reduce(
    (result, condition) => {
      const conditionCode = condition.code;

      const updateResultObject = (
        targetCombinedAttrCode: CombinedConditionGroupCode,
        _conditionCode: ConditionCode,
      ) => ({
        ...result,
        [targetCombinedAttrCode]: [
          ...result[targetCombinedAttrCode],
          _conditionCode,
        ],
      });

      //
      //
      // PERIODONTAL RULES
      //
      const PERIO = ConditionCode.PeriodontalBoneLoss; // 'periodontal_bone_loss';
      const PERIO_SEVERE =
        ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe; // 'periodontal_bone_loss_severe';

      if (conditionCode === PERIO) {
        // transfer by periodontal_bone_loss_severe tag
        if (isConditionItemEnabledByCode(PERIO_SEVERE)) {
          return updateResultObject(
            CombinedConditionGroupCode.MISSING_NON_RESTORABLE,
            PERIO,
          );
        }

        return updateResultObject(CombinedConditionGroupCode.PERIO, PERIO);
      }

      //
      //
      // FILLING RULES
      //
      const FILLING = ConditionCode.Filling; // 'filling';
      const FILLING_PULP = ConditionCode.Child_DefectDepth_WithPulpExposure; // 'filling_depth/pulp'; ???
      const FILLING_MAX_SURFACE_LIMIT = 3;
      const FILLING_SURFACES_CODES = [
        ConditionCode.Child_DefectSurface_Mesial, // 'filling_surface/mesial', ???
        ConditionCode.Child_DefectSurface_Distal, // 'filling_surface/distal',
        ConditionCode.Child_DefectSurface_Occlusial, // 'filling_surface/occlusial',
        ConditionCode.Child_DefectSurface_IncisalEdge, // 'filling_surface/incisal',
        ConditionCode.Child_DefectSurface_Buccal, // 'filling_surface/buccal',
        ConditionCode.Child_DefectSurface_Vestibular, // 'filling_surface/vestibular',
        ConditionCode.Child_DefectSurface_Lingual, // 'filling_surface/lingual',
      ];

      if (conditionCode === FILLING) {
        // transfer by filling_depth/pulp tag
        if (isConditionItemEnabledByCode(FILLING_PULP)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            FILLING,
          );
        }

        // transfer if more than 3 surfaces enabled
        if (
          getEnabledConditionsCount(FILLING_SURFACES_CODES) >=
          FILLING_MAX_SURFACE_LIMIT
        ) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            FILLING,
          );
        }

        return updateResultObject(
          CombinedConditionGroupCode.TREATMENT,
          FILLING,
        );
      }

      //
      //
      // CROWN FRACTURE RULES
      //
      const CROWN_FRACTURE = ConditionCode.CrownFracture; // 'crown_fracture';
      const CROWN_FRACTURE_PULP = ConditionCode.Child_DefectDepth_WithPulpExposure; // 'crown_fracture_depth/pulp'; ???

      if (conditionCode === CROWN_FRACTURE) {
        // transfer by crown_fracture_depth/pulp tag
        if (isConditionItemEnabledByCode(CROWN_FRACTURE_PULP)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            CROWN_FRACTURE,
          );
        }

        return updateResultObject(
          CombinedConditionGroupCode.CARIES_AND_CROWN,
          CROWN_FRACTURE,
        );
      }

      //
      //
      // ARTIFICAL CROWN RULES
      //
      const ARTIFICAL_CROWN = ConditionCode.ArtificialCrown; // 'artificial_crown';
      const OVERHANG = ConditionCode.Overhang; // 'overhang';
      const OPEN_MARGIN = ConditionCode.OpenMargin; // 'open_margin';
      const LACK_OF_CONTACT = ConditionCode.LackOfInterproximalContact; // 'lack_of_interproximal_contact';

      if (conditionCode === OVERHANG) {
        // transfer if artifical crown enabled
        if (isConditionItemEnabledByCode(ARTIFICAL_CROWN)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            OVERHANG,
          );
        }

        return updateResultObject(
          CombinedConditionGroupCode.CARIES_AND_CROWN,
          OVERHANG,
        );
      }

      if (conditionCode === OPEN_MARGIN) {
        // transfer if artifical crown enabled
        if (isConditionItemEnabledByCode(ARTIFICAL_CROWN)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            OPEN_MARGIN,
          );
        }

        return updateResultObject(
          CombinedConditionGroupCode.CARIES_AND_CROWN,
          OPEN_MARGIN,
        );
      }

      if (conditionCode === LACK_OF_CONTACT) {
        // transfer if artifical crown enabled
        if (isConditionItemEnabledByCode(ARTIFICAL_CROWN)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            LACK_OF_CONTACT,
          );
        }

        return updateResultObject(
          CombinedConditionGroupCode.CARIES_AND_CROWN,
          LACK_OF_CONTACT,
        );
      }

      //
      //
      // CARIES RULES
      //
      const CARIES = ConditionCode.CariesSigns; // 'caries_signs';
      const CARIES_PULP = ConditionCode.Child_DefectDepth_WithPulpExposure; // 'depth/pulp'; ???
      const CARIES_ROOT = ConditionCode.Child_DefectDepth_Root; // 'depth/root'; ???
      const CARIES_MAX_SURFACE_LIMIT = 3;
      const CARIES_SURFACE_CODES = [
        ConditionCode.Child_DefectSurface_Mesial, // 'surface/mesial',
        ConditionCode.Child_DefectSurface_Distal, // 'surface/distal',
        ConditionCode.Child_DefectSurface_Occlusial, // 'surface/occlusial',
        ConditionCode.Child_DefectSurface_IncisalEdge, // 'surface/incisal',
        ConditionCode.Child_DefectSurface_Buccal, // 'surface/buccal',
        ConditionCode.Child_DefectSurface_Vestibular, // 'surface/vestibular',
        ConditionCode.Child_DefectSurface_Lingual, // 'surface/lingual',
      ];

      if (conditionCode === CARIES) {
        // transfer by depth/pulp tag
        if (isConditionItemEnabledByCode(CARIES_PULP)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            CARIES,
          );
        }

        // transfer by depth/root tag
        if (isConditionItemEnabledByCode(CARIES_ROOT)) {
          return updateResultObject(
            CombinedConditionGroupCode.MISSING_NON_RESTORABLE,
            CARIES,
          );
        }

        // transfer if more than 3 surfaces enabled
        if (
          getEnabledConditionsCount(CARIES_SURFACE_CODES) >=
          CARIES_MAX_SURFACE_LIMIT
        ) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            CARIES,
          );
        }

        return updateResultObject(
          CombinedConditionGroupCode.CARIES_AND_CROWN,
          CARIES,
        );
      }

      //
      //
      // SECONDARY CARIES RULES
      //
      const SECONDARY_CARIES = ConditionCode.SecondaryCaries; // 'secondary_caries';
      const SECONDARY_CARIES_PULP = ConditionCode.Child_DefectDepth_WithPulpExposure; // 'secondary_caries_depth/pulp';
      const SECONDARY_CARIES_ROOT = ConditionCode.Child_DefectDepth_Root; // 'secondary_caries_depth/root';
      const SECONDARY_CARIES_MAX_SURFACE_LIMIT = 3;
      const SECONDARY_CARIES_SURFACE_CODES = [
        ConditionCode.Child_DefectSurface_Mesial, // 'surface/mesial',
        ConditionCode.Child_DefectSurface_Distal, // 'surface/distal',
        ConditionCode.Child_DefectSurface_Occlusial, // 'surface/occlusial',
        ConditionCode.Child_DefectSurface_IncisalEdge, // 'surface/incisal',
        ConditionCode.Child_DefectSurface_Buccal, // 'surface/buccal',
        ConditionCode.Child_DefectSurface_Vestibular, // 'surface/vestibular',
        ConditionCode.Child_DefectSurface_Lingual, // 'surface/lingual',
        // 'secondary_caries_surface/distal',
        // 'secondary_caries_surface/occlusial',
        // 'secondary_caries_surface/incisal',
        // 'secondary_caries_surface/buccal',
        // 'secondary_caries_surface/vestibular',
        // 'secondary_caries_surface/lingual',
      ];

      if (conditionCode === SECONDARY_CARIES) {
        // transfer by secondary_caries_depth/pulp tag
        if (isConditionItemEnabledByCode(SECONDARY_CARIES_PULP)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            SECONDARY_CARIES,
          );
        }

        // transfer by secondary_caries_depth/root tag
        if (isConditionItemEnabledByCode(SECONDARY_CARIES_ROOT)) {
          return updateResultObject(
            CombinedConditionGroupCode.MISSING_NON_RESTORABLE,
            SECONDARY_CARIES,
          );
        }

        // transfer if more than 3 surfaces enabled
        if (
          getEnabledConditionsCount(SECONDARY_CARIES_SURFACE_CODES) >=
          SECONDARY_CARIES_MAX_SURFACE_LIMIT
        ) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            SECONDARY_CARIES,
          );
        }

        return updateResultObject(
          CombinedConditionGroupCode.CARIES_AND_CROWN,
          SECONDARY_CARIES,
        );
      }

      //
      //
      // ENDO TREATMENT RULES
      //
      const ENDO = ConditionCode.EndoTreated; // 'endo/treated';
      const ENDO_MAX_TAGS = 1;
      const ENDO_TAG_CODES = [
        ConditionCode.Child_EndoTreated_Obturation_ShortFilling, // 'endo/short_filling',
        ConditionCode.Child_EndoTreated_Obturation_MissedCanal, // 'endo/missed_canal',
        ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling, // 'endo/voids_present_in_the_root_filling',
      ];

      if (conditionCode === ENDO) {
        // transfer if any of tags enabled
        if (getEnabledConditionsCount(ENDO_TAG_CODES) >= ENDO_MAX_TAGS) {
          return updateResultObject(CombinedConditionGroupCode.ENDO, ENDO);
        }

        // transfer if artifical crown enabled
        if (isConditionItemEnabledByCode(ARTIFICAL_CROWN)) {
          return updateResultObject(
            CombinedConditionGroupCode.CROWN_INDICATION,
            ENDO,
          );
        }

        return updateResultObject(CombinedConditionGroupCode.TREATMENT, ENDO);
      }

      //
      //
      // SIMPLE CONDITIONS RULES
      //
      const combinedAttrsList =
        getCombinedConditionGroupCodeList(conditionCode);

      if (combinedAttrsList?.length) {
        const updatedResult = combinedAttrsList.reduce(
          (accum, combineAttrCode) => {
            if (result[combineAttrCode]) {
              return {
                ...accum,
                [combineAttrCode]: [...result[combineAttrCode], conditionCode],
              };
            }

            return accum;
          },
          {} as Record<CombinedConditionGroupCode, ConditionCode[]>,
        );

        return {
          ...result,
          ...updatedResult,
        };
      }

      return result;
    },
    INITIAL_COMBINED_CONDITION_CODES,
  );

  return combinedConditions;
};

import { useContext } from 'react';
import { ObjectFitContaintContext } from '../ObjectFitContain';

import { Annotation } from './Annotation';
import { cssToLogical } from './csstoLogical';

const angle = (x1: number, y1: number, x2: number, y2: number): number => {
  const A = y2 - y1;
  const C = length(x1, y1, x2, y2);

  if (!C) return 0;

  const sinAlpha = A / C;
  const alpha = Math.asin(sinAlpha) * 57.2958;

  return x2 > x1 ? alpha : 360 - alpha;
};

const length = (x1: number, y1: number, x2: number, y2: number): number =>
  Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

export const lengthInMm = (x1: number, y1: number, x2: number, y2: number, pixelSpacing: [number, number]): number => {
  return length(
    x1 * pixelSpacing[0],
    y1 * pixelSpacing[1],
    x2 * pixelSpacing[0],
    y2 * pixelSpacing[1],
  );
}

export type RulerProps = Omit<
  Extract<Annotation, { kind: 'ruler' }>,
  'kind'
> & {
  pixelSpacing: [number, number];
};

const strokeColor = 'lime';
// Number of digits after the decimal point
const fractionDigits = 2;

export const Ruler = ({ x1, y1, x2, y2, pixelSpacing }: RulerProps) => {

  const objectFit = useContext(ObjectFitContaintContext);

  /*
      Q: Why do we use cssToLogical here?
      A: Designs measurements are presented in css pixels (as you always expect them to be)
      But in case of svg we need to convert them to logical pixels (see cssToLogical documentation)
  */

  return (
    <>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={strokeColor}
        strokeWidth={cssToLogical(2, objectFit)}
        strokeOpacity="0.4"
      />

      <text
        transform={`
          translate(${(x2 + x1) / 2},${(y2 + y1) / 2} ) rotate(${angle(
          x1,
          y1,
          x2,
          y2,
        )})`}
        fill={strokeColor}
        textAnchor="middle"
        fontSize={cssToLogical(12, objectFit)}
        dy={cssToLogical(-5, objectFit)}
        style={{
          userSelect: 'none',
          MozUserSelect: 'none',
          WebkitUserSelect: 'none',
        }}
      >
        {lengthInMm(x1, y1, x2, y2, pixelSpacing).toFixed(fractionDigits)}
        mm
      </text>
    </>
  );
};

import { FC, ReactNode } from 'react';

import { Tooltip } from '@/shared/ui';

type PatientNameTooltipProps = {
  name: ReactNode;
  children: ReactNode;
  className?: string;
  testID?: string;
};

export const PatientNameTooltip: FC<PatientNameTooltipProps> = (props) => {
  const { name, children, className, testID } = props;

  return (
    <Tooltip.Third
      content={name}
      delayDuration={800}
      side="right"
      align="end"
      alignOffset={-12}
      className={className}
      testID={testID}
    >
      {children}
    </Tooltip.Third>
  );
};

import { Study, StudyType } from '@/shared/api/protocol_gen/model/dto_study';

export const getRelevantStudy = (
  studies: Study[] | undefined,
): Study | null => {
  if (!studies) {
    return null;
  }

  const cbctStudy = studies.find(
    (study) => study.Type === StudyType.StudyType_CBCT,
  );

  if (cbctStudy) {
    return cbctStudy;
  }

  const latestStudy = studies.reduce<Study | null>((latest, study) => {
    if (
      !latest ||
      (study.Created?.At &&
        new Date(study.Created.At) < new Date(latest?.Created?.At || 0))
    ) {
      return study;
    }
    return latest;
  }, null);

  return latestStudy || null;
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSectionOrientation,
  CBCTSliceOrientation,
  File,
  DerivedDataImage,
  cBCTSectionOrientationFromJSON,
  cBCTSectionOrientationToJSON,
  cBCTSliceOrientationFromJSON,
  cBCTSliceOrientationToJSON,
} from '../model/dto_asset_common';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentGeneratedCBCTMolarPanorama {
  Panorama: File | undefined;
  CanalPenetration: AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType;
  Derived: DerivedDataImage | undefined;
}

/** {"type": "File", "file": {"task_id": "23ddc55e-d818-f8bd-4cb6-b37576bf4f61", "key": "pano", "filename": "/tmp/tmpb08cng3z", "content_type": "application/dicom", "size": 256600, "meta": {"canal_penetration": 0, "image_height": 213, "image_width": 600}, "type": "File"}, "event": "execution-event"} */
export enum AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType {
  NotTouching = 0,
  Touching = 1,
  Penetration = 2,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedCBCTMolarPanorama_CanalPenetrationTypeFromJSON(
  object: any,
): AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType {
  switch (object) {
    case 0:
    case 'NotTouching':
      return AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.NotTouching;
    case 1:
    case 'Touching':
      return AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.Touching;
    case 2:
    case 'Penetration':
      return AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.Penetration;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.UNRECOGNIZED;
  }
}

export function assetContentGeneratedCBCTMolarPanorama_CanalPenetrationTypeToJSON(
  object: AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType,
): string {
  switch (object) {
    case AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.NotTouching:
      return 'NotTouching';
    case AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.Touching:
      return 'Touching';
    case AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.Penetration:
      return 'Penetration';
    case AssetContentGeneratedCBCTMolarPanorama_CanalPenetrationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface AssetContentGeneratedCBCTMolarGuide {
  /** {"type": "File", "file": {"task_id": "23ddc55e-d818-f8bd-4cb6-b37576bf4f61", "key": "vestibulooral-guide", "filename": "/tmp/tmp2wa3ivxs", "content_type": "application/dicom", "size": 44120, "meta": {"name": "vestibulooral-guide", "type": "guide", "section": "vestibulooral", "section_name": "Vestibulooral slices", "slice_coordinates": [{"x1": 10, "y1": 0, "x2": 10, "y2": 196}, {"x1": 16, "y1": 0, "x2": 16, "y2": 196}, {"x1": 22, "y1": 0, "x2": 22, "y2": 196}, {"x1": 28, "y1": 0, "x2": 28, "y2": 196}, {"x1": 34, "y1": 0, "x2": 34, "y2": 196}, {"x1": 40, "y1": 0, "x2": 40, "y2": 196}, {"x1": 46, "y1": 0, "x2": 46, "y2": 196}, {"x1": 52, "y1": 0, "x2": 52, "y2": 196}, {"x1": 58, "y1": 0, "x2": 58, "y2": 196}, {"x1": 64, "y1": 0, "x2": 64, "y2": 196}, {"x1": 70, "y1": 0, "x2": 70, "y2": 196}, {"x1": 76, "y1": 0, "x2": 76, "y2": 196}, {"x1": 82, "y1": 0, "x2": 82, "y2": 196}, {"x1": 88, "y1": 0, "x2": 88, "y2": 196}, {"x1": 94, "y1": 0, "x2": 94, "y2": 196}, {"x1": 100, "y1": 0, "x2": 100, "y2": 196}], "orientation_markers": null, "slice_step": 1.5, "slice_thickness": 0.25, "image_height": 196, "image_width": 110}, "type": "File"}, "event": "execution-event"} */
  Guide: File | undefined;
  Section: CBCTSectionOrientation;
  SliceStep: number;
  SliceThickness: number;
}

/**
 * name example: vestibulooral-000
 * name template: <secton>-<order>
 * {"type": "File", "file": {"task_id": "23ddc55e-d818-f8bd-4cb6-b37576bf4f61", "key": "vestibulooral-000", "filename": "/tmp/tmp2p3divx4", "content_type": "application/dicom", "size": 42944, "meta": {"name": "vestibulooral-000", "type": "cross-section", "order": 0, "section": "vestibulooral", "orientation_markers": {"left": "L", "right": "B"}, "image_height": 196, "image_width": 107}, "type": "File"}, "event": "execution-event"}
 */
export interface AssetContentGeneratedCBCTMolarCrossSection {
  CrossSection: File | undefined;
  Section: CBCTSectionOrientation;
  Order: number;
  /** maps to `"orientation_markers": {"left": "B", "right": "L"}` */
  RightSideOrient: CBCTSliceOrientation;
  /** maps to `"orientation_markers": {"left": "B", "right": "L"}` */
  LeftSideOrient: CBCTSliceOrientation;
}

function createBaseAssetContentGeneratedCBCTMolarPanorama(): AssetContentGeneratedCBCTMolarPanorama {
  return { Panorama: undefined, CanalPenetration: 0, Derived: undefined };
}

export const AssetContentGeneratedCBCTMolarPanorama = {
  encode(
    message: AssetContentGeneratedCBCTMolarPanorama,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Panorama !== undefined) {
      File.encode(message.Panorama, writer.uint32(10).fork()).ldelim();
    }
    if (message.CanalPenetration !== 0) {
      writer.uint32(16).int32(message.CanalPenetration);
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTMolarPanorama {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTMolarPanorama();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Panorama = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.CanalPenetration = reader.int32() as any;
          break;
        case 101:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTMolarPanorama {
    return {
      Panorama: isSet(object.Panorama)
        ? File.fromJSON(object.Panorama)
        : undefined,
      CanalPenetration: isSet(object.CanalPenetration)
        ? assetContentGeneratedCBCTMolarPanorama_CanalPenetrationTypeFromJSON(
            object.CanalPenetration,
          )
        : 0,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTMolarPanorama): unknown {
    const obj: any = {};
    message.Panorama !== undefined &&
      (obj.Panorama = message.Panorama
        ? File.toJSON(message.Panorama)
        : undefined);
    message.CanalPenetration !== undefined &&
      (obj.CanalPenetration =
        assetContentGeneratedCBCTMolarPanorama_CanalPenetrationTypeToJSON(
          message.CanalPenetration,
        ));
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTMolarPanorama>, I>,
  >(object: I): AssetContentGeneratedCBCTMolarPanorama {
    const message = createBaseAssetContentGeneratedCBCTMolarPanorama();
    message.Panorama =
      object.Panorama !== undefined && object.Panorama !== null
        ? File.fromPartial(object.Panorama)
        : undefined;
    message.CanalPenetration = object.CanalPenetration ?? 0;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTMolarGuide(): AssetContentGeneratedCBCTMolarGuide {
  return { Guide: undefined, Section: 0, SliceStep: 0, SliceThickness: 0 };
}

export const AssetContentGeneratedCBCTMolarGuide = {
  encode(
    message: AssetContentGeneratedCBCTMolarGuide,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Guide !== undefined) {
      File.encode(message.Guide, writer.uint32(10).fork()).ldelim();
    }
    if (message.Section !== 0) {
      writer.uint32(16).int32(message.Section);
    }
    if (message.SliceStep !== 0) {
      writer.uint32(93).float(message.SliceStep);
    }
    if (message.SliceThickness !== 0) {
      writer.uint32(101).float(message.SliceThickness);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTMolarGuide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTMolarGuide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Guide = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Section = reader.int32() as any;
          break;
        case 11:
          message.SliceStep = reader.float();
          break;
        case 12:
          message.SliceThickness = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTMolarGuide {
    return {
      Guide: isSet(object.Guide) ? File.fromJSON(object.Guide) : undefined,
      Section: isSet(object.Section)
        ? cBCTSectionOrientationFromJSON(object.Section)
        : 0,
      SliceStep: isSet(object.SliceStep) ? Number(object.SliceStep) : 0,
      SliceThickness: isSet(object.SliceThickness)
        ? Number(object.SliceThickness)
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTMolarGuide): unknown {
    const obj: any = {};
    message.Guide !== undefined &&
      (obj.Guide = message.Guide ? File.toJSON(message.Guide) : undefined);
    message.Section !== undefined &&
      (obj.Section = cBCTSectionOrientationToJSON(message.Section));
    message.SliceStep !== undefined && (obj.SliceStep = message.SliceStep);
    message.SliceThickness !== undefined &&
      (obj.SliceThickness = message.SliceThickness);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTMolarGuide>, I>,
  >(object: I): AssetContentGeneratedCBCTMolarGuide {
    const message = createBaseAssetContentGeneratedCBCTMolarGuide();
    message.Guide =
      object.Guide !== undefined && object.Guide !== null
        ? File.fromPartial(object.Guide)
        : undefined;
    message.Section = object.Section ?? 0;
    message.SliceStep = object.SliceStep ?? 0;
    message.SliceThickness = object.SliceThickness ?? 0;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTMolarCrossSection(): AssetContentGeneratedCBCTMolarCrossSection {
  return {
    CrossSection: undefined,
    Section: 0,
    Order: 0,
    RightSideOrient: 0,
    LeftSideOrient: 0,
  };
}

export const AssetContentGeneratedCBCTMolarCrossSection = {
  encode(
    message: AssetContentGeneratedCBCTMolarCrossSection,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CrossSection !== undefined) {
      File.encode(message.CrossSection, writer.uint32(10).fork()).ldelim();
    }
    if (message.Section !== 0) {
      writer.uint32(16).int32(message.Section);
    }
    if (message.Order !== 0) {
      writer.uint32(24).uint32(message.Order);
    }
    if (message.RightSideOrient !== 0) {
      writer.uint32(88).int32(message.RightSideOrient);
    }
    if (message.LeftSideOrient !== 0) {
      writer.uint32(96).int32(message.LeftSideOrient);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTMolarCrossSection {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTMolarCrossSection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CrossSection = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Section = reader.int32() as any;
          break;
        case 3:
          message.Order = reader.uint32();
          break;
        case 11:
          message.RightSideOrient = reader.int32() as any;
          break;
        case 12:
          message.LeftSideOrient = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTMolarCrossSection {
    return {
      CrossSection: isSet(object.CrossSection)
        ? File.fromJSON(object.CrossSection)
        : undefined,
      Section: isSet(object.Section)
        ? cBCTSectionOrientationFromJSON(object.Section)
        : 0,
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      RightSideOrient: isSet(object.RightSideOrient)
        ? cBCTSliceOrientationFromJSON(object.RightSideOrient)
        : 0,
      LeftSideOrient: isSet(object.LeftSideOrient)
        ? cBCTSliceOrientationFromJSON(object.LeftSideOrient)
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTMolarCrossSection): unknown {
    const obj: any = {};
    message.CrossSection !== undefined &&
      (obj.CrossSection = message.CrossSection
        ? File.toJSON(message.CrossSection)
        : undefined);
    message.Section !== undefined &&
      (obj.Section = cBCTSectionOrientationToJSON(message.Section));
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    message.RightSideOrient !== undefined &&
      (obj.RightSideOrient = cBCTSliceOrientationToJSON(
        message.RightSideOrient,
      ));
    message.LeftSideOrient !== undefined &&
      (obj.LeftSideOrient = cBCTSliceOrientationToJSON(message.LeftSideOrient));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTMolarCrossSection>, I>,
  >(object: I): AssetContentGeneratedCBCTMolarCrossSection {
    const message = createBaseAssetContentGeneratedCBCTMolarCrossSection();
    message.CrossSection =
      object.CrossSection !== undefined && object.CrossSection !== null
        ? File.fromPartial(object.CrossSection)
        : undefined;
    message.Section = object.Section ?? 0;
    message.Order = object.Order ?? 0;
    message.RightSideOrient = object.RightSideOrient ?? 0;
    message.LeftSideOrient = object.LeftSideOrient ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { browserHistory } from '@/shared/lib';

import { Icon } from '../Icon/Icon';

import styles from './Back.module.scss';

type BackProps = {
  className?: string;
  testID?: string;
};

export const Back: FC<BackProps> = (props) => {
  const { className, testID } = props;

  return (
    <nav className={cn(styles.container, className)} data-testid={testID}>
      <button
        type="button"
        onClick={() => browserHistory.back()}
        className={cn(styles.backButton, 'p1')}
      >
        <Icon className={styles.backButtonIcon} name="arrowDown" size={32} />

        <FormattedMessage id="navigation.back" defaultMessage="Back" />
      </button>
    </nav>
  );
};

import {
  IOXrayImageInterface,
  ImageSizeType,
} from '@/entities/IOXRayImagesMatrix';

export const getImageSizeDataByType = (
  imageItem: IOXrayImageInterface,
  imageSizeType: ImageSizeType,
): { width: number; height: number } => {
  switch (imageSizeType) {
    case 'original':
      return imageItem.originalSize || 1;

    case 'preview':
      return imageItem.previewSize || 1;

    case 'thumbnail':
      return imageItem.thumbnailSize || 1;

    default:
      return imageItem.originalSize || 1;
  }
};

import { useIntl } from 'react-intl';

import { softwareTypes } from '../config/i18n';

export const useGetSoftwareOptions = () => {
  const { formatMessage } = useIntl();

  const softwareOptions = [
    {
      label: formatMessage(softwareTypes.AISActeon),
      value: 'AIS (Acteon)',
    },
    {
      label: formatMessage(softwareTypes.carestream7),
      value: 'Carestream7',
    },
    {
      label: formatMessage(softwareTypes.carestream8),
      value: 'Carestream8',
    },
    {
      label: formatMessage(softwareTypes.DTXStudioEnvista),
      value: 'DTX Studio (Envista)',
    },
    {
      label: formatMessage(softwareTypes.EZ3DVaTech),
      value: 'EZ 3D (VaTech)',
    },
    {
      label: formatMessage(softwareTypes.iCATVision),
      value: 'i-CAT Vision',
    },
    {
      label: formatMessage(softwareTypes.iDixelMorita),
      value: 'i-Dixel (Morita)',
    },
    {
      label: formatMessage(softwareTypes.invivoDexis),
      value: 'Invivo (Dexis)',
    },
    {
      label: formatMessage(softwareTypes.prexion),
      value: 'Prexion',
    },
    {
      label: formatMessage(softwareTypes.romexisPlanmeca),
      value: 'Romexis (Planmeca)',
    },
    {
      label: formatMessage(softwareTypes.sidexisXGSirona),
      value: 'Sidexis XG (Sirona)',
    },
    {
      label: formatMessage(softwareTypes.sidexis4Sirona),
      value: 'Sidexis 4 (Sirona)',
    },
    {
      label: formatMessage(softwareTypes.TXStudioDexis),
      value: 'TX Studio (Dexis)',
    },
  ];

  softwareOptions.sort((optionA, optionB) =>
    optionA.label.localeCompare(optionB.label, undefined, { numeric: true }),
  );

  softwareOptions.push({
    label: formatMessage({ id: 'global.other', defaultMessage: 'Other' }),
    value: 'Other',
  });

  return softwareOptions;
};

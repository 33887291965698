/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface EmptyPayload {}

export interface ReportLegacyPayload {
  Key: string;
  /** legacy JSON encoded message for backward compatibility */
  JSON: Uint8Array;
}

function createBaseEmptyPayload(): EmptyPayload {
  return {};
}

export const EmptyPayload = {
  encode(
    _: EmptyPayload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmptyPayload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmptyPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): EmptyPayload {
    return {};
  },

  toJSON(_: EmptyPayload): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EmptyPayload>, I>>(
    _: I,
  ): EmptyPayload {
    const message = createBaseEmptyPayload();
    return message;
  },
};

function createBaseReportLegacyPayload(): ReportLegacyPayload {
  return { Key: '', JSON: new Uint8Array() };
}

export const ReportLegacyPayload = {
  encode(
    message: ReportLegacyPayload,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Key !== '') {
      writer.uint32(10).string(message.Key);
    }
    if (message.JSON.length !== 0) {
      writer.uint32(18).bytes(message.JSON);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportLegacyPayload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportLegacyPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Key = reader.string();
          break;
        case 2:
          message.JSON = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportLegacyPayload {
    return {
      Key: isSet(object.Key) ? String(object.Key) : '',
      JSON: isSet(object.JSON)
        ? bytesFromBase64(object.JSON)
        : new Uint8Array(),
    };
  },

  toJSON(message: ReportLegacyPayload): unknown {
    const obj: any = {};
    message.Key !== undefined && (obj.Key = message.Key);
    message.JSON !== undefined &&
      (obj.JSON = base64FromBytes(
        message.JSON !== undefined ? message.JSON : new Uint8Array(),
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportLegacyPayload>, I>>(
    object: I,
  ): ReportLegacyPayload {
    const message = createBaseReportLegacyPayload();
    message.Key = object.Key ?? '';
    message.JSON = object.JSON ?? new Uint8Array();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, 'base64').toString('binary'));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, 'binary').toString('base64'));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(''));
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

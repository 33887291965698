import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import studyPlaceholderImage from '@/shared/assets/images/cats/patientListStudyPlaceholder.png';

import styles from '../SharedPatientList/SharedPatientList.module.scss';
import { PatientNameTooltip } from '../PatientNameTooltip/PatientNameTooltip';

type PatientColumnHeaderProps = {
  displayAssetSrc: string;
  patientName: string;
  isSharedPatientAccepted?: boolean;
  className?: string;
  testID?: string;
};

export const PatientColumnHeader: FC<PatientColumnHeaderProps> = (props) => {
  const {
    displayAssetSrc,
    patientName,
    isSharedPatientAccepted = true,
    className,
    testID,
  } = props;

  const [isError, setIsError] = useState(false);

  return (
    <div className={cn(styles.infoInCell, className)} data-testid={testID}>
      {!isError ? (
        <img
          src={displayAssetSrc}
          alt={patientName}
          onError={() => setIsError(true)}
          className={cn(styles.patientStudyImage)}
        />
      ) : (
        <img
          src={studyPlaceholderImage}
          alt={patientName}
          className={styles.studyPlaceholderImage}
        />
      )}

      <div
        className={cn(styles.fullName, isError && styles.paddingForFullName)}
      >
        {isSharedPatientAccepted ? (
          <PatientNameTooltip name={patientName}>
            <span>{patientName}</span>
          </PatientNameTooltip>
        ) : (
          <FormattedMessage
            id="sharedPatient.nameHidden"
            defaultMessage="Name hidden"
          />
        )}
      </div>
    </div>
  );
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CephalometricLandmarkCBCTCode,
  ToothLandmarkCBCTCode,
  ToothLandmarkIOSCode,
  ToothLandmarkImageCode,
  toothLandmarkCBCTCodeFromJSON,
  toothLandmarkIOSCodeFromJSON,
  toothLandmarkImageCodeFromJSON,
  toothLandmarkCBCTCodeToJSON,
  toothLandmarkIOSCodeToJSON,
  toothLandmarkImageCodeToJSON,
  cephalometricLandmarkCBCTCodeFromJSON,
  cephalometricLandmarkCBCTCodeToJSON,
} from '../model/dto_report_landmark_codes';
import { ToothNumeration } from '../model/dto_report_common';
import { FloatPoint3D } from '../model/dto_common_geometry';
import { Created, Revision, Deleted } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

export interface ToothLandmark {
  ID: string;
  /**
   * `ToothNumeration`: [optional]. Tooth numeration, outputted from segmentron
   * reports. REQUIRED in segmentron reports.
   */
  Tooth: ToothNumeration | undefined;
  /**
   * `ToothLocalizationID`: [optional]. Link to tooth localization, outputted form
   * non segmentron reports. REQUIRED in nonsegmentron reports.
   */
  ToothLocalizationID: string;
  ToothID: string;
  ReportID: string;
  PatientID: string;
  OrganizationID: string;
  ModelPosition: FloatPoint3D | undefined;
  UserPosition: FloatPoint3D | undefined;
  CBCT: ToothLandmarkCBCTCode | undefined;
  IOS: ToothLandmarkIOSCode | undefined;
  Image: ToothLandmarkImageCode | undefined;
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

export interface CephalometricLandmark {
  ID: string;
  Code: CephalometricLandmarkCBCTCode;
  ModelPosition: FloatPoint3D | undefined;
  UserPosition: FloatPoint3D | undefined;
}

function createBaseToothLandmark(): ToothLandmark {
  return {
    ID: '',
    Tooth: undefined,
    ToothLocalizationID: '',
    ToothID: '',
    ReportID: '',
    PatientID: '',
    OrganizationID: '',
    ModelPosition: undefined,
    UserPosition: undefined,
    CBCT: undefined,
    IOS: undefined,
    Image: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const ToothLandmark = {
  encode(
    message: ToothLandmark,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(26).string(message.ID);
    }
    if (message.Tooth !== undefined) {
      ToothNumeration.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.ToothLocalizationID !== '') {
      writer.uint32(34).string(message.ToothLocalizationID);
    }
    if (message.ToothID !== '') {
      writer.uint32(170).string(message.ToothID);
    }
    if (message.ReportID !== '') {
      writer.uint32(178).string(message.ReportID);
    }
    if (message.PatientID !== '') {
      writer.uint32(186).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(194).string(message.OrganizationID);
    }
    if (message.ModelPosition !== undefined) {
      FloatPoint3D.encode(
        message.ModelPosition,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.UserPosition !== undefined) {
      FloatPoint3D.encode(
        message.UserPosition,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.CBCT !== undefined) {
      writer.uint32(88).int32(message.CBCT);
    }
    if (message.IOS !== undefined) {
      writer.uint32(96).int32(message.IOS);
    }
    if (message.Image !== undefined) {
      writer.uint32(104).int32(message.Image);
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ToothLandmark {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToothLandmark();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.ID = reader.string();
          break;
        case 1:
          message.Tooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 4:
          message.ToothLocalizationID = reader.string();
          break;
        case 21:
          message.ToothID = reader.string();
          break;
        case 22:
          message.ReportID = reader.string();
          break;
        case 23:
          message.PatientID = reader.string();
          break;
        case 24:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.ModelPosition = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 5:
          message.UserPosition = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 11:
          message.CBCT = reader.int32() as any;
          break;
        case 12:
          message.IOS = reader.int32() as any;
          break;
        case 13:
          message.Image = reader.int32() as any;
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ToothLandmark {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Tooth: isSet(object.Tooth)
        ? ToothNumeration.fromJSON(object.Tooth)
        : undefined,
      ToothLocalizationID: isSet(object.ToothLocalizationID)
        ? String(object.ToothLocalizationID)
        : '',
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      ModelPosition: isSet(object.ModelPosition)
        ? FloatPoint3D.fromJSON(object.ModelPosition)
        : undefined,
      UserPosition: isSet(object.UserPosition)
        ? FloatPoint3D.fromJSON(object.UserPosition)
        : undefined,
      CBCT: isSet(object.CBCT)
        ? toothLandmarkCBCTCodeFromJSON(object.CBCT)
        : undefined,
      IOS: isSet(object.IOS)
        ? toothLandmarkIOSCodeFromJSON(object.IOS)
        : undefined,
      Image: isSet(object.Image)
        ? toothLandmarkImageCodeFromJSON(object.Image)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: ToothLandmark): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth
        ? ToothNumeration.toJSON(message.Tooth)
        : undefined);
    message.ToothLocalizationID !== undefined &&
      (obj.ToothLocalizationID = message.ToothLocalizationID);
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.ModelPosition !== undefined &&
      (obj.ModelPosition = message.ModelPosition
        ? FloatPoint3D.toJSON(message.ModelPosition)
        : undefined);
    message.UserPosition !== undefined &&
      (obj.UserPosition = message.UserPosition
        ? FloatPoint3D.toJSON(message.UserPosition)
        : undefined);
    message.CBCT !== undefined &&
      (obj.CBCT =
        message.CBCT !== undefined
          ? toothLandmarkCBCTCodeToJSON(message.CBCT)
          : undefined);
    message.IOS !== undefined &&
      (obj.IOS =
        message.IOS !== undefined
          ? toothLandmarkIOSCodeToJSON(message.IOS)
          : undefined);
    message.Image !== undefined &&
      (obj.Image =
        message.Image !== undefined
          ? toothLandmarkImageCodeToJSON(message.Image)
          : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ToothLandmark>, I>>(
    object: I,
  ): ToothLandmark {
    const message = createBaseToothLandmark();
    message.ID = object.ID ?? '';
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? ToothNumeration.fromPartial(object.Tooth)
        : undefined;
    message.ToothLocalizationID = object.ToothLocalizationID ?? '';
    message.ToothID = object.ToothID ?? '';
    message.ReportID = object.ReportID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.ModelPosition =
      object.ModelPosition !== undefined && object.ModelPosition !== null
        ? FloatPoint3D.fromPartial(object.ModelPosition)
        : undefined;
    message.UserPosition =
      object.UserPosition !== undefined && object.UserPosition !== null
        ? FloatPoint3D.fromPartial(object.UserPosition)
        : undefined;
    message.CBCT = object.CBCT ?? undefined;
    message.IOS = object.IOS ?? undefined;
    message.Image = object.Image ?? undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseCephalometricLandmark(): CephalometricLandmark {
  return { ID: '', Code: 0, ModelPosition: undefined, UserPosition: undefined };
}

export const CephalometricLandmark = {
  encode(
    message: CephalometricLandmark,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(26).string(message.ID);
    }
    if (message.Code !== 0) {
      writer.uint32(8).int32(message.Code);
    }
    if (message.ModelPosition !== undefined) {
      FloatPoint3D.encode(
        message.ModelPosition,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.UserPosition !== undefined) {
      FloatPoint3D.encode(
        message.UserPosition,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CephalometricLandmark {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCephalometricLandmark();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.ID = reader.string();
          break;
        case 1:
          message.Code = reader.int32() as any;
          break;
        case 2:
          message.ModelPosition = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 4:
          message.UserPosition = FloatPoint3D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CephalometricLandmark {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Code: isSet(object.Code)
        ? cephalometricLandmarkCBCTCodeFromJSON(object.Code)
        : 0,
      ModelPosition: isSet(object.ModelPosition)
        ? FloatPoint3D.fromJSON(object.ModelPosition)
        : undefined,
      UserPosition: isSet(object.UserPosition)
        ? FloatPoint3D.fromJSON(object.UserPosition)
        : undefined,
    };
  },

  toJSON(message: CephalometricLandmark): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Code !== undefined &&
      (obj.Code = cephalometricLandmarkCBCTCodeToJSON(message.Code));
    message.ModelPosition !== undefined &&
      (obj.ModelPosition = message.ModelPosition
        ? FloatPoint3D.toJSON(message.ModelPosition)
        : undefined);
    message.UserPosition !== undefined &&
      (obj.UserPosition = message.UserPosition
        ? FloatPoint3D.toJSON(message.UserPosition)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CephalometricLandmark>, I>>(
    object: I,
  ): CephalometricLandmark {
    const message = createBaseCephalometricLandmark();
    message.ID = object.ID ?? '';
    message.Code = object.Code ?? 0;
    message.ModelPosition =
      object.ModelPosition !== undefined && object.ModelPosition !== null
        ? FloatPoint3D.fromPartial(object.ModelPosition)
        : undefined;
    message.UserPosition =
      object.UserPosition !== undefined && object.UserPosition !== null
        ? FloatPoint3D.fromPartial(object.UserPosition)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

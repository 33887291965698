import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './ConditionToggleContainer.module.scss';

type ConditionToggleContainerProps = {
  children: ReactNode;
  className?: string;
};

export const ConditionToggleContainer: FC<ConditionToggleContainerProps> = (
  props,
) => {
  const { children, className } = props;

  return <div className={cn(styles.container, className)}>{children}</div>;
};

import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';

import { logicalConditionModel } from '@/entities/logicalCondition';

import { makePathologyGroup } from '../lib/makePathologyGroup';
import {
  TOOTH_PAGE_GROUPS,
  TOOTH_PAGE_PATHOLOGY_GROUPS,
} from '../config/constants';
import { Group, PathologyGroup } from '../config/medicalImages.types';
import { makeGroupByOrientation } from '../lib/makeGroupByOrientation';
import * as assetsModel from '../model';

export const useGetMedicalImageGroups = (
  toothID: string,
): Array<Group | PathologyGroup> => {
  const toothAssets = useAppSelector(
    assetsModel.selectors.selectByToothID(toothID),
  );

  const allSlicesMasks = useAppSelector(
    logicalConditionModel.selectors.selectAllSlicesMasks(toothID),
  );

  const pathologyGroups = useMemo(
    () =>
      TOOTH_PAGE_PATHOLOGY_GROUPS.map((group) =>
        makePathologyGroup(group, toothAssets, allSlicesMasks),
      ),
    [toothAssets],
  );

  const groups = useMemo(
    () =>
      TOOTH_PAGE_GROUPS.map((group) =>
        makeGroupByOrientation(group, toothAssets),
      ),
    [toothAssets],
  );

  return [...groups, ...pathologyGroups];
};

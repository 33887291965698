import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Control, Controller } from 'react-hook-form';

import { Select } from '@/shared/ui';

import { additionalInfoSelectStyles } from '../AdditionalInfoSelect/AdditionalInfoSelect.styles';
import {
  AdditionalInfoMultiValueLabel,
  AdditionalInfoMultiValueRemove,
} from '../AdditionalInfoSelect/AdditionalInfoSelect.components';
import { useGetSoftwareOptions } from '../../hooks/useGetSoftwareOptions';
import styles from '../../Steps.module.scss';
import { AdditionalInfoFormPayload } from '../../config/types';

type CBCTImagingSoftwareProps = {
  control: Control<AdditionalInfoFormPayload>;
  className?: string;
  testID?: string;
};

export const CBCTImagingSoftwareStep: FC<CBCTImagingSoftwareProps> = (
  props,
) => {
  const { control, className, testID } = props;

  const softwareOptions = useGetSoftwareOptions();

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.headerOfOptionalSelect}>
        <h3 className={cn(styles.title, 'h3')}>
          <FormattedMessage
            id="additionalInfo.whatSoftware"
            defaultMessage="What CBCT imaging software do you use?"
          />
        </h3>

        <p className="p2">
          <FormattedMessage
            id="additionalInfo.dontKnowTheAnswer"
            defaultMessage="If you don't know the answer, click Next."
          />
        </p>
      </div>

      <Controller
        control={control}
        name="CBCTImagingSoftware"
        render={({ field: { ref, value, name, onBlur, onChange } }) => (
          <Select
            options={softwareOptions}
            isMulti
            ref={ref}
            value={value}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            styles={additionalInfoSelectStyles}
            components={{
              MultiValueLabel: AdditionalInfoMultiValueLabel,
              MultiValueRemove: AdditionalInfoMultiValueRemove,
            }}
          />
        )}
      />
    </div>
  );
};

import { useAppDispatch } from '@/shared/hooks';

import { accessModel } from '@/entities/access';
import { patientModel } from '@/entities/patient';

export const useAcceptSharingPatientInvite = () => {
  const dispatch = useAppDispatch();

  return async ({
    invitationID,
    accessCode,
  }: {
    invitationID: string;
    accessCode: string;
  }) => {
    // Two arrays are returned, one with a single patient and one with a single invitation
    const { Invitations, Patients } = await dispatch(
      accessModel.thunks.acceptInvite({
        InvitationID: invitationID,
        Secret: accessCode,
      }),
    ).unwrap();

    const sharedPatient = Patients[0];

    dispatch(patientModel.actions.setNewestOne(sharedPatient));

    const acceptedInvitation = Invitations[0];

    dispatch(accessModel.actions.setNewestOne(acceptedInvitation));

    return {
      acceptedInvitationStatus: acceptedInvitation?.Status,
      sharedPatientID: sharedPatient?.ID,
    };
  };
};

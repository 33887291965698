import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import sadCatImage from '@/shared/assets/images/cats/sad.png';

import { modalModel } from '@/entities/modal';

import {
  resetPasswordErrorModalMessages,
  resetPasswordErrorModalTittleMessages,
} from '../config/i18n';

import styles from './ResetPasswordErrorModal.module.scss';

export const ResetPasswordErrorModal: FC = () => {
  const {
    visible,
    data: { errorType },
  } = useAppSelector(modalModel.selectors.selectResetPasswordErrorModal);

  const { formatMessage } = useIntl();

  return (
    <Modal hideFooter shouldRenderCloseIconButton={false} isOpen={visible}>
      <div className={styles.container}>
        <img
          src={sadCatImage}
          alt={formatMessage({
            id: 'imgAltText.sadCat',
            defaultMessage: 'Sad cat',
          })}
          className={styles.sadCatImage}
        />
        <div className={styles.errorMessages}>
          <h5 className="h5">
            {formatMessage(resetPasswordErrorModalTittleMessages[errorType])}
          </h5>
          <p className="p1">
            {formatMessage(resetPasswordErrorModalMessages[errorType])}
          </p>
        </div>
      </div>
    </Modal>
  );
};

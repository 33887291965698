/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Gender, genderFromJSON, genderToJSON } from '../../model/dto_base';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { User } from '../../model/dto_user';
import { Organization } from '../../model/dto_organization';
import { Empty } from '../../google/protobuf/empty';

export const protobufPackage = 'com.diagnocat.core';

export interface MyDataStreamReq {}

export interface MyDataStreamResp {
  /** Current user (the user who's making the request) */
  HistoricalMe: User | undefined;
  /** Organizations where `Me` has at least one role */
  HistoricalOrganizations: MyDataStreamResp_Organizations | undefined;
  UpdatedMe: User | undefined;
  UpdatedOrganization: Organization | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface MyDataStreamResp_Organizations {
  Organizations: Organization[];
}

export interface SetUserNameReq {
  /** `UserID`: [optional]: if empty -> get from context */
  UserID: string;
  /** `FirstName`: [required] */
  FirstName: string;
  /** `LastName`: [required] */
  LastName: string;
}

export interface SetUserNameResp {
  /** user model */
  User: User | undefined;
}

export interface SetUserDateOfBirthReq {
  /** `UserID`: [optional]: if empty -> get from context */
  UserID: string;
  /** `DateOfBirth`: [required]: format="2022-01-14" */
  DateOfBirth: string;
}

export interface SetUserDateOfBirthResp {
  /** user model */
  User: User | undefined;
}

export interface SetUserGenderReq {
  /** `UserID`: [optional]: if empty -> get from context */
  UserID: string;
  /** `Gender`: [required] */
  Gender: Gender;
}

export interface SetUserGenderResp {
  /** user model */
  User: User | undefined;
}

export interface SetUserLanguageReq {
  /** `UserID`: [optional]: if empty -> get from context */
  UserID: string;
  /** `Language`: [required] */
  Language: string;
}

export interface SetUserLanguageResp {
  /** user model */
  User: User | undefined;
}

function createBaseMyDataStreamReq(): MyDataStreamReq {
  return {};
}

export const MyDataStreamReq = {
  encode(
    _: MyDataStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MyDataStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMyDataStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MyDataStreamReq {
    return {};
  },

  toJSON(_: MyDataStreamReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MyDataStreamReq>, I>>(
    _: I,
  ): MyDataStreamReq {
    const message = createBaseMyDataStreamReq();
    return message;
  },
};

function createBaseMyDataStreamResp(): MyDataStreamResp {
  return {
    HistoricalMe: undefined,
    HistoricalOrganizations: undefined,
    UpdatedMe: undefined,
    UpdatedOrganization: undefined,
    Ping: undefined,
  };
}

export const MyDataStreamResp = {
  encode(
    message: MyDataStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HistoricalMe !== undefined) {
      User.encode(message.HistoricalMe, writer.uint32(10).fork()).ldelim();
    }
    if (message.HistoricalOrganizations !== undefined) {
      MyDataStreamResp_Organizations.encode(
        message.HistoricalOrganizations,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.UpdatedMe !== undefined) {
      User.encode(message.UpdatedMe, writer.uint32(90).fork()).ldelim();
    }
    if (message.UpdatedOrganization !== undefined) {
      Organization.encode(
        message.UpdatedOrganization,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MyDataStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMyDataStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HistoricalMe = User.decode(reader, reader.uint32());
          break;
        case 2:
          message.HistoricalOrganizations =
            MyDataStreamResp_Organizations.decode(reader, reader.uint32());
          break;
        case 11:
          message.UpdatedMe = User.decode(reader, reader.uint32());
          break;
        case 12:
          message.UpdatedOrganization = Organization.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MyDataStreamResp {
    return {
      HistoricalMe: isSet(object.HistoricalMe)
        ? User.fromJSON(object.HistoricalMe)
        : undefined,
      HistoricalOrganizations: isSet(object.HistoricalOrganizations)
        ? MyDataStreamResp_Organizations.fromJSON(
            object.HistoricalOrganizations,
          )
        : undefined,
      UpdatedMe: isSet(object.UpdatedMe)
        ? User.fromJSON(object.UpdatedMe)
        : undefined,
      UpdatedOrganization: isSet(object.UpdatedOrganization)
        ? Organization.fromJSON(object.UpdatedOrganization)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: MyDataStreamResp): unknown {
    const obj: any = {};
    message.HistoricalMe !== undefined &&
      (obj.HistoricalMe = message.HistoricalMe
        ? User.toJSON(message.HistoricalMe)
        : undefined);
    message.HistoricalOrganizations !== undefined &&
      (obj.HistoricalOrganizations = message.HistoricalOrganizations
        ? MyDataStreamResp_Organizations.toJSON(message.HistoricalOrganizations)
        : undefined);
    message.UpdatedMe !== undefined &&
      (obj.UpdatedMe = message.UpdatedMe
        ? User.toJSON(message.UpdatedMe)
        : undefined);
    message.UpdatedOrganization !== undefined &&
      (obj.UpdatedOrganization = message.UpdatedOrganization
        ? Organization.toJSON(message.UpdatedOrganization)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MyDataStreamResp>, I>>(
    object: I,
  ): MyDataStreamResp {
    const message = createBaseMyDataStreamResp();
    message.HistoricalMe =
      object.HistoricalMe !== undefined && object.HistoricalMe !== null
        ? User.fromPartial(object.HistoricalMe)
        : undefined;
    message.HistoricalOrganizations =
      object.HistoricalOrganizations !== undefined &&
      object.HistoricalOrganizations !== null
        ? MyDataStreamResp_Organizations.fromPartial(
            object.HistoricalOrganizations,
          )
        : undefined;
    message.UpdatedMe =
      object.UpdatedMe !== undefined && object.UpdatedMe !== null
        ? User.fromPartial(object.UpdatedMe)
        : undefined;
    message.UpdatedOrganization =
      object.UpdatedOrganization !== undefined &&
      object.UpdatedOrganization !== null
        ? Organization.fromPartial(object.UpdatedOrganization)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseMyDataStreamResp_Organizations(): MyDataStreamResp_Organizations {
  return { Organizations: [] };
}

export const MyDataStreamResp_Organizations = {
  encode(
    message: MyDataStreamResp_Organizations,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Organizations) {
      Organization.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MyDataStreamResp_Organizations {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMyDataStreamResp_Organizations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organizations.push(
            Organization.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MyDataStreamResp_Organizations {
    return {
      Organizations: Array.isArray(object?.Organizations)
        ? object.Organizations.map((e: any) => Organization.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MyDataStreamResp_Organizations): unknown {
    const obj: any = {};
    if (message.Organizations) {
      obj.Organizations = message.Organizations.map((e) =>
        e ? Organization.toJSON(e) : undefined,
      );
    } else {
      obj.Organizations = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MyDataStreamResp_Organizations>, I>>(
    object: I,
  ): MyDataStreamResp_Organizations {
    const message = createBaseMyDataStreamResp_Organizations();
    message.Organizations =
      object.Organizations?.map((e) => Organization.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetUserNameReq(): SetUserNameReq {
  return { UserID: '', FirstName: '', LastName: '' };
}

export const SetUserNameReq = {
  encode(
    message: SetUserNameReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.FirstName !== '') {
      writer.uint32(18).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(26).string(message.LastName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserNameReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.FirstName = reader.string();
          break;
        case 3:
          message.LastName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserNameReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
    };
  },

  toJSON(message: SetUserNameReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserNameReq>, I>>(
    object: I,
  ): SetUserNameReq {
    const message = createBaseSetUserNameReq();
    message.UserID = object.UserID ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    return message;
  },
};

function createBaseSetUserNameResp(): SetUserNameResp {
  return { User: undefined };
}

export const SetUserNameResp = {
  encode(
    message: SetUserNameResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserNameResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserNameResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserNameResp {
    return {
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
    };
  },

  toJSON(message: SetUserNameResp): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserNameResp>, I>>(
    object: I,
  ): SetUserNameResp {
    const message = createBaseSetUserNameResp();
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    return message;
  },
};

function createBaseSetUserDateOfBirthReq(): SetUserDateOfBirthReq {
  return { UserID: '', DateOfBirth: '' };
}

export const SetUserDateOfBirthReq = {
  encode(
    message: SetUserDateOfBirthReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.DateOfBirth !== '') {
      writer.uint32(18).string(message.DateOfBirth);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetUserDateOfBirthReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserDateOfBirthReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.DateOfBirth = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserDateOfBirthReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      DateOfBirth: isSet(object.DateOfBirth) ? String(object.DateOfBirth) : '',
    };
  },

  toJSON(message: SetUserDateOfBirthReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.DateOfBirth !== undefined &&
      (obj.DateOfBirth = message.DateOfBirth);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserDateOfBirthReq>, I>>(
    object: I,
  ): SetUserDateOfBirthReq {
    const message = createBaseSetUserDateOfBirthReq();
    message.UserID = object.UserID ?? '';
    message.DateOfBirth = object.DateOfBirth ?? '';
    return message;
  },
};

function createBaseSetUserDateOfBirthResp(): SetUserDateOfBirthResp {
  return { User: undefined };
}

export const SetUserDateOfBirthResp = {
  encode(
    message: SetUserDateOfBirthResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetUserDateOfBirthResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserDateOfBirthResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserDateOfBirthResp {
    return {
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
    };
  },

  toJSON(message: SetUserDateOfBirthResp): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserDateOfBirthResp>, I>>(
    object: I,
  ): SetUserDateOfBirthResp {
    const message = createBaseSetUserDateOfBirthResp();
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    return message;
  },
};

function createBaseSetUserGenderReq(): SetUserGenderReq {
  return { UserID: '', Gender: 0 };
}

export const SetUserGenderReq = {
  encode(
    message: SetUserGenderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.Gender !== 0) {
      writer.uint32(16).int32(message.Gender);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserGenderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserGenderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.Gender = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserGenderReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Gender: isSet(object.Gender) ? genderFromJSON(object.Gender) : 0,
    };
  },

  toJSON(message: SetUserGenderReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Gender !== undefined && (obj.Gender = genderToJSON(message.Gender));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserGenderReq>, I>>(
    object: I,
  ): SetUserGenderReq {
    const message = createBaseSetUserGenderReq();
    message.UserID = object.UserID ?? '';
    message.Gender = object.Gender ?? 0;
    return message;
  },
};

function createBaseSetUserGenderResp(): SetUserGenderResp {
  return { User: undefined };
}

export const SetUserGenderResp = {
  encode(
    message: SetUserGenderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserGenderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserGenderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserGenderResp {
    return {
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
    };
  },

  toJSON(message: SetUserGenderResp): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserGenderResp>, I>>(
    object: I,
  ): SetUserGenderResp {
    const message = createBaseSetUserGenderResp();
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    return message;
  },
};

function createBaseSetUserLanguageReq(): SetUserLanguageReq {
  return { UserID: '', Language: '' };
}

export const SetUserLanguageReq = {
  encode(
    message: SetUserLanguageReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.Language !== '') {
      writer.uint32(18).string(message.Language);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserLanguageReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserLanguageReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.Language = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserLanguageReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
    };
  },

  toJSON(message: SetUserLanguageReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Language !== undefined && (obj.Language = message.Language);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserLanguageReq>, I>>(
    object: I,
  ): SetUserLanguageReq {
    const message = createBaseSetUserLanguageReq();
    message.UserID = object.UserID ?? '';
    message.Language = object.Language ?? '';
    return message;
  },
};

function createBaseSetUserLanguageResp(): SetUserLanguageResp {
  return { User: undefined };
}

export const SetUserLanguageResp = {
  encode(
    message: SetUserLanguageResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserLanguageResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserLanguageResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserLanguageResp {
    return {
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
    };
  },

  toJSON(message: SetUserLanguageResp): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserLanguageResp>, I>>(
    object: I,
  ): SetUserLanguageResp {
    const message = createBaseSetUserLanguageResp();
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: UserID
 * - type: NotFoundError, Entity: user (if user not found by UserID)
 */
export interface UserService {
  /** Get stream with information about available organizations */
  MyDataStream(
    request: DeepPartial<MyDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<MyDataStreamResp>;
  /**
   * Set user name
   *
   * Permission: `UserPermissions.CanEditPersonalData`
   */
  SetUserName(
    request: DeepPartial<SetUserNameReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserNameResp>;
  /**
   * Set user date of birth
   *
   * Permission: `UserPermissions.CanEditPersonalData`
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: DateOfBirth - (if `SetUserDateOfBirthReq.DateOfBirth` is not in the correct format ("2006-01-25"))
   */
  SetUserDateOfBirth(
    request: DeepPartial<SetUserDateOfBirthReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserDateOfBirthResp>;
  /**
   * Set user gender
   *
   * Permission: `UserPermissions.CanEditInfo`
   */
  SetUserGender(
    request: DeepPartial<SetUserGenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserGenderResp>;
  /**
   * Set user Language
   *
   * Permission: `UserPermissions.CanEditInfo`
   */
  SetUserLanguage(
    request: DeepPartial<SetUserLanguageReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserLanguageResp>;
}

export class UserServiceClientImpl implements UserService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.MyDataStream = this.MyDataStream.bind(this);
    this.SetUserName = this.SetUserName.bind(this);
    this.SetUserDateOfBirth = this.SetUserDateOfBirth.bind(this);
    this.SetUserGender = this.SetUserGender.bind(this);
    this.SetUserLanguage = this.SetUserLanguage.bind(this);
  }

  MyDataStream(
    request: DeepPartial<MyDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<MyDataStreamResp> {
    return this.rpc.invoke(
      UserServiceMyDataStreamDesc,
      MyDataStreamReq.fromPartial(request),
      metadata,
    );
  }

  SetUserName(
    request: DeepPartial<SetUserNameReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserNameResp> {
    return this.rpc.unary(
      UserServiceSetUserNameDesc,
      SetUserNameReq.fromPartial(request),
      metadata,
    );
  }

  SetUserDateOfBirth(
    request: DeepPartial<SetUserDateOfBirthReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserDateOfBirthResp> {
    return this.rpc.unary(
      UserServiceSetUserDateOfBirthDesc,
      SetUserDateOfBirthReq.fromPartial(request),
      metadata,
    );
  }

  SetUserGender(
    request: DeepPartial<SetUserGenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserGenderResp> {
    return this.rpc.unary(
      UserServiceSetUserGenderDesc,
      SetUserGenderReq.fromPartial(request),
      metadata,
    );
  }

  SetUserLanguage(
    request: DeepPartial<SetUserLanguageReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserLanguageResp> {
    return this.rpc.unary(
      UserServiceSetUserLanguageDesc,
      SetUserLanguageReq.fromPartial(request),
      metadata,
    );
  }
}

export const UserServiceDesc = {
  serviceName: 'com.diagnocat.core.UserService',
};

export const UserServiceMyDataStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'MyDataStream',
  service: UserServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return MyDataStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...MyDataStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserServiceSetUserNameDesc: UnaryMethodDefinitionish = {
  methodName: 'SetUserName',
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUserNameReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetUserNameResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserServiceSetUserDateOfBirthDesc: UnaryMethodDefinitionish = {
  methodName: 'SetUserDateOfBirth',
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUserDateOfBirthReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetUserDateOfBirthResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserServiceSetUserGenderDesc: UnaryMethodDefinitionish = {
  methodName: 'SetUserGender',
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUserGenderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetUserGenderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserServiceSetUserLanguageDesc: UnaryMethodDefinitionish = {
  methodName: 'SetUserLanguage',
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUserLanguageReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetUserLanguageResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

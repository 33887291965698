import { FC, useEffect } from 'react';
import cn from 'classnames';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Button, InlineFormError, Input, InputPassword } from '@/shared/ui';
import { useAppDispatch, useMedia } from '@/shared/hooks';
import { PATHS, UserBadRequestError } from '@/shared/config';
import { AuthState_AuthStatus } from '@/shared/api/protocol_gen/api/auth/dto_auth';
import { ApiError } from '@/shared/api/api';
import { trackEvent, trackPageView } from '@/shared/lib';

import { authModel } from '@/entities/auth';

import styles from './SignIn.module.scss';
import { SignInFormPayload, signInSchema } from './config/formConfig';

export const SignIn: FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  const { formatMessage } = useIntl();

  const from = (location?.state as { from: Location })?.from ?? redirectTo;

  const fromSegmentron = new URLSearchParams(location.search).get(
    'redirectFromSegmentron',
  );

  const { isMobile } = useMedia();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, errors },
    setError,
    clearErrors,
  } = useForm<SignInFormPayload>({
    resolver: yupResolver(signInSchema),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<SignInFormPayload> = async (data) => {
    const { email, password } = data;

    try {
      const startLogInResponse = await dispatch(
        authModel.thunks.startSignIn({ Email: email, Password: password }),
      ).unwrap();

      if (startLogInResponse.Status === AuthState_AuthStatus.SUCCESSFUL) {
        trackEvent('Login Success', { cluster: location.pathname });

        if (from) {
          navigate(from);
        } else if (fromSegmentron) {
          window.location.href = fromSegmentron;
        } else {
          navigate(PATHS.patients);
        }
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);

      const isInvalidCreds =
        // TODO: [h/4] sync types with backend
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (error as ApiError).type === 'UserBadRequestError' &&
        parsedMessage.code === UserBadRequestError.LOGIN_INVALID_CREDS;

      const incorrectPasswordOrEmailMessage = formatMessage({
        id: 'signIn.incorrectPasswordOrEmail',
        defaultMessage:
          'Your password is incorrect or this account does not exist.',
      });

      if (isInvalidCreds) {
        setError('root', { message: incorrectPasswordOrEmailMessage });
      }
    }
  };

  const onClearErrors = () => {
    clearErrors();
  };

  useEffect(() => {
    trackPageView('SignIn Page Viewed');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h3 className={cn(styles.header, 'h3')}>
          <FormattedMessage id="signIn.header" defaultMessage="Sign in" />
        </h3>
        <form
          id="signinform"
          onSubmit={handleSubmit(onSubmit)}
          onChange={onClearErrors}
        >
          <Controller
            control={control}
            name="email"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error, isTouched },
            }) => (
              <Input
                className={styles.email}
                required
                ref={ref}
                value={value}
                name={name}
                inputMode="email"
                type="email"
                onBlur={onBlur}
                onChange={(event) => {
                  if (!isTouched) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                label={formatMessage({
                  id: 'global.email',
                  defaultMessage: 'Email',
                })}
                error={error?.message}
                autoComplete="email"
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <InputPassword
                className={styles.password}
                required
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                label={formatMessage({
                  id: 'signIn.password',
                  defaultMessage: 'Password',
                })}
                error={error?.message}
                autoComplete="current-password"
              />
            )}
          />

          {errors.root && (
            <InlineFormError errorMessage={errors.root.message} />
          )}

          <div className={styles.formFooter}>
            <Button
              variant="tertiary"
              disabled={isSubmitting}
              onClick={() => navigate(PATHS.resetPassword)}
              size={isMobile ? 'medium' : 'large'}
            >
              <FormattedMessage
                id="signIn.forgotPassword"
                defaultMessage="Forgot password"
              />
            </Button>
            <Button
              type="submit"
              form="signinform"
              loading={isSubmitting}
              size={isMobile ? 'medium' : 'large'}
            >
              <FormattedMessage id="signIn.submit" defaultMessage="Sign in" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

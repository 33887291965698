import { FC, useEffect } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '@/shared/hooks';
import { InputPassword, Layout, Modal } from '@/shared/ui/';
import { PATHS } from '@/shared/config';
import { AuthState_AuthStatus } from '@/shared/api/protocol_gen/api/auth/dto_auth';
import { trackPageView } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { authModel } from '@/entities/auth';

import { CongratsModal } from '@/widgets/CongratsModal';
import { CustomPasswordError } from '@/widgets/CustomPasswordError';

import styles from './NewPassword.module.scss';
import {
  NewPasswordFormPayload,
  newPasswordFormSchema,
} from './config/formConfig';

type NewPasswordProps = {
  className?: string;
  testID?: string;
};

export const NewPassword: FC<NewPasswordProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const confirmationToken = searchParams.get('token');

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitted },
    setValue,
  } = useForm<NewPasswordFormPayload>({
    resolver: yupResolver(newPasswordFormSchema),
    mode: 'onChange',
  });

  const openNewPasswordCongratsModalHandle = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.CongratsModal,
        data: {
          title: formatMessage({
            id: 'newPassword.congrats.title',
            defaultMessage:
              'Congrats! Your password has been successfully reset.',
          }),

          redirectTo: PATHS.signIn,
        },
      }),
    );
  };

  const onSubmit: SubmitHandler<NewPasswordFormPayload> = async (data) => {
    const { password } = data;

    try {
      if (confirmationToken) {
        const setPasswordResponse = await dispatch(
          authModel.thunks.setPassword({
            Token: confirmationToken,
            Password: password,
          }),
        ).unwrap();

        if (setPasswordResponse.Status === AuthState_AuthStatus.SUCCESSFUL) {
          openNewPasswordCongratsModalHandle();
        }
      }
    } catch (error) {
      // TODO: [1/l]  Investigate reason of this behaviour
      // We need a catch for useForm's isSubmitting to work correctly
    }
  };

  useEffect(() => {
    trackPageView('NewPassword Page Viewed');
  }, []);

  return (
    <Layout>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            <Modal
              title={formatMessage({
                id: 'newPassword.title',
                defaultMessage: 'New password',
              })}
              shouldRenderCloseIconButton={false}
              isOpen
              okButtonText={formatMessage({
                id: 'newPassword.save',
                defaultMessage: 'Save',
              })}
              okButtonProps={{
                type: 'submit',
                form: 'newPasswordForm',
                loading: isSubmitting,
              }}
              cancelButtonProps={{ variant: 'tertiary' }}
              onCancel={() => navigate(PATHS.signIn)}
              shouldCloseOnOverlayClick={false}
            >
              <form
                id="newPasswordForm"
                onSubmit={handleSubmit(onSubmit)}
                className={styles.form}
              >
                <Controller
                  control={control}
                  name="password"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <InputPassword
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        inputMode="text"
                        type="password"
                        onBlur={onBlur}
                        onChange={onChange}
                        label={formatMessage({
                          id: 'newPassword.newPassword',
                          defaultMessage: 'New password',
                        })}
                        autoComplete="password"
                      />
                      {error && (
                        <CustomPasswordError inputValue={value ?? ''} />
                      )}
                    </>
                  )}
                />

                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error, isTouched },
                  }) => (
                    <InputPassword
                      required
                      ref={ref}
                      value={value}
                      name={name}
                      inputMode="text"
                      type="password"
                      onBlur={onBlur}
                      onChange={(event) => {
                        if (!isSubmitted && !isTouched) {
                          setValue(name, event.target.value);
                        } else {
                          onChange(event);
                        }
                      }}
                      label={formatMessage({
                        id: 'newPassword.confirmNewPassword',
                        defaultMessage: 'Confirm new password',
                      })}
                      error={error?.message}
                      autoComplete="password"
                    />
                  )}
                />
              </form>
            </Modal>
          </div>
        </Layout.Main>
      </Layout.Content>

      <CongratsModal />
    </Layout>
  );
};

import { FC } from 'react';
import cn from 'classnames';

import { SpinnerEye } from '../SpinnerEye/SpinnerEye';

import styles from './StatusCatImage.module.scss';

type StatusCatImageProps = {
  statusImageSrc: string;
  altText: string | undefined;
  isLoading?: boolean | undefined;
  className?: string;
  testID?: string;
};

export const StatusCatImage: FC<StatusCatImageProps> = (props) => {
  const {
    statusImageSrc,
    altText,
    isLoading = false,
    className,
    testID,
  } = props;

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <img
        className={styles.placeholderImage}
        src={statusImageSrc}
        alt={altText}
      />

      {isLoading && (
        <div className={styles.eyes}>
          <SpinnerEye />

          <SpinnerEye />
        </div>
      )}
    </div>
  );
};

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {
  APP_VERSION,
  ENVIRONMENT,
  IS_ERROR_TRACKING_ENABLED,
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
} from '@/shared/config';

import { App } from '@/app/App';

import './index.css';
import './shared/styles/styles.scss';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  environment: ENVIRONMENT,
  release: APP_VERSION,
  enabled: IS_ERROR_TRACKING_ENABLED,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

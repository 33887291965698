import { FC } from 'react';
import cn from 'classnames';

import styles from './ErrorText.module.scss';

type ErrorTextProps = {
  className?: string;
  testID?: string;
  error?: string | JSX.Element;
  withInput?: boolean;
};

export const ErrorText: FC<ErrorTextProps> = (props) => {
  const { className, testID, error, withInput } = props;

  if (!error) {
    return null;
  }

  return (
    <span
      className={cn(
        styles.container,
        'p4',
        withInput && styles.withInput,
        className,
      )}
      data-testid={testID}
    >
      {error}
    </span>
  );
};

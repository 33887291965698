import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';

import { Group, GroupConfigsType } from '../config/medicalImages.types';

import { getGroupGuides } from './getGroupGuides';
import { getGroupMedicalImages } from './getGroupMedicalImages';

export const makeGroupByOrientation = (
  groupConfigs: GroupConfigsType,
  sliceList: Asset[],
): Group => {
  const { groupName, groupCode } = groupConfigs;
  const groupItems = sliceList?.filter(
    (item) =>
      item?.GeneratedReport?.CBCTGPToothSlice?.Orientation === groupCode,
  );

  const guides = getGroupGuides(groupItems);
  const medicalImages = getGroupMedicalImages(groupItems, groupName);

  return {
    type: groupName,
    medicalImages,
    guides,
  };
};

import { defineMessages } from 'react-intl';

import { AlreadyExistError, NotFoundError } from '@/shared/config';
import { UserBadRequestError } from '@/shared/config';

export const sharePatientErrorMessages = defineMessages<string>({
  [AlreadyExistError.ACCEPTED_PATIENT_SHARING]: {
    id: 'sharePatient.errors.alreadyRegisteredError',
    defaultMessage:
      "The patient's case has already been sent to this dental professional.",
  },
});

export const confirmEmailErrorMessages = defineMessages<string>({
  [UserBadRequestError.ALREADY_REGISTERED]: {
    id: 'confirmEmail.errors.alreadyRegisteredError',
    defaultMessage: 'You have already finished the registration',
  },
  [UserBadRequestError.CODE_RETRIES_LIMIT]: {
    id: 'confirmEmail.errors.retriesLimitError',
    defaultMessage:
      'The limit for retries has been exceeded for your confirmation code. Please refill the Sign Up form.',
  },
  [UserBadRequestError.CODE_IS_EXPIRED]: {
    id: 'confirmEmail.errors.codeExpiredError',
    defaultMessage:
      'The confirmation code has expired. Please refill your Sign Up form.',
  },
  [UserBadRequestError.WRONG_CODE]: {
    id: 'confirmEmail.errors.wrongCodeError',
    defaultMessage: 'The code you have entered is incorrect. Please try again.',
  },
});

export const newPasswordErrorMessages = defineMessages<string>({
  [NotFoundError.TOKEN]: {
    id: 'newPassword.errors.twiceTokenError',
    defaultMessage: 'Password already set with this link.',
  },
});

import { ConditionColor } from '@/entities/condition';

export const convertConditionColorToHEX = (
  conditionColor: ConditionColor,
): string => {
  switch (conditionColor) {
    case 'purple':
      return '#5241cc';
    case 'red':
      return '#ff254e';
    case 'yellow':
      return '#ff8a00';
    default:
      return '#ffffff';
  }
};

import { Button } from '@/shared/ui';

export const Buttons = () => {
  return (
    <div>
      <Button>Button</Button>
      <Button variant="secondary">Button</Button>
      <Button variant="tertiary">Button</Button>
    </div>
  );
};

import { defineMessages } from 'react-intl';

import {
  GuideAxesNames,
  MedicalImagesGroups,
  MedicalImagesLabels,
} from './medicalImages.types';

export const guideAxesNames = defineMessages<GuideAxesNames>({
  [GuideAxesNames.SectionOrientationAxial]: {
    id: 'GuideAxes.Axial',
    defaultMessage: 'Axial',
  },
  [GuideAxesNames.SectionOrientationVestibuloOral]: {
    id: 'GuideAxes.Coronal',
    defaultMessage: 'Coronal (Y)',
  },
  [GuideAxesNames.SectionOrientationMesioDistal]: {
    id: 'GuideAxes.Sagittal',
    defaultMessage: 'Sagittal (X)',
  },
  [GuideAxesNames.SectionOrientationInvalidValue]: {
    id: 'GuideAxes.InvalidValue',
    defaultMessage: 'Axial',
  },
});

export const medicalImageTitles = defineMessages<MedicalImagesGroups>({
  [MedicalImagesGroups.CBCTGPToothSliceGuideFrontal]: {
    id: 'medicalImageTitle.space',
    defaultMessage: 'Frontal view',
  },
  [MedicalImagesGroups.OrientationAxial]: {
    id: 'medicalImageTitle.axial',
    defaultMessage: 'Axial view',
  },
  [MedicalImagesGroups.OrientationMesioDistal]: {
    id: 'medicalImageTitle.mesioDistal',
    defaultMessage: 'Mesio-distal',
  },
  [MedicalImagesGroups.CBCTEndoFurcationGuide]: {
    id: 'medicalImageTitle.caries',
    defaultMessage: 'Caries',
  },
  [MedicalImagesGroups.OrientationVestibuloOral]: {
    id: 'medicalImageTitle.vestibuloOral',
    defaultMessage: 'Vestibulo-oral',
  },
  [MedicalImagesGroups.Caries]: {
    id: 'medicalImageTitle.caries',
    defaultMessage: 'Caries',
  },
  [MedicalImagesGroups.PeriapicalLesion]: {
    id: 'medicalImageTitle.periapicalLesion',
    defaultMessage: 'Periapical Lesion',
  },
  [MedicalImagesGroups.EndoReportSectionRootCanalSpace]: {
    id: 'medicalImageTitle.endo_ReportSection_RootCanalSpace',
    defaultMessage: 'Root canal space',
  },
  [MedicalImagesGroups.EndoReportSectionFurcation]: {
    id: 'medicalImageTitle.endo_ReportSection_Furcation',
    defaultMessage: 'Furcation',
  },
  [MedicalImagesGroups.EndoReportSectionRootCanalShape]: {
    id: 'medicalImageTitle.endo_ReportSection_RootCanalShape',
    defaultMessage: 'Root canal shape',
  },
  [MedicalImagesGroups.EndoReportSectionRootCanalSystem]: {
    id: 'medicalImageTitle.endo_ReportSection_RootCanalSystem',
    defaultMessage: 'Root canal system',
  },
  [MedicalImagesGroups.UserUpload]: {
    id: 'medicalImageTitle.userUpload',
    defaultMessage: 'Uploaded',
  },
  [MedicalImagesGroups.SavedMPR]: {
    id: 'medicalImageTitle.savedMPR',
    defaultMessage: 'MPR',
  },
});

export const medicalImagesLabels = defineMessages<MedicalImagesLabels>({
  [MedicalImagesLabels.OrientationAxial]: {
    id: 'medicalImagesLabels.axial',
    defaultMessage: 'Axial',
  },
  [MedicalImagesLabels.OrientationMesioDistal]: {
    id: 'medicalImagesLabels.mesioDistal',
    defaultMessage: 'Mesio-distal',
  },
  [MedicalImagesLabels.CBCTEndoFurcationGuide]: {
    id: 'medicalImagesLabels.caries',
    defaultMessage: 'Caries',
  },
  [MedicalImagesLabels.OrientationVestibuloOral]: {
    id: 'medicalImagesLabels.vestibuloOral',
    defaultMessage: 'Vestibulo-oral',
  },
  [MedicalImagesLabels.Caries]: {
    id: 'medicalImagesLabels.caries',
    defaultMessage: 'Caries',
  },
  [MedicalImagesLabels.PeriapicalLesion]: {
    id: 'medicalImagesLabels.periapicalLesion',
    defaultMessage: 'Periapical Lesion',
  },
  [MedicalImagesLabels.UserUpload]: {
    id: 'medicalImagesLabels.uploaded',
    defaultMessage: 'Uploaded',
  },
  [MedicalImagesLabels.SavedMPR]: {
    id: 'medicalImagesLabels.savedMPR',
    defaultMessage: 'MPR',
  },
});

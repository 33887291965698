import { FC } from 'react';
import cn from 'classnames';

import { Avatar, Tooltip } from '@/shared/ui';
import { OrganizationUserRole } from '@/shared/api/protocol_gen/model/dto_organization';

import { useDoctorDetails } from '@/entities/organization/hooks/useDoctorDetails';

import styles from './DoctorsAvatars.module.scss';

type DoctorsAvatarsProps = {
  doctor: OrganizationUserRole;
  isOnlyOneDoctor: boolean;
  className?: string;
  testID?: string;
};

export const DoctorsAvatars: FC<DoctorsAvatarsProps> = (props) => {
  const { doctor, isOnlyOneDoctor, className, testID } = props;

  const { doctorFullName, doctorInitials, doctorAvatarSrc } =
    useDoctorDetails(doctor);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {isOnlyOneDoctor ? (
        <>
          <Avatar
            src={doctorAvatarSrc}
            alt={doctorFullName}
            initials={doctorInitials}
            key={doctor.UserID}
            className={styles.notHoveredAvatar}
          />

          <p className={cn('p2', styles.fullName)}>{doctorFullName}</p>
        </>
      ) : (
        <Tooltip.Third
          content={doctorFullName}
          delayDuration={0}
          side="right"
          align="end"
          alignOffset={-9}
        >
          <Avatar
            src={doctorAvatarSrc}
            alt={doctorFullName}
            initials={doctorInitials}
            key={doctor.UserID}
          />
        </Tooltip.Third>
      )}
    </div>
  );
};

import { FC, ReactNode } from 'react';
import { TooltipContentProps } from '@radix-ui/react-tooltip';
import cn from 'classnames';

import { Tooltip } from '@/shared/ui';

import styles from './SupportTooltip.module.scss';

type SupportTooltipProps = {
  className?: string;
  children: ReactNode;
  side?: TooltipContentProps['side'];
  sideOffset?: number;
  errorMessage?: ReactNode;
  messageClassName?: string;
};

export const SupportTooltip: FC<SupportTooltipProps> = (props) => {
  const {
    children,
    className,
    sideOffset,
    side,
    errorMessage,
    messageClassName = 'p4',
  } = props;

  return (
    <Tooltip.Primary
      className={cn(styles.tooltip, className)}
      side={side}
      sideOffset={sideOffset}
      content={
        <p className={cn(styles.errorMessage, messageClassName)}>
          {errorMessage}
        </p>
      }
    >
      {children}
    </Tooltip.Primary>
  );
};

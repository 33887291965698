/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Certainty } from '../model/dto_report_certainty';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportDentalPhotoGP {
  TypePredictions: PhotoGPTypePrediction[];
}

/**
 * Image Type inference for Photos
 * Finding the positive values should be implemented by looking over each com.diagnocat.backbone.pipelines.com.diagnocat.model.ConditionCertainty to find a POSITIVE variant
 */
export interface PhotoGPTypePrediction {
  AssetID: string;
  /** maps to "photo_type" */
  ImageType: PhotoGPTypePrediction_PhotoGPTypePredictionImageType | undefined;
  /** set for Intraoral; maps to "intraoral_subtype" */
  IntraoralSubtype:
    | PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype
    | undefined;
  /** set for Portrait; maps to "portrait_subtype" */
  PortraitSubtype:
    | PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype
    | undefined;
  /** set for Portrait; maps to "portrait_side" */
  PortraitSide:
    | PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide
    | undefined;
  /** set for Portrait; maps to "smile" */
  SmileType: PhotoGPTypePrediction_PhotoGPTypePredictionSmileType | undefined;
  /** set only for Intraoral and Portrait; maps to "adult"."true" */
  Adult: Certainty | undefined;
  /** set only for Intraoral; maps to "mirror"."true" */
  Mirror: Certainty | undefined;
}

export interface PhotoGPTypePrediction_PhotoGPTypePredictionImageType {
  /** maps to "intraoral" */
  Intraoral: Certainty | undefined;
  /** maps to "portrait" */
  Portrait: Certainty | undefined;
  /** maps to "other" */
  Other: Certainty | undefined;
  /** maps to "lab" */
  Lab: Certainty | undefined;
}

export interface PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype {
  /** maps to "occlusal_lower" */
  OcclusalLower: Certainty | undefined;
  /** maps to "occlusal_upper" */
  OcclusalUpper: Certainty | undefined;
  /** maps to "occlusal_right" */
  OcclusalRight: Certainty | undefined;
  /** maps to "occlusal_left" */
  OcclusalLeft: Certainty | undefined;
  /** maps to "frontal" */
  Frontal: Certainty | undefined;
}

export interface PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype {
  /** maps to "frontal"; photo taken from the front */
  Frontal: Certainty | undefined;
  /** maps to "45"; photo taken from the 45 degrees angle */
  Oblique: Certainty | undefined;
  /** maps to "profile"; photo taken from the side */
  Profile: Certainty | undefined;
}

export interface PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide {
  /** maps to "left" */
  Left: Certainty | undefined;
  /** maps to "right" */
  Right: Certainty | undefined;
}

export interface PhotoGPTypePrediction_PhotoGPTypePredictionSmileType {
  /** maps to "no_smile" */
  NoSmile: Certainty | undefined;
  /** maps to "smile" */
  Smile: Certainty | undefined;
  /** maps to "grin" */
  Grin: Certainty | undefined;
}

function createBaseReportDentalPhotoGP(): ReportDentalPhotoGP {
  return { TypePredictions: [] };
}

export const ReportDentalPhotoGP = {
  encode(
    message: ReportDentalPhotoGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.TypePredictions) {
      PhotoGPTypePrediction.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportDentalPhotoGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportDentalPhotoGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TypePredictions.push(
            PhotoGPTypePrediction.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportDentalPhotoGP {
    return {
      TypePredictions: Array.isArray(object?.TypePredictions)
        ? object.TypePredictions.map((e: any) =>
            PhotoGPTypePrediction.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ReportDentalPhotoGP): unknown {
    const obj: any = {};
    if (message.TypePredictions) {
      obj.TypePredictions = message.TypePredictions.map((e) =>
        e ? PhotoGPTypePrediction.toJSON(e) : undefined,
      );
    } else {
      obj.TypePredictions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportDentalPhotoGP>, I>>(
    object: I,
  ): ReportDentalPhotoGP {
    const message = createBaseReportDentalPhotoGP();
    message.TypePredictions =
      object.TypePredictions?.map((e) =>
        PhotoGPTypePrediction.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBasePhotoGPTypePrediction(): PhotoGPTypePrediction {
  return {
    AssetID: '',
    ImageType: undefined,
    IntraoralSubtype: undefined,
    PortraitSubtype: undefined,
    PortraitSide: undefined,
    SmileType: undefined,
    Adult: undefined,
    Mirror: undefined,
  };
}

export const PhotoGPTypePrediction = {
  encode(
    message: PhotoGPTypePrediction,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AssetID !== '') {
      writer.uint32(10).string(message.AssetID);
    }
    if (message.ImageType !== undefined) {
      PhotoGPTypePrediction_PhotoGPTypePredictionImageType.encode(
        message.ImageType,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.IntraoralSubtype !== undefined) {
      PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype.encode(
        message.IntraoralSubtype,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.PortraitSubtype !== undefined) {
      PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype.encode(
        message.PortraitSubtype,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.PortraitSide !== undefined) {
      PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide.encode(
        message.PortraitSide,
        writer.uint32(114).fork(),
      ).ldelim();
    }
    if (message.SmileType !== undefined) {
      PhotoGPTypePrediction_PhotoGPTypePredictionSmileType.encode(
        message.SmileType,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    if (message.Adult !== undefined) {
      Certainty.encode(message.Adult, writer.uint32(130).fork()).ldelim();
    }
    if (message.Mirror !== undefined) {
      Certainty.encode(message.Mirror, writer.uint32(138).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhotoGPTypePrediction {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhotoGPTypePrediction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AssetID = reader.string();
          break;
        case 11:
          message.ImageType =
            PhotoGPTypePrediction_PhotoGPTypePredictionImageType.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 12:
          message.IntraoralSubtype =
            PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 13:
          message.PortraitSubtype =
            PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 14:
          message.PortraitSide =
            PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 15:
          message.SmileType =
            PhotoGPTypePrediction_PhotoGPTypePredictionSmileType.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 16:
          message.Adult = Certainty.decode(reader, reader.uint32());
          break;
        case 17:
          message.Mirror = Certainty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PhotoGPTypePrediction {
    return {
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
      ImageType: isSet(object.ImageType)
        ? PhotoGPTypePrediction_PhotoGPTypePredictionImageType.fromJSON(
            object.ImageType,
          )
        : undefined,
      IntraoralSubtype: isSet(object.IntraoralSubtype)
        ? PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype.fromJSON(
            object.IntraoralSubtype,
          )
        : undefined,
      PortraitSubtype: isSet(object.PortraitSubtype)
        ? PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype.fromJSON(
            object.PortraitSubtype,
          )
        : undefined,
      PortraitSide: isSet(object.PortraitSide)
        ? PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide.fromJSON(
            object.PortraitSide,
          )
        : undefined,
      SmileType: isSet(object.SmileType)
        ? PhotoGPTypePrediction_PhotoGPTypePredictionSmileType.fromJSON(
            object.SmileType,
          )
        : undefined,
      Adult: isSet(object.Adult) ? Certainty.fromJSON(object.Adult) : undefined,
      Mirror: isSet(object.Mirror)
        ? Certainty.fromJSON(object.Mirror)
        : undefined,
    };
  },

  toJSON(message: PhotoGPTypePrediction): unknown {
    const obj: any = {};
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    message.ImageType !== undefined &&
      (obj.ImageType = message.ImageType
        ? PhotoGPTypePrediction_PhotoGPTypePredictionImageType.toJSON(
            message.ImageType,
          )
        : undefined);
    message.IntraoralSubtype !== undefined &&
      (obj.IntraoralSubtype = message.IntraoralSubtype
        ? PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype.toJSON(
            message.IntraoralSubtype,
          )
        : undefined);
    message.PortraitSubtype !== undefined &&
      (obj.PortraitSubtype = message.PortraitSubtype
        ? PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype.toJSON(
            message.PortraitSubtype,
          )
        : undefined);
    message.PortraitSide !== undefined &&
      (obj.PortraitSide = message.PortraitSide
        ? PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide.toJSON(
            message.PortraitSide,
          )
        : undefined);
    message.SmileType !== undefined &&
      (obj.SmileType = message.SmileType
        ? PhotoGPTypePrediction_PhotoGPTypePredictionSmileType.toJSON(
            message.SmileType,
          )
        : undefined);
    message.Adult !== undefined &&
      (obj.Adult = message.Adult ? Certainty.toJSON(message.Adult) : undefined);
    message.Mirror !== undefined &&
      (obj.Mirror = message.Mirror
        ? Certainty.toJSON(message.Mirror)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PhotoGPTypePrediction>, I>>(
    object: I,
  ): PhotoGPTypePrediction {
    const message = createBasePhotoGPTypePrediction();
    message.AssetID = object.AssetID ?? '';
    message.ImageType =
      object.ImageType !== undefined && object.ImageType !== null
        ? PhotoGPTypePrediction_PhotoGPTypePredictionImageType.fromPartial(
            object.ImageType,
          )
        : undefined;
    message.IntraoralSubtype =
      object.IntraoralSubtype !== undefined && object.IntraoralSubtype !== null
        ? PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype.fromPartial(
            object.IntraoralSubtype,
          )
        : undefined;
    message.PortraitSubtype =
      object.PortraitSubtype !== undefined && object.PortraitSubtype !== null
        ? PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype.fromPartial(
            object.PortraitSubtype,
          )
        : undefined;
    message.PortraitSide =
      object.PortraitSide !== undefined && object.PortraitSide !== null
        ? PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide.fromPartial(
            object.PortraitSide,
          )
        : undefined;
    message.SmileType =
      object.SmileType !== undefined && object.SmileType !== null
        ? PhotoGPTypePrediction_PhotoGPTypePredictionSmileType.fromPartial(
            object.SmileType,
          )
        : undefined;
    message.Adult =
      object.Adult !== undefined && object.Adult !== null
        ? Certainty.fromPartial(object.Adult)
        : undefined;
    message.Mirror =
      object.Mirror !== undefined && object.Mirror !== null
        ? Certainty.fromPartial(object.Mirror)
        : undefined;
    return message;
  },
};

function createBasePhotoGPTypePrediction_PhotoGPTypePredictionImageType(): PhotoGPTypePrediction_PhotoGPTypePredictionImageType {
  return {
    Intraoral: undefined,
    Portrait: undefined,
    Other: undefined,
    Lab: undefined,
  };
}

export const PhotoGPTypePrediction_PhotoGPTypePredictionImageType = {
  encode(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionImageType,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Intraoral !== undefined) {
      Certainty.encode(message.Intraoral, writer.uint32(10).fork()).ldelim();
    }
    if (message.Portrait !== undefined) {
      Certainty.encode(message.Portrait, writer.uint32(18).fork()).ldelim();
    }
    if (message.Other !== undefined) {
      Certainty.encode(message.Other, writer.uint32(26).fork()).ldelim();
    }
    if (message.Lab !== undefined) {
      Certainty.encode(message.Lab, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionImageType {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionImageType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Intraoral = Certainty.decode(reader, reader.uint32());
          break;
        case 2:
          message.Portrait = Certainty.decode(reader, reader.uint32());
          break;
        case 3:
          message.Other = Certainty.decode(reader, reader.uint32());
          break;
        case 4:
          message.Lab = Certainty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PhotoGPTypePrediction_PhotoGPTypePredictionImageType {
    return {
      Intraoral: isSet(object.Intraoral)
        ? Certainty.fromJSON(object.Intraoral)
        : undefined,
      Portrait: isSet(object.Portrait)
        ? Certainty.fromJSON(object.Portrait)
        : undefined,
      Other: isSet(object.Other) ? Certainty.fromJSON(object.Other) : undefined,
      Lab: isSet(object.Lab) ? Certainty.fromJSON(object.Lab) : undefined,
    };
  },

  toJSON(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionImageType,
  ): unknown {
    const obj: any = {};
    message.Intraoral !== undefined &&
      (obj.Intraoral = message.Intraoral
        ? Certainty.toJSON(message.Intraoral)
        : undefined);
    message.Portrait !== undefined &&
      (obj.Portrait = message.Portrait
        ? Certainty.toJSON(message.Portrait)
        : undefined);
    message.Other !== undefined &&
      (obj.Other = message.Other ? Certainty.toJSON(message.Other) : undefined);
    message.Lab !== undefined &&
      (obj.Lab = message.Lab ? Certainty.toJSON(message.Lab) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PhotoGPTypePrediction_PhotoGPTypePredictionImageType>,
      I
    >,
  >(object: I): PhotoGPTypePrediction_PhotoGPTypePredictionImageType {
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionImageType();
    message.Intraoral =
      object.Intraoral !== undefined && object.Intraoral !== null
        ? Certainty.fromPartial(object.Intraoral)
        : undefined;
    message.Portrait =
      object.Portrait !== undefined && object.Portrait !== null
        ? Certainty.fromPartial(object.Portrait)
        : undefined;
    message.Other =
      object.Other !== undefined && object.Other !== null
        ? Certainty.fromPartial(object.Other)
        : undefined;
    message.Lab =
      object.Lab !== undefined && object.Lab !== null
        ? Certainty.fromPartial(object.Lab)
        : undefined;
    return message;
  },
};

function createBasePhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype(): PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype {
  return {
    OcclusalLower: undefined,
    OcclusalUpper: undefined,
    OcclusalRight: undefined,
    OcclusalLeft: undefined,
    Frontal: undefined,
  };
}

export const PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype = {
  encode(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OcclusalLower !== undefined) {
      Certainty.encode(
        message.OcclusalLower,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.OcclusalUpper !== undefined) {
      Certainty.encode(
        message.OcclusalUpper,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.OcclusalRight !== undefined) {
      Certainty.encode(
        message.OcclusalRight,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.OcclusalLeft !== undefined) {
      Certainty.encode(message.OcclusalLeft, writer.uint32(34).fork()).ldelim();
    }
    if (message.Frontal !== undefined) {
      Certainty.encode(message.Frontal, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OcclusalLower = Certainty.decode(reader, reader.uint32());
          break;
        case 2:
          message.OcclusalUpper = Certainty.decode(reader, reader.uint32());
          break;
        case 3:
          message.OcclusalRight = Certainty.decode(reader, reader.uint32());
          break;
        case 4:
          message.OcclusalLeft = Certainty.decode(reader, reader.uint32());
          break;
        case 5:
          message.Frontal = Certainty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype {
    return {
      OcclusalLower: isSet(object.OcclusalLower)
        ? Certainty.fromJSON(object.OcclusalLower)
        : undefined,
      OcclusalUpper: isSet(object.OcclusalUpper)
        ? Certainty.fromJSON(object.OcclusalUpper)
        : undefined,
      OcclusalRight: isSet(object.OcclusalRight)
        ? Certainty.fromJSON(object.OcclusalRight)
        : undefined,
      OcclusalLeft: isSet(object.OcclusalLeft)
        ? Certainty.fromJSON(object.OcclusalLeft)
        : undefined,
      Frontal: isSet(object.Frontal)
        ? Certainty.fromJSON(object.Frontal)
        : undefined,
    };
  },

  toJSON(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype,
  ): unknown {
    const obj: any = {};
    message.OcclusalLower !== undefined &&
      (obj.OcclusalLower = message.OcclusalLower
        ? Certainty.toJSON(message.OcclusalLower)
        : undefined);
    message.OcclusalUpper !== undefined &&
      (obj.OcclusalUpper = message.OcclusalUpper
        ? Certainty.toJSON(message.OcclusalUpper)
        : undefined);
    message.OcclusalRight !== undefined &&
      (obj.OcclusalRight = message.OcclusalRight
        ? Certainty.toJSON(message.OcclusalRight)
        : undefined);
    message.OcclusalLeft !== undefined &&
      (obj.OcclusalLeft = message.OcclusalLeft
        ? Certainty.toJSON(message.OcclusalLeft)
        : undefined);
    message.Frontal !== undefined &&
      (obj.Frontal = message.Frontal
        ? Certainty.toJSON(message.Frontal)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype>,
      I
    >,
  >(object: I): PhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype {
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionIntraoralSubtype();
    message.OcclusalLower =
      object.OcclusalLower !== undefined && object.OcclusalLower !== null
        ? Certainty.fromPartial(object.OcclusalLower)
        : undefined;
    message.OcclusalUpper =
      object.OcclusalUpper !== undefined && object.OcclusalUpper !== null
        ? Certainty.fromPartial(object.OcclusalUpper)
        : undefined;
    message.OcclusalRight =
      object.OcclusalRight !== undefined && object.OcclusalRight !== null
        ? Certainty.fromPartial(object.OcclusalRight)
        : undefined;
    message.OcclusalLeft =
      object.OcclusalLeft !== undefined && object.OcclusalLeft !== null
        ? Certainty.fromPartial(object.OcclusalLeft)
        : undefined;
    message.Frontal =
      object.Frontal !== undefined && object.Frontal !== null
        ? Certainty.fromPartial(object.Frontal)
        : undefined;
    return message;
  },
};

function createBasePhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype(): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype {
  return { Frontal: undefined, Oblique: undefined, Profile: undefined };
}

export const PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype = {
  encode(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Frontal !== undefined) {
      Certainty.encode(message.Frontal, writer.uint32(10).fork()).ldelim();
    }
    if (message.Oblique !== undefined) {
      Certainty.encode(message.Oblique, writer.uint32(18).fork()).ldelim();
    }
    if (message.Profile !== undefined) {
      Certainty.encode(message.Profile, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Frontal = Certainty.decode(reader, reader.uint32());
          break;
        case 2:
          message.Oblique = Certainty.decode(reader, reader.uint32());
          break;
        case 3:
          message.Profile = Certainty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype {
    return {
      Frontal: isSet(object.Frontal)
        ? Certainty.fromJSON(object.Frontal)
        : undefined,
      Oblique: isSet(object.Oblique)
        ? Certainty.fromJSON(object.Oblique)
        : undefined,
      Profile: isSet(object.Profile)
        ? Certainty.fromJSON(object.Profile)
        : undefined,
    };
  },

  toJSON(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype,
  ): unknown {
    const obj: any = {};
    message.Frontal !== undefined &&
      (obj.Frontal = message.Frontal
        ? Certainty.toJSON(message.Frontal)
        : undefined);
    message.Oblique !== undefined &&
      (obj.Oblique = message.Oblique
        ? Certainty.toJSON(message.Oblique)
        : undefined);
    message.Profile !== undefined &&
      (obj.Profile = message.Profile
        ? Certainty.toJSON(message.Profile)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype>,
      I
    >,
  >(object: I): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype {
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionPortraitSubtype();
    message.Frontal =
      object.Frontal !== undefined && object.Frontal !== null
        ? Certainty.fromPartial(object.Frontal)
        : undefined;
    message.Oblique =
      object.Oblique !== undefined && object.Oblique !== null
        ? Certainty.fromPartial(object.Oblique)
        : undefined;
    message.Profile =
      object.Profile !== undefined && object.Profile !== null
        ? Certainty.fromPartial(object.Profile)
        : undefined;
    return message;
  },
};

function createBasePhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide(): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide {
  return { Left: undefined, Right: undefined };
}

export const PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide = {
  encode(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Left !== undefined) {
      Certainty.encode(message.Left, writer.uint32(10).fork()).ldelim();
    }
    if (message.Right !== undefined) {
      Certainty.encode(message.Right, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Left = Certainty.decode(reader, reader.uint32());
          break;
        case 2:
          message.Right = Certainty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide {
    return {
      Left: isSet(object.Left) ? Certainty.fromJSON(object.Left) : undefined,
      Right: isSet(object.Right) ? Certainty.fromJSON(object.Right) : undefined,
    };
  },

  toJSON(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide,
  ): unknown {
    const obj: any = {};
    message.Left !== undefined &&
      (obj.Left = message.Left ? Certainty.toJSON(message.Left) : undefined);
    message.Right !== undefined &&
      (obj.Right = message.Right ? Certainty.toJSON(message.Right) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide>,
      I
    >,
  >(object: I): PhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide {
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionPortraitSide();
    message.Left =
      object.Left !== undefined && object.Left !== null
        ? Certainty.fromPartial(object.Left)
        : undefined;
    message.Right =
      object.Right !== undefined && object.Right !== null
        ? Certainty.fromPartial(object.Right)
        : undefined;
    return message;
  },
};

function createBasePhotoGPTypePrediction_PhotoGPTypePredictionSmileType(): PhotoGPTypePrediction_PhotoGPTypePredictionSmileType {
  return { NoSmile: undefined, Smile: undefined, Grin: undefined };
}

export const PhotoGPTypePrediction_PhotoGPTypePredictionSmileType = {
  encode(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionSmileType,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.NoSmile !== undefined) {
      Certainty.encode(message.NoSmile, writer.uint32(10).fork()).ldelim();
    }
    if (message.Smile !== undefined) {
      Certainty.encode(message.Smile, writer.uint32(18).fork()).ldelim();
    }
    if (message.Grin !== undefined) {
      Certainty.encode(message.Grin, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PhotoGPTypePrediction_PhotoGPTypePredictionSmileType {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionSmileType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.NoSmile = Certainty.decode(reader, reader.uint32());
          break;
        case 2:
          message.Smile = Certainty.decode(reader, reader.uint32());
          break;
        case 3:
          message.Grin = Certainty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PhotoGPTypePrediction_PhotoGPTypePredictionSmileType {
    return {
      NoSmile: isSet(object.NoSmile)
        ? Certainty.fromJSON(object.NoSmile)
        : undefined,
      Smile: isSet(object.Smile) ? Certainty.fromJSON(object.Smile) : undefined,
      Grin: isSet(object.Grin) ? Certainty.fromJSON(object.Grin) : undefined,
    };
  },

  toJSON(
    message: PhotoGPTypePrediction_PhotoGPTypePredictionSmileType,
  ): unknown {
    const obj: any = {};
    message.NoSmile !== undefined &&
      (obj.NoSmile = message.NoSmile
        ? Certainty.toJSON(message.NoSmile)
        : undefined);
    message.Smile !== undefined &&
      (obj.Smile = message.Smile ? Certainty.toJSON(message.Smile) : undefined);
    message.Grin !== undefined &&
      (obj.Grin = message.Grin ? Certainty.toJSON(message.Grin) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PhotoGPTypePrediction_PhotoGPTypePredictionSmileType>,
      I
    >,
  >(object: I): PhotoGPTypePrediction_PhotoGPTypePredictionSmileType {
    const message =
      createBasePhotoGPTypePrediction_PhotoGPTypePredictionSmileType();
    message.NoSmile =
      object.NoSmile !== undefined && object.NoSmile !== null
        ? Certainty.fromPartial(object.NoSmile)
        : undefined;
    message.Smile =
      object.Smile !== undefined && object.Smile !== null
        ? Certainty.fromPartial(object.Smile)
        : undefined;
    message.Grin =
      object.Grin !== undefined && object.Grin !== null
        ? Certainty.fromPartial(object.Grin)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Accordion, WidgetCard } from '@/shared/ui';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { RarePathologies } from './RarePathologies/RarePathologies';

type NonDentalFindingsProps = {
  conditions: Condition[];
  className?: string;
};

export const NonDentalFindings: FC<NonDentalFindingsProps> = (props) => {
  const { className, conditions } = props;

  return (
    <WidgetCard className={className}>
      <Accordion.Root type="single" collapsible>
        <Accordion.Item value="one">
          <Accordion.Header>
            <Accordion.Trigger>
              <h4 className="h4">
                <FormattedMessage
                  id="report.rarePathology.header"
                  defaultMessage="Non-dental findings"
                />
              </h4>
            </Accordion.Trigger>
          </Accordion.Header>

          <Accordion.Content>
            <RarePathologies conditions={conditions} />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </WidgetCard>
  );
};

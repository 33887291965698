import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

export const getConditionModelScorePercentage = (
  condition?: Condition,
): string | undefined => {
  // TODO: [h|2] what is IsModelScoreSet flag?
  // if (condition?.Certainty?.IsModelScoreSet) {
  const score = condition?.Certainty?.ModelScore ?? 0;

  if (score > 0 && score <= 0.01) {
    return '~0%';
  }

  if (score > 0.01) {
    return `${Math.trunc(score * 100)}%`;
  }

  return '';
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTToothRootType,
  ToothNumeration,
  cBCTToothRootTypeFromJSON,
  cBCTToothRootTypeToJSON,
} from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export enum ToothPeriodontalStatusMeasurementSite {
  PeriodontalMeasurement_Site_InvalidValue = 0,
  PeriodontalMeasurement_Site_Mesial = 1,
  PeriodontalMeasurement_Site_MesialBuccal = 2,
  PeriodontalMeasurement_Site_MesialLingual = 3,
  PeriodontalMeasurement_Site_Distal = 4,
  PeriodontalMeasurement_Site_DistalBuccal = 5,
  PeriodontalMeasurement_Site_DistalLingual = 6,
  PeriodontalMeasurement_Site_Vestibular = 7,
  PeriodontalMeasurement_Site_Oral = 8,
  UNRECOGNIZED = -1,
}

export function toothPeriodontalStatusMeasurementSiteFromJSON(
  object: any,
): ToothPeriodontalStatusMeasurementSite {
  switch (object) {
    case 0:
    case 'PeriodontalMeasurement_Site_InvalidValue':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_InvalidValue;
    case 1:
    case 'PeriodontalMeasurement_Site_Mesial':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Mesial;
    case 2:
    case 'PeriodontalMeasurement_Site_MesialBuccal':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_MesialBuccal;
    case 3:
    case 'PeriodontalMeasurement_Site_MesialLingual':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_MesialLingual;
    case 4:
    case 'PeriodontalMeasurement_Site_Distal':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Distal;
    case 5:
    case 'PeriodontalMeasurement_Site_DistalBuccal':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_DistalBuccal;
    case 6:
    case 'PeriodontalMeasurement_Site_DistalLingual':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_DistalLingual;
    case 7:
    case 'PeriodontalMeasurement_Site_Vestibular':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Vestibular;
    case 8:
    case 'PeriodontalMeasurement_Site_Oral':
      return ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Oral;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ToothPeriodontalStatusMeasurementSite.UNRECOGNIZED;
  }
}

export function toothPeriodontalStatusMeasurementSiteToJSON(
  object: ToothPeriodontalStatusMeasurementSite,
): string {
  switch (object) {
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_InvalidValue:
      return 'PeriodontalMeasurement_Site_InvalidValue';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Mesial:
      return 'PeriodontalMeasurement_Site_Mesial';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_MesialBuccal:
      return 'PeriodontalMeasurement_Site_MesialBuccal';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_MesialLingual:
      return 'PeriodontalMeasurement_Site_MesialLingual';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Distal:
      return 'PeriodontalMeasurement_Site_Distal';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_DistalBuccal:
      return 'PeriodontalMeasurement_Site_DistalBuccal';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_DistalLingual:
      return 'PeriodontalMeasurement_Site_DistalLingual';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Vestibular:
      return 'PeriodontalMeasurement_Site_Vestibular';
    case ToothPeriodontalStatusMeasurementSite.PeriodontalMeasurement_Site_Oral:
      return 'PeriodontalMeasurement_Site_Oral';
    case ToothPeriodontalStatusMeasurementSite.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ToothPeriodontalStatus {
  ID: string;
  /**
   * `ToothNumeration`: [optional]. Tooth numeration, outputted from segmentron
   * reports. REQUIRED in segmentron reports.
   */
  ToothNumeration: ToothNumeration | undefined;
  /**
   * `ToothLocalizationID`: [optional]. Link to tooth localization, outputted from
   * nonsegmentron reports. REQUIRED in nonsegmentron reports.
   */
  ToothLocalizationID: string;
  Measurements: ToothPeriodontalStatusMeasurement[];
  Roots: ToothPeriodontalStatusRoot[];
}

export interface ToothPeriodontalStatusMeasurement {
  Site: ToothPeriodontalStatusMeasurementSite;
  /** At least one measurement field is required to be set. */
  BoneLoss?: number | undefined;
  /** Distance from CEJ to gingiva in mm. */
  Recession?: number | undefined;
  /** Distance from gingiva to bone level in mm. */
  Probing?: number | undefined;
}

export interface ToothPeriodontalStatusRoot {
  RootType: CBCTToothRootType;
  Length: number;
}

function createBaseToothPeriodontalStatus(): ToothPeriodontalStatus {
  return {
    ID: '',
    ToothNumeration: undefined,
    ToothLocalizationID: '',
    Measurements: [],
    Roots: [],
  };
}

export const ToothPeriodontalStatus = {
  encode(
    message: ToothPeriodontalStatus,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.ToothNumeration !== undefined) {
      ToothNumeration.encode(
        message.ToothNumeration,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.ToothLocalizationID !== '') {
      writer.uint32(26).string(message.ToothLocalizationID);
    }
    for (const v of message.Measurements) {
      ToothPeriodontalStatusMeasurement.encode(
        v!,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    for (const v of message.Roots) {
      ToothPeriodontalStatusRoot.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ToothPeriodontalStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToothPeriodontalStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.ToothNumeration = ToothNumeration.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.ToothLocalizationID = reader.string();
          break;
        case 4:
          message.Measurements.push(
            ToothPeriodontalStatusMeasurement.decode(reader, reader.uint32()),
          );
          break;
        case 5:
          message.Roots.push(
            ToothPeriodontalStatusRoot.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ToothPeriodontalStatus {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      ToothNumeration: isSet(object.ToothNumeration)
        ? ToothNumeration.fromJSON(object.ToothNumeration)
        : undefined,
      ToothLocalizationID: isSet(object.ToothLocalizationID)
        ? String(object.ToothLocalizationID)
        : '',
      Measurements: Array.isArray(object?.Measurements)
        ? object.Measurements.map((e: any) =>
            ToothPeriodontalStatusMeasurement.fromJSON(e),
          )
        : [],
      Roots: Array.isArray(object?.Roots)
        ? object.Roots.map((e: any) => ToothPeriodontalStatusRoot.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ToothPeriodontalStatus): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.ToothNumeration !== undefined &&
      (obj.ToothNumeration = message.ToothNumeration
        ? ToothNumeration.toJSON(message.ToothNumeration)
        : undefined);
    message.ToothLocalizationID !== undefined &&
      (obj.ToothLocalizationID = message.ToothLocalizationID);
    if (message.Measurements) {
      obj.Measurements = message.Measurements.map((e) =>
        e ? ToothPeriodontalStatusMeasurement.toJSON(e) : undefined,
      );
    } else {
      obj.Measurements = [];
    }
    if (message.Roots) {
      obj.Roots = message.Roots.map((e) =>
        e ? ToothPeriodontalStatusRoot.toJSON(e) : undefined,
      );
    } else {
      obj.Roots = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ToothPeriodontalStatus>, I>>(
    object: I,
  ): ToothPeriodontalStatus {
    const message = createBaseToothPeriodontalStatus();
    message.ID = object.ID ?? '';
    message.ToothNumeration =
      object.ToothNumeration !== undefined && object.ToothNumeration !== null
        ? ToothNumeration.fromPartial(object.ToothNumeration)
        : undefined;
    message.ToothLocalizationID = object.ToothLocalizationID ?? '';
    message.Measurements =
      object.Measurements?.map((e) =>
        ToothPeriodontalStatusMeasurement.fromPartial(e),
      ) || [];
    message.Roots =
      object.Roots?.map((e) => ToothPeriodontalStatusRoot.fromPartial(e)) || [];
    return message;
  },
};

function createBaseToothPeriodontalStatusMeasurement(): ToothPeriodontalStatusMeasurement {
  return {
    Site: 0,
    BoneLoss: undefined,
    Recession: undefined,
    Probing: undefined,
  };
}

export const ToothPeriodontalStatusMeasurement = {
  encode(
    message: ToothPeriodontalStatusMeasurement,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Site !== 0) {
      writer.uint32(8).int32(message.Site);
    }
    if (message.BoneLoss !== undefined) {
      writer.uint32(21).float(message.BoneLoss);
    }
    if (message.Recession !== undefined) {
      writer.uint32(29).float(message.Recession);
    }
    if (message.Probing !== undefined) {
      writer.uint32(37).float(message.Probing);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ToothPeriodontalStatusMeasurement {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToothPeriodontalStatusMeasurement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Site = reader.int32() as any;
          break;
        case 2:
          message.BoneLoss = reader.float();
          break;
        case 3:
          message.Recession = reader.float();
          break;
        case 4:
          message.Probing = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ToothPeriodontalStatusMeasurement {
    return {
      Site: isSet(object.Site)
        ? toothPeriodontalStatusMeasurementSiteFromJSON(object.Site)
        : 0,
      BoneLoss: isSet(object.BoneLoss) ? Number(object.BoneLoss) : undefined,
      Recession: isSet(object.Recession) ? Number(object.Recession) : undefined,
      Probing: isSet(object.Probing) ? Number(object.Probing) : undefined,
    };
  },

  toJSON(message: ToothPeriodontalStatusMeasurement): unknown {
    const obj: any = {};
    message.Site !== undefined &&
      (obj.Site = toothPeriodontalStatusMeasurementSiteToJSON(message.Site));
    message.BoneLoss !== undefined && (obj.BoneLoss = message.BoneLoss);
    message.Recession !== undefined && (obj.Recession = message.Recession);
    message.Probing !== undefined && (obj.Probing = message.Probing);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ToothPeriodontalStatusMeasurement>, I>,
  >(object: I): ToothPeriodontalStatusMeasurement {
    const message = createBaseToothPeriodontalStatusMeasurement();
    message.Site = object.Site ?? 0;
    message.BoneLoss = object.BoneLoss ?? undefined;
    message.Recession = object.Recession ?? undefined;
    message.Probing = object.Probing ?? undefined;
    return message;
  },
};

function createBaseToothPeriodontalStatusRoot(): ToothPeriodontalStatusRoot {
  return { RootType: 0, Length: 0 };
}

export const ToothPeriodontalStatusRoot = {
  encode(
    message: ToothPeriodontalStatusRoot,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.RootType !== 0) {
      writer.uint32(8).int32(message.RootType);
    }
    if (message.Length !== 0) {
      writer.uint32(21).float(message.Length);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ToothPeriodontalStatusRoot {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToothPeriodontalStatusRoot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.RootType = reader.int32() as any;
          break;
        case 2:
          message.Length = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ToothPeriodontalStatusRoot {
    return {
      RootType: isSet(object.RootType)
        ? cBCTToothRootTypeFromJSON(object.RootType)
        : 0,
      Length: isSet(object.Length) ? Number(object.Length) : 0,
    };
  },

  toJSON(message: ToothPeriodontalStatusRoot): unknown {
    const obj: any = {};
    message.RootType !== undefined &&
      (obj.RootType = cBCTToothRootTypeToJSON(message.RootType));
    message.Length !== undefined && (obj.Length = message.Length);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ToothPeriodontalStatusRoot>, I>>(
    object: I,
  ): ToothPeriodontalStatusRoot {
    const message = createBaseToothPeriodontalStatusRoot();
    message.RootType = object.RootType ?? 0;
    message.Length = object.Length ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { BASE_URI } from '@/shared/config/config';

import { AccessServiceClientImpl } from './protocol_gen/api/core/svc_access';
import { PatientServiceClientImpl } from './protocol_gen/api/core/svc_patient';
import { UserServiceClientImpl } from './protocol_gen/api/core/svc_user';
import { PatientTaskServiceClientImpl } from './protocol_gen/api/core/svc_patient_task';
import { NotificationsClientImpl } from './protocol_gen/api/notifications/svc_notifications';
import { ReportServiceClientImpl } from './protocol_gen/api/core/svc_report';
import { OrganizationServiceClientImpl } from './protocol_gen/api/core/svc_organization';
import { StorageServiceClientImpl } from './protocol_gen/api/core/svc_upload';
import { AuthenticationClientImpl, Authentication } from './protocol_gen/api/auth/svc_authentication';
import { AssetServiceClientImpl } from './protocol_gen/api/core/svc_asset';
import { QuestionnaireServiceClientImpl } from './protocol_gen/api/marketing/svc_questionnaire';
import { IntegrationServiceClientImpl } from './protocol_gen/api/marketing/svc_integration';
import { StudyServiceClientImpl } from './protocol_gen/api/core/svc_study';
import { BillingClientImpl } from './protocol_gen/api/billing_new/svc_billing_new';
import { redirectToSignIn } from '../lib';

const lockTimeout = 20 * 1e3;

const grpcWebEndpoint = `${BASE_URI}/api/grpcweb_ws`;

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

function sleep(timeout: number) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export class ApiError {
  message: string;

  code: number;

  metadata: grpc.Metadata | undefined;

  FileID?: string;

  type:
    | 'UnauthenticatedError'
    | 'PermissionDeniedError'
    | 'ConcurrencyError'
    | 'AlreadyExistError'
    | 'NotFoundError'
    | 'InvalidArgumentValueError'
    | 'RequiredArgumentMissingError'
    | 'UnknownError'
    | 'InternalServerError';

  constructor(
    message: string,
    code: number,
    metadata: grpc.Metadata | undefined,
  ) {
    this.message = message;
    this.code = code;
    this.metadata = metadata;

    try {
      const m = JSON.parse(message);
      this.type = m.type;
    } catch (e) {
      this.type = 'UnknownError';
    }
  }
}

class Refresher {
  authAPI: Authentication;
  refreshTTL: number;
  accessTTL: number;
  isInited: boolean = false;

  constructor(authAPI: Authentication) {
    this.authAPI = authAPI;
    this.refreshTTL = 0;
    this.accessTTL = 0;

    localStorage.setItem('refresh_lock', 'pass')
    // ensures that no dead-lock occurs
    setInterval(()=>{
      const lock = localStorage.getItem('refresh_lock');
      if (lock == 'pass') {
        return;
      }

      if (((new Date()).getTime() - Number(lock)) > lockTimeout) {
        localStorage.setItem('refresh_lock', 'pass');
      }
    }, 100);
  }

  async init() {
    if (this.isInited) {
      return;
    }

    const {AccessTokenTTL, RefreshTokenTTL} = await this.authAPI.GetTTLs({});
    this.refreshTTL = RefreshTokenTTL;
    this.accessTTL = AccessTokenTTL * 0.8; // leave 20% for the refresh
    this.isInited = true;
    console.log("TTLs", this.refreshTTL, this.accessTTL);
  }

  async refresh() {
    await this.init();

    const ts = Number(localStorage.getItem('refresh_last_at'));
    if ((new Date().getTime() - ts) > this.accessTTL) {
      if (localStorage.getItem('refresh_lock') === 'pass') {
        localStorage.setItem(
          'refresh_lock', (new Date()).getTime().toString());

        try {
          const {AccessTokenTTL, RefreshTokenTTL} = await this.authAPI.Refresh({});
          this.refreshTTL = RefreshTokenTTL;
          this.accessTTL = AccessTokenTTL;
        } catch (e) {
          const parsedMessage = JSON.parse((e as ApiError)?.message);

          if (parsedMessage.text === 'refresh token timed out' ||
            parsedMessage.text === 'no session was found for provided access token') {
            localStorage.removeItem('user');
            window.dispatchEvent(new Event('storage'));
            redirectToSignIn();
          }

          reportError(e);
          throw e;
        } finally {
          localStorage.setItem('refresh_lock', 'pass')
        }

        localStorage.setItem(
          'refresh_last_at', 
          (new Date()).getTime().toString());
      
       } else {
        await sleep(50);
        await this.refresh();
      }
    }
  }
}

export class Impl {
  refresher: Refresher | null = null;

  private host: string;

  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  async unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {

    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options?.metadata
        ? new BrowserHeaders({
            ...this.options.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;

    return (async () => {
      const serviceName = methodDesc.service.serviceName !== 'com.diagnocat.auth.Authentication';
      const methodName = methodDesc.methodName !== 'Refresh';
      if (serviceName && methodName) {
        await this.refresher?.refresh();
      }
      
      return await new Promise((rslv, rjct) => {
        grpc.unary(methodDesc, {
          request,
          host: this.host,
          metadata: maybeCombinedMetadata,
          transport: this.options.transport,
          debug: this.options.debug,
          onEnd(response) {
            const reportError = () => {
              const err = new ApiError(
                response.statusMessage,
                response.status,
                response.trailers,
              );
              rjct(err);
            };
  
            if (response.status === grpc.Code.OK) {
              rslv(response.message);
            } else if (response.status === grpc.Code.Unauthenticated) {
              localStorage.removeItem('user');
              window.dispatchEvent(new Event('storage'));
              reportError();
            } else {
              reportError();
            }
          }
        });
      })
    })();
  }

  

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 14, 15];
    const DEFAULT_TIMEOUT_TIME = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options?.metadata
        ? new BrowserHeaders({
            ...this.options.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;

    return new Observable((observer) => {
      const upStream = async () => {
        await this.refresher?.refresh();

        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => {
            return observer.next(next);
          },
          onEnd: (code: grpc.Code, message: string) => {
            const reportError = () => {
              console.log('%c There is an error %s in %s stream %O', 'color:lime;background:black;', message, methodDesc.methodName, methodDesc);
              console.error(message);
              observer.error(new ApiError(message, code, undefined));
            }
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else if (code === grpc.Code.Unauthenticated) {
              localStorage.removeItem('user');
              window.dispatchEvent(new Event('storage'));
              reportError();
            } else {
              reportError();
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

const enableDebug = false;
  // window.location.hostname.search('staging.diagnocat.dev') !== -1 ||
  // window.location.hostname.search('dev.diagnocat.dev') !== -1 ||
  // window.location.hostname.search('localhost') !== -1 ||
  // window.location.hostname.search('127.0.0.1') !== -1 ||
  // isDev;

const transport = grpc.XhrTransport({
  withCredentials: true,
});

// const transport = grpc.FetchReadableStreamTransport({
//   credentials: 'include',
//   mode: 'no-cors',
//   cache: 'no-cache',
//   keepalive: true,
// });

const rpc = new Impl(grpcWebEndpoint, {
  debug: enableDebug,
  metadata: new grpc.Metadata({}),
  transport: transport,
  streamingTransport: transport,
});

const auth = new AuthenticationClientImpl(rpc);
const refresher = new Refresher(auth);
rpc.refresher = refresher;

const api = {
  user: new UserServiceClientImpl(rpc),
  access: new AccessServiceClientImpl(rpc),
  patient: new PatientServiceClientImpl(rpc),
  patientTask: new PatientTaskServiceClientImpl(rpc),
  billing: new BillingClientImpl(rpc),
  notifications: new NotificationsClientImpl(rpc),
  report: new ReportServiceClientImpl(rpc),
  organization: new OrganizationServiceClientImpl(rpc),
  // StorageServiceClientImpl contains the study entity
  storage: new StorageServiceClientImpl(rpc),
  study: new StudyServiceClientImpl(rpc),
  auth,
  assets: new AssetServiceClientImpl(rpc),
  marketing: new QuestionnaireServiceClientImpl(rpc),
  hubspot: new IntegrationServiceClientImpl(rpc),

  refresher,
};



export type ApiServices = keyof typeof api;
export default api;
/* eslint-enable */

import { generatePath, useLocation, useNavigate } from 'react-router';
import { isEmpty } from 'lodash';

import { useAppSelector } from '@/shared/hooks';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { LocationStateType } from '@/shared/config';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { billingModel } from '@/entities/billing';
import { getReportPath, SegmentationReportTypes } from '@/entities/reports';
import { getImageSrc } from '@/entities/assets';

import { useNewReport } from '@/features/addNewReport';
import { StructuredReportsAndStudiesType } from '@/features/reportCards';

import { useDownloadPDF } from '@/pages/PreviewReport/hooks/useDownloadPDF';

import { getRelevantStudy, getSegmentronReportLink } from '../lib';

export const useReportCard = (
  structuredReportsAndStudies: StructuredReportsAndStudiesType,
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
  } = useNewReport();

  const reportType = structuredReportsAndStudies.reportType;

  const report = structuredReportsAndStudies?.report;
  const studies = structuredReportsAndStudies?.studies;
  const date = structuredReportsAndStudies?.date;

  const activeReportsIDs = structuredReportsAndStudies?.activeReportIDs;

  const relevantStudy = getRelevantStudy(studies);
  const relevantStudyType = relevantStudy?.Type;

  const patientID = report?.PatientID;

  const reportID = report?.ID;
  const reportStatus = report?.Status;
  const isReportGenerationCompleted = reportStatus?.Completed;
  const isReportSigned = report?.Signature?.Signed;
  const isReportFailed = reportStatus?.Failed;

  const isSegmentronReport = SegmentationReportTypes.includes(reportType);

  const isReportOrderAvailable = useAppSelector(
    billingModel.selectors.selectIsReportOrderAvailable(
      relevantStudyType ?? StudyType.UNRECOGNIZED,
    ),
  );

  const { downloadPDF, isLoadingPDF } = useDownloadPDF(
    patientID ?? '',
    reportID ?? '',
  );

  const isIOXRayOrPanowings =
    relevantStudyType === StudyType.StudyType_IntraoralXRay ||
    (relevantStudyType === StudyType.StudyType_PanoramicXRay &&
      !isEmpty(report?.DataPanoBitewings));

  const previewImageSrc = getImageSrc(
    report?.DisplayAssetID || relevantStudy?.DisplayAssetID,
    'preview',
  );

  // NOTE: type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const navigateToReport = ({
    reportType,
    patientID,
    reportID,
  }: {
    reportType: ReportType;
    patientID: string;
    reportID: string;
  }) => {
    navigate(
      generatePath(getReportPath(reportType), {
        patientID,
        reportID,
      }),
      {
        state: {
          ...locationState,
          lastPositionPatientProfile: window.pageYOffset,
        },
      },
    );
  };

  const handleCardClick = () => {
    if (isReportGenerationCompleted && !isReportFailed) {
      if (isSegmentronReport && report) {
        window.open(
          getSegmentronReportLink(report),
          '_blank',
          'noopener,noreferrer',
        );
      } else if (relevantStudy && patientID && reportID) {
        navigateToReport({
          reportType: reportType,
          patientID: patientID,
          reportID: reportID,
        });
      }
    }
  };

  const reorderReport = () => {
    const findStudyID = (type: StudyType) =>
      studies?.find((study) => study.Type === type)?.ID;

    const CBCTID = findStudyID(StudyType.StudyType_CBCT);
    const IOXRayStudyID = findStudyID(StudyType.StudyType_IntraoralXRay);
    const PanoStudyID = findStudyID(StudyType.StudyType_PanoramicXRay);
    const IOSID = findStudyID(StudyType.StudyType_IOS_Meshes);
    const dentalPhotoStudyID = findStudyID(StudyType.StudyType_DentalPhoto);

    if (CBCTID) {
      if (reportType === ReportType.ReportType_CBCT_GP) {
        requestCBCTGPReport(CBCTID);
      }

      if (reportType === ReportType.ReportType_CBCT_Segmentation) {
        requestCBCTSegmentationReport(CBCTID);
      }

      if (reportType === ReportType.ReportType_StudioImplant) {
        requestStudioImplantReport({
          CBCTStudyID: CBCTID,
          IOSMeshesStudyID: IOSID as string,
          DentalPhotoStudyID: dentalPhotoStudyID as string,
        });
      }

      if (reportType === ReportType.ReportType_StudioOrtho) {
        requestStudioOrthoReport({
          CBCTStudyID: CBCTID,
          IOSMeshesStudyID: IOSID as string,
          DentalPhotoStudyID: dentalPhotoStudyID as string,
        });
      }
    }

    if (IOXRayStudyID && reportType === ReportType.ReportType_IOXRay_GP) {
      requestIOXRayGPReport(IOXRayStudyID);
    }

    if (PanoStudyID && reportType === ReportType.ReportType_Pano_GP) {
      requestPanoGPReport(PanoStudyID);
    }
  };

  return {
    isReportGenerationCompleted,
    isIOXRayOrPanowings,
    isSegmentronReport,
    reportType,
    previewImageSrc,
    report,
    relevantStudy,
    activeReportsIDs,
    date,
    isReportFailed,
    isReportSigned,
    isReportOrderAvailable,
    isLoadingPDF,
    handleCardClick,
    reorderReport,
    downloadPDF,
  };
};

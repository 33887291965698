import React, { FC } from 'react';
import cn from 'classnames';

import styles from './Content.module.scss';

type ContentProps = {
  className?: string;
  testID?: string;
  children: React.ReactNode;
};

export const Content: FC<ContentProps> = (props) => {
  const { className, testID, children } = props;

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {children}
    </div>
  );
};

import { compose, flip, includes, prop } from 'ramda';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { ConditionInterface } from '../config/types';

import { collectConditionCodesFromGroups } from './collectConditionCodesFromGroups';

export const isConditionInGroups = (groups: Record<string, ConditionCode[]>) =>
  compose(
    flip(includes)(collectConditionCodesFromGroups(groups)),
    prop<keyof Condition>('Code'),
  );

export const isConditionItemInGroups = (
  groups: Record<string, ConditionCode[]>,
) =>
  compose(
    flip(includes)(collectConditionCodesFromGroups(groups)),
    prop<keyof ConditionInterface>('code'),
  );

import { FC } from 'react';
import cn from 'classnames';

import { BasicSkeleton } from '../BasicSkeleton/BasicSkeleton';

import styles from './SkeletonGrid.module.scss';

type SkeletonGridProps = {
  className?: string;
  testID?: string;
  cardsQuantity?: number;
};

export const SkeletonGrid: FC<SkeletonGridProps> = (props) => {
  const { className, testID, cardsQuantity = 10 } = props;

  const cardsQuantityArray: number[] = Array(cardsQuantity).fill(0);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {cardsQuantityArray.map((element, index) => (
        <BasicSkeleton
          width="100%"
          height="100%"
          borderRadius="16px"
          key={(element + index).toString()}
        />
      ))}
    </div>
  );
};

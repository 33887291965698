/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

/** A lot of Attributes were added erroneously and will be phased out. */
export enum ConditionCode {
  /** ConditionCodeInvalidValue - unacceptable value */
  ConditionCodeInvalidValue = 0,
  /** CodeTooth - @exclude detected/model-relevant conditions */
  CodeTooth = 1000,
  /** Missing - empty tooth socket */
  Missing = 1001,
  Implant = 1003,
  Pontic = 1004,
  RootFragment = 1057,
  /** PartiallyVisible - tooth of tooth-like object is partially visible */
  PartiallyVisible = 1002,
  Filling = 1007,
  Hypercementosis = 1008,
  Impaction = 1009,
  Roots1 = 1010,
  Roots2 = 1011,
  Roots3 = 1012,
  Roots4 = 1013,
  Canals1 = 1014,
  Canals2 = 1015,
  Canals3 = 1016,
  Canals4 = 1017,
  Canals5 = 1018,
  ArtificialCrown = 1019,
  CariesSigns = 1021,
  EctopicEruption = 1023,
  EndoTreated = 1024,
  VerticalRootFracture = 1026,
  CanalObliteration = 1033,
  InternalResorption = 1036,
  OrthodonticAppliance = 1037,
  PeriapicalRadiopacity = 1038,
  PeriapicalRadiolucency = 1202,
  PDLSpaceWidening_InPeriapicalRegion = 1039,
  PDLSpaceWidening_AlongTheRoot = 1042,
  FurcationLesion = 1043,
  PulpStone = 1044,
  ExternalResorption = 1046,
  HorizontalRootFracture = 1051,
  CrownFracture = 1052,
  /** Abfraction - also known as `wedge_shaped_defect` */
  Abfraction = 1053,
  Attrition = 1060,
  PeriImplantitis = 1065,
  DentalCalculus = 1068,
  HorizontalDisplacement = 1075,
  Overeruption = 1077,
  OpenMargin = 1083,
  Overhang = 1084,
  LackOfInterproximalContact = 1086,
  SecondaryCaries = 1087,
  /** CrownDefectOver50pct - group condition; includes `caries_signs`, `restoration_fracture`, etc. */
  CrownDefectOver50pct = 1022,
  PeriodontalBoneLoss = 1092,
  EndodonticAccess = 1148,
  Pulpotomy = 1149,
  Clamp = 1150,
  ForeignBody = 1151,
  EndodonticInstrument = 1152,
  IndirectRestoration = 1181,
  RestorationFracture = 1182,
  LateralRadiolucency = 1185,
  SingsOfCommunicationWithMaxillarySinus = 1186,
  SignsOfInferiorAlveolarCanalInvolvement = 1187,
  Apicoectomy = 1189,
  ToothGerm = 1197,
  /** MucosalThickening - Maxillofacial pathologies (but writing `maxfax is redundant`) */
  MucosalThickening = 1200,
  BoneStructureDisorder = 1201,
  ToothStump = 1204,
  VoidsInTheFilling = 1205,
  Extrusion = 1206,
  Intrusion = 1207,
  LateralLuxation = 1208,
  HealingAbutment = 1218,
  CervicalResorption = 1223,
  PreEruptiveResorption = 1224,
  MaxFaxArtifact = 1240,
  MaxFaxBoneDisruption = 1241,
  MaxFaxMetalConstruction = 1242,
  MaxFaxBoneAugmentation = 1243,
  MaxFaxSoftTissueThickening = 1244,
  EndoAntralSyndrome = 1245,
  OroantralCommunication = 1246,
  OstialObstruction = 1247,
  /** MarginalDiscrepancy - group condition; */
  MarginalDiscrepancy = 1248,
  /** Child_DefectDepth_NotDefined - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectDepth_NotDefined = 5000,
  /** Child_DefectDepth_Initial - Parent conditions: caries_signs (1021) || secondary_caries (1087) */
  Child_DefectDepth_Initial = 5001,
  /** Child_DefectDepth_Enamel - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || crown_fracture_depth (1052) */
  Child_DefectDepth_Enamel = 5002,
  /** Child_DefectDepth_Dentin - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || crown_fracture_depth (1052) */
  Child_DefectDepth_Dentin = 5003,
  /** Child_DefectDepth_WithPulpExposure - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || crown_fracture_depth (1052) */
  Child_DefectDepth_WithPulpExposure = 5004,
  /** Child_DefectDepth_Root - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectDepth_Root = 5005,
  /** Child_DefectDepth_CrownRoot - Parent conditions: crown_fracture_depth (1052) */
  Child_DefectDepth_CrownRoot = 5006,
  /** Child_DefectSurface_NotDefined - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_NotDefined = 5050,
  /** Child_DefectSurface_Mesial - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_Mesial = 5051,
  /** Child_DefectSurface_Distal - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_Distal = 5052,
  /** Child_DefectSurface_Lingual - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_Lingual = 5053,
  /** Child_DefectSurface_Buccal - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_Buccal = 5054,
  /** Child_DefectSurface_Occlusial - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_Occlusial = 5055,
  /** Child_DefectSurface_Vestibular - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_Vestibular = 5056,
  /** Child_DefectSurface_IncisalEdge - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) */
  Child_DefectSurface_IncisalEdge = 5057,
  /** Child_PeriodontalBoneLoss_Severity_Mild - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Severity_Mild = 5200,
  /** Child_PeriodontalBoneLoss_Severity_Moderate - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Severity_Moderate = 5201,
  /** Child_PeriodontalBoneLoss_Severity_Severe - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Severity_Severe = 5202,
  /** Child_PeriodontalBoneLoss_Type_Vertical - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Type_Vertical = 5210,
  /** Child_PeriodontalBoneLoss_Type_Horizontal - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Type_Horizontal = 5211,
  /** Child_PeriodontalBoneLoss_Type_Mixed - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Type_Mixed = 5212,
  /** Child_Filling_BuildUp - Parent conditions: filling (1007) */
  Child_Filling_BuildUp = 5250,
  /** Child_MaxFaxBoneStructureDisorder_Type_Hyperdense - Parent conditions: maxfax_lesion (1201) */
  Child_MaxFaxBoneStructureDisorder_Type_Hyperdense = 5300,
  /** Child_MaxFaxBoneStructureDisorder_Type_Hypodense - Parent conditions: maxfax_lesion (1201) */
  Child_MaxFaxBoneStructureDisorder_Type_Hypodense = 5301,
  /** Child_MaxFaxBoneStructureDisorder_Type_Mix - Parent conditions: maxfax_lesion (1201) */
  Child_MaxFaxBoneStructureDisorder_Type_Mix = 5302,
  /** Child_Impaction_Subtype_CompleteBony - Parent conditions: impaction (1009) */
  Child_Impaction_Subtype_CompleteBony = 5350,
  /** Child_Impaction_Subtype_PartiallyBony - Parent conditions: impaction (1009) */
  Child_Impaction_Subtype_PartiallyBony = 5351,
  /** Child_EndoTreated_Obturation_AdequateObturation - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Obturation_AdequateObturation = 5400,
  /** Child_EndoTreated_Obturation_ShortFilling - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Obturation_ShortFilling = 5401,
  /** Child_EndoTreated_Obturation_Overfilling - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Obturation_Overfilling = 5402,
  /** Child_EndoTreated_Obturation_MissedCanal - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Obturation_MissedCanal = 5403,
  /** Child_EndoTreated_Quality_AdequateDensity - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Quality_AdequateDensity = 5410,
  /** Child_EndoTreated_Quality_VoidsPresentInTheRootFilling - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Quality_VoidsPresentInTheRootFilling = 5411,
  /** Child_EndoTreated_Quality_VoidsInThePostArea - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Quality_VoidsInThePostArea = 5412,
  /** Child_EndoTreated_PostAndCore_CastPostAndCore - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_PostAndCore_CastPostAndCore = 5420,
  /** Child_EndoTreated_PostAndCore_FiberglassPost - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_PostAndCore_FiberglassPost = 5421,
  /** Child_EndoTreated_PostAndCore_MetalPost - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_PostAndCore_MetalPost = 5422,
  /** Child_EndoTreated_PostAndCore_Post - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_PostAndCore_Post = 5423,
  /** Child_EndoTreated_Complications_RootPerforation - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Complications_RootPerforation = 5430,
  /** Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal - Parent conditions: endo_treated (1024) */
  Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal = 5431,
  Child_MucosalThickening_Type_Focal = 5450,
  Child_MucosalThickening_Type_Diffused = 5451,
  Child_MucosalThickening_Type_CompleteOrPartialObstruction = 5452,
  /** Child_RootType_SingleRoot - "a1" */
  Child_RootType_SingleRoot = 5470,
  /** Child_RootType_Buccal - "b" */
  Child_RootType_Buccal = 5471,
  /** Child_RootType_Lingual - "l" */
  Child_RootType_Lingual = 5472,
  /** Child_RootType_Palatal - "p" */
  Child_RootType_Palatal = 5473,
  /** Child_RootType_Mesial - "m" */
  Child_RootType_Mesial = 5474,
  /** Child_RootType_Mesial1 - "m1" */
  Child_RootType_Mesial1 = 5475,
  /** Child_RootType_Mesial2 - "m2" */
  Child_RootType_Mesial2 = 5476,
  /** Child_RootType_MesialBuccal - "mb" */
  Child_RootType_MesialBuccal = 5477,
  /** Child_RootType_MesialLingual - "ml" */
  Child_RootType_MesialLingual = 5478,
  /** Child_RootType_Distal - "d" */
  Child_RootType_Distal = 5479,
  /** Child_RootType_DistalBuccal - "db" */
  Child_RootType_DistalBuccal = 5480,
  /** Child_RootType_DistalLingual - "dl" */
  Child_RootType_DistalLingual = 5481,
  UNRECOGNIZED = -1,
}

export function conditionCodeFromJSON(object: any): ConditionCode {
  switch (object) {
    case 0:
    case 'ConditionCodeInvalidValue':
      return ConditionCode.ConditionCodeInvalidValue;
    case 1000:
    case 'CodeTooth':
      return ConditionCode.CodeTooth;
    case 1001:
    case 'Missing':
      return ConditionCode.Missing;
    case 1003:
    case 'Implant':
      return ConditionCode.Implant;
    case 1004:
    case 'Pontic':
      return ConditionCode.Pontic;
    case 1057:
    case 'RootFragment':
      return ConditionCode.RootFragment;
    case 1002:
    case 'PartiallyVisible':
      return ConditionCode.PartiallyVisible;
    case 1007:
    case 'Filling':
      return ConditionCode.Filling;
    case 1008:
    case 'Hypercementosis':
      return ConditionCode.Hypercementosis;
    case 1009:
    case 'Impaction':
      return ConditionCode.Impaction;
    case 1010:
    case 'Roots1':
      return ConditionCode.Roots1;
    case 1011:
    case 'Roots2':
      return ConditionCode.Roots2;
    case 1012:
    case 'Roots3':
      return ConditionCode.Roots3;
    case 1013:
    case 'Roots4':
      return ConditionCode.Roots4;
    case 1014:
    case 'Canals1':
      return ConditionCode.Canals1;
    case 1015:
    case 'Canals2':
      return ConditionCode.Canals2;
    case 1016:
    case 'Canals3':
      return ConditionCode.Canals3;
    case 1017:
    case 'Canals4':
      return ConditionCode.Canals4;
    case 1018:
    case 'Canals5':
      return ConditionCode.Canals5;
    case 1019:
    case 'ArtificialCrown':
      return ConditionCode.ArtificialCrown;
    case 1021:
    case 'CariesSigns':
      return ConditionCode.CariesSigns;
    case 1023:
    case 'EctopicEruption':
      return ConditionCode.EctopicEruption;
    case 1024:
    case 'EndoTreated':
      return ConditionCode.EndoTreated;
    case 1026:
    case 'VerticalRootFracture':
      return ConditionCode.VerticalRootFracture;
    case 1033:
    case 'CanalObliteration':
      return ConditionCode.CanalObliteration;
    case 1036:
    case 'InternalResorption':
      return ConditionCode.InternalResorption;
    case 1037:
    case 'OrthodonticAppliance':
      return ConditionCode.OrthodonticAppliance;
    case 1038:
    case 'PeriapicalRadiopacity':
      return ConditionCode.PeriapicalRadiopacity;
    case 1202:
    case 'PeriapicalRadiolucency':
      return ConditionCode.PeriapicalRadiolucency;
    case 1039:
    case 'PDLSpaceWidening_InPeriapicalRegion':
      return ConditionCode.PDLSpaceWidening_InPeriapicalRegion;
    case 1042:
    case 'PDLSpaceWidening_AlongTheRoot':
      return ConditionCode.PDLSpaceWidening_AlongTheRoot;
    case 1043:
    case 'FurcationLesion':
      return ConditionCode.FurcationLesion;
    case 1044:
    case 'PulpStone':
      return ConditionCode.PulpStone;
    case 1046:
    case 'ExternalResorption':
      return ConditionCode.ExternalResorption;
    case 1051:
    case 'HorizontalRootFracture':
      return ConditionCode.HorizontalRootFracture;
    case 1052:
    case 'CrownFracture':
      return ConditionCode.CrownFracture;
    case 1053:
    case 'Abfraction':
      return ConditionCode.Abfraction;
    case 1060:
    case 'Attrition':
      return ConditionCode.Attrition;
    case 1065:
    case 'PeriImplantitis':
      return ConditionCode.PeriImplantitis;
    case 1068:
    case 'DentalCalculus':
      return ConditionCode.DentalCalculus;
    case 1075:
    case 'HorizontalDisplacement':
      return ConditionCode.HorizontalDisplacement;
    case 1077:
    case 'Overeruption':
      return ConditionCode.Overeruption;
    case 1083:
    case 'OpenMargin':
      return ConditionCode.OpenMargin;
    case 1084:
    case 'Overhang':
      return ConditionCode.Overhang;
    case 1086:
    case 'LackOfInterproximalContact':
      return ConditionCode.LackOfInterproximalContact;
    case 1087:
    case 'SecondaryCaries':
      return ConditionCode.SecondaryCaries;
    case 1022:
    case 'CrownDefectOver50pct':
      return ConditionCode.CrownDefectOver50pct;
    case 1092:
    case 'PeriodontalBoneLoss':
      return ConditionCode.PeriodontalBoneLoss;
    case 1148:
    case 'EndodonticAccess':
      return ConditionCode.EndodonticAccess;
    case 1149:
    case 'Pulpotomy':
      return ConditionCode.Pulpotomy;
    case 1150:
    case 'Clamp':
      return ConditionCode.Clamp;
    case 1151:
    case 'ForeignBody':
      return ConditionCode.ForeignBody;
    case 1152:
    case 'EndodonticInstrument':
      return ConditionCode.EndodonticInstrument;
    case 1181:
    case 'IndirectRestoration':
      return ConditionCode.IndirectRestoration;
    case 1182:
    case 'RestorationFracture':
      return ConditionCode.RestorationFracture;
    case 1185:
    case 'LateralRadiolucency':
      return ConditionCode.LateralRadiolucency;
    case 1186:
    case 'SingsOfCommunicationWithMaxillarySinus':
      return ConditionCode.SingsOfCommunicationWithMaxillarySinus;
    case 1187:
    case 'SignsOfInferiorAlveolarCanalInvolvement':
      return ConditionCode.SignsOfInferiorAlveolarCanalInvolvement;
    case 1189:
    case 'Apicoectomy':
      return ConditionCode.Apicoectomy;
    case 1197:
    case 'ToothGerm':
      return ConditionCode.ToothGerm;
    case 1200:
    case 'MucosalThickening':
      return ConditionCode.MucosalThickening;
    case 1201:
    case 'BoneStructureDisorder':
      return ConditionCode.BoneStructureDisorder;
    case 1204:
    case 'ToothStump':
      return ConditionCode.ToothStump;
    case 1205:
    case 'VoidsInTheFilling':
      return ConditionCode.VoidsInTheFilling;
    case 1206:
    case 'Extrusion':
      return ConditionCode.Extrusion;
    case 1207:
    case 'Intrusion':
      return ConditionCode.Intrusion;
    case 1208:
    case 'LateralLuxation':
      return ConditionCode.LateralLuxation;
    case 1218:
    case 'HealingAbutment':
      return ConditionCode.HealingAbutment;
    case 1223:
    case 'CervicalResorption':
      return ConditionCode.CervicalResorption;
    case 1224:
    case 'PreEruptiveResorption':
      return ConditionCode.PreEruptiveResorption;
    case 1240:
    case 'MaxFaxArtifact':
      return ConditionCode.MaxFaxArtifact;
    case 1241:
    case 'MaxFaxBoneDisruption':
      return ConditionCode.MaxFaxBoneDisruption;
    case 1242:
    case 'MaxFaxMetalConstruction':
      return ConditionCode.MaxFaxMetalConstruction;
    case 1243:
    case 'MaxFaxBoneAugmentation':
      return ConditionCode.MaxFaxBoneAugmentation;
    case 1244:
    case 'MaxFaxSoftTissueThickening':
      return ConditionCode.MaxFaxSoftTissueThickening;
    case 1245:
    case 'EndoAntralSyndrome':
      return ConditionCode.EndoAntralSyndrome;
    case 1246:
    case 'OroantralCommunication':
      return ConditionCode.OroantralCommunication;
    case 1247:
    case 'OstialObstruction':
      return ConditionCode.OstialObstruction;
    case 1248:
    case 'MarginalDiscrepancy':
      return ConditionCode.MarginalDiscrepancy;
    case 5000:
    case 'Child_DefectDepth_NotDefined':
      return ConditionCode.Child_DefectDepth_NotDefined;
    case 5001:
    case 'Child_DefectDepth_Initial':
      return ConditionCode.Child_DefectDepth_Initial;
    case 5002:
    case 'Child_DefectDepth_Enamel':
      return ConditionCode.Child_DefectDepth_Enamel;
    case 5003:
    case 'Child_DefectDepth_Dentin':
      return ConditionCode.Child_DefectDepth_Dentin;
    case 5004:
    case 'Child_DefectDepth_WithPulpExposure':
      return ConditionCode.Child_DefectDepth_WithPulpExposure;
    case 5005:
    case 'Child_DefectDepth_Root':
      return ConditionCode.Child_DefectDepth_Root;
    case 5006:
    case 'Child_DefectDepth_CrownRoot':
      return ConditionCode.Child_DefectDepth_CrownRoot;
    case 5050:
    case 'Child_DefectSurface_NotDefined':
      return ConditionCode.Child_DefectSurface_NotDefined;
    case 5051:
    case 'Child_DefectSurface_Mesial':
      return ConditionCode.Child_DefectSurface_Mesial;
    case 5052:
    case 'Child_DefectSurface_Distal':
      return ConditionCode.Child_DefectSurface_Distal;
    case 5053:
    case 'Child_DefectSurface_Lingual':
      return ConditionCode.Child_DefectSurface_Lingual;
    case 5054:
    case 'Child_DefectSurface_Buccal':
      return ConditionCode.Child_DefectSurface_Buccal;
    case 5055:
    case 'Child_DefectSurface_Occlusial':
      return ConditionCode.Child_DefectSurface_Occlusial;
    case 5056:
    case 'Child_DefectSurface_Vestibular':
      return ConditionCode.Child_DefectSurface_Vestibular;
    case 5057:
    case 'Child_DefectSurface_IncisalEdge':
      return ConditionCode.Child_DefectSurface_IncisalEdge;
    case 5200:
    case 'Child_PeriodontalBoneLoss_Severity_Mild':
      return ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild;
    case 5201:
    case 'Child_PeriodontalBoneLoss_Severity_Moderate':
      return ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate;
    case 5202:
    case 'Child_PeriodontalBoneLoss_Severity_Severe':
      return ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe;
    case 5210:
    case 'Child_PeriodontalBoneLoss_Type_Vertical':
      return ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical;
    case 5211:
    case 'Child_PeriodontalBoneLoss_Type_Horizontal':
      return ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal;
    case 5212:
    case 'Child_PeriodontalBoneLoss_Type_Mixed':
      return ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed;
    case 5250:
    case 'Child_Filling_BuildUp':
      return ConditionCode.Child_Filling_BuildUp;
    case 5300:
    case 'Child_MaxFaxBoneStructureDisorder_Type_Hyperdense':
      return ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hyperdense;
    case 5301:
    case 'Child_MaxFaxBoneStructureDisorder_Type_Hypodense':
      return ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hypodense;
    case 5302:
    case 'Child_MaxFaxBoneStructureDisorder_Type_Mix':
      return ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Mix;
    case 5350:
    case 'Child_Impaction_Subtype_CompleteBony':
      return ConditionCode.Child_Impaction_Subtype_CompleteBony;
    case 5351:
    case 'Child_Impaction_Subtype_PartiallyBony':
      return ConditionCode.Child_Impaction_Subtype_PartiallyBony;
    case 5400:
    case 'Child_EndoTreated_Obturation_AdequateObturation':
      return ConditionCode.Child_EndoTreated_Obturation_AdequateObturation;
    case 5401:
    case 'Child_EndoTreated_Obturation_ShortFilling':
      return ConditionCode.Child_EndoTreated_Obturation_ShortFilling;
    case 5402:
    case 'Child_EndoTreated_Obturation_Overfilling':
      return ConditionCode.Child_EndoTreated_Obturation_Overfilling;
    case 5403:
    case 'Child_EndoTreated_Obturation_MissedCanal':
      return ConditionCode.Child_EndoTreated_Obturation_MissedCanal;
    case 5410:
    case 'Child_EndoTreated_Quality_AdequateDensity':
      return ConditionCode.Child_EndoTreated_Quality_AdequateDensity;
    case 5411:
    case 'Child_EndoTreated_Quality_VoidsPresentInTheRootFilling':
      return ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling;
    case 5412:
    case 'Child_EndoTreated_Quality_VoidsInThePostArea':
      return ConditionCode.Child_EndoTreated_Quality_VoidsInThePostArea;
    case 5420:
    case 'Child_EndoTreated_PostAndCore_CastPostAndCore':
      return ConditionCode.Child_EndoTreated_PostAndCore_CastPostAndCore;
    case 5421:
    case 'Child_EndoTreated_PostAndCore_FiberglassPost':
      return ConditionCode.Child_EndoTreated_PostAndCore_FiberglassPost;
    case 5422:
    case 'Child_EndoTreated_PostAndCore_MetalPost':
      return ConditionCode.Child_EndoTreated_PostAndCore_MetalPost;
    case 5423:
    case 'Child_EndoTreated_PostAndCore_Post':
      return ConditionCode.Child_EndoTreated_PostAndCore_Post;
    case 5430:
    case 'Child_EndoTreated_Complications_RootPerforation':
      return ConditionCode.Child_EndoTreated_Complications_RootPerforation;
    case 5431:
    case 'Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal':
      return ConditionCode.Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal;
    case 5450:
    case 'Child_MucosalThickening_Type_Focal':
      return ConditionCode.Child_MucosalThickening_Type_Focal;
    case 5451:
    case 'Child_MucosalThickening_Type_Diffused':
      return ConditionCode.Child_MucosalThickening_Type_Diffused;
    case 5452:
    case 'Child_MucosalThickening_Type_CompleteOrPartialObstruction':
      return ConditionCode.Child_MucosalThickening_Type_CompleteOrPartialObstruction;
    case 5470:
    case 'Child_RootType_SingleRoot':
      return ConditionCode.Child_RootType_SingleRoot;
    case 5471:
    case 'Child_RootType_Buccal':
      return ConditionCode.Child_RootType_Buccal;
    case 5472:
    case 'Child_RootType_Lingual':
      return ConditionCode.Child_RootType_Lingual;
    case 5473:
    case 'Child_RootType_Palatal':
      return ConditionCode.Child_RootType_Palatal;
    case 5474:
    case 'Child_RootType_Mesial':
      return ConditionCode.Child_RootType_Mesial;
    case 5475:
    case 'Child_RootType_Mesial1':
      return ConditionCode.Child_RootType_Mesial1;
    case 5476:
    case 'Child_RootType_Mesial2':
      return ConditionCode.Child_RootType_Mesial2;
    case 5477:
    case 'Child_RootType_MesialBuccal':
      return ConditionCode.Child_RootType_MesialBuccal;
    case 5478:
    case 'Child_RootType_MesialLingual':
      return ConditionCode.Child_RootType_MesialLingual;
    case 5479:
    case 'Child_RootType_Distal':
      return ConditionCode.Child_RootType_Distal;
    case 5480:
    case 'Child_RootType_DistalBuccal':
      return ConditionCode.Child_RootType_DistalBuccal;
    case 5481:
    case 'Child_RootType_DistalLingual':
      return ConditionCode.Child_RootType_DistalLingual;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ConditionCode.UNRECOGNIZED;
  }
}

export function conditionCodeToJSON(object: ConditionCode): string {
  switch (object) {
    case ConditionCode.ConditionCodeInvalidValue:
      return 'ConditionCodeInvalidValue';
    case ConditionCode.CodeTooth:
      return 'CodeTooth';
    case ConditionCode.Missing:
      return 'Missing';
    case ConditionCode.Implant:
      return 'Implant';
    case ConditionCode.Pontic:
      return 'Pontic';
    case ConditionCode.RootFragment:
      return 'RootFragment';
    case ConditionCode.PartiallyVisible:
      return 'PartiallyVisible';
    case ConditionCode.Filling:
      return 'Filling';
    case ConditionCode.Hypercementosis:
      return 'Hypercementosis';
    case ConditionCode.Impaction:
      return 'Impaction';
    case ConditionCode.Roots1:
      return 'Roots1';
    case ConditionCode.Roots2:
      return 'Roots2';
    case ConditionCode.Roots3:
      return 'Roots3';
    case ConditionCode.Roots4:
      return 'Roots4';
    case ConditionCode.Canals1:
      return 'Canals1';
    case ConditionCode.Canals2:
      return 'Canals2';
    case ConditionCode.Canals3:
      return 'Canals3';
    case ConditionCode.Canals4:
      return 'Canals4';
    case ConditionCode.Canals5:
      return 'Canals5';
    case ConditionCode.ArtificialCrown:
      return 'ArtificialCrown';
    case ConditionCode.CariesSigns:
      return 'CariesSigns';
    case ConditionCode.EctopicEruption:
      return 'EctopicEruption';
    case ConditionCode.EndoTreated:
      return 'EndoTreated';
    case ConditionCode.VerticalRootFracture:
      return 'VerticalRootFracture';
    case ConditionCode.CanalObliteration:
      return 'CanalObliteration';
    case ConditionCode.InternalResorption:
      return 'InternalResorption';
    case ConditionCode.OrthodonticAppliance:
      return 'OrthodonticAppliance';
    case ConditionCode.PeriapicalRadiopacity:
      return 'PeriapicalRadiopacity';
    case ConditionCode.PeriapicalRadiolucency:
      return 'PeriapicalRadiolucency';
    case ConditionCode.PDLSpaceWidening_InPeriapicalRegion:
      return 'PDLSpaceWidening_InPeriapicalRegion';
    case ConditionCode.PDLSpaceWidening_AlongTheRoot:
      return 'PDLSpaceWidening_AlongTheRoot';
    case ConditionCode.FurcationLesion:
      return 'FurcationLesion';
    case ConditionCode.PulpStone:
      return 'PulpStone';
    case ConditionCode.ExternalResorption:
      return 'ExternalResorption';
    case ConditionCode.HorizontalRootFracture:
      return 'HorizontalRootFracture';
    case ConditionCode.CrownFracture:
      return 'CrownFracture';
    case ConditionCode.Abfraction:
      return 'Abfraction';
    case ConditionCode.Attrition:
      return 'Attrition';
    case ConditionCode.PeriImplantitis:
      return 'PeriImplantitis';
    case ConditionCode.DentalCalculus:
      return 'DentalCalculus';
    case ConditionCode.HorizontalDisplacement:
      return 'HorizontalDisplacement';
    case ConditionCode.Overeruption:
      return 'Overeruption';
    case ConditionCode.OpenMargin:
      return 'OpenMargin';
    case ConditionCode.Overhang:
      return 'Overhang';
    case ConditionCode.LackOfInterproximalContact:
      return 'LackOfInterproximalContact';
    case ConditionCode.SecondaryCaries:
      return 'SecondaryCaries';
    case ConditionCode.CrownDefectOver50pct:
      return 'CrownDefectOver50pct';
    case ConditionCode.PeriodontalBoneLoss:
      return 'PeriodontalBoneLoss';
    case ConditionCode.EndodonticAccess:
      return 'EndodonticAccess';
    case ConditionCode.Pulpotomy:
      return 'Pulpotomy';
    case ConditionCode.Clamp:
      return 'Clamp';
    case ConditionCode.ForeignBody:
      return 'ForeignBody';
    case ConditionCode.EndodonticInstrument:
      return 'EndodonticInstrument';
    case ConditionCode.IndirectRestoration:
      return 'IndirectRestoration';
    case ConditionCode.RestorationFracture:
      return 'RestorationFracture';
    case ConditionCode.LateralRadiolucency:
      return 'LateralRadiolucency';
    case ConditionCode.SingsOfCommunicationWithMaxillarySinus:
      return 'SingsOfCommunicationWithMaxillarySinus';
    case ConditionCode.SignsOfInferiorAlveolarCanalInvolvement:
      return 'SignsOfInferiorAlveolarCanalInvolvement';
    case ConditionCode.Apicoectomy:
      return 'Apicoectomy';
    case ConditionCode.ToothGerm:
      return 'ToothGerm';
    case ConditionCode.MucosalThickening:
      return 'MucosalThickening';
    case ConditionCode.BoneStructureDisorder:
      return 'BoneStructureDisorder';
    case ConditionCode.ToothStump:
      return 'ToothStump';
    case ConditionCode.VoidsInTheFilling:
      return 'VoidsInTheFilling';
    case ConditionCode.Extrusion:
      return 'Extrusion';
    case ConditionCode.Intrusion:
      return 'Intrusion';
    case ConditionCode.LateralLuxation:
      return 'LateralLuxation';
    case ConditionCode.HealingAbutment:
      return 'HealingAbutment';
    case ConditionCode.CervicalResorption:
      return 'CervicalResorption';
    case ConditionCode.PreEruptiveResorption:
      return 'PreEruptiveResorption';
    case ConditionCode.MaxFaxArtifact:
      return 'MaxFaxArtifact';
    case ConditionCode.MaxFaxBoneDisruption:
      return 'MaxFaxBoneDisruption';
    case ConditionCode.MaxFaxMetalConstruction:
      return 'MaxFaxMetalConstruction';
    case ConditionCode.MaxFaxBoneAugmentation:
      return 'MaxFaxBoneAugmentation';
    case ConditionCode.MaxFaxSoftTissueThickening:
      return 'MaxFaxSoftTissueThickening';
    case ConditionCode.EndoAntralSyndrome:
      return 'EndoAntralSyndrome';
    case ConditionCode.OroantralCommunication:
      return 'OroantralCommunication';
    case ConditionCode.OstialObstruction:
      return 'OstialObstruction';
    case ConditionCode.MarginalDiscrepancy:
      return 'MarginalDiscrepancy';
    case ConditionCode.Child_DefectDepth_NotDefined:
      return 'Child_DefectDepth_NotDefined';
    case ConditionCode.Child_DefectDepth_Initial:
      return 'Child_DefectDepth_Initial';
    case ConditionCode.Child_DefectDepth_Enamel:
      return 'Child_DefectDepth_Enamel';
    case ConditionCode.Child_DefectDepth_Dentin:
      return 'Child_DefectDepth_Dentin';
    case ConditionCode.Child_DefectDepth_WithPulpExposure:
      return 'Child_DefectDepth_WithPulpExposure';
    case ConditionCode.Child_DefectDepth_Root:
      return 'Child_DefectDepth_Root';
    case ConditionCode.Child_DefectDepth_CrownRoot:
      return 'Child_DefectDepth_CrownRoot';
    case ConditionCode.Child_DefectSurface_NotDefined:
      return 'Child_DefectSurface_NotDefined';
    case ConditionCode.Child_DefectSurface_Mesial:
      return 'Child_DefectSurface_Mesial';
    case ConditionCode.Child_DefectSurface_Distal:
      return 'Child_DefectSurface_Distal';
    case ConditionCode.Child_DefectSurface_Lingual:
      return 'Child_DefectSurface_Lingual';
    case ConditionCode.Child_DefectSurface_Buccal:
      return 'Child_DefectSurface_Buccal';
    case ConditionCode.Child_DefectSurface_Occlusial:
      return 'Child_DefectSurface_Occlusial';
    case ConditionCode.Child_DefectSurface_Vestibular:
      return 'Child_DefectSurface_Vestibular';
    case ConditionCode.Child_DefectSurface_IncisalEdge:
      return 'Child_DefectSurface_IncisalEdge';
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild:
      return 'Child_PeriodontalBoneLoss_Severity_Mild';
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate:
      return 'Child_PeriodontalBoneLoss_Severity_Moderate';
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe:
      return 'Child_PeriodontalBoneLoss_Severity_Severe';
    case ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical:
      return 'Child_PeriodontalBoneLoss_Type_Vertical';
    case ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal:
      return 'Child_PeriodontalBoneLoss_Type_Horizontal';
    case ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed:
      return 'Child_PeriodontalBoneLoss_Type_Mixed';
    case ConditionCode.Child_Filling_BuildUp:
      return 'Child_Filling_BuildUp';
    case ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hyperdense:
      return 'Child_MaxFaxBoneStructureDisorder_Type_Hyperdense';
    case ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hypodense:
      return 'Child_MaxFaxBoneStructureDisorder_Type_Hypodense';
    case ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Mix:
      return 'Child_MaxFaxBoneStructureDisorder_Type_Mix';
    case ConditionCode.Child_Impaction_Subtype_CompleteBony:
      return 'Child_Impaction_Subtype_CompleteBony';
    case ConditionCode.Child_Impaction_Subtype_PartiallyBony:
      return 'Child_Impaction_Subtype_PartiallyBony';
    case ConditionCode.Child_EndoTreated_Obturation_AdequateObturation:
      return 'Child_EndoTreated_Obturation_AdequateObturation';
    case ConditionCode.Child_EndoTreated_Obturation_ShortFilling:
      return 'Child_EndoTreated_Obturation_ShortFilling';
    case ConditionCode.Child_EndoTreated_Obturation_Overfilling:
      return 'Child_EndoTreated_Obturation_Overfilling';
    case ConditionCode.Child_EndoTreated_Obturation_MissedCanal:
      return 'Child_EndoTreated_Obturation_MissedCanal';
    case ConditionCode.Child_EndoTreated_Quality_AdequateDensity:
      return 'Child_EndoTreated_Quality_AdequateDensity';
    case ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling:
      return 'Child_EndoTreated_Quality_VoidsPresentInTheRootFilling';
    case ConditionCode.Child_EndoTreated_Quality_VoidsInThePostArea:
      return 'Child_EndoTreated_Quality_VoidsInThePostArea';
    case ConditionCode.Child_EndoTreated_PostAndCore_CastPostAndCore:
      return 'Child_EndoTreated_PostAndCore_CastPostAndCore';
    case ConditionCode.Child_EndoTreated_PostAndCore_FiberglassPost:
      return 'Child_EndoTreated_PostAndCore_FiberglassPost';
    case ConditionCode.Child_EndoTreated_PostAndCore_MetalPost:
      return 'Child_EndoTreated_PostAndCore_MetalPost';
    case ConditionCode.Child_EndoTreated_PostAndCore_Post:
      return 'Child_EndoTreated_PostAndCore_Post';
    case ConditionCode.Child_EndoTreated_Complications_RootPerforation:
      return 'Child_EndoTreated_Complications_RootPerforation';
    case ConditionCode.Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal:
      return 'Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal';
    case ConditionCode.Child_MucosalThickening_Type_Focal:
      return 'Child_MucosalThickening_Type_Focal';
    case ConditionCode.Child_MucosalThickening_Type_Diffused:
      return 'Child_MucosalThickening_Type_Diffused';
    case ConditionCode.Child_MucosalThickening_Type_CompleteOrPartialObstruction:
      return 'Child_MucosalThickening_Type_CompleteOrPartialObstruction';
    case ConditionCode.Child_RootType_SingleRoot:
      return 'Child_RootType_SingleRoot';
    case ConditionCode.Child_RootType_Buccal:
      return 'Child_RootType_Buccal';
    case ConditionCode.Child_RootType_Lingual:
      return 'Child_RootType_Lingual';
    case ConditionCode.Child_RootType_Palatal:
      return 'Child_RootType_Palatal';
    case ConditionCode.Child_RootType_Mesial:
      return 'Child_RootType_Mesial';
    case ConditionCode.Child_RootType_Mesial1:
      return 'Child_RootType_Mesial1';
    case ConditionCode.Child_RootType_Mesial2:
      return 'Child_RootType_Mesial2';
    case ConditionCode.Child_RootType_MesialBuccal:
      return 'Child_RootType_MesialBuccal';
    case ConditionCode.Child_RootType_MesialLingual:
      return 'Child_RootType_MesialLingual';
    case ConditionCode.Child_RootType_Distal:
      return 'Child_RootType_Distal';
    case ConditionCode.Child_RootType_DistalBuccal:
      return 'Child_RootType_DistalBuccal';
    case ConditionCode.Child_RootType_DistalLingual:
      return 'Child_RootType_DistalLingual';
    case ConditionCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

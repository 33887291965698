import { FormattedMessage } from 'react-intl';

import { PASSWORD_REGEX } from '@/shared/config';
import { boolean, InferType, object, string, ref } from '@/shared/config/yup';

export const signUpFormSchema = object({
  email: string().email().required(),
  confirmEmail: string()
    .oneOf([ref('email')], () => (
      <FormattedMessage
        id="signUp.emailsMustMatch"
        defaultMessage="Emails must match"
      />
    ))
    .required(),
  password: string().optional(),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .optional(),
  firstName: string().required(),
  lastName: string().required(),
  language: string().required(),
  country: string().required(),
  phoneNumber: string().optional(),
  firstAgreements: boolean().oneOf([true]).required(),
  secondAgreements: boolean().oneOf([true]).required(),
  marketingCommunicationConsent: boolean(),
});

export type SignUpFormPayload = InferType<typeof signUpFormSchema>;

export const signUpViaOrganizationInviteFormSchema = object({
  email: string().email().optional(),
  confirmEmail: string()
    .oneOf([ref('email')], () => (
      <FormattedMessage
        id="signUp.emailsMustMatch"
        defaultMessage="Emails must match"
      />
    ))
    .optional(),
  password: string()
    .required()
    .test('password', (value) => PASSWORD_REGEX.test(value || '')),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .required(),
  firstName: string().required(),
  lastName: string().required(),
  language: string().required(),
  country: string().optional(),
  phoneNumber: string().optional(),
  firstAgreements: boolean().oneOf([true]).required(),
  secondAgreements: boolean().oneOf([true]).required(),
  marketingCommunicationConsent: boolean(),
});

export type SignUpViaOrganizationInvitePayload = InferType<
  typeof signUpViaOrganizationInviteFormSchema
>;

export const signUpViaSharedPatientFormSchema = object({
  email: string().email().optional(),
  confirmEmail: string()
    .oneOf([ref('email')], () => (
      <FormattedMessage
        id="signUp.emailsMustMatch"
        defaultMessage="Emails must match"
      />
    ))
    .optional(),
  password: string()
    .required()
    .test('password', (value) => PASSWORD_REGEX.test(value || '')),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .required(),
  firstName: string().required(),
  lastName: string().required(),
  language: string().required(),
  country: string().required(),
  phoneNumber: string().optional(),
  firstAgreements: boolean().oneOf([true]).required(),
  secondAgreements: boolean().oneOf([true]).required(),
  marketingCommunicationConsent: boolean(),
});

export type SignUpViaSharedPatientPayload = InferType<
  typeof signUpViaOrganizationInviteFormSchema
>;

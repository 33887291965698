export const LABELING_EU_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Serbia',
  'Jordan',
  'Iran',
  'Colombia',
  'Costa Rica',
];

export const LABELING_CIS_COUNTRIES = [
  'Armenia',
  'Azerbaijan',
  'Belarus',
  'Kazakhstan',
  'Kyrgyzstan',
  'Moldova',
  'Russia',
  'Tajikistan',
  'Uzbekistan',
];

export const LABELING_UK_COUNTRY = ['UK'];
export const LABELING_SWITZERLAND_COUNTRY = ['Switzerland'];
export const LABELING_US_COUNTRY = ['US'];
export const LABELING_CANADA_COUNTRY = ['Canada'];
export const LABELING_MEXICO_COUNTRY = ['Mexico'];
export const LABELING_ISRAEL_COUNTRY = ['Israel'];
export const LABELING_AUSTRALIA_COUNTRY = ['Australia'];

export const LABELING_COUNTRIES = [
  ...LABELING_EU_COUNTRIES,
  ...LABELING_UK_COUNTRY,
  ...LABELING_SWITZERLAND_COUNTRY,
  ...LABELING_US_COUNTRY,
  ...LABELING_CANADA_COUNTRY,
  ...LABELING_MEXICO_COUNTRY,
  ...LABELING_ISRAEL_COUNTRY,
  ...LABELING_CIS_COUNTRIES,
  ...LABELING_AUSTRALIA_COUNTRY,
].sort((a, b) => a.localeCompare(b));

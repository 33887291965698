/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export enum CBCTAnatomy {
  CBCT_Anatomy_InvalidValue = 0,
  CBCT_Anatomy_Airways = 1,
  CBCT_Anatomy_Cranium = 2,
  CBCT_Anatomy_SoftTissue = 3,
  CBCT_Anatomy_IncisiveCanal = 4,
  CBCT_Anatomy_Mandible = 5,
  CBCT_Anatomy_Maxilla = 6,
  CBCT_Anatomy_MandibularCanal = 7,
  CBCT_Anatomy_Sinuses = 8,
  UNRECOGNIZED = -1,
}

export function cBCTAnatomyFromJSON(object: any): CBCTAnatomy {
  switch (object) {
    case 0:
    case 'CBCT_Anatomy_InvalidValue':
      return CBCTAnatomy.CBCT_Anatomy_InvalidValue;
    case 1:
    case 'CBCT_Anatomy_Airways':
      return CBCTAnatomy.CBCT_Anatomy_Airways;
    case 2:
    case 'CBCT_Anatomy_Cranium':
      return CBCTAnatomy.CBCT_Anatomy_Cranium;
    case 3:
    case 'CBCT_Anatomy_SoftTissue':
      return CBCTAnatomy.CBCT_Anatomy_SoftTissue;
    case 4:
    case 'CBCT_Anatomy_IncisiveCanal':
      return CBCTAnatomy.CBCT_Anatomy_IncisiveCanal;
    case 5:
    case 'CBCT_Anatomy_Mandible':
      return CBCTAnatomy.CBCT_Anatomy_Mandible;
    case 6:
    case 'CBCT_Anatomy_Maxilla':
      return CBCTAnatomy.CBCT_Anatomy_Maxilla;
    case 7:
    case 'CBCT_Anatomy_MandibularCanal':
      return CBCTAnatomy.CBCT_Anatomy_MandibularCanal;
    case 8:
    case 'CBCT_Anatomy_Sinuses':
      return CBCTAnatomy.CBCT_Anatomy_Sinuses;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTAnatomy.UNRECOGNIZED;
  }
}

export function cBCTAnatomyToJSON(object: CBCTAnatomy): string {
  switch (object) {
    case CBCTAnatomy.CBCT_Anatomy_InvalidValue:
      return 'CBCT_Anatomy_InvalidValue';
    case CBCTAnatomy.CBCT_Anatomy_Airways:
      return 'CBCT_Anatomy_Airways';
    case CBCTAnatomy.CBCT_Anatomy_Cranium:
      return 'CBCT_Anatomy_Cranium';
    case CBCTAnatomy.CBCT_Anatomy_SoftTissue:
      return 'CBCT_Anatomy_SoftTissue';
    case CBCTAnatomy.CBCT_Anatomy_IncisiveCanal:
      return 'CBCT_Anatomy_IncisiveCanal';
    case CBCTAnatomy.CBCT_Anatomy_Mandible:
      return 'CBCT_Anatomy_Mandible';
    case CBCTAnatomy.CBCT_Anatomy_Maxilla:
      return 'CBCT_Anatomy_Maxilla';
    case CBCTAnatomy.CBCT_Anatomy_MandibularCanal:
      return 'CBCT_Anatomy_MandibularCanal';
    case CBCTAnatomy.CBCT_Anatomy_Sinuses:
      return 'CBCT_Anatomy_Sinuses';
    case CBCTAnatomy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum CBCTToothAnatomy {
  CBCT_Tooth_Anatomy_InvalidValue = 0,
  CBCT_Tooth_Anatomy_Pulp = 1,
  CBCT_Tooth_Anatomy_Dentin = 2,
  CBCT_Tooth_Anatomy_Enamel = 3,
  CBCT_Tooth_Anatomy_Root = 4,
  UNRECOGNIZED = -1,
}

export function cBCTToothAnatomyFromJSON(object: any): CBCTToothAnatomy {
  switch (object) {
    case 0:
    case 'CBCT_Tooth_Anatomy_InvalidValue':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_InvalidValue;
    case 1:
    case 'CBCT_Tooth_Anatomy_Pulp':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_Pulp;
    case 2:
    case 'CBCT_Tooth_Anatomy_Dentin':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_Dentin;
    case 3:
    case 'CBCT_Tooth_Anatomy_Enamel':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_Enamel;
    case 4:
    case 'CBCT_Tooth_Anatomy_Root':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_Root;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTToothAnatomy.UNRECOGNIZED;
  }
}

export function cBCTToothAnatomyToJSON(object: CBCTToothAnatomy): string {
  switch (object) {
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_InvalidValue:
      return 'CBCT_Tooth_Anatomy_InvalidValue';
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_Pulp:
      return 'CBCT_Tooth_Anatomy_Pulp';
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_Dentin:
      return 'CBCT_Tooth_Anatomy_Dentin';
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_Enamel:
      return 'CBCT_Tooth_Anatomy_Enamel';
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_Root:
      return 'CBCT_Tooth_Anatomy_Root';
    case CBCTToothAnatomy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

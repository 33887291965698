import { FC, useCallback } from 'react';

import { MedicalImage } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';

type SliceListProps = {
  toothID: string;
  medicalImages?: MedicalImageInterface[];
};

export const GPMedicalImageList: FC<SliceListProps> = (props) => {
  const { toothID, medicalImages } = props;

  const dispatch = useAppDispatch();

  const handleOpenModal = useCallback(
    (medicalImage: MedicalImageInterface) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.ZoomedMedicalImage,
          data: { medicalImage, toothID },
        }),
      );
    },
    [toothID],
  );

  if (!medicalImages) {
    return null;
  }

  return (
    <>
      {medicalImages?.map((medicalImage) => {
        const { viewOptions } = medicalImage;

        return (
          <MedicalImage
            key={medicalImage.src}
            medicalImage={medicalImage}
            onClick={() => handleOpenModal(medicalImage)}
            annotations={medicalImage.annotations}
            wc={viewOptions?.wwwc?.wc}
            ww={viewOptions?.wwwc?.ww}
            sharpness={viewOptions?.sharpness}
            inverted={viewOptions?.invert}
          />
        );
      })}
    </>
  );
};

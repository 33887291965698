import { InferType, object, string } from '@/shared/config/yup';

export const billingInformationFormSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  phoneNumber: string().required(),
  email: string().email().required(),
  companyName: string().optional(),
  taxID: string().optional(),
  country: string().required(),
  region: string().required(),
  city: string().required(),
  zipCode: string().required(),
  address: string().required(),
}).required();

export type BillingInformationFormPayload = InferType<
  typeof billingInformationFormSchema
>;

import { useAppSelector } from '@/shared/hooks';

import { conditionModel } from '@/entities/condition';
import { toothLandmarksModel } from '@/entities/toothLandmarks';

import { PblConditionWithChildren, PBLLandmark } from '../config';
import { filterPblConditions } from '../lib/filterPblConditions';
import { getPblLandmarksFromConditions } from '../lib/getPblLandmarksFromConditions';

type GetPBLForImage = (imageTargetAssetID: string) => PBLLandmark[];

export const useTeethLandmarks = (
  teethIDs: string[],
): {
  pblLandmarks: PBLLandmark[];
  getPBLsForImage: GetPBLForImage;
} => {
  const teethConditions = useAppSelector(
    conditionModel.selectors.selectByTeethIDs(teethIDs),
  );
  const teethLandmarks = useAppSelector(
    toothLandmarksModel.selectors.selectByTeethIDs(teethIDs),
  );

  if (teethIDs.length === 0) {
    return {
      pblLandmarks: [] as PBLLandmark[],
      getPBLsForImage: (() => {}) as unknown as GetPBLForImage,
    };
  }

  const pblConditions = filterPblConditions(teethConditions);

  const pblConditionsWithChildren: PblConditionWithChildren[] =
    pblConditions.map((condition) => {
      const conditionChildren = teethConditions.filter(
        (childCondition) =>
          childCondition.ParentID && childCondition.ParentID === condition.ID,
      );
      return { ...condition, children: conditionChildren };
    });

  const pblLandmarks = getPblLandmarksFromConditions(
    pblConditionsWithChildren,
    teethLandmarks,
  );

  const getPBLsForImage = (imageTargetAssetID: string) =>
    pblLandmarks.filter(
      (landmark) => landmark.imageTargetAssetID === imageTargetAssetID,
    );

  return {
    pblLandmarks,
    getPBLsForImage,
  };
};

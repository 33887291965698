import { FC } from 'react';

import { Avatar } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { DoctorsAvatars } from '@/entities/doctors';

import { patientDoctorsModel } from '@/features/patientDoctors';

import styles from './PatientDoctrosCell.module.scss';

type PatientDoctrosCellProps = {
  patientID: string;
  className?: string;
};

export const PatientDoctrosCell: FC<PatientDoctrosCellProps> = (props) => {
  const { className, patientID } = props;

  const allPatientDoctors = useAppSelector(
    patientDoctorsModel.selectors.selectAllPatientDoctors(patientID),
  );

  const isOnlyOneDoctor = allPatientDoctors?.length === 1;

  return (
    <div className={className}>
      <Avatar.Group>
        {allPatientDoctors
          ?.slice(0, 3)
          .map((patientDoctor) => (
            <DoctorsAvatars
              doctor={patientDoctor}
              isOnlyOneDoctor={isOnlyOneDoctor}
              key={patientDoctor?.UserID}
            />
          ))}
        {allPatientDoctors && allPatientDoctors?.length > 4 && (
          <Avatar
            className={styles.avatarCount}
            count={allPatientDoctors && allPatientDoctors.length - 4}
          />
        )}
      </Avatar.Group>
    </div>
  );
};

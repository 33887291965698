import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Result.module.scss';

type ResultProps = {
  className?: string;
  text?: string | JSX.Element;
  icon?: ReactNode;
  extra?: ReactNode;
};

export const Result: FC<ResultProps> = (props) => {
  const { className, text, icon, extra } = props;

  return (
    <div className={cn(styles.container, className)}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {text && <p className={cn(styles.text, 'p2')}>{text}</p>}
      {extra && <div className={styles.extra}>{extra}</div>}
    </div>
  );
};

import cn from 'classnames';

import { Icon } from '@/shared/ui';

import styles from './ExitFromFullscreenButton.module.scss';

type ExitFromFullscreenButtonProps = {
  className?: string;
  testID?: string;
  onClick: () => void;
};

export const ExitFromFullscreenButton = (
  props: ExitFromFullscreenButtonProps,
) => {
  const { className, testID, onClick } = props;

  return (
    <Icon
      testID={testID}
      name="close"
      size={40}
      onClick={() => {
        document.exitFullscreen();
        onClick();
      }}
      className={cn(styles.container, className)}
    />
  );
};

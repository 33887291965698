import { FC, ReactNode } from 'react';
import cn from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { formats, modules } from '../../config';

import styles from './Editor.module.scss';

type EditorProps = {
  value: string;
  setValue: (newValue: string) => void;
  placeholder: ReactNode;
  className?: string;
};

export const Editor: FC<EditorProps> = (props) => {
  const { value, setValue, placeholder, className } = props;

  return (
    <div className={cn(styles.container, className)}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        placeholder={placeholder as string}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

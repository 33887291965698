import { FC } from 'react';
import { useIntl } from 'react-intl';

import { DropdownSelect } from '@/shared/ui';

import { LABELING_COUNTRIES } from '../../config';

import styles from './LabelingHeader.module.scss';

type LabelingHeaderProps = {
  country: string;
  onChange: (newValue: string | string[]) => void;
};

export const LabelingHeader: FC<LabelingHeaderProps> = (props) => {
  const { country, onChange } = props;

  const { formatMessage } = useIntl();

  return (
    <DropdownSelect
      label={formatMessage({
        id: 'global.country',
        defaultMessage: 'Country',
      })}
      onChange={onChange}
      value={
        country
          ? LABELING_COUNTRIES.filter((item) => item === country)[0]
          : formatMessage({
              id: 'labeling.selectCountry',
              defaultMessage: 'Select country',
            })
      }
      options={LABELING_COUNTRIES.map((countryOption) => ({
        label: countryOption,
        value: countryOption,
      }))}
      className={styles.select}
      icon="arrow2"
    />
  );
};

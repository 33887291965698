import { FormattedMessage } from 'react-intl';
import { boolean, object, string, ref, InferType } from 'yup';

import { PASSWORD_REGEX } from '@/shared/config';

import { FinishRegistrationMethod } from './constants';

export const confirmPasswordFormSchema = object({
  password: string()
    .required()
    .test('password', (value) => PASSWORD_REGEX.test(value || '')),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .required(),
  marketingCommunicationConsent: boolean(),
});

export const signUpViaSalesmanFormSchema = object({
  password: string().required(),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .required(),
});

export const finishRegistrationFormSchema = {
  [FinishRegistrationMethod.confirmPassword]: confirmPasswordFormSchema,
  [FinishRegistrationMethod.signUpViaSalesman]: signUpViaSalesmanFormSchema,
};

export type FinishRegistrationFormPayload = InferType<
  (typeof finishRegistrationFormSchema)[FinishRegistrationMethod]
>;

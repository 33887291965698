import { FC } from 'react';
import cn from 'classnames';

import styles from './HeaderDivider.module.scss';

type HeaderDividerProps = {
  className?: string;
  testID?: string;
};

export const HeaderDivider: FC<HeaderDividerProps> = (props) => {
  const { className, testID } = props;

  // TODO: mb need to refactoring with hook useHeaderDivider
  return (
    <div className={cn(styles.container, className)} data-testid={testID} />
  );
};

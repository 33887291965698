import { FileWithPath } from 'react-dropzone';

import { StartUploadSessionReq } from '@/shared/api/protocol_gen/api/core/svc_upload';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';

export const getStudyRequestParams = ({
  patientID,
  userID,
  organizationID,
  files,
  assetType,
  toothID,
  studyID,
  reportID,
}: {
  files: FileWithPath[];
  assetType: AssetType;
  patientID?: string;
  userID?: string;
  organizationID?: string;
  toothID?: string;
  studyID?: string;
  reportID?: string;
}): StartUploadSessionReq => {
  const filePaths = files.map((file) => file.path ?? '');

  switch (assetType) {
    case AssetType.AssetType_Organization_EmployeeStamp: {
      return {
        OrganizationEmployeeStamp: {
          UserID: userID,
          OrganizationID: organizationID,
          FilePath: filePaths[0],
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Organization_Stamp: {
      return {
        OrganizationStamp: {
          OrganizationID: organizationID,
          FilePath: filePaths[0],
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_User_Signature: {
      return {
        UserSignature: {
          UserID: userID,
          FilePath: filePaths[0],
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_User_Avatar: {
      return {
        UserAvatar: {
          UserID: userID,
          FilePath: filePaths[0],
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Organization_Logo: {
      return {
        OrganizationLogo: {
          OrganizationID: organizationID,
          FilePath: filePaths[0],
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Report_Uploaded_ToothSlice: {
      return {
        ToothUserUploadedSlice: {
          ToothID: toothID,
          Slices: files.map((file) => ({
            Slice: {
              Path: file.path,
              MIME: file.type,
              Size: file.size,
              NoMetadata: {},
            },
          })),
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Study_CBCT: {
      return {
        StudyCBCT: {
          PatientID: patientID,
          FilePaths: filePaths,
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Study_IntraoralXRay: {
      return {
        StudyIntraoralXRay: {
          PatientID: patientID,
          FilePaths: filePaths,
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Study_PanoramicXRay: {
      return {
        StudyPanoramicXRay: {
          PatientID: patientID,
          FilePath: filePaths.at(0),
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Study_IOS_Meshes: {
      return {
        StudyIOSMeshes: {
          PatientID: patientID,
          FilePaths: filePaths,
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Report_Uploaded_MPRPlane: {
      return {
        ToothUserUploadedMPRPlanes: {
          ToothID: toothID,
          MPRPanes: files.map((file) => ({
            MPRPlane: {
              MIME: file.type,
              Size: file.size,
              NoMetadata: {},
            },
          })),
        },
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_Study_DentalPhoto: {
      return {
        StudyDentalPhoto: {
          PatientID: patientID,
          FilePaths: filePaths,
          StudyID: studyID,
        },
      } as StartUploadSessionReq;
    }
    case AssetType.AssetType_Report_Uploaded_DisplayAsset: {
      return {
        ReportDisplayAsset: {
          ReportID: reportID,
          File: files[0],
        },
      } as unknown as StartUploadSessionReq;
    }
    default:
      throw new Error('Asset type is invalid or missing');
  }
};

import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Modal } from '@/shared/ui';
import { OrganizationUserRole } from '@/shared/api/protocol_gen/model/dto_organization';

import { DoctorRow } from '@/entities/doctors';

import styles from './ShowAllTreatingDoctorsModal.module.scss';

type ShowAllTreatingDoctorsModalProps = {
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
  treatingDoctors: OrganizationUserRole[];
  openRemoveDoctorModal: (doctorID: string) => void;
};

export const ShowAllTreatingDoctorsModal: FC<
  ShowAllTreatingDoctorsModalProps
> = (props) => {
  const {
    className,
    onClose,
    isOpen = false,
    treatingDoctors,
    openRemoveDoctorModal,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <Modal
      className={cn(styles.container, className)}
      title={formatMessage({
        id: 'patientInfo.treatingDoctors',
        defaultMessage: 'Treating doctors',
      })}
      isOpen={isOpen}
      onCancel={onClose}
      hideFooter
    >
      <div className={styles.content}>
        {treatingDoctors.map((treatingDoctor) => (
          <DoctorRow
            key={treatingDoctor.UserID}
            doctor={treatingDoctor}
            onDelete={() => openRemoveDoctorModal(treatingDoctor?.UserID)}
          />
        ))}
      </div>
    </Modal>
  );
};

import { filter } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { isConditionPatientIDEquals } from './isConditionPatientIDEquals';

export const filterConditionsByPatientID = (
  conditions: Condition[],
  patientID: string,
) => filter(isConditionPatientIDEquals(patientID))(conditions);

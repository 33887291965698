/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSliceOrientation,
  CBCTSectionOrientation,
  File,
  DerivedDataImage,
  cBCTSliceOrientationFromJSON,
  cBCTSectionOrientationFromJSON,
  cBCTSliceOrientationToJSON,
  cBCTSectionOrientationToJSON,
} from '../model/dto_asset_common';

export const protobufPackage = 'com.diagnocat.model';

export enum AssetContentGeneratedCBCTEndoReportSection {
  Endo_ReportSection_RootCanalSpace = 0,
  Endo_ReportSection_RootCanalSystem = 1,
  Endo_ReportSection_RootCanalShape = 2,
  Endo_ReportSection_Furcation = 3,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedCBCTEndoReportSectionFromJSON(
  object: any,
): AssetContentGeneratedCBCTEndoReportSection {
  switch (object) {
    case 0:
    case 'Endo_ReportSection_RootCanalSpace':
      return AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSpace;
    case 1:
    case 'Endo_ReportSection_RootCanalSystem':
      return AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSystem;
    case 2:
    case 'Endo_ReportSection_RootCanalShape':
      return AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalShape;
    case 3:
    case 'Endo_ReportSection_Furcation':
      return AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_Furcation;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedCBCTEndoReportSection.UNRECOGNIZED;
  }
}

export function assetContentGeneratedCBCTEndoReportSectionToJSON(
  object: AssetContentGeneratedCBCTEndoReportSection,
): string {
  switch (object) {
    case AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSpace:
      return 'Endo_ReportSection_RootCanalSpace';
    case AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSystem:
      return 'Endo_ReportSection_RootCanalSystem';
    case AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalShape:
      return 'Endo_ReportSection_RootCanalShape';
    case AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_Furcation:
      return 'Endo_ReportSection_Furcation';
    case AssetContentGeneratedCBCTEndoReportSection.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface AssetContentGeneratedCBCTEndoPanoramaSplit {
  PanoramaSplit: File | undefined;
  Type: AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType;
  /** in millimeters; maps to `width` */
  Thickness: number;
  WindowWidth: number;
  WindowLevel: number;
  Derived: DerivedDataImage | undefined;
}

/** {"type": "File", "file": {"task_id": "da8561ec-a376-9d8a-c0a9-6da76722dcc4", "key": "split-panorama", "filename": "/tmp/tmp9z4ug6ej", "content_type": "application/dicom", "size": 222976, "meta": {"type": "mandible", "ww": 2890, "wl": 670, "width": 5, "image_height": 185, "image_width": 600, "name": "split-panorama"}, "type": "File"}, "event": "execution-event"} */
export enum AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType {
  Mandible = 0,
  Maxilla = 1,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitTypeFromJSON(
  object: any,
): AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType {
  switch (object) {
    case 0:
    case 'Mandible':
      return AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType.Mandible;
    case 1:
    case 'Maxilla':
      return AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType.Maxilla;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType.UNRECOGNIZED;
  }
}

export function assetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitTypeToJSON(
  object: AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType,
): string {
  switch (object) {
    case AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType.Mandible:
      return 'Mandible';
    case AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType.Maxilla:
      return 'Maxilla';
    case AssetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface AssetContentGeneratedCBCTEndoCrossSection {
  /** {"type": "File", "file": {"task_id": "da8561ec-a376-9d8a-c0a9-6da76722dcc4", "key": "axial-000", "filename": "/tmp/tmpdga7v8kj", "content_type": "application/dicom", "size": 9966, "meta": {"order": 0, "stride_mm": 1, "thickness_mm": 0.25, "right_side": "M", "left_side": "D", "type": "cross-section", "section": "root-canal-space", "image_height": 65, "image_width": 69, "name": "axial-000"}, "type": "File"}, "event": "execution-event"} */
  CrossSection: File | undefined;
  Order: number;
  /** in millimeters; maps to `thickness_mm` */
  Thickness: number;
  /** maps to `right_side` */
  RightSideOrient: CBCTSliceOrientation;
  /** maps to `left_side` */
  LeftSideOrient: CBCTSliceOrientation;
  Section: AssetContentGeneratedCBCTEndoReportSection;
  Orientation: CBCTSectionOrientation;
  Axial: CBCTEndoCrossSectionMetadataAxial | undefined;
  Furcation: CBCTEndoCrossSectionMetadataFurcation | undefined;
  Vertical: CBCTEndoCrossSectionMetadataVertical | undefined;
}

export interface CBCTEndoCrossSectionMetadataFurcation {}

export interface CBCTEndoCrossSectionMetadataAxial {
  Stride: number;
}

export interface CBCTEndoCrossSectionMetadataVertical {
  /** in millimeters; maps to `canal_len` */
  CanalLength: number;
  /** maps to `canal_curv` */
  CanalCurvature:
    | CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple
    | undefined;
  RootName: CBCTEndoCrossSectionMetadataVertical_Root;
}

export enum CBCTEndoCrossSectionMetadataVertical_Root {
  /** MB - root == 2 */
  MB = 0,
  /** DB - root == 4 */
  DB = 1,
  /**
   * P1 - if 3 in basis.roots and 5 in basis.roots:
   *    if root == 3:
   */
  P1 = 2,
  /** P2 - if root == 5: */
  P2 = 3,
  /**
   * P - else:
   *     if (root == 3) | (root == 5):
   */
  P = 4,
  UNRECOGNIZED = -1,
}

export function cBCTEndoCrossSectionMetadataVertical_RootFromJSON(
  object: any,
): CBCTEndoCrossSectionMetadataVertical_Root {
  switch (object) {
    case 0:
    case 'MB':
      return CBCTEndoCrossSectionMetadataVertical_Root.MB;
    case 1:
    case 'DB':
      return CBCTEndoCrossSectionMetadataVertical_Root.DB;
    case 2:
    case 'P1':
      return CBCTEndoCrossSectionMetadataVertical_Root.P1;
    case 3:
    case 'P2':
      return CBCTEndoCrossSectionMetadataVertical_Root.P2;
    case 4:
    case 'P':
      return CBCTEndoCrossSectionMetadataVertical_Root.P;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTEndoCrossSectionMetadataVertical_Root.UNRECOGNIZED;
  }
}

export function cBCTEndoCrossSectionMetadataVertical_RootToJSON(
  object: CBCTEndoCrossSectionMetadataVertical_Root,
): string {
  switch (object) {
    case CBCTEndoCrossSectionMetadataVertical_Root.MB:
      return 'MB';
    case CBCTEndoCrossSectionMetadataVertical_Root.DB:
      return 'DB';
    case CBCTEndoCrossSectionMetadataVertical_Root.P1:
      return 'P1';
    case CBCTEndoCrossSectionMetadataVertical_Root.P2:
      return 'P2';
    case CBCTEndoCrossSectionMetadataVertical_Root.P:
      return 'P';
    case CBCTEndoCrossSectionMetadataVertical_Root.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple {
  V1: number;
  V2: number;
  V3: number;
}

export interface AssetContentGeneratedCBCTEndoGuide {
  Guide: File | undefined;
  Section: AssetContentGeneratedCBCTEndoReportSection;
  Order: number;
  Orientation: CBCTSectionOrientation;
}

function createBaseAssetContentGeneratedCBCTEndoPanoramaSplit(): AssetContentGeneratedCBCTEndoPanoramaSplit {
  return {
    PanoramaSplit: undefined,
    Type: 0,
    Thickness: 0,
    WindowWidth: 0,
    WindowLevel: 0,
    Derived: undefined,
  };
}

export const AssetContentGeneratedCBCTEndoPanoramaSplit = {
  encode(
    message: AssetContentGeneratedCBCTEndoPanoramaSplit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaSplit !== undefined) {
      File.encode(message.PanoramaSplit, writer.uint32(10).fork()).ldelim();
    }
    if (message.Type !== 0) {
      writer.uint32(16).int32(message.Type);
    }
    if (message.Thickness !== 0) {
      writer.uint32(29).float(message.Thickness);
    }
    if (message.WindowWidth !== 0) {
      writer.uint32(37).float(message.WindowWidth);
    }
    if (message.WindowLevel !== 0) {
      writer.uint32(45).float(message.WindowLevel);
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTEndoPanoramaSplit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTEndoPanoramaSplit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaSplit = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Type = reader.int32() as any;
          break;
        case 3:
          message.Thickness = reader.float();
          break;
        case 4:
          message.WindowWidth = reader.float();
          break;
        case 5:
          message.WindowLevel = reader.float();
          break;
        case 101:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTEndoPanoramaSplit {
    return {
      PanoramaSplit: isSet(object.PanoramaSplit)
        ? File.fromJSON(object.PanoramaSplit)
        : undefined,
      Type: isSet(object.Type)
        ? assetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitTypeFromJSON(
            object.Type,
          )
        : 0,
      Thickness: isSet(object.Thickness) ? Number(object.Thickness) : 0,
      WindowWidth: isSet(object.WindowWidth) ? Number(object.WindowWidth) : 0,
      WindowLevel: isSet(object.WindowLevel) ? Number(object.WindowLevel) : 0,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTEndoPanoramaSplit): unknown {
    const obj: any = {};
    message.PanoramaSplit !== undefined &&
      (obj.PanoramaSplit = message.PanoramaSplit
        ? File.toJSON(message.PanoramaSplit)
        : undefined);
    message.Type !== undefined &&
      (obj.Type =
        assetContentGeneratedCBCTEndoPanoramaSplit_PanoramaSplitTypeToJSON(
          message.Type,
        ));
    message.Thickness !== undefined && (obj.Thickness = message.Thickness);
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTEndoPanoramaSplit>, I>,
  >(object: I): AssetContentGeneratedCBCTEndoPanoramaSplit {
    const message = createBaseAssetContentGeneratedCBCTEndoPanoramaSplit();
    message.PanoramaSplit =
      object.PanoramaSplit !== undefined && object.PanoramaSplit !== null
        ? File.fromPartial(object.PanoramaSplit)
        : undefined;
    message.Type = object.Type ?? 0;
    message.Thickness = object.Thickness ?? 0;
    message.WindowWidth = object.WindowWidth ?? 0;
    message.WindowLevel = object.WindowLevel ?? 0;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTEndoCrossSection(): AssetContentGeneratedCBCTEndoCrossSection {
  return {
    CrossSection: undefined,
    Order: 0,
    Thickness: 0,
    RightSideOrient: 0,
    LeftSideOrient: 0,
    Section: 0,
    Orientation: 0,
    Axial: undefined,
    Furcation: undefined,
    Vertical: undefined,
  };
}

export const AssetContentGeneratedCBCTEndoCrossSection = {
  encode(
    message: AssetContentGeneratedCBCTEndoCrossSection,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CrossSection !== undefined) {
      File.encode(message.CrossSection, writer.uint32(10).fork()).ldelim();
    }
    if (message.Order !== 0) {
      writer.uint32(16).uint32(message.Order);
    }
    if (message.Thickness !== 0) {
      writer.uint32(29).float(message.Thickness);
    }
    if (message.RightSideOrient !== 0) {
      writer.uint32(32).int32(message.RightSideOrient);
    }
    if (message.LeftSideOrient !== 0) {
      writer.uint32(40).int32(message.LeftSideOrient);
    }
    if (message.Section !== 0) {
      writer.uint32(48).int32(message.Section);
    }
    if (message.Orientation !== 0) {
      writer.uint32(56).int32(message.Orientation);
    }
    if (message.Axial !== undefined) {
      CBCTEndoCrossSectionMetadataAxial.encode(
        message.Axial,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Furcation !== undefined) {
      CBCTEndoCrossSectionMetadataFurcation.encode(
        message.Furcation,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.Vertical !== undefined) {
      CBCTEndoCrossSectionMetadataVertical.encode(
        message.Vertical,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTEndoCrossSection {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTEndoCrossSection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CrossSection = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Order = reader.uint32();
          break;
        case 3:
          message.Thickness = reader.float();
          break;
        case 4:
          message.RightSideOrient = reader.int32() as any;
          break;
        case 5:
          message.LeftSideOrient = reader.int32() as any;
          break;
        case 6:
          message.Section = reader.int32() as any;
          break;
        case 7:
          message.Orientation = reader.int32() as any;
          break;
        case 101:
          message.Axial = CBCTEndoCrossSectionMetadataAxial.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.Furcation = CBCTEndoCrossSectionMetadataFurcation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.Vertical = CBCTEndoCrossSectionMetadataVertical.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTEndoCrossSection {
    return {
      CrossSection: isSet(object.CrossSection)
        ? File.fromJSON(object.CrossSection)
        : undefined,
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      Thickness: isSet(object.Thickness) ? Number(object.Thickness) : 0,
      RightSideOrient: isSet(object.RightSideOrient)
        ? cBCTSliceOrientationFromJSON(object.RightSideOrient)
        : 0,
      LeftSideOrient: isSet(object.LeftSideOrient)
        ? cBCTSliceOrientationFromJSON(object.LeftSideOrient)
        : 0,
      Section: isSet(object.Section)
        ? assetContentGeneratedCBCTEndoReportSectionFromJSON(object.Section)
        : 0,
      Orientation: isSet(object.Orientation)
        ? cBCTSectionOrientationFromJSON(object.Orientation)
        : 0,
      Axial: isSet(object.Axial)
        ? CBCTEndoCrossSectionMetadataAxial.fromJSON(object.Axial)
        : undefined,
      Furcation: isSet(object.Furcation)
        ? CBCTEndoCrossSectionMetadataFurcation.fromJSON(object.Furcation)
        : undefined,
      Vertical: isSet(object.Vertical)
        ? CBCTEndoCrossSectionMetadataVertical.fromJSON(object.Vertical)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTEndoCrossSection): unknown {
    const obj: any = {};
    message.CrossSection !== undefined &&
      (obj.CrossSection = message.CrossSection
        ? File.toJSON(message.CrossSection)
        : undefined);
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    message.Thickness !== undefined && (obj.Thickness = message.Thickness);
    message.RightSideOrient !== undefined &&
      (obj.RightSideOrient = cBCTSliceOrientationToJSON(
        message.RightSideOrient,
      ));
    message.LeftSideOrient !== undefined &&
      (obj.LeftSideOrient = cBCTSliceOrientationToJSON(message.LeftSideOrient));
    message.Section !== undefined &&
      (obj.Section = assetContentGeneratedCBCTEndoReportSectionToJSON(
        message.Section,
      ));
    message.Orientation !== undefined &&
      (obj.Orientation = cBCTSectionOrientationToJSON(message.Orientation));
    message.Axial !== undefined &&
      (obj.Axial = message.Axial
        ? CBCTEndoCrossSectionMetadataAxial.toJSON(message.Axial)
        : undefined);
    message.Furcation !== undefined &&
      (obj.Furcation = message.Furcation
        ? CBCTEndoCrossSectionMetadataFurcation.toJSON(message.Furcation)
        : undefined);
    message.Vertical !== undefined &&
      (obj.Vertical = message.Vertical
        ? CBCTEndoCrossSectionMetadataVertical.toJSON(message.Vertical)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTEndoCrossSection>, I>,
  >(object: I): AssetContentGeneratedCBCTEndoCrossSection {
    const message = createBaseAssetContentGeneratedCBCTEndoCrossSection();
    message.CrossSection =
      object.CrossSection !== undefined && object.CrossSection !== null
        ? File.fromPartial(object.CrossSection)
        : undefined;
    message.Order = object.Order ?? 0;
    message.Thickness = object.Thickness ?? 0;
    message.RightSideOrient = object.RightSideOrient ?? 0;
    message.LeftSideOrient = object.LeftSideOrient ?? 0;
    message.Section = object.Section ?? 0;
    message.Orientation = object.Orientation ?? 0;
    message.Axial =
      object.Axial !== undefined && object.Axial !== null
        ? CBCTEndoCrossSectionMetadataAxial.fromPartial(object.Axial)
        : undefined;
    message.Furcation =
      object.Furcation !== undefined && object.Furcation !== null
        ? CBCTEndoCrossSectionMetadataFurcation.fromPartial(object.Furcation)
        : undefined;
    message.Vertical =
      object.Vertical !== undefined && object.Vertical !== null
        ? CBCTEndoCrossSectionMetadataVertical.fromPartial(object.Vertical)
        : undefined;
    return message;
  },
};

function createBaseCBCTEndoCrossSectionMetadataFurcation(): CBCTEndoCrossSectionMetadataFurcation {
  return {};
}

export const CBCTEndoCrossSectionMetadataFurcation = {
  encode(
    _: CBCTEndoCrossSectionMetadataFurcation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTEndoCrossSectionMetadataFurcation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTEndoCrossSectionMetadataFurcation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): CBCTEndoCrossSectionMetadataFurcation {
    return {};
  },

  toJSON(_: CBCTEndoCrossSectionMetadataFurcation): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CBCTEndoCrossSectionMetadataFurcation>, I>,
  >(_: I): CBCTEndoCrossSectionMetadataFurcation {
    const message = createBaseCBCTEndoCrossSectionMetadataFurcation();
    return message;
  },
};

function createBaseCBCTEndoCrossSectionMetadataAxial(): CBCTEndoCrossSectionMetadataAxial {
  return { Stride: 0 };
}

export const CBCTEndoCrossSectionMetadataAxial = {
  encode(
    message: CBCTEndoCrossSectionMetadataAxial,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Stride !== 0) {
      writer.uint32(13).float(message.Stride);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTEndoCrossSectionMetadataAxial {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTEndoCrossSectionMetadataAxial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Stride = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTEndoCrossSectionMetadataAxial {
    return {
      Stride: isSet(object.Stride) ? Number(object.Stride) : 0,
    };
  },

  toJSON(message: CBCTEndoCrossSectionMetadataAxial): unknown {
    const obj: any = {};
    message.Stride !== undefined && (obj.Stride = message.Stride);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CBCTEndoCrossSectionMetadataAxial>, I>,
  >(object: I): CBCTEndoCrossSectionMetadataAxial {
    const message = createBaseCBCTEndoCrossSectionMetadataAxial();
    message.Stride = object.Stride ?? 0;
    return message;
  },
};

function createBaseCBCTEndoCrossSectionMetadataVertical(): CBCTEndoCrossSectionMetadataVertical {
  return { CanalLength: 0, CanalCurvature: undefined, RootName: 0 };
}

export const CBCTEndoCrossSectionMetadataVertical = {
  encode(
    message: CBCTEndoCrossSectionMetadataVertical,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanalLength !== 0) {
      writer.uint32(13).float(message.CanalLength);
    }
    if (message.CanalCurvature !== undefined) {
      CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple.encode(
        message.CanalCurvature,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.RootName !== 0) {
      writer.uint32(24).int32(message.RootName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTEndoCrossSectionMetadataVertical {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTEndoCrossSectionMetadataVertical();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanalLength = reader.float();
          break;
        case 2:
          message.CanalCurvature =
            CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 3:
          message.RootName = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTEndoCrossSectionMetadataVertical {
    return {
      CanalLength: isSet(object.CanalLength) ? Number(object.CanalLength) : 0,
      CanalCurvature: isSet(object.CanalCurvature)
        ? CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple.fromJSON(
            object.CanalCurvature,
          )
        : undefined,
      RootName: isSet(object.RootName)
        ? cBCTEndoCrossSectionMetadataVertical_RootFromJSON(object.RootName)
        : 0,
    };
  },

  toJSON(message: CBCTEndoCrossSectionMetadataVertical): unknown {
    const obj: any = {};
    message.CanalLength !== undefined &&
      (obj.CanalLength = message.CanalLength);
    message.CanalCurvature !== undefined &&
      (obj.CanalCurvature = message.CanalCurvature
        ? CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple.toJSON(
            message.CanalCurvature,
          )
        : undefined);
    message.RootName !== undefined &&
      (obj.RootName = cBCTEndoCrossSectionMetadataVertical_RootToJSON(
        message.RootName,
      ));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CBCTEndoCrossSectionMetadataVertical>, I>,
  >(object: I): CBCTEndoCrossSectionMetadataVertical {
    const message = createBaseCBCTEndoCrossSectionMetadataVertical();
    message.CanalLength = object.CanalLength ?? 0;
    message.CanalCurvature =
      object.CanalCurvature !== undefined && object.CanalCurvature !== null
        ? CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple.fromPartial(
            object.CanalCurvature,
          )
        : undefined;
    message.RootName = object.RootName ?? 0;
    return message;
  },
};

function createBaseCBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple(): CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple {
  return { V1: 0, V2: 0, V3: 0 };
}

export const CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple = {
  encode(
    message: CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.V1 !== 0) {
      writer.uint32(13).float(message.V1);
    }
    if (message.V2 !== 0) {
      writer.uint32(21).float(message.V2);
    }
    if (message.V3 !== 0) {
      writer.uint32(29).float(message.V3);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseCBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.V1 = reader.float();
          break;
        case 2:
          message.V2 = reader.float();
          break;
        case 3:
          message.V3 = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple {
    return {
      V1: isSet(object.V1) ? Number(object.V1) : 0,
      V2: isSet(object.V2) ? Number(object.V2) : 0,
      V3: isSet(object.V3) ? Number(object.V3) : 0,
    };
  },

  toJSON(
    message: CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple,
  ): unknown {
    const obj: any = {};
    message.V1 !== undefined && (obj.V1 = message.V1);
    message.V2 !== undefined && (obj.V2 = message.V2);
    message.V3 !== undefined && (obj.V3 = message.V3);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple>,
      I
    >,
  >(object: I): CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple {
    const message =
      createBaseCBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple();
    message.V1 = object.V1 ?? 0;
    message.V2 = object.V2 ?? 0;
    message.V3 = object.V3 ?? 0;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTEndoGuide(): AssetContentGeneratedCBCTEndoGuide {
  return { Guide: undefined, Section: 0, Order: 0, Orientation: 0 };
}

export const AssetContentGeneratedCBCTEndoGuide = {
  encode(
    message: AssetContentGeneratedCBCTEndoGuide,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Guide !== undefined) {
      File.encode(message.Guide, writer.uint32(10).fork()).ldelim();
    }
    if (message.Section !== 0) {
      writer.uint32(16).int32(message.Section);
    }
    if (message.Order !== 0) {
      writer.uint32(24).uint32(message.Order);
    }
    if (message.Orientation !== 0) {
      writer.uint32(32).int32(message.Orientation);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTEndoGuide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTEndoGuide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Guide = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Section = reader.int32() as any;
          break;
        case 3:
          message.Order = reader.uint32();
          break;
        case 4:
          message.Orientation = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTEndoGuide {
    return {
      Guide: isSet(object.Guide) ? File.fromJSON(object.Guide) : undefined,
      Section: isSet(object.Section)
        ? assetContentGeneratedCBCTEndoReportSectionFromJSON(object.Section)
        : 0,
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      Orientation: isSet(object.Orientation)
        ? cBCTSectionOrientationFromJSON(object.Orientation)
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTEndoGuide): unknown {
    const obj: any = {};
    message.Guide !== undefined &&
      (obj.Guide = message.Guide ? File.toJSON(message.Guide) : undefined);
    message.Section !== undefined &&
      (obj.Section = assetContentGeneratedCBCTEndoReportSectionToJSON(
        message.Section,
      ));
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    message.Orientation !== undefined &&
      (obj.Orientation = cBCTSectionOrientationToJSON(message.Orientation));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTEndoGuide>, I>,
  >(object: I): AssetContentGeneratedCBCTEndoGuide {
    const message = createBaseAssetContentGeneratedCBCTEndoGuide();
    message.Guide =
      object.Guide !== undefined && object.Guide !== null
        ? File.fromPartial(object.Guide)
        : undefined;
    message.Section = object.Section ?? 0;
    message.Order = object.Order ?? 0;
    message.Orientation = object.Orientation ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

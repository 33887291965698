import { createSelector } from '@reduxjs/toolkit';

import { studyCountAdapter } from '@/entities/studyCount/model/studyCountSlice';

import { RootState } from '@/app/model/store';

export const studyCountsSelectors = studyCountAdapter.getSelectors(
  (state: RootState) => state.studyCount,
);

export const selectStudyCountsByPatientID = (patientID: string) =>
  createSelector(studyCountsSelectors.selectAll, (studyCounts) =>
    studyCounts.find((studyCount) => studyCount.patientID === patientID),
  );

import { createSelector } from '@reduxjs/toolkit';
import { head } from 'ramda';

import {
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol_gen/model/dto_study';
import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';
import { getImageSrc } from '@/entities/assets';

type StudyOrderItem = {
  id: string;
  createdAt?: Date;
  previewSrc: string;
};

const reportHasStudyIDAndNotPanowing = (studyID: string) => (report: Report) =>
  report.SourceStudyIDs.includes(studyID) &&
  report.Type !== ReportType.ReportType_Pano_Bitewings;

export const selectStudyList = (studyType: StudyType) =>
  createSelector(
    studyModel.selectors.selectByType(studyType),
    reportsModel.selectors.selectAll,
    (studies, reports): StudyOrderItem[] => {
      return studies
        .filter((study) => study.Status === StudyStatus.StudyStatus_Finished)
        .map((study) => {
          const studyReports = reports.filter(
            reportHasStudyIDAndNotPanowing(study.ID),
          );
          const firstStudyReport = head(studyReports);
          const previewSrc = getImageSrc(
            firstStudyReport?.DisplayAssetID || study.DisplayAssetID,
            'preview',
          );

          return {
            id: study.ID,
            createdAt: study.Created?.At,
            previewSrc,
          };
        });
    },
  );

export const selectFirstStudyID = (studyType: StudyType) =>
  createSelector(
    selectStudyList(studyType),
    (studyList) => head(studyList)?.id,
  );

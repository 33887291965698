import { defineMessages } from 'react-intl';

import { PatientsFilters } from '@/entities/patient';

export const searchBarPlaceholders = defineMessages<PatientsFilters>({
  [PatientsFilters.all]: {
    id: 'patientList.filters.searchBarPlaceholderAll',
    defaultMessage: ' Search by Patient name or ID',
  },
  [PatientsFilters.sharedWithMe]: {
    id: 'patientList.filters.searchBarPlaceholderSharedWithMe',
    defaultMessage: 'Search by Patient name, Clinic name or Shared by',
  },
  [PatientsFilters.sharedByMe]: {
    id: 'patientList.filters.searchBarPlaceholderSharedByMeOrStaff',
    defaultMessage: 'Search by Patient name, Shared by or Shared with',
  },
  [PatientsFilters.sharedByStaff]: {
    id: 'patientList.filters.searchBarPlaceholderSharedByMeOrStaff',
    defaultMessage: 'Search by Patient name, Shared by or Shared with',
  },
});

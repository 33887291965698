import { ToothWithLocalization } from '@/entities/tooth';

type DetectionsFromTeeth = {
  tooth: ToothWithLocalization;
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
};

export const makeDetectionsFromTeeth = (
  teeth: ToothWithLocalization[],
): DetectionsFromTeeth[] => {
  if (!teeth.length) {
    return [];
  }

  return teeth.reduce((localizations, tooth) => {
    if (tooth.Localization.BBox) {
      const {
        Localization: { BBox },
      } = tooth;

      localizations.push({
        tooth,
        xmin: BBox?.X?.Min ?? 0,
        ymin: BBox?.Y?.Min ?? 0,
        xmax: BBox?.X?.Max ?? 0,
        ymax: BBox?.Y?.Max ?? 0,
      });
    }

    return localizations;
  }, [] as DetectionsFromTeeth[]);
};

import { useAppDispatch } from '@/shared/hooks';
import { RequestReportReq } from '@/shared/api/protocol_gen/api/core/svc_report';
import {
  ReportRequest_InputTypeStudioImplant,
  ReportRequest_InputTypeStudioOrtho,
} from '@/shared/api/protocol_gen/model/dto_report_request';

import { reportsModel } from '@/entities/reports';

import { getSegmentsWithTeethAndAnatomies } from '../lib';

export const useNewReport = () => {
  const dispatch = useAppDispatch();

  const requestIOXRayGPReport = async (studyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputIOXRayGP: {
            IntraoralXRayStudyID: studyID,
          },
        },
      } as RequestReportReq),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestPanoGPReport = async (studyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputPanoGP: {
            PanoramicXRayStudyID: studyID,
          },
        },
      } as RequestReportReq),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestCBCTGPReport = async (studyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputCBCTGP: {
            CBCTStudyID: studyID,
          },
        },
      } as RequestReportReq),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestCBCTSegmentationReport = async (CBCTStudyID: string) => {
    await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputCBCTSegmentation: {
            CBCTStudyID,
            Segments: getSegmentsWithTeethAndAnatomies(),
          },
        },
      } as RequestReportReq),
    );
  };

  const requestStudioOrthoReport = async ({
    CBCTStudyID,
    IOSMeshesStudyID,
    DentalPhotoStudyID,
  }: ReportRequest_InputTypeStudioImplant) => {
    await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputStudioOrtho: {
            CBCTStudyID,
            IOSMeshesStudyID,
            DentalPhotoStudyID,
          },
        },
      } as unknown as RequestReportReq),
    );
  };

  const requestStudioImplantReport = async ({
    CBCTStudyID,
    IOSMeshesStudyID,
    DentalPhotoStudyID,
  }: ReportRequest_InputTypeStudioOrtho) => {
    await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputStudioImplant: {
            CBCTStudyID,
            IOSMeshesStudyID,
            DentalPhotoStudyID,
          },
        },
      } as unknown as RequestReportReq),
    );
  };

  return {
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
  };
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  SetUserLanguageReq,
  SetUserLanguageResp,
  SetUserNameReq,
  SetUserNameResp,
  UserServiceClientImpl,
} from '@/shared/api/protocol_gen/api/core/svc_user';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import api from '@/shared/api/api';

import { UserLocalSettings } from '../lib/getInitialUserSettings';

type UserRequests = keyof UserServiceClientImpl;

const generateAccessThunk = createThunkGenerator<UserRequests>(
  SliceName.user,
  'user',
);

export const setSettings = createAsyncThunk(
  'user/setSettings',
  async (settings: Partial<UserLocalSettings>) => {
    Object.entries(settings).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
    return settings;
  },
);

export const setUserLanguage = generateAccessThunk<
  SetUserLanguageReq,
  SetUserLanguageResp
>('SetUserLanguage', api.user.SetUserLanguage);

export const setUserName = generateAccessThunk<SetUserNameReq, SetUserNameResp>(
  'SetUserName',
  api.user.SetUserName,
);

import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ToothLandmark } from '@/shared/api/protocol_gen/model/dto_report_landmark';
import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';

export const toothLandmarksAdapter = createEntityAdapter<ToothLandmark>({
  selectId: (toothLandmark: ToothLandmark) => toothLandmark.ID,
});

const initialStateFlags: DefaultInitialState = {
  loading: 'idle',
};

const toothLandmarksSlice = createSlice({
  name: SliceName.toothLandmarks,
  initialState: toothLandmarksAdapter.getInitialState(initialStateFlags),
  reducers: {
    addOne: toothLandmarksAdapter.addOne,
    addMany: toothLandmarksAdapter.addMany,
    setOne: toothLandmarksAdapter.setOne,
    setNewestOne: (state, action: PayloadAction<ToothLandmark>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        toothLandmarksAdapter.setOne(state, action.payload);
      }
    },
    setMany: toothLandmarksAdapter.setMany,
    removeOne: toothLandmarksAdapter.removeOne,
    removeAll: toothLandmarksAdapter.removeAll,
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
});

export const { actions } = toothLandmarksSlice;

export default toothLandmarksSlice.reducer;

import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { groupBy, prop } from 'ramda';
import { generatePath, useLocation, useNavigate } from 'react-router';
import cn from 'classnames';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { useAppSelector } from '@/shared/hooks';
import { LocationStateType } from '@/shared/config';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';

import { getReportPath, i18n, reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';

import {
  RemoveReportModal,
  useRemoveReportModal,
} from '@/features/removeReport';
import { ReportRow } from '@/features/reportRow';

import styles from './ReportAccordion.module.scss';

const isReportNotPanoBitewings = (report: Report) =>
  report.Type !== ReportType.ReportType_Pano_Bitewings;

// Can we add remove callback here and move remove report modal upper in StudyCard?
type ReportAccordionProps = {
  studyID: string;
};

// TODO: refactore and rename without accordions
export const ReportAccordion: FC<ReportAccordionProps> = (props) => {
  const { studyID } = props;

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const activeReports = useAppSelector(
    reportsModel.selectors.selectActiveReportsByStudyID([studyID]),
  );

  const study = useAppSelector(studyModel.selectors.selectByID(studyID));

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  // Hide Panowings reports if study type is not PanoramicXRay
  const filteredReports =
    study?.Type !== StudyType.StudyType_PanoramicXRay
      ? activeReports.filter(isReportNotPanoBitewings)
      : activeReports;

  const reportGroups = useMemo(
    () => groupBy(prop<keyof Report>('Type'))(filteredReports),
    [filteredReports],
  ) as Record<ReportType, Report[]>;

  const {
    currentReportIDs,
    isRemoveReportModalOpen,
    openRemoveReportModal,
    closeRemoveReportModal,
  } = useRemoveReportModal();

  const studyReportTypes = Object.keys(reportGroups);

  const navigateToReportHandle = ({
    reportType,
    patientID,
    reportID,
  }: {
    reportType: ReportType;
    patientID: string;
    reportID: string;
  }) => {
    navigate(
      generatePath(getReportPath(reportType), {
        patientID,
        reportID,
      }),
      {
        state: {
          ...locationState,
          lastPositionPatientProfile: window.pageYOffset,
          lastOpenTabStudyID: studyID,
        },
      },
    );
  };

  return (
    <div className={styles.container}>
      {studyReportTypes
        .filter((reportType) =>
          [
            ReportType.ReportType_CBCT_GP,
            ReportType.ReportType_CBCT_Endo,
            ReportType.ReportType_IOXRay_GP,
            ReportType.ReportType_Pano_GP,
            ReportType.ReportType_Pano_Bitewings,
          ].includes(+reportType),
        )
        .map((reportType) => (
          <div
            key={reportType}
            className={cn(
              styles.reportGroup,
              (+reportType as unknown as ReportType) ===
                ReportType.ReportType_Pano_Bitewings && styles.panowings,
            )}
          >
            <h3 className={styles.subtitle}>
              {formatMessage(i18n[reportType as unknown as ReportType])}
            </h3>

            <div className={styles.reportRowContainer}>
              {reportGroups[reportType as unknown as ReportType].map(
                (report) => (
                  <ReportRow
                    key={report.ID}
                    report={report}
                    onDelete={() => openRemoveReportModal([report.ID])}
                    onClick={() =>
                      navigateToReportHandle({
                        reportType: report.Type,
                        reportID: report.ID,
                        patientID: report.PatientID,
                      })
                    }
                  />
                ),
              )}
            </div>
          </div>
        ))}

      <RemoveReportModal
        reportIDs={currentReportIDs}
        isOpen={isRemoveReportModalOpen}
        onCancel={closeRemoveReportModal}
      />
    </div>
  );
};

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { PURPLE_CODES, RED_CODES, YELLOW_CODES } from '../config/const';
import { ConditionColor } from '../config/types';

export const getConditionCodeColor = (
  code: ConditionCode,
  isConditionUncertain: boolean,
  showLowProbability: boolean = false,
): ConditionColor => {
  if (
    showLowProbability &&
    YELLOW_CODES.includes(code) &&
    isConditionUncertain
  ) {
    return 'yellow';
  }

  if (RED_CODES.includes(code)) {
    return 'red';
  }

  if (PURPLE_CODES.includes(code)) {
    return 'purple';
  }

  return 'white';
};

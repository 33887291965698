import { FC } from 'react';
import cn from 'classnames';

import { MOLAR_TOOTH_NUMBER, ToothStatus } from '@/shared/config';
import { getToothIconPathByToothStatus } from '@/shared/lib/getToothIconPathByToothStatus';

import { Icon } from '../Icon/Icon';

import toothSprite from './toothSprite.svg';
import styles from './ToothIcon.module.scss';

export type ToothIconProps = {
  position: number;
  isMissing?: boolean;
  isThirdMolar?: boolean;
  className?: string;
  testID?: string;
  onClick?: () => void;
  blackAndWhiteMode?: boolean;
  toothStatus?: ToothStatus;
};

export const ToothIcon: FC<ToothIconProps> = (props) => {
  const {
    className,
    testID,
    position,
    isMissing,
    isThirdMolar,
    onClick,
    blackAndWhiteMode = false,
    toothStatus,
  } = props;

  const shouldShowPointerCursor = typeof onClick === 'function' && !isMissing;

  const isMolar = MOLAR_TOOTH_NUMBER.includes(position);

  const toothIconPathByToothStatus = getToothIconPathByToothStatus(
    toothStatus ?? ToothStatus.healthy,
  );

  if (isMissing) {
    return (
      <Icon
        name="close"
        size={24}
        className={cn(
          !isThirdMolar && !blackAndWhiteMode
            ? styles.missing
            : styles.missingBlackAndWhite,
        )}
      />
    );
  }

  return blackAndWhiteMode ? (
    <img
      src={
        // eslint-disable-next-line import/no-dynamic-require, global-require
        require(`../../assets/images/B&WToothIcon/${toothIconPathByToothStatus}/tooth${position}.svg`)
      }
      alt={`tooth${position}`}
      onClick={onClick}
      role="presentation"
      aria-hidden="true"
      data-testid={testID}
      width={isMolar ? 27 : 20}
      height={isMolar ? 47 : 48}
      className={cn(
        className,
        styles.container,
        shouldShowPointerCursor && styles.pointer,
      )}
    />
  ) : (
    <svg
      onClick={onClick}
      role="presentation"
      aria-hidden="true"
      focusable="false"
      data-testid={testID}
      viewBox={isMolar ? '0 0 27 47' : '0 0 20 48'}
      width={isMolar ? 13 : 10}
      height={isMolar ? 23 : 24}
      className={cn(
        className,
        styles.container,
        shouldShowPointerCursor && styles.pointer,
      )}
    >
      <use href={`${toothSprite}#tooth${position}`} />
    </svg>
  );
};

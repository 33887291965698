import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  BillingClientImpl,
  CreateOrderReq,
  CreateOrderResp,
  GetAvailableProductsReq,
  GetAvailableProductsResp,
  PayOrderReq,
  PayOrderResp,
  SetAccountBillingInformationReq,
  SetAccountBillingInformationResp,
} from '@/shared/api/protocol_gen/api/billing_new/svc_billing_new';

type BillingRequests = keyof BillingClientImpl;

const generateBillingThunk = createThunkGenerator<BillingRequests>(
  SliceName.billing,
  'billing',
);

export const setAccountBillingInformation = generateBillingThunk<
  SetAccountBillingInformationReq,
  SetAccountBillingInformationResp
>('SetAccountBillingInformation', api.billing.SetAccountBillingInformation);

export const getAvailableProducts = generateBillingThunk<
  GetAvailableProductsReq,
  GetAvailableProductsResp
>('GetAvailableProducts', api.billing.GetAvailableProducts);

export const payOrder = generateBillingThunk<PayOrderReq, PayOrderResp>(
  'PayOrder',
  api.billing.PayOrder,
);

export const createOrder = generateBillingThunk<
  CreateOrderReq,
  CreateOrderResp
>('CreateOrder', api.billing.CreateOrder);

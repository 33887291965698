import { FormattedMessage } from 'react-intl';
import { InferType, object, string, ref } from 'yup';

import { PASSWORD_REGEX } from '@/shared/config';

export const newPasswordFormSchema = object({
  password: string()
    .required()
    .test('password', (value) => PASSWORD_REGEX.test(value || '')),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .required(),
});

export type NewPasswordFormPayload = InferType<typeof newPasswordFormSchema>;

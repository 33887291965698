// @ts-nocheck
import { ContextRequest, ProcessingUniforms, ProcessingUnitContext } from 'graphics';
import {
    CSSProperties,
    useState,
} from 'react';
import { AbsoluteBlock } from '../AbsoluteBlock';
import { AnnotationsLayer } from '../AnnotationsLayer/AnnotationsLayer';
import { ObjectFitContain } from '../ObjectFitContain';
import { UltimateMedicalImageProps } from '../Presets/UltimateMedicalImage';
import { RCContainer } from '../RCContainer/RCContainer';
import { TouchableLayer } from '../TouchableLayer/TouchableLayer';
import { RCCropImage, RCCropImageCrop } from './RCCropImage';
import { CORS_POLICY } from '@/shared/config';

export interface RCImageProps {
    src: ContextRequest;
    onLoaded?: (context: ProcessingUnitContext) => void;
    onRendered?: (getPngBlob: () => Promise<Blob>) => void;
    uniforms?: ProcessingUniforms;
    style?: CSSProperties,
    onClick?: () => void;
}

export type Repaint = (context: ProcessingUnitContext, canvas: HTMLCanvasElement) => void;

export interface CropImageWithPToolsProps extends UltimateMedicalImageProps {
    crop: RCCropImageCrop
}

export type UltimateMedicalImageMode =
    | 'view'
    | 'annotate'
    | 'sharpening'
    | 'windowing';

export const RCCropImageWithPTools = ({
    src,
    mode,
    annotations,
    currentAnnotation,
    onAnnotationsChanged,
    onWWWCChanged,
    onSharpnessChanged,
    onLoaded,
    ww,
    wc,
    mask,
    sharpness,
    inverted,
    className,
    style,
    crop,
}: CropImageWithPToolsProps) => {
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [pixelSpacing, setPixelSpacing] = useState<
        [number, number] | undefined
    >([1, 1]);

    const srcWithCors = {
        ...src,
        credentials: CORS_POLICY,
    };

    const maskWithCors = {
        ...mask,
        credentials: CORS_POLICY,
    };

    return (
        <RCContainer className={className} style={style}>
            <AbsoluteBlock data-id="MedicalImage">
                <RCCropImage
                    crop={crop}
                    src={srcWithCors}
                    uniforms={{ ww, wc, sharpness, invert: inverted }}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: "contain",
                    }}
                    onLoaded={(context) => {
                        onLoaded(context);
                        setImageSize({ width: context.width, height: context.height });
                        setPixelSpacing(context.pixelSpacing);
                    }}
                />
            </AbsoluteBlock>
            {mask && (
                <AbsoluteBlock data-id="MedicalImage Mask">
                    <RCCropImage
                        crop={crop}
                        src={maskWithCors}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: "contain",
                        }}
                    />
                </AbsoluteBlock>
            )}
            <AbsoluteBlock data-id="Annotations">
                <ObjectFitContain
                    objectWidth={crop.w}
                    objectHeight={crop.h}
                >
                    <AnnotationsLayer
                        pixelSpacing={pixelSpacing}
                        annotations={annotations}
                        annotationKind={currentAnnotation}
                        setAnnotations={onAnnotationsChanged}
                        editable={mode === 'annotate'}
                    />
                </ObjectFitContain>
            </AbsoluteBlock>
            {mode === 'windowing' && (
                <>
                    <AbsoluteBlock data-id="TouchableLayer Windowing">
                        <ObjectFitContain
                            objectWidth={imageSize.width}
                            objectHeight={imageSize.height}
                        >
                            <TouchableLayer
                                onDrag={(x1, y1, x2, y2, dx: number, dy: number) => {
                                    onWWWCChanged((state) => ({
                                        ww: state.ww + dx * 10,
                                        wc: state.wc + dy * 10,
                                    }));
                                }}
                            />
                        </ObjectFitContain>
                    </AbsoluteBlock>
                </>
            )}
            {mode === 'sharpening' && (
                <>
                    <AbsoluteBlock data-id="TouchableLayer Sharpening">
                        <ObjectFitContain
                            objectWidth={crop.w}
                            objectHeight={crop.h}
                        >
                            <TouchableLayer
                                onDrag={(x1, y1, x2, y2, dx: number) => {
                                    onSharpnessChanged((state) => state + dx * 0.01);
                                }}
                            />
                        </ObjectFitContain>
                    </AbsoluteBlock>
                </>
            )}
        </RCContainer>
    );
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  NotificationMessagePosted,
  NotificationMessageEdited,
  NotificationMessageDeleted,
  NotificationStudyUploaded,
  NotificationReportRequested,
  NotificationReportCompleted,
  NotificationPatientProfileChanged,
  NotificationPatientStatusChanged,
  NotificationPatientTreatingDoctorAdded,
  NotificationPatientTreatingDoctorRemoved,
  NotificationPatientInviteAccepted,
  NotificationProjectTaskAddAssignee,
} from '../../api/notifications/dto_notifications';

export const protobufPackage = 'com.diagnocat.notifications';

export interface Notification {
  ID: string;
  Time: string;
  Read: boolean;
  MessagePosted: NotificationMessagePosted | undefined;
  MessageEdited: NotificationMessageEdited | undefined;
  MessageDeleted: NotificationMessageDeleted | undefined;
  StudyUploaded: NotificationStudyUploaded | undefined;
  ReportRequested: NotificationReportRequested | undefined;
  ReportCompleted: NotificationReportCompleted | undefined;
  PatientProfileChanged: NotificationPatientProfileChanged | undefined;
  PatientStatusChanged: NotificationPatientStatusChanged | undefined;
  PatientTreatingDoctorAdded:
    | NotificationPatientTreatingDoctorAdded
    | undefined;
  PatientTreatingDoctorRemoved:
    | NotificationPatientTreatingDoctorRemoved
    | undefined;
  PatientInviteAccepted: NotificationPatientInviteAccepted | undefined;
  ProjectTaskAddAssignee: NotificationProjectTaskAddAssignee | undefined;
}

function createBaseNotification(): Notification {
  return {
    ID: '',
    Time: '',
    Read: false,
    MessagePosted: undefined,
    MessageEdited: undefined,
    MessageDeleted: undefined,
    StudyUploaded: undefined,
    ReportRequested: undefined,
    ReportCompleted: undefined,
    PatientProfileChanged: undefined,
    PatientStatusChanged: undefined,
    PatientTreatingDoctorAdded: undefined,
    PatientTreatingDoctorRemoved: undefined,
    PatientInviteAccepted: undefined,
    ProjectTaskAddAssignee: undefined,
  };
}

export const Notification = {
  encode(
    message: Notification,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Time !== '') {
      writer.uint32(18).string(message.Time);
    }
    if (message.Read === true) {
      writer.uint32(24).bool(message.Read);
    }
    if (message.MessagePosted !== undefined) {
      NotificationMessagePosted.encode(
        message.MessagePosted,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    if (message.MessageEdited !== undefined) {
      NotificationMessageEdited.encode(
        message.MessageEdited,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.MessageDeleted !== undefined) {
      NotificationMessageDeleted.encode(
        message.MessageDeleted,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.StudyUploaded !== undefined) {
      NotificationStudyUploaded.encode(
        message.StudyUploaded,
        writer.uint32(1602).fork(),
      ).ldelim();
    }
    if (message.ReportRequested !== undefined) {
      NotificationReportRequested.encode(
        message.ReportRequested,
        writer.uint32(2402).fork(),
      ).ldelim();
    }
    if (message.ReportCompleted !== undefined) {
      NotificationReportCompleted.encode(
        message.ReportCompleted,
        writer.uint32(2410).fork(),
      ).ldelim();
    }
    if (message.PatientProfileChanged !== undefined) {
      NotificationPatientProfileChanged.encode(
        message.PatientProfileChanged,
        writer.uint32(3202).fork(),
      ).ldelim();
    }
    if (message.PatientStatusChanged !== undefined) {
      NotificationPatientStatusChanged.encode(
        message.PatientStatusChanged,
        writer.uint32(3210).fork(),
      ).ldelim();
    }
    if (message.PatientTreatingDoctorAdded !== undefined) {
      NotificationPatientTreatingDoctorAdded.encode(
        message.PatientTreatingDoctorAdded,
        writer.uint32(3218).fork(),
      ).ldelim();
    }
    if (message.PatientTreatingDoctorRemoved !== undefined) {
      NotificationPatientTreatingDoctorRemoved.encode(
        message.PatientTreatingDoctorRemoved,
        writer.uint32(3226).fork(),
      ).ldelim();
    }
    if (message.PatientInviteAccepted !== undefined) {
      NotificationPatientInviteAccepted.encode(
        message.PatientInviteAccepted,
        writer.uint32(3234).fork(),
      ).ldelim();
    }
    if (message.ProjectTaskAddAssignee !== undefined) {
      NotificationProjectTaskAddAssignee.encode(
        message.ProjectTaskAddAssignee,
        writer.uint32(4002).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Notification {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Time = reader.string();
          break;
        case 3:
          message.Read = reader.bool();
          break;
        case 100:
          message.MessagePosted = NotificationMessagePosted.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.MessageEdited = NotificationMessageEdited.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.MessageDeleted = NotificationMessageDeleted.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 200:
          message.StudyUploaded = NotificationStudyUploaded.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 300:
          message.ReportRequested = NotificationReportRequested.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 301:
          message.ReportCompleted = NotificationReportCompleted.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 400:
          message.PatientProfileChanged =
            NotificationPatientProfileChanged.decode(reader, reader.uint32());
          break;
        case 401:
          message.PatientStatusChanged =
            NotificationPatientStatusChanged.decode(reader, reader.uint32());
          break;
        case 402:
          message.PatientTreatingDoctorAdded =
            NotificationPatientTreatingDoctorAdded.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 403:
          message.PatientTreatingDoctorRemoved =
            NotificationPatientTreatingDoctorRemoved.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 404:
          message.PatientInviteAccepted =
            NotificationPatientInviteAccepted.decode(reader, reader.uint32());
          break;
        case 500:
          message.ProjectTaskAddAssignee =
            NotificationProjectTaskAddAssignee.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Notification {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Time: isSet(object.Time) ? String(object.Time) : '',
      Read: isSet(object.Read) ? Boolean(object.Read) : false,
      MessagePosted: isSet(object.MessagePosted)
        ? NotificationMessagePosted.fromJSON(object.MessagePosted)
        : undefined,
      MessageEdited: isSet(object.MessageEdited)
        ? NotificationMessageEdited.fromJSON(object.MessageEdited)
        : undefined,
      MessageDeleted: isSet(object.MessageDeleted)
        ? NotificationMessageDeleted.fromJSON(object.MessageDeleted)
        : undefined,
      StudyUploaded: isSet(object.StudyUploaded)
        ? NotificationStudyUploaded.fromJSON(object.StudyUploaded)
        : undefined,
      ReportRequested: isSet(object.ReportRequested)
        ? NotificationReportRequested.fromJSON(object.ReportRequested)
        : undefined,
      ReportCompleted: isSet(object.ReportCompleted)
        ? NotificationReportCompleted.fromJSON(object.ReportCompleted)
        : undefined,
      PatientProfileChanged: isSet(object.PatientProfileChanged)
        ? NotificationPatientProfileChanged.fromJSON(
            object.PatientProfileChanged,
          )
        : undefined,
      PatientStatusChanged: isSet(object.PatientStatusChanged)
        ? NotificationPatientStatusChanged.fromJSON(object.PatientStatusChanged)
        : undefined,
      PatientTreatingDoctorAdded: isSet(object.PatientTreatingDoctorAdded)
        ? NotificationPatientTreatingDoctorAdded.fromJSON(
            object.PatientTreatingDoctorAdded,
          )
        : undefined,
      PatientTreatingDoctorRemoved: isSet(object.PatientTreatingDoctorRemoved)
        ? NotificationPatientTreatingDoctorRemoved.fromJSON(
            object.PatientTreatingDoctorRemoved,
          )
        : undefined,
      PatientInviteAccepted: isSet(object.PatientInviteAccepted)
        ? NotificationPatientInviteAccepted.fromJSON(
            object.PatientInviteAccepted,
          )
        : undefined,
      ProjectTaskAddAssignee: isSet(object.ProjectTaskAddAssignee)
        ? NotificationProjectTaskAddAssignee.fromJSON(
            object.ProjectTaskAddAssignee,
          )
        : undefined,
    };
  },

  toJSON(message: Notification): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Time !== undefined && (obj.Time = message.Time);
    message.Read !== undefined && (obj.Read = message.Read);
    message.MessagePosted !== undefined &&
      (obj.MessagePosted = message.MessagePosted
        ? NotificationMessagePosted.toJSON(message.MessagePosted)
        : undefined);
    message.MessageEdited !== undefined &&
      (obj.MessageEdited = message.MessageEdited
        ? NotificationMessageEdited.toJSON(message.MessageEdited)
        : undefined);
    message.MessageDeleted !== undefined &&
      (obj.MessageDeleted = message.MessageDeleted
        ? NotificationMessageDeleted.toJSON(message.MessageDeleted)
        : undefined);
    message.StudyUploaded !== undefined &&
      (obj.StudyUploaded = message.StudyUploaded
        ? NotificationStudyUploaded.toJSON(message.StudyUploaded)
        : undefined);
    message.ReportRequested !== undefined &&
      (obj.ReportRequested = message.ReportRequested
        ? NotificationReportRequested.toJSON(message.ReportRequested)
        : undefined);
    message.ReportCompleted !== undefined &&
      (obj.ReportCompleted = message.ReportCompleted
        ? NotificationReportCompleted.toJSON(message.ReportCompleted)
        : undefined);
    message.PatientProfileChanged !== undefined &&
      (obj.PatientProfileChanged = message.PatientProfileChanged
        ? NotificationPatientProfileChanged.toJSON(
            message.PatientProfileChanged,
          )
        : undefined);
    message.PatientStatusChanged !== undefined &&
      (obj.PatientStatusChanged = message.PatientStatusChanged
        ? NotificationPatientStatusChanged.toJSON(message.PatientStatusChanged)
        : undefined);
    message.PatientTreatingDoctorAdded !== undefined &&
      (obj.PatientTreatingDoctorAdded = message.PatientTreatingDoctorAdded
        ? NotificationPatientTreatingDoctorAdded.toJSON(
            message.PatientTreatingDoctorAdded,
          )
        : undefined);
    message.PatientTreatingDoctorRemoved !== undefined &&
      (obj.PatientTreatingDoctorRemoved = message.PatientTreatingDoctorRemoved
        ? NotificationPatientTreatingDoctorRemoved.toJSON(
            message.PatientTreatingDoctorRemoved,
          )
        : undefined);
    message.PatientInviteAccepted !== undefined &&
      (obj.PatientInviteAccepted = message.PatientInviteAccepted
        ? NotificationPatientInviteAccepted.toJSON(
            message.PatientInviteAccepted,
          )
        : undefined);
    message.ProjectTaskAddAssignee !== undefined &&
      (obj.ProjectTaskAddAssignee = message.ProjectTaskAddAssignee
        ? NotificationProjectTaskAddAssignee.toJSON(
            message.ProjectTaskAddAssignee,
          )
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Notification>, I>>(
    object: I,
  ): Notification {
    const message = createBaseNotification();
    message.ID = object.ID ?? '';
    message.Time = object.Time ?? '';
    message.Read = object.Read ?? false;
    message.MessagePosted =
      object.MessagePosted !== undefined && object.MessagePosted !== null
        ? NotificationMessagePosted.fromPartial(object.MessagePosted)
        : undefined;
    message.MessageEdited =
      object.MessageEdited !== undefined && object.MessageEdited !== null
        ? NotificationMessageEdited.fromPartial(object.MessageEdited)
        : undefined;
    message.MessageDeleted =
      object.MessageDeleted !== undefined && object.MessageDeleted !== null
        ? NotificationMessageDeleted.fromPartial(object.MessageDeleted)
        : undefined;
    message.StudyUploaded =
      object.StudyUploaded !== undefined && object.StudyUploaded !== null
        ? NotificationStudyUploaded.fromPartial(object.StudyUploaded)
        : undefined;
    message.ReportRequested =
      object.ReportRequested !== undefined && object.ReportRequested !== null
        ? NotificationReportRequested.fromPartial(object.ReportRequested)
        : undefined;
    message.ReportCompleted =
      object.ReportCompleted !== undefined && object.ReportCompleted !== null
        ? NotificationReportCompleted.fromPartial(object.ReportCompleted)
        : undefined;
    message.PatientProfileChanged =
      object.PatientProfileChanged !== undefined &&
      object.PatientProfileChanged !== null
        ? NotificationPatientProfileChanged.fromPartial(
            object.PatientProfileChanged,
          )
        : undefined;
    message.PatientStatusChanged =
      object.PatientStatusChanged !== undefined &&
      object.PatientStatusChanged !== null
        ? NotificationPatientStatusChanged.fromPartial(
            object.PatientStatusChanged,
          )
        : undefined;
    message.PatientTreatingDoctorAdded =
      object.PatientTreatingDoctorAdded !== undefined &&
      object.PatientTreatingDoctorAdded !== null
        ? NotificationPatientTreatingDoctorAdded.fromPartial(
            object.PatientTreatingDoctorAdded,
          )
        : undefined;
    message.PatientTreatingDoctorRemoved =
      object.PatientTreatingDoctorRemoved !== undefined &&
      object.PatientTreatingDoctorRemoved !== null
        ? NotificationPatientTreatingDoctorRemoved.fromPartial(
            object.PatientTreatingDoctorRemoved,
          )
        : undefined;
    message.PatientInviteAccepted =
      object.PatientInviteAccepted !== undefined &&
      object.PatientInviteAccepted !== null
        ? NotificationPatientInviteAccepted.fromPartial(
            object.PatientInviteAccepted,
          )
        : undefined;
    message.ProjectTaskAddAssignee =
      object.ProjectTaskAddAssignee !== undefined &&
      object.ProjectTaskAddAssignee !== null
        ? NotificationProjectTaskAddAssignee.fromPartial(
            object.ProjectTaskAddAssignee,
          )
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

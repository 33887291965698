import { useState } from 'react';

import { useModal } from '@/shared/hooks';

export const useRemoveStudyModal = () => {
  const [currentStudyID, setCurrentStudyID] = useState<string>();

  const { isOpen, openModal, closeModal } = useModal();

  const openRemoveStudyModal = (studyID: string) => {
    setCurrentStudyID(studyID);
    openModal();
  };

  const closeRemoveStudyModal = () => {
    setCurrentStudyID(undefined);
    closeModal();
  };

  return {
    currentStudyID,
    isRemoveStudyModalOpen: isOpen,
    openRemoveStudyModal,
    closeRemoveStudyModal,
  };
};

import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { generatePath, useLocation, useNavigate } from 'react-router';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { useHubspotEvents } from '@/shared/hooks/useHubspotEvents';

import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';
import { authModel } from '@/entities/auth';

import { useSyncOrganizationID } from './useSyncOrganizationID';

export const useUserDataStream = (invitationToken: string | undefined) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setIdentity, trackPage } = useHubspotEvents();

  const userDataStream = useRef<Subscription | null>(null);

  const openUserDataStream = () => {
    dispatch(organizationModel.actions.setLoading('pending'));
    dispatch(userModel.actions.setLoading('pending'));

    userDataStream.current = api.user.MyDataStream({}).subscribe({
      next: (data) => {
        if (data.HistoricalMe) {
          // Hubspot events to set identity and track page view
          setIdentity({
            email: data?.HistoricalMe?.PersonalData?.Emails[0] ?? '',
            options: {
              id: data.HistoricalMe.ID,
            },
          });
          trackPage(window.location.href);

          dispatch(userModel.actions.addUser(data.HistoricalMe));
          dispatch(userModel.actions.setLoading('succeeded'));
        }

        if (data.UpdatedMe) {
          dispatch(userModel.actions.addUser(data.UpdatedMe));
        }

        if (data.HistoricalOrganizations) {
          dispatch(
            organizationModel.actions.setNewestMany(
              data.HistoricalOrganizations.Organizations,
            ),
          );
          // There is no EndOfHistoricalOrganizations in the API
          dispatch(organizationModel.actions.setLoading('succeeded'));
        }

        if (data.UpdatedOrganization) {
          dispatch(
            organizationModel.actions.setNewestOne(data.UpdatedOrganization),
          );
        }
      },
      error: async (error) => {
        // eslint-disable-next-line no-console
        console.error(
          'MyDataStream:: error: ',
          error.message,
          error.code,
          error.type,
          error,
        );

        // TODO: [2/m] think about how to improve navigation
        if (error.type === 'UnauthenticatedError') {
          if (invitationToken) {
            const { IsAlreadyRegistered } = await dispatch(
              authModel.thunks.checkInviteToken({ Token: invitationToken }),
            ).unwrap();

            if (IsAlreadyRegistered) {
              navigate(PATHS.signIn, {
                state: {
                  from: generatePath(PATHS.organization_invite, {
                    token: invitationToken,
                  }),
                },
              });
            } else {
              navigate(PATHS.signUp, { state: { invitationToken } });
            }
          } else {
            navigate(PATHS.signIn, { state: { from: location?.pathname } });
          }
        }
      },

      complete: () => {
        // Do nothing
      },
    });
  };

  const closeUserDataStream = () => {
    if (userDataStream.current) {
      userDataStream.current.unsubscribe();
    }
  };

  // verify available current organization and sync with localStorage
  useSyncOrganizationID();

  useEffect(() => {
    openUserDataStream();

    return () => {
      closeUserDataStream();
    };
  }, []);
};

import { RootState } from '@/app/model/store';

import { allowedToothConditionsAdapter } from './allowedToothConditionsSlice';

export const allowedToothConditionsSelectors =
  allowedToothConditionsAdapter.getSelectors(
    (state: RootState) => state.allowedToothConditions,
  );

export const { selectIds, selectEntities, selectTotal, selectById, selectAll } =
  allowedToothConditionsSelectors;

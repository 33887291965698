import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import logo from '@/shared/assets/logo.svg';

import styles from './PreviewFooter.module.scss';

type PreviewFooterProps = {
  text: string;
  date: string;
  blackAndWhiteMode?: boolean;
};

export const PreviewFooter: FC<PreviewFooterProps> = (props) => {
  const { text, date, blackAndWhiteMode } = props;

  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <p className={cn(styles.text, 'p4')}>{text}</p>

      <div className={styles.rightSide}>
        <img
          src={logo}
          width={100}
          alt={formatMessage({
            id: 'imgAltText.diagnocat',
            defaultMessage: 'Diagnocat',
          })}
          className={cn({ [styles.blackImg]: blackAndWhiteMode })}
        />
        <p className={styles.date}>{date}</p>
      </div>
    </div>
  );
};

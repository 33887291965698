import { CBCTSectionOrientation } from '@/shared/api/protocol_gen/model/dto_asset_common';
import { FormatMessageFunction } from '@/shared/config';

import { guideAxesNames } from '../config/i18n';

export const getGuideAxesName = (
  orient: CBCTSectionOrientation,
  formatMessage: FormatMessageFunction,
) => {
  switch (orient) {
    case CBCTSectionOrientation.SectionOrientationInvalidValue:
    case CBCTSectionOrientation.SectionOrientationAxial:
      return formatMessage(guideAxesNames.SectionOrientationAxial);
    case CBCTSectionOrientation.SectionOrientationVestibuloOral:
      return formatMessage(guideAxesNames.SectionOrientationVestibuloOral);
    case CBCTSectionOrientation.SectionOrientationMesioDistal:
      return formatMessage(guideAxesNames.SectionOrientationMesioDistal);
    default:
      return '';
  }
};

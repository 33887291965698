import { ObjectFitContaintContextType } from "../ObjectFitContain";

/**
 * svg viewBox and it's elements lives is a logical space (data pixels)
 * while the svg element itlself lives in a css space (screen pixels)
 * The requirements is to keep annotations (which are svg elements)
 * the same size regardless of the aspect between css size and viewBox logical size
 * 
 * Imagine a 500x500 picture placed in a 100x100 div
 * 500x500 is a logical size
 * 100x100 is a css size
 * Your picure pixel will be x5 times smaller than your physical screen pixel
 * 
 * While placing an svg element inside
 * to make it, for example, half of a container we want to set it's width to 50
 * but the correct value is 250
 * 
 * cssToLogical(50, objectFit) === 250
 * 
 * 
 * @param value - The CSS value to convert.
 * @param objectFit - The object fit context to use for the conversion.
 * @returns The converted logical value.
 */
export const cssToLogical = (value: number, objectFit: ObjectFitContaintContextType) => value * objectFit.objectWidth / objectFit.width
import { CSSProperties, FC } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';
import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';

import { LocalizationWithISO, getDisplayToothNumber } from '@/entities/tooth';

import styles from './ImageWithTeethNumbers.module.scss';

type ImageWithTeethNumbersProps = {
  className?: string;
  testID?: string;
  onToothClick: (
    viewedISONumbers: number[],
    localization: LocalizationWithISO,
  ) => void;
  localizations: LocalizationWithISO[];
  src: string;
  dentalNotation: DentalNotationFormat;
  scale: number;
  style?: CSSProperties;
};

export const ImageWithTeethNumbers: FC<ImageWithTeethNumbersProps> = (
  props,
) => {
  const {
    className,
    testID,
    onToothClick,
    localizations = [],
    src,
    dentalNotation = DentalNotationFormat.DentalNotationFormatISO,
    scale,
    style,
  } = props;

  const { duplicatedISONumbers, viewedISONumbers } = localizations.reduce(
    (accum, localization) => {
      if (
        localization.ISONumber !== 404 &&
        localizations.some(
          (item) =>
            item.ISONumber === localization.ISONumber &&
            item.SupernumeraryIndex === localization.SupernumeraryIndex &&
            item.ID !== localization.ID,
        )
      ) {
        accum.duplicatedISONumbers.push(localization.ISONumber);
      } else {
        accum.viewedISONumbers.push(localization.ISONumber);
      }

      return accum;
    },
    { duplicatedISONumbers: [], viewedISONumbers: [] } as {
      duplicatedISONumbers: number[];
      viewedISONumbers: number[];
    },
  );

  return (
    <div
      className={cn(styles.container, className)}
      data-testid={testID}
      style={style}
    >
      <img className={styles.image} src={src} />
      {localizations.map((localization) => {
        const Xmin = localization.BBox?.X?.Min as number | 0;
        const Xmax = localization.BBox?.X?.Max as number | 0;
        const Ymin = localization.BBox?.Y?.Min as number | 0;
        const Ymax = localization.BBox?.Y?.Max as number | 0;

        const centroidX = Xmin + (Xmax - Xmin) / 2;
        const centroidY = Ymin + (Ymax - Ymin) / 2;

        const isDuplicated = duplicatedISONumbers.includes(
          localization.ISONumber,
        );

        return (
          <div
            onClick={() => {
              onToothClick(viewedISONumbers, localization);
            }}
            key={localization.ID}
            className={cn(
              styles.centroid,
              isDuplicated ? styles.duplicated : '',
            )}
            style={{
              left: centroidX * scale,
              top: centroidY * scale,
            }}
          >
            {localization.ISONumber === 404 ? (
              <Icon name="close" size={20} />
            ) : (
              getDisplayToothNumber(localization.ISONumber, dentalNotation)
            )}
          </div>
        );
      })}
    </div>
  );
};

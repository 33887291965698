/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ConditionCode,
  conditionCodeFromJSON,
  conditionCodeToJSON,
} from '../model/dto_report_condition_codes';
import { Certainty } from '../model/dto_report_certainty';
import { Localization } from '../model/dto_report_localization';
import { Created, Revision, Deleted } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

/**
 * //////////////////////////////////////////////////////////////
 * //  Condition
 * //
 * //  Medical condition. That is.
 * //
 * //  Note: Either `ParentID` or `Localizations` are present. Reason for not
 * //  not making it `oneof` is high frequency of `Localizations` field usage.
 */
export interface Condition {
  ID: string;
  ReportID: string;
  PatientID: string;
  OrganizationID: string;
  ParentID: string;
  ParentCode: ConditionCode;
  /** `ConditionCode`: [required] */
  Code: ConditionCode;
  /** `Certainty`: [required] */
  Certainty: Certainty | undefined;
  Localizations: Localization[];
  Tooth: Condition_ToothAttribution | undefined;
  MaxFax: Condition_MaxFaxAttribution | undefined;
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

export interface Condition_ToothAttribution {
  ToothID: string;
  /** only between pipelines and backend */
  ToothLocalizationID: string;
}

export interface Condition_MaxFaxAttribution {}

function createBaseCondition(): Condition {
  return {
    ID: '',
    ReportID: '',
    PatientID: '',
    OrganizationID: '',
    ParentID: '',
    ParentCode: 0,
    Code: 0,
    Certainty: undefined,
    Localizations: [],
    Tooth: undefined,
    MaxFax: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Condition = {
  encode(
    message: Condition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.ReportID !== '') {
      writer.uint32(18).string(message.ReportID);
    }
    if (message.PatientID !== '') {
      writer.uint32(26).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(34).string(message.OrganizationID);
    }
    if (message.ParentID !== '') {
      writer.uint32(42).string(message.ParentID);
    }
    if (message.ParentCode !== 0) {
      writer.uint32(48).int32(message.ParentCode);
    }
    if (message.Code !== 0) {
      writer.uint32(80).int32(message.Code);
    }
    if (message.Certainty !== undefined) {
      Certainty.encode(message.Certainty, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.Localizations) {
      Localization.encode(v!, writer.uint32(1610).fork()).ldelim();
    }
    if (message.Tooth !== undefined) {
      Condition_ToothAttribution.encode(
        message.Tooth,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    if (message.MaxFax !== undefined) {
      Condition_MaxFaxAttribution.encode(
        message.MaxFax,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Condition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCondition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.ReportID = reader.string();
          break;
        case 3:
          message.PatientID = reader.string();
          break;
        case 4:
          message.OrganizationID = reader.string();
          break;
        case 5:
          message.ParentID = reader.string();
          break;
        case 6:
          message.ParentCode = reader.int32() as any;
          break;
        case 10:
          message.Code = reader.int32() as any;
          break;
        case 11:
          message.Certainty = Certainty.decode(reader, reader.uint32());
          break;
        case 201:
          message.Localizations.push(
            Localization.decode(reader, reader.uint32()),
          );
          break;
        case 100:
          message.Tooth = Condition_ToothAttribution.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.MaxFax = Condition_MaxFaxAttribution.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Condition {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      ParentID: isSet(object.ParentID) ? String(object.ParentID) : '',
      ParentCode: isSet(object.ParentCode)
        ? conditionCodeFromJSON(object.ParentCode)
        : 0,
      Code: isSet(object.Code) ? conditionCodeFromJSON(object.Code) : 0,
      Certainty: isSet(object.Certainty)
        ? Certainty.fromJSON(object.Certainty)
        : undefined,
      Localizations: Array.isArray(object?.Localizations)
        ? object.Localizations.map((e: any) => Localization.fromJSON(e))
        : [],
      Tooth: isSet(object.Tooth)
        ? Condition_ToothAttribution.fromJSON(object.Tooth)
        : undefined,
      MaxFax: isSet(object.MaxFax)
        ? Condition_MaxFaxAttribution.fromJSON(object.MaxFax)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Condition): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.ParentID !== undefined && (obj.ParentID = message.ParentID);
    message.ParentCode !== undefined &&
      (obj.ParentCode = conditionCodeToJSON(message.ParentCode));
    message.Code !== undefined &&
      (obj.Code = conditionCodeToJSON(message.Code));
    message.Certainty !== undefined &&
      (obj.Certainty = message.Certainty
        ? Certainty.toJSON(message.Certainty)
        : undefined);
    if (message.Localizations) {
      obj.Localizations = message.Localizations.map((e) =>
        e ? Localization.toJSON(e) : undefined,
      );
    } else {
      obj.Localizations = [];
    }
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth
        ? Condition_ToothAttribution.toJSON(message.Tooth)
        : undefined);
    message.MaxFax !== undefined &&
      (obj.MaxFax = message.MaxFax
        ? Condition_MaxFaxAttribution.toJSON(message.MaxFax)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Condition>, I>>(
    object: I,
  ): Condition {
    const message = createBaseCondition();
    message.ID = object.ID ?? '';
    message.ReportID = object.ReportID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.ParentID = object.ParentID ?? '';
    message.ParentCode = object.ParentCode ?? 0;
    message.Code = object.Code ?? 0;
    message.Certainty =
      object.Certainty !== undefined && object.Certainty !== null
        ? Certainty.fromPartial(object.Certainty)
        : undefined;
    message.Localizations =
      object.Localizations?.map((e) => Localization.fromPartial(e)) || [];
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Condition_ToothAttribution.fromPartial(object.Tooth)
        : undefined;
    message.MaxFax =
      object.MaxFax !== undefined && object.MaxFax !== null
        ? Condition_MaxFaxAttribution.fromPartial(object.MaxFax)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseCondition_ToothAttribution(): Condition_ToothAttribution {
  return { ToothID: '', ToothLocalizationID: '' };
}

export const Condition_ToothAttribution = {
  encode(
    message: Condition_ToothAttribution,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.ToothLocalizationID !== '') {
      writer.uint32(18).string(message.ToothLocalizationID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Condition_ToothAttribution {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCondition_ToothAttribution();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.ToothLocalizationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Condition_ToothAttribution {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      ToothLocalizationID: isSet(object.ToothLocalizationID)
        ? String(object.ToothLocalizationID)
        : '',
    };
  },

  toJSON(message: Condition_ToothAttribution): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.ToothLocalizationID !== undefined &&
      (obj.ToothLocalizationID = message.ToothLocalizationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Condition_ToothAttribution>, I>>(
    object: I,
  ): Condition_ToothAttribution {
    const message = createBaseCondition_ToothAttribution();
    message.ToothID = object.ToothID ?? '';
    message.ToothLocalizationID = object.ToothLocalizationID ?? '';
    return message;
  },
};

function createBaseCondition_MaxFaxAttribution(): Condition_MaxFaxAttribution {
  return {};
}

export const Condition_MaxFaxAttribution = {
  encode(
    _: Condition_MaxFaxAttribution,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Condition_MaxFaxAttribution {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCondition_MaxFaxAttribution();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): Condition_MaxFaxAttribution {
    return {};
  },

  toJSON(_: Condition_MaxFaxAttribution): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Condition_MaxFaxAttribution>, I>>(
    _: I,
  ): Condition_MaxFaxAttribution {
    const message = createBaseCondition_MaxFaxAttribution();
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

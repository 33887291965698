import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';

import {
  MedicalImageToolbar,
  ToolbarOptions,
} from '@/features/MedilcalImageToolbar';

export const Toolbar = () => {
  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const isInvert = currentReport?.MedicalImageFeatures?.ViewOptions?.Invert;

  const controlsOptions: ToolbarOptions[] = [
    {
      control: 'expand',
    },
    {
      control: 'split',
    },
    {
      control: 'sharpness',
    },
    {
      control: 'brightness',
    },
    {
      control: 'invert',
      isActive: isInvert,
      onClick: async () => {
        MedicalImageRender.toggleInversion();

        const { Report } = await dispatch(
          reportsModel.thunks.setReportMedicalImageViewOptions({
            ReportID: currentReport?.ID as string,
            MedicalImageViewOptions: {
              ...(currentReport?.MedicalImageFeatures
                ?.ViewOptions as MedicalImageViewOptions),
              Invert: !isInvert,
            },
          }),
        ).unwrap();

        if (Report) {
          dispatch(reportsModel.actions.setNewestOne(Report));
        }
      },
    },
    {
      control: 'reset',
      notToggle: true,
    },
    {
      control: 'editNumbers',
      notToggle: true,
      onClick: () => {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.TeethNumberingModal,
            data: {},
          }),
        );
      },
    },
  ];

  return (
    <MedicalImageToolbar
      defaultControl="view"
      controlsOptions={controlsOptions}
    />
  );
};

import { FC } from 'react';
import cn from 'classnames';

import styles from './FooterDate.module.scss';

type FooterDateProps = {
  date: string;
  label: string;
  className?: string;
  blackAndWhiteMode?: boolean;
};

export const FooterDate: FC<FooterDateProps> = (props) => {
  const { date, label, className, blackAndWhiteMode } = props;

  return (
    <div className={cn(styles.container, className)}>
      <p
        className={cn(
          styles.descriptionLabel,
          { [styles.blackDescriptionLabel]: blackAndWhiteMode },
          'p3t',
        )}
      >
        {label}
      </p>

      <p className="p2">{date}</p>
    </div>
  );
};

export const getMaskByLocale = (locale: string) => {
  switch (locale) {
    case 'ru':
      return '99.99.9999';

    case 'en-GB':
      return '99/99/9999';

    case 'zh-CN':
      return '9999-99-99';

    default:
      return '99-99-9999';
  }
};

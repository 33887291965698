import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {} from '@/shared/api/protocol_gen/api/marketing/svc_questionnaire';
import { OrganizationOnboardingQuestionnaireV1 } from '@/shared/api/protocol_gen/api/marketing/dto_questionnaire';
import { SliceName } from '@/shared/config';

import { MarketingInitialState } from '../config/types';

const initialState: MarketingInitialState = {
  questionnaire: {
    OwnerSpecialization: '',
    CompanyProfile: '',
    CBCTMachineType: [],
    CBCTImagingSoftware: [],
    XRayImagingSoftware: [],
    FocusOfInterest: [],
  },
  loading: 'idle',
};

const marketingSlice = createSlice({
  name: SliceName.marketing,
  initialState,
  reducers: {
    updateQuestionnaire: (
      state,
      action: PayloadAction<OrganizationOnboardingQuestionnaireV1>,
    ) => {
      state.questionnaire = action.payload;
    },
  },
});

export const { actions } = marketingSlice;

export default marketingSlice.reducer;

import { FC } from 'react';
import cn from 'classnames';

import { BasicSkeleton } from '../BasicSkeleton/BasicSkeleton';

import styles from './SkeletonFilter.module.scss';

type SkeletonFilterProps = {
  className?: string;
  testID?: string;
  filtersQuantity?: number;
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
  gap?: number;
};

export const SkeletonFilter: FC<SkeletonFilterProps> = (props) => {
  const {
    className,
    testID,
    filtersQuantity = 6,
    width = '100%',
    height = '20px',
    borderRadius = '28px',
    gap,
  } = props;

  const filtersQuantityArray: number[] = Array(filtersQuantity).fill(0);

  return (
    <div
      className={cn(styles.container, className)}
      style={{ gap }}
      data-testid={testID}
    >
      {filtersQuantityArray.map((element, index) => (
        <BasicSkeleton
          width={width}
          height={height}
          borderRadius={borderRadius}
          key={(element + index).toString()}
        />
      ))}
    </div>
  );
};

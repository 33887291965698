import { FC, ReactNode, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { Button, Spinner } from '@/shared/ui';
import imageBroken from '@/shared/assets/images/imagebroken.png';

import styles from './UploadDropzone.module.scss';

type UploadDropzoneProps = {
  onChange: (image: File | FileWithPath) => void;
  error?: string;
  loading?: boolean;
  className?: string;
  description?: ReactNode;
};
export const UploadDropzone: FC<UploadDropzoneProps> = (props) => {
  const {
    onChange,
    error,
    loading,
    className,
    description = (
      <FormattedMessage
        id="global.dragDropPhotos"
        defaultMessage="Drag image or add from your computer"
      />
    ),
  } = props;

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      onChange(acceptedFiles[0]);
    },
    [onChange],
  );

  const { getInputProps, getRootProps, isFocused } = useDropzone({
    onDrop,
  });

  const dropzoneDescription = error || description;

  return (
    <div
      {...getRootProps()}
      className={cn(
        styles.container,
        {
          [styles.focused]: isFocused,
          [styles.notDashed]: !!loading || !!error,
        },
        className,
      )}
    >
      <input {...getInputProps()} />

      {loading ? (
        <Spinner primary size={48} />
      ) : (
        <div className={cn(styles.content, { [styles.errorContent]: !!error })}>
          <img
            className={cn(styles.brokenImage, { [styles.visible]: !!error })}
            src={imageBroken}
            alt=""
          />

          <p
            className={cn(
              styles.description,
              { [styles.errorDescription]: !!error },
              error ? 'p3' : 'p2',
            )}
          >
            {dropzoneDescription}
          </p>

          <Button
            size="medium"
            variant="tertiary"
            className={cn('p2', styles.loadAgain)}
          >
            <FormattedMessage
              id="uploadImage.loadAgain"
              defaultMessage="Load again"
            />
          </Button>
        </div>
      )}
    </div>
  );
};

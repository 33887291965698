import { Outlet, useParams } from 'react-router';

import { useReportDataStream } from '../../hooks/useReportDataStream';

export const ReportLayout = () => {
  const { reportID = '' } = useParams();

  useReportDataStream(reportID);

  return <Outlet />;
};

export const Preview2DReportLayout = () => {
  const { reportID = '' } = useParams();

  useReportDataStream(reportID);

  return <Outlet key="preview-2d" />;
};

import { RawDraftContentBlock } from 'draft-js';

import { addInlineStyles } from '@/widgets/Conclusion/lib/addInlineStyles';

import { isJsonRegex } from '../config';

import { reformatHTMLForDeletedText } from './reformatHTMLForDeletedText';
import { getTextTagsFromHTML } from './getTextTagsFromHTML';

export const getHTMLWithStyles = (conclusion: string, html: string) => {
  const modifiedTags = reformatHTMLForDeletedText(html);
  const tagsList = getTextTagsFromHTML(modifiedTags);
  const isRTF = conclusion.match(isJsonRegex);

  if (conclusion && tagsList.length) {
    if (isRTF) {
      const parsedConclusion = JSON.parse(conclusion);

      const tagsWithStyles = parsedConclusion.blocks.map(
        (block: RawDraftContentBlock, index: number) => {
          if (!block?.data) {
            return '';
          }

          const currentTag = tagsList[index];
          switch (block.data['text-align']) {
            case 'center':
              return addInlineStyles(currentTag, 'center');
            case 'right':
              return addInlineStyles(currentTag, 'right');
            case 'justify':
              return addInlineStyles(currentTag, 'justify');
            case 'left':
            default:
              return addInlineStyles(currentTag, 'left');
          }
        },
      );

      return tagsWithStyles.join('');
    }
    return conclusion;
  }

  return '';
};

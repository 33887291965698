import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import segmentronPreview from '@/shared/assets/images/segmentronPreview.png';
import { Button, Icon } from '@/shared/ui';

import { StructuredReportsAndStudiesType } from '@/features/reportCards';

import { ReportGenerationStatus } from '../ReportGenerationStatus/ReportGenerationStatus';
import { reportCardTitle } from '../../config';
import { DetailsPopover } from '../DetailsPopover/DetailsPopover';
import { useReportCard } from '../../hooks';

import styles from './ReportCard.module.scss';

type ReportCardProps = {
  structuredReportsAndStudies: StructuredReportsAndStudiesType;
  className?: string;
  testID?: string;
  onViewDetails: (studyID: string) => void;
  onRemoveReport: (reportIDs: string[]) => void;
};

export const ReportCard: FC<ReportCardProps> = (props) => {
  const {
    structuredReportsAndStudies,
    className,
    testID,
    onViewDetails,
    onRemoveReport,
  } = props;
  const { formatMessage, formatDate } = useIntl();

  const {
    isReportGenerationCompleted,
    isIOXRayOrPanowings,
    isSegmentronReport,
    reportType,
    previewImageSrc,
    relevantStudy,
    activeReportsIDs,
    date,
    report,
    isReportFailed,
    isReportSigned,
    isReportOrderAvailable,
    isLoadingPDF,
    handleCardClick,
    reorderReport,
    downloadPDF,
  } = useReportCard(structuredReportsAndStudies);

  const backgroundColorStyle = isReportGenerationCompleted
    ? styles.blackBackground
    : styles.whiteBackground;

  const textColorStyle = isReportGenerationCompleted
    ? styles.textWhite
    : styles.textBlack;

  return (
    <div
      className={cn(
        styles.container,
        isReportGenerationCompleted && styles.reportGenerationCompleted,
        backgroundColorStyle,
        className,
      )}
      data-testid={testID}
      onClick={handleCardClick}
    >
      {isReportGenerationCompleted && reportType && (
        <img
          className={cn(
            styles.previewImage,
            isIOXRayOrPanowings && styles.IOXRayOrPanowingsPreview,
          )}
          src={isSegmentronReport ? segmentronPreview : previewImageSrc}
          alt={formatMessage(reportCardTitle[reportType])}
        />
      )}

      <header className={cn(styles.header, textColorStyle)}>
        <h4 className={cn(styles.title, textColorStyle, 'h4')}>
          {formatMessage(reportCardTitle[reportType])}

          <DetailsPopover
            isReportGenerationCompleted={Boolean(isReportGenerationCompleted)}
            isLoadingPDF={isLoadingPDF}
            onDetailsView={() => {
              onViewDetails(relevantStudy?.ID ?? '');
            }}
            onRemoveReport={() => {
              onRemoveReport(activeReportsIDs);
            }}
            onDownloadReport={downloadPDF}
          />
        </h4>

        {date
          ? formatDate(date, {
              dateStyle: 'medium',
            })
          : formatMessage({
              id: 'reportCard.unknownDate',
              defaultMessage: 'Unknown date',
            })}
      </header>

      {!isReportGenerationCompleted && (
        <main className={styles.content}>
          <ReportGenerationStatus report={report} />
        </main>
      )}

      {(isReportSigned || isReportFailed) && (
        <footer className={cn(styles.footer, 'p2')}>
          {isReportSigned && (
            <div className={styles.signed}>
              <Icon name="check" size={24} />

              <FormattedMessage
                id="reportCard.signed"
                defaultMessage="Signed"
              />
            </div>
          )}

          {isReportFailed && (
            <Button
              variant="secondary"
              onClick={(event) => {
                event.stopPropagation();

                reorderReport();
              }}
              size="medium"
              className={styles.reorder}
              disabled={isReportOrderAvailable}
            >
              <FormattedMessage
                id="reportCard.reorder"
                defaultMessage="Reorder"
              />
            </Button>
          )}
        </footer>
      )}
    </div>
  );
};

import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';

export const makeDetectionsFromDeletedToothLocalizations = (
  localizations: Localization[],
) => {
  if (!localizations.length) {
    return [];
  }

  return localizations.map((localization) => {
    const { BBox } = localization;

    return {
      tooth: {
        toothID: localization.ID,
        ISONumber: 404,
        Localization: localization,
      },
      xmin: BBox?.X?.Min ?? 0,
      ymin: BBox?.Y?.Min ?? 0,
      xmax: BBox?.X?.Max ?? 0,
      ymax: BBox?.Y?.Max ?? 0,
    };
  });
};

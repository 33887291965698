/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  Decision,
  decisionFromJSON,
  decisionToJSON,
} from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

/** TMP: Think of better naming (?) */
export interface Certainty {
  ModelDecision: Decision;
  ModelScore: number;
  IsModelScoreSet: boolean;
  UserDecision: Decision;
  /** Post-processing of model decision and user decision by diagnosis engine. */
  EngineDecision: Decision;
}

function createBaseCertainty(): Certainty {
  return {
    ModelDecision: 0,
    ModelScore: 0,
    IsModelScoreSet: false,
    UserDecision: 0,
    EngineDecision: 0,
  };
}

export const Certainty = {
  encode(
    message: Certainty,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ModelDecision !== 0) {
      writer.uint32(8).int32(message.ModelDecision);
    }
    if (message.ModelScore !== 0) {
      writer.uint32(21).float(message.ModelScore);
    }
    if (message.IsModelScoreSet === true) {
      writer.uint32(24).bool(message.IsModelScoreSet);
    }
    if (message.UserDecision !== 0) {
      writer.uint32(32).int32(message.UserDecision);
    }
    if (message.EngineDecision !== 0) {
      writer.uint32(40).int32(message.EngineDecision);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Certainty {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCertainty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ModelDecision = reader.int32() as any;
          break;
        case 2:
          message.ModelScore = reader.float();
          break;
        case 3:
          message.IsModelScoreSet = reader.bool();
          break;
        case 4:
          message.UserDecision = reader.int32() as any;
          break;
        case 5:
          message.EngineDecision = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Certainty {
    return {
      ModelDecision: isSet(object.ModelDecision)
        ? decisionFromJSON(object.ModelDecision)
        : 0,
      ModelScore: isSet(object.ModelScore) ? Number(object.ModelScore) : 0,
      IsModelScoreSet: isSet(object.IsModelScoreSet)
        ? Boolean(object.IsModelScoreSet)
        : false,
      UserDecision: isSet(object.UserDecision)
        ? decisionFromJSON(object.UserDecision)
        : 0,
      EngineDecision: isSet(object.EngineDecision)
        ? decisionFromJSON(object.EngineDecision)
        : 0,
    };
  },

  toJSON(message: Certainty): unknown {
    const obj: any = {};
    message.ModelDecision !== undefined &&
      (obj.ModelDecision = decisionToJSON(message.ModelDecision));
    message.ModelScore !== undefined && (obj.ModelScore = message.ModelScore);
    message.IsModelScoreSet !== undefined &&
      (obj.IsModelScoreSet = message.IsModelScoreSet);
    message.UserDecision !== undefined &&
      (obj.UserDecision = decisionToJSON(message.UserDecision));
    message.EngineDecision !== undefined &&
      (obj.EngineDecision = decisionToJSON(message.EngineDecision));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Certainty>, I>>(
    object: I,
  ): Certainty {
    const message = createBaseCertainty();
    message.ModelDecision = object.ModelDecision ?? 0;
    message.ModelScore = object.ModelScore ?? 0;
    message.IsModelScoreSet = object.IsModelScoreSet ?? false;
    message.UserDecision = object.UserDecision ?? 0;
    message.EngineDecision = object.EngineDecision ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export type vec2 = { x: number, y: number }

export const vec2 = (x: number, y: number) => ({ x, y })
export const add = (a: vec2, b: vec2) => ({ x: a.x + b.x, y: a.y + b.y })
export const sub = (a: vec2, b: vec2) => ({ x: a.x - b.x, y: a.y - b.y })
export const len = (v: vec2) => Math.sqrt(v.x * v.x + v.y * v.y)
export const scl = (v: vec2, s: number) => ({ x: v.x * s, y: v.y * s })
export const norm = (v: vec2) => {
	const vlen = len(v)
	return { x: v.x / vlen, y: v.y / vlen }
}
export const dot = (a: vec2, b: vec2) => a.x * b.x + a.y * b.y
export const cross = (a: vec2, b: vec2) => a.x * b.y - a.y * b.x
// n means both input should be normalized
export const angle = (nfrom: vec2, nto: vec2): number => {


	const theta = dot(nfrom, nto);

	// clamp, to handle numerical problems

	return Math.acos(theta);
}
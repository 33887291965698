/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Localization } from '../model/dto_report_localization';
import {
  IOXRayToothAnatomy,
  PanoToothAnatomy,
  iOXRayToothAnatomyFromJSON,
  panoToothAnatomyFromJSON,
  iOXRayToothAnatomyToJSON,
  panoToothAnatomyToJSON,
} from '../model/dto_report_type_2D_common';
import {
  CBCTToothAnatomy,
  cBCTToothAnatomyFromJSON,
  cBCTToothAnatomyToJSON,
} from '../model/dto_common_anatomy';

export const protobufPackage = 'com.diagnocat.model';

export interface ToothAnatomyLocalization {
  /** ToothLocalizationID: [required] */
  ToothLocalizationID: string;
  /** Localization: [required] */
  Localization: Localization | undefined;
  IOXRay: IOXRayToothAnatomy | undefined;
  Pano: PanoToothAnatomy | undefined;
  CBCT: CBCTToothAnatomy | undefined;
}

function createBaseToothAnatomyLocalization(): ToothAnatomyLocalization {
  return {
    ToothLocalizationID: '',
    Localization: undefined,
    IOXRay: undefined,
    Pano: undefined,
    CBCT: undefined,
  };
}

export const ToothAnatomyLocalization = {
  encode(
    message: ToothAnatomyLocalization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothLocalizationID !== '') {
      writer.uint32(10).string(message.ToothLocalizationID);
    }
    if (message.Localization !== undefined) {
      Localization.encode(
        message.Localization,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.IOXRay !== undefined) {
      writer.uint32(24).int32(message.IOXRay);
    }
    if (message.Pano !== undefined) {
      writer.uint32(32).int32(message.Pano);
    }
    if (message.CBCT !== undefined) {
      writer.uint32(40).int32(message.CBCT);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ToothAnatomyLocalization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToothAnatomyLocalization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothLocalizationID = reader.string();
          break;
        case 2:
          message.Localization = Localization.decode(reader, reader.uint32());
          break;
        case 3:
          message.IOXRay = reader.int32() as any;
          break;
        case 4:
          message.Pano = reader.int32() as any;
          break;
        case 5:
          message.CBCT = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ToothAnatomyLocalization {
    return {
      ToothLocalizationID: isSet(object.ToothLocalizationID)
        ? String(object.ToothLocalizationID)
        : '',
      Localization: isSet(object.Localization)
        ? Localization.fromJSON(object.Localization)
        : undefined,
      IOXRay: isSet(object.IOXRay)
        ? iOXRayToothAnatomyFromJSON(object.IOXRay)
        : undefined,
      Pano: isSet(object.Pano)
        ? panoToothAnatomyFromJSON(object.Pano)
        : undefined,
      CBCT: isSet(object.CBCT)
        ? cBCTToothAnatomyFromJSON(object.CBCT)
        : undefined,
    };
  },

  toJSON(message: ToothAnatomyLocalization): unknown {
    const obj: any = {};
    message.ToothLocalizationID !== undefined &&
      (obj.ToothLocalizationID = message.ToothLocalizationID);
    message.Localization !== undefined &&
      (obj.Localization = message.Localization
        ? Localization.toJSON(message.Localization)
        : undefined);
    message.IOXRay !== undefined &&
      (obj.IOXRay =
        message.IOXRay !== undefined
          ? iOXRayToothAnatomyToJSON(message.IOXRay)
          : undefined);
    message.Pano !== undefined &&
      (obj.Pano =
        message.Pano !== undefined
          ? panoToothAnatomyToJSON(message.Pano)
          : undefined);
    message.CBCT !== undefined &&
      (obj.CBCT =
        message.CBCT !== undefined
          ? cBCTToothAnatomyToJSON(message.CBCT)
          : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ToothAnatomyLocalization>, I>>(
    object: I,
  ): ToothAnatomyLocalization {
    const message = createBaseToothAnatomyLocalization();
    message.ToothLocalizationID = object.ToothLocalizationID ?? '';
    message.Localization =
      object.Localization !== undefined && object.Localization !== null
        ? Localization.fromPartial(object.Localization)
        : undefined;
    message.IOXRay = object.IOXRay ?? undefined;
    message.Pano = object.Pano ?? undefined;
    message.CBCT = object.CBCT ?? undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { ProductState } from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';
import { Service } from '@/shared/api/protocol_gen/api/billing_new/dto_services_new';

export const getServicesFromSubscriptionList = (products: ProductState[]) => {
  const services = products.reduce(
    (result, product) => result.concat(product?.Services),
    [] as Service[],
  );

  return services;
};

import { FC } from 'react';
import cn from 'classnames';

import { TreatingDoctors } from './TreatingDoctors/TreatingDoctors';
import { SharedWith } from './SharedWith/SharedWith';
import styles from './PatientInfo.module.scss';

type PatientInfoProps = {
  className?: string;
  patientID: string;
};

export const PatientInfo: FC<PatientInfoProps> = (props) => {
  const { className, patientID } = props;

  return (
    <div className={cn(styles.container, className)}>
      <TreatingDoctors patientID={patientID} />

      <SharedWith patientID={patientID} />
    </div>
  );
};

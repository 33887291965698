import { FC } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { scrollToElementByID } from '@/shared/lib';

import { toothModel } from '@/entities/tooth';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import { RenderViewSlider } from '@/features/renderViewSlider';

import styles from './PanowingImageSlider.module.scss';

type PanowingImageSliderProps = {
  className?: string;
};

export const PanowingImageSlider: FC<PanowingImageSliderProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const reportID = currentReport?.ID as string;

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID,
    ),
  );

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface as IOXrayImageInterface[],
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  const bitewingsImageMetaIDs = bitewingsImagesInterface.map(
    (imageItem) =>
      imageItem.imageMeta?.GeneratedAssetID ||
      imageItem?.imageMeta?.StudyAssetID,
  );

  const resultImageMetaIDs = panoImageInterface
    ? [
        panoImageInterface.imageMeta?.GeneratedAssetID ||
          panoImageInterface?.imageMeta?.StudyAssetID,
        ...bitewingsImageMetaIDs,
      ]
    : [];

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID),
  );
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  // select data by navigation mode
  const isMatrixMode = navigationMode === FMXNavigationMode.MatrixView;
  const itemIDs = isMatrixMode ? resultImageMetaIDs : teethIDs;
  const activeItemID = isMatrixMode ? focusedImageMetaID : activeToothID;

  const updateActiveToothID = (newItemID: string) => {
    MedicalImageRender.activateMode('mainMode');
    newItemID
      ? MedicalImageRender.layoutModes.mode === 'crop'
        ? MedicalImageRender.layoutModes.crop(newItemID)
        : MedicalImageRender.layoutModes.focus(newItemID)
      : MedicalImageRender.layoutModes.matrix();
    dispatch(reportsModel.actions.setToolbarActiveControl('view'));
    if (isMatrixMode) {
      dispatch(reportsModel.actions.setFocusedMetaImageID(newItemID));
    } else {
      dispatch(reportsModel.actions.setActiveToothID(newItemID));
      scrollToElementByID(newItemID, 'smooth', 'start');
      if (newItemID === '') {
        dispatch(
          reportsModel.actions.setNavigationMode(FMXNavigationMode.MatrixView),
        );
        dispatch(reportsModel.actions.setFocusedMetaImageID(newItemID));
      }
    }
  };

  return (
    <RenderViewSlider
      updateActiveItem={updateActiveToothID}
      itemIDs={itemIDs as string[]}
      activeItemID={activeItemID}
      className={cn(styles.container, className)}
    />
  );
};

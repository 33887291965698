import { head, pipe } from 'ramda';

import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';

export const getPatientLabel = (opts: {
  patient: Patient;
  isWide?: boolean;
  isLarge?: boolean;
}): string => {
  const { patient, isLarge, isWide } = opts;

  const firstname = patient?.PersonalData?.FirstName ?? '';
  const lastname = patient?.PersonalData?.LastName ?? '';

  const patientFullName = `${firstname} ${lastname}`.trim();

  const shortedFirstname = firstname
    ? `${pipe<string[], string>(head)(firstname)}.`
    : '';
  const shortedLastname = lastname
    ? `${pipe<string[], string>(head)(lastname)}.`
    : '';

  if (!isLarge) {
    return `${shortedFirstname} ${shortedLastname}`;
  }

  if (!isWide) {
    return `${shortedFirstname} ${lastname}`;
  }

  return patientFullName;
};

import { Annotation as CroppedImageAnnotation } from '@/shared/api/protocol_gen/model/dto_common_image_annotations';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';

export const croppedImageAnnotationConvertor = (
  annotations: Annotation[] = [],
): CroppedImageAnnotation[] => {
  const emptyAnnotation: CroppedImageAnnotation = {
    Arrow: undefined,
    Angle: undefined,
    Guide: undefined,
    PolyLine: undefined,
    Ruler: undefined,
  };

  const croppedImageAnnotations = annotations.reduce((acc, annotation) => {
    if (annotation.kind === 'angle') {
      acc.push({
        ...emptyAnnotation,
        Angle: {
          Start: { X: annotation.x1, Y: annotation.y1 },
          End: { X: annotation.x2, Y: annotation.y2 },
          Corner: { X: annotation.x3, Y: annotation.y3 },
        },
      });
    }
    if (annotation.kind === 'arrow') {
      acc.push({
        ...emptyAnnotation,
        Arrow: {
          Start: { X: annotation.x1, Y: annotation.y1 },
          End: { X: annotation.x2, Y: annotation.y2 },
          DoubleSided: false,
        },
      });
    }

    if (annotation.kind === 'ruler') {
      acc.push({
        ...emptyAnnotation,
        Ruler: {
          Start: { X: annotation.x1, Y: annotation.y1 },
          End: { X: annotation.x2, Y: annotation.y2 },
        },
      });
    }

    return acc;
  }, [] as CroppedImageAnnotation[]);

  return croppedImageAnnotations;
};

import { FC } from 'react';
import cn from 'classnames';

import styles from './BasicSkeleton.module.scss';

export type BasicSkeletonProps = {
  className?: string;
  testID?: string;
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
};

export const BasicSkeleton: FC<BasicSkeletonProps> = (props) => {
  const {
    className,
    testID,
    width = 280,
    height = 60,
    borderRadius = 90,
  } = props;

  return (
    <div
      className={cn(styles.container, className)}
      data-testid={testID}
      style={{
        width,
        height,
        borderRadius,
      }}
    />
  );
};

import { useSearchParams } from 'react-router-dom';

type UseTab = {
  name: string;
  onTabChange?: (value: string) => void;
};

export const useTab = ({ name, onTabChange }: UseTab) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tabValue = searchParams.get(name);

  const updateQueryString = (value: string): void => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };

  const handleTabChange = (value: string) => {
    if (typeof onTabChange === 'function') {
      onTabChange(value);
    }
    updateQueryString(value);
  };

  return {
    tabValue,
    handleTabChange,
  };
};

/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { File, DerivedDataImage } from '../model/dto_asset_common';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentDocument {
  Document: File | undefined;
  Image: DerivedDataImage | undefined;
  PDF: AssetContentDocument_DerivedDataPDF | undefined;
  Doc: AssetContentDocument_DerivedDataDOC | undefined;
  Video: AssetContentDocument_DerivedDataVideo | undefined;
  Archive: AssetContentDocument_DerivedDataArchive | undefined;
  Text: AssetContentDocument_DerivedDataText | undefined;
}

export interface AssetContentDocument_DerivedDataPDF {}

export interface AssetContentDocument_DerivedDataDOC {}

export interface AssetContentDocument_DerivedDataVideo {}

export interface AssetContentDocument_DerivedDataArchive {}

export interface AssetContentDocument_DerivedDataText {}

function createBaseAssetContentDocument(): AssetContentDocument {
  return {
    Document: undefined,
    Image: undefined,
    PDF: undefined,
    Doc: undefined,
    Video: undefined,
    Archive: undefined,
    Text: undefined,
  };
}

export const AssetContentDocument = {
  encode(
    message: AssetContentDocument,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Document !== undefined) {
      File.encode(message.Document, writer.uint32(10).fork()).ldelim();
    }
    if (message.Image !== undefined) {
      DerivedDataImage.encode(message.Image, writer.uint32(18).fork()).ldelim();
    }
    if (message.PDF !== undefined) {
      AssetContentDocument_DerivedDataPDF.encode(
        message.PDF,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Doc !== undefined) {
      AssetContentDocument_DerivedDataDOC.encode(
        message.Doc,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.Video !== undefined) {
      AssetContentDocument_DerivedDataVideo.encode(
        message.Video,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.Archive !== undefined) {
      AssetContentDocument_DerivedDataArchive.encode(
        message.Archive,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.Text !== undefined) {
      AssetContentDocument_DerivedDataText.encode(
        message.Text,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentDocument {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Document = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Image = DerivedDataImage.decode(reader, reader.uint32());
          break;
        case 3:
          message.PDF = AssetContentDocument_DerivedDataPDF.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.Doc = AssetContentDocument_DerivedDataDOC.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.Video = AssetContentDocument_DerivedDataVideo.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.Archive = AssetContentDocument_DerivedDataArchive.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 7:
          message.Text = AssetContentDocument_DerivedDataText.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentDocument {
    return {
      Document: isSet(object.Document)
        ? File.fromJSON(object.Document)
        : undefined,
      Image: isSet(object.Image)
        ? DerivedDataImage.fromJSON(object.Image)
        : undefined,
      PDF: isSet(object.PDF)
        ? AssetContentDocument_DerivedDataPDF.fromJSON(object.PDF)
        : undefined,
      Doc: isSet(object.Doc)
        ? AssetContentDocument_DerivedDataDOC.fromJSON(object.Doc)
        : undefined,
      Video: isSet(object.Video)
        ? AssetContentDocument_DerivedDataVideo.fromJSON(object.Video)
        : undefined,
      Archive: isSet(object.Archive)
        ? AssetContentDocument_DerivedDataArchive.fromJSON(object.Archive)
        : undefined,
      Text: isSet(object.Text)
        ? AssetContentDocument_DerivedDataText.fromJSON(object.Text)
        : undefined,
    };
  },

  toJSON(message: AssetContentDocument): unknown {
    const obj: any = {};
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? File.toJSON(message.Document)
        : undefined);
    message.Image !== undefined &&
      (obj.Image = message.Image
        ? DerivedDataImage.toJSON(message.Image)
        : undefined);
    message.PDF !== undefined &&
      (obj.PDF = message.PDF
        ? AssetContentDocument_DerivedDataPDF.toJSON(message.PDF)
        : undefined);
    message.Doc !== undefined &&
      (obj.Doc = message.Doc
        ? AssetContentDocument_DerivedDataDOC.toJSON(message.Doc)
        : undefined);
    message.Video !== undefined &&
      (obj.Video = message.Video
        ? AssetContentDocument_DerivedDataVideo.toJSON(message.Video)
        : undefined);
    message.Archive !== undefined &&
      (obj.Archive = message.Archive
        ? AssetContentDocument_DerivedDataArchive.toJSON(message.Archive)
        : undefined);
    message.Text !== undefined &&
      (obj.Text = message.Text
        ? AssetContentDocument_DerivedDataText.toJSON(message.Text)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentDocument>, I>>(
    object: I,
  ): AssetContentDocument {
    const message = createBaseAssetContentDocument();
    message.Document =
      object.Document !== undefined && object.Document !== null
        ? File.fromPartial(object.Document)
        : undefined;
    message.Image =
      object.Image !== undefined && object.Image !== null
        ? DerivedDataImage.fromPartial(object.Image)
        : undefined;
    message.PDF =
      object.PDF !== undefined && object.PDF !== null
        ? AssetContentDocument_DerivedDataPDF.fromPartial(object.PDF)
        : undefined;
    message.Doc =
      object.Doc !== undefined && object.Doc !== null
        ? AssetContentDocument_DerivedDataDOC.fromPartial(object.Doc)
        : undefined;
    message.Video =
      object.Video !== undefined && object.Video !== null
        ? AssetContentDocument_DerivedDataVideo.fromPartial(object.Video)
        : undefined;
    message.Archive =
      object.Archive !== undefined && object.Archive !== null
        ? AssetContentDocument_DerivedDataArchive.fromPartial(object.Archive)
        : undefined;
    message.Text =
      object.Text !== undefined && object.Text !== null
        ? AssetContentDocument_DerivedDataText.fromPartial(object.Text)
        : undefined;
    return message;
  },
};

function createBaseAssetContentDocument_DerivedDataPDF(): AssetContentDocument_DerivedDataPDF {
  return {};
}

export const AssetContentDocument_DerivedDataPDF = {
  encode(
    _: AssetContentDocument_DerivedDataPDF,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentDocument_DerivedDataPDF {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentDocument_DerivedDataPDF();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AssetContentDocument_DerivedDataPDF {
    return {};
  },

  toJSON(_: AssetContentDocument_DerivedDataPDF): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentDocument_DerivedDataPDF>, I>,
  >(_: I): AssetContentDocument_DerivedDataPDF {
    const message = createBaseAssetContentDocument_DerivedDataPDF();
    return message;
  },
};

function createBaseAssetContentDocument_DerivedDataDOC(): AssetContentDocument_DerivedDataDOC {
  return {};
}

export const AssetContentDocument_DerivedDataDOC = {
  encode(
    _: AssetContentDocument_DerivedDataDOC,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentDocument_DerivedDataDOC {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentDocument_DerivedDataDOC();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AssetContentDocument_DerivedDataDOC {
    return {};
  },

  toJSON(_: AssetContentDocument_DerivedDataDOC): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentDocument_DerivedDataDOC>, I>,
  >(_: I): AssetContentDocument_DerivedDataDOC {
    const message = createBaseAssetContentDocument_DerivedDataDOC();
    return message;
  },
};

function createBaseAssetContentDocument_DerivedDataVideo(): AssetContentDocument_DerivedDataVideo {
  return {};
}

export const AssetContentDocument_DerivedDataVideo = {
  encode(
    _: AssetContentDocument_DerivedDataVideo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentDocument_DerivedDataVideo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentDocument_DerivedDataVideo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AssetContentDocument_DerivedDataVideo {
    return {};
  },

  toJSON(_: AssetContentDocument_DerivedDataVideo): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentDocument_DerivedDataVideo>, I>,
  >(_: I): AssetContentDocument_DerivedDataVideo {
    const message = createBaseAssetContentDocument_DerivedDataVideo();
    return message;
  },
};

function createBaseAssetContentDocument_DerivedDataArchive(): AssetContentDocument_DerivedDataArchive {
  return {};
}

export const AssetContentDocument_DerivedDataArchive = {
  encode(
    _: AssetContentDocument_DerivedDataArchive,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentDocument_DerivedDataArchive {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentDocument_DerivedDataArchive();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AssetContentDocument_DerivedDataArchive {
    return {};
  },

  toJSON(_: AssetContentDocument_DerivedDataArchive): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentDocument_DerivedDataArchive>, I>,
  >(_: I): AssetContentDocument_DerivedDataArchive {
    const message = createBaseAssetContentDocument_DerivedDataArchive();
    return message;
  },
};

function createBaseAssetContentDocument_DerivedDataText(): AssetContentDocument_DerivedDataText {
  return {};
}

export const AssetContentDocument_DerivedDataText = {
  encode(
    _: AssetContentDocument_DerivedDataText,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentDocument_DerivedDataText {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentDocument_DerivedDataText();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AssetContentDocument_DerivedDataText {
    return {};
  },

  toJSON(_: AssetContentDocument_DerivedDataText): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentDocument_DerivedDataText>, I>,
  >(_: I): AssetContentDocument_DerivedDataText {
    const message = createBaseAssetContentDocument_DerivedDataText();
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

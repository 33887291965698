import { FC } from 'react';

import {
  BasicSkeleton,
  BasicSkeletonProps,
} from './components/BasicSkeleton/BasicSkeleton';
import { SkeletonFilter } from './components/SkeletonFilter/SkeletonFilter';
import { SkeletonGrid } from './components/SkeletonGrid/SkeletonGrid';
import { SkeletonRow } from './components/SkeletonRow/SkeletonRow';

type CompoundedComponent = FC<BasicSkeletonProps> & {
  Filter: typeof SkeletonFilter;
  Row: typeof SkeletonRow;
  Grid: typeof SkeletonGrid;
};

const Skeleton = BasicSkeleton as CompoundedComponent;

Skeleton.Filter = SkeletonFilter;
Skeleton.Row = SkeletonRow;
Skeleton.Grid = SkeletonGrid;

export { Skeleton };

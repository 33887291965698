/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { BrowserHeaders } from 'browser-headers';

export const protobufPackage = 'com.diagnocat.marketing';

export interface GetChatIdentificationTokenReq {}

export interface GetChatIdentificationTokenResp {
  Token: string;
}

function createBaseGetChatIdentificationTokenReq(): GetChatIdentificationTokenReq {
  return {};
}

export const GetChatIdentificationTokenReq = {
  encode(
    _: GetChatIdentificationTokenReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetChatIdentificationTokenReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChatIdentificationTokenReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetChatIdentificationTokenReq {
    return {};
  },

  toJSON(_: GetChatIdentificationTokenReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetChatIdentificationTokenReq>, I>>(
    _: I,
  ): GetChatIdentificationTokenReq {
    const message = createBaseGetChatIdentificationTokenReq();
    return message;
  },
};

function createBaseGetChatIdentificationTokenResp(): GetChatIdentificationTokenResp {
  return { Token: '' };
}

export const GetChatIdentificationTokenResp = {
  encode(
    message: GetChatIdentificationTokenResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Token !== '') {
      writer.uint32(10).string(message.Token);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetChatIdentificationTokenResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChatIdentificationTokenResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetChatIdentificationTokenResp {
    return {
      Token: isSet(object.Token) ? String(object.Token) : '',
    };
  },

  toJSON(message: GetChatIdentificationTokenResp): unknown {
    const obj: any = {};
    message.Token !== undefined && (obj.Token = message.Token);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetChatIdentificationTokenResp>, I>>(
    object: I,
  ): GetChatIdentificationTokenResp {
    const message = createBaseGetChatIdentificationTokenResp();
    message.Token = object.Token ?? '';
    return message;
  },
};

export interface IntegrationService {
  GetChatIdentificationToken(
    request: DeepPartial<GetChatIdentificationTokenReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetChatIdentificationTokenResp>;
}

export class IntegrationServiceClientImpl implements IntegrationService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetChatIdentificationToken =
      this.GetChatIdentificationToken.bind(this);
  }

  GetChatIdentificationToken(
    request: DeepPartial<GetChatIdentificationTokenReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetChatIdentificationTokenResp> {
    return this.rpc.unary(
      IntegrationServiceGetChatIdentificationTokenDesc,
      GetChatIdentificationTokenReq.fromPartial(request),
      metadata,
    );
  }
}

export const IntegrationServiceDesc = {
  serviceName: 'com.diagnocat.marketing.IntegrationService',
};

export const IntegrationServiceGetChatIdentificationTokenDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetChatIdentificationToken',
    service: IntegrationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetChatIdentificationTokenReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...GetChatIdentificationTokenResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

import { filter } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { isConditionReportIDEquals } from './isConditionReportIDEquals';

export const filterConditionsByReportID = (
  conditions: Condition[],
  reportID: string,
) => filter(isConditionReportIDEquals(reportID))(conditions);

import React, { FC } from 'react';
import cn from 'classnames';

import { HeaderDivider } from '@/shared/ui/HeaderDivider/HeaderDivider';
import { useHeaderDivider } from '@/shared/hooks';

import styles from './Header.module.scss';

type HeaderProps = {
  className?: string;
  testID?: string;
  children?: React.ReactNode;
  sticky?: boolean;
};

export const Header: FC<HeaderProps> = (props) => {
  const { className, testID, children, sticky } = props;

  const { isDividerLineVisible } = useHeaderDivider();

  return (
    <div
      className={cn(styles.container, sticky && styles.sticky, className)}
      data-testid={testID}
    >
      {children}
      {isDividerLineVisible && <HeaderDivider />}
    </div>
  );
};

import { filter } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { isConditionToothIDEquals } from './isConditionToothIDEquals';

export const filterConditionsByToothID = (
  conditions: Condition[],
  toothID: string,
) => filter(isConditionToothIDEquals(toothID))(conditions);

import { useMemo } from 'react';
import { useParams } from 'react-router';

import { useAppSelector } from '@/shared/hooks';

import { reportsModel } from '@/entities/reports';

import { ToothChart, toothChartModel } from '@/features/toothChart';

import styles from './PreviewToothChart.module.scss';

export const PreviewToothChart = () => {
  const { reportID } = useParams();
  const { toothItems } = useAppSelector(
    toothChartModel.selectors.selectToothChartItems(reportID as string, true),
  );
  const showUpperJaw = useAppSelector(
    reportsModel.selectors.selectPreviewSettingsShowTopJaw,
  );
  const showLowerJaw = useAppSelector(
    reportsModel.selectors.selectPreviewSettingsShowBottomJaw,
  );
  const blackAndWhiteMode = useAppSelector(
    reportsModel.selectors.selectPreviewSettingsBlackAndWhiteMode,
  );

  const filteredTeethItems = useMemo(
    () =>
      toothItems.filter((toothItem) => {
        if (!showUpperJaw) {
          return toothItem.isLowerJaw;
        }

        if (!showLowerJaw) {
          return !toothItem.isLowerJaw;
        }

        return true;
      }),
    [showLowerJaw, showUpperJaw, toothItems],
  );

  return (
    <ToothChart.Root className={styles.container}>
      <ToothChart.Grid showUpperJaw={showUpperJaw} showLowerJaw={showLowerJaw}>
        {filteredTeethItems.map((toothItem) => (
          <ToothChart.Item
            key={toothItem.id}
            id={toothItem.id}
            status={toothItem.status}
            ISONumber={toothItem.ISONumber}
            isLowerJaw={toothItem.isLowerJaw}
            active={toothItem.active}
            childItems={toothItem.childItems}
            blackAndWhite={blackAndWhiteMode}
            gridArea={toothItem.gridArea}
            className={styles.toothItem}
            preview
          />
        ))}
      </ToothChart.Grid>
    </ToothChart.Root>
  );
};

import { boolean, object, string } from 'yup';

export const sharePatientFormSchema = object({
  email: string().email().required(),
  comment: string().optional(),
  patientCaseSharingConfirmation: boolean().oneOf([true]).required(),
  patientDataSharingConsentConfirmation: boolean().oneOf([true]).required(),
});

export const addSharedPatientModalSchema = object({
  accessCode: string().required(),
});
